import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../../../../components/ui/checkbox";
import { DataTableColumnHeader } from "../../../../components/ui/table-helpers/data-table-column-header";
import { GetNextAppointments_appointments as Appointment } from "../../../../generated/GetNextAppointments";
import { Tooltip } from "../../../../components";
import { format, formatDistanceToNow } from "date-fns";
import { Badge } from "../../../../components/ui/badge";

export type BenefitMappingPreviewRow = {
  id: string;
  appointment: Appointment;
  start: Date | null;
  patientName: string;
  providerName: string | null;
  providerTaxonomyCode: string | null;
  appointmentLabels: string[];
  accountType: string | null;
  insurancePolicies: string[];
  charges: string[];
  match: boolean;
};

export const columns: ColumnDef<BenefitMappingPreviewRow>[] = [
  {
    id: "start",
    accessorKey: "start",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Appt Date" />
    ),
    cell: ({ row }) => {
      if (row.original.start) {
        return <div>{format(row.original.start, "MM/dd/yyyy")}</div>;
      }
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.start);
    },
  },
  {
    id: "patientName",
    accessorKey: "patientName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Patient" />
    ),
    cell: ({ row }) => {
      return <div>{row.original.patientName}</div>;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.patientName);
    },
  },
  {
    id: "accountType",
    accessorKey: "accountType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Account" />
    ),
    cell: ({ row }) => {
      return <div>{row.original.accountType}</div>;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.accountType);
    },
  },
  {
    id: "providerName",
    accessorKey: "providerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Provider" />
    ),
    cell: ({ row }) => {
      return <div>{row.original.providerName}</div>;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.providerName);
    },
  },
  {
    id: "providerTaxonomyCode",
    accessorKey: "providerTaxonomyCode",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Provider Classification" />
    ),
    cell: ({ row }) => {
      return <div>{row.original.providerTaxonomyCode}</div>;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.providerTaxonomyCode);
    },
  },
  {
    id: "appointmentLabels",
    accessorKey: "appointmentLabels",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Appointment Labels" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center gap-1">
          {row.original.appointment.appointmentLabelings.map((labeling) => (
            <Badge
              key={labeling.appointmentLabel.id}
              className="px-1 py-[1px] font-normal"
              variant="outline"
            >
              {labeling.appointmentLabel.name}
            </Badge>
          ))}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return row.original.appointmentLabels.some((al) => value.includes(al));
    },
  },
  {
    id: "payer",
    accessorKey: "payer",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payers" />
    ),
    cell: ({ row }) => {
      return <div>{row.original.insurancePolicies.join(", ")}</div>;
    },
    filterFn: (row, id, value) => {
      return value.some((v: any) =>
        row.original.insurancePolicies.some((ip) => ip === v)
      );
    },
  },
  {
    id: "charges",
    accessorKey: "charges",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charges" />
    ),
    cell: ({ row }) => {
      if (row.original.charges.length === 0) {
        return <div className="text-gray-400">No charges</div>;
      }
      return <div>{row.original.charges.join(", ")}</div>;
    },
    filterFn: (row, id, value) => {
      return value.some((v: any) => row.original.charges.some((c) => c === v));
    },
  },
  {
    id: "match",
    accessorKey: "match",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Matches" />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {row.original.match ? (
            <span className="text-green-600">Yes</span>
          ) : (
            <span className="text-gray-400">No</span>
          )}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.match);
    },
  },
];
