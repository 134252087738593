import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  Table as ReactTable,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { OvalSpinner } from "../../components/loading";
import {
  cn,
  formatRelativeDay,
  formatUSD,
  isDefined,
  mapNullable,
} from "../../utils";
import { gql, useQuery } from "@apollo/client";
import { DataTableColumnHeader } from "../../components/ui/table-helpers/data-table-column-header";
import { Table as ITable } from "@tanstack/react-table";
import {
  GetChargemasterCharges,
  GetChargemasterChargesVariables,
  GetChargemasterCharges_charges as Charge,
} from "../../generated/GetChargemasterCharges";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useUser } from "../../user-context";
import { DataTableFacetedFilter } from "../../components/ui/table-helpers/data-table-faceted-filter";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { HorizontalPadding } from "../layout";
import { parseISO } from "date-fns";
import { ChargeInsuranceState } from "../../generated/globalTypes";

interface DataTableToolbarProps<TData extends { id: string }> {
  table: ITable<TData>;
}

const CHARGE_STATUS_OPTIONS = [
  { label: "Billed", value: ChargeInsuranceState.Billed },
  { label: "Pending", value: ChargeInsuranceState.Pending },
  { label: "Processed", value: ChargeInsuranceState.Processed },
];

export function DataTableToolbar({ table }: DataTableToolbarProps<ChargeRow>) {
  const user = useUser()!;
  const isFiltered = table.getState().columnFilters.length > 0;

  const rows = table.getPreFilteredRowModel().rows;

  const payerOptions = Array.from(
    new Set(rows.map((row) => row.original.payerName).filter(isDefined))
  ).map((payer) => ({
    value: payer,
    label: payer,
  }));
  const accountOptions = Array.from(
    new Set(rows.map((row) => row.original.account).filter(isDefined))
  ).map((account) => ({
    value: account,
    label: account,
  }));

  return (
    <HorizontalPadding>
      <div className="flex items-start justify-between gap-2 w-full">
        <div className="flex flex-1 items-center flex-wrap gap-2">
          {table.getColumn("payerName") && (
            <DataTableFacetedFilter
              column={table.getColumn("payerName")}
              title="Payer"
              options={payerOptions}
              sortByCount={true}
            />
          )}
          {table.getColumn("account") && (
            <DataTableFacetedFilter
              column={table.getColumn("account")}
              title="Account"
              options={accountOptions}
              sortByCount={true}
            />
          )}
          {table.getColumn("status") && (
            <DataTableFacetedFilter
              column={table.getColumn("status")}
              title="Status"
              options={CHARGE_STATUS_OPTIONS}
              sortByCount={true}
            />
          )}
          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => table.resetColumnFilters()}
              className="h-8 px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    </HorizontalPadding>
  );
}

export type ChargeRow = {
  id: string;
  dateOfService: string;
  patientName: string;
  account: string | null;
  payerName: string | null;
  allowedAmount: number | null;
  status: ChargeInsuranceState;
  charge: Charge;
};

interface DataTableProps<TValue> {
  columns: ColumnDef<ChargeRow, TValue>[];
  defaultColumnFilters?: ColumnFiltersState;
  data: ChargeRow[];
}

const columns: ColumnDef<ChargeRow>[] = [
  {
    id: "dateOfService",
    accessorKey: "dateOfService",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="DOS" />
    ),
    cell: ({ row }) => {
      return <>{formatRelativeDay(parseISO(row.original.dateOfService))}</>;
    },
  },
  {
    id: "patientName",
    accessorKey: "patientName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Patient" />
    ),
  },
  {
    id: "account",
    accessorKey: "account",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Account" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.original.account);
    },
  },
  {
    id: "status",
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.original.status);
    },
  },
  {
    id: "payerName",
    accessorKey: "payerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payer" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.original.payerName);
    },
  },
  {
    id: "units",
    accessorKey: "units",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Units" />
    ),
  },
  {
    id: "allowedAmount",
    accessorKey: "allowedAmount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Allowed Amount" />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {mapNullable((v: number) => formatUSD(-v))(
            row.original.allowedAmount
          )}
        </div>
      );
    },
  },
];

function DataTable<TValue>({
  columns,
  data,
  defaultColumnFilters,
  children,
  onRowClick,
  loading,
  loadingMore,
}: DataTableProps<TValue> & {
  children?: (table: ReactTable<ChargeRow>) => React.ReactElement;
  onRowClick?: (row: ChargeRow) => void;
  loading?: boolean;
  loadingMore?: boolean;
}) {
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  // const [pagination, setPagination] = React.useState<PaginationState>({
  //   pageSize: 20,
  //   pageIndex: 0,
  // });
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    defaultColumnFilters ?? []
  );
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "dateOfService",
      desc: true,
    },
  ]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter,
      // pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <>
      {children && children(table)}
      <div className="space-y-4">
        <div className="w-full">
          <DataTableToolbar table={table} />
        </div>
        <div className="border bg-white">
          <Table className="max-h-[85vh]">
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow
                  key={headerGroup.id}
                  className="divide-x"
                  onClick={() => {}}
                >
                  {/* <TableHead className="sticky top-0 z-10 bg-white"></TableHead> */}
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className="sticky top-0 z-10 p-2 bg-white"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <>
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                      onClick={() => {
                        onRowClick?.(row.original);
                      }}
                      className={cn("divide-x", onRowClick && "cursor-pointer")}
                    >
                      {row.getVisibleCells().map((cell, i) => (
                        <TableCell
                          key={cell.id}
                          className={cn(
                            "whitespace-nowrap p-2",
                            // CSS to make the last column sticky
                            // "last:sticky last:right-0  last:bg-white last:drop-shadow"
                            cell.column.columnDef.className
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center p-2"
                  >
                    <div className="w-full flex justify-center">
                      <OvalSpinner />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center p-2"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {/* <div className="w-full">
          <DataTablePagination table={table} loading={loading || loadingMore} />
        </div> */}
      </div>
    </>
  );
}

const GET_CHARGEMASTER_CHARGES = gql`
  query GetChargemasterCharges($id: String!) {
    charges(
      where: { chargemaster: { is: { chargemasterGroupId: { equals: $id } } } }
      orderBy: { createdAt: desc }
      take: 1000
    ) {
      id
      createdAt
      allowedAmount
      units
      transaction {
        id
        transactedAt
        account {
          id
          accountType {
            id
            name
          }
          patient {
            id
            displayName
          }
        }
      }
      insuranceBillableCharges {
        id
        status
        accountCoverage {
          id
          insurancePolicy {
            id
            payer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const ChargeTable: React.FC<{
  chargemasterGroupId: string;
}> = ({ chargemasterGroupId }) => {
  const { data, loading } = useQuery<
    GetChargemasterCharges,
    GetChargemasterChargesVariables
  >(GET_CHARGEMASTER_CHARGES, {
    variables: { id: chargemasterGroupId },
  });

  const tableData: ChargeRow[] =
    data?.charges.map((charge) => {
      const billedCharge = charge.insuranceBillableCharges.at(0);
      return {
        id: charge.id,
        dateOfService: charge.transaction.transactedAt,
        status: billedCharge?.status,
        patientName: charge.transaction.account.patient.displayName,
        account: charge.transaction.account.accountType?.name ?? null,
        payerName:
          billedCharge?.accountCoverage?.insurancePolicy?.payer?.name ?? null,
        units: charge.units,
        allowedAmount: charge.allowedAmount,
        charge,
      };
    }) ?? [];

  return <DataTable columns={columns} data={tableData} loading={loading} />;
};
