import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppProviders from "./context";

import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en"; // locale-data for en
import { constants } from "./constants";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if (constants.ENV !== "development") {
  Sentry.init({
    dsn: "https://a7073ec007684581862b3b3f764d32b2@o1146536.ingest.sentry.io/6215374",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
        tracingOrigins: [
          "localhost",
          "api.pledge.health",
          "api.staging.platform.pledge.health",
        ],
      }),
    ],
    // TODO: Set this lower once we've got more traffic
    tracesSampleRate: 1.0,
    release: constants.APP_VERSION,
    environment: constants.ENV,
  });
}

const root = createRoot(document.getElementById("root")!);
root.render(
  <AppProviders>
    <App />
  </AppProviders>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <AppProviders>
//       <App />
//     </AppProviders>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
