import React from "react";
import { Layout } from "../../layout";
import { ChargeTemplateMappingForm } from "./charge-template-mapping-form";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { Condition, Visit } from "../../../evaluate-rule";
import {
  GetChargeTemplates,
  GetChargeTemplatesVariables,
} from "../../../generated/GetChargeTemplates";
import { useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import { GET_CHARGE_TEMPLATES } from "../../chargemaster/templates";
import { useLocation } from "react-router-dom";
import { GetLocationRules_chargeTemplateMappings as ChargeTemplateMapping } from "../../../generated/GetLocationRules";

export const NewChargeTemplateRule: React.FC = () => {
  const user = useUser()!;
  const location = useLocation();
  const state: {
    chargeTemplateMapping?: ChargeTemplateMapping | null;
    defaultNewChargeTemplate?: {
      name: string | null;
      charges: {
        chargemasterGroupId: string;
        units: number;
      }[];
    };
  } | null = location.state;

  const { data, loading, error } = useQuery<
    GetChargeTemplates,
    GetChargeTemplatesVariables
  >(GET_CHARGE_TEMPLATES, {
    variables: {
      locationId: user.activeLocation.id,
      pending: false,
    },
  });

  const chargeTemplates = data?.chargeTemplates ?? [];

  const defaultNewChargeTemplate = state?.defaultNewChargeTemplate;
  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Create New Rule
          </h1>
        </div>
      }
      content={
        loading ? (
          <div className="flex flex-col gap-4">
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          </div>
        ) : (
          <ChargeTemplateMappingForm
            chargeTemplateMapping={state?.chargeTemplateMapping ?? null}
            chargeTemplates={chargeTemplates}
            defaultNewChargeTemplate={defaultNewChargeTemplate}
          >
            {({ conditions }) => (
              <div>
                <BenefitAssignmentRuleSimulatorTable
                  conditions={conditions as Condition<keyof Visit>[]}
                />
              </div>
            )}
          </ChargeTemplateMappingForm>
        )
      }
    />
  );
};
