import { useMutation } from "@apollo/client";
import { SettingsLayout } from ".";
import { Card, StripeLogo } from "../../components";
import { GetMyOrganization_me_organization as Organization } from "../../generated/GetMyOrganization";
import { StripeConnectAccountType } from "../../generated/globalTypes";
import {
  CREATE_STRIPE_ACCOUNT_LINK,
  StripeConnectedAccountDetails,
} from "./workspace";

export const WorkspacePaymentSettings: React.FC<{
  organization: Organization;
}> = ({ organization }) => {
  const [createStripeAccountLink, createStripeAccountLinkResult] = useMutation(
    CREATE_STRIPE_ACCOUNT_LINK
  );
  const registerStripeAccount = async () => {
    const res = await createStripeAccountLink({
      variables: { organizationId: organization.id },
    });
    const url = res.data.createStripeAccountLink?.stripeAccountLink?.url;
    window.open(url, "_blank");
  };
  return (
    <SettingsLayout
      title="Payments"
      description="Manage your payment settings."
    >
      {organization.stripeConnectAccountType ===
        StripeConnectAccountType.Standard && (
        <Card>
          <div className="flex flex-col p-4">
            <div className="text-gray-500 pb-4">
              Register with our payments partner, Stripe, to begin collecting
              payments.
            </div>
            {organization.stripeAccountId &&
            organization.stripeDetailsSubmitted ? (
              <a
                href="https://dashboard.stripe.com"
                target="_blank"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 stripe-connect"
              >
                <span>Go to</span>
                <StripeLogo />
                Dashboard
              </a>
            ) : (
              <button
                onClick={registerStripeAccount}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 stripe-connect"
              >
                <span>Register with</span> <StripeLogo />
              </button>
            )}
          </div>
        </Card>
      )}

      {organization.stripeConnectAccountType ===
        StripeConnectAccountType.Express && (
        <div className="flex flex-col gap-2">
          {organization.stripeConnectedAccounts.map((account) => (
            <StripeConnectedAccountDetails account={account} />
          ))}
        </div>
      )}
    </SettingsLayout>
  );
};
