import { gql, useMutation, useQuery } from "@apollo/client";
import {
  InboxInIcon,
  InformationCircleIcon,
  ShieldCheckIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Sentry from "@sentry/react";

import { Rings } from "../../../components/loading";
import {
  GetPublicOrganizationData,
  GetPublicOrganizationDataVariables,
  GetPublicOrganizationData_getPublicOrganizationData_locations as PublicLocation,
} from "../../../generated/GetPublicOrganizationData";
import {
  GetPublicLocationData,
  GetPublicLocationDataVariables,
} from "../../../generated/GetPublicLocationData";
import {
  FindOrganizationPatientMatches,
  FindOrganizationPatientMatchesVariables,
} from "../../../generated/FindOrganizationPatientMatches";
import {
  FindOrganizationPatientByAccountNumber,
  FindOrganizationPatientByAccountNumberVariables,
} from "../../../generated/FindOrganizationPatientByAccountNumber";
import {
  FindPaymentRequestByAccessCode,
  FindPaymentRequestByAccessCodeVariables,
} from "../../../generated/FindPaymentRequestByAccessCode";
import { SubmitButton } from "../../../components/Button";
import { FeedbackSurvey } from "../feedback-survey";
import { FeedbackType } from "../../../generated/globalTypes";
import {
  GET_PUBLIC_LOCATION_DATA,
  GET_PUBLIC_ORGANIZATION_DATA,
} from "../../../graphql";
import { DateInput } from "../../../components";
import { Tab } from "@headlessui/react";
import { classNames, isDefined } from "../../../utils";
import { useQueryParams } from "../../../hooks";
import { PortalLayout } from "../portal-layout";
import { FixRequiredSelect } from "../../../components/FixedRequiredSelect";
import { toast } from "react-toastify";

const FIND_ORGANIZATION_PATIENT_MATCHES = gql`
  mutation FindOrganizationPatientMatches(
    $organizationId: String!
    $locationId: String
    $params: FindOrganizationPatientMatchesInput!
  ) {
    findOrganizationPatientMatches(
      organizationId: $organizationId
      locationId: $locationId
      params: $params
    ) {
      success
      primaryLinkedRecordId
    }
  }
`;

const FIND_ORGANIZATION_PATIENT_BY_ACCOUNT_NUMBER = gql`
  mutation FindOrganizationPatientByAccountNumber(
    $organizationId: String!
    $locationId: String
    $accountNumber: String!
  ) {
    findOrganizationPatientByAccountNumber(
      organizationId: $organizationId
      locationId: $locationId
      accountNumber: $accountNumber
    ) {
      success
      primaryLinkedRecordId
    }
  }
`;

const FIND_PAYMENT_REQUEST_BY_ACCESS_CODE = gql`
  mutation FindPaymentRequestByAccessCode(
    $accessCode: String!
    $dateOfBirth: DateTime!
  ) {
    findPaymentRequestByAccessCode(
      accessCode: $accessCode
      dateOfBirth: $dateOfBirth
    ) {
      success
      organizationId
      patientId
    }
  }
`;

export const PagePublicOrgHeader: React.FC<
  React.PropsWithChildren<{
    logoUrl: string | null;
  }>
> = ({ logoUrl }) => {
  const [showSurvey, setShowSurvey] = useState(false);
  return (
    <nav className="bg-white border-b border-gray-300">
      <div className="h-16 mx-auto max-w-7xl flex justify-center md:justify-between items-center px-4 md:px-16">
        {logoUrl && (
          <img
            className="flex h-14 w-auto justify-center md:justify-start"
            src={logoUrl}
            alt="Logo"
          />
        )}
        <div className="hidden md:visible md:inline-flex">
          <button
            className="inline-flex items-center space-x-1 justify-center px-4 py-2 text-indigo-900 border border-transparent rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={() => setShowSurvey(true)}
          >
            <div>Submit Feedback</div>
            <InboxInIcon className="h-6 w-6" />
          </button>
        </div>
        <FeedbackSurvey
          title="How was your payment experience?"
          showSurvey={showSurvey}
          setShowSurvey={setShowSurvey}
          feedbackType={FeedbackType.PATIENT_PUBLIC_PORTAL}
        />
      </div>
    </nav>
  );
};

const PatientMatchError: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Couldn't identify you
          </h3>
          <div className="mt-2 text-sm text-red-700">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const AccountNumberIdentificationForm: React.FC<
  React.PropsWithChildren<{
    loading: boolean;
    onSubmit: (data: any) => void;
    defaultLocationId: string | null;
    locations: PublicLocation[];
  }>
> = ({ loading, onSubmit, defaultLocationId, locations }) => {
  const [locationDirty, setLocationDirty] = useState(false);
  const methods = useForm<{
    location: string | null;
    accountNumber: string;
  }>({
    defaultValues: {
      location: defaultLocationId,
    },
  });
  const { register, handleSubmit, control } = methods;
  const defaultLocation = locations.find((l) => l.id === defaultLocationId);
  const selectedLocation = methods.watch("location");

  return (
    <FormProvider {...methods}>
      <form className="space-y-2 px-2" onSubmit={handleSubmit(onSubmit)}>
        {locations.length > 1 && (
          <>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              Clinic Location
            </label>
            <div className="mt-1">
              <Controller
                name="location"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FixRequiredSelect
                    // @ts-expect-error
                    className={
                      !isDefined(selectedLocation) && locationDirty
                        ? "ring-2 ring-red-400 rounded-md"
                        : ""
                    }
                    onInvalid={() => setLocationDirty(true)}
                    onChange={(val: any) => {
                      setLocationDirty(false);
                      field.onChange(val.value);
                    }}
                    options={locations.map((l) => ({
                      label: l.name,
                      value: l.id,
                    }))}
                    currentValue={selectedLocation}
                    defaultValue={
                      defaultLocation
                        ? {
                            label: defaultLocation.name,
                            value: defaultLocation.name,
                          }
                        : null
                    }
                    required
                    SelectComponent={Select}
                  />
                )}
              />
            </div>
          </>
        )}
        <div>
          {/* TODO: Make this text customizable to each org */}
          <div className="text-sm text-gray-800 mt-2">
            We need your account number to identify you. This information can be
            found at the top right corner of any paper statement above{" "}
            <span className="italic">Patient Balance</span>
          </div>
          <label
            htmlFor="accountNumber"
            className="block text-sm font-medium text-gray-700 pt-4 mt-2"
          >
            Account Number
          </label>
          <div className="text-xs text-yellow-600 mt-1 mb-1">
            Please only enter the numeric portion before the '-' sign. For
            example, you should enter '123456' if '123456-Ins Chiro' is shown.
          </div>
          <div className="mt-2 mb-4">
            <input
              id="accountNumber"
              type="text"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              {...register("accountNumber")}
            />
          </div>
        </div>
        <SubmitButton loading={loading}>View Bill</SubmitButton>
      </form>
    </FormProvider>
  );
};

export const DemographicIdentificationForm: React.FC<
  React.PropsWithChildren<{
    loading: boolean;
    onSubmit: (data: any) => void;
    defaultLocationId: string | null;
    locations: PublicLocation[];
  }>
> = ({ loading, onSubmit, defaultLocationId, locations }) => {
  const [locationDirty, setLocationDirty] = useState(false);
  const methods = useForm<{
    location: string | null;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
  }>({
    defaultValues: {
      location: defaultLocationId,
    },
  });
  const { register, handleSubmit, control } = methods;
  const defaultLocation = locations.find((l) => l.id === defaultLocationId);
  const selectedLocation = methods.watch("location");

  return (
    <FormProvider {...methods}>
      <form className="space-y-2 px-2" onSubmit={handleSubmit(onSubmit)}>
        {locations.length > 1 && (
          <>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              Clinic Location
            </label>
            <div className="mt-1">
              <Controller
                name="location"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FixRequiredSelect
                    // @ts-expect-error
                    className={
                      !isDefined(selectedLocation) && locationDirty
                        ? "ring-2 ring-red-400 rounded-md"
                        : ""
                    }
                    onInvalid={() => setLocationDirty(true)}
                    onChange={(val: any) => {
                      setLocationDirty(false);
                      field.onChange(val.value);
                    }}
                    options={locations.map((l) => ({
                      label: l.name,
                      value: l.id,
                    }))}
                    currentValue={selectedLocation}
                    defaultValue={
                      defaultLocation
                        ? {
                            label: defaultLocation.name,
                            value: defaultLocation.name,
                          }
                        : null
                    }
                    required
                    SelectComponent={Select}
                  />
                )}
              />
            </div>
          </>
        )}
        <div>
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-700"
          >
            First Name
          </label>
          <div className="mt-1">
            <input
              id="firstName"
              type="text"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              {...register("firstName")}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-700"
          >
            Last Name
          </label>
          <div className="mt-1">
            <input
              id="lastName"
              type="text"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              {...register("lastName")}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="dateOfBirth"
            className="block text-sm font-medium text-gray-700"
          >
            Date of Birth
          </label>
          <div className="mt-1">
            <DateInput name="dateOfBirth" control={control as any} required />
          </div>
        </div>
        <div>
          <SubmitButton loading={loading}>View Bill</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

const tabs = ["Demographic Info", "Account Number"];

const PatientIdentificationForm: React.FC<
  React.PropsWithChildren<{
    organizationId: string;
    contactEmail?: string;
    locations: PublicLocation[];
    defaultLocationId: string | null;
    patientPortalAccountLookupMessage: string | null;
    organizationName: string | null;
    locationDisplayName: string | null;
  }>
> = ({
  organizationId,
  contactEmail,
  locations,
  defaultLocationId,
  patientPortalAccountLookupMessage,
  organizationName,
  locationDisplayName,
}) => {
  const navigate = useNavigate();
  const params = useQueryParams();
  // Optional locationId query parameter
  const locationId = params.get("locationId");
  const [demographicMatchError, setDemographicMatchError] = useState(false);
  const [accountNumberMatchError, setAccountNumberMatchError] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const [findOrganizationPatientMatches, findOrganizationPatientMatchesResult] =
    useMutation<
      FindOrganizationPatientMatches,
      FindOrganizationPatientMatchesVariables
    >(FIND_ORGANIZATION_PATIENT_MATCHES);
  const [
    findOrganizationPatientByAccountNumber,
    findOrganizationPatientByAccountNumberResult,
  ] = useMutation<
    FindOrganizationPatientByAccountNumber,
    FindOrganizationPatientByAccountNumberVariables
  >(FIND_ORGANIZATION_PATIENT_BY_ACCOUNT_NUMBER);

  const onDemographicSubmit = (data: any) => {
    findOrganizationPatientMatches({
      variables: {
        organizationId,
        locationId: data.location,
        params: {
          firstName: data.firstName,
          lastName: data.lastName,
          dateOfBirth: data.dateOfBirth,
        },
      },
      onCompleted: (data) => {
        if (data.findOrganizationPatientMatches.success) {
          navigate(
            `/portal/${organizationId}/${data.findOrganizationPatientMatches.primaryLinkedRecordId}`
          );
        } else {
          setDemographicMatchError(true);
          setCurrentTabIndex(1); // Navigate to account number tab
        }
      },
    });
  };

  const onAccountNumberSubmit = (data: any) => {
    findOrganizationPatientByAccountNumber({
      variables: {
        organizationId,
        locationId: data.location,
        accountNumber: data.accountNumber,
      },
      onCompleted: (data) => {
        if (data.findOrganizationPatientByAccountNumber.success) {
          navigate(
            `/portal/${organizationId}/${data.findOrganizationPatientByAccountNumber.primaryLinkedRecordId}`
          );
        } else {
          setAccountNumberMatchError(true);
        }
      },
    });
  };

  const placeDisplayName = locationDisplayName || organizationName;

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 mx-4 md:mx-0">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <ShieldCheckIcon className="h-10 md:h-15 w-full text-gray-400"></ShieldCheckIcon>
        <h2 className="mt-4 text-center text-xl mx-10 md:mx-4 md:text-2xl font-semibold text-indigo-500">
          Pay your bills online easily and securely
        </h2>
        {patientPortalAccountLookupMessage && (
          <div className="italic pt-2 text-gray-700">
            {patientPortalAccountLookupMessage}
          </div>
        )}
      </div>
      <div className="mt-4 md:mt-6 sm:mx-auto sm:w-full sm:max-w-md">
        <Tab.Group key={currentTabIndex} defaultIndex={currentTabIndex}>
          <div className="-mb-px flex space-x-8">
            <Tab.List>
              {tabs.map((tab) => (
                <Tab
                  key={tab}
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? "text-indigo-600 border-b-indigo-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-1 px-4 border-b-2 font-medium text-sm"
                    )
                  }
                >
                  {tab}
                </Tab>
              ))}
            </Tab.List>
          </div>
          <Tab.Panels>
            <div className="py-6">
              <Tab.Panel>
                <DemographicIdentificationForm
                  onSubmit={onDemographicSubmit}
                  loading={findOrganizationPatientMatchesResult.loading}
                  locations={locations}
                  defaultLocationId={defaultLocationId}
                />
              </Tab.Panel>
              <Tab.Panel>
                {demographicMatchError && (
                  <div className="rounded-md bg-yellow-50 text-center p-2 mb-4 mx-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-yellow-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="text-sm text-yellow-600">
                        We had trouble identifying you with your demographic
                        information
                      </div>
                    </div>
                  </div>
                )}
                <AccountNumberIdentificationForm
                  onSubmit={onAccountNumberSubmit}
                  loading={findOrganizationPatientByAccountNumberResult.loading}
                  locations={locations}
                  defaultLocationId={defaultLocationId}
                />
                {accountNumberMatchError && (
                  <div className="p-2">
                    <PatientMatchError>
                      <span>
                        Please{" "}
                        {isDefined(contactEmail) ? (
                          <a
                            href={`mailto:${contactEmail}?subject=${encodeURIComponent(
                              `${placeDisplayName} - Contact Us (Account Lookup)`
                            )}`}
                            className="underline py-1"
                          >
                            contact
                          </a>
                        ) : (
                          "contact"
                        )}{" "}
                        us to resolve this.
                      </span>
                    </PatientMatchError>
                  </div>
                )}
              </Tab.Panel>
            </div>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export const PatientIdentification: React.FC<
  React.PropsWithChildren<{}>
> = ({}) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const params = useQueryParams();
  // Optional locationId query parameter
  const locationId = params.get("locationId");
  const { data, loading } = useQuery<
    GetPublicOrganizationData,
    GetPublicOrganizationDataVariables
  >(GET_PUBLIC_ORGANIZATION_DATA, {
    variables: { id: organizationId! },
  });
  const publicLocationResult = useQuery<
    GetPublicLocationData,
    GetPublicLocationDataVariables
  >(GET_PUBLIC_LOCATION_DATA, {
    variables: { id: locationId },
  });
  const [showSurvey, setShowSurvey] = useState(false);

  if (loading || !data || publicLocationResult.loading)
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );

  const getPublicLocationData =
    publicLocationResult.data?.getPublicLocationData;
  const getPublicOrganizationData = data.getPublicOrganizationData;

  const contactEmail =
    getPublicLocationData?.primaryEmail ??
    getPublicOrganizationData?.primaryEmail ??
    undefined;

  const locationDisplayName =
    getPublicLocationData?.locationDisplayName ??
    getPublicOrganizationData?.name ??
    null;

  return (
    <PortalLayout organizationId={getPublicOrganizationData?.id!}>
      <div className="text-gray-900 flex flex-col">
        <div>
          <PagePublicOrgHeader
            logoUrl={getPublicOrganizationData?.logoUrl ?? null}
          />
          <PatientIdentificationForm
            organizationId={organizationId!}
            organizationName={getPublicOrganizationData?.name ?? ""}
            locationDisplayName={locationDisplayName}
            contactEmail={contactEmail}
            locations={getPublicOrganizationData?.locations ?? []}
            defaultLocationId={locationId}
            patientPortalAccountLookupMessage={
              getPublicOrganizationData?.patientPortalAccountLookupMessage ??
              null
            }
          />
          {/* <div className="flex justify-center mb-5">
            <button
              className="md:hidden inline-flex items-center space-x-1 justify-center px-4 py-2 text-sm font-medium text-indigo-900 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
              onClick={() => setShowSurvey(true)}
            >
              <div>Submit Feedback</div>
              <InboxInIcon className="h-6 w-6" />
            </button>
          </div>
          <FeedbackSurvey
            title="How was your payment experience?"
            showSurvey={showSurvey}
            setShowSurvey={setShowSurvey}
            feedbackType={FeedbackType.PATIENT_PUBLIC_PORTAL}
          /> */}
        </div>
      </div>
    </PortalLayout>
  );
};

const UniversalPatientIdentificationForm = () => {
  const navigate = useNavigate();
  const params = useQueryParams();
  const accessCode = params.get("accessCode");
  const [billInfoMatchError, setBillInfoMatchError] = useState(false);
  const [findPaymentRequestByAccessCode, findPaymentRequestByAccessCodeResult] =
    useMutation<
      FindPaymentRequestByAccessCode,
      FindPaymentRequestByAccessCodeVariables
    >(FIND_PAYMENT_REQUEST_BY_ACCESS_CODE);
  const methods = useForm<{
    accessCode: string;
    dateOfBirth: string;
  }>({ defaultValues: { accessCode: accessCode ?? "" } });

  const { register, handleSubmit, control } = methods;

  const onBillLookupSubmit = (data: any) => {
    findPaymentRequestByAccessCode({
      variables: {
        accessCode: data.accessCode,
        dateOfBirth: data.dateOfBirth,
      },
      onCompleted: async (completedData) => {
        if (completedData.findPaymentRequestByAccessCode.success) {
          const { patientId, organizationId } =
            completedData.findPaymentRequestByAccessCode;
          navigate(`/portal/${organizationId}/${patientId}?utm_medium=access-code`);
        } else {
          setBillInfoMatchError(true);
        }
      },
      onError(e) {
        toast.error("Something went wrong.");
        Sentry.captureException(e);
      },
    });
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 mx-4 md:mx-0">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <ShieldCheckIcon className="h-10 md:h-15 w-full text-gray-400"></ShieldCheckIcon>
        <h2 className="mt-4 text-center text-xl mx-10 md:mx-4 md:text-2xl font-semibold text-indigo-500">
          Pay your bills online easily and securely
        </h2>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-6">
          {billInfoMatchError && (
            <div className="rounded-md bg-yellow-50 text-center p-2 mb-4 mx-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-yellow-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="text-sm text-yellow-600">
                  Either your access code or date of birth is incorrect. Please
                  try again.
                </div>
              </div>
            </div>
          )}
          <FormProvider {...methods}>
            <form
              className="space-y-2 px-2"
              onSubmit={handleSubmit(onBillLookupSubmit)}
            >
              <div>
                <label
                  htmlFor="accessCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Access Code
                </label>
                <div className="mt-1">
                  <input
                    id="accessCode"
                    type="text"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    {...register("accessCode")}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="dateOfBirth"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date of Birth
                </label>
                <div className="mt-1">
                  <DateInput
                    name="dateOfBirth"
                    control={control as any}
                    required
                  />
                </div>
              </div>
              <div>
                <SubmitButton>View Bill</SubmitButton>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export const UniversalPatientIdentification: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <PortalLayout>
      <div className="text-gray-900 flex flex-col">
        <PagePublicOrgHeader
          logoUrl={
            "https://s3.us-east-2.amazonaws.com/assets.pledge.health/pledge-logo.png"
          }
        />
        <UniversalPatientIdentificationForm />
      </div>
    </PortalLayout>
  );
};
