import React, { useState } from "react";
import { Layout } from "../../layout";
import { gql, useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import {
  GetPaymentRequestBatches,
  GetPaymentRequestBatchesVariables,
  GetPaymentRequestBatches_paymentRequestBatches as PaymentRequestBatch,
} from "../../../generated/GetPaymentRequestBatches";
import { Table, Tooltip } from "../../../components";
import { Td } from "../../../components/Table";
import {
  addDays,
  differenceInDays,
  differenceInHours,
  format,
  formatDistanceToNow,
  isAfter,
  isBefore,
  isFuture,
  isPast,
  parse,
  parseISO,
  subDays,
  subMonths,
} from "date-fns";
import {
  classNames,
  formatDateMMDDYYYY,
  formatPercentage,
  formatUSD,
  isDefined,
} from "../../../utils";
import { Link, useNavigate } from "react-router-dom";
import { PaymentRequestBatchStatus } from "../../../generated/globalTypes";
import {
  ClockIcon,
  CogIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import {
  GetAutopaySettings,
  GetAutopaySettingsVariables,
  GetAutopaySettings_location as Location,
} from "../../../generated/GetAutopaySettings";

export const GET_PAYMENT_REQUEST_BATCHES = gql`
  query GetPaymentRequestBatches($locationId: String!, $take: Int, $skip: Int) {
    paymentRequestBatches(
      where: { locationId: { equals: $locationId } }
      take: $take
      skip: $skip
      orderBy: { createdAt: desc }
    ) {
      id
      createdAt
      status
      failureReason
      totalAmount
      startedAt
      completedAt
      canceledAt
      scheduledAt
      createdBy {
        id
        firstName
        lastName
      }
      canceledBy {
        id
        firstName
        lastName
      }
      paymentRequests {
        id
        status
      }
    }
    aggregatePaymentRequestBatch(
      where: { locationId: { equals: $locationId } }
    ) {
      _count {
        id
      }
    }
  }
`;

export const PaymentRequestBatchStatusBadge: React.FC<
  React.PropsWithChildren<{
    status: PaymentRequestBatchStatus;
  }>
> = ({ status }) => {
  const statuses: { [K in PaymentRequestBatchStatus]: string } = {
    Scheduled: "text-slate-400 bg-slate-400/10",
    Running: "text-blue-400 bg-blue-400/10",
    Completed: "text-green-400 bg-green-400/10",
    Canceled: "text-rose-400 bg-rose-400/10",
    Failed: "text-rose-400 bg-rose-400/10",
  };

  return (
    <div className="flex items-center justify-end gap-x-2 sm:justify-start">
      <div
        className={classNames(statuses[status], "flex-none rounded-full p-1")}
      >
        <div className="h-1.5 w-1.5 rounded-full bg-current" />
      </div>
      <div>{status}</div>
    </div>
  );
};

const PAGE_SIZE = 15;

const PaymentRequestBatches: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const user = useUser()!;
  const navigate = useNavigate();
  const [currentPage, setPageNum] = useState(1);
  const onPageChange = (num: number) => {
    setPageNum(num);
  };
  const skip = (currentPage - 1) * PAGE_SIZE;

  const { loading, error, data } = useQuery<
    GetPaymentRequestBatches,
    GetPaymentRequestBatchesVariables
  >(GET_PAYMENT_REQUEST_BATCHES, {
    variables: {
      take: PAGE_SIZE,
      skip,
      locationId: user.activeLocation.id,
    },
  });
  if (loading) return <>Loading</>;
  if (!data) return null;
  const batches = data.paymentRequestBatches ?? [];
  const totalCount = data.aggregatePaymentRequestBatch?._count?.id ?? 0;
  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <Table
            columnDefs={[
              {
                header: "Date",
                cellFn: (batch: PaymentRequestBatch) => {
                  if (batch.scheduledAt && batch.status === "Scheduled") {
                    return (
                      <Td>
                        <div className="text-gray-900">
                          Scheduled{" "}
                          <Tooltip
                            content={
                              <>
                                {format(
                                  parseISO(batch.scheduledAt),
                                  "MM/dd/yyyy hh:mm a"
                                )}
                              </>
                            }
                            trigger={
                              <>
                                {formatDistanceToNow(
                                  parseISO(batch.scheduledAt),
                                  {
                                    addSuffix: true,
                                  }
                                )}
                              </>
                            }
                          />
                        </div>
                      </Td>
                    );
                  } else if (batch.startedAt) {
                    return (
                      <Td>
                        <div className="text-gray-900">
                          Started{" "}
                          <Tooltip
                            content={
                              <>
                                {format(
                                  parseISO(batch.startedAt),
                                  "MM/dd/yyyy hh:mm a"
                                )}
                              </>
                            }
                            trigger={
                              <>
                                {formatDistanceToNow(
                                  parseISO(batch.startedAt),
                                  {
                                    addSuffix: true,
                                  }
                                )}
                              </>
                            }
                          />
                        </div>
                      </Td>
                    );
                  } else if (batch.canceledAt) {
                    return (
                      <Td>
                        <div className="text-gray-900">
                          Canceled{" "}
                          <Tooltip
                            content={
                              <>
                                {format(
                                  parseISO(batch.canceledAt),
                                  "MM/dd/yyyy hh:mm a"
                                )}
                              </>
                            }
                            trigger={
                              <>
                                {formatDistanceToNow(
                                  parseISO(batch.canceledAt),
                                  {
                                    addSuffix: true,
                                  }
                                )}
                              </>
                            }
                          />
                        </div>
                      </Td>
                    );
                  }
                  return <Td></Td>;
                },
              },
              {
                header: "Status",
                cellFn: (batch: PaymentRequestBatch) => (
                  <Td>
                    <div className="text-gray-900">
                      <PaymentRequestBatchStatusBadge status={batch.status} />
                    </div>
                  </Td>
                ),
              },
              {
                header: "Created By",
                cellFn: (batch: PaymentRequestBatch) => (
                  <Td>
                    <div className="text-gray-900 flex items-center gap-1">
                      {batch.createdBy ? (
                        <>
                          {[
                            batch.createdBy.firstName,
                            batch.createdBy.lastName,
                          ].join(" ")}
                        </>
                      ) : (
                        <div className="text-sm text-gray-900 flex gap-1">
                          <CogIcon className="w-4" />
                          <span>Automated</span>
                        </div>
                      )}
                      <span className="text-sm text-gray-700">
                        on{" "}
                        <Tooltip
                          content={
                            <>
                              {format(
                                parseISO(batch.createdAt),
                                "MM/dd/yyyy hh:mm a"
                              )}
                            </>
                          }
                          trigger={<>{formatDateMMDDYYYY(batch.createdAt)}</>}
                        />
                      </span>
                    </div>
                  </Td>
                ),
              },
              {
                header: "Patients Enrolled",
                cellFn: (batch: PaymentRequestBatch) => (
                  <Td>
                    <div className="text-gray-900">
                      {batch.paymentRequests.length}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Total Balance",
                cellFn: (batch: PaymentRequestBatch) => (
                  <Td>
                    <div className="text-gray-900">
                      {isDefined(batch.totalAmount) &&
                        formatUSD(batch.totalAmount)}
                    </div>
                  </Td>
                ),
              },
            ]}
            rows={batches}
            onRowClick={(batch) => {
              navigate(`/billing/batches/${batch.id}`);
            }}
            pagination={{
              currentPage,
              totalCount,
              onPageChange,
              pageSize: PAGE_SIZE,
              siblingCount: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const GET_AUTOPAY_SETTINGS = gql`
  query GetAutopaySettings($locationId: String!) {
    location(where: { id: $locationId }) {
      id
      currentAutopayCycleCloseDate
      currentAutopayNotificationDatetime
      currentAutopaymentDatetime
    }
  }
`;

const currentProgress = ({
  cycleStartDate,
  cycleCloseDate,
  cycleNotificationDate,
  cycleChargeDate,
}: {
  cycleStartDate: Date;
  cycleCloseDate: Date;
  cycleNotificationDate: Date;
  cycleChargeDate: Date;
}) => {
  if (isBefore(new Date(), cycleCloseDate)) {
    const hoursSinceCycleStart = differenceInHours(new Date(), cycleStartDate);
    const cycleStartToCloseLength = differenceInHours(
      cycleCloseDate,
      cycleStartDate
    );
    // Normalize because first 1/3 of cycle is notification period
    return formatPercentage(
      (0.33 * hoursSinceCycleStart) / cycleStartToCloseLength
    );
  }
  if (isBefore(new Date(), cycleNotificationDate)) {
    const hoursSinceCycleClose = differenceInHours(new Date(), cycleCloseDate);
    const cycleCloseToNotificationLength = differenceInHours(
      cycleNotificationDate,
      cycleCloseDate
    );
    // Normalize because first 1/3 of cycle is notification period
    return formatPercentage(
      0.33 + (0.33 * hoursSinceCycleClose) / cycleCloseToNotificationLength
    );
  }
  if (isBefore(new Date(), cycleChargeDate)) {
    const hoursSinceCycleNotification = differenceInHours(
      new Date(),
      cycleNotificationDate
    );
    const cycleNotificationToChargeLength = differenceInHours(
      cycleChargeDate,
      cycleNotificationDate
    );
    // Normalize because first 1/3 of cycle is notification period
    return formatPercentage(
      0.66 +
        (0.33 * hoursSinceCycleNotification) / cycleNotificationToChargeLength
    );
  }
  return "100%";
};

export const AutopayCycleTimeline: React.FC<
  React.PropsWithChildren<{ location: Location }>
> = ({ location }) => {
  const currentAutopayCycleCloseDate = parseISO(
    location.currentAutopayCycleCloseDate
  );
  const autopayCycleStartDate = addDays(
    subMonths(currentAutopayCycleCloseDate, 1),
    1
  );
  const currentAutopayNotificationDatetime = parseISO(
    location.currentAutopayNotificationDatetime
  );
  const currentAutopaymentDatetime = parseISO(
    location.currentAutopaymentDatetime
  );

  const progress = currentProgress({
    cycleStartDate: autopayCycleStartDate,
    cycleCloseDate: currentAutopayCycleCloseDate,
    cycleNotificationDate: currentAutopayNotificationDatetime,
    cycleChargeDate: currentAutopaymentDatetime,
  });

  return (
    <div className="mt-2 w-full" aria-hidden="true">
      <div
        className="text-right text-sm font-medium text-gray-600 ml-5 py-1"
        style={{ width: progress }}
      >
        <Tooltip
          content={<>{format(new Date(), "MM/dd/yyyy")}</>}
          trigger={<>Today</>}
        />
      </div>
      <div className="overflow-hidden rounded-full bg-gray-200">
        <div
          className="h-2 rounded-full bg-indigo-600"
          style={{ width: progress }}
        />
      </div>
      <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
        <div
          className={classNames(
            "text-left",
            isPast(autopayCycleStartDate) ? "text-indigo-600" : ""
          )}
        >
          <div>{format(autopayCycleStartDate, "MM/dd/yyyy")}</div>
          <div>Cycle Started</div>
        </div>
        <div
          className={classNames(
            "text-center",
            isPast(currentAutopayCycleCloseDate) ? "text-indigo-600" : ""
          )}
        >
          <div>{format(currentAutopayCycleCloseDate, "MM/dd/yyyy")}</div>
          <div>
            Cycle {isPast(currentAutopayCycleCloseDate) ? "Ended" : "Ends"}
          </div>
        </div>
        <div
          className={classNames(
            "text-center",
            isPast(currentAutopayNotificationDatetime) ? "text-indigo-600" : ""
          )}
        >
          <div>
            {format(currentAutopayNotificationDatetime, "MM/dd/yyyy hh:mm a")}
          </div>
          <div>
            Autopay Notifications{" "}
            {isPast(currentAutopayNotificationDatetime) ? "Sent" : "to be Sent"}
          </div>
        </div>
        <div
          className={classNames(
            "text-right",
            isPast(currentAutopaymentDatetime) ? "text-indigo-600" : ""
          )}
        >
          <div>{format(currentAutopaymentDatetime, "MM/dd/yyyy hh:mm a")}</div>
          <div>
            Cards{" "}
            {isPast(currentAutopaymentDatetime) ? "Charged" : "to be Charged"}
          </div>
        </div>
      </div>
    </div>
  );
};

const AutopayAlert: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser()!;
  const { data } = useQuery<GetAutopaySettings, GetAutopaySettingsVariables>(
    GET_AUTOPAY_SETTINGS,
    {
      variables: {
        locationId: user.activeLocation.id,
      },
    }
  );

  return (
    <div className="rounded-md p-4 bg-white shadow mt-4">
      <div>
        <p className="text-sm font-medium text-gray-900 flex justify-between">
          <div>Monthly Autopay Cycles are enabled</div>
          {user.isAdmin && (
            <div>
              <Link
                to="/settings"
                className="whitespace-nowrap font-medium text-indigo-700 hover:text-indigo-600"
              >
                Details
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          )}
        </p>
        {data?.location && <AutopayCycleTimeline location={data.location} />}
      </div>
    </div>
  );
};

export const BillingBatches: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const user = useUser()!;
  return (
    <Layout
      header={
        <div className="flex flex-col">
          <h1 className="text-2xl font-semibold text-gray-900">Batches</h1>
        </div>
      }
      content={
        <div className="flex flex-col gap-2">
          {user.activeLocation.balanceAutopayEnabled && <AutopayAlert />}
          <PaymentRequestBatches />
        </div>
      }
    />
  );
};
