import { gql, useApolloClient, useQuery } from "@apollo/client";
import {
  endOfDay,
  format,
  formatRelative,
  isSameDay,
  parseISO,
  startOfDay,
} from "date-fns";
import React, { useState } from "react";

import { Disclosure } from "@headlessui/react";
import {
  ChevronUpIcon,
  CreditCardIcon,
  InformationCircleIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { IntegrationStatus, SubmitButton, Tooltip } from "../../../components";
import { OvalSpinner } from "../../../components/loading";
import { SlideOut } from "../../../components/slideout";
import { Card } from "../../../components/ui/card";
import {
  GetPatientCheckoutDetails,
  GetPatientCheckoutDetailsVariables,
  GetPatientCheckoutDetails_getPatientCheckoutDetails as PatientCheckoutDetails,
  GetPatientCheckoutDetails_getPatientCheckoutDetails_patient as Patient,
} from "../../../generated/GetPatientCheckoutDetails";
import {
  GetAllPastBills,
  GetAllPastBillsVariables,
  GetAllPastBills_getAllPastBills as Bill,
} from "../../../generated/GetAllPastBills";
import {
  BILL_CHARGE_FIELDS,
  ELIGIBILITY_REQUEST_STATUS_FIELD,
  INSURANCE_POLICY_SUMMARY_FIELDS,
  PATIENT_HEADER_FIELDS,
} from "../../../graphql";
import { useFeatureFlags } from "../../../hooks";
import {
  cn,
  formatRelativeDay,
  formatUSD,
  isDefined,
  sleep,
  toDate,
} from "../../../utils";
import { PaymentMethodDisplay } from "../../patients/payment-method-display";
import {
  AddPaymentMethodDialog,
  CreatePaymentDialog,
} from "../../patients/profile";
import {
  VisitBillStatusIndicator,
  getBillStatus,
  getVisitBillStatus,
} from "../../patients/visits";
import { VisitBillSummary } from "../../shared/visit-bill-display-card";

export const GET_PATIENT_CHECKOUT_DETAILS = gql`
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${PATIENT_HEADER_FIELDS}
  ${BILL_CHARGE_FIELDS}
  query GetPatientCheckoutDetails(
    $id: String!
    $startOfDay: DateTime!
    $endOfDay: DateTime!
  ) {
    getPatientCheckoutDetails(
      id: $id
      startOfDay: $startOfDay
      endOfDay: $endOfDay
    ) {
      patient {
        id
        newPatientBalance
        pastPatientPendingBalance(before: $startOfDay)
        patientStatementBalance {
          readyBalance
        }
        totalCredits
        ...PatientHeaderFields
        insurancePolicies(where: { deletedAt: { equals: null } }) {
          ...InsurancePolicySummaryFields
          mostRecentPriorEligibilityRequest {
            id
            createdAt
            automated
            requestedBy {
              id
              firstName
              lastName
            }
            accountCoverage {
              payer {
                id
                name
              }
              plan {
                id
                name
              }
            }
            ...EligibilityRequestStatusFields
          }
        }
        paymentMethods(where: { detatchedAt: { equals: null } }) {
          id
          detatchedAt
          default
          detatchedAt
          cardBrand
          walletType
          lastFour
          expirationMonth
          expirationYear
          funding
          type
          paymentIntents(take: 1, orderBy: { createdAt: desc }) {
            id
            lastPaymentError
          }
        }
      }
      # Today's appointments for the patient
      todaysAppointments {
        id
        start
        end
        provider {
          id
          displayName
        }
        account {
          id
          accountType {
            id
            name
          }
        }
        bill(where: { dateOfService: { gte: $startOfDay, lte: $endOfDay } }) {
          ...BillChargeFields
        }
        accumulatorAdjustments {
          id
          type
          insurancePolicy {
            id
            memberId
            payer {
              id
              name
            }
          }
          benefitAccumulatorAdjustments {
            id
            networkStatus
            coverageLevel
            providerServiceConfiguration {
              id
              name
            }
            appliedDeductible
            appliedOutOfPocket
            appliedVisits
          }
        }
      }
      upcomingAppointments {
        id
        start
        end
        provider {
          id
          displayName
        }
        account {
          id
          accountType {
            id
            name
          }
        }
      }
      bills {
        ...BillChargeFields
        appointment {
          id
          accumulatorAdjustments {
            id
            type
            insurancePolicy {
              id
              memberId
              payer {
                id
                name
              }
            }
            benefitAccumulatorAdjustments {
              id
              networkStatus
              coverageLevel
              providerServiceConfiguration {
                id
                name
              }
              appliedDeductible
              appliedOutOfPocket
              appliedVisits
            }
          }
        }
      }
      payments {
        id
        isPledgeRefund
        paymentIntent {
          id
          status
          autoPay
        }
        transaction {
          id
          transactedAt
          patientAmount
          description
          account {
            id
            accountType {
              id
              name
            }
          }
        }
      }
      totalBillCount
    }
  }
`;

export const GET_ALL_PAST_BILLS = gql`
  ${BILL_CHARGE_FIELDS}
  query GetAllPastBills($id: String!, $startOfDay: DateTime!) {
    getAllPastBills(id: $id, startOfDay: $startOfDay) {
      ...BillChargeFields
      appointment {
        id
        accumulatorAdjustments {
          id
          type
          insurancePolicy {
            id
            memberId
            payer {
              id
              name
            }
          }
          benefitAccumulatorAdjustments {
            id
            networkStatus
            coverageLevel
            providerServiceConfiguration {
              id
              name
            }
            appliedDeductible
            appliedOutOfPocket
            appliedVisits
          }
        }
      }
    }
  }
`;

export const CheckoutSlideoutTitle: React.FC<{
  patient: Patient;
}> = ({ patient }) => {
  return (
    <div className="flex flex-col">
      <div className="flex gap-1">
        <Link
          to={`/patients/${patient.id}`}
          className="hover:text-gray-500 text-2xl"
        >
          {patient.displayName}
        </Link>
        {patient.integrationLinks.length > 0 && (
          <IntegrationStatus
            patientId={patient.id}
            integrationLinks={patient.integrationLinks}
            refetchQueries={[GET_PATIENT_CHECKOUT_DETAILS]}
          />
        )}
      </div>
      <div className="flex gap-2">
        <div className="flex items-center space-x-1">
          <span className="text-sm font-semibold text-gray-500">DOB</span>{" "}
          <span className="text-sm text-gray-900">
            {patient.dateOfBirth
              ? format(parseISO(toDate(patient.dateOfBirth)), "MM/dd/yyyy")
              : "N/A"}
          </span>
        </div>
        <div className="flex items-center space-x-1">
          <span className="text-sm font-semibold text-gray-500">Sex</span>{" "}
          <span className="text-sm text-gray-900">
            {patient.birthSex ?? "N/A"}
          </span>
        </div>
      </div>
    </div>
  );
};

export const CheckoutSlideoutBody: React.FC<{
  data: PatientCheckoutDetails;
  allBills: Bill[];
  allBillsLoading: boolean;
  start: Date;
  end: Date;
}> = ({ data, start, end, allBills, allBillsLoading }) => {
  const flags = useFeatureFlags();
  const apollo = useApolloClient();
  const [addPaymentMethodDialogOpen, setAddPaymentMethodDialogOpen] =
    useState(false);
  const [createPaymentDialogOpen, setCreatePaymentDialogOpen] = useState(false);
  const [showMorePastBills, setShowMorePastBills] = useState(false);

  const patient = data.patient!;
  const todaysAppointments = data.todaysAppointments!;
  const upcomingAppointments = data.upcomingAppointments!;
  const pastPatientPendingBalance =
    data.patient?.pastPatientPendingBalance ?? 0;
  // TODO: This should really be the *past* ready balance
  const patientReadyBalance = -(
    data.patient?.patientStatementBalance?.readyBalance || 0
  );
  const todaysAppointmentsSet = new Set(todaysAppointments.map((a) => a.id));
  const paymentMethods = patient.paymentMethods;

  const pastBills = allBillsLoading
    ? data.bills!.filter((bill) => {
        const fromToday =
          !!bill.appointment && todaysAppointmentsSet.has(bill.appointment.id);
        return !fromToday;
      })
    : allBills;

  const todaysResponsibility = todaysAppointments.reduce((acc, appt) => {
    const bill = appt.bill.at(0);
    if (!bill) return acc;
    return acc + bill.toCollect.patientResponsibility;
  }, 0);

  const payments = data.payments!;

  const paidToday = todaysAppointments.reduce((acc, appt) => {
    const bill = appt.bill.at(0);
    if (!bill) return acc;
    return acc + bill.toCollect.patientPaid;
  }, 0);

  const credits = data.patient?.totalCredits ?? 0;

  const todaysBalance =
    todaysResponsibility +
    (pastPatientPendingBalance + patientReadyBalance) -
    paidToday -
    credits;

  const visiblePastBills = showMorePastBills
    ? pastBills
    : pastBills.slice(0, 3);

  return (
    <>
      <div className="flex flex-1 flex-col space-y-2 overflow-y-auto">
        {flags.estimatesEnabled && (
          <Card>
            <div className="p-2">
              <div className="grid grid-cols-4">
                <div className="flex flex-col items-center relative">
                  <div className="text-sm font-semibold text-gray-500">
                    {formatRelativeDay(start)}'s Charge
                  </div>
                  <div className="font-semibold text-lg">
                    {formatUSD(todaysResponsibility)}
                  </div>
                  <div className="absolute right-0 top-[50%] translate-y-[-50%]">
                    +
                  </div>
                </div>
                <div className="flex flex-col items-center relative">
                  <div className="text-sm font-semibold text-gray-500 flex items-center gap-1">
                    Past Collectable Balance
                    <Tooltip
                      trigger={<InformationCircleIcon className="w-4 h-4" />}
                      content={
                        <div className="max-w-[300px]">
                          The sum of the patient's estimated pending and ready
                          balances for past bills.
                        </div>
                      }
                    />
                  </div>
                  <div className="font-semibold text-lg">
                    {formatUSD(patientReadyBalance + pastPatientPendingBalance)}
                  </div>
                  <div className="absolute right-0 top-[50%] translate-y-[-50%]">
                    -
                  </div>
                </div>
                <div className="flex flex-col items-center relative">
                  <div className="text-sm font-semibold text-gray-500">
                    Applied Payments
                  </div>
                  <div className="font-semibold text-lg">
                    {formatUSD(paidToday)}
                  </div>
                  <div className="absolute right-0 top-[50%] translate-y-[-50%]">
                    =
                  </div>
                </div>
                <div className="flex flex-col items-center relative">
                  <div className="text-sm font-semibold text-gray-500">
                    Currently Owes
                  </div>
                  <div className="font-semibold text-lg flex flex-col items-center">
                    <div>{formatUSD(todaysBalance)}</div>
                    <span className="text-xs text-yellow-500">
                      {credits > 0
                        ? "(" + formatUSD(credits) + " credit applied)"
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )}

        <Card>
          <div className="p-2 flex flex-col gap-2">
            <div className="flex justify-between items-center pb-2 border-b">
              <dt className="font-medium text-gray-900">
                {formatRelativeDay(start)}'s Visits
              </dt>
            </div>

            <div className="flex flex-col divide-y gap-2 text-sm">
              {todaysAppointments.map((appointment, i) => {
                const billStatus = getVisitBillStatus(appointment);
                const bill = appointment.bill.at(0);

                if (!bill) {
                  return (
                    <div
                      className={cn(
                        "w-full flex justify-between gap-2",
                        i !== 0 && "pt-2"
                      )}
                    >
                      <div className="grow flex justify-between gap-2">
                        <div className="[&:first-letter]:capitalize grow text-left">
                          {formatRelative(
                            parseISO(appointment.start),
                            new Date()
                          )}{" "}
                          with {appointment.provider?.displayName}
                        </div>

                        {flags.chargesSupported && (
                          <VisitBillStatusIndicator billStatus={billStatus} />
                        )}

                        <div className="text-right min-w-[4rem]">
                          <span className="text-gray-500 italic font-normal">
                            Unestimated
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }

                const estimated = isDefined(
                  bill.toCollect.visitCollectionRequest
                );

                return (
                  <Disclosure as="div">
                    <Disclosure.Button
                      className={cn(
                        "w-full flex justify-between gap-2",
                        i !== 0 && "pt-2"
                      )}
                    >
                      {({ open }) => (
                        <>
                          <div className="grow flex justify-between gap-2">
                            <div className="[&:first-letter]:capitalize grow text-left">
                              {formatRelative(
                                parseISO(appointment.start),
                                new Date()
                              )}{" "}
                              with {appointment.provider?.displayName}
                            </div>

                            {flags.chargesSupported && (
                              <VisitBillStatusIndicator
                                billStatus={billStatus}
                              />
                            )}

                            <div className="text-right min-w-[4rem] flex justify-end items-center gap-1">
                              {estimated && (
                                <Tooltip
                                  trigger={
                                    <LightningBoltIcon className="w-4 h-4 text-gray-500" />
                                  }
                                  content={<>Estimated amount</>}
                                />
                              )}
                              <span className="font-medium">
                                {formatUSD(bill.toCollect.patientBalance)}
                              </span>
                            </div>
                          </div>
                          <ChevronUpIcon
                            className={cn(
                              "h-6 w-6 text-gray-500 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                              open && "rotate-180"
                            )}
                          />
                        </>
                      )}
                    </Disclosure.Button>
                    <Disclosure.Panel className="py-2">
                      <div className="py-1 px-2 border rounded-md">
                        <VisitBillSummary
                          appointment={appointment}
                          bill={bill}
                        />
                      </div>
                    </Disclosure.Panel>
                  </Disclosure>
                );
              })}
            </div>
          </div>
        </Card>

        <Card>
          <div className="p-2 flex flex-col gap-2">
            <div className="flex justify-between items-center pb-2 border-b">
              <dt className="font-medium text-gray-900">Past Visits</dt>
            </div>

            <div className="flex flex-col divide-y gap-2 text-sm">
              {visiblePastBills.length === 0 && (
                <div className="text-gray-500 italic font-normal">
                  No past visits
                </div>
              )}
              {visiblePastBills.map((bill, i) => {
                const billStatus = getBillStatus(bill);
                const estimated = isDefined(
                  bill.toCollect.visitCollectionRequest
                );
                return (
                  <Disclosure as="div">
                    <Disclosure.Button
                      className={cn(
                        "w-full flex justify-between gap-2",
                        i !== 0 && "pt-2"
                      )}
                    >
                      {({ open }) => (
                        <>
                          <div className="grow flex justify-between gap-2">
                            <div className="[&:first-letter]:capitalize grow text-left">
                              {formatRelative(
                                parseISO(bill.dateOfService),
                                new Date()
                              )}{" "}
                              with {bill.primaryProvider?.displayName}
                            </div>

                            {flags.chargesSupported && (
                              <VisitBillStatusIndicator
                                billStatus={billStatus}
                              />
                            )}

                            <div className="text-right min-w-[4rem] flex justify-end items-center gap-1">
                              {estimated && (
                                <Tooltip
                                  trigger={
                                    <LightningBoltIcon className="w-4 h-4 text-gray-500" />
                                  }
                                  content={<>Estimated amount</>}
                                />
                              )}
                              <span className="font-medium">
                                {formatUSD(bill.toCollect.patientBalance)}
                              </span>
                            </div>
                          </div>
                          <ChevronUpIcon
                            className={cn(
                              "h-6 w-6 text-gray-500 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                              open && "rotate-180"
                            )}
                          />
                        </>
                      )}
                    </Disclosure.Button>
                    <Disclosure.Panel className="py-2">
                      <div className="py-1 px-2 border rounded-md">
                        <VisitBillSummary
                          appointment={bill.appointment}
                          bill={bill}
                        />
                      </div>
                    </Disclosure.Panel>
                  </Disclosure>
                );
              })}
              {data.totalBillCount > 3 && (
                <div>
                  {allBillsLoading && showMorePastBills ? (
                    <div className="flex justify-center items-center mt-1">
                      <OvalSpinner className="text-indigo-300 h-8 w-8" />
                    </div>
                  ) : (
                    <button
                      className="text-indigo-600 hover:text-indigo-500 w-full rounded-md mt-1 py-1 hover:bg-gray-50"
                      onClick={() => setShowMorePastBills(!showMorePastBills)}
                    >
                      {showMorePastBills ? "Show less" : "Show more"}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </Card>

        <Card>
          <div className="p-2 flex flex-col gap-2">
            <div className="flex justify-between items-center pb-2 border-b">
              <dt className="font-medium text-gray-900">
                Upcoming Appointments
              </dt>
            </div>

            <div className="flex flex-col divide-y gap-2 text-sm">
              {upcomingAppointments.length === 0 && (
                <div className="text-gray-500 italic font-normal">
                  No upcoming appointments
                </div>
              )}
              {upcomingAppointments.map((appointment, i) => {
                return (
                  <div
                    className={cn(
                      "w-full flex justify-between gap-2",
                      i !== 0 && "pt-2"
                    )}
                  >
                    <div className="[&:first-letter]:capitalize">
                      {formatRelative(parseISO(appointment.start), new Date())}{" "}
                      with {appointment.provider?.displayName}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Card>
        <Card>
          <div className="p-2 flex flex-col gap-2">
            <div className="flex justify-between items-center pb-2 border-b">
              <dt className="font-medium text-gray-900">Recent Payments</dt>
            </div>

            <div className="flex flex-col divide-y gap-2 text-sm">
              {payments.length === 0 && (
                <div className="text-gray-500 italic font-normal">
                  No past payments
                </div>
              )}
              {payments.map((payment, i) => {
                const sameDay = isSameDay(
                  parseISO(payment.transaction.transactedAt),
                  start
                );
                return (
                  <div
                    className={cn(
                      "w-full flex justify-between gap-2",
                      sameDay && "font-semibold",
                      i !== 0 && "pt-2"
                    )}
                  >
                    <div className="[&:first-letter]:capitalize">
                      {formatRelative(
                        parseISO(payment.transaction.transactedAt),
                        new Date()
                      )}{" "}
                      {payment.transaction.description}
                    </div>
                    <div>{formatUSD(payment.transaction.patientAmount)}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </Card>
        {flags.stripeAccountActive && (
          <Card>
            <div className="p-2 flex flex-col gap-2">
              <div className="flex justify-between items-center pb-2 border-b">
                <dt className="font-medium text-gray-900">Payments Methods</dt>

                <button
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  onClick={() => setAddPaymentMethodDialogOpen(true)}
                >
                  Add
                </button>
              </div>

              <div className="flex flex-col divide-y gap-2 text-sm">
                {paymentMethods.length === 0 && (
                  <div className="text-gray-500 italic font-normal">None</div>
                )}
                {paymentMethods.map((paymentMethod, i) => {
                  return (
                    <div
                      className={cn(
                        "w-full flex justify-between gap-2",
                        i !== 0 && "pt-2"
                      )}
                    >
                      <div className="w-full">
                        <PaymentMethodDisplay
                          paymentMethod={paymentMethod}
                          lastPaymentError={
                            paymentMethod.paymentIntents.at(0)?.lastPaymentError
                          }
                          refetchQueries={[GET_PATIENT_CHECKOUT_DETAILS]}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Card>
        )}
      </div>
      <div className="p-4 border-t">
        {flags.stripeAccountActive && paymentMethods.length > 0 ? (
          <SubmitButton
            type="button"
            onClick={() => {
              setCreatePaymentDialogOpen(true);
            }}
            className="w-full items-center bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-md font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center disabled:bg-opacity-50 disabled:cursor-not-allowed"
          >
            <CreditCardIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            Charge Card
          </SubmitButton>
        ) : (
          <SubmitButton type="button" disabled={true}>
            <Tooltip
              trigger={<div className="w-full">Charge Card</div>}
              content={<>Patient must have a saved payment method</>}
            />
          </SubmitButton>
        )}
      </div>
      {addPaymentMethodDialogOpen && (
        <AddPaymentMethodDialog
          open={addPaymentMethodDialogOpen}
          setOpen={setAddPaymentMethodDialogOpen}
          patientId={patient.id}
          onSuccessfulSetup={async () => {
            // FIXME: Hack for dealing with async payment method creation
            await sleep(2000);
            await apollo.refetchQueries({
              include: [GET_PATIENT_CHECKOUT_DETAILS],
            });
          }}
        />
      )}
      {createPaymentDialogOpen && (
        <CreatePaymentDialog
          open={createPaymentDialogOpen}
          setOpen={setCreatePaymentDialogOpen}
          patient={patient}
          referenceDate={start}
        />
      )}
    </>
  );
};

export const CheckoutSlideout: React.FC<{
  patientId: string;
  appointmentStart: Date;
  onClose: () => void;
}> = ({ patientId, appointmentStart, onClose }) => {
  const start = startOfDay(appointmentStart);
  const end = endOfDay(appointmentStart);
  const { data, loading } = useQuery<
    GetPatientCheckoutDetails,
    GetPatientCheckoutDetailsVariables
  >(GET_PATIENT_CHECKOUT_DETAILS, {
    variables: {
      id: patientId,
      startOfDay: start,
      endOfDay: end,
    },
  });

  const { data: allBills, loading: allBillsLoading } = useQuery<
    GetAllPastBills,
    GetAllPastBillsVariables
  >(GET_ALL_PAST_BILLS, {
    variables: {
      id: patientId,
      startOfDay: start,
    },
  });

  const isLoading = !data || loading;

  const checkoutDetails = data?.getPatientCheckoutDetails;

  return (
    <SlideOut
      open={true}
      onClose={onClose}
      title={
        isLoading ? (
          <div className="h-16 w-96 animate-pulse rounded-md bg-slate-100" />
        ) : (
          <CheckoutSlideoutTitle patient={checkoutDetails!.patient} />
        )
      }
    >
      <div className="flex flex-col h-full justify-between max-h-[calc(100vh-6.5em)]">
        {isLoading ? (
          <div className="flex flex-col gap-4">
            <div className="h-32 animate-pulse rounded-md bg-slate-100" />
            <div className="h-32 animate-pulse rounded-md bg-slate-100" />
            <div className="h-32 animate-pulse rounded-md bg-slate-100" />
            <div className="h-32 animate-pulse rounded-md bg-slate-100" />
          </div>
        ) : (
          <CheckoutSlideoutBody
            start={start}
            end={end}
            data={checkoutDetails!}
            allBills={allBills?.getAllPastBills || []}
            allBillsLoading={allBillsLoading}
          />
        )}
      </div>
    </SlideOut>
  );
};
