import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import { useUser } from "../../user-context";
import {
  Provider,
  Provider_provider,
  ProviderVariables,
} from "../../generated/Provider";
import {
  UpdateProvider,
  UpdateProviderVariables,
} from "../../generated/UpdateProvider";
import { ProviderForm } from "./providerForm";
import { Card } from "../../components";
import { Layout } from "../layout";
import { ProviderType } from "../../generated/globalTypes";

const UPDATE_PROVIDER = gql`
  mutation UpdateProvider($id: String!, $data: ProviderUpdateInput!) {
    updateOneProvider(where: { id: $id }, data: $data) {
      id
      type
      firstName
      lastName
      organizationName
      npi
    }
  }
`;

const UpdateProviderForm: React.FC<{
  provider: Provider_provider;
  payers: { id: string; name: string }[];
}> = ({ provider, payers }) => {
  const navigate = useNavigate();
  const user = useUser()!;
  const [updateProvider, result] = useMutation<
    UpdateProvider,
    UpdateProviderVariables
  >(UPDATE_PROVIDER);

  const inNetworkPayers = provider.providerNetworkParticipations
    .filter((p) => p.inNetwork)
    .map((p) => p.payerId);
  const outNetworkPayers = provider.providerNetworkParticipations
    .filter((p) => !p.inNetwork)
    .map((p) => p.payerId);
  const defaultValues = {
    type: provider.type,
    firstName: provider.firstName,
    lastName: provider.lastName,
    organizationName: provider.organizationName,
    npi: provider.npi,
    organizationId: user.organization.id,
    locationId: user.activeLocation.id,
    inNetworkPayers,
    outNetworkPayers,
  };

  const onSubmit = async (data: ProviderForm) => {
    const allPayers = [...data.inNetworkPayers, ...data.outNetworkPayers];
    const toDelete = provider.providerNetworkParticipations.filter(
      (p) => !allPayers.includes(p.payerId)
    );
    const toUpdate = provider.providerNetworkParticipations.filter((p) =>
      allPayers.includes(p.payerId)
    );
    const toCreate = allPayers.filter(
      (p) =>
        !provider.providerNetworkParticipations
          .map((p) => p.payerId)
          .includes(p)
    );

    await updateProvider({
      variables: {
        id: provider.id,
        data: {
          type: { set: data.type as ProviderType },
          firstName: { set: data.firstName },
          lastName: { set: data.lastName },
          organizationName: { set: data.organizationName },
          npi: { set: data.npi },
          providerNetworkParticipations: {
            delete: toDelete.map((p) => ({ id: p.id })),
            update: toUpdate.map((p) => ({
              where: { id: p.id },
              data: {
                inNetwork: { set: data.inNetworkPayers.includes(p.payerId) },
              },
            })),
            create: toCreate.map((p) => ({
              inNetwork: data.inNetworkPayers.includes(p),
              payer: {
                connect: { id: p },
              },
            })),
          },
        },
      },
    });
    toast.success("Provider Updated");
    navigate("/providers");
  };

  return (
    <ProviderForm
      loading={result.loading}
      submit={onSubmit}
      submitText="Update Provider"
      defaultValues={defaultValues}
      payers={payers}
    />
  );
};

const GET_PROVIDER = gql`
  query Provider($id: String!) {
    provider(where: { id: $id }) {
      id
      type
      firstName
      lastName
      organizationName
      npi
      providerNetworkParticipations {
        id
        inNetwork
        payerId
      }
    }
  }
`;

export const GET_PROVIDER_FORM_OPTIONS = gql`
  query GetProviderFormOptions($locationId: String!) {
    payers(
      where: { locationId: { equals: $locationId } }
      orderBy: { name: asc }
    ) {
      id
      name
    }
  }
`;

export const EditProvider: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser()!;
  const { providerId } = useParams<{ providerId: string }>();
  const { data, loading } = useQuery<Provider, ProviderVariables>(
    GET_PROVIDER,
    {
      variables: { id: providerId! },
    }
  );

  const optionsResult = useQuery(GET_PROVIDER_FORM_OPTIONS, {
    variables: { locationId: user.activeLocation.id },
  });

  const payers = optionsResult.data?.payers || [];

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Edit Provider
          </h1>
        </div>
      }
      content={
        <div className="py-4">
          <Card className="rounded-lg">
            <div className="px-4 py-5 sm:p-6 w-full">
              {!loading && data && (
                <UpdateProviderForm provider={data.provider!} payers={payers} />
              )}
            </div>
          </Card>
        </div>
      }
    />
  );
};
