import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { PatientLedgerRow } from ".";
import { useUser } from "../../../user-context";
import { DataTableFacetedFilter } from "../../../components/ui/table-helpers/data-table-faceted-filter";
import { Button } from "../../../components/ui/button";
import { TransactionType } from "../../../generated/globalTypes";

interface DataTableToolbarProps<TData extends { id: string }> {
  table: Table<TData>;
}

const TRANSACTION_TYPE_OPTIONS = [
  {
    value: TransactionType.Payment,
    label: "Payment",
  },
  {
    value: TransactionType.Charge,
    label: "Charge",
  },
  {
    value: TransactionType.Adjustment,
    label: "Adjustment",
  },
  {
    value: "Visit",
    label: "Visit",
  },
];

const YES_NO_OPTIONS = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

export function DataTableToolbar({
  table,
}: DataTableToolbarProps<PatientLedgerRow>) {
  const user = useUser()!;
  const isFiltered = table.getState().columnFilters.length > 0;

  const rows = table.getFilteredRowModel().rows;
  const accountTypeOptions = Array.from(
    new Set(rows.map((row) => row.original.accountType))
  ).map((accountType) => ({
    value: accountType,
    label: accountType,
  }));

  const externalBalanceShown = table
    .getColumn("externalBalance")
    ?.getIsVisible();

  return (
    <div className="flex items-start justify-between gap-2">
      <div className="flex flex-1 items-center flex-wrap gap-2">
        {table.getColumn("type") && (
          <DataTableFacetedFilter
            column={table.getColumn("type")}
            title="Type"
            options={TRANSACTION_TYPE_OPTIONS}
          />
        )}
        {table.getColumn("accountType") && (
          <DataTableFacetedFilter
            column={table.getColumn("accountType")}
            title="Account"
            options={accountTypeOptions}
          />
        )}
        {table.getColumn("pledgePayment") && (
          <DataTableFacetedFilter
            column={table.getColumn("pledgePayment")}
            title="Pledge Payment"
            options={YES_NO_OPTIONS}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <div className="flex items-start gap-2">
        <Button
          variant="outline"
          size="sm"
          className="h-8"
          onClick={() => {
            table
              .getColumn("externalBalance")
              ?.toggleVisibility(!externalBalanceShown);
          }}
        >
          {externalBalanceShown ? "Hide" : "Show"} {user.externalLedgerName}{" "}
          Balance
        </Button>
      </div>
    </div>
  );
}
