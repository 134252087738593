import { gql, useMutation, useQuery } from "@apollo/client";
import { Layout, LoadingLayout } from "../../layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card } from "../../../components/ui/card";
import {
  GetChargeTemplateDetails,
  GetChargeTemplateDetailsVariables,
  GetChargeTemplateDetails_chargeTemplate as ChargeTemplate,
} from "../../../generated/GetChargeTemplateDetails";
import { BarChart, NumberInput, TextInput } from "@tremor/react";
import { useFieldArray, useForm } from "react-hook-form";
import { v4 as uuidV4 } from "uuid";
import { SubmitButton } from "../../../components";
import {
  UpsertChargeTemplate,
  UpsertChargeTemplateVariables,
} from "../../../generated/UpsertChargeTemplate";
import { toast } from "react-toastify";
import { useUser } from "../../../user-context";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { parseISO } from "date-fns";
import { DataTable } from "./table";
import { columns } from "./columns";
import { mapNullable } from "../../../utils";
import { UPSERT_CHARGE_TEMPLATE } from ".";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "../../../components/ui/form";
import { ChargemasterGroupCombobox } from "../../rules/rule-inputs";
import { GET_LOCATION_CHARGEMASTER_GROUPS } from "../../appointments/table/estimate-dialog";
import {
  GetLocationChargemasterGroups,
  GetLocationChargemasterGroups_chargemasterGroupsByLastUsed_chargemasterGroup as ChargemasterGroup,
} from "../../../generated/GetLocationChargemasterGroups";
import {
  GetAppointmentsMatchingTemplates,
  GetAppointmentsMatchingTemplatesVariables,
} from "../../../generated/GetAppointmentsMatchingTemplates";

const GET_CHARGE_TEMPLATE_DETAILS = gql`
  query GetChargeTemplateDetails($id: String!) {
    chargeTemplate(where: { id: $id }) {
      id
      updatedAt
      name
      chargeTemplateCharges(orderBy: { priority: asc }) {
        id
        units
        chargemasterGroup {
          id
          code
          modifier1
          modifier2
        }
      }
    }
    chargeTemplateUsageStats(chargeTemplateId: $id) {
      month
      total
    }
  }
`;

const GET_APPOINTMENTS_MATCHING_TEMPLATES = gql`
  query GetAppointmentsMatchingTemplates($chargeTemplateId: String!) {
    appointmentsMatchingTemplate(chargeTemplateId: $chargeTemplateId) {
      id
      start
      benefitStatus
      appointmentLabelings {
        id
        appointmentLabel {
          id
          name
        }
      }
      account {
        id
        accountType {
          id
          name
        }
        patient {
          id
          displayName
        }
      }
      provider {
        id
        displayName
        providerTaxonomyCode {
          id
          displayName
          code
        }
      }
      insurancePolicies {
        id
        payer {
          id
          name
        }
      }
      bill {
        id
        charges {
          id
          customCode
          units
          chargemaster {
            id
            code
            description
            modifier1
            modifier2
            modifier3
            modifier4
            chargemasterGroupId
          }
        }
        activeEstimate {
          id
          chargeTemplate {
            id
            name
          }
        }
      }
    }
  }
`;

const MatchingAppointmentsTable = ({
  chargeTemplateId,
}: {
  chargeTemplateId: string;
}) => {
  const { data, loading } = useQuery<
    GetAppointmentsMatchingTemplates,
    GetAppointmentsMatchingTemplatesVariables
  >(GET_APPOINTMENTS_MATCHING_TEMPLATES, {
    variables: { chargeTemplateId },
  });

  const appointments = data?.appointmentsMatchingTemplate ?? [];

  return (
    <DataTable
      data={[...appointments].map((appointment) => {
        const activeEstimate = appointment.bill.at(0)?.activeEstimate;
        const charges = appointment.bill.flatMap((b) => b.charges);
        return {
          id: appointment.id,
          appointment,
          start: mapNullable(parseISO)(appointment.start),
          patientName: appointment.account.patient.displayName,
          providerName: appointment.provider?.displayName ?? null,
          accountType: appointment.account.accountType?.name ?? null,
          charges,
          codes: charges.map((c) => c.customCode ?? c.chargemaster?.code),
          providerTaxonomyCode:
            appointment.provider?.providerTaxonomyCode?.displayName ?? null,
          insurancePolicies: appointment.insurancePolicies.map(
            (ip) => ip.payer.name
          ),
          chargeTemplate: activeEstimate
            ? activeEstimate.chargeTemplate?.name ?? null
            : "Unestimated",
          match: activeEstimate?.chargeTemplate?.id === chargeTemplateId,
          appointmentLabels: appointment.appointmentLabelings.map(
            (labeling) => labeling.appointmentLabel.name
          ),
        };
      })}
      columns={columns}
      loading={loading}
    />
  );
};

const ChargeTemplateSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  chargeTemplateCharges: z.array(
    z.object({
      id: z.string().nullable(),
      chargemasterGroup: z
        .object({
          id: z.string(),
        })
        .nullable(),
      units: z.string(),
    })
  ),
});

export const ChargeTemplateForm: React.FC<{
  chargeTemplate: ChargeTemplate | null;
  chargemasterGroups: ChargemasterGroup[];
  onSuccess?: () => void;
}> = ({ chargeTemplate, chargemasterGroups, onSuccess }) => {
  const user = useUser()!;
  const navigate = useNavigate();

  const [upsertChargeTemplate, upsertChargeTemplateResult] = useMutation<
    UpsertChargeTemplate,
    UpsertChargeTemplateVariables
  >(UPSERT_CHARGE_TEMPLATE);

  const form = useForm<z.infer<typeof ChargeTemplateSchema>>({
    resolver: zodResolver(ChargeTemplateSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      id: chargeTemplate?.id ?? uuidV4(),
      name: chargeTemplate?.name,
      chargeTemplateCharges: chargeTemplate?.chargeTemplateCharges.map(
        (ctc) => ({
          id: ctc.id,
          chargemasterGroup: {
            id: ctc.chargemasterGroup.id,
          },
          units: ctc.units.toString(),
        })
      ) ?? [
        {
          id: uuidV4(),
          chargemasterGroup: undefined,
          units: "1",
        },
      ],
    },
  });
  const chargeTemplateChargesField = useFieldArray({
    name: "chargeTemplateCharges",
    control: form.control,
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          const toDelete = chargeTemplate?.chargeTemplateCharges.filter(
            (ctc) =>
              !data.chargeTemplateCharges.find((ctc2) => ctc.id === ctc2.id)
          );

          upsertChargeTemplate({
            variables: {
              id: data.id,
              create: {
                id: data.id,
                name: data.name,
                location: { connect: { id: user.activeLocation.id } },
                chargeTemplateCharges: {
                  create: data.chargeTemplateCharges.map((ctc, i) => ({
                    id: ctc.id,
                    chargemasterGroup: {
                      connect: { id: ctc.chargemasterGroup!.id },
                    },
                    units: Number.parseInt(ctc.units),
                    priority: i,
                  })),
                },
              },
              update: {
                name: { set: data.name },
                chargeTemplateCharges: {
                  upsert: data.chargeTemplateCharges.map((ctc, i) => ({
                    where: { id: ctc.id },
                    create: {
                      id: ctc.id,
                      chargemasterGroup: {
                        connect: { id: ctc.chargemasterGroup!.id },
                      },
                      units: Number.parseInt(ctc.units),
                      priority: i,
                    },
                    update: {
                      chargemasterGroup: {
                        connect: { id: ctc.chargemasterGroup!.id },
                      },
                      units: { set: Number.parseInt(ctc.units) },
                      priority: { set: i },
                    },
                  })),
                  delete: toDelete?.map((ctc) => ({ id: ctc.id })),
                },
              },
            },
            onCompleted: () => {
              toast.success("Charge template saved");
              onSuccess?.();
              navigate("/chargemaster/templates");
            },
            onError: () => {
              toast.error("Failed to save charge template");
            },
          });
        })}
      >
        <div className="flex flex-col gap-8 p-2 w-full">
          <div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-4">
                <div className="w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <TextInput {...form.register("name")} required />
                </div>
              </div>
              <ul
                role="list"
                className="py-1 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6 w-full"
              >
                {chargeTemplateChargesField.fields.map((field, i) => (
                  <li
                    className="flex justify-between gap-x-6 py-2"
                    key={field.id}
                  >
                    <input
                      type="hidden"
                      {...form.register(`chargeTemplateCharges.${i}.id`)}
                    />
                    <div className="flex flex-col grow">
                      <label
                        htmlFor={`chargeTemplateCharges.${i}.code`}
                        className="block text-sm font-medium leading-6 text-gray-700"
                      >
                        Code
                      </label>
                      <div className="flex gap-1 items-center">
                        <ChargemasterGroupCombobox
                          chargemasterGroups={chargemasterGroups}
                          key={form.getValues(
                            `chargeTemplateCharges.${i}.chargemasterGroup.id`
                          )}
                          value={form.watch(
                            `chargeTemplateCharges.${i}.chargemasterGroup.id`
                          )}
                          onSelect={(chargemasterGroupIds) => {
                            const chargemasterGroup = chargemasterGroupIds[0];
                            form.setValue(
                              `chargeTemplateCharges.${i}.chargemasterGroup.id`,
                              chargemasterGroup,
                              {
                                shouldTouch: true,
                                shouldDirty: true,
                                shouldValidate: true,
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor={`chargeTemplateCharges.${i}.units`}
                        className="block text-sm font-medium leading-6 text-gray-700"
                      >
                        Units
                      </label>
                      <div className="font-medium text-gray-900">
                        <NumberInput
                          min={1}
                          placeholder="Number of units"
                          required
                          {...form.register(`chargeTemplateCharges.${i}.units`)}
                        />
                      </div>
                    </div>

                    <div className="flex gap-2 mt-6">
                      <div className="flex gap-2">
                        <button
                          type="button"
                          onClick={() => {
                            chargeTemplateChargesField.swap(i, i - 1);
                          }}
                          disabled={i === 0}
                          className="disabled:cursor-not-allowed disabled:opacity-50"
                        >
                          <ArrowUpIcon className="h-4 w-4 text-gray-500 hover:text-gray-900" />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            chargeTemplateChargesField.swap(i, i + 1);
                          }}
                          disabled={
                            i === chargeTemplateChargesField.fields.length - 1
                          }
                          className="disabled:cursor-not-allowed disabled:opacity-50"
                        >
                          <ArrowDownIcon className="h-4 w-4 text-gray-500 hover:text-gray-900" />
                        </button>
                      </div>

                      <button
                        type="button"
                        className="text-red-400 disabled:cursor-not-allowed disabled:opacity-50"
                        onClick={() => {
                          chargeTemplateChargesField.remove(i);
                        }}
                        disabled={
                          chargeTemplateChargesField.fields.length === 1
                        }
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>

              <div className="flex border-t border-gray-100 pt-6">
                <button
                  type="button"
                  onClick={() => {
                    chargeTemplateChargesField.append({
                      id: uuidV4(),
                      chargemasterGroup: undefined,
                      units: "1",
                    });
                  }}
                  className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                >
                  <span aria-hidden="true">+</span> Add another charge
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div>
            <SubmitButton loading={upsertChargeTemplateResult.loading}>
              Save
            </SubmitButton>
          </div>
        </div>
      </form>
    </Form>
  );
};

export const ChargeTemplateDetails: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<
    GetChargeTemplateDetails,
    GetChargeTemplateDetailsVariables
  >(GET_CHARGE_TEMPLATE_DETAILS, {
    variables: { id: params.id! },
  });
  const chargemasterGroupResult = useQuery<GetLocationChargemasterGroups>(
    GET_LOCATION_CHARGEMASTER_GROUPS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const chargemasterGroups = (
    chargemasterGroupResult.data?.chargemasterGroupsByLastUsed ?? []
  ).map((cg) => cg.chargemasterGroup);

  if (loading) return <LoadingLayout />;

  const chargeTemplate = data?.chargeTemplate!;
  const stats = data?.chargeTemplateUsageStats!;

  const chartData = stats.map((stat) => ({
    date: stat.month,
    Count: stat.total,
  }));

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900 flex gap-2 items-center">
            <Link to="/chargemaster/templates" className="hover:text-gray-400">
              Charge Template
            </Link>
            <svg
              className="h-6 w-6 flex-shrink-0 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            {chargeTemplate.name}
          </h1>
        </div>
      }
      content={
        <div className="flex flex-col gap-4 py-8">
          <div className="grid grid-cols-2 gap-4">
            <Card>
              <div className="p-4">
                <ChargeTemplateForm
                  chargeTemplate={chargeTemplate}
                  chargemasterGroups={chargemasterGroups}
                />
              </div>
            </Card>
            <Card>
              <div className="p-4">
                <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Estimate Usage
                </h3>
                <BarChart
                  data={chartData}
                  index="date"
                  categories={["Count"]}
                  colors={["blue"]}
                  yAxisWidth={45}
                  className="mt-6 hidden h-60 sm:block"
                />
              </div>
            </Card>
          </div>
          <Card>
            <div className="p-4">
              <h3 className="text-lg pb-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                Recent Visits with matching Charges
              </h3>
              <MatchingAppointmentsTable chargeTemplateId={chargeTemplate.id} />
              {/* <DataTable
                data={[...appointments].map((appointment) => {
                  const activeEstimate = appointment.bill.at(0)?.activeEstimate;
                  const charges = appointment.bill.flatMap((b) => b.charges);
                  return {
                    id: appointment.id,
                    appointment,
                    start: mapNullable(parseISO)(appointment.start),
                    patientName: appointment.account.patient.displayName,
                    providerName: appointment.provider?.displayName ?? null,
                    accountType: appointment.account.accountType?.name ?? null,
                    charges,
                    codes: charges.map(
                      (c) => c.customCode ?? c.chargemaster?.code
                    ),
                    providerTaxonomyCode:
                      appointment.provider?.providerTaxonomyCode?.displayName ??
                      null,
                    insurancePolicies: appointment.insurancePolicies.map(
                      (ip) => ip.payer.name
                    ),
                    chargeTemplate: activeEstimate
                      ? activeEstimate.chargeTemplate?.name ?? null
                      : "Unestimated",
                    match:
                      activeEstimate?.chargeTemplate?.id === chargeTemplate?.id,
                  };
                })}
                columns={columns}
              /> */}
            </div>
          </Card>
        </div>
      }
    />
  );
};
