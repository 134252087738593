import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { formatDistanceToNow, parseISO } from "date-fns";

import { useUser } from "../../user-context";
import { Table, Td } from "../../components/Table";
import {
  GetIntegrations,
  GetIntegrationsVariables,
  GetIntegrations_integrations_integrationSyncs as IntegrationSync,
  GetIntegrations_integrations_integrationSyncSchedules as IntegrationSyncSchedule,
} from "../../generated/GetIntegrations";
import {
  PauseIntegrationSyncSchedule,
  PauseIntegrationSyncScheduleVariables,
} from "../../generated/PauseIntegrationSyncSchedule";
import {
  ResumeIntegrationSyncSchedule,
  ResumeIntegrationSyncScheduleVariables,
} from "../../generated/ResumeIntegrationSyncSchedule";
import { Badge, Button } from "../../components";
import { Layout } from "../layout";

const GET_INTEGRATIONS = gql`
  query GetIntegrations($organizationId: String!, $skip: Int!, $take: Int!) {
    integrations(where: { organizationId: { equals: $organizationId } }) {
      id
      name
      location {
        id
        name
      }
      integrationSyncSchedules {
        id
        cron
        cronDisplay
        direction
        pausedAt
      }
      integrationSyncs(orderBy: { createdAt: desc }, skip: $skip, take: $take) {
        id
        createdAt
        direction
        user {
          id
          firstName
          lastName
        }
        automated
        linkSyncsCount
        linkSyncErrorsCount
      }
      _count {
        integrationSyncs
      }
    }
  }
`;

const PAUSE_INTEGRATION_SYNC_SCHEDULE = gql`
  mutation PauseIntegrationSyncSchedule($id: String!) {
    pauseIntegrationSyncSchedule(id: $id) {
      id
      cron
      cronDisplay
      direction
      pausedAt
    }
  }
`;

const RESUME_INTEGRATION_SYNC_SCHEDULE = gql`
  mutation ResumeIntegrationSyncSchedule($id: String!) {
    resumeIntegrationSyncSchedule(id: $id) {
      id
      cron
      cronDisplay
      direction
      pausedAt
    }
  }
`;

const IntegrationSyncScheduleSection: React.FC<React.PropsWithChildren<{
  schedule: IntegrationSyncSchedule;
}>> = ({ schedule }) => {
  const [pauseSchedule, pauseScheduleResult] = useMutation<
    PauseIntegrationSyncSchedule,
    PauseIntegrationSyncScheduleVariables
  >(PAUSE_INTEGRATION_SYNC_SCHEDULE);
  const [resumeSchedule, resumeScheduleResult] = useMutation<
    ResumeIntegrationSyncSchedule,
    ResumeIntegrationSyncScheduleVariables
  >(RESUME_INTEGRATION_SYNC_SCHEDULE);

  return (
    <div className="flex items-center space-x-2">
      <p>
        {schedule.direction} {schedule.cronDisplay}
      </p>
      <div>
        {schedule.pausedAt ? (
          <Button
            onClick={() => resumeSchedule({ variables: { id: schedule.id } })}
            loading={resumeScheduleResult.loading}
          >
            Resume Sync
          </Button>
        ) : (
          <Button
            onClick={() => pauseSchedule({ variables: { id: schedule.id } })}
            loading={pauseScheduleResult.loading}
          >
            Pause Sync
          </Button>
        )}
      </div>
    </div>
  );
};

const PAGE_SIZE = 10;

export const IntegrationList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser();
  const [currentPage, setPageNum] = useState(1);
  const onPageChange = (num: number) => {
    setPageNum(num);
  };
  const skip = (currentPage - 1) * PAGE_SIZE;
  const { loading, data } = useQuery<GetIntegrations, GetIntegrationsVariables>(
    GET_INTEGRATIONS,
    {
      variables: {
        organizationId: user!.organization.id,
        skip,
        take: PAGE_SIZE,
      },
    }
  );

  if (loading) return <>Loading</>;
  if (!data) return null;
  if (data.integrations.length === 0) return <div>No Integrations.</div>;
  return (
    <div className="flex flex-col gap-8">
      {data.integrations.map((integration) => (
        <div className="flex flex-col" key={integration.id}>
          <div className="flex items-center space-x-2 pb-2">
            <h2 className="text-lg font-semibold">
              {integration.location.name} - {integration.name}
            </h2>
            {integration.integrationSyncSchedules.every(
              (schedule) => schedule.pausedAt
            ) ? (
              <Badge text="Paused" variant="warning" />
            ) : (
              <Badge text="Running" variant="success" />
            )}
          </div>
          <div className="flex pb-2">
            {integration.integrationSyncSchedules.map((schedule) => (
              <IntegrationSyncScheduleSection
                key={schedule.id}
                schedule={schedule}
              />
            ))}
          </div>
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <Table
                columnDefs={[
                  {
                    header: "Synced At",
                    cellFn: (sync: IntegrationSync) => (
                      <Td>
                        <div className="text-sm text-gray-900">
                          {formatDistanceToNow(parseISO(sync.createdAt), {
                            addSuffix: true,
                          })}
                        </div>
                      </Td>
                    ),
                  },
                  {
                    header: "Direction",
                    cellFn: (sync: IntegrationSync) => (
                      <Td>
                        <div className="text-sm text-gray-900">
                          {sync.direction}
                        </div>
                      </Td>
                    ),
                  },
                  {
                    header: "Who",
                    cellFn: (sync: IntegrationSync) => (
                      <Td>
                        <div className="text-sm text-gray-900">
                          {sync.automated
                            ? "Automated"
                            : [sync.user?.firstName, sync.user?.lastName].join(
                                " "
                              )}
                        </div>
                      </Td>
                    ),
                  },
                  {
                    header: "Records Synced",
                    cellFn: (sync: IntegrationSync) => (
                      <Td>
                        <div className="text-sm text-gray-900">
                          {sync.linkSyncsCount}
                        </div>
                      </Td>
                    ),
                  },
                  {
                    header: "Sync Errors",
                    cellFn: (sync: IntegrationSync) => (
                      <Td>
                        <div className="text-sm text-gray-900">
                          {sync.linkSyncErrorsCount}
                        </div>
                      </Td>
                    ),
                  },
                ]}
                rows={integration.integrationSyncs}
                pagination={
                  integration._count!.integrationSyncs > 0
                    ? {
                        currentPage,
                        totalCount: integration._count!.integrationSyncs,
                        onPageChange,
                        pageSize: PAGE_SIZE,
                        siblingCount: 1,
                      }
                    : undefined
                }
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const Integrations: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">Integration</h1>
        </div>
      }
      content={
        <div className="py-4">
          <IntegrationList />
        </div>
      }
    />
  );
};
