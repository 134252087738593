import React from "react";
import { useForm, FormProvider } from "react-hook-form";

import { SubmitButton } from "../../components";
import { RadioGroup } from "@headlessui/react";
import { ProviderType } from "../../generated/globalTypes";
import { PayerCombobox } from "../rules/rule-inputs";
import { Input } from "../../components/ui/input";

export type ProviderForm = {
  type: "Individual" | "Organization";
  firstName: string | null;
  lastName: string | null;
  organizationName: string | null;
  npi: string | null;
  inNetworkPayers: string[];
  outNetworkPayers: string[];
};

export const ProviderForm: React.FC<{
  submit: (args: any) => void;
  submitText: string;
  loading: boolean;
  defaultValues?: any;
  payers: { id: string; name: string }[];
}> = ({ submit, submitText, loading, defaultValues, payers }) => {
  const methods = useForm({ defaultValues });
  const { register, handleSubmit } = methods;

  const [providerType, setProviderType] = React.useState<ProviderType>(
    (defaultValues?.type || "Individual") as ProviderType
  );

  const onSubmit = async (data: ProviderForm) => {
    const args =
      providerType === "Individual"
        ? {
            type: providerType,
            firstName: data.firstName,
            lastName: data.lastName,
            organizationName: null,
            npi: data.npi,
          }
        : {
            type: providerType,
            firstName: null,
            lastName: null,
            organizationName: data.organizationName,
            npi: data.npi,
          };
    await submit({
      ...args,
      inNetworkPayers: data.inNetworkPayers,
      outNetworkPayers: data.outNetworkPayers,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-6 gap-2">
          <div className="col-span-full">Create New Provider</div>
          <div className="col-span-6 pb-2">
            <RadioGroup
              value={providerType}
              onChange={setProviderType}
              className="flex flex-col"
            >
              <RadioGroup.Label className="block text-sm font-medium text-gray-700">
                Type
              </RadioGroup.Label>
              <div className="flex flex-col gap-1">
                <RadioGroup.Option value="Individual">
                  {({ checked }) => (
                    <div className="flex items-center">
                      <input
                        name="notification-method"
                        type="radio"
                        checked={checked}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Individual
                      </label>
                    </div>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  value="Organization"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                >
                  {({ checked }) => (
                    <div className="flex items-center">
                      <input
                        name="notification-method"
                        type="radio"
                        checked={checked}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                        Organization
                      </label>
                    </div>
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>
          </div>
          {providerType === "Individual" ? (
            <>
              <div className="col-span-6">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <Input required type="text" {...register("firstName")} />
              </div>
              <div className="col-span-6">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <Input required type="text" {...register("lastName")} />
              </div>
            </>
          ) : (
            <>
              <div className="col-span-6">
                <label
                  htmlFor="organizationName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Organization Name
                </label>
                <Input required type="text" {...register("organizationName")} />
              </div>
            </>
          )}
          <div className="col-span-6">
            <label
              htmlFor="npi"
              className="block text-sm font-medium text-gray-700"
            >
              NPI
            </label>
            <Input type="text" required {...register("npi")} />
          </div>

          <div className="col-span-6">
            <label className="block text-sm font-medium text-gray-700">
              In Network Payers
            </label>
            <PayerCombobox
              value={methods.watch("inNetworkPayers")}
              payers={payers}
              onSelect={(value) => {
                methods.setValue("inNetworkPayers", value);
              }}
              isMulti={true}
            />
          </div>
          <div className="col-span-6">
            <label className="block text-sm font-medium text-gray-700">
              Out of Network Payers
            </label>
            <PayerCombobox
              value={methods.watch("outNetworkPayers")}
              payers={payers}
              onSelect={(value) => {
                methods.setValue("outNetworkPayers", value);
              }}
              isMulti={true}
            />
          </div>
        </div>
        <div className="flex justify-start items-center mt-4">
          <div>
            <SubmitButton disabled={loading}>{submitText}</SubmitButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
