// @ts-nocheck
import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import {
  GetPortalPatientBills_getPortalPatientBills_patient as Patient,
  GetPortalPatientBills_getPortalPatientBills_patient_paymentIntents as PaymentIntent,
  GetPortalPatientBills_getPortalPatientBills_patient_organization as Organization,
  GetPortalPatientBills_getPortalPatientBills_patient_location as Location,
} from "../../../generated/GetPortalPatientBills";
import { isDefined, formatUSD, formatDateMMDDYYYY } from "../../../utils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Helvetica",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
});

type ReceiptPatient = Pick<
  Patient,
  | "firstName"
  | "lastName"
  | "address1"
  | "address2"
  | "city"
  | "state"
  | "postalCode"
> & {
  organization: Pick<Organization, "id" | "name" | "logoUrl">;
  location: Pick<
    Location,
    "id" | "name" | "address1" | "address2" | "city" | "state" | "postalCode"
  >;
};

const Header: React.FC<React.PropsWithChildren<{
  paymentIntent: PaymentIntent;
  patient: ReceiptPatient;
}>> = ({ paymentIntent, patient }) => {
  return (
    <View
      style={{
        width: "100%",
        backgroundColor: "rgb(243 244 246)", // bg-gray-100
        display: "flex",
        padding: "24px",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={{
              uri: patient.organization.logoUrl,
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
            style={{
              height: 96,
              width: "auto",
            }}
            cache={false}
          />
        </View>
        <View
          style={{
            flex: 1,
            paddingLeft: "4px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontSize: 24,
              ...styles.bold,
            }}
          >
            {patient.organization.name}
          </Text>
          <Text
            style={{
              fontSize: 12,
              paddingTop: 8,
              ...styles.bold,
            }}
          >
            Receipt Number:
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {paymentIntent.receiptCode}
          </Text>
          <Text
            style={{
              fontSize: 12,
              paddingTop: 8,
              ...styles.bold,
            }}
          >
            Payment Date:
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {formatDateMMDDYYYY(paymentIntent.createdAt)}
          </Text>
          <Text
            style={{
              fontSize: 12,
              paddingTop: 8,
              ...styles.bold,
            }}
          >
            Total Payment Amount:
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {formatUSD(paymentIntent.amount)}
          </Text>
        </View>
      </View>
    </View>
  );
};

const BillingAddressSection: React.FC<React.PropsWithChildren<{
  patient: ReceiptPatient;
}>> = ({ patient }) => {
  const patientName = [patient.firstName, patient.lastName].join(" ");
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        padding: "24px",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              paddingTop: 8,
              ...styles.bold,
            }}
          >
            Payment From:
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {patientName}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {[patient.address1, patient.address2].filter(isDefined).join(", ")}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {[patient.city, patient.state, patient.postalCode]
              .filter(isDefined)
              .join(", ")}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              paddingTop: 8,
              ...styles.bold,
            }}
          >
            Payment To:
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {patient.location?.name}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {[patient.location?.address1, patient.location?.address2]
              .filter(isDefined)
              .join(", ")}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {[
              patient.location?.city,
              patient.location?.state,
              patient.location?.postalCode,
            ]
              .filter(isDefined)
              .join(", ")}
          </Text>
        </View>
      </View>
    </View>
  );
};

const LineItemsSection: React.FC<React.PropsWithChildren<{
  paymentIntent: PaymentIntent;
}>> = ({ paymentIntent }) => {
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          backgroundColor: "rgb(243 244 246)", // bg-gray-50
          fontSize: 9,
          textAlign: "center",
          padding: 4,
          ...styles.bold,
        }}
      >
        <View style={{ flex: 1 }}>
          <Text
            style={{
              ...styles.bold,
            }}
          >
            Bill ID
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Date of Service</Text>
        </View>
        <View style={{ flex: 4 }}>
          <Text>Description</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Payment Amount</Text>
        </View>
      </View>
      {/* Line Items */}
      {paymentIntent.payments.flatMap((payment) => [
        ...payment.transaction.paymentAllocations.map((pa) => {
          const isRefund = payment.isPledgeRefund;
          return (
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                fontSize: 9,
                textAlign: "center",
                paddingLeft: 4,
                paddingRight: 4,
                paddingTop: 8,
                paddingBottom: 8,
              }}
            >
              <View style={{ flex: 1 }}>
                <Text>{pa.chargeTransaction.charge?.bill?.billCode}</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text>
                  {pa.chargeTransaction.charge?.bill?.dateOfServiceDisplay}
                </Text>
              </View>
              <View style={{ flex: 4 }}>
                <Text>
                  {isRefund && "Refunded "}
                  {pa.chargeTransaction.description}
                  {pa.chargeTransaction.charge?.customCode &&
                    ` (${pa.chargeTransaction.charge.customCode})`}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text>{formatUSD(pa.amount)}</Text>
              </View>
            </View>
          );
        }),
        // Payments towards estimated charges
        // Filter out any estimated charges that don't have a charge
        ...payment.transaction.billPayments.map((bp) => {
          const isRefund = payment.isPledgeRefund;
          return (
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                fontSize: 9,
                textAlign: "center",
                paddingLeft: 4,
                paddingRight: 4,
                paddingTop: 8,
                paddingBottom: 8,
              }}
            >
              <View style={{ flex: 1 }}>
                <Text>{bp.bill?.billCode}</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text>{bp.bill?.dateOfServiceDisplay ?? null}</Text>
              </View>
              <View style={{ flex: 4 }}>
                <Text>Visit with {bp.bill.primaryProvider?.displayName}</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text>{formatUSD(bp.amount)}</Text>
              </View>
            </View>
          );
        }),
      ])}
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        {/* Empty column */}
        <View style={{ flex: 5 }}></View>
        <View
          style={{
            flex: 7,
            borderTopWidth: 1,
            borderTopColor: "rgb(17 24 39)", // text-gray-900
            borderTopStyle: "solid",
            display: "flex",
            flexDirection: "column",
            fontSize: 9,
            marginLeft: 20,
            marginRight: 20,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View
            style={{
              display: "flex",
              paddingTop: 8,
              paddingBottom: 8,
              ...styles.bold,
            }}
          >
            <View style={{ flex: 5, textAlign: "left" }}>
              <Text>Amount Paid</Text>
            </View>
            <View style={{ flex: 2, textAlign: "right" }}>
              <Text>{formatUSD(paymentIntent.amount)}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export const ReceiptPDF: React.FC<React.PropsWithChildren<{
  paymentIntent: PaymentIntent;
  patient: ReceiptPatient;
}>> = ({ paymentIntent, patient }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Header paymentIntent={paymentIntent} patient={patient} />
        </View>
        <View>
          <BillingAddressSection patient={patient} />
        </View>
        <View>
          <LineItemsSection paymentIntent={paymentIntent} />
        </View>
      </Page>
    </Document>
  );
};
