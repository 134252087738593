import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import OTPInput from "../../components/OTPInput";

import { OTPOption, OTPSelection } from "./otp-selection";
import { OvalSpinner } from "../../components/loading";
import {
  LoginOrCreatePatientUser,
  LoginOrCreatePatientUserVariables,
} from "../../generated/LoginOrCreatePatientUser";
import {
  LoginPatientUserOTP,
  LoginPatientUserOTPVariables,
} from "../../generated/LoginPatientUserOTP";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useAnalytics } from "../../analytics-context";
import { isDefined } from "../../utils";

const LOGIN_PATIENT_USER = gql`
  mutation LoginPatientUserOTP($code: String!, $methodId: String!) {
    loginPatientUserOTP(code: $code, methodId: $methodId) {
      id
    }
  }
`;

const LOGIN_OR_CREATE_PATIENT_USER = gql`
  mutation LoginOrCreatePatientUser($patientId: String!, $type: String!) {
    loginOrCreatePatientUser(patientId: $patientId, type: $type) {
      success
      errors {
        message
      }
      stytchMethodId
      patientUser {
        id
        email
      }
    }
  }
`;

export const OTPLogin: React.FC<React.PropsWithChildren<{
  patientId: string;
  firstName: string;
  maskedEmail: string | null;
  maskedCellPhone: string | null;
  onLogin: (data: LoginPatientUserOTP) => void;
  contactEmail?: string;
  organizationName: string | null;
  locationDisplayName: string | null;
}>> = ({
  patientId,
  firstName,
  maskedEmail,
  maskedCellPhone,
  onLogin,
  contactEmail,
  organizationName,
  locationDisplayName,
}) => {
  const analytics = useAnalytics();
  const [loginOrCreatePatient, result] = useMutation<
    LoginOrCreatePatientUser,
    LoginOrCreatePatientUserVariables
  >(LOGIN_OR_CREATE_PATIENT_USER);
  const [selectedOtpMethod, setSelectedOtpMethod] = useState<OTPOption | null>(
    null
  );

  const placeDisplayName = locationDisplayName ?? organizationName;

  const login = (otpMethod: OTPOption) => {
    loginOrCreatePatient({
      variables: {
        type: otpMethod.method,
        patientId,
      },
      onCompleted: (data) => {
        const patientUser = data.loginOrCreatePatientUser?.patientUser;
        if (patientUser) {
          // Identify the logged in user
          analytics?.identify(patientUser.id, {
            email: patientUser.email,
          });
        }
      },
    });
  };

  if (result.data?.loginOrCreatePatientUser?.success) {
    return (
      <PatientOtpLogin
        otpOption={selectedOtpMethod!}
        methodId={result.data?.loginOrCreatePatientUser!.stytchMethodId!}
        firstName={firstName}
        onLogin={onLogin}
      />
    );
  }

  const otpOptions: OTPOption[] = [];
  if (maskedEmail) {
    otpOptions.push({
      id: "email",
      method: "email",
      display: maskedEmail,
    });
  }
  if (maskedCellPhone) {
    otpOptions.push({
      id: "sms",
      method: "sms",
      display: maskedCellPhone,
    });
  }

  const onSelect = (otpOption: OTPOption) => {
    setSelectedOtpMethod(otpOption);
  };

  return (
    <div>
      <p className="text-xl text-gray-900 font-medium">We value your privacy</p>
      <p className="text-gray-900 text-xs font-medium pt-1">
        Choose a method to receive a one time passcode so that we can verify
        it's really you
      </p>
      {otpOptions.length === 0 ? (
        <div className="mt-5">
          <div className="flex justify-center">
            <ExclamationIcon className="h-10 w-10 text-yellow-500"></ExclamationIcon>
          </div>
          <div className="flex justify-center mt-1 mb-2">
            <span className="text-center text-sm">
              Oops, it looks like we don't have an email or phone number on file
              to send you the passcode. Please{" "}
              {isDefined(contactEmail) ? (
                <a
                  href={`mailto:${contactEmail}?subject=${encodeURIComponent(
                    `${placeDisplayName} - Contact Us (No email or phone number)`
                  )}`}
                  className="text-indigo-500 underline py-1"
                >
                  contact
                </a>
              ) : (
                "contact"
              )}{" "}
              us to add your email or phone number to access your bills.
            </span>
          </div>
        </div>
      ) : (
        <div>
          <div className="py-2 mt-4">
            <OTPSelection otpOptions={otpOptions} onSelect={onSelect} />
          </div>
          {(result.data?.loginOrCreatePatientUser?.errors?.length ?? 0) > 0 && (
            <div className="rounded-md bg-red-50 p-4">
              {result.data!.loginOrCreatePatientUser!.errors.map((error) => (
                <p className="text-sm text-red-600 text-center">
                  {error.message}
                </p>
              ))}
            </div>
          )}
          <div className="w-full">
            <button
              onClick={() => login(selectedOtpMethod!)}
              disabled={result.loading || !selectedOtpMethod}
              className="w-full mt-6 bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center"
            >
              {result.loading ? (
                <OvalSpinner className="text-white" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
          <p className="py-2 mt-2 text-xs text-gray-500">
            By selecting the phone number option, you agree that it may be used
            to send you text messages. Message and data rates may apply.
          </p>
        </div>
      )}
    </div>
  );
};

export const PatientOtpLogin: React.FC<React.PropsWithChildren<{
  otpOption: OTPOption;
  methodId: string;
  firstName: string;
  onLogin: (data: LoginPatientUserOTP) => void;
}>> = ({ firstName, otpOption, methodId, onLogin }) => {
  const [failure, setFailure] = useState(false);
  const [code, setCode] = useState<string>("");
  const [loginPatientUserOTP, result] = useMutation<
    LoginPatientUserOTP,
    LoginPatientUserOTPVariables
  >(LOGIN_PATIENT_USER);

  const onChange = (otp: string) => {
    setCode(otp);
  };

  const login = async () => {
    await loginPatientUserOTP({
      variables: {
        code,
        methodId,
      },
      onCompleted: onLogin,
      onError: (error) => {
        toast.error("Something went wrong.");
      },
    });
  };

  return (
    <div>
      <div className="flex flex-col justify-center">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Hi {firstName}, check your{" "}
          {otpOption.method === "sms" ? "mobile phone" : "email"}!
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          We sent a 6-digit code to {otpOption.display} from Pledge Health. It
          expires shortly, so enter it soon.
        </p>
      </div>
      <div className="py-8">
        <OTPInput
          autoFocus
          isNumberInput={true}
          length={6}
          className="flex justify-center space-x-2"
          inputClassName="hide-number-input-spinners w-12 p-4 text-lg text-center border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:ring-1"
          onChangeOTP={onChange}
        />
      </div>
      <div className="pb-4">
        <button
          onClick={login}
          disabled={code.length !== 6}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
        >
          Submit
        </button>
      </div>
    </div>
  );
};
