import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Transition, Dialog, RadioGroup } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useState, Fragment } from "react";
import { toast } from "react-toastify";
import { SubmitButton } from "../../components";
import {
  UnlinkPatient,
  UnlinkPatientVariables,
} from "../../generated/UnlinkPatient";
import { GET_PATIENT } from "./show";
import { GetPatient_patient_organizationPatientGroup_patients as PatientGroupPatient } from "../../generated/GetPatient";
import { GetPossibleMatches_getPossibleMatches_patient as MatchingPatient } from "../../generated/GetPossibleMatches";
import {
  SearchForMatchingPatients,
  SearchForMatchingPatientsVariables,
} from "../../generated/SearchForMatchingPatients";
import { FormProvider, useForm } from "react-hook-form";
import {
  LinkPatients,
  LinkPatientsVariables,
} from "../../generated/LinkPatients";
import { GetPatient_patient as IPatient } from "../../generated/GetPatient";
import { format, parseISO } from "date-fns";
import { useAnalytics } from "../../analytics-context";
import { QueryMode } from "../../generated/globalTypes";
import { useUser } from "../../user-context";
import {
  mapNullable,
  formatDateMMDDYYYY,
  isDefined,
  classNames,
  toDate,
} from "../../utils";
import { GET_POSSIBLE_MATCHES } from "./profile";

const TextDiff: React.FC<{ a: string; b: string }> = ({ a, b }) => {
  if (a !== b) {
    return (
      <span className="p-1 bg-red-100">
        {b ? b : <span className="text-gray-500 italic">Empty</span>}
      </span>
    );
  }
  return <span className="p-1">{b}</span>;
};

const UNLINK_PATIENT = gql`
  mutation UnlinkPatient($id: String!) {
    updateOnePatient(
      where: { id: $id }
      data: { organizationPatientGroup: { disconnect: true } }
    ) {
      id
    }
  }
`;

export const UnlinkPatientDialogButton: React.FC<{
  patient: PatientGroupPatient;
  remainingPatients: PatientGroupPatient[];
}> = ({ patient, remainingPatients }) => {
  const [unlinkDialogOpen, setUnlinkDialogOpen] = useState(false);
  return (
    <>
      <button
        className="p-1 rounded-lg hover:bg-gray-50"
        onClick={() => {
          setUnlinkDialogOpen(true);
        }}
      >
        <XIcon className="h-4 w-4 text-gray-500" />
      </button>
      {unlinkDialogOpen && (
        <UnlinkPatientDialog
          patient={patient}
          remainingPatients={remainingPatients}
          open={unlinkDialogOpen}
          setOpen={setUnlinkDialogOpen}
        />
      )}
    </>
  );
};

export const UnlinkPatientDialog: React.FC<{
  patient: PatientGroupPatient;
  remainingPatients: PatientGroupPatient[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ patient, remainingPatients, open, setOpen }) => {
  const [unlinkPatient, unlinkPatientResult] = useMutation<
    UnlinkPatient,
    UnlinkPatientVariables
  >(UNLINK_PATIENT, {
    onCompleted: () => {
      toast.success("Patient unlinked successfully");
      setOpen(false);
    },
    onError: () => {
      toast.error("Failed to unlink patient");
    },
    refetchQueries: [GET_PATIENT],
  });

  const onSubmit = async () => {
    await unlinkPatient({ variables: { id: patient.id } });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Unlink {patient.displayName}?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Unlinking a patient will remove the link to any linked
                      patient accounts. This will remove the ability for
                      patients to view the patient portal for linked accounts
                      and make payments towards the account.
                    </p>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    <p className="text-sm text-gray-500">
                      The remaining patients in the group after unlinking will
                      be:
                    </p>
                    <div className="flex text-left">
                      {remainingPatients.length > 0 ? (
                        <ul className="list-disc list-inside mx-auto">
                          {remainingPatients.map((p) => (
                            <li key={p.id}>
                              {p.displayName} ({p.location.name})
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div>None</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <SubmitButton
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                  onClick={onSubmit}
                  loading={unlinkPatientResult.loading}
                >
                  Unlink
                </SubmitButton>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const SEARCH_FOR_MATCHING_PATIENTS = gql`
  query SearchForMatchingPatients($where: PatientWhereInput!) {
    patients(where: $where, take: 10) {
      id
      firstName
      lastName
      dateOfBirth
      email
      cellPhone
      address1
      address2
      city
      state
      postalCode
      country
      location {
        id
        name
      }
      accounts {
        id
        accountType {
          id
          name
        }
      }
    }
  }
`;

const LINK_PATIENTS = gql`
  mutation LinkPatients($sourcePatientId: String!, $targetPatientId: String!) {
    linkPatients(
      sourcePatientId: $sourcePatientId
      targetPatientId: $targetPatientId
    ) {
      id
      organizationPatientGroup {
        id
        # Filter out the current patient
        patients(where: { id: { not: { equals: $sourcePatientId } } }) {
          id
          displayName
          dateOfBirth
          patientReadyBalance
          location {
            id
            name
          }
        }
      }
    }
  }
`;

type PatientMatchSearchForm = {
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
};

export const LinkPatientDialog: React.FC<{
  patient: IPatient;
  open: boolean;
  setOpen: (open: boolean) => void;
  defaultPatient?: MatchingPatient;
}> = ({ patient, open, setOpen, defaultPatient }) => {
  const user = useUser()!;
  const analytics = useAnalytics();

  const methods = useForm<PatientMatchSearchForm>();

  const [searchForMatchingPatients, result] = useLazyQuery<
    SearchForMatchingPatients,
    SearchForMatchingPatientsVariables
  >(SEARCH_FOR_MATCHING_PATIENTS);

  const [linkPatients, linkPatientsResult] = useMutation<
    LinkPatients,
    LinkPatientsVariables
  >(LINK_PATIENTS);

  const onSubmit = (data: PatientMatchSearchForm) => {
    searchForMatchingPatients({
      variables: {
        where: {
          organizationId: { equals: user.organization.id },
          id: { not: { equals: patient.id } },
          ...(data.firstName
            ? {
                firstName: {
                  contains: data.firstName,
                  mode: QueryMode.insensitive,
                },
              }
            : {}),
          ...(data.lastName
            ? {
                lastName: {
                  contains: data.lastName,
                  mode: QueryMode.insensitive,
                },
              }
            : {}),
          ...(data.dateOfBirth
            ? {
                dateOfBirth: {
                  equals: data.dateOfBirth,
                },
              }
            : {}),
        },
      },
    });
  };

  const [selectedPatient, setSelectedPatient] =
    useState<MatchingPatient | null>(defaultPatient ?? null);
  const [matchedPatient, setMatchedPatient] = useState<MatchingPatient | null>(
    selectedPatient
  );

  console.log({ matchedPatient });

  const valid =
    methods.watch("firstName") ||
    methods.watch("lastName") ||
    methods.watch("dateOfBirth");

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-6xl sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Link Accounts
                    </Dialog.Title>
                  </div>
                </div>
                {matchedPatient ? (
                  // Link specific patient
                  <>
                    {/* Patient Linking Comp */}
                    <div className="divide-y divide-gray-100">
                      <dl className="grid grid-cols-3 px-4 py-6">
                        <dt className="text-sm font-medium text-gray-900"></dt>
                        <dt className="text-sm font-medium text-gray-900">
                          Current Patient
                        </dt>
                        <dt className="text-sm font-medium text-gray-900">
                          Patient to Link
                        </dt>
                      </dl>
                      <dl className="grid grid-cols-3 px-4 py-6">
                        <dt className="text-sm font-medium text-gray-900">
                          First Name
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          {patient.firstName}
                        </dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          <TextDiff
                            a={patient.firstName}
                            b={matchedPatient.firstName}
                          />
                        </dd>
                      </dl>
                      <dl className="grid grid-cols-3 px-4 py-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Last Name
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          {patient.lastName}
                        </dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          <TextDiff
                            a={patient.lastName}
                            b={matchedPatient.lastName}
                          />
                        </dd>
                      </dl>
                      <dl className="grid grid-cols-3 px-4 py-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Date of Birth
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          {mapNullable(formatDateMMDDYYYY)(patient.dateOfBirth)}
                        </dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          <TextDiff
                            a={
                              mapNullable(formatDateMMDDYYYY)(
                                patient.dateOfBirth
                              ) ?? ""
                            }
                            b={
                              mapNullable(formatDateMMDDYYYY)(
                                matchedPatient.dateOfBirth
                              ) ?? ""
                            }
                          />
                        </dd>
                      </dl>
                      <dl className="grid grid-cols-3 px-4 py-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Email
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          {patient.email}
                        </dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          <TextDiff
                            a={patient.email ?? ""}
                            b={matchedPatient.email ?? ""}
                          />
                        </dd>
                      </dl>
                      <dl className="grid grid-cols-3 px-4 py-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Cell Phone
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          {patient.cellPhone}
                        </dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          <TextDiff
                            a={patient.cellPhone ?? ""}
                            b={matchedPatient.cellPhone ?? ""}
                          />
                        </dd>
                      </dl>
                      <dl className="grid grid-cols-3 px-4 py-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Address
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          {[
                            patient.address1,
                            patient.address2,
                            patient.city,
                            patient.state,
                            patient.postalCode,
                            patient.country,
                          ]
                            .filter(isDefined)
                            .join(", ")}
                        </dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          <TextDiff
                            a={[
                              patient.address1,
                              patient.address2,
                              patient.city,
                              patient.state,
                              patient.postalCode,
                              patient.country,
                            ]
                              .filter(isDefined)
                              .join(", ")}
                            b={[
                              matchedPatient.address1,
                              matchedPatient.address2,
                              matchedPatient.city,
                              matchedPatient.state,
                              matchedPatient.postalCode,
                              matchedPatient.country,
                            ]
                              .filter(isDefined)
                              .join(", ")}
                          />
                        </dd>
                      </dl>
                      <dl className="grid grid-cols-3 px-4 py-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Accounts
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          {patient.accounts
                            .map((acct) => acct.accountType?.name)
                            .filter(isDefined)
                            .join(", ")}
                        </dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          {matchedPatient.accounts
                            .map((acct) => acct.accountType?.name)
                            .filter(isDefined)
                            .join(", ")}
                        </dd>
                      </dl>
                      <dl className="grid grid-cols-3 px-4 py-6">
                        <dt className="text-sm font-medium text-gray-900">
                          Location
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          {patient.location.name}
                        </dd>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 col-span-1 sm:mt-0">
                          {matchedPatient.location.name}
                        </dd>
                      </dl>
                    </div>
                    <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Close
                      </button>
                      <SubmitButton
                        type="button"
                        onClick={() => {
                          linkPatients({
                            variables: {
                              sourcePatientId: patient.id,
                              targetPatientId: matchedPatient.id,
                            },
                            onCompleted: () => {
                              toast.success("Account linked");
                              setOpen(false);
                            },
                            onError: () => {
                              toast.error("Error linking account");
                            },
                            refetchQueries: [GET_POSSIBLE_MATCHES],
                          });
                        }}
                        loading={linkPatientsResult.loading}
                      >
                        Link Accounts
                      </SubmitButton>
                    </div>
                  </>
                ) : (
                  // Patient Search
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <div className="grid grid-cols-3">
                        <div className="flex flex-col gap-2 px-2">
                          <div>
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              First name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                {...methods.register("firstName")}
                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Last name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                {...methods.register("lastName")}
                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="date-of-birth"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Date of Birth
                            </label>
                            <div className="mt-2">
                              <input
                                type="date"
                                {...methods.register("dateOfBirth")}
                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div>
                            <SubmitButton
                              loading={result.loading}
                              disabled={!valid}
                            >
                              Search
                            </SubmitButton>
                          </div>
                        </div>
                        <div className="px-2 col-span-2">
                          <RadioGroup
                            value={selectedPatient}
                            onChange={setSelectedPatient}
                            className="overflow-auto"
                          >
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Date of Birth
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Email
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Address
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Location
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                  {!result.data?.patients?.length && (
                                    <tr>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                        No patients found
                                      </td>
                                    </tr>
                                  )}
                                  {result.data?.patients?.map((patient) => (
                                    <RadioGroup.Option
                                      as="tr"
                                      value={patient}
                                      key={patient.id}
                                      className={({ checked }) =>
                                        classNames(
                                          checked
                                            ? "ring-2 ring-indigo-500 ring-opacity-60 ring-offset-1 rounded-md"
                                            : "",
                                          "cursor-pointer hover:bg-gray-100"
                                        )
                                      }
                                    >
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                                        {patient.firstName} {patient.lastName}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {patient.dateOfBirth
                                          ? format(
                                              parseISO(
                                                toDate(patient.dateOfBirth)
                                              ),
                                              "MM/dd/yyyy"
                                            )
                                          : "N/A"}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {patient.email}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {[
                                          patient.address1,
                                          patient.address2,
                                          patient.city,
                                          patient.state,
                                          patient.postalCode,
                                          patient.country,
                                        ]
                                          .filter(isDefined)
                                          .join(", ")}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {patient.location.name}
                                      </td>
                                    </RadioGroup.Option>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </RadioGroup>
                        </div>
                        <div className="col-span-1"></div>
                        <div className="mt-5 sm:mt-6 flex flex-justify space-x-4 col-span-2">
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                            onClick={() => setOpen(false)}
                          >
                            Close
                          </button>
                          <SubmitButton
                            type="button"
                            onClick={() => {
                              setMatchedPatient(selectedPatient);
                            }}
                            loading={false}
                            disabled={!selectedPatient}
                          >
                            Select
                          </SubmitButton>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const NewLinkPatientDialogButton: React.FC<{ patient: IPatient }> = ({
  patient,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <button
        className="font-medium text-indigo-600 hover:text-indigo-500"
        onClick={() => setOpen(true)}
      >
        Add
      </button>
      {open && (
        <LinkPatientDialog patient={patient} open={open} setOpen={setOpen} />
      )}
    </div>
  );
};

export const LinkPatientDialogButton: React.FC<{
  patient: IPatient;
  matchingPatient: MatchingPatient;
}> = ({ patient, matchingPatient }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <button
        className="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600"
        onClick={() => setOpen(true)}
      >
        Link account
        <span aria-hidden="true"> &rarr;</span>
      </button>
      {open && (
        <LinkPatientDialog
          patient={patient}
          open={open}
          setOpen={setOpen}
          defaultPatient={matchingPatient}
        />
      )}
    </div>
  );
};
