import React from "react";
import { Layout } from "../../layout";
import { PreVisitMappingForm } from "./pre-visit-mapping-form";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { Condition, Visit } from "../../../evaluate-rule";
import { GetPreVisitMapping_preVisitMapping as PreVisitMapping } from "../../../generated/GetPreVisitMapping";
import { useLocation } from "react-router-dom";

export const NewPreVisitRule: React.FC = () => {
  const location = useLocation();
  const state: {
    preVisitMapping?: PreVisitMapping | null;
  } | null = location.state;

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Create New Rule
          </h1>
        </div>
      }
      content={
        <PreVisitMappingForm preVisitMapping={state?.preVisitMapping ?? null}>
          {({ conditions }) => (
            <div>
              <BenefitAssignmentRuleSimulatorTable
                conditions={conditions as Condition<keyof Visit>[]}
              />
            </div>
          )}
        </PreVisitMappingForm>
      }
    />
  );
};
