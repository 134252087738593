import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "../../../components/ui/table-helpers/data-table-column-header";
import { format, formatRelative } from "date-fns";
import { isDefined } from "../../../utils";
import { ChargemasterBadge } from "../../shared/visit-bill-display-card";
import { GetRuleExplorerVisits_getRuleExplorerVisits_bill_charges as Charge } from "../../../generated/GetRuleExplorerVisits";
import { Badge } from "../../../components/ui/badge";

export type RuleExplorerRow = {
  id: string;
  patientName: string;
  start: Date;
  end: Date | null;
  providerName: string | null;
  providerClassification: string | null;
  appointmentLabels: string[];
  accountType: string | null;
  patientLabels: string[];
  payerName: string | null;
  feeSchedule: string | null;
  chargeTemplate: string | null;
  charges: Charge[];
  codes: string;
};

export const columns: ColumnDef<RuleExplorerRow>[] = [
  {
    id: "appointmentStart",
    accessorKey: "appointmentStart",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Appointment" />
    ),
    cell: ({ row }) => {
      return (
        <div className="[&:first-letter]:capitalize">
          {format(row.original.start, "MMM dd, yyyy h:mm aa")}
          {isDefined(row.original.end) &&
            ` - ${format(row.original.end, "h:mm aa")}`}
        </div>
      );
    },
  },
  {
    id: "patientName",
    accessorKey: "patientName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Patient" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.original.patientName);
    },
  },
  {
    accessorKey: "accountType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Account" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "patientLabels",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Patient Labels" />
    ),
    cell: ({ row }) => {
      return (
        <div className="max-w-[12em] truncate">
          {row.original.patientLabels.map((label) => (
            <Badge
              key={label}
              variant="outline"
              className="px-1 py-[1px] font-normal"
            >
              {label}
            </Badge>
          ))}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return row.original.patientLabels.some((label) => value.includes(label));
    },
  },
  {
    accessorKey: "appointmentLabels",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Appointment Labels" />
    ),
    cell: ({ row }) => {
      return (
        <div className="max-w-[12em] truncate">
          <div className="flex items-center gap-1">
            {row.original.appointmentLabels.map((label) => (
              <Badge
                key={label}
                variant="outline"
                className="px-1 py-[1px] font-normal"
              >
                {label}
              </Badge>
            ))}
          </div>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return row.original.appointmentLabels.some((label) =>
        value.includes(label)
      );
    },
  },
  {
    accessorKey: "providerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Provider" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "providerClassification",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Provider Classification" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "payerName",
    accessorKey: "payerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payer" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "codes",
    accessorKey: "codes",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charges" />
    ),
    cell: ({ row }) => {
      const charges = row.original.charges;
      // TODO component for fake badge
      return (
        <div className="flex gap-2 max-w-[16em] flex-wrap">
          {charges.map((c) =>
            c.chargemaster ? (
              <ChargemasterBadge
                key={c.id}
                chargemaster={c.chargemaster}
                units={c.units ?? 1}
              />
            ) : (
              <div>{c.customCode}</div>
            )
          )}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.every((v) =>
        row.original.charges.some(
          (c) => c.chargemaster?.chargemasterGroupId === v
        )
      );
    },
  },
  {
    id: "chargeTemplate",
    accessorKey: "chargeTemplate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charge Template" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "feeSchedule",
    accessorKey: "feeSchedule",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Fee Schedule" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
];
