import React from "react";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { NotFound } from "../components/404";
import { UniversalPatientIdentification } from "./pages/identification";
import { TermsOfServiceUniversal } from "./pages/terms-of-service";
import { PrivacyPolicyUniversal } from "./pages/privacy-policy";

export const UniversalPatientPortal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Routes>
      <Route path="terms-of-service" element={<TermsOfServiceUniversal />} />
      <Route path="privacy-policy" element={<PrivacyPolicyUniversal />} />
      <Route path="/" element={<UniversalPatientIdentification />} />
      <Route
        path="*"
        element={
          <div className="min-h-screen flex flex-col justify-center">
            <NotFound />
          </div>
        }
      ></Route>
    </Routes>
  );
};
