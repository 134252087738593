import React from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";

import { SubmitButton } from "../../components";
import {
  BarChart,
  NumberInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TextInput,
} from "@tremor/react";
import {
  CurrencyDollarIcon,
  InformationCircleIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { useQuery } from "@apollo/client";
import { GetMeBootstrap } from "../../generated/GetMeBootstrap";
import { GET_ME_BOOTSTRAP } from "../../auth-context";
import {
  FeeScheduleImportDialog,
  ImportedFee,
} from "./fee-schedule-import-dialog";
import { useUser } from "../../user-context";
import { cn, isDefined, toDollars } from "../../utils";
import { LoadingSelect } from "../rules/benefit-mappings/benefit-mapping-form";
import { v4 as uuidV4 } from "uuid";
import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { CodeSearchCommandButton } from "../chargemaster/templates";
import { FormField } from "../../components/ui/form";
import { PayerCombobox } from "../rules/rule-inputs";
import { GetFeeSchedule_feeScheduleUsageStats as FeeScheduleUsageStats } from "../../generated/GetFeeSchedule";

export type ScheduledServiceFeeForm = {
  id: string | null;
  allowedAmount: number | null;
  code: string | null;
  name: string | null;
  modifier1: string | null;
  modifier2: string | null;
  modifier3: string | null;
  modifier4: string | null;
};

export type FeeScheduleForm = {
  id: string | null;
  name: string | null;
  // validAfter: string | null;
  // validBefore: string | null;
  scheduledServiceFees: ScheduledServiceFeeForm[];
  payerFeeSchedules: string[];
};

const ImportFeeScheduleFromIntegrationAlert: React.FC<{
  integrationId: string;
  onImport: (fee: ImportedFee[]) => void;
}> = ({ integrationId, onImport }) => {
  const [importFeeScheduleDialogOpen, setImportFeeScheduleDialogOpen] =
    React.useState(false);
  return (
    <>
      <div key={integrationId} className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              Check ChiroTouch for Fee Schedules to import.
            </p>
            <p className="mt-3 text-sm md:ml-6 md:mt-0">
              <button
                type="button"
                onClick={() => {
                  setImportFeeScheduleDialogOpen(true);
                }}
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
              >
                Check
                <span aria-hidden="true"> &rarr;</span>
              </button>
            </p>
          </div>
        </div>
      </div>
      {importFeeScheduleDialogOpen && (
        <FeeScheduleImportDialog
          integrationId={integrationId}
          open={importFeeScheduleDialogOpen}
          setOpen={setImportFeeScheduleDialogOpen}
          onImport={onImport}
        />
      )}
    </>
  );
};

export const FeeScheduleForm: React.FC<{
  submit: (args: any) => void;
  submitText: string;
  loading: boolean;
  defaultValues?: any;
  feeScheduleUsageStats?: FeeScheduleUsageStats[];
}> = ({
  submit,
  submitText,
  loading,
  defaultValues,
  feeScheduleUsageStats,
}) => {
  const user = useUser()!;
  // This works because we dedupe and use the cache
  const bootstrapResult = useQuery<GetMeBootstrap>(GET_ME_BOOTSTRAP, {
    fetchPolicy: "cache-first",
  });

  const methods = useForm<FeeScheduleForm>({ defaultValues });
  const { register, handleSubmit } = methods;

  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control: methods.control,
      name: "scheduledServiceFees",
    });

  const onSubmit = async (data: FeeScheduleForm) => {
    const args = data;
    await submit(args);
  };

  const payers = [
    ...(bootstrapResult.data?.me?.activeLocation?.payers ?? []),
  ].sort((a, b) => a.name.localeCompare(b.name));

  // TODO: Flag for fee schedule import enabled
  const integrationsWithFeeScheduleImportEnabled =
    user.activeLocation.integrations;

  const fees = methods.watch("scheduledServiceFees") ?? [];
  const feeMap = new Map<string, number>();
  fees.forEach((fee) => {
    const key = [
      fee.code,
      fee.modifier1,
      fee.modifier2,
      fee.modifier3,
      fee.modifier4,
      fee.name,
    ].join("-");
    const count = feeMap.get(key) ?? 0;
    feeMap.set(key, count + 1);
  });

  const anyDuplicates = Array.from(feeMap.values()).some((count) => count > 1);

  const chartData =
    feeScheduleUsageStats?.map((stat) => ({
      date: stat.month,
      Count: stat.total,
    })) ?? [];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div>
            <div className="grid grid-cols-2 gap-4">
              <Card>
                <div className="p-4">
                  <div className="col-span-6">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Name
                    </label>
                    <Input required type="text" {...register("name")} />
                  </div>
                  {/* <div className="col-span-6">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Valid From
                    </label>
                    <Input required type="date" {...register("validAfter")} />
                  </div>
                  <div className="col-span-6">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Valid To
                    </label>
                    <Input type="date" {...register("validBefore")} />
                  </div> */}
                  <div className="col-span-6">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Payers
                    </label>
                    {bootstrapResult.loading ? (
                      <LoadingSelect />
                    ) : (
                      <FormField
                        control={methods.control}
                        name="payerFeeSchedules"
                        render={({ field }) => (
                          <PayerCombobox
                            value={field.value}
                            payers={payers}
                            onSelect={(value) => {
                              field.onChange(value);
                            }}
                            isMulti
                          />
                        )}
                      />
                    )}
                  </div>

                  <div className="flex justify-end items-center mt-4">
                    <div className="flex flex-col gap-2">
                      {anyDuplicates && (
                        <span className="text-red-600">
                          Duplicate fees detected. Please ensure each fee is
                          unique.
                        </span>
                      )}
                      <SubmitButton disabled={loading || anyDuplicates}>
                        {submitText}
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </Card>

              {isDefined(feeScheduleUsageStats) && (
                <Card>
                  <div className="p-4">
                    <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                      Usage in Estimates
                    </h3>
                    <BarChart
                      data={chartData}
                      index="date"
                      categories={["Count"]}
                      colors={["blue"]}
                      yAxisWidth={45}
                      className="mt-6 hidden h-60 sm:block"
                    />
                  </div>
                </Card>
              )}
            </div>
          </div>

          <Card>
            <div className="flex flex-col gap-2 p-4">
              <div className="col-span-6">
                <div className="flex flex-col gap-1">
                  {integrationsWithFeeScheduleImportEnabled.map(
                    (integration) => (
                      <ImportFeeScheduleFromIntegrationAlert
                        key={integration.id}
                        integrationId={integration.id}
                        onImport={(fees) => {
                          replace(
                            fees.map((fee) => ({
                              ...fee,
                              id: null,
                              name: fee.description,
                              allowedAmount: toDollars(fee.allowedAmount),
                              units: 1,
                            }))
                          );
                        }}
                      />
                    )
                  )}
                </div>

                <Table className="mt-5 max-h-[50vh] border rounded-md my-2">
                  <TableHead className="border-b bg-white">
                    <TableRow className="divide-x">
                      <TableHeaderCell className="z-10 bg-white">
                        Code
                      </TableHeaderCell>
                      <TableHeaderCell className="z-10 bg-white">
                        Description
                      </TableHeaderCell>
                      <TableHeaderCell className="z-10 bg-white">
                        Modifier 1
                      </TableHeaderCell>
                      <TableHeaderCell className="z-10 bg-white">
                        Modifier 2
                      </TableHeaderCell>
                      <TableHeaderCell className="z-10 bg-white">
                        Modifier 3
                      </TableHeaderCell>
                      <TableHeaderCell className="z-10 bg-white">
                        Modifier 4
                      </TableHeaderCell>
                      <TableHeaderCell className="z-10 bg-white">
                        Allowed Amount
                      </TableHeaderCell>
                      <TableHeaderCell className="z-10 bg-white"></TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="divide-y">
                    {fields.length === 0 && (
                      <TableRow className={cn("divide-x")}>
                        <TableCell colSpan={5} className="text-center">
                          No fees
                        </TableCell>
                      </TableRow>
                    )}
                    {fields.map((fee, i) => {
                      const key = [
                        fee.code,
                        fee.modifier1,
                        fee.modifier2,
                        fee.modifier3,
                        fee.modifier4,
                        fee.name,
                      ].join("-");
                      const duplicate = (feeMap.get(key) ?? 0) > 1;
                      return (
                        <TableRow
                          key={fee.id}
                          className={cn(
                            "divide-x",
                            duplicate && "ring ring-red-500 ring-inset"
                          )}
                        >
                          <TableCell>
                            <div className="flex gap-1 items-center">
                              <div className="font-medium text-gray-900">
                                <TextInput
                                  placeholder="Enter a code"
                                  required
                                  {...methods.register(
                                    `scheduledServiceFees.${i}.code`
                                  )}
                                />
                              </div>
                              <CodeSearchCommandButton
                                onSelect={(chargemaster) => {
                                  methods.setValue(
                                    `scheduledServiceFees.${i}.code`,
                                    chargemaster.code
                                  );
                                  methods.setValue(
                                    `scheduledServiceFees.${i}.name`,
                                    chargemaster.description
                                  );
                                  methods.setValue(
                                    `scheduledServiceFees.${i}.modifier1`,
                                    chargemaster.modifier1
                                  );
                                  methods.setValue(
                                    `scheduledServiceFees.${i}.modifier2`,
                                    chargemaster.modifier2
                                  );
                                  methods.setValue(
                                    `scheduledServiceFees.${i}.modifier3`,
                                    chargemaster.modifier3
                                  );
                                  methods.setValue(
                                    `scheduledServiceFees.${i}.modifier4`,
                                    chargemaster.modifier4
                                  );
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <TextInput
                              {...methods.register(
                                `scheduledServiceFees.${i}.name`
                              )}
                              placeholder=""
                            />
                          </TableCell>
                          <TableCell>
                            <TextInput
                              {...methods.register(
                                `scheduledServiceFees.${i}.modifier1`
                              )}
                              placeholder=""
                            />
                          </TableCell>
                          <TableCell>
                            <TextInput
                              {...methods.register(
                                `scheduledServiceFees.${i}.modifier2`
                              )}
                              placeholder=""
                            />
                          </TableCell>
                          <TableCell>
                            <TextInput
                              {...methods.register(
                                `scheduledServiceFees.${i}.modifier3`
                              )}
                              placeholder=""
                            />
                          </TableCell>
                          <TableCell>
                            <TextInput
                              {...methods.register(
                                `scheduledServiceFees.${i}.modifier4`
                              )}
                              placeholder=""
                            />
                          </TableCell>
                          <TableCell>
                            <NumberInput
                              className="min-w-[4rem] [&>input]:!px-1"
                              placeholder=""
                              icon={() => (
                                <CurrencyDollarIcon className="ml-2 tremor-NumberInput-icon shrink-0 text-tremor-content-subtle dark:text-dark-tremor-content-subtle h-5 w-5" />
                              )}
                              step="0.01"
                              min={0}
                              enableStepper={false}
                              {...methods.register(
                                `scheduledServiceFees.${i}.allowedAmount`
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="destructive"
                              onClick={() => {
                                remove(i);
                              }}
                            >
                              Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Button
                  onClick={() => {
                    append({
                      id: uuidV4(),
                      code: null,
                      name: null,
                      modifier1: null,
                      modifier2: null,
                      modifier3: null,
                      modifier4: null,
                      allowedAmount: null,
                    });
                  }}
                >
                  <PlusIcon className="h-4 w-4 mr-2 text-white" />
                  Add new
                </Button>
              </div>
            </div>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};
