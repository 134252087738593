import React from "react";
import { v4 as uuidv4 } from "uuid";

import {
  GetEditPatient_patient as Patient,
  GetEditPatient_patient_insurancePolicies as InsurancePolicy,
  GetEditPatient_patient_insurancePolicies_payer as Payer,
} from "../../generated/GetEditPatient";
import { accessObjectByPath, classNames, cn } from "../../utils";
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useFieldArray,
} from "react-hook-form";
import { FormCombobox, FormSelect, Option } from "./eligibilities/new";
import { BirthSex } from "../../generated/globalTypes";
import { Switch } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

const PayerFormSelect: React.FC<
  React.PropsWithChildren<{
    name: string;
    options: Option[];
    rules?: any;
    policy?: InsurancePolicy;
  }>
> = ({ name, options, policy, rules }) => {
  const defaultPayer = options.find((c) => c.value === policy?.payer?.id);
  return (
    <FormCombobox
      name={name}
      options={options}
      defaultValue={defaultPayer}
      rules={rules}
      createable={true}
    />
  );
};

export type PatientFormData = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  birthSex: {
    value: BirthSex;
    label: string;
  };
  email: string | null;
  insurancePolicies: {
    id: string;
    payer: Option;
    active: boolean;
    memberId: string;
    groupName: string | null;
    groupId: string | null;
    effectiveDate: string | null;
    renewalDate: string | null;
    terminationDate: string | null;
    // If the policy already exists in the db
    existing: boolean;
    deletedAt: string | null;
    archived: boolean;
  }[];
};

export const PatientForm: React.FC<
  React.PropsWithChildren<{
    patient: Patient | null;
    methods: UseFormReturn<PatientFormData>;
    payers: Payer[];
    onSubmit: (data: PatientFormData) => void;
    formActions: React.ReactNode;
  }>
> = ({ patient, methods, payers, onSubmit, formActions }) => {
  const [showArchived, setShowArchived] = React.useState(false);
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "insurancePolicies",
  });

  const payerOptions = payers.map((p) => ({
    value: p.id,
    label: p.name,
  }));

  const errors = methods.formState.errors;

  // Show archived policies if there is a duplicate policy error
  React.useEffect(() => {
    const duplicatePolicyError = (errors?.insurancePolicies as any)?.some(
      (e) => e?.memberId?.message === "No duplicate insurance policies allowed."
    );
    if (duplicatePolicyError) {
      setShowArchived(true);
    }
  }, [errors]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Patient Information
            </h2>
          </div>

          <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-4 py-6 sm:p-8">
              <div className="sm:col-span-3">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    {...methods.register("firstName", {
                      required: true,
                    })}
                    className={classNames(
                      "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                      errors.firstName && "ring-red-500"
                    )}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    {...methods.register("lastName", {
                      required: true,
                    })}
                    className={classNames(
                      "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                      errors.lastName && "ring-red-500"
                    )}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="dateOfBirth"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Date of Birth
                </label>
                <div className="mt-2">
                  <input
                    type="date"
                    {...methods.register("dateOfBirth", {
                      required: true,
                    })}
                    className={classNames(
                      "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                      errors.dateOfBirth && "ring-red-500"
                    )}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="birthSex"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Birth Sex
                </label>
                <div className="mt-2">
                  <FormSelect
                    name="birthSex"
                    options={[
                      {
                        value: BirthSex.FEMALE,
                        label: "Female",
                      },
                      {
                        value: BirthSex.MALE,
                        label: "Male",
                      },
                    ]}
                    rules={{ required: true }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    {...methods.register("email")}
                    className={classNames(
                      "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                      errors.email && "ring-red-500"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Insurance Policies
            </h2>
          </div>

          <div className="md:col-span-2 flex flex-col gap-y-8">
            {fields.map((item, index) => {
              const active = methods.getValues(
                `insurancePolicies.${index}.active`
              );
              const archived = !!methods.watch(
                `insurancePolicies.${index}.deletedAt`
              );
              return (
                <div
                  key={item.id}
                  className={cn(
                    "relative grid grid-cols-2 gap-x-6 gap-y-8 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl w-full px-4 py-6 sm:p-8",
                    !showArchived && archived && "hidden"
                  )}
                >
                  {!item.existing && (
                    <button
                      className="absolute top-2 right-2 p-1 rounded-md hover:cursor-pointer hover:bg-gray-100"
                      onClick={() => remove(index)}
                    >
                      <XIcon className="h-4 w-4 text-gray-600" />
                    </button>
                  )}
                  <div className="sm:col-span-1">
                    <label
                      htmlFor={`insurancePolicies.${index}.payer`}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Payer
                    </label>
                    <div className="mt-2">
                      <PayerFormSelect
                        name={`insurancePolicies.${index}.payer`}
                        options={payerOptions}
                        policy={patient?.insurancePolicies?.find(
                          (p) => p.id === item.id
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-1">
                    <label
                      htmlFor={`insurancePolicies.${index}.memberId`}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Member ID
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        {...methods.register(
                          `insurancePolicies.${index}.memberId`,
                          {
                            required: true,
                            validate: (memberId, formValues) => {
                              const policies = formValues.insurancePolicies;
                              const policy = policies[index];
                              const hasDuplicate = policies.some(
                                (p) =>
                                  p.id !== policy.id &&
                                  p.memberId === policy.memberId &&
                                  p.payer.value === policy.payer.value
                              );
                              return hasDuplicate
                                ? "No duplicate insurance policies allowed."
                                : true;
                            },
                          }
                        )}
                        className={classNames(
                          "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          errors.insurancePolicies?.[index]?.memberId &&
                            "ring-red-500"
                        )}
                      />
                      {errors.insurancePolicies?.[index]?.memberId && (
                        <div className="text-red-500">
                          {errors.insurancePolicies?.[index]?.memberId?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sm:col-span-1 grid grid-cols-2 gap-2">
                    <div className="col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Effective On
                      </label>
                      <div className="mt-2">
                        <input
                          type="date"
                          {...methods.register(
                            `insurancePolicies.${index}.effectiveDate`
                          )}
                          className={classNames(
                            "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Renewal Date
                      </label>
                      <div className="mt-2">
                        <input
                          type="date"
                          {...methods.register(
                            `insurancePolicies.${index}.renewalDate`
                          )}
                          className={classNames(
                            "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-1">
                    <label
                      htmlFor="groupName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Group Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        {...methods.register(
                          `insurancePolicies.${index}.groupName`
                        )}
                        className={classNames(
                          "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          errors.insurancePolicies?.[index]?.groupName &&
                            "ring-red-500"
                        )}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-1">
                    <label
                      htmlFor="groupId"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Group Number
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        {...methods.register(
                          `insurancePolicies.${index}.groupId`
                        )}
                        className={classNames(
                          "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          errors.insurancePolicies?.[index]?.groupId &&
                            "ring-red-500"
                        )}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-1 grid grid-cols-2 gap-2">
                    <div className="col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Coverage Status
                      </label>
                      <div className="mt-2">
                        <Controller
                          name={`insurancePolicies.${index}.active`}
                          control={methods.control}
                          render={({ field }) => (
                            <Switch.Group
                              as="div"
                              className="flex items-center gap-2"
                            >
                              <Switch.Label as="span" className="text-sm">
                                <span className="text-gray-900">Inactive</span>
                              </Switch.Label>
                              {/* @ts-ignore */}
                              <Switch
                                {...field}
                                checked={field.value}
                                onChange={(val: boolean) => {
                                  methods.setValue(
                                    `insurancePolicies.${index}.active`,
                                    val,
                                    {
                                      shouldValidate: true,
                                    }
                                  );
                                }}
                                className={classNames(
                                  field.value ? "bg-indigo-600" : "bg-gray-200",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    field.value
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                              <Switch.Label as="span" className="text-sm">
                                <span className="text-gray-900">Active</span>
                              </Switch.Label>
                            </Switch.Group>
                          )}
                        />
                      </div>
                    </div>

                    {!active && (
                      <div className="col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Termination Date
                        </label>
                        <div className="mt-2">
                          <input
                            type="date"
                            {...methods.register(
                              `insurancePolicies.${index}.terminationDate`
                            )}
                            className={classNames(
                              "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              accessObjectByPath(
                                errors,
                                `insurancePolicies.${index}.terminationDate`
                              )
                                ? "ring-red-500 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="sm:col-span-1 grid grid-cols-2 gap-2">
                    <div className="col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Archived
                      </label>
                      <div className="mt-2 flex justify-between gap-4">
                        <Controller
                          name={`insurancePolicies.${index}.archived`}
                          control={methods.control}
                          render={({ field }) => (
                            <Switch.Group
                              as="div"
                              className="flex items-center gap-2"
                            >
                              {/* @ts-ignore */}
                              <Switch
                                {...field}
                                checked={field.value}
                                onChange={(val: boolean) => {
                                  methods.setValue(
                                    `insurancePolicies.${index}.archived`,
                                    val,
                                    {
                                      shouldValidate: true,
                                    }
                                  );
                                }}
                                className={classNames(
                                  field.value ? "bg-red-600" : "bg-gray-200",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    field.value
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </Switch.Group>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex justify-between">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                onClick={() => {
                  // @ts-ignore
                  append({
                    id: uuidv4(),
                    active: true,
                    existing: false,
                  });
                }}
              >
                <span aria-hidden="true">+</span> Add another insurance policy
              </button>
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-600 hover:text-gray-500"
                onClick={() => {
                  setShowArchived(!showArchived);
                }}
              >
                {showArchived ? (
                  <>
                    <span aria-hidden="true">-</span> Hide Archived
                  </>
                ) : (
                  <>
                    <span aria-hidden="true">+</span> Show Archived
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        {formActions}
      </form>
    </FormProvider>
  );
};
