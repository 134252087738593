import React from "react";
import { Layout, LoadingLayout } from "../../layout";
import { ChargeTemplateMappingForm } from "./charge-template-mapping-form";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { Condition, Visit } from "../../../evaluate-rule";
import {
  GetChargeTemplates,
  GetChargeTemplatesVariables,
} from "../../../generated/GetChargeTemplates";
import { gql, useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import { useParams } from "react-router-dom";
import {
  GetChargeTemplateMapping,
  GetChargeTemplateMappingVariables,
} from "../../../generated/GetChargeTemplateMapping";
import { CHARGE_TEMPLATE_MAPPING_FIELDS } from "../../../graphql";
import { GET_CHARGE_TEMPLATES } from "../../chargemaster/templates";

const GET_CHARGE_TEMPLATE_MAPPING = gql`
  ${CHARGE_TEMPLATE_MAPPING_FIELDS}
  query GetChargeTemplateMapping($id: String!) {
    chargeTemplateMapping(where: { id: $id }) {
      ...ChargeTemplateMappingFields
    }
  }
`;

export const EditChargeTemplateRule: React.FC = () => {
  const params = useParams<{ id: string }>();
  const user = useUser()!;
  const { data, loading, error } = useQuery<
    GetChargeTemplateMapping,
    GetChargeTemplateMappingVariables
  >(GET_CHARGE_TEMPLATE_MAPPING, {
    variables: {
      id: params.id!,
    },
  });
  const chargeTemplatesResult = useQuery<
    GetChargeTemplates,
    GetChargeTemplatesVariables
  >(GET_CHARGE_TEMPLATES, {
    variables: {
      locationId: user.activeLocation.id,
      pending: false,
    },
  });

  const chargeTemplateMapping = data?.chargeTemplateMapping;
  const chargeTemplates = chargeTemplatesResult.data?.chargeTemplates ?? [];

  if (loading || !chargeTemplateMapping) {
    return <LoadingLayout />;
  }

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Edit Charge Template Rule
          </h1>
        </div>
      }
      content={
        loading ? (
          <div className="flex flex-col gap-4">
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          </div>
        ) : (
          <ChargeTemplateMappingForm
            chargeTemplateMapping={chargeTemplateMapping}
            chargeTemplates={chargeTemplates}
          >
            {({ conditions }) => (
              <div>
                <BenefitAssignmentRuleSimulatorTable
                  conditions={conditions as Condition<keyof Visit>[]}
                />
              </div>
            )}
          </ChargeTemplateMappingForm>
        )
      }
    />
  );
};
