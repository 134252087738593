import React, { Fragment, useRef, useState } from "react";
import {
  GetEstimationWorkflowAppointmentDetails_appointment as Appointment,
  GetEstimationWorkflowAppointmentDetails_appointment_insurancePolicies as InsurancePolicy,
  GetEstimationWorkflowAppointmentDetails_appointment_mostRecentVisitCollectionRequest as VisitCollectionRequest,
  GetEstimationWorkflowAppointmentDetails_appointment_mostRecentVisitCollectionRequest_coverageBenefit as CoverageBenefit,
} from "../../../generated/GetEstimationWorkflowAppointmentDetails";
import { DocumentNode, gql, useMutation } from "@apollo/client";
import { Tab } from "@headlessui/react";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import { Button, SubmitButton } from "../../../components";
import { Button as UiButton } from "../../../components/ui/button";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  cn,
  formatUSD,
  isDefined,
  mapNullable,
  toCents,
  toDollarStr,
} from "../../../utils";
import { CoverageBenefitCompactDisplay } from "../../appointments";
import {
  CodeSearchCommand,
  CreateEstimateWizardDialog,
  EstimateDollarInput,
} from "../../appointments/table/estimate-dialog";
import {
  GetLocationChargeTemplates_chargeTemplates as ChargeTemplate,
  GetLocationChargeTemplates_chargeTemplates_chargeTemplateCharges_chargemasterGroup as ChargemasterGroup,
  GetLocationChargeTemplates_chargeTemplates_chargeTemplateCharges_chargemasterGroup_primaryChargemaster as Chargemaster,
} from "../../../generated/GetLocationChargeTemplates";
import { ServiceBenefitCell } from "../../patients/insurances/show";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  CreateDeposit,
  CreateDepositVariables,
} from "../../../generated/CreateDeposit";
import {
  ESTIMATE_FIELDS,
  VISIT_COLLECTION_REQUEST_FIELDS,
} from "../../../graphql";
import { ServiceTypeCode } from "../../patients/eligibilities/types";
import { ChargemasterBadge } from "../../shared/visit-bill-display-card";
import { SaltedCharges } from "../../appointments/table/estimate-form";
import { GetEstimateFormData_appointment_recommendedDeposit_rule as DepositMapping } from "../../../generated/GetEstimateFormData";

const CREATE_DEPOSIT = gql`
  ${ESTIMATE_FIELDS}
  ${VISIT_COLLECTION_REQUEST_FIELDS}
  mutation CreateDeposit(
    $appointmentId: String!
    $amount: Int!
    $coverageBenefitId: String
  ) {
    createDeposit(
      appointmentId: $appointmentId
      amount: $amount
      coverageBenefitId: $coverageBenefitId
    ) {
      id
      appointment {
        id
        estimationWorkflowStatus {
          id
          name
          stage
        }
        mostRecentVisitCollectionRequest {
          ...VisitCollectionRequestFields
        }
      }
    }
  }
`;

type Policy = Pick<
  InsurancePolicy,
  "id" | "mostRecentCoverageBenefits" | "inNetwork" | "payer" | "memberId"
>;

export const CreateEstimateWithoutChargesForm: React.FC<{
  appointment: Pick<Appointment, "id" | "matchingBenefitMappings">;
  defaultPolicyId: string | null;
  policies: Policy[];
  defaultDeposit: {
    amount: number;
    coverageBenefit: CoverageBenefit;
    rule: DepositMapping;
  } | null;
  // defaultDeposit: Pick<
  //   VisitCollectionRequest,
  //   "amount" | "coverageBenefit"
  // > | null;
  patientId: string;
  closeWizard: () => void;
  refetchQueries?: DocumentNode[];
}> = ({
  appointment,
  defaultPolicyId,
  policies,
  defaultDeposit,
  patientId,
  closeWizard,
  refetchQueries,
}) => {
  const [selectedPolicyId, setSelectedPolicyId] = useState<string | undefined>(
    defaultPolicyId ?? undefined
  );
  const selectedPolicy = policies.find((p) => p.id === selectedPolicyId);
  const [createDeposit, createDepositResult] = useMutation<
    CreateDeposit,
    CreateDepositVariables
  >(CREATE_DEPOSIT);
  const [deposit, setDeposit] = useState<number | undefined>(
    defaultDeposit?.amount
  );

  const policyBenefitMappings =
    appointment.matchingBenefitMappings.find(
      (match) => match.insurancePolicy.id === selectedPolicy?.id
    )?.benefitMappings ?? [];
  const matches =
    [...policyBenefitMappings].sort((a, b) => a.priority - b.priority) ?? [];

  const coverageBenefits = (
    selectedPolicy
      ? selectedPolicy?.inNetwork
        ? selectedPolicy.inNetworkBenefits
        : selectedPolicy.outOfNetworkBenefits
      : []
  ).filter(
    (cb) =>
      cb.providerServiceConfiguration.serviceType !==
      ServiceTypeCode.HealthBenefitPlanCoverage
  );

  // Recommended Benefit from copay Deposit Rule match
  const depositRuleBenefit = coverageBenefits.find(
    (benefit) =>
      benefit.providerServiceConfiguration.id ===
      defaultDeposit?.rule?.providerServiceConfiguration?.id
  );

  const recommendedConfig =
    depositRuleBenefit ??
    matches.find((match) =>
      coverageBenefits.some(
        (benefit) =>
          benefit.providerServiceConfiguration.id ===
          match.providerServiceConfiguration?.id
      )
    );

  const recommendedBenefit = coverageBenefits.find(
    (benefit) =>
      benefit.providerServiceConfiguration.id ===
      recommendedConfig?.providerServiceConfiguration?.id
  );
  const defaultBenefit = coverageBenefits.find(
    (benefit) =>
      benefit.providerServiceConfiguration.id ===
      defaultDeposit?.coverageBenefit?.providerServiceConfiguration?.id
  );
  const [selectedBenefitId, setSelectedBenefitId] = useState<string | null>(
    defaultBenefit?.id ?? recommendedBenefit?.id ?? null
  );
  const selectedBenefit = coverageBenefits.find(
    (cb) => cb.id === selectedBenefitId
  );
  const matchingRule = matches.find(
    (match) =>
      match.providerServiceConfiguration?.id ===
      selectedBenefit?.providerServiceConfiguration?.id
  );

  const selectedCopay = selectedBenefit?.combinedCoverageBenefit?.copay ?? null;
  const selectedDeductible =
    selectedBenefit?.combinedCoverageBenefit?.adjustedRemainingDeductible ??
    null;
  const metDeductible =
    selectedBenefit?.combinedCoverageBenefit?.deductibleApplies === false ||
    selectedDeductible === 0;

  const depositRef = useRef<HTMLInputElement>(null);

  const validDeposit = isDefined(deposit) && !Number.isNaN(deposit);

  // If the selected benefit is being used for the deposit
  const [benefitIsUsed, setBenefitIsUsed] = useState<boolean>(false);

  const isSuggestedAmount = (deposit: number) => {
    const copay = selectedBenefit?.combinedCoverageBenefit?.copay;
    // TODO: identify other suggested amounts
    return copay == deposit;
  };

  // const suggestions = [
  //   {
  //     enabled: isDefined(selectedCopay),
  //     text: `Use ${mapNullable(formatUSD)(selectedCopay)} Copay`,
  //     onClick: () => {
  //       if (depositRef.current) {
  //         depositRef.current.value = toDollarStr(selectedCopay!);
  //       }
  //       setDeposit(selectedCopay!);
  //       setBenefitIsUsed(true);
  //     },
  //   },
  //   {
  //     enabled:
  //       selectedBenefit?.combinedCoverageBenefit?.deductibleApplies === true &&
  //       selectedBenefit?.combinedCoverageBenefit?.adjustedMetDeductible,
  //     text: `Deductible Met - Use ${formatUSD(deductibleMetDeposit)} deposit`,
  //     onClick: () => {
  //       if (depositRef.current) {
  //         depositRef.current.value = toDollarStr(deductibleMetDeposit);
  //       }
  //       setDeposit(deductibleMetDeposit);
  //       setBenefitIsUsed(true);
  //     },
  //   },
  //   {
  //     enabled:
  //       selectedBenefit?.combinedCoverageBenefit?.deductibleApplies === true &&
  //       selectedBenefit?.combinedCoverageBenefit?.adjustedMetDeductible ===
  //         false,
  //     text: `Deductible Not Met - Use ${formatUSD(
  //       deductibleNotMetDeposit
  //     )} deposit`,
  //     onClick: () => {
  //       if (depositRef.current) {
  //         depositRef.current.value = toDollarStr(deductibleNotMetDeposit);
  //       }
  //       setDeposit(deductibleNotMetDeposit);
  //       setBenefitIsUsed(true);
  //     },
  //   },
  // ];

  return (
    <div className="flex flex-col pt-2">
      <label className="font-medium text-gray-500">Select Policy</label>

      <Select
        defaultValue={selectedPolicyId}
        onValueChange={setSelectedPolicyId}
        disabled={policies.length === 0}
      >
        <SelectTrigger
          className={cn(
            "w-full pr-2 flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0"
          )}
        >
          <SelectValue placeholder={"Select a policy"}>
            {selectedPolicy ? (
              <span className="ml-2">
                {selectedPolicy.payer.name}{" "}
                <span className="text-gray-500 italic">
                  {selectedPolicy.memberId}
                </span>
              </span>
            ) : (
              <span className="text-gray-500">No policy selected</span>
            )}
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {policies.map((p, i) => (
            <SelectItem
              key={p.id}
              value={p.id}
              className={cn(i !== 0 && "border-t rounded-t-none")}
            >
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
                  {p.payer.name}{" "}
                  <span className="text-gray-500 italic">{p.memberId}</span>
                </div>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <label className="font-medium text-gray-500">Select Benefit</label>

      <Select
        defaultValue={selectedBenefitId}
        onValueChange={(val) => {
          setSelectedBenefitId(val);
          setBenefitIsUsed(true);
        }}
        disabled={coverageBenefits.length === 0}
      >
        <SelectTrigger
          className={cn(
            "w-full pr-2 flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0"
          )}
        >
          <SelectValue
            placeholder={
              coverageBenefits.length > 0
                ? "Select a benefit"
                : "No benefits tracked"
            }
          >
            <span className={cn("ml-2")}>
              {selectedBenefit?.providerServiceConfiguration?.name}
              {isDefined(recommendedBenefit) &&
                selectedBenefit?.id === recommendedBenefit?.id && (
                  <> (Recommended)</>
                )}
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {coverageBenefits.map((cb, i) => (
            <SelectItem
              key={cb.id}
              value={cb.id}
              className={cn(i !== 0 && "border-t rounded-t-none")}
            >
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
                  {cb.providerServiceConfiguration?.name}
                  {isDefined(recommendedBenefit) &&
                    cb.id === recommendedBenefit?.id && <> (Recommended)</>}
                </div>
                <CoverageBenefitCompactDisplay benefits={cb} />
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      {selectedBenefit && (
        <div className="pt-2">
          {matchingRule &&
            selectedBenefit.providerServiceConfiguration.id ===
              matchingRule.providerServiceConfiguration.id && (
              <div className="flex gap-1 items-center">
                <AdjustmentsIcon className="h-4 w-4 text-gray-500" />{" "}
                {matchingRule.name}
              </div>
            )}
          {/* <CoverageBenefitCompactDisplay benefits={selectedBenefit} /> */}
          <ServiceBenefitCell
            coverageBenefit={selectedBenefit.combinedCoverageBenefit}
            patientId={patientId}
            inNetwork={selectedPolicy?.inNetwork}
            insurancePolicy={selectedPolicy}
            providerServiceConfiguration={
              selectedBenefit.providerServiceConfiguration
            }
            isHealthPlan={false}
            benefitAccumulatorAdjustments={
              selectedBenefit?.appliedBenefitAccumulatorAdjustments ?? []
            }
          />
        </div>
      )}
      {selectedPolicy && (
        <div className="flex justify-end">
          <Link
            to={`/patients/${patientId}/insurances/${selectedPolicy.id}`}
            className="flex items-center gap-1 text-indigo-600 group-hover:text-indigo-500"
          >
            View Full Benefits
            <div className="whitespace-nowrap transition ease-in-out duration-300 group-hover:translate-x-1">
              <span aria-hidden="true"> &rarr;</span>
            </div>
          </Link>
        </div>
      )}

      <label className="font-medium text-gray-900">Set estimate</label>

      {defaultDeposit?.rule && (
        <dl className="space-y-6 px-2 py-1 rounded-md text-sm bg-blue-50 text-blue-700 lg:block">
          <div className="flex items-center justify-between gap-4">
            <dd className="flex items-center gap-2">
              <div>Deposit Rule Applied:</div>
              <span className="font-semibold">{defaultDeposit.rule.name}</span>
            </dd>
          </div>
        </dl>
      )}

      {/* <div className="grid grid-cols-3 gap-2">
        {suggestions
          .filter((suggestion) => suggestion.enabled)
          .map((suggestion) => (
            <Button
              className="rounded bg-indigo-50 px-2 py-1 text-sm text-indigo-600 shadow-sm hover:bg-indigo-100"
              onClick={suggestion.onClick}
            >
              {suggestion.text}
            </Button>
          ))}
      </div> */}

      <div className="pt-4">
        <EstimateDollarInput
          ref={depositRef}
          defaultValue={isDefined(deposit) ? toDollarStr(deposit) : undefined}
          required
          onValueChange={(val) => {
            const cents = toCents(val);
            setDeposit(cents);
            if (isSuggestedAmount(cents)) {
              setBenefitIsUsed(true);
            } else {
              setBenefitIsUsed(false);
            }
          }}
          // Don't show the error display
          error={false}
        />
      </div>

      <div className="flex justify-end pt-4">
        <SubmitButton
          type="button"
          loading={createDepositResult.loading}
          onClick={() => {
            createDeposit({
              variables: {
                amount: deposit!,
                appointmentId: appointment.id,
                coverageBenefitId:
                  benefitIsUsed && selectedBenefit ? selectedBenefit.id : null,
              },
              onCompleted: () => {
                toast.success("Deposit created");
                closeWizard();
              },
              onError: () => {
                toast.error("Error creating deposit");
              },
              refetchQueries,
            });
          }}
          disabled={!validDeposit}
        >
          Create {validDeposit ? formatUSD(deposit!) : null} Estimate
        </SubmitButton>
      </div>
    </div>
  );
};

type EstimationInsurancePolicy = Pick<InsurancePolicy, "id">;

export const CreateEstimateWizard: React.FC<{
  appointment: Appointment;
  insurancePolicy: EstimationInsurancePolicy;
  chargeTemplates: ChargeTemplate[];
  patientId: string;
  closeWizard: () => void;
}> = ({
  appointment,
  insurancePolicy,
  chargeTemplates,
  patientId,
  closeWizard,
}) => {
  const [open, setOpen] = useState(false);
  const [choice, setChoice] = useState<"fromCharges" | "withoutCharges" | null>(
    null
  );
  const [chargesChanged, setChargesChanged] = useState(false);

  const deposit = appointment.mostRecentVisitCollectionRequest;
  const saltedCharges = appointment?.recommendedSaltingCharges?.charges;

  const estimate = deposit?.estimate;

  const recommendedChargeTemplate = chargeTemplates.find(
    (ct) => appointment.recommendedChargeTemplate?.id === ct.id
  );
  const [selectedChargeTemplateId, setSelectedChargeTemplateId] = useState<
    string | null
  >(
    (saltedCharges.length === 0 ? recommendedChargeTemplate?.id : null) ?? null
  );

  const selectedChargeTemplate = chargeTemplates.find(
    (ct) => ct.id === selectedChargeTemplateId
  );

  const initialCharges =
    saltedCharges.length > 0
      ? saltedCharges.map((c) => ({
          ...c,
          chargemasterGroup: c.chargemaster?.chargemasterGroup,
        }))
      : selectedChargeTemplate?.chargeTemplateCharges ?? [];

  const [estimatedCharges, setEstimatedCharges] =
    useState<{ chargemasterGroup: ChargemasterGroup; units: number }[]>(
      initialCharges
    );

  const removeCharge = (chargemasterGroupId: string) => {
    setEstimatedCharges(
      estimatedCharges.filter(
        (ec) => ec.chargemasterGroup.id !== chargemasterGroupId
      )
    );
    setSelectedChargeTemplateId(null);
    setChargesChanged(true);
  };
  const [codeSearchOpen, setCodeSearchOpen] = useState(false);

  const policies = appointment.insurancePolicies;
  const primaryPolicy = policies.at(0);

  return (
    <>
      <div className="pt-1 pr-2">
        <Tab.Group>
          <Tab.List className="isolate flex divide-x divide-gray-200 rounded-lg shadow border">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  type="button"
                  className={cn(
                    selected
                      ? "text-gray-900"
                      : "text-gray-500 hover:text-gray-700",
                    "rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-3 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                  )}
                >
                  With Charges
                  <span
                    aria-hidden="true"
                    className={cn(
                      selected ? "bg-indigo-500" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-1"
                    )}
                  />
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  type="button"
                  className={cn(
                    selected
                      ? "text-gray-900"
                      : "text-gray-500 hover:text-gray-700",
                    "rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-3 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                  )}
                >
                  Without Charges
                  <span
                    aria-hidden="true"
                    className={cn(
                      selected ? "bg-indigo-500" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-1"
                    )}
                  />
                </button>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className="flex flex-col gap-2">
                <>
                  <div className="pt-2">
                    <div className="flex items-center space-x-2">
                      <label
                        htmlFor="charges"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Charges
                      </label>
                      {appointment?.recommendedSaltingCharges
                        ?.saltedAppointmentId &&
                        !chargesChanged && (
                          <SaltedCharges
                            appointmentId={
                              appointment.recommendedSaltingCharges
                                .saltedAppointmentId
                            }
                          />
                        )}
                    </div>
                    <div className="mt-1">
                      <div className="flex items-center gap-2 flex-wrap">
                        {estimatedCharges.map((ec, idx) => {
                          const modifiers = [
                            ec.chargemasterGroup.modifier1,
                            ec.chargemasterGroup.modifier2,
                            ec.chargemasterGroup.modifier3,
                            ec.chargemasterGroup.modifier4,
                          ]
                            .filter((m) => !!m)
                            .join(" | ");
                          return (
                            <ChargemasterBadge
                              key={idx}
                              chargemaster={
                                ec.chargemasterGroup.primaryChargemaster
                              }
                              trigger={
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border hover:bg-gray-50">
                                  {ec.chargemasterGroup.code}
                                  {modifiers && ` - ${modifiers}`}
                                  <button
                                    type="button"
                                    className="group relative ml-1 -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
                                    onClick={() => {
                                      removeCharge(ec.chargemasterGroup.id);
                                    }}
                                  >
                                    <span className="sr-only">Remove</span>
                                    <svg
                                      viewBox="0 0 14 14"
                                      className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
                                    >
                                      <path d="M4 4l6 6m0-6l-6 6" />
                                    </svg>
                                    <span className="absolute -inset-1" />
                                  </button>
                                </span>
                              }
                            />
                          );
                        })}
                        <UiButton
                          type="button"
                          variant="secondary"
                          size="sm"
                          className="text-xs px-2 py-1 h-6"
                          onClick={() => {
                            setCodeSearchOpen(true);
                            // Reset the charge template selection when charges changed
                            setSelectedChargeTemplateId(null);
                          }}
                        >
                          Add Charge +
                        </UiButton>
                      </div>
                    </div>
                  </div>
                  {codeSearchOpen && (
                    <CodeSearchCommand
                      open={codeSearchOpen}
                      setOpen={setCodeSearchOpen}
                      onSelect={(chargemasterGroup) => {
                        setEstimatedCharges([
                          ...estimatedCharges,
                          { chargemasterGroup, units: 1 },
                        ]);
                        setChargesChanged(true);
                      }}
                    />
                  )}
                </>

                <label className="text-sm font-medium text-gray-700">
                  Or use Charge Template
                </label>

                <Select
                  defaultValue={selectedChargeTemplateId ?? undefined}
                  onValueChange={(templateId) => {
                    setSelectedChargeTemplateId(templateId);
                    const selectedTemplate = chargeTemplates.find(
                      (ct) => ct.id === templateId
                    );
                    setEstimatedCharges(
                      selectedTemplate?.chargeTemplateCharges.map((c) => ({
                        chargemasterGroup: c.chargemasterGroup,
                        units: c.units,
                      })) ?? []
                    );
                    setChargesChanged(true);
                  }}
                >
                  <SelectTrigger
                    className={cn(
                      "w-full pr-2 flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0"
                    )}
                    aria-label="Select account"
                  >
                    <SelectValue placeholder="Select a template">
                      <span className={cn("ml-2")}>
                        {selectedChargeTemplate?.name}
                        {isDefined(recommendedChargeTemplate) &&
                          selectedChargeTemplate?.id ===
                            recommendedChargeTemplate?.id && (
                            <> (Recommended)</>
                          )}
                      </span>
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {chargeTemplates.map((template, i) => (
                      <SelectItem
                        key={template.id}
                        value={template.id}
                        className={cn(i !== 0 && "border-t rounded-t-none")}
                      >
                        <div className="flex flex-col gap-1">
                          <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
                            {template.name}
                            {isDefined(recommendedChargeTemplate) &&
                              template.id === recommendedChargeTemplate?.id && (
                                <> (Recommended)</>
                              )}
                          </div>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <div className="flex justify-end pt-4">
                  <Button
                    type="button"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Create Estimate
                  </Button>
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <CreateEstimateWithoutChargesForm
                appointment={appointment}
                defaultPolicyId={insurancePolicy?.id ?? null}
                policies={policies}
                patientId={patientId}
                defaultDeposit={null}
                closeWizard={closeWizard}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      {open && (
        <CreateEstimateWizardDialog
          appointmentId={appointment.id}
          setOpen={setOpen}
          defaultChargeTemplateId={selectedChargeTemplateId ?? undefined}
          defaultEstimatedCharges={estimatedCharges.map((c) => ({
            ...c,
            chargemaster: c.chargemasterGroup.primaryChargemaster,
            copay: null,
            coinsurance: null,
            remainingDeductible: null,
            remainingOutOfPocket: null,
            deductibleApplies: null,
            coverageBenefitId: null,
            allowedAmount: null,
            estimatedInsurancePolicy: primaryPolicy
              ? {
                  insurancePolicyId: primaryPolicy.id,
                }
              : null,
          }))}
          onComplete={() => {
            setOpen(false);
            closeWizard();
          }}
        />
      )}
    </>
  );
};
