import React, { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import * as SelectPrimitive from "@radix-ui/react-select";
import { cn } from "../../utils";
import { toast } from "react-toastify";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectValue,
} from "../../components/ui/select";
import { WorkflowStage } from "../../generated/globalTypes";
import { Modal, SubmitButton } from "../../components";
import { useUser } from "../../user-context";
import {
  GetInsurancePolicy_insurancePolicy_patient_location_verificationWorkflowStatuses as WorkflowStatus,
  GetInsurancePolicy_insurancePolicy as InsurancePolicy,
} from "../../generated/GetInsurancePolicy";
import { VerificationWorkflowStatusIndicator } from "../verifications/list";

const UPDATE_POLICY_VERIFICATION_WORKFLOW_STATUS_MANUALLY = gql`
  mutation updatePolicyVerificationWorkflowStatusManually(
    $insurancePolicyId: String!
    $statusId: String!
    $userId: String!
    $initiateWorkflow: Boolean!
  ) {
    updatePolicyVerificationWorkflowStatusManually(
      insurancePolicyId: $insurancePolicyId
      statusId: $statusId
      userId: $userId
      initiateWorkflow: $initiateWorkflow
    ) {
      id
      verificationWorkflowStatusId
      verificationWorkflowActiveAt
      verificationWorkflowArchivedAt
    }
  }
`;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      "flex h-7 items-center justify-between rounded-md border border-input bg-background px-2 py-1 text-xs ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      className
    )}
    {...props}
  >
    {children}
  </SelectPrimitive.Trigger>
));

export function InsurancePolicyWorkflowButton({
  insurancePolicy,
  verificationWorkflowStatuses,
}: {
  insurancePolicy: InsurancePolicy;
  verificationWorkflowStatuses: WorkflowStatus[];
}) {
  const { id: userId } = useUser()!;
  const [
    updatePolicyVerificationWorkflowStatusManually,
    updatePolicyVerificationWorkflowStatusManuallyResult,
  ] = useMutation(UPDATE_POLICY_VERIFICATION_WORKFLOW_STATUS_MANUALLY);
  const [open, setOpen] = useState(false);
  const [dialogStatusId, setDialogStatusId] = useState<string>(
    verificationWorkflowStatuses.find((s) => s.stage === WorkflowStage.Todo)
      ?.id ?? ""
  );

  const handleWorkflowStatusChange = (value: string) => {
    updatePolicyVerificationWorkflowStatusManually({
      variables: {
        insurancePolicyId: insurancePolicy.id,
        statusId: value,
        userId,
        initiateWorkflow: false,
      },
      onCompleted: () => {
        toast.success("Verification workflow status updated");
      },
      onError: (error) => {
        toast.error("Verification workflow status update failed");
      },
    });
  };
  const selectedWorkflowStatus = verificationWorkflowStatuses.find(
    (status) => status.id === insurancePolicy.verificationWorkflowStatusId
  );

  const isWorkflowActive =
    selectedWorkflowStatus &&
    insurancePolicy.verificationWorkflowActiveAt &&
    !insurancePolicy.verificationWorkflowArchivedAt;

  return isWorkflowActive ? (
    <Select
      value={insurancePolicy.verificationWorkflowStatusId}
      onValueChange={handleWorkflowStatusChange}
    >
      <SelectTrigger
        className={cn(
          selectedWorkflowStatus ? "" : "!text-gray-500 font-normal"
        )}
      >
        <SelectValue placeholder="Select verification worklist status">
          <div className="flex items-center gap-2">
            <VerificationWorkflowStatusIndicator
              key={selectedWorkflowStatus.id}
              status={selectedWorkflowStatus}
            />
            {selectedWorkflowStatus?.name ??
              "Select verification worklist status"}
          </div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {verificationWorkflowStatuses.map((status) => (
          <SelectItem key={status.id} value={status.id}>
            <div className="flex items-center gap-2">
              <VerificationWorkflowStatusIndicator
                key={status.id}
                status={status}
              />
              {status.name}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  ) : (
    <>
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 print:hidden disabled:opacity-50 disabled:pointer-events-none"
        onClick={() => {
          setOpen(true);
        }}
      >
        <div>Add to Verification Worklist</div>
      </button>
      <Modal open={open} setOpen={setOpen}>
        <div>
          <div className="mt-3 sm:mt-5">
            <h3 className="text-center text-lg leading-6 font-medium text-gray-900">
              Set Your Workflow Status
            </h3>
            <div className="mt-2 py-4">
              <label
                htmlFor="statusId"
                className="block text-sm font-medium text-gray-700"
              >
                Status
              </label>
              <Select value={dialogStatusId} onValueChange={setDialogStatusId}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder={`Select a status`} />
                </SelectTrigger>
                <SelectContent className="w-full">
                  <SelectGroup>
                    {verificationWorkflowStatuses.map((s) => (
                      <SelectItem value={s.id}>
                        <div className="flex items-center gap-2">
                          <VerificationWorkflowStatusIndicator
                            key={s.id}
                            status={s}
                          />
                          {s.name}
                        </div>
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
            <SubmitButton
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              loading={
                updatePolicyVerificationWorkflowStatusManuallyResult.loading
              }
              onClick={() => {
                updatePolicyVerificationWorkflowStatusManually({
                  variables: {
                    insurancePolicyId: insurancePolicy.id,
                    statusId: dialogStatusId,
                    userId,
                    initiateWorkflow: true,
                  },
                  onCompleted: () => {
                    toast.success("Updated policies");
                    setOpen(false);
                  },
                  onError: () => {
                    toast.error("Error updating policies");
                    setOpen(false);
                  },
                });
              }}
            >
              Confirm
            </SubmitButton>
          </div>
        </div>
      </Modal>
    </>
  );
}
