import React, { Fragment, useState } from "react";
import {
  ChevronDownIcon,
  InboxInIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import { FeedbackSurvey } from "../feedback-survey";
import { FeedbackType } from "../../../generated/globalTypes";
import { Disclosure, Menu, Switch, Transition } from "@headlessui/react";
import { gql } from "@apollo/client";
import { GetPublicPatientBillingData_getPublicPatientBillingData as PublicPatient } from "../../../generated/GetPublicPatientBillingData";
import { Link } from "react-router-dom";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const PatientPortalHeader: React.FC<
  React.PropsWithChildren<{
    logoUrl: string | null;
    logout: () => void;
    isAuthenticated: boolean;
    feedbackType: FeedbackType;
    patient: PublicPatient;
  }>
> = ({ logoUrl, logout, isAuthenticated, feedbackType, patient }) => {
  const [showSurvey, setShowSurvey] = useState(false);
  return (
    <Disclosure as="nav" className="bg-white border-b-2 border-gray-300">
      <div className="h-16 mx-auto max-w-7xl flex justify-between items-center px-4 md:px-16">
        {logoUrl && (
          <Link
            to={
              isAuthenticated
                ? `/portal/${patient.organizationId}/${patient.id}`
                : "#"
            }
          >
            <img
              className="flex h-10 m:h-14 w-auto justify-center md:justify-start"
              src={logoUrl}
              alt="Logo"
            />
          </Link>
        )}
        <div className="inline-flex md:visible md:justify-items-end md:space-x-6 md:items-center md:text-sm md:font-medium">
          <div className="hidden md:inline-flex">
            <button
              className="inline-flex items-center space-x-1 justify-center px-4 py-2 text-indigo-900 border border-transparent rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
              onClick={() => setShowSurvey(true)}
            >
              <div>Submit Feedback</div>
              <InboxInIcon className="h-6 w-6" />
            </button>
          </div>
          {/* Mobile menu button */}
          <Disclosure.Button className="flex lg:hidden p-1 rounded-md text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-500">
            <span className="sr-only">Open user menu</span>
            <MenuIcon className="h-6 w-6" />
          </Disclosure.Button>
          <Menu as="div" className="relative ml-4 flex-shrink-0">
            <div>
              <Menu.Button className="hidden lg:flex p-1 rounded-md text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-500">
                <span className="sr-only">Open user menu</span>
                <MenuIcon className="h-6 w-6" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-normal">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={`/portal/${patient.organizationId}/${patient.id}`}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Home
                    </Link>
                  )}
                </Menu.Item>
                {isAuthenticated && (
                  <>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/portal/${patient.organizationId}/${patient.id}/settings`}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Settings
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      <button
                        className="max-w-xs inline-flex items-center text-indigo-500 hover:bg-gray-100 px-4 py-2 w-full"
                        onClick={logout}
                      >
                        Logout
                      </button>
                    </Menu.Item>
                  </>
                )}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <Disclosure.Panel className="lg:hidden">
        <div className="space-y-1 px-2 pt-2 pb-3">
          <Disclosure.Button
            as={Link}
            to={`/portal/${patient.organizationId}/${patient.id}`}
            className="block px-4 py-1 text-sm text-gray-700"
          >
            Home
          </Disclosure.Button>
        </div>
        {isAuthenticated && (
          <>
            <div className="space-y-1 px-2 pt-2 pb-3">
              <Disclosure.Button
                as={Link}
                to={`/portal/${patient.organizationId}/${patient.id}/settings`}
                className="block px-4 py-1 text-sm text-gray-700"
              >
                Settings
              </Disclosure.Button>
            </div>
          </>
        )}
        <div className="space-y-1 px-2 pt-2 pb-3">
          <Disclosure.Button
            onClick={() => setShowSurvey(true)}
            className="flex items-center gap-2 px-4 py-1 text-sm text-gray-700"
          >
            Submit Feedback
            <InboxInIcon className="h-6 w-6" />
          </Disclosure.Button>
        </div>
        {isAuthenticated && (
          <>
            <div className="space-y-1 px-2 pt-2 pb-3">
              <Disclosure.Button
                onClick={logout}
                className="block px-4 py-1 text-sm text-gray-700"
              >
                Logout
              </Disclosure.Button>
            </div>
          </>
        )}
      </Disclosure.Panel>
      <FeedbackSurvey
        title="How was your payment experience?"
        showSurvey={showSurvey}
        setShowSurvey={setShowSurvey}
        feedbackType={feedbackType}
        patientId={patient.id}
      />
    </Disclosure>
  );
};
