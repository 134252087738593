/**
 * Environment dependent constants
 */

type Environment = "development" | "test" | "staging" | "production";

const constants = {
  VITE_API_URL: import.meta.env.VITE_API_URL,
  VITE_GRAPHQL_URL: import.meta.env.VITE_GRAPHQL_URL,
  VITE_SEGMENT_KEY: import.meta.env.VITE_SEGMENT_KEY,
  VITE_INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
  VITE_STYTCH_PUBLIC_TOKEN: import.meta.env.VITE_STYTCH_PUBLIC_TOKEN!,
  VITE_STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY!,
  ENV: import.meta.env.VITE_ENVIRONMENT! as Environment,
  APP_VERSION: __APP_VERSION__,
};

for (const [key, value] of Object.entries(constants)) {
  if (value === undefined)
    throw new Error(`Required env var ${key} not provided.`);
}

export { constants };
