import React from "react";
import { Link } from "react-router-dom";

export const PortalLayout: React.FC<React.PropsWithChildren<{ organizationId?: string }>> = ({
  children,
  organizationId,
}) => {
  const termsOfServiceUrl = organizationId ? `/portal/${organizationId}/terms-of-service` : "/paymybill/terms-of-service";
  const privacyPolicyUrl = organizationId ? `/portal/${organizationId}/privacy-policy` : "/paymybill/privacy-policy";
  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow bg-slate-50">{children}</div>
      <footer className="w-full p-8 border-t border-gray-200">
        <div className="mx-auto max-w-7xl w-full md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 justify-center md:justify-start">
            <Link
              to={termsOfServiceUrl}
              className="text-gray-400 hover:text-gray-500"
            >
              Terms
            </Link>
            <Link
              to={privacyPolicyUrl}
              className="text-gray-400 hover:text-gray-500"
            >
              Privacy
            </Link>
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 text-center md:text-left">
            Powered by{" "}
            <a
              href="https://www.pledge.health/"
              className="text-indigo-500 font-semibold"
            >
              Pledge
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};
