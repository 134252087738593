import { gql, useMutation } from "@apollo/client";
import { ClockIcon } from "@heroicons/react/outline";
import { formatDistanceToNow, isBefore, parseISO } from "date-fns";
import {
  CalculatorIcon,
  MoreHorizontal,
  PauseIcon,
  PlayIcon,
} from "lucide-react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { WorkflowStepStatus, WorkflowStepStatusDisplay } from ".";
import { Tooltip } from "../../../../components";
import { Card } from "../../../../components/card";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import {
  PauseAppointmentEstimation,
  PauseAppointmentEstimationVariables,
} from "../../../../generated/PauseAppointmentEstimation";
import {
  PausePatientEstimation,
  PausePatientEstimationVariables,
} from "../../../../generated/PausePatientEstimation";
import {
  ResumeAppointmentEstimation,
  ResumeAppointmentEstimationVariables,
} from "../../../../generated/ResumeAppointmentEstimation";
import {
  ResumePatientEstimation,
  ResumePatientEstimationVariables,
} from "../../../../generated/ResumePatientEstimation";
import { useFeatureFlags } from "../../../../hooks";
import { formatUSD, isDefined, mapNullable } from "../../../../utils";
import { AppointmentsRow } from "../columns";
import { CreateEstimateWizardDialog } from "../estimate-dialog";

export const PAUSE_APPOINTMENT_ESTIMATION = gql`
  mutation PauseAppointmentEstimation(
    $appointmentId: String!
    $estimationPausedAt: DateTime!
  ) {
    updateOneAppointment(
      where: { id: $appointmentId }
      data: { estimationPausedAt: { set: $estimationPausedAt } }
    ) {
      id
      estimationPausedAt
      account {
        id
        patient {
          id
          estimationPausedAt
        }
      }
    }
  }
`;

export const RESUME_APPOINTMENT_ESTIMATION = gql`
  mutation ResumeAppointmentEstimation($appointmentId: String!) {
    updateOneAppointment(
      where: { id: $appointmentId }
      data: { estimationPausedAt: { set: null } }
    ) {
      id
      estimationPausedAt
      account {
        id
        patient {
          id
          estimationPausedAt
        }
      }
    }
  }
`;

const PAUSE_PATIENT_ESTIMATION = gql`
  mutation PausePatientEstimation(
    $patientId: String!
    $estimationPausedAt: DateTime!
  ) {
    updateOnePatient(
      where: { id: $patientId }
      data: { estimationPausedAt: { set: $estimationPausedAt } }
    ) {
      id
      estimationPausedAt
    }
  }
`;

const RESUME_PATIENT_ESTIMATION = gql`
  mutation ResumePatientEstimation($patientId: String!) {
    updateOnePatient(
      where: { id: $patientId }
      data: { estimationPausedAt: { set: null } }
    ) {
      id
      estimationPausedAt
    }
  }
`;

export const preVisitEstimateIsScheduled = (row: AppointmentsRow) => {
  const estimationPausedAt = mapNullable(parseISO)(
    row.appointment.estimationPausedAt
  );
  const estimationOff = isDefined(estimationPausedAt);
  const preVisitEstimateScheduledAt = mapNullable(parseISO)(
    row.appointment.preVisitEstimateScheduledAt
  );
  const estimateScheduled =
    isDefined(preVisitEstimateScheduledAt) && isBefore(new Date(), row.start);
  return !estimationOff && estimateScheduled;
};

export const EstimationEnabledDialogButton: React.FC<{
  row: AppointmentsRow;
}> = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pauseAppointmentEstimation] = useMutation<
    PauseAppointmentEstimation,
    PauseAppointmentEstimationVariables
  >(PAUSE_APPOINTMENT_ESTIMATION);
  const [resumeAppointmentEstimation] = useMutation<
    ResumeAppointmentEstimation,
    ResumeAppointmentEstimationVariables
  >(RESUME_APPOINTMENT_ESTIMATION);
  const [pausePatientEstimation] = useMutation<
    PausePatientEstimation,
    PausePatientEstimationVariables
  >(PAUSE_PATIENT_ESTIMATION);
  const [resumePatientEstimation] = useMutation<
    ResumePatientEstimation,
    ResumePatientEstimationVariables
  >(RESUME_PATIENT_ESTIMATION);

  const appointmentEstimationPaused = !!row.appointment.estimationPausedAt;
  const patientEstimationPaused = !!row.account.patient.estimationPausedAt;
  const paused = appointmentEstimationPaused || patientEstimationPaused;

  const handleConfirm = async (scope: "appointment" | "patient") => {
    if (scope === "appointment") {
      if (appointmentEstimationPaused) {
        await resumeAppointmentEstimation({
          variables: { appointmentId: row.appointment.id },
          onCompleted: () => {
            toast.success("Estimation resumed");
          },
          onError: () => {
            toast.error("Failed to resume estimation");
          },
        });
      } else {
        await pauseAppointmentEstimation({
          variables: {
            appointmentId: row.appointment.id,
            estimationPausedAt: new Date().toISOString(),
          },
          onCompleted: () => {
            toast.success("Estimation paused");
          },
          onError: () => {
            toast.error("Failed to pause estimation");
          },
        });
      }
    } else {
      if (patientEstimationPaused) {
        await resumePatientEstimation({
          variables: { patientId: row.account.patient.id },
          onCompleted: () => {
            toast.success("Estimation resumed");
          },
          onError: () => {
            toast.error("Failed to resume estimation");
          },
        });
      } else {
        await pausePatientEstimation({
          variables: {
            patientId: row.account.patient.id,
            estimationPausedAt: new Date().toISOString(),
          },
          onCompleted: () => {
            toast.success("Estimation paused");
          },
          onError: () => {
            toast.error("Failed to pause estimation");
          },
        });
      }
    }
    setIsOpen(false);
  };

  let dialogTitle = "";
  let dialogContent = "";

  if (appointmentEstimationPaused && patientEstimationPaused) {
    dialogTitle = "Enable Automated Estimates";
    dialogContent =
      "Do you want to enable automated estimates for this appointment or for all of this patient's appointments?";
  } else if (!appointmentEstimationPaused && !patientEstimationPaused) {
    dialogTitle = "Disable Automated Estimates";
    dialogContent =
      "Do you want to disable automated estimates for this appointment or for all of this patient's appointments?";
  } else if (appointmentEstimationPaused) {
    dialogTitle = "Enable Appointment Estimation";
    dialogContent =
      "Do you want to enable automated estimates for this appointment?";
  } else {
    dialogTitle = "Disable Patient Estimation";
    dialogContent =
      "Do you want to disable automated estimates for all of this patient's appointments?";
  }

  return (
    <>
      <Button
        type="button"
        variant="secondary"
        size="sm"
        onClick={() => setIsOpen(true)}
      >
        {paused ? (
          <>
            <PlayIcon className="h-4 w-4" />
            <span className="ml-1">Resume</span>
          </>
        ) : (
          <>
            <PauseIcon className="h-4 w-4" />
            <span className="ml-1">Pause</span>
          </>
        )}{" "}
      </Button>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogDescription>{dialogContent}</DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex justify-between">
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <div className="flex gap-2">
              {appointmentEstimationPaused && (
                <Button onClick={() => handleConfirm("appointment")}>
                  Enable for Appointment
                </Button>
              )}
              {patientEstimationPaused && (
                <Button onClick={() => handleConfirm("patient")}>
                  Enable for Patient
                </Button>
              )}
              {!appointmentEstimationPaused && !patientEstimationPaused && (
                <Button onClick={() => handleConfirm("appointment")}>
                  Disable for Appointment
                </Button>
              )}
              {!appointmentEstimationPaused && !patientEstimationPaused && (
                <Button onClick={() => handleConfirm("patient")}>
                  Disable for Patient
                </Button>
              )}
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const preVisitEstimateIsComplete = (row: AppointmentsRow) => {
  const lastPreVisitCollectionRequest = row.lastPreVisitCollectionRequest;
  const preVisitEstimated = isDefined(lastPreVisitCollectionRequest);
  return preVisitEstimated;
};

export const EstimationWorkflowHoverCardContent: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const flags = useFeatureFlags();

  const lastPreVisitCollectionRequest = row.lastPreVisitCollectionRequest;
  const preVisitEstimated = preVisitEstimateIsComplete(row);

  const preVisitEstimateScheduledAt = mapNullable(parseISO)(
    row.appointment.preVisitEstimateScheduledAt
  );

  const appointmentEstimationPausedAt = mapNullable(parseISO)(
    row.appointment.estimationPausedAt
  );
  const patientEstimationPausedAt = mapNullable(parseISO)(
    row.account.patient.estimationPausedAt
  );
  const estimationPausedAt =
    appointmentEstimationPausedAt ?? patientEstimationPausedAt;
  const estimationOff = isDefined(estimationPausedAt);
  const estimateScheduled = preVisitEstimateIsScheduled(row);

  let content = null;

  if (preVisitEstimated) {
    content = (
      <div className="text-wrap">
        Pre-visit estimate of {formatUSD(lastPreVisitCollectionRequest!.amount)}
        .
      </div>
    );
  } else if (estimationOff) {
    content = (
      <>
        <div className="text-wrap">
          {patientEstimationPausedAt ? (
            <>
              Automated estimation was turned off for this patient{" "}
              {formatDistanceToNow(patientEstimationPausedAt, {
                addSuffix: true,
              })}
              .
            </>
          ) : appointmentEstimationPausedAt ? (
            <>
              Automated estimation was turned off for this appointment{" "}
              {formatDistanceToNow(appointmentEstimationPausedAt, {
                addSuffix: true,
              })}
              .
            </>
          ) : (
            <>
              Automated estimation was turned off{" "}
              {formatDistanceToNow(estimationPausedAt, {
                addSuffix: true,
              })}
              .
            </>
          )}
        </div>
        {flags.automatedEstimatesEnabled && (
          <div className="flex justify-between gap-2 pt-1 border-t">
            <EstimationEnabledDialogButton row={row} />
          </div>
        )}
      </>
    );
  } else if (estimateScheduled && isDefined(preVisitEstimateScheduledAt)) {
    const isScheduledInFuture = isBefore(
      new Date(),
      preVisitEstimateScheduledAt
    );
    content = (
      <>
        <div className="text-wrap">
          Appointment scheduled to be estimated{" "}
          {isScheduledInFuture && (
            <>
              in{" "}
              {formatDistanceToNow(preVisitEstimateScheduledAt, {
                addSuffix: true,
              })}
            </>
          )}
        </div>
        {flags.automatedEstimatesEnabled && (
          <div className="flex justify-between gap-2 pt-1 border-t">
            <EstimationEnabledDialogButton row={row} />
          </div>
        )}
      </>
    );
  } else if (row.appointment.lastEstimationError) {
    content = (
      <>
        <div className="text-wrap">No estimate yet for visit</div>
        <EstimationErrorDisplay
          estimationError={row.appointment.lastEstimationError}
        />
      </>
    );
  } else {
    content = (
      <>
        <div className="text-wrap">No estimate yet for visit</div>
      </>
    );
  }

  return (
    <Card>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <h2 className="font-semibold">Pre-visit Estimation</h2>
            <WorkflowStepStatusDisplay status={status} />
          </div>
        </div>
        {content}
      </div>
    </Card>
  );
};

export const EstimationErrorDisplay: React.FC<{ estimationError: string }> = ({
  estimationError,
}) => {
  switch (estimationError) {
    case "NoInsurancePolicy":
      return <>Unable to estimate due to missing insurance policy.</>;
    case "NoBenefitsTracked":
      return <>Unable to map due to missing benefits.</>;
    case "NoChargeTemplate":
      return <>No charge template assigned.</>;
    case "NoBenefitsMapped":
      return <>Unable to map benefits to charges.</>;
    case "MissingAllowedAmount":
      return <>Missing allowed amount.</>;
    case "UnrecognizedChargemaster":
      return <>Unrecognized charge.</>;
  }
  return <>Unable to automatically create estimate.</>;
};

export const PreVisitEstimationWorkflowNextAction: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const [open, setOpen] = useState(false);

  const preVisitEstimateScheduledAt = mapNullable(parseISO)(
    row.appointment.preVisitEstimateScheduledAt
  );

  let content = null;
  if (status === "not_started") {
    // TODO: Implement actions
    content = (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <Button
            variant="outline"
            size="sm"
            className="flex items-center gap-1"
            onClick={() => setOpen(true)}
          >
            <CalculatorIcon className="h-4 w-4" />
            Create Estimate
          </Button>
        </div>
      </div>
    );
  } else if (status === "scheduled" && preVisitEstimateScheduledAt) {
    const isScheduledInFuture = isBefore(
      new Date(),
      preVisitEstimateScheduledAt
    );
    content = (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <Tooltip
            content={
              <>
                Appointment scheduled to be estimated{" "}
                {isScheduledInFuture && (
                  <>
                    in{" "}
                    {formatDistanceToNow(preVisitEstimateScheduledAt, {
                      addSuffix: true,
                    })}
                  </>
                )}
              </>
            }
            trigger={
              <div className="flex items-center gap-1">
                <ClockIcon className="h-4 w-4 text-gray-400" />
                Estimate Scheduled
              </div>
            }
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => {
                setOpen(true);
              }}
            >
              Create Estimate
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  } else if (status === "action_required") {
    content = (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <Button
            variant="outline"
            size="sm"
            className="flex items-center gap-1"
            onClick={() => setOpen(true)}
          >
            <CalculatorIcon className="h-4 w-4" />
            Create Estimate
          </Button>
        </div>
      </div>
    );
  }
  return (
    <>
      {content}
      {open && (
        <CreateEstimateWizardDialog
          appointmentId={row.id}
          setOpen={setOpen}
          onComplete={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
