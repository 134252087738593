import React from "react";
import { gql, useQuery } from "@apollo/client";
import { compareDesc, format, formatRelative, parseISO } from "date-fns";

import { DataTable } from "./table";
import { ColumnDef, ColumnFiltersState } from "@tanstack/react-table";
import { DataTableColumnHeader } from "../../../components/ui/table-helpers/data-table-column-header";
import { formatUSD, isDefined, mapNullable } from "../../../utils";
import { useUser } from "../../../user-context";
import {
  AppointmentStatus,
  BillCollectionMode,
  BillState,
} from "../../../generated/globalTypes";
import { ProviderCell } from "../../appointments/table/columns";
import {
  ArchivedStatusIndicator,
  InReviewStatusIndicator,
  PaidStatusIndicator,
  ReadyStatusIndicator,
  StatusIndicator,
} from "../../billing";
import {
  CheckCircleIcon,
  ClockIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import {
  GetPatientVisits,
  GetPatientVisitsVariables,
  GetPatientVisits_appointments as Appointment,
} from "../../../generated/GetPatientVisits";
import { Tooltip } from "../../../components";
import { VisitBillStatus } from "./types";
import { useFeatureFlags } from "../../../hooks";

export const GET_PATIENT_VISITS = gql`
  query GetPatientVisits($patientId: String!) {
    appointments(
      where: { account: { is: { patientId: { equals: $patientId } } } }
      orderBy: { start: { sort: desc, nulls: last } }
    ) {
      id
      createdAt
      updatedAt
      status

      # Estimation Workflow state
      estimationWorkflowStatus {
        id
        name
        stage
      }
      lastEstimationWorkflowStartedAt
      lastEstimationWorkflowCompletedAt
      lastEstimationWorkflowCanceledAt
      lastEstimationError
      estimationWorkflowActiveAt
      estimationWorkflowArchivedAt

      start
      end
      appointmentLabelings {
        id
        appointmentLabel {
          id
          name
        }
      }
      account {
        id
        accountType {
          id
          name
        }
        patient {
          id
          displayName
        }
      }
      insurancePolicies {
        id
        priority
        memberId
        insurancePolicyVerificationStatus
        payer {
          id
          name
          eligibilityEnabled
        }
      }
      provider {
        id
        displayName
      }
      mostRecentVisitCollectionRequest {
        id
        amount
      }
      bill {
        id
        status
        charges {
          id
        }
        toCollect {
          collectionMode
          patientBalance
          patientResponsibility
          patientPaid
        }
      }
    }
    # Bills that don't belong to an appointment
    bills(
      where: {
        account: { is: { patientId: { equals: $patientId } } }
        appointmentId: { equals: null }
      }
      orderBy: { dateOfService: { sort: desc, nulls: last } }
    ) {
      id
      dateOfService
      status
      charges {
        id
        customCode
        units
        insuranceBillableCharges {
          id
          status
          accountCoverage {
            id
            insurancePolicy {
              id
              memberId
              payer {
                id
                name
              }
            }
          }
        }
      }
      account {
        id
        accountType {
          id
          name
        }
      }
      primaryProvider {
        id
        displayName
      }
      toCollect {
        collectionMode
        patientBalance
        patientResponsibility
        patientPaid
      }
    }
  }
`;

export const VisitBillStatusIndicator: React.FC<{
  billStatus: VisitBillStatus;
}> = ({ billStatus }) => {
  switch (billStatus) {
    case VisitBillStatus.Archived:
      return (
        <div className="flex items-center gap-1.5 text-sm text-gray-700">
          <ArchivedStatusIndicator /> Archived
        </div>
      );
    case VisitBillStatus.AwaitingCharges:
      return (
        <div className="flex items-center gap-1 text-sm text-gray-700">
          <ClockIcon className="w-4 h-4 text-gray-300" />
          Awaiting Charges
        </div>
      );
    case VisitBillStatus.ChargesEntered:
      return (
        <div className="flex items-center gap-1 text-sm text-gray-700">
          <CheckCircleIcon className="w-4 h-4 text-green-500" />
          Charges Entered
        </div>
      );
    case VisitBillStatus.InReview:
      return (
        <div className="flex items-center gap-1.5 text-sm text-gray-700">
          <InReviewStatusIndicator /> In Review
        </div>
      );
    case VisitBillStatus.Ready:
      return (
        <div className="flex items-center gap-1.5 text-sm text-gray-700">
          <ReadyStatusIndicator /> Ready
        </div>
      );
    case VisitBillStatus.Paid:
      return (
        <div className="flex items-center gap-1.5 text-sm text-gray-700">
          <PaidStatusIndicator /> Paid
        </div>
      );
  }
};

export type PatientVisitRow = {
  id: string;
  start: Date | null;
  appointment: Appointment | null;
  status: string;
  providerId: string | null;
  providerName: string | null;
  appointmentLabels: string[];
  billStatus: VisitBillStatus;
  collectionMode: BillCollectionMode | null;
  accountType: string | null;
};

export const columns: ColumnDef<PatientVisitRow>[] = [
  {
    id: "start",
    accessorKey: "start",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Appointment" />
    ),
    cell: ({ row }) => {
      if (!row.original.start) return null;
      return (
        <div className="[&:first-letter]:capitalize">
          {format(row.original.start, "MMM d, yyyy")}
        </div>
      );
    },
  },
  {
    id: "time",
    accessorKey: "start",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Time" />
    ),
    cell: ({ row }) => {
      if (!row.original.start) return null;
      return (
        <div className="[&:first-letter]:capitalize">
          {format(row.original.start, "h:mm aa")}
          {isDefined(row.original.appointment?.end) &&
            `- ${format(parseISO(row.original.appointment?.end), "h:mm aa")}`}
        </div>
      );
    },
  },
  {
    id: "status",
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "providerName",
    accessorKey: "providerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Provider" />
    ),
    cell: ({ row }) => {
      return (
        <ProviderCell
          appointmentLabels={(
            row.original.appointment?.appointmentLabelings ?? []
          ).map((labeling) => labeling.appointmentLabel)}
          providerName={row.original.providerName}
        />
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "accountType",
    accessorKey: "accountType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Account" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "billStatus",
    accessorKey: "billStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Bill Status" />
    ),
    cell: ({ row }) => {
      return <VisitBillStatusIndicator billStatus={row.original.billStatus} />;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "patientResponsibility",
    accessorKey: "patientResponsibility",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Patient Resp"
        className="justify-end"
      />
    ),
    cell: ({ row }) => {
      const patientResponsibility = row.original.patientResponsibility;
      const estimated =
        row.original.collectionMode === BillCollectionMode.Estimate ||
        row.original.collectionMode === BillCollectionMode.Deposit;
      return (
        <div className="pr-2 flex gap-1 items-center justify-end">
          {estimated && (
            <Tooltip
              trigger={<LightningBoltIcon className="w-4 h-4 text-gray-500" />}
              content={<>Estimated amount</>}
            />
          )}
          {mapNullable(formatUSD)(patientResponsibility)}
        </div>
      );
    },
  },
  {
    id: "patientBalance",
    accessorKey: "patientBalance",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Patient Balance"
        className="justify-end"
      />
    ),
    cell: ({ row }) => {
      const patientBalance = row.original.patientBalance;
      return (
        <div className="text-right pr-2">
          {mapNullable(formatUSD)(patientBalance)}
        </div>
      );
    },
  },
];

export const getBillStatus = (
  bill: Pick<Bill, "status" | "charges"> | null
) => {
  let billStatus;
  if (bill) {
    if (bill.status === BillState.Archived) {
      billStatus = VisitBillStatus.Archived;
    } else if (bill.charges.length === 0) {
      billStatus = VisitBillStatus.AwaitingCharges;
    } else {
      switch (bill.status) {
        case BillState.InReview:
          billStatus = VisitBillStatus.InReview;
          break;
        case BillState.Ready:
          billStatus = VisitBillStatus.Ready;
          break;
        case BillState.Reconciled:
          billStatus = VisitBillStatus.Paid;
          break;
        case BillState.Resolved:
          billStatus = VisitBillStatus.Paid;
          break;
        default:
          billStatus = VisitBillStatus.ChargesEntered;
      }
    }
  } else {
    billStatus = VisitBillStatus.AwaitingCharges;
  }
  return billStatus;
};
export const getVisitBillStatus = (appointment: Pick<Appointment, "bill">) => {
  const bill = appointment.bill?.at(0);
  return getBillStatus(bill);
};

export const PatientVisitList: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const user = useUser()!;
  const flags = useFeatureFlags();

  const { data, loading } = useQuery<
    GetPatientVisits,
    GetPatientVisitsVariables
  >(GET_PATIENT_VISITS, {
    variables: {
      patientId,
    },
  });

  const appointments = data?.appointments ?? [];
  const bills = data?.bills ?? [];

  const tableData: PatientVisitRow[] = [
    ...appointments.map((row) => {
      const appointment = row;
      let start = appointment?.start ? parseISO(appointment?.start) : null;

      const bill = appointment.bill?.at(0);
      const billStatus = getVisitBillStatus(appointment);

      return {
        id: row.id,
        start,
        status: appointment.status,
        appointment,
        providerId: appointment.provider?.id ?? null,
        providerName: appointment.provider?.displayName,
        appointmentLabels: appointment.appointmentLabelings.map(
          (labeling) => labeling.appointmentLabel.name
        ),
        accountType: appointment.account.accountType?.name,
        billStatus,
        collectionMode: bill?.toCollect?.collectionMode ?? null,
        patientResponsibility: bill?.toCollect?.patientResponsibility,
        patientBalance: bill?.toCollect?.patientBalance,
      };
    }),
    ...bills.map((bill) => {
      const billStatus = getBillStatus(bill);

      return {
        id: bill.id,
        start: mapNullable(parseISO)(bill.dateOfService),
        status: null,
        appointment: null,
        providerId: bill.primaryProvider?.id ?? null,
        providerName: bill.primaryProvider?.displayName,
        appointmentLabels: [],
        accountType: bill.account.accountType?.name,
        billStatus,
        collectionMode: bill?.toCollect?.collectionMode ?? null,
        patientResponsibility: bill?.toCollect?.patientResponsibility,
        patientBalance: bill?.toCollect?.patientBalance,
      };
    }),
  ].sort((a, b) => compareDesc(a.start, b.start));

  const locationEstimationWorkflowStatuses =
    data?.location?.estimationWorkflowStatuses ?? [];

  const statuses = locationEstimationWorkflowStatuses.map((s) => ({
    id: s.id,
    name: s.name,
    stage: s.stage,
    count: rows.filter((row) => row.estimationWorkflowStatus.id === s.id)
      .length,
  }));

  return (
    <div className="flex flex-col gap-8">
      <DataTable
        data={tableData}
        columns={columns.filter((column) => {
          // Hide the bill status column if charges are not supported
          if (!flags.chargesSupported && column.id === "billStatus") {
            return false;
          }
          if (
            !flags.chargesSupported &&
            column.id === "patientResponsibility"
          ) {
            return false;
          }
          if (!flags.chargesSupported && column.id === "patientBalance") {
            return false;
          }
          return true;
        })}
        loading={loading}
        defaultColumnFilters={[
          {
            id: "status",
            value: [
              AppointmentStatus.Booked,
              AppointmentStatus.CheckedIn,
              AppointmentStatus.Completed,
              null,
            ],
          },
          {
            id: "billStatus",
            value: [
              VisitBillStatus.AwaitingCharges,
              VisitBillStatus.ChargesEntered,
              VisitBillStatus.InReview,
              VisitBillStatus.Ready,
              VisitBillStatus.Paid,
            ],
          },
        ]}
      />
    </div>
  );
};
