import { useForm } from "react-hook-form";
import { SettingsLayout } from "..";
import {
  GetMyOrganization_me_organization as Organization,
  GetMyOrganization_me_organization_locations as Location,
} from "../../../generated/GetMyOrganization";
import { toast } from "react-toastify";
import { gql, useMutation } from "@apollo/client";
import { Card, Spinner } from "../../../components";
import { useParams } from "react-router-dom";

const UPDATE_LOCATION_SETTINGS = gql`
  mutation UpdateLocationSettings(
    $id: String!
    $name: String!
    $address1: String!
    $city: String!
    $state: String!
    $country: String!
    $postalCode: String!
  ) {
    updateOneLocation(
      where: { id: $id }
      data: {
        name: { set: $name }
        address1: { set: $address1 }
        city: { set: $city }
        state: { set: $state }
        country: { set: $country }
        postalCode: { set: $postalCode }
      }
    ) {
      id
      name
      address1
      city
      state
      country
      postalCode
    }
  }
`;

type LocationForm = {
  name: string;
  address1: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};

const LocationForm: React.FC<{
  organization: Organization;
  location: Location;
}> = ({ organization, location }) => {
  const [updateLocation, updateLocationResult] = useMutation(
    UPDATE_LOCATION_SETTINGS
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<LocationForm>({
    defaultValues: {
      name: location.name,
      address1: location.address1,
      city: location.city,
      state: location.state,
      country: location.country,
      postalCode: location.postalCode,
    },
  });

  const onSubmit = (data: any) => {
    updateLocation({
      variables: {
        id: location.id,
        name: data.name,
        address1: data.address1,
        city: data.city,
        state: data.state,
        country: data.country,
        postalCode: data.postalCode,
      },
      onCompleted: () => {
        toast.success("Settings updated");
      },
      onError: (error) => {
        toast.error("Error updating settings");
      },
    });
  };

  return (
    <SettingsLayout
      title={location.name}
      description="Manage location settings."
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    {...register("name")}
                    className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country / Region
                </label>
                <select
                  required
                  {...register("country")}
                  className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                >
                  <option value="United States">United States</option>
                  <option value="Canada">Canada</option>
                  <option value="Mexico">Mexico</option>
                </select>
              </div>

              <div className="col-span-6">
                <label
                  htmlFor="address1"
                  className="block text-sm font-medium text-gray-700"
                >
                  Street address
                </label>
                <input
                  required
                  type="text"
                  {...register("address1")}
                  className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City
                </label>
                <input
                  type="text"
                  required
                  {...register("city")}
                  className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-gray-700"
                >
                  State / Province
                </label>
                <input
                  type="text"
                  required
                  {...register("state")}
                  className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="postalCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  ZIP / Postal
                </label>
                <input
                  type="text"
                  required
                  {...register("postalCode")}
                  className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                />
              </div>
            </div>
          </div>
        </Card>
        <div className="pt-2 pr-1 text-right">
          <div className="flex justify-end items-center">
            {updateLocationResult.loading && (
              <Spinner className="text-indigo-600 h-5" />
            )}
            <button
              type="submit"
              disabled={updateLocationResult.loading}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </SettingsLayout>
  );
};

export const WorkspaceLocationSettings: React.FC<{
  organization: Organization;
}> = ({ organization }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const location = organization.locations.find((l) => l.id === locationId)!;
  return (
    <LocationForm
      key={locationId}
      location={location}
      organization={organization}
    />
  );
};
