import React from "react";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";

import { GetLatestPortalBenefitsForInsurancePolicy_latestPortalBenefitsForInsurancePolicy_inNetworkIndvPlanBenefits as PlanBenefits } from "../../../generated/GetLatestPortalBenefitsForInsurancePolicy";
import { DEDUCTIBLE_HELP_TEXT, OOP_HELP_TEXT } from "./help-text";
import { isDefined } from "../../../utils";

type OpenHelpDialog = (args: { label: string; helpText: string }) => void;

export const MetDeductibleMessage: React.FC<
  React.PropsWithChildren<{
    setHelpDialog: OpenHelpDialog;
  }>
> = ({ setHelpDialog }) => (
  <div className="flex space-x-1 pt-4">
    <CheckCircleIcon className="h-[20px] w-[20px] text-green-600" />
    <p>
      You've met your
      <button
        onClick={() =>
          setHelpDialog({
            label: "Deductible",
            helpText: DEDUCTIBLE_HELP_TEXT,
          })
        }
      >
        <span className="decoration-dotted underline underline-offset-2 pl-1">
          Deductible
        </span>
      </button>
      !
    </p>
  </div>
);

export const NotMetDeductibleMessage: React.FC<
  React.PropsWithChildren<{
    setHelpDialog: OpenHelpDialog;
  }>
> = ({ setHelpDialog }) => (
  <div className="flex space-x-1 pt-4">
    <MinusCircleIcon className="h-[20px] w-[20px] text-yellow-600" />
    <p>
      You haven't hit your
      <button
        onClick={() =>
          setHelpDialog({
            label: "Deductible",
            helpText: DEDUCTIBLE_HELP_TEXT,
          })
        }
      >
        <span className="decoration-dotted underline underline-offset-2 pl-1">
          Deductible
        </span>
      </button>
    </p>
  </div>
);

export const MetOopMessage: React.FC<
  React.PropsWithChildren<{ setHelpDialog: OpenHelpDialog }>
> = ({ setHelpDialog }) => (
  <div className="flex space-x-1 pt-4">
    <CheckCircleIcon className="h-6 w-6 text-green-600" />
    <p>
      You've met your
      <button
        onClick={() =>
          setHelpDialog({
            label: "Out of Pocket",
            helpText: OOP_HELP_TEXT,
          })
        }
      >
        <span className="decoration-dotted underline underline-offset-2 px-1">
          Out of Pocket
        </span>
      </button>
      max!
    </p>
  </div>
);

export const NotMetOopMessage: React.FC<
  React.PropsWithChildren<{ setHelpDialog: OpenHelpDialog }>
> = ({ setHelpDialog }) => (
  <div className="flex space-x-1 pt-4">
    <MinusCircleIcon className="h-6 w-6 text-yellow-600" />
    <p>
      You haven't hit your
      <button
        onClick={() =>
          setHelpDialog({
            label: "Out of Pocket",
            helpText: OOP_HELP_TEXT,
          })
        }
      >
        <span className="decoration-dotted underline underline-offset-2 px-1">
          Out of Pocket
        </span>
        max
      </button>
    </p>
  </div>
);

export const PlanProgress: React.FC<
  React.PropsWithChildren<{
    planBenefits: {
      indv: {
        deductible: Pick<PlanBenefits["deductible"], "metDeductible">;
        outOfPocket: Pick<PlanBenefits["outOfPocket"], "metOutOfPocketMax">;
      };
      fam: {
        deductible: Pick<PlanBenefits["deductible"], "metDeductible">;
        outOfPocket: Pick<PlanBenefits["outOfPocket"], "metOutOfPocketMax">;
      };
    };
    setHelpDialog: (args: { label: string; helpText: string }) => void;
  }>
> = ({ planBenefits, setHelpDialog }) => {
  const metDeductible =
    planBenefits.indv.deductible.metDeductible ||
    planBenefits.fam.deductible.metDeductible;
  const metOopMax =
    planBenefits.indv.outOfPocket.metOutOfPocketMax ||
    planBenefits.fam.outOfPocket.metOutOfPocketMax;

  return (
    <div className="text-lg">
      {!isDefined(metDeductible) ? null : metDeductible ? (
        <MetDeductibleMessage setHelpDialog={setHelpDialog} />
      ) : (
        <NotMetDeductibleMessage setHelpDialog={setHelpDialog} />
      )}
      {!isDefined(metOopMax) ? null : metOopMax ? (
        <MetOopMessage setHelpDialog={setHelpDialog} />
      ) : (
        <NotMetOopMessage setHelpDialog={setHelpDialog} />
      )}
    </div>
  );
};
