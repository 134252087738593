import { Link, LinkProps } from "react-router-dom";
import { classNames } from "../utils";

export const DisableableLink: React.FC<React.PropsWithChildren<LinkProps & { disabled?: boolean }>> = (
  props
) => (
  <Link
    {...props}
    className={classNames(
      props.className || "",
      props.disabled ? "cursor-not-allowed" : ""
    )}
    onClick={(event) => props.disabled && event.preventDefault()}
  />
);
