import React, { useMemo, useState, useRef, useEffect } from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../utils";
import { Button } from "./button";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Badge } from "./badge";
import fuzzysort from "fuzzysort";
import { useVirtualizer } from "@tanstack/react-virtual";

interface CodeCodeset {
  name: string;
  sections: CodeSection[];
  codeRange: string;
}

interface CodeSection {
  name: string;
  levelOne: CodeLevelOne[];
  codeRange: string;
}

interface CodeLevelOne {
  name: string;
  levelTwo: CodeLevelTwo[];
  codeRange: string;
}

interface CodeLevelTwo {
  name: string;
  levelThree: CodeLevelThree[];
  codeRange: string;
}

interface CodeLevelThree {
  name: string;
  levelFour: CodeLevelFour[];
  codeRange: string;
}

interface CodeLevelFour {
  name: string;
  levelFive: CodeLevelFive[];
  codeRange: string;
}

interface CodeLevelFive {
  name: string;
  codeRange: string;
}

interface CodeCategoryComboboxProps {
  value: string[];
  codeCategories: CodeSection[];
  onSelect: (value: string[]) => void;
  placeholder: string;
  emptyMessage: string;
}

const normalizeScore = (score: number | null) => {
  const MIN_SCORE = -3000;
  if (score === null || score < MIN_SCORE) return 0;
  return (score - MIN_SCORE) / -MIN_SCORE;
};

const isCodeInRange = (code: string, range: string): boolean => {
  const [start, end] = range.split("-").map((c) => c.trim());
  return code >= start && code <= end;
};

const isHcpcsCode = (code: string): boolean => {
  return code.length === 5 && /^[A-Z]\d{4}$/.test(code);
};

const isCptCode = (code: string): boolean => {
  return code.length === 5 && !isNaN(Number(code));
};

export const CodeCategoryCombobox: React.FC<CodeCategoryComboboxProps> = ({
  value,
  codeCategories,
  onSelect,
  placeholder,
  emptyMessage,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const isCodeSearch = isCptCode(search) || isHcpcsCode(search);

  const flattenedItems = useMemo(() => {
    const items: {
      fullPathString: string;
      name: string;
      level: number;
      codeRange: string;
    }[] = [];

    const flatten = (
      codesets: (
        | CodeCodeset
        | CodeSection
        | CodeLevelOne
        | CodeLevelTwo
        | CodeLevelThree
        | CodeLevelFour
        | CodeLevelFive
      )[],
      level: number,
      parentPath: string[] = []
    ) => {
      console.log(codesets);
      codesets.forEach((item) => {
        const fullPath = [...parentPath, item.name];
        const fullPathString = fullPath.join(" > ");
        items.push({
          fullPathString,
          name: item.name,
          level,
          codeRange: item.codeRange,
        });

        if ("sections" in item) flatten(item.sections, level + 1, fullPath);
        if ("levelOne" in item) flatten(item.levelOne, level + 1, fullPath);
        if ("levelTwo" in item) flatten(item.levelTwo, level + 1, fullPath);
        if ("levelThree" in item) flatten(item.levelThree, level + 1, fullPath);
        if ("levelFour" in item) flatten(item.levelFour, level + 1, fullPath);
        if ("levelFive" in item) flatten(item.levelFive, level + 1, fullPath);
      });
    };

    flatten(codeCategories, 0);
    return items;
  }, [codeCategories]);

  const filteredItems = useMemo(() => {
    if (search.length === 0) return flattenedItems;

    // If the search is a 5 digit number, filter by code range
    if (isCodeSearch) {
      return flattenedItems.filter((item) =>
        isCodeInRange(search, item.codeRange)
      );
    }

    return flattenedItems.filter((item) => {
      const result = fuzzysort.single(
        search.toLowerCase(),
        item.fullPathString.toLowerCase()
      );
      return normalizeScore(result?.score ?? null) > 0;
    });
  }, [flattenedItems, search, isCodeSearch]);

  const virtualizer = useVirtualizer({
    count: filteredItems.length,
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: () => 35,
    overscan: 5,
  });

  useEffect(() => {
    virtualizer.measure();
  }, [filteredItems, virtualizer]);

  const renderRow = (index: number) => {
    const item = filteredItems[index];
    return (
      <CommandItem
        key={item.fullPathString}
        value={item.fullPathString}
        onSelect={() => {
          onSelect(
            value.includes(item.fullPathString)
              ? value.filter((v) => v !== item.fullPathString)
              : [...value, item.fullPathString]
          );
        }}
        className={cn(
          "flex items-center",
          item.level === 0 && "pl-0",
          item.level === 1 && "pl-4",
          item.level === 2 && "pl-8",
          item.level === 3 && "pl-12",
          item.level === 4 && "pl-16",
          item.level === 5 && "pl-20"
        )}
      >
        <Check
          className={cn(
            "mr-2 h-4 w-4 flex-shrink-0",
            value.includes(item.fullPathString) ? "opacity-100" : "opacity-0"
          )}
        />
        <span className="font-medium truncate flex-grow">{item.name}</span>
        <span className="ml-auto text-sm text-gray-500 flex-shrink-0 font-mono">
          {item.codeRange ? `(${item.codeRange})` : ""}
        </span>
      </CommandItem>
    );
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "justify-between w-full h-fit min-w-10",
            value.length > 0 && "text-muted-foreground"
          )}
        >
          {value.length > 0 ? (
            <div className="flex gap-1 flex-wrap">
              {value.map((category) => (
                <Badge
                  key={category}
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {category}
                </Badge>
              ))}
            </div>
          ) : (
            placeholder
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-[700px] p-0">
        <Command shouldFilter={false}>
          <div className="flex items-center border-b px-3 w-full">
            {isCodeSearch ? (
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal mr-2 flex-none"
              >
                Code Search
              </Badge>
            ) : null}
            <CommandInput
              placeholder={`Search ${placeholder.toLowerCase()}...`}
              value={search}
              onValueChange={(newSearch) => {
                setSearch(newSearch);
                virtualizer.scrollToIndex(0);
              }}
              className={cn(
                isCodeSearch && "border-none shadow-none focus:ring-0",
                "w-full"
              )}
            />
          </div>
          <CommandGroup className="overflow-hidden">
            {filteredItems.length === 0 ? (
              <CommandEmpty>{emptyMessage}</CommandEmpty>
            ) : (
              <div
                ref={scrollContainerRef}
                className="max-h-[300px] overflow-y-auto"
              >
                <div
                  style={{
                    height: `${virtualizer.getTotalSize()}px`,
                    width: "100%",
                    position: "relative",
                  }}
                >
                  {virtualizer.getVirtualItems().map((virtualItem) => (
                    <div
                      key={virtualItem.key}
                      data-index={virtualItem.index}
                      ref={virtualizer.measureElement}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        transform: `translateY(${virtualItem.start}px)`,
                      }}
                    >
                      {renderRow(virtualItem.index)}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
