import React, { useState } from "react";
import { InformationCircleIcon, PlusIcon } from "@heroicons/react/outline";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";

import { useUser } from "../../user-context";
import { Table, Td } from "../../components/Table";
import { GetProviders_providers as Provider } from "../../generated/GetProviders";
import { toast } from "react-toastify";
import { OvalSpinner } from "../../components/loading";
import { classNames, isDefined } from "../../utils";
import {
  SetProviderHidden,
  SetProviderHiddenVariables,
} from "../../generated/SetProviderHidden";
import { Modal, Tooltip } from "../../components";
import { Layout } from "../layout";
import { MakeProviderDefaultForLocationEligibility } from "../../generated/MakeProviderDefaultForLocationEligibility";

const PROVIDER_LIST_FRAGMENT = gql`
  fragment ProviderListFragment on Provider {
    id
    type
    firstName
    lastName
    displayName
    organizationName
    npi
    hidden
    billingGroup {
      id
      name
      npi
    }
    defaultEligibilityProviderLocations {
      id
    }
    providerNetworkParticipations {
      id
      inNetwork
      payer {
        id
        name
      }
    }
  }
`;

export const GET_PROVIDERS = gql`
  ${PROVIDER_LIST_FRAGMENT}
  query GetProviders($organizationId: String!, $locationId: String!) {
    providers(
      where: {
        organizationId: { equals: $organizationId }
        primaryLocationId: { equals: $locationId }
        deletedAt: { equals: null }
      }
      orderBy: { updatedAt: desc }
    ) {
      ...ProviderListFragment
    }
  }
`;

const EmptyProviders: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className="text-center">
    <svg
      className="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
    <h3 className="mt-2 text-sm font-medium text-gray-900">No providers</h3>
    <p className="mt-1 text-sm text-gray-500">
      Get started by creating a new provider.
    </p>
    <div className="mt-6">
      <Link
        to="/providers/new"
        className="flex px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        New Provider
      </Link>
    </div>
  </div>
);

const SET_PROVIDER_HIDDEN = gql`
  ${PROVIDER_LIST_FRAGMENT}
  mutation SetProviderHidden($providerId: String!, $hidden: Boolean!) {
    updateOneProvider(
      where: { id: $providerId }
      data: { hidden: { set: $hidden } }
    ) {
      ...ProviderListFragment
    }
  }
`;

const MAKE_PROVIDER_DEFAULT_FOR_LOCATION_ELIGIBILITY = gql`
  ${PROVIDER_LIST_FRAGMENT}
  mutation MakeProviderDefaultForLocationEligibility($providerId: String!) {
    updateOneProvider(
      where: { id: $providerId }
      data: {
        primaryLocation: {
          update: {
            defaultEligibilityProvider: { connect: { id: $providerId } }
          }
        }
      }
    ) {
      ...ProviderListFragment
    }
  }
`;

const ProviderHiddenToggleCell: React.FC<
  React.PropsWithChildren<{ provider: Provider }>
> = ({ provider }) => {
  const [enabled, setEnabled] = useState(provider.hidden);
  const [setProviderHidden, setProviderHiddenResult] = useMutation<
    SetProviderHidden,
    SetProviderHiddenVariables
  >(SET_PROVIDER_HIDDEN);
  const onChange = (val: boolean) => {
    setEnabled(val);
    setProviderHidden({
      variables: {
        providerId: provider.id,
        hidden: val,
      },
      onCompleted: () => {
        if (val) {
          toast.success("Payer hidden");
        } else {
          toast.success("Payer unhidden");
        }
      },
      onError: () => {
        toast.error("Failed to update provider");
      },
    });
  };
  return (
    <Td>
      <div
        className="flex items-center gap-1 text-sm text-gray-900"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Switch
          checked={enabled}
          onChange={onChange}
          className={classNames(
            enabled ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
          disabled={setProviderHiddenResult.loading}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
        {setProviderHiddenResult.loading && <OvalSpinner className="w-4 h-4" />}
      </div>
    </Td>
  );
};

const ProviderDefaultDialog: React.FC<{
  setOpen: (open: boolean) => void;
  provider: Provider;
}> = ({ setOpen, provider }) => {
  const user = useUser()!;
  const [makeProviderDefaultForLocationEligibility] =
    useMutation<MakeProviderDefaultForLocationEligibility>(
      MAKE_PROVIDER_DEFAULT_FOR_LOCATION_ELIGIBILITY
    );
  const onClick = () => {
    makeProviderDefaultForLocationEligibility({
      variables: {
        providerId: provider.id,
      },
      onCompleted: () => {
        toast.success("Provider set as default");
        setOpen(false);
      },
      onError: () => {
        toast.error("Failed to set provider as default");
      },
      refetchQueries: [GET_PROVIDERS],
    });
  };

  return (
    <Modal open={true} setOpen={setOpen} size="md">
      <div className="min-w-1/2" onClick={(e) => e.stopPropagation()}>
        <div className="mt-3 text-center sm:mt-5">
          <div className="flex justify-center gap-2 items-center text-2xl pb-4">
            Set as Default Eligibility Provider
          </div>
        </div>
        <div className="mt-4">
          <p className="text-gray-500">
            Are you sure you want to set {provider.displayName} as the default
            provider for to use for eligibility requests for{" "}
            {user.activeLocation.name}?
          </p>
        </div>
        <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            onClick={onClick}
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          >
            Set as Default
          </button>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

const ProviderDefaultDialogButton: React.FC<{
  provider: Provider;
}> = ({ provider }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        className="inline-flex justify-center items-center min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-indigo-500 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:text-gray-500 disabled:hover:bg-gray-200"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        disabled={!provider.npi}
      >
        Make Default
      </button>
      {open && <ProviderDefaultDialog setOpen={setOpen} provider={provider} />}
    </>
  );
};

export const ProviderList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser()!;
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_PROVIDERS, {
    variables: {
      organizationId: user!.organization.id,
      locationId: user!.activeLocation.id,
    },
  });
  if (loading) return <>Loading</>;
  if (!data) return null;
  if (data.providers.length === 0) return <EmptyProviders />;
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <Table
            columnDefs={[
              {
                header: "Name",
                cellFn: (provider: Provider) => {
                  let name;
                  if (provider.type === "Organization") {
                    name = provider.organizationName;
                  } else {
                    name = [provider.firstName, provider.lastName]
                      .filter(isDefined)
                      .join(" ");
                  }
                  const defaultProvider =
                    provider.defaultEligibilityProviderLocations.some(
                      (l) => l.id === user.activeLocation.id
                    );
                  return (
                    <Td className="flex items-center gap-2">
                      <div className="text-sm text-gray-900">{name}</div>
                      {defaultProvider && (
                        <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                          Default
                        </span>
                      )}
                    </Td>
                  );
                },
              },
              {
                header: "Type",
                cellFn: (provider: Provider) => (
                  <Td>
                    <div className="text-sm text-gray-900">{provider.type}</div>
                  </Td>
                ),
              },
              {
                header: "NPI",
                cellFn: (provider: Provider) => (
                  <Td>
                    <div className="text-sm text-gray-900">{provider.npi}</div>
                  </Td>
                ),
              },
              {
                header: "Billing Group",
                cellFn: (provider: Provider) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {provider.billingGroup && (
                        <>
                          {provider.billingGroup.name} (
                          {provider.billingGroup.npi})
                        </>
                      )}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Network Participation",
                cellFn: (provider: Provider) => {
                  const inNetwork =
                    provider.providerNetworkParticipations.filter(
                      (p) => p.inNetwork
                    );
                  const outOfNetwork =
                    provider.providerNetworkParticipations.filter(
                      (p) => !p.inNetwork
                    );
                  return (
                    <Td className="max-w-md">
                      <div className="text-sm text-gray-900">
                        {inNetwork.length > 0 && (
                          <div className="flex items-center gap-1">
                            <strong className="text-green-500">In</strong>
                            <div className="truncate">
                              {inNetwork.map((p) => p.payer.name).join(", ")}
                            </div>
                          </div>
                        )}
                        {outOfNetwork.length > 0 && (
                          <div className="flex items-center gap-1">
                            <strong className="text-red-500">Out</strong>
                            <div className="truncate">
                              {outOfNetwork.map((p) => p.payer.name).join(", ")}
                            </div>
                          </div>
                        )}
                      </div>
                    </Td>
                  );
                },
              },
              {
                header: "Hidden",
                headerComponent: (
                  <div className="flex gap-1 items-center">
                    <div>Hidden</div>
                    <Tooltip
                      trigger={
                        <InformationCircleIcon className="h-4 w-4 text-grey-700" />
                      }
                      content={
                        <div className="max-w-sm text-center">
                          Hides provider from dropdown menus.
                        </div>
                      }
                    />
                  </div>
                ),
                cellFn: (provider: Provider) => (
                  <ProviderHiddenToggleCell provider={provider} />
                ),
              },
              {
                header: "",
                cellFn: (provider: Provider) => (
                  <Td>
                    <ProviderDefaultDialogButton provider={provider} />
                  </Td>
                ),
              },
            ]}
            rows={data.providers}
            onRowClick={(provider) =>
              navigate(`/providers/${provider.id}/edit`)
            }
          />
        </div>
      </div>
    </div>
  );
};

export const Providers: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">Providers</h1>
          <Link
            to="/providers/new"
            className="flex px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            New Provider
          </Link>
        </div>
      }
      content={
        <div className="py-4">
          <ProviderList />
        </div>
      }
    />
  );
};
