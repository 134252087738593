import { Switch, Popover, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PaperAirplaneIcon,
  CurrencyDollarIcon,
  XIcon,
  DotsVerticalIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  classNames,
  formatDateMMDDYYYY,
  formatUSD,
  isDefined,
  toDateMMDDYYYY,
  toDate,
  formatBillCode,
  parseDate,
} from "../../utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useUser } from "../../user-context";
import {
  GetTasks,
  GetTasksVariables,
  GetTasks_tasks as Task,
} from "../../generated/GetTasks";
import {
  CalendarIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import {
  differenceInCalendarDays,
  formatDistanceToNow,
  isFuture,
  isPast,
  parseISO,
  startOfDay,
  subDays,
} from "date-fns";
import { Card, Table } from "../../components";
import {
  GetChargeExternalPaymentMethodTask,
  GetChargeExternalPaymentMethodTaskVariables,
  GetChargeExternalPaymentMethodTask_task_paymentRequest_paymentRequestTargets_bill as Bill,
} from "../../generated/GetChargeExternalPaymentMethodTask";
import {
  MarkTaskAsComplete,
  MarkTaskAsCompleteVariables,
} from "../../generated/MarkTaskAsComplete";
import {
  MarkTaskAsIncomplete,
  MarkTaskAsIncompleteVariables,
} from "../../generated/MarkTaskAsIncomplete";
import { PaymentRequestTimeline } from "../billing/batches/show";
import {
  GET_SIDEBAR_COUNTS,
  PaymentRequestHistoryFragment,
} from "../../graphql";
import { toast } from "react-toastify";
import { Td } from "../../components/Table";
import { BillStatusDisplay } from "../appointments";
import { BillState } from "../../generated/globalTypes";
import { AutoEstimateIndicator } from "../shared/Tooltips";
import { useQueryParams } from "../../hooks";
import {
  MarkTaskAsCanceled,
  MarkTaskAsCanceledVariables,
} from "../../generated/MarkTaskAsCanceled";
import { SnoozeTask, SnoozeTaskVariables } from "../../generated/SnoozeTask";

const TaskFields = gql`
  fragment TaskFields on Task {
    id
    type
    dueDate
    completedAt
    canceledAt
    snoozedUntil
  }
`;

const GET_TASKS = gql`
  ${TaskFields}
  query GetTasks(
    $locationId: String!
    $completedAtThreshold: DateTime!
    $snoozedUntilFilter: [TaskWhereInput!]
  ) {
    tasks(
      where: {
        locationId: { equals: $locationId }
        AND: [
          {
            OR: [
              { completedAt: { equals: null } }
              { completedAt: { gte: $completedAtThreshold } }
              { canceledAt: { gte: $completedAtThreshold } }
            ]
          }
          { OR: $snoozedUntilFilter }
        ]
      }
      orderBy: [{ dueDate: { sort: asc, nulls: last } }, { createdAt: asc }]
    ) {
      ...TaskFields
      paymentRequest {
        id
        patient {
          id
          displayName
          maxAutopayLimit
        }
      }
    }
  }
`;

type TaskStatus = "Todo" | "Overdue" | "Canceled" | "Complete";

const getTaskStatus = (task: Task): TaskStatus => {
  if (task.completedAt) return "Complete";
  if (task.canceledAt) return "Canceled";
  if (task.dueDate && isPast(parseDate(task.dueDate))) return "Overdue";
  return "Todo";
};

const TaskDescription: React.FC<React.PropsWithChildren<{ task: Task }>> = ({
  task,
}) => {
  if (task.type === "ChargeExternalPaymentMethod") {
    return (
      <>Charge card on file for {task.paymentRequest!.patient.displayName}</>
    );
  }
  return null;
};

const CompleteIcon: React.FC<React.PropsWithChildren<unknown>> = () => (
  <CheckCircleIcon className="w-5 h-5 text-green-600" />
);
const OverdueIcon: React.FC<React.PropsWithChildren<unknown>> = () => (
  <CalendarIcon className="w-5 h-5 text-red-600" />
);
const TodoIcon: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className="w-4 h-4 border-2 border-gray-600 rounded-full" />
);
const CancelIcon: React.FC<React.PropsWithChildren<unknown>> = () => (
  <XCircleIcon className="w-5 h-5 text-red-600" />
);

const TaskStatusIcon: React.FC<React.PropsWithChildren<{ task: Task }>> = ({
  task,
}) => {
  const status = getTaskStatus(task);
  switch (status) {
    case "Complete":
      return <CompleteIcon />;
    case "Overdue":
      return <OverdueIcon />;
    case "Todo":
      return <TodoIcon />;
    case "Canceled":
      return <CancelIcon />;
  }
};

const TaskStatusBadge: React.FC<React.PropsWithChildren<{ task: Task }>> = ({
  task,
}) => {
  const status = getTaskStatus(task);
  const icon = <TaskStatusIcon task={task} />;
  switch (status) {
    case "Complete":
      return (
        <div className="flex gap-2 items-center text-green-600">
          {icon}
          Complete
        </div>
      );
    case "Overdue":
      return (
        <div className="flex gap-2 items-center text-red-600">
          {icon}
          Overdue
        </div>
      );
    case "Todo":
      return (
        <div className="flex gap-2 items-center text-gray-600">
          {icon}
          Todo
        </div>
      );
    case "Canceled":
      return (
        <div className="flex gap-2 items-center text-red-600">
          {icon}
          Canceled
        </div>
      );
  }
};

const GET_CHARGE_EXTERNAL_PAYMENT_METHOD_TASK = gql`
  ${PaymentRequestHistoryFragment}
  ${TaskFields}
  query GetChargeExternalPaymentMethodTask($id: String!) {
    task(where: { id: $id }) {
      ...TaskFields
      paymentRequest {
        ...PaymentRequestHistoryFields
        id
        type
        amount
        status
        startedAt
        paymentRequestBatch {
          id
          startedAt
          scheduledAt
        }
        patient {
          id
          displayName
          maxAutopayLimit
        }
      }
    }
  }
`;

export const PatientBillsTable: React.FC<
  React.PropsWithChildren<{
    loading: boolean;
    bills: Bill[];
  }>
> = ({ loading, bills }) => {
  return (
    <>
      <Table
        loading={loading}
        columnDefs={[
          {
            header: "Bill ID",
            cellFn: (row: Bill) => (
              <Td className="text-sm font-normal normal-case">
                {formatBillCode(row.billCode)}
              </Td>
            ),
          },
          {
            header: "Account",
            headerComponent: <span>Account</span>,
            cellFn: (row: Bill) => (
              <Td className="text-sm font-normal text-gray-900 normal-case">
                {row.account.accountType?.name && row.account.accountType.name}
              </Td>
            ),
          },
          {
            header: "Provider",
            cellFn: (row: Bill) => (
              <Td className="text-sm font-normal normal-case">
                {row.primaryProvider?.displayName}
              </Td>
            ),
          },
          {
            header: "Status",
            cellFn: (bill: Bill) => (
              <Td className="text-sm">
                <BillStatusDisplay bill={bill} />
              </Td>
            ),
          },
          {
            header: "Date Of Service",
            headerComponent: <span>Date of Service</span>,
            cellFn: (row: Bill) => (
              <Td className="space-x-1 min-w-[11em] text-sm font-normal normal-case">
                <span>
                  {row.dateOfServiceDisplay &&
                    toDateMMDDYYYY(row.dateOfServiceDisplay)}
                </span>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                  {row.charges.length > 0 ? row.charges.length : "No"} charges
                </span>
              </Td>
            ),
          },
          {
            header: "Due Date",
            cellFn: (row: Bill) => (
              <Td
                className={classNames(
                  row.dueDate && isPast(parseISO(row.dueDate))
                    ? "text-red-600"
                    : "text-gray-900",
                  "text-sm"
                )}
              >
                {row.status === BillState.Ready &&
                  row.dueDate &&
                  toDateMMDDYYYY(toDate(row.dueDate))}
              </Td>
            ),
          },
          {
            header: "Last Sent",
            cellFn: (row: Bill) => {
              const [lastCommunication] = row.communications;
              // Only show last sent if bill is ready for now
              if (row.status !== BillState.Ready || !lastCommunication)
                return <Td></Td>;
              return (
                <Td className="text-sm font-normal text-gray-900">
                  {toDateMMDDYYYY(toDate(lastCommunication.sentAt!))}
                </Td>
              );
            },
          },
          {
            header: "Patient Resp.",
            headerComponent: <div className="text-right">Patient Resp.</div>,
            cellFn: (row: Bill) => {
              return (
                <Td className="text-sm font-normal text-gray-900">
                  <div className="items-center justify-end gap-1 flex">
                    {(row.toCollect.collectionMode === "Estimate" ||
                      row.toCollect.collectionMode === "Deposit") && (
                      <AutoEstimateIndicator />
                    )}
                    {formatUSD(row.toCollect.patientResponsibility)}
                  </div>
                </Td>
              );
            },
          },
          {
            header: "Patient Owes",
            headerComponent: <div className="text-right">Patient Owes</div>,
            cellFn: (row: Bill) => {
              return (
                <Td>
                  <div className="text-sm font-normal text-gray-900 text-right">
                    {formatUSD(row.toCollect.patientBalance)}
                  </div>
                </Td>
              );
            },
          },
        ]}
        rows={bills}
      />
    </>
  );
};

export const MARK_TASK_AS_COMPLETE = gql`
  ${TaskFields}
  ${PaymentRequestHistoryFragment}
  mutation MarkTaskAsComplete($id: String!) {
    markTaskAsComplete(id: $id) {
      taskResult {
        ... on PaymentRequestTaskResult {
          task {
            ...TaskFields
          }
          paymentRequest {
            ...PaymentRequestHistoryFields
            id
            type
            amount
            status
            startedAt
            paymentRequestBatch {
              id
              startedAt
              scheduledAt
            }
            patient {
              id
              displayName
              maxAutopayLimit
            }
          }
        }
      }
      errors {
        message
      }
    }
  }
`;

export const MARK_TASK_AS_INCOMPLETE = gql`
  ${TaskFields}
  ${PaymentRequestHistoryFragment}
  mutation MarkTaskAsIncomplete($id: String!) {
    markTaskAsIncomplete(id: $id) {
      taskResult {
        ... on PaymentRequestTaskResult {
          task {
            ...TaskFields
          }
          paymentRequest {
            ...PaymentRequestHistoryFields
            id
            type
            amount
            status
            startedAt
            paymentRequestBatch {
              id
              startedAt
              scheduledAt
            }
            patient {
              id
              displayName
              maxAutopayLimit
            }
          }
        }
      }
      errors {
        message
      }
    }
  }
`;

export const MARK_TASK_AS_CANCELED = gql`
  ${PaymentRequestHistoryFragment}
  ${TaskFields}
  mutation MarkTaskAsCanceled($id: String!) {
    markTaskAsCanceled(id: $id) {
      taskResult {
        ... on PaymentRequestTaskResult {
          task {
            ...TaskFields
          }
          paymentRequest {
            ...PaymentRequestHistoryFields
            id
            type
            amount
            status
            startedAt
            paymentRequestBatch {
              id
              startedAt
              scheduledAt
            }
            patient {
              id
              displayName
              maxAutopayLimit
            }
          }
        }
      }
      errors {
        message
      }
    }
  }
`;

export const SNOOZE_TASK = gql`
  ${TaskFields}
  mutation SnoozeTask(
    $id: String!
    $snoozedUntil: DateTime!
    $snoozedBy: String!
  ) {
    updateOneTask(
      where: { id: $id }
      data: {
        snoozedUntil: { set: $snoozedUntil }
        snoozedBy: { connect: { id: $snoozedBy } }
      }
    ) {
      ...TaskFields
    }
  }
`;

const ActionDateDisplay: React.FC<
  React.PropsWithChildren<{ name: string; daysDiff: number }>
> = ({ name, daysDiff }) => (
  <div className="text-base text-gray-600">
    {name}{" "}
    {daysDiff === 0 ? (
      <>Today</>
    ) : daysDiff > 0 ? (
      <>in {daysDiff} days</>
    ) : (
      <>{-daysDiff} days ago</>
    )}
  </div>
);

const TaskDetails: React.FC<React.PropsWithChildren<{ task: Task }>> = ({
  task: { id },
}) => {
  const user = useUser()!;
  const { data, loading, error } = useQuery<
    GetChargeExternalPaymentMethodTask,
    GetChargeExternalPaymentMethodTaskVariables
  >(GET_CHARGE_EXTERNAL_PAYMENT_METHOD_TASK, {
    variables: { id },
  });
  const [markTaskAsComplete, markTaskAsCompleteResult] = useMutation<
    MarkTaskAsComplete,
    MarkTaskAsCompleteVariables
  >(MARK_TASK_AS_COMPLETE);
  if (loading) {
    return (
      <>
        <div className="bg-white pb-6 pt-5 shadow">
          <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
            <div className="sm:w-0 sm:flex-1 gap-2 flex items-center">
              <div className="h-16 animate-pulse rounded-md bg-slate-100" />
            </div>

            <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
              <div className="h-64 animate-pulse rounded-md bg-slate-100" />
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-2 py-4 sm:space-y-4 sm:px-6 lg:px-8">
          <Card>
            <div className="flex flex-col">
              <div>Charge the card on file for bills marked as Ready on</div>
            </div>
          </Card>
        </div>
      </>
    );
  }
  const task = data?.task!;
  const paymentRequest = task.paymentRequest!;
  const remainingBalance = paymentRequest.paymentRequestTargets
    .map((target) => target.bill)
    .reduce((acc, bill) => acc + bill.toCollect.patientBalance, 0);
  // If the payment request was less than the total bill balance because of the
  // autopay max limit, then make sure the amount we say is left to charge is at
  // least less than the payment request amount.
  const normalizedRemainingBalance = Math.min(
    remainingBalance,
    paymentRequest.amount!
  );

  const paymentRequestBatchDate =
    paymentRequest.paymentRequestBatch?.startedAt ??
    paymentRequest.paymentRequestBatch?.scheduledAt;

  const status = getTaskStatus(task);
  const showMarkAsCompleteButton =
    status !== "Complete" && status !== "Canceled";

  const maxAutopayLimitEnabled = user.activeLocation.maxAutopayLimitEnabled;
  const maxAutopayLimit =
    maxAutopayLimitEnabled && paymentRequest.patient.maxAutopayLimit
      ? paymentRequest.patient.maxAutopayLimit
      : null;

  return (
    <>
      <div className="bg-white pb-6 pt-5 shadow">
        <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
          <div className="sm:w-0 sm:flex-1 gap-2 flex items-center">
            <h1
              id="message-heading"
              className="text-lg font-medium text-gray-900"
            >
              Charge card on file for {paymentRequest.patient.displayName}
            </h1>
            {(status === "Todo" || status === "Overdue") && (
              <ActionDateDisplay
                name="Due"
                daysDiff={differenceInCalendarDays(
                  parseDate(task.dueDate),
                  new Date()
                )}
              />
            )}
            {status === "Complete" && (
              <ActionDateDisplay
                name="Completed"
                daysDiff={differenceInCalendarDays(
                  parseDate(task.completedAt),
                  new Date()
                )}
              />
            )}
            {status === "Canceled" && (
              <ActionDateDisplay
                name="Canceled"
                daysDiff={differenceInCalendarDays(
                  parseDate(task.canceledAt),
                  new Date()
                )}
              />
            )}
          </div>

          <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
            <TaskStatusBadge task={task} />
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-2 py-4 sm:space-y-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Charge card on file
            </h3>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
              <div className="text-sm text-gray-500">
                <p>
                  Charge the patient's card on file in ChiroTouch for the{" "}
                  {formatUSD(normalizedRemainingBalance)} remaining of the{" "}
                  {formatUSD(paymentRequest.amount!)} requested on{" "}
                  {formatDateMMDDYYYY(paymentRequestBatchDate!)}.
                  {maxAutopayLimit && (
                    <>
                      {" "}
                      The patient has a max Autopay limit set to{" "}
                      {formatUSD(maxAutopayLimit)}.
                    </>
                  )}
                </p>
                {paymentRequest.paymentRequestBatch && (
                  <Link
                    to={`/billing/batches/${paymentRequest.paymentRequestBatch.id}`}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    View payment request batch
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                )}
              </div>
              <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                {showMarkAsCompleteButton && (
                  <button
                    type="button"
                    className="relative -ml-px hidden items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 border border-gray-200 shadow-sm hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
                    disabled={markTaskAsCompleteResult.loading}
                    onClick={() =>
                      markTaskAsComplete({
                        variables: { id: task.id },
                        onCompleted: (data) => {
                          if (data.markTaskAsComplete.errors.length > 0) {
                            toast.error(
                              data.markTaskAsComplete.errors[0].message
                            );
                          } else {
                            toast.success(
                              "Payment request task marked as complete"
                            );
                          }
                        },
                        onError: () => {
                          toast.error(
                            "Failed to mark payment request task as complete"
                          );
                        },
                        refetchQueries: [GET_SIDEBAR_COUNTS],
                      })
                    }
                  >
                    <CompleteIcon />
                    Mark Complete
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <h3 className="font-semibold text-lg">Bills to pay</h3>

        <PatientBillsTable
          loading={loading}
          bills={paymentRequest.paymentRequestTargets.map((t) => t.bill) ?? []}
        />

        <h3 className="font-semibold text-lg">Related Activity</h3>

        <div className="bg-white shadow sm:rounded-lg p-4">
          <PaymentRequestTimeline paymentRequest={paymentRequest} />
        </div>
      </div>
    </>
  );
};

const ChargeExternalPaymentMethodTaskActions: React.FC<
  React.PropsWithChildren<{
    task: Task;
  }>
> = ({ task }) => {
  const user = useUser()!;
  const [snoozeUntil, setSnoozeUntil] = useState<string | null>(null);
  const [markTaskAsComplete, markTaskAsCompleteResult] = useMutation<
    MarkTaskAsComplete,
    MarkTaskAsCompleteVariables
  >(MARK_TASK_AS_COMPLETE);
  const [markTaskAsIncomplete, markTaskAsIncompleteResult] = useMutation<
    MarkTaskAsIncomplete,
    MarkTaskAsIncompleteVariables
  >(MARK_TASK_AS_INCOMPLETE);
  const [markTaskAsCanceled, markTaskAsCanceledResult] = useMutation<
    MarkTaskAsCanceled,
    MarkTaskAsCanceledVariables
  >(MARK_TASK_AS_CANCELED);
  const [snoozeTask, snoozeTaskResult] = useMutation<
    SnoozeTask,
    SnoozeTaskVariables
  >(SNOOZE_TASK);

  const status = getTaskStatus(task);

  const showMarkAsCompleteButton =
    status !== "Complete" && status !== "Canceled";
  const showMarkAsIncompleteButton = status === "Complete";
  const showCancelButton = status !== "Complete" && status !== "Canceled";
  const showSnoozeButton =
    (status === "Todo" || status === "Overdue") &&
    (task.snoozedUntil === null || isPast(parseISO(task.snoozedUntil)));
  return (
    <>
      {showMarkAsCompleteButton && (
        <span className="space-x-3 flex">
          <button
            type="button"
            className="relative -ml-px  items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 border border-gray-200 shadow-sm hover:z-10 hover:bg-gray-50 focus:z-10 inline-flex"
            disabled={markTaskAsCompleteResult.loading}
            onClick={() =>
              markTaskAsComplete({
                variables: { id: task.id },
                onCompleted: (data) => {
                  if (data.markTaskAsComplete.errors.length > 0) {
                    toast.error(data.markTaskAsComplete.errors[0].message);
                  } else {
                    toast.success("Task marked as complete");
                  }
                },
                onError: () => {
                  toast.error("Failed to mark task as complete");
                },
                refetchQueries: [GET_SIDEBAR_COUNTS],
              })
            }
          >
            <CompleteIcon />
            Mark Complete
          </button>
        </span>
      )}
      {showMarkAsIncompleteButton && (
        <span className="space-x-3 lg:flex">
          <button
            type="button"
            className="relative -ml-px items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 border border-gray-200 shadow-sm hover:z-10 hover:bg-gray-50 focus:z-10 inline-flex"
            disabled={markTaskAsIncompleteResult.loading}
            onClick={() =>
              markTaskAsIncomplete({
                variables: { id: task.id },
                onCompleted: (data) => {
                  if (data.markTaskAsIncomplete.errors.length > 0) {
                    toast.error(data.markTaskAsIncomplete.errors[0].message);
                  } else {
                    toast.success("Task marked as incomplete");
                  }
                },
                onError: () => {
                  toast.error("Failed to mark task as incomplete");
                },
                refetchQueries: [GET_SIDEBAR_COUNTS],
              })
            }
          >
            <TodoIcon />
            Mark Todo
          </button>
        </span>
      )}
      {showCancelButton && (
        <button
          type="button"
          className="relative -ml-px items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 border border-gray-200 shadow-sm hover:z-10 hover:bg-gray-50 focus:z-10 inline-flex"
          disabled={markTaskAsCanceledResult.loading}
          onClick={() =>
            markTaskAsCanceled({
              variables: { id: task.id },
              onCompleted: (data) => {
                if (data.markTaskAsCanceled.errors.length > 0) {
                  toast.error(data.markTaskAsCanceled.errors[0].message);
                } else {
                  toast.success("Task canceled");
                }
              },
              onError: () => {
                toast.error("Failed to cancel task");
              },
              refetchQueries: [GET_SIDEBAR_COUNTS],
            })
          }
        >
          <CancelIcon />
          Cancel
        </button>
      )}
      {showSnoozeButton && (
        <Popover as="div" className="relative inline-block text-left">
          <div>
            <Popover.Button className="relative -ml-px items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 border border-gray-200 shadow-sm hover:z-10 hover:bg-gray-50 focus:z-10 inline-flex">
              <span className="sr-only">Snooze</span>
              <ClockIcon className="h-5 w-5 text-gray-700" />
              Snooze
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute right-0 z-10 mt-3 w-screen max-w-xs px-4 sm:px-0 bg-white">
              <div className="overflow-hidden rounded-lg shadow-lg border p-4">
                <div className="relative grid gap-4">
                  <div className="flex items-center justify-between w-full">
                    <div className="text-xs text-gray-700">Snooze Until</div>
                    <div className="flex-shrink-0 pl-2">
                      <input
                        type="datetime-local"
                        min={startOfDay(new Date()).toISOString().slice(0, 16)}
                        onChange={(e) => {
                          setSnoozeUntil(e.target.value);
                        }}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={!snoozeUntil || snoozeTaskResult.loading}
                      onClick={() =>
                        snoozeTask({
                          variables: {
                            id: task.id,
                            snoozedUntil: new Date(snoozeUntil!).toISOString(),
                            snoozedBy: user.id,
                          },
                          onCompleted: (data) => {
                            toast.success("Task snoozed");
                          },
                          onError: () => {
                            toast.error("Failed to snooze task");
                          },
                          refetchQueries: [GET_SIDEBAR_COUNTS, GET_TASKS],
                        })
                      }
                    >
                      Snooze
                    </button>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
    </>
  );
};

const TaskActions: React.FC<React.PropsWithChildren<{ task: Task }>> = ({
  task,
}) => {
  if (task.type === "ChargeExternalPaymentMethod")
    return <ChargeExternalPaymentMethodTaskActions task={task} />;
  return null;
};

const isTodo = (task: Task) => {
  const status = getTaskStatus(task);
  return status === "Todo" || status === "Overdue";
};

const TasksDisplay: React.FC<
  React.PropsWithChildren<{
    tasks: Task[];
    showSnoozed: boolean;
    setShowSnoozed: (showSnoozed: boolean) => void;
  }>
> = ({ tasks, showSnoozed, setShowSnoozed }) => {
  const query = useQueryParams();
  const statusFilter: TaskStatus | "All" =
    (query.get("status") as TaskStatus | null) ?? "All";

  const filteredTasks = tasks.filter((task) => {
    if (statusFilter === "All") return true;
    if (statusFilter === "Todo") return isTodo(task);
    return getTaskStatus(task) === statusFilter;
  });

  const [selectedTask, setSelectedTask] = useState<Task | null>(tasks?.[0]);

  // If the tasks list changes, try to re-select the same task. Fallback to the first task.
  useEffect(() => {
    const task = tasks.find((task) => task.id === selectedTask?.id) ?? tasks[0];
    setSelectedTask(task);
  }, [tasks]);

  const currentIndex = tasks.findIndex((task) => task.id === selectedTask?.id);
  const hasNextTask = currentIndex !== -1 && currentIndex < tasks.length - 1;
  const hasPreviousTask = currentIndex !== -1 && currentIndex > 0;

  const selectNextTask = () => {
    if (hasNextTask) {
      setSelectedTask(tasks[currentIndex + 1]);
    }
  };

  const selectPreviousTask = () => {
    if (hasPreviousTask) {
      setSelectedTask(tasks[currentIndex - 1]);
    }
  };

  const todoTasks = tasks.filter(isTodo);
  const overdueTasks = tasks.filter(
    (task) => getTaskStatus(task) === "Overdue"
  );
  const completedTasks = tasks.filter(
    (task) => getTaskStatus(task) === "Complete"
  );
  const canceledTasks = tasks.filter(
    (task) => getTaskStatus(task) === "Canceled"
  );

  return (
    <div className="flex min-h-0 flex-1 overflow-hidden h-screen">
      <main className="min-w-0 flex-1 border-t border-gray-200 flex">
        <section
          aria-labelledby="message-heading"
          className="flex h-full min-w-0 flex-1 flex-col overflow-hidden order-last"
        >
          {/* Top section */}
          <div className="flex-shrink-0 border-b border-gray-200 bg-white">
            {/* Toolbar*/}
            <div className="flex h-16 flex-col justify-center">
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between py-3">
                  {/* Left buttons */}
                  <div>
                    <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
                      {selectedTask && <TaskActions task={selectedTask} />}
                    </div>
                  </div>

                  {/* Right buttons */}
                  <nav aria-label="Pagination">
                    <span className="isolate inline-flex rounded-md shadow-sm">
                      <button
                        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-gray-400 border border-gray-200 shadow-sm hover:z-10 hover:bg-gray-50 focus:z-10"
                        disabled={!hasNextTask}
                        onClick={selectNextTask}
                      >
                        <span className="sr-only">Next</span>
                        <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                      <button
                        className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-gray-400 border border-gray-200 shadow-sm hover:z-10 hover:bg-gray-50 focus:z-10"
                        disabled={!hasPreviousTask}
                        onClick={selectPreviousTask}
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </span>
                  </nav>
                </div>
              </div>
            </div>
            {/* Message header */}
          </div>

          {!selectedTask ? (
            <div className="max-w-xl m-auto">
              <div className="relative block w-full text-center p-12">
                <div className="text-[64px]">🥳</div>
                <span className="mt-2 block text-lg font-semibold text-gray-900">
                  You're all caught up!
                </span>
              </div>
            </div>
          ) : (
            <div className="min-h-0 flex-1 overflow-y-auto">
              <TaskDetails task={selectedTask} />
            </div>
          )}
        </section>

        {/* Message list*/}
        {/* <aside className="hidden xl:order-first xl:block xl:flex-shrink-0"> */}
        <aside className="order-first block flex-shrink-0">
          <div className="relative flex h-full w-96 flex-col border-r border-gray-200 bg-gray-100">
            <div className="flex-shrink-0">
              <div className="flex h-16 flex-col justify-center bg-white px-6">
                <div className="flex justify-between items-baseline space-x-3">
                  <h2 className="text-lg font-medium text-gray-900">Tasks</h2>

                  <Popover as="div" className="relative inline-block text-left">
                    <div>
                      <Popover.Button className="flex items-center rounded-full p-0.5 text-gray-400 hover:bg-gray-100 hover:text-gray-600">
                        <span className="sr-only">Open options</span>
                        <DotsVerticalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Popover.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="absolute right-0 z-10 mt-3 w-screen max-w-xs px-4 sm:px-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-4 bg-white bg-opacity-95 p-4">
                            <div className="flex items-center justify-between w-full">
                              <div className="text-xs text-gray-700">
                                Show snoozed
                              </div>
                              <Switch
                                checked={showSnoozed}
                                onChange={setShowSnoozed}
                                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    showSnoozed
                                      ? "bg-indigo-600"
                                      : "bg-gray-200",
                                    "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                                  )}
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    showSnoozed
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 shadow-sm bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
              </div>
              <nav
                className="flex space-x-1 border-b border-t border-gray-200 bg-white p-2 text-sm font-medium text-gray-500 overflow-x-auto"
                aria-label="Tabs"
              >
                {[
                  {
                    count: tasks.length,
                    name: "All",
                  },
                  {
                    count: todoTasks.length,
                    name: "Todo",
                  },
                  {
                    count: overdueTasks.length,
                    name: "Overdue",
                  },
                  {
                    count: completedTasks.length,
                    name: "Complete",
                  },
                  {
                    count: canceledTasks.length,
                    name: "Canceled",
                  },
                ].map((tab) => {
                  const current = tab.name === statusFilter;
                  let link = `/tasks`;
                  // If we're on the current tab, remove the status filter on click
                  if (!current && tab.name !== "All") {
                    link =
                      link +
                      `?${new URLSearchParams({
                        status: tab.name,
                      }).toString()}`;
                  }
                  return (
                    <Link
                      to={link}
                      key={tab.name}
                      className={classNames(
                        current
                          ? "bg-indigo-100 text-indigo-700"
                          : "text-gray-500 hover:text-gray-700 hover:bg-gray-100",
                        "rounded-md px-3 py-2 text-sm font-medium flex-shrink-0 group"
                      )}
                      aria-current={current ? "page" : undefined}
                    >
                      {tab.name}
                      <span
                        className={classNames(
                          current
                            ? "bg-indigo-200 text-indigo-900"
                            : "bg-gray-100 text-gray-900 group-hover:bg-gray-200 group-hover:text-gray-900",
                          "ml-3 rounded-full py-0.5 px-2.5 text-xs font-medium"
                        )}
                      >
                        {tab.count}
                      </span>
                    </Link>
                  );
                })}
              </nav>
              <div className="border-b border-t border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
                Sorted by status
              </div>
            </div>
            <nav
              aria-label="Message list"
              className="min-h-0 flex-1 overflow-y-auto"
            >
              <ul
                role="list"
                className="divide-y divide-gray-200 border-b border-gray-200"
              >
                {filteredTasks.map((task) => (
                  <li
                    key={task.id}
                    className={classNames(
                      "relative px-6 py-5 hover:bg-gray-50",
                      task.id === selectedTask?.id
                        ? "border-l-4 !border-l-indigo-500 bg-slate-50"
                        : "bg-white"
                    )}
                    onClick={() => {
                      setSelectedTask(task);
                    }}
                  >
                    <div className="flex justify-between space-x-3">
                      <div className="min-w-0 flex-1">
                        <a href="#" className="block focus:outline-none">
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          <p className="truncate text-sm font-medium text-gray-900">
                            {task.paymentRequest?.patient?.displayName}
                          </p>
                        </a>
                      </div>
                      {task.dueDate && (
                        <time
                          dateTime={task.dueDate}
                          className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
                        >
                          {toDateMMDDYYYY(toDate(task.dueDate))}
                        </time>
                      )}
                    </div>
                    <div className="flex justify-between space-x-3 mt-1">
                      <div className="min-w-0 flex-1">
                        <p className="line-clamp-2 text-sm text-gray-600">
                          <TaskDescription task={task} />
                        </p>
                      </div>
                      <TaskStatusIcon task={task} />
                    </div>
                    {task.snoozedUntil &&
                      isFuture(parseISO(task.snoozedUntil)) && (
                        <span className="text-xs text-indigo-700">
                          Snoozed for{" "}
                          {formatDistanceToNow(parseISO(task.snoozedUntil))}
                        </span>
                      )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </aside>
      </main>
    </div>
  );
};

export const Tasks: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser()!;
  const threshold = startOfDay(subDays(new Date(), 7));
  const [showSnoozed, setShowSnoozed] = useState(false);
  const [now] = useState(new Date());
  const { data, loading, error } = useQuery<GetTasks, GetTasksVariables>(
    GET_TASKS,
    {
      variables: {
        locationId: user.activeLocation.id,
        completedAtThreshold: threshold,
        snoozedUntilFilter: showSnoozed
          ? []
          : [
              {
                snoozedUntil: {
                  equals: null,
                },
              },
              {
                snoozedUntil: {
                  lte: now,
                },
              },
            ],
      },
    }
  );
  const tasks = [...(data?.tasks ?? [])].sort((a, b) => {
    const aStatus = getTaskStatus(a);
    const bStatus = getTaskStatus(b);
    if (aStatus === "Canceled" && bStatus !== "Canceled") return 1;
    if (aStatus !== "Canceled" && bStatus === "Canceled") return -1;
    if (aStatus === "Complete" && bStatus !== "Complete") return 1;
    if (aStatus !== "Complete" && bStatus === "Complete") return -1;
    if (aStatus === "Overdue" && bStatus !== "Overdue") return -1;
    if (aStatus !== "Overdue" && bStatus === "Overdue") return 1;
    return 0;
  });

  // Sekelton loading state
  if (loading) {
    return (
      <div className="flex min-h-0 flex-1 overflow-hidden h-screen">
        <main className="min-w-0 flex-1 border-t border-gray-200 flex">
          <section
            aria-labelledby="message-heading"
            className="flex h-full min-w-0 flex-1 flex-col overflow-hidden order-last"
          >
            {/* Top section */}
            <div className="flex-shrink-0 border-b border-gray-200 bg-white">
              <div className="h-8 animate-pulse rounded-md bg-slate-100" />
              <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            </div>
            {/* Main section */}
            <div className="max-w-xl m-auto">
              <div className="relative block w-full text-center p-12">
                <div className="h-8 animate-pulse rounded-md bg-slate-100" />
                <div className="h-8 animate-pulse rounded-md bg-slate-100" />
                <div className="h-8 animate-pulse rounded-md bg-slate-100" />
                <div className="h-8 animate-pulse rounded-md bg-slate-100" />
              </div>
            </div>
          </section>
          {/* Message list */}
          <aside className="order-first block flex-shrink-0">
            <div className="relative flex h-full w-96 flex-col border-r border-gray-200 bg-gray-100">
              <div className="flex-shrink-0">
                <div className="flex h-16 flex-col justify-center bg-white px-6 border-b">
                  <div className="flex items-baseline space-x-3">
                    <h2 className="text-lg font-medium text-gray-900">
                      Loading...
                    </h2>
                  </div>
                </div>
                <ul
                  role="list"
                  className="divide-y divide-gray-200 border-b border-gray-200"
                >
                  {Array.from({ length: 8 }).map((_, i) => (
                    <div
                      key={i}
                      className="relative bg-white px-6 py-5 flex w-full flex-col gap-2"
                    >
                      <div className="h-8 animate-pulse rounded-md bg-slate-100" />
                      <div className="h-8 animate-pulse rounded-md bg-slate-100" />
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </aside>
        </main>
      </div>
    );
  }
  return (
    <TasksDisplay
      tasks={tasks}
      showSnoozed={showSnoozed}
      setShowSnoozed={setShowSnoozed}
    />
  );
};
