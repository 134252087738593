import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { format, parseISO } from "date-fns";

import {
  GetInsurancePolicy_insurancePolicy as InsurancePolicy,
  GetInsurancePolicy_insurancePolicy_inNetworkBenefits as Benefit,
  GetInsurancePolicy_insurancePolicy_patient_organization_providerServiceConfiguration as ProviderServiceConfiguration,
} from "../../../generated/GetInsurancePolicy";
import {
  isDefined,
  formatUSD,
  formatPercentage,
  toDateMMDDYYYY,
  dateStrToMMDDYYYY,
} from "../../../utils";
import { toQuantityQualifierString } from "./show";
import { ServiceTypeCode } from "./types";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Helvetica",
    fontSize: 10,
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
});

const Header: React.FC<
  React.PropsWithChildren<{ insurancePolicy: InsurancePolicy }>
> = ({ insurancePolicy }) => {
  const patient = insurancePolicy.patient;
  const mostRecentEligibility = insurancePolicy.eligibilityRequests[0];
  return (
    <View
      style={{
        width: "100%",
        backgroundColor: "rgb(243 244 246)", // bg-gray-100
        display: "flex",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "24px",
        paddingBottom: "12px",
        flexDirection: "column",
        fontSize: 12,
      }}
    >
      {mostRecentEligibility && (
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View style={{ flex: 1 }}>
            <Text>
              Insurance Verification on{" "}
              {format(parseISO(mostRecentEligibility.createdAt), "MM/dd/yyyy")}
            </Text>
          </View>
        </View>
      )}
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View style={{ flex: 1 }}>
          <Text>Patient Name: {insurancePolicy.patient.displayName}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>
            DOB:{" "}
            {patient.dateOfBirth
              ? dateStrToMMDDYYYY(patient.dateOfBirth) ?? "N/A"
              : "N/A"}
          </Text>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View style={{ flex: 1 }}>
          <Text>Insurance Company: {insurancePolicy.payer.name}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Member ID: {insurancePolicy.memberId}</Text>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View style={{ flex: 1 }}>
          <Text>Group ID: {insurancePolicy.groupId}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Group Name: {insurancePolicy.groupName}</Text>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View style={{ flex: 1 }}>
          <Text>Plan: {insurancePolicy.plan?.name}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Plan Type: {insurancePolicy.plan?.type}</Text>
        </View>
      </View>
    </View>
  );
};

const HealthPlanSummary: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: InsurancePolicy;
    inNetwork: boolean;
  }>
> = ({ insurancePolicy, inNetwork }) => {
  const {
    inNetworkIndividualBenefit,
    inNetworkFamilyBenefit,
    outOfNetworkIndividualBenefit,
    outOfNetworkFamilyBenefit,
  } = insurancePolicy.planCoverageBenefit;

  const planBenefits = inNetwork
    ? { indv: inNetworkIndividualBenefit, fam: inNetworkFamilyBenefit }
    : { indv: outOfNetworkIndividualBenefit, fam: outOfNetworkFamilyBenefit };

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "12px",
        flexDirection: "column",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          paddingBottom: "2px",
          flexDirection: "row",
        }}
      >
        <Text
          style={{
            fontSize: 16,
            ...styles.bold,
          }}
        >
          Health Plan Summary
        </Text>
      </View>
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View style={{ width: 100 }}>
          <Text>In Network?: {inNetwork ? "Yes" : "No"}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>
            Policy Effective Date:{" "}
            {insurancePolicy.effectiveDate
              ? toDateMMDDYYYY(insurancePolicy.effectiveDate)
              : "N/A"}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          {insurancePolicy.active ? (
            <Text>
              Policy Renewal Date:{" "}
              {insurancePolicy.renewalDate
                ? toDateMMDDYYYY(insurancePolicy.renewalDate)
                : "N/A"}
            </Text>
          ) : (
            <Text>
              Policy Term Date:{" "}
              {insurancePolicy.terminationDate
                ? toDateMMDDYYYY(insurancePolicy.terminationDate)
                : "N/A"}
            </Text>
          )}
        </View>
      </View>
      {/* Deductible Row */}
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View style={{ width: 100 }}>
          <Text>Deductible</Text>
        </View>
        {planBenefits.indv?.hidden !== true && (
          <>
            <View style={{ flex: 1 }}>
              <Text>
                Ind Rem:{" "}
                {isDefined(planBenefits.indv?.adjustedRemainingDeductible) ? (
                  <Text style={{ ...styles.bold }}>
                    {formatUSD(planBenefits.indv!.adjustedRemainingDeductible)}
                  </Text>
                ) : (
                  "-"
                )}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text>
                Ind Max:{" "}
                {isDefined(planBenefits.indv?.maxDeductible) ? (
                  <Text style={{ ...styles.bold }}>
                    {formatUSD(planBenefits.indv!.maxDeductible)}
                  </Text>
                ) : (
                  "-"
                )}
              </Text>
            </View>
          </>
        )}
        {planBenefits.fam?.hidden !== true && (
          <>
            <View style={{ flex: 1 }}>
              <Text>
                Fam Rem:{" "}
                {isDefined(planBenefits.fam?.adjustedRemainingDeductible) ? (
                  <Text style={{ ...styles.bold }}>
                    {formatUSD(planBenefits.fam!.adjustedRemainingDeductible)}
                  </Text>
                ) : (
                  "-"
                )}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text>
                Fam Max:{" "}
                {isDefined(planBenefits.fam?.maxDeductible) ? (
                  <Text style={{ ...styles.bold }}>
                    {formatUSD(planBenefits.fam!.maxDeductible)}
                  </Text>
                ) : (
                  "-"
                )}
              </Text>
            </View>
          </>
        )}
      </View>
      {/* OOP Row */}
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View style={{ width: 100 }}>
          <Text>Out of Pocket</Text>
        </View>
        {planBenefits.indv?.hidden !== true && (
          <>
            <View style={{ flex: 1 }}>
              <Text>
                Ind Rem:{" "}
                {isDefined(planBenefits.indv?.adjustedRemainingOutOfPocket) ? (
                  <Text style={{ ...styles.bold }}>
                    {formatUSD(planBenefits.indv!.adjustedRemainingOutOfPocket)}
                  </Text>
                ) : (
                  "-"
                )}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text>
                Ind Max:{" "}
                {isDefined(planBenefits.indv?.maxOutOfPocket) ? (
                  <Text style={{ ...styles.bold }}>
                    {formatUSD(planBenefits.indv!.maxOutOfPocket)}
                  </Text>
                ) : (
                  "-"
                )}
              </Text>
            </View>
          </>
        )}
        {planBenefits.fam?.hidden !== true && (
          <>
            <View style={{ flex: 1 }}>
              <Text>
                Fam Rem:{" "}
                {isDefined(planBenefits.fam?.adjustedRemainingOutOfPocket) ? (
                  <Text style={{ ...styles.bold }}>
                    {formatUSD(planBenefits.fam!.adjustedRemainingOutOfPocket)}
                  </Text>
                ) : (
                  "-"
                )}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text>
                Fam Max:{" "}
                {isDefined(planBenefits.fam?.maxOutOfPocket) ? (
                  <Text style={{ ...styles.bold }}>
                    {formatUSD(planBenefits.fam!.maxOutOfPocket)}
                  </Text>
                ) : (
                  "-"
                )}
              </Text>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const ServiceBenefitSummary: React.FC<
  React.PropsWithChildren<{
    name: string;
    serviceTypeCode: string;
    benefit?: Benefit;
    providerServiceConfigurations: ProviderServiceConfiguration[];
  }>
> = ({ name, serviceTypeCode, benefit, providerServiceConfigurations }) => {
  const providerServiceConfiguration = providerServiceConfigurations.find(
    (c) => c.name === name
  );
  // Skip if not configured to show in PDF
  if (providerServiceConfiguration?.showInPdf === false) return null;
  const visitLimitHit = benefit?.adjustedRemainingVisits === 0;
  const deductibleDoesNotApply = benefit?.deductibleApplies === false;
  const copay = isDefined(benefit?.copay)
    ? formatUSD(benefit!.copay)
    : "Does not apply";
  const coinsurance = isDefined(benefit?.coinsurance)
    ? formatPercentage(benefit!.coinsurance)
    : "Does not apply";
  const authRequired = benefit?.authRequired === true;
  const deductibleText = deductibleDoesNotApply ? "Does not apply" : "Applies";

  const noBenefit = benefit?.empty;

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      // Don't break within this component
      break={false}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          paddingBottom: "2px",
          flexDirection: "row",
        }}
      >
        <Text
          style={{
            fontSize: 14,
            ...styles.bold,
          }}
        >
          {name}
        </Text>
      </View>
      {noBenefit ? (
        <View
          style={{
            paddingBottom: "8px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flex: 1,
          }}
        >
          <Text>No benefits information available for this service type</Text>
        </View>
      ) : (
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingBottom: "12px",
              flex: 1,
            }}
          >
            <View style={{ width: 140 }}>
              <Text>Cost Share</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text>
                Copay: <Text style={{ ...styles.bold }}>{copay}</Text>
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text>
                Coinsurance:{" "}
                <Text style={{ ...styles.bold }}>{coinsurance}</Text>
              </Text>
            </View>
          </View>
          {(isDefined(benefit?.adjustedRemainingVisits) ||
            isDefined(benefit?.maxVisits)) && (
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View style={{ width: 140 }}>
                  <Text>Limits</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text>
                    Remaining:{" "}
                    {isDefined(benefit?.adjustedRemainingVisits) ? (
                      <Text style={{ ...styles.bold }}>
                        {benefit?.adjustedRemainingVisits}
                      </Text>
                    ) : (
                      "-"
                    )}
                    {benefit?.adjustedRemainingVisitsBenefit
                      ?.quantityQualifier &&
                      " " +
                        toQuantityQualifierString(
                          benefit.adjustedRemainingVisitsBenefit
                            ?.quantityQualifier
                        )}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text>
                    Max:{" "}
                    {isDefined(benefit?.maxVisits) ? (
                      <Text style={{ ...styles.bold }}>
                        {benefit?.maxVisits}
                      </Text>
                    ) : (
                      "-"
                    )}
                    {benefit?.maxVisitsBenefit?.quantityQualifier &&
                      " " +
                        toQuantityQualifierString(
                          benefit?.maxVisitsBenefit?.quantityQualifier
                        )}
                  </Text>
                </View>
              </View>
            </View>
          )}

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View style={{ width: 140 }}>
                <Text>
                  Deductible:{" "}
                  <Text style={{ ...styles.bold }}>{deductibleText}</Text>
                </Text>
              </View>
              {(isDefined(benefit?.adjustedRemainingDeductible) ||
                benefit?.maxDeductible) && (
                <>
                  <View style={{ flex: 1 }}>
                    <Text>
                      Remaining:{" "}
                      {isDefined(benefit?.adjustedRemainingDeductible) ? (
                        <Text style={{ ...styles.bold }}>
                          {formatUSD(benefit!.adjustedRemainingDeductible)}
                        </Text>
                      ) : (
                        "-"
                      )}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text>
                      Max:{" "}
                      {isDefined(benefit?.maxDeductible) ? (
                        <Text style={{ ...styles.bold }}>
                          {formatUSD(benefit!.maxDeductible)}
                        </Text>
                      ) : (
                        "-"
                      )}
                    </Text>
                  </View>
                </>
              )}
            </View>
          </View>
          {authRequired && (
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View style={{ width: 100 }}>
                  <Text>
                    Auth Required: <Text style={{ ...styles.bold }}>Yes</Text>
                  </Text>
                </View>
              </View>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const ServiceBenefitsSummary: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: InsurancePolicy;
    providerServiceConfigurations: ProviderServiceConfiguration[];
    inNetwork: boolean;
  }>
> = ({ insurancePolicy, providerServiceConfigurations, inNetwork }) => {
  const organizationServiceBenefits = React.useMemo(() => {
    const organizationServiceBenefits: {
      [name: string]: {
        in?: Benefit;
        out?: Benefit;
        serviceTypeCode: string;
      };
    } = {};

    insurancePolicy.inNetworkBenefits
      .filter(
        (c) =>
          c.providerServiceConfiguration.serviceType !==
          ServiceTypeCode.HealthBenefitPlanCoverage
      )
      .forEach((benefit) => {
        const group =
          organizationServiceBenefits[
            benefit.providerServiceConfiguration.name
          ] || {};
        group.in = benefit;
        group.serviceTypeCode =
          benefit.providerServiceConfiguration.serviceType;
        organizationServiceBenefits[benefit.providerServiceConfiguration.name] =
          group;
      });

    insurancePolicy.outOfNetworkBenefits
      .filter(
        (c) =>
          c.providerServiceConfiguration.serviceType !==
          ServiceTypeCode.HealthBenefitPlanCoverage
      )
      .forEach((benefit) => {
        const group =
          organizationServiceBenefits[
            benefit.providerServiceConfiguration.name
          ] || {};
        group.out = benefit;
        group.serviceTypeCode =
          benefit.providerServiceConfiguration.serviceType;
        organizationServiceBenefits[benefit.providerServiceConfiguration.name] =
          group;
      });
    return organizationServiceBenefits;
  }, [insurancePolicy]);

  return (
    <View
      style={{
        width: "100%",
        paddingLeft: "24px",
        paddingRight: "24px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {Object.entries(organizationServiceBenefits).map(([key, value]) => {
        const benefit = inNetwork ? value.in : value.out;
        // Skip if not service is hidden
        if (benefit?.hidden === true) return null;
        return (
          <View style={{ paddingTop: "8px" }}>
            <ServiceBenefitSummary
              key={key}
              name={key}
              serviceTypeCode={value.serviceTypeCode}
              benefit={benefit}
              providerServiceConfigurations={providerServiceConfigurations}
            />
          </View>
        );
      })}
    </View>
  );
};

export const BenefitsPDF: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: InsurancePolicy;
    providerServiceConfigurations: ProviderServiceConfiguration[];
  }>
> = ({ insurancePolicy, providerServiceConfigurations }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Header insurancePolicy={insurancePolicy} />
        </View>
        <View>
          <HealthPlanSummary
            insurancePolicy={insurancePolicy}
            inNetwork={insurancePolicy.inNetwork}
          />
        </View>
        <View>
          <ServiceBenefitsSummary
            insurancePolicy={insurancePolicy}
            providerServiceConfigurations={providerServiceConfigurations}
            inNetwork={insurancePolicy.inNetwork}
          />
        </View>
        {insurancePolicy.customPatientNote && (
          <View
            style={{
              width: "100%",
              display: "flex",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingTop: "12px",
              paddingBottom: "12px",
              flexDirection: "column",
              fontSize: 10,
            }}
          >
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              // Don't break within this component
              break={false}
            >
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  paddingBottom: "2px",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    ...styles.bold,
                    paddingBottom: "8px",
                    fontStyle: "italic",
                  }}
                >
                  Note to Patient
                </Text>

                <Text>{insurancePolicy.customPatientNote}</Text>
              </View>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};
