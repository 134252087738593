import { AppointmentsRow } from "../columns";
import { Card } from "../../../../components";
import { WorkflowStepStatus, WorkflowStepStatusDisplay } from ".";
import { HourglassIcon } from "lucide-react";

export const ChargeEntryWorkflowHoverCardContent: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const chargesEntered = row.chargeStatus;

  const charges = row.appointment.bill.at(0)?.charges ?? [];

  return (
    <Card>
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center gap-2">
          <h2 className="font-semibold">Charge Entry</h2>
          <WorkflowStepStatusDisplay status={status} />
        </div>
        <div className="text-wrap">
          {chargesEntered ? (
            <div className="flex flex-col">
              <div className="flex flex-col divide-y py-1">
                <dl className="flex flex-col gap-1 w-full">
                  <div className="flex items-center justify-between gap-4 border-b">
                    <dt className="text-gray-600 font-normal">Charge</dt>
                    <dd>Units</dd>
                  </div>
                  {charges.map((charge) => (
                    <div className="flex items-center justify-between gap-4">
                      <dt className="text-gray-600 font-normal">
                        {charge.customCode}
                      </dt>
                      <dd>{charge.transaction.description}</dd>
                      <dd>{charge.units}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          ) : (
            <div className="italic text-gray-500">
              Waiting for charges to be entered for visit.
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export const ChargeEntryWorkflowNextAction: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  if (status === "not_started") {
    // TODO: Show the scheduled verification date
    return (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <HourglassIcon className="h-4 w-4 text-gray-400" />
          Awaiting Charges
        </div>
      </div>
    );
  }
  return null;
};
