import { InsuranceType, MedicaidType } from "../../../generated/globalTypes";

type PlanType = {
  insuranceType: InsuranceType;
  medigapPlanType?: string;
  medicaidType?: string;
  tricareType?: string;
};

type CoordinationRuleProps = {
  planType: PlanType;
  className?: string;
};

const MedigapCoverageDisplay: React.FC<{
  medigapPlanType: string;
}> = ({ medigapPlanType }) => {
  const getMedigapCoverage = () => {
    switch (medigapPlanType) {
      case "PLAN_A":
        return "Covers Medicare coinsurance but not Part B deductible";
      case "PLAN_B":
        return "Covers Medicare coinsurance but not Part B deductible";
      case "PLAN_C":
        return "Covers all Medicare cost sharing including Part B deductible";
      case "PLAN_D":
        return "Covers Medicare coinsurance but not Part B deductible";
      case "PLAN_F":
        return "Covers all Medicare cost sharing including Part B deductible";
      case "PLAN_F_HIGH_DEDUCTIBLE":
        return "Covers all Medicare cost sharing after plan deductible is met";
      case "PLAN_G":
        return "Covers Medicare coinsurance but not Part B deductible";
      case "PLAN_K":
        return "Covers 50% of Medicare cost sharing";
      case "PLAN_L":
        return "Covers 75% of Medicare cost sharing";
      case "PLAN_M":
        return "Covers Medicare coinsurance but not Part B deductible";
      case "PLAN_N":
        return "Covers Medicare coinsurance (except some office visit copays) but not Part B deductible";
      default:
        return "Unknown Medigap plan type";
    }
  };

  return (
    <div className="flex items-center gap-2 bg-blue-50 px-3 py-1.5 rounded-md">
      <span className="text-sm font-medium text-blue-900 whitespace-nowrap">
        Medicare Supplement Rule:
      </span>
      <span className="text-sm text-blue-700">{getMedigapCoverage()}</span>
    </div>
  );
};

const MedicaidCoverageDisplay: React.FC<{
  medicaidType: MedicaidType;
}> = ({ medicaidType }) => {
  let medicaidProgram = null;
  if (medicaidType === MedicaidType.QUALIFIED_MEDICARE_BENEFICIARY) {
    medicaidProgram = "Qualified Medicare Beneficiary";
  }
  return (
    <div className="flex items-center gap-2 bg-blue-50 px-3 py-1.5 rounded-md">
      <span className="text-sm font-medium text-blue-900 whitespace-nowrap">
        Medicaid {medicaidProgram} Rule:
      </span>
      <span className="text-sm text-blue-700">
        Covers all Medicare cost sharing
      </span>
    </div>
  );
};

const TricareCoverageDisplay: React.FC = () => (
  <div className="flex items-center gap-2 bg-blue-50 px-3 py-1.5 rounded-md">
    <span className="text-sm font-medium text-blue-900 whitespace-nowrap">
      Tricare:
    </span>
    <span className="text-sm text-blue-700">
      Covers all Medicare cost sharing
    </span>
  </div>
);

export const getCoordinationRuleDisplay: React.FC<CoordinationRuleProps> = ({
  planType,
}) => {
  switch (planType.insuranceType) {
    case InsuranceType.MEDIGAP:
      return (
        <MedigapCoverageDisplay medigapPlanType={planType.medigapPlanType!} />
      );
    case InsuranceType.MEDICAID:
      if (
        planType.medicaidType === MedicaidType.QUALIFIED_MEDICARE_BENEFICIARY
      ) {
        return <MedicaidCoverageDisplay medicaidType={planType.medicaidType} />;
      }
      return null;
    case InsuranceType.TRICARE:
      return <TricareCoverageDisplay />;
    default:
      return null;
  }
};
