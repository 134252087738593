import React from "react";
import { classNames } from "../utils";

export const ProgressBar: React.FC<
  React.PropsWithChildren<{
    percentage: number;
    className?: string;
    innerBarClassName?: string;
  }>
> = ({ percentage, className, innerBarClassName }) => {
  return (
    <div
      className={classNames(
        "w-full h-3 rounded-lg border border-gray-200 bg-gray-200",
        className ?? ""
      )}
    >
      <div
        className={classNames(
          "bg-green-600 h-full rounded-lg transition duration-300 ease-in",
          innerBarClassName ?? ""
        )}
        style={{ width: `${percentage * 100}%` }}
      />
    </div>
  );
};

export const MultiProgressBar: React.FC<
  React.PropsWithChildren<{
    bars: { percentage: number; className?: string }[];
    className?: string;
  }>
> = ({ bars, className }) => {
  return (
    <div
      className={classNames(
        "flex-1 flex items-center overflow-hidden w-full h-3 rounded-lg border border-gray-200 bg-gray-200 group",
        className ?? ""
      )}
    >
      {bars.map((bar, index) => (
        <div
          key={index}
          className={classNames(
            "bg-green-600 h-full transition duration-300 ease-in",
            index === 0 ? "rounded-l-lg" : "",
            index === bars.length - 1 ? "rounded-r-lg" : "",
            bar.className ?? ""
          )}
          style={{ width: `${bar.percentage * 100}%` }}
        />
      ))}
    </div>
  );
};
