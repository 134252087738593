import React from "react";

import { FullWidthLayout, HorizontalPadding } from "../layout";
import { EstimationWorklist } from "./list";

export const EstimationWorkflow: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <FullWidthLayout
      header={
        <HorizontalPadding>
          <div className="flex flex-col pt-4">
            <h1 className="text-2xl font-semibold text-gray-900">
              Estimation Worklist
            </h1>
          </div>
        </HorizontalPadding>
      }
      content={
        <div className="flex flex-col">
          <div className="py-4">
            <EstimationWorklist />
          </div>
        </div>
      }
    />
  );
};
