import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  Table as ReactTable,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";

import { GetVerificationWorkflowPolicies_location_verificationWorkflowStatuses as LocationVerificationWorkflowStatus } from "../../generated/GetVerificationWorkflowPolicies";
import { DataTablePagination } from "./../../components/ui/table-helpers/data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
import { cn } from "./../../utils";
import { HorizontalPadding } from "./../layout";
import { VerificationWorklistRow } from "./columns";
import { OvalSpinner } from "./../../components/loading";
import { WorkflowStage } from "../../generated/globalTypes";

interface DataTableProps<TValue> {
  columns: ColumnDef<VerificationWorklistRow, TValue>[];
  defaultColumnFilters?: ColumnFiltersState;
  data: VerificationWorklistRow[];
}

export function DataTable<TValue>({
  columns,
  data,
  defaultColumnFilters,
  children,
  loading,
  loadingMore,
  workflowStatuses,
}: DataTableProps<TValue> & {
  children?: (table: ReactTable<VerificationWorklistRow>) => React.ReactElement;
  loading?: boolean;
  loadingMore?: boolean;
  workflowStatuses: LocationVerificationWorkflowStatus[];
}) {
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      lastExportedAt: false,
    });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    defaultColumnFilters ?? []
  );
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "date",
      desc: true,
    },
  ]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const ToDoStatuses = workflowStatuses.filter(
    (s) => s.stage === WorkflowStage.Todo
  );
  const statusFilter = columnFilters.find((filter) => filter.id === "status");
  const lastExportedAtVisible =
    Array.isArray(statusFilter?.value) &&
    statusFilter.value.some((statusFilterValue) =>
      ToDoStatuses.some((todo) => todo.name === statusFilterValue)
    );

  React.useEffect(() => {
    setColumnFilters(defaultColumnFilters ?? []);
  }, [defaultColumnFilters]);

  React.useEffect(() => {
    setColumnVisibility((columnVisibility) => ({
      ...columnVisibility,
      lastExportedAt: !lastExportedAtVisible,
    }));
  }, [lastExportedAtVisible]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter,
      pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: (filters) => {
      setColumnFilters(filters);
      // Reset to first page on filter change
      setPagination({ ...pagination, pageIndex: 0 });
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onPaginationChange: setPagination,
    autoResetPageIndex: false,
  });

  return (
    <>
      {children && children(table)}
      <div className="space-y-4">
        <HorizontalPadding>
          <div className="w-full">
            <DataTableToolbar table={table} />
          </div>
        </HorizontalPadding>
        <div className="border bg-white max-h-[75vh] overflow-x-auto">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="sticky top-0 z-10">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell, i) => (
                      <TableCell
                        key={cell.id}
                        className={cn(
                          "whitespace-nowrap"
                          // CSS to make the last column sticky
                          // "last:sticky last:right-0  last:bg-white last:drop-shadow"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    <div className="w-full flex justify-center">
                      <OvalSpinner />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <HorizontalPadding>
          <div className="w-full">
            <DataTablePagination
              table={table}
              loading={loading || loadingMore}
            />
          </div>
        </HorizontalPadding>
      </div>
    </>
  );
}
