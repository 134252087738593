import React, { useRef } from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import generatePDF from "react-to-pdf";
import { DownloadIcon } from "@heroicons/react/outline";
import {
  GetPatientReceiptDetails,
  GetPatientReceiptDetailsVariables,
  GetPatientReceiptDetails_getPatientReceiptDetails as Patient,
  GetPatientReceiptDetails_getPatientReceiptDetails_organization as Organization,
  GetPatientReceiptDetails_getPatientReceiptDetails_location as Location,
} from "../../../generated/GetPatientReceiptDetails";
import {
  GetPatientReceiptPaymentIntent,
  GetPatientReceiptPaymentIntentVariables,
  GetPatientReceiptPaymentIntent_getPatientReceiptPaymentIntent as PaymentIntent,
} from "../../../generated/GetPatientReceiptPaymentIntent";
import { handleAuthorizationError } from "../utils";
import { isDefined, formatUSD, formatDateMMDDYYYY } from "../../../utils";
import { OvalSpinner } from "../../../components/loading";

const GET_PATIENT_RECEIPT_DETAILS = gql`
  query GetPatientReceiptDetails($id: String!) {
    getPatientReceiptDetails(patientId: $id) {
      firstName
      lastName
      address1
      address2
      city
      state
      postalCode
      organization {
        id
        name
        logoUrl
      }
      location {
        id
        name
        address1
        address2
        city
        state
        postalCode
      }
    }
  }
`;

export const GET_PATIENT_RECEIPT_PAYMENT_INTENT = gql`
  query GetPatientReceiptPaymentIntent($id: String!) {
    getPatientReceiptPaymentIntent(paymentIntentId: $id) {
      id
      createdAt
      amount
      autoPay
      receiptCode
      payments {
        id
        patientAmount
        isPledgeRefund
        transaction {
          id
          transactedAt
          paymentAllocations {
            id
            amount
            createdAt
            chargeTransaction {
              id
              transactedAt
              description
              charge {
                id
                customCode
                bill {
                  id
                  dateOfServiceDisplay
                  billCode
                  status
                }
              }
            }
          }
          billPayments {
            id
            createdAt
            amount
            bill {
              id
              billCode
              dateOfServiceDisplay
              primaryProvider {
                id
                displayName
              }
            }
          }
        }
      }
      paymentIntentRefunds(where: { status: { equals: "succeeded" } }) {
        id
        amount
        createdAt
      }
    }
  }
`;

type ReceiptPatient = Pick<
  Patient,
  | "firstName"
  | "lastName"
  | "address1"
  | "address2"
  | "city"
  | "state"
  | "postalCode"
> & {
  organization: Pick<Organization, "id" | "name" | "logoUrl">;
  location: Pick<
    Location,
    "id" | "name" | "address1" | "address2" | "city" | "state" | "postalCode"
  >;
};

const Header: React.FC<{
  paymentIntent: PaymentIntent;
  patient: ReceiptPatient;
}> = ({ paymentIntent, patient }) => {
  return (
    <div className="w-full bg-gray-100 flex p-6">
      <div className="flex-1 pl-1 flex flex-col">
        <h1 className="text-2xl font-bold">{patient.organization.name}</h1>
        <div className="pt-2 font-bold">Receipt Number:</div>
        <div className="text-gray-500">{paymentIntent.receiptCode}</div>
        <div className="pt-2 font-bold">Payment Date:</div>
        <div className="text-gray-500">
          {formatDateMMDDYYYY(paymentIntent.createdAt)}
        </div>
        <div className="pt-2 font-bold">Total Payment Amount:</div>
        <div className="text-gray-500">{formatUSD(paymentIntent.amount)}</div>
      </div>
    </div>
  );
};

const BillingAddressSection: React.FC<{
  patient: ReceiptPatient;
}> = ({ patient }) => {
  const patientName = [patient.firstName, patient.lastName].join(" ");
  return (
    <div className="w-full flex p-6">
      <div className="flex-1 flex flex-col">
        <div className="pt-2 font-bold">Payment From:</div>
        <div className="text-gray-500">{patientName}</div>
        <div className="text-gray-500">
          {[patient.address1, patient.address2].filter(isDefined).join(", ")}
        </div>
        <div className="text-gray-500">
          {[patient.city, patient.state, patient.postalCode]
            .filter(isDefined)
            .join(", ")}
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="pt-2 font-bold">Payment To:</div>
        <div className="text-gray-500">{patient.location?.name}</div>
        <div className="text-gray-500">
          {[patient.location?.address1, patient.location?.address2]
            .filter(isDefined)
            .join(", ")}
        </div>
        <div className="text-gray-500">
          {[
            patient.location?.city,
            patient.location?.state,
            patient.location?.postalCode,
          ]
            .filter(isDefined)
            .join(", ")}
        </div>
      </div>
    </div>
  );
};

const LineItemsSection: React.FC<{
  paymentIntent: PaymentIntent;
}> = ({ paymentIntent }) => (
  <div className="w-full flex flex-col">
    {/* Header */}
    <div className="w-full grid grid-cols-7 bg-gray-50 text-center text-xs p-1 font-bold">
      <div className="col-span-1">Bill ID</div>
      <div className="col-span-1">Date of Service</div>
      <div className="col-span-4">Description</div>
      <div className="col-span-1">Payment Amount</div>
    </div>
    {/* Line Items */}
    {paymentIntent.payments.flatMap((payment) => [
      ...payment.transaction.paymentAllocations.map((pa) => {
        const isRefund = payment.isPledgeRefund;
        return (
          <div className="w-full grid grid-cols-7 text-center text-xs p-2">
            <div className="col-span-1">
              {pa.chargeTransaction.charge?.bill?.billCode}
            </div>
            <div className="col-span-1">
              {pa.chargeTransaction.charge?.bill?.dateOfServiceDisplay}
            </div>
            <div className="col-span-4">
              {isRefund && "Refunded "}
              {pa.chargeTransaction.description}
              {pa.chargeTransaction.charge?.customCode &&
                ` (${pa.chargeTransaction.charge.customCode})`}
            </div>
            <div className="col-span-1">{formatUSD(pa.amount)}</div>
          </div>
        );
      }),
      // Payments towards estimated charges
      ...payment.transaction.billPayments.map((bp) => {
        return (
          <div className="w-full grid grid-cols-7 text-center text-xs p-2">
            <div className="col-span-1">{bp.bill?.billCode}</div>
            <div className="col-span-1">
              {bp.bill?.dateOfServiceDisplay ?? null}
            </div>
            <div className="col-span-4">
              Visit with {bp.bill.primaryProvider?.displayName}
            </div>
            <div className="col-span-1">{formatUSD(bp.amount)}</div>
          </div>
        );
      }),
    ])}
    <div className="w-full grid grid-cols-7 p-2">
      <div className="col-span-5"></div>
      <div className="col-span-2 border-t border-gray-900 flex flex-col text-xs mx-5 px-2">
        <div className="flex py-2 font-bold">
          <div className="flex-1 text-left">Amount Paid</div>
          <div className="flex-1 mr-3 text-right">
            {formatUSD(paymentIntent.amount)}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const PatientReceipt: React.FC<{}> = () => {
  const { patientId, paymentIntentId, organizationId } = useParams<{
    patientId: string;
    paymentIntentId: string;
    organizationId: string;
  }>();
  const { data: getPatientData } = useQuery<
    GetPatientReceiptDetails,
    GetPatientReceiptDetailsVariables
  >(GET_PATIENT_RECEIPT_DETAILS, {
    variables: { id: patientId! },
    onError: (error) =>
      handleAuthorizationError(error, `/portal/${organizationId}`),
  });
  const { data: paymentIntentData } = useQuery<
    GetPatientReceiptPaymentIntent,
    GetPatientReceiptPaymentIntentVariables
  >(GET_PATIENT_RECEIPT_PAYMENT_INTENT, {
    variables: { id: paymentIntentId! },
    onError: (error) =>
      handleAuthorizationError(error, `/portal/${organizationId}`),
  });

  const targetRef = useRef(null);

  return getPatientData?.getPatientReceiptDetails &&
    paymentIntentData?.getPatientReceiptPaymentIntent ? (
    <div className="mt-5 w-full max-w-4xl mx-auto p-6 bg-white shadow-md">
      <div ref={targetRef}>
        <Header
          paymentIntent={paymentIntentData.getPatientReceiptPaymentIntent}
          patient={getPatientData.getPatientReceiptDetails}
        />
        <BillingAddressSection
          patient={getPatientData.getPatientReceiptDetails}
        />
        <LineItemsSection
          paymentIntent={paymentIntentData.getPatientReceiptPaymentIntent}
        />
      </div>
      <button
        onClick={() => {
          generatePDF(targetRef, {
            filename: `${paymentIntentId}.pdf`,
          });
        }}
      >
        <DownloadIcon className="flex-shrink-0 h-6 w-6" />
      </button>
    </div>
  ) : (
    <OvalSpinner className="text-indigo-300 h-8 w-8" />
  );
};
