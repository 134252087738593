import React, { Fragment, useEffect, useState } from "react";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Disclosure, RadioGroup, Tab } from "@headlessui/react";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  CreditCardIcon,
  DownloadIcon,
  ExclamationCircleIcon,
  ExternalLinkIcon,
  IdentificationIcon,
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
  ShieldCheckIcon,
  XIcon,
} from "@heroicons/react/outline";
import { CheckCircleIcon as SolidCheckCircleIcon } from "@heroicons/react/solid";
import {
  compareAsc,
  compareDesc,
  format,
  formatDistanceToNow,
  parseISO,
} from "date-fns";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import CurrencyInput from "react-currency-input-field";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { toast } from "react-toastify";

import { Rings } from "../../../components/loading";
import {
  GetPublicPatientBillingData,
  GetPublicPatientBillingDataVariables,
  GetPublicPatientBillingData_getPublicPatientBillingData as PublicPatient,
} from "../../../generated/GetPublicPatientBillingData";
import { CurrentSession } from "../../../generated/CurrentSession";
import { SubmitButton } from "../../../components/Button";
import { OTPLogin } from "../otp-login";
import {
  formatUSD,
  classNames,
  toCents,
  isDefined,
  toDate,
  formatDateMMDDYYYY,
} from "../../../utils";
import {
  GetPortalPatientBills,
  GetPortalPatientBillsVariables,
  GetPortalPatientBills_getPortalPatientBills_patient as Patient,
  GetPortalPatientBills_getPortalPatientBills_unpaidBills as BillSummary,
  GetPortalPatientBills_getPortalPatientBills_patient_paymentMethods as PaymentMethod,
} from "../../../generated/GetPortalPatientBills";
import {
  GetPortalPatientBillDetails,
  GetPortalPatientBillDetailsVariables,
  GetPortalPatientBillDetails_getPortalPatientBillDetails as Bill,
  GetPortalPatientBillDetails_getPortalPatientBillDetails_charges as Charge,
  GetPortalPatientBillDetails_getPortalPatientBillDetails_charges_transaction_chargeAllocations_paymentTransaction as PaymentTransaction,
  GetPortalPatientBillDetails_getPortalPatientBillDetails_charges_insuranceBillableCharges as InsuranceBillableCharge,
} from "../../../generated/GetPortalPatientBillDetails";
import {
  GetPortalPatientPaidBills,
  GetPortalPatientPaidBillsVariables,
} from "../../../generated/GetPortalPatientPaidBills";
import { constants } from "../../../constants";
import { LoginPatientUserOTP } from "../../../generated/LoginPatientUserOTP";
import { PatientPortalHeader } from "./header";
import { FeedbackSurvey } from "../feedback-survey";
import { CoveragePriority, FeedbackType } from "../../../generated/globalTypes";
import { StripeProvider } from "../../../stripe";
import { StripeCheckout } from "./payment-form";
import { useQueryParams, useUtmParams } from "../../../hooks";
import { NotFound } from "../../../components/404";
import {
  LoginPatientUserMagicLink,
  LoginPatientUserMagicLinkVariables,
} from "../../../generated/LoginPatientUserMagicLink";
import {
  MarkBillsAsViewed,
  MarkBillsAsViewedVariables,
} from "../../../generated/MarkBillsAsViewed";
import { PortalLayout } from "../portal-layout";
import { BillPDF } from "./bill-pdf";
import { ReceiptPDF } from "./receipt-pdf";
import { PatientReceipt } from "./receipt-page";
import { useAnalytics } from "../../../analytics-context";
import { Settings } from "../settings";
import { CardComponent } from "../../../components/card-icons";
import { AddPaymentMethodDialog } from "../../../pages/patients/profile";
import { Badge, Card, Tooltip } from "../../../components";
import {
  ChargeAmountToPatientPaymentMethod,
  ChargeAmountToPatientPaymentMethodVariables,
} from "../../../generated/ChargeAmountToPatientPaymentMethod";
import { communicationTypeDisplay } from "../../../pages/patients/activity-feed";
import { paymentMethodDisplay } from "../../../pages/patients/payment-method-display";
import { handleAuthorizationError } from "../utils";
import {
  GetPatientPayments,
  GetPatientPaymentsVariables,
  GetPatientPayments_getPatientPayments as PatientPayment,
} from "../../../generated/GetPatientPayments";
import {
  GetPatientPaymentsCount,
  GetPatientPaymentsCountVariables,
} from "../../../generated/GetPatientPaymentsCount";
import { PatientVisitPage } from "./patient-visit-page";
import { useAuth } from "../../../auth-context";
import {
  ChargeBillAmountsToPatientPaymentMethod,
  ChargeBillAmountsToPatientPaymentMethodVariables,
} from "../../../generated/ChargeBillAmountsToPatientPaymentMethod";

const BillFragment = gql`
  fragment PortalBill on Bill {
    id
    billCode
    status
    appointment {
      id
      start
      provider {
        id
        displayName
      }
    }
    dateOfService
    dateOfServiceDisplayLong
    dueDate
    firstBilledAt
    lastAdjudicatedAt
    paidAt
    patientBalance
    chargeTotal
    allowedTotal
    insurancePaidTotal
    patientPaidTotal
    patientPaymentsTotal
    patientResponsibility
    adjustmentsTotal
    primaryProvider {
      id
      displayName
    }
    toCollect {
      copayAmount
      coinsuranceAmount
      deductibleAmount
      otherAmount
    }
    charges {
      id
      units
      patientBalance
      chargeAmount
      copayAmount
      coinsuranceAmount
      deductibleAmount
      otherAmount
      insuranceAmount
      allowedAmount
      patientResponsibilityAmount
      customCode
      transaction {
        id
        description
        transactedAt
        chargeAllocations {
          id
          chargeTransactionId
          paymentTransactionId
          paymentTransaction {
            id
            type
            transactedAt
          }
          amount
          createdAt
        }
      }
      insuranceBillableCharges {
        id
        status
        billedAt
        paidAt
        accountCoverage {
          id
          insurancePolicy {
            id
            payer {
              id
              name
            }
          }
        }
      }
    }
    billedCoverages {
      id
    }
    billPayments {
      id
      createdAt
      amount
      paymentTransaction {
        id
        transactedAt
        payment {
          id
          refundedByPayments {
            id
            transaction {
              id
              transactedAt
              description
            }
            patientAmount
          }
        }
        paymentAllocations {
          id
          createdAt
          amount
          chargeTransaction {
            id
            customCode
            transactedAt
            charge {
              id
              billId
            }
          }
        }
      }
    }
    communications(
      where: {
        lastErrorType: { equals: null }
        sentAt: { not: { equals: null } }
      }
      orderBy: [{ sentAt: { sort: asc, nulls: last } }]
      take: 1
    ) {
      id
      type
      contentType
      sentAt
    }
    paymentRequestTargets(
      where: {
        paymentRequest: {
          is: { type: { in: [Autopay] }, status: { equals: Scheduled } }
        }
      }
      take: 1
      orderBy: { createdAt: desc }
    ) {
      id
    }
  }
`;

const GET_PAID_BILLS = gql`
  ${BillFragment}
  query GetPortalPatientPaidBills($id: String!) {
    getPortalPatientPaidBills(patientId: $id) {
      ...PortalBill
    }
  }
`;

const GET_PORTAL_BILL_DETAILS = gql`
  ${BillFragment}
  query GetPortalPatientBillDetails($billId: String!) {
    getPortalPatientBillDetails(billId: $billId) {
      ...PortalBill
    }
  }
`;

const BillSummaryFragment = gql`
  fragment PortalBillSummary on Bill {
    id
    dateOfServiceDisplayLong
    dateOfService
    status
    patientPaidTotal
    patientBalance
    dueDate
    appointment {
      id
      start
      provider {
        id
        displayName
      }
    }
    primaryProvider {
      id
      displayName
    }
    billedCoverages {
      id
    }
    paymentRequestTargets(
      where: {
        paymentRequest: {
          is: { type: { in: [Autopay] }, status: { equals: Scheduled } }
        }
      }
      take: 1
      orderBy: { createdAt: desc }
    ) {
      id
    }
  }
`;

const GET_READY_BILLS = gql`
  ${BillSummaryFragment}
  query GetPortalPatientBills($id: String!) {
    getPortalPatientBills(patientId: $id) {
      patient {
        id
        firstName
        lastName
        email
        cellPhone
        address1
        address2
        city
        state
        postalCode
        communicationPreferences
        autoPayEnabled
        enrolledInAutopay
        defaultPaymentMethod {
          id
          default
          cardBrand
          walletType
          lastFour
          expirationMonth
          expirationYear
          funding
          type
        }
        nextAutopayPaymentRequest: paymentRequests(
          where: { type: { in: [Autopay] }, status: { equals: Scheduled } }
          take: 1
          orderBy: { scheduledAt: { sort: desc, nulls: last } }
        ) {
          id
          scheduledAt
          amount
        }
        patientPayments(take: 3) {
          payment {
            id
            patientAmount
            isPledgeRefund
            transaction {
              id
              transactedAt
              paymentAllocations {
                id
                amount
                createdAt
                chargeTransaction {
                  id
                  transactedAt
                  description
                  charge {
                    id
                    customCode
                    bill {
                      id
                      dateOfServiceDisplay
                      billCode
                      status
                    }
                  }
                }
              }
              billPayments {
                id
                createdAt
                amount
                bill {
                  id
                  billCode
                  dateOfServiceDisplay
                  primaryProvider {
                    id
                    displayName
                  }
                }
              }
            }
          }
          paymentIntent {
            id
            createdAt
            amount
            autoPay
            receiptCode
            payments {
              id
              patientAmount
              isPledgeRefund
              transaction {
                id
                transactedAt
                paymentAllocations {
                  id
                  amount
                  createdAt
                  chargeTransaction {
                    id
                    transactedAt
                    description
                    charge {
                      id
                      customCode
                      bill {
                        id
                        dateOfServiceDisplay
                        billCode
                        status
                      }
                    }
                  }
                }
                billPayments {
                  id
                  createdAt
                  amount
                  bill {
                    id
                    billCode
                    dateOfServiceDisplay
                    primaryProvider {
                      id
                      displayName
                    }
                  }
                }
              }
            }
            paymentIntentRefunds(where: { status: { equals: "succeeded" } }) {
              id
              amount
              createdAt
            }
          }
          transactedAt
        }
        paymentMethods(where: { detatchedAt: { equals: null } }) {
          id
          default
          cardBrand
          walletType
          lastFour
          expirationMonth
          expirationYear
          funding
          type
        }
        patientStatementBalance {
          finalBalance
          unAllocatedCredits
          readyBalance
        }
        patientInReviewBalance
        # patientPendingBalance
        organization {
          id
          name
          logoUrl
          primaryEmail
        }
        location {
          id
          primaryEmail
          name
          locationDisplayName
          address1
          address2
          city
          state
          postalCode
        }
        insurancePolicies(
          where: { deletedAt: null, active: { equals: true } }
        ) {
          id
          priority
          active
          memberId
          payer {
            id
            name
          }
        }
        organizationPatientGroup {
          id
          # Filter out the current patient
          patients(where: { id: { not: { equals: $id } } }) {
            id
            firstName
            lastName
            dateOfBirth
            patientReadyBalance
            location {
              id
              name
            }
          }
        }
      }
      unpaidBills {
        ...PortalBillSummary
      }
      inReviewBills {
        ...PortalBillSummary
      }
    }
  }
`;

const formatDate = (date: Date) => {
  return format(new Date(date), "MMMM do, yyyy");
};

type Event = {
  id: string;
  timestamp: Date;
  dateDisplay: string;
  description: React.ReactNode;
  icon?: React.ReactNode;
};

const BillActivityTimeline: React.FC<
  React.PropsWithChildren<{
    bill: Bill;
    patient: Patient;
  }>
> = ({ bill, patient }) => {
  const uniqueBilledCharges = new Map<string, InsuranceBillableCharge>();

  for (const charge of bill.charges) {
    for (const billableCharge of charge.insuranceBillableCharges) {
      const accountCoverage = billableCharge.accountCoverage;
      if (!accountCoverage) continue;
      const existing = uniqueBilledCharges.get(accountCoverage.id);

      // If billableCharge is paid after existing charge, replace it
      // Or if billableCharge and existing both not paid, if billableCharge is billed after existing charge, replace it
      if (existing) {
        if (
          (billableCharge.paidAt &&
            existing.paidAt &&
            billableCharge.paidAt > existing.paidAt) ||
          (!billableCharge.paidAt &&
            !existing.paidAt &&
            billableCharge.billedAt > existing.billedAt)
        ) {
          uniqueBilledCharges.set(accountCoverage.id, billableCharge);
        }
      } else {
        uniqueBilledCharges.set(accountCoverage.id, billableCharge);
      }
    }
  }

  const claimBilledEvents = Array.from(uniqueBilledCharges)
    .filter(([id, insuranceBillableCharge]) => insuranceBillableCharge.billedAt)
    .map(([id, insuranceBillableCharge]) => {
      return {
        id: id + "-insurance-billed",
        timestamp: parseISO(insuranceBillableCharge.billedAt),
        dateDisplay: formatDistanceToNow(
          parseISO(insuranceBillableCharge.billedAt),
          {
            addSuffix: true,
          }
        ),
        description:
          "Claim billed to " +
            insuranceBillableCharge.accountCoverage!.insurancePolicy.payer
              .name ?? "Insurance",
      };
    });
  const claimAdjudicatedEvents = Array.from(uniqueBilledCharges)
    .filter(([id, insuranceBillableCharge]) => insuranceBillableCharge.paidAt)
    .map(([id, insuranceBillableCharge]) => {
      return {
        id: id + "-insurance-adjudicated",
        timestamp: parseISO(insuranceBillableCharge.paidAt),
        dateDisplay: formatDistanceToNow(
          parseISO(insuranceBillableCharge.paidAt),
          {
            addSuffix: true,
          }
        ),
        description:
          "Claim paid by " +
            insuranceBillableCharge.accountCoverage!.insurancePolicy.payer
              .name ?? "Insurance",
      };
    });

  // Just the first sent event
  const communicationEvents = bill.communications.map((c) => ({
    id: c.id,
    timestamp: parseISO(c.sentAt),
    dateDisplay: formatDistanceToNow(parseISO(c.sentAt), {
      addSuffix: true,
    }),
    description: `Bill sent via ${communicationTypeDisplay(c.type)}`,
  }));
  const billPayments = bill.billPayments.flatMap((bp) => {
    let billPaymentEvents = [];
    if (bp.paymentTransaction.paymentAllocations.length > 0) {
      for (const pa of bp.paymentTransaction.paymentAllocations) {
        // If a payment is allocated to a charge that is not on this bill, then add an event
        if (pa.chargeTransaction.charge?.billId !== bill.id) {
          billPaymentEvents.push({
            id: pa.id,
            timestamp: parseISO(pa.createdAt),
            dateDisplay: formatDistanceToNow(parseISO(pa.createdAt), {
              addSuffix: true,
            }),
            description: (
              <>
                <span className="font-medium text-gray-700">
                  {formatUSD(pa.amount)}
                </span>{" "}
                of {formatDateMMDDYYYY(bp.paymentTransaction.transactedAt)}{" "}
                payment reallocated towards charges{" "}
                {pa.chargeTransaction.customCode} on{" "}
                {formatDateMMDDYYYY(pa.chargeTransaction.transactedAt)}
              </>
            ),
          });
        }
      }
    }
    const refunds = bp.paymentTransaction.payment?.refundedByPayments || [];
    for (const refund of refunds) {
      billPaymentEvents.push({
        id: refund.id,
        timestamp: parseISO(refund.transaction.transactedAt),
        dateDisplay: formatDistanceToNow(
          parseISO(refund.transaction.transactedAt),
          {
            addSuffix: true,
          }
        ),
        description: (
          <>
            <span className="font-medium text-gray-700">
              {formatUSD(-refund.patientAmount)}
            </span>{" "}
            of {formatDateMMDDYYYY(bp.paymentTransaction.transactedAt)} payment
            refunded
          </>
        ),
      });
    }
    billPaymentEvents.push({
      id: bp.id,
      timestamp: parseISO(bp.createdAt),
      dateDisplay: formatDistanceToNow(parseISO(bp.createdAt), {
        addSuffix: true,
      }),
      description: (
        <>
          Payment of{" "}
          <span className="font-medium text-gray-700">
            {formatUSD(bp.amount)}
          </span>{" "}
          towards bill
        </>
      ),
    });
    return billPaymentEvents;
  });

  const chargePayments = bill.charges.flatMap((c) =>
    c.transaction.chargeAllocations.map((ca) => ({ ...ca, charge: c }))
  );
  const lastPayment = chargePayments.sort((a, b) =>
    compareDesc(
      parseISO(a.paymentTransaction.transactedAt),
      parseISO(b.paymentTransaction.transactedAt)
    )
  )[0];
  const payments = chargePayments.reduce(
    (acc, cp) => {
      const id = cp.paymentTransaction.id;
      const payment = acc[id] ?? {
        amount: 0,
        charges: [],
        paymentTransaction: cp.paymentTransaction,
      };
      return {
        ...acc,
        [id]: {
          ...payment,
          amount: payment.amount + cp.amount,
          charges: [...payment.charges, cp.charge],
        },
      };
    },
    {} as {
      [paymentId: string]: {
        amount: number;
        paymentTransaction: PaymentTransaction;
        charges: Charge[];
      };
    }
  );

  const paymentEvents = Object.entries(payments).map(([id, payment]) => {
    const isCredit = payment.amount < 0;
    const isRefund = isCredit && payment.paymentTransaction.type === "Payment";
    const isAdjustment =
      isCredit && payment.paymentTransaction.type === "Adjustment";
    return {
      id,
      timestamp: parseISO(payment.paymentTransaction.transactedAt),
      dateDisplay: formatDistanceToNow(
        parseISO(payment.paymentTransaction.transactedAt),
        {
          addSuffix: true,
        }
      ),
      description: (
        <>
          {isRefund ? "Refund" : isAdjustment ? "Adjustment" : "Payment"} of{" "}
          <span className="font-medium text-gray-700">
            {formatUSD(payment.amount)}
          </span>{" "}
        </>
      ),
    };
  });
  let activity: Event[] = [
    {
      id: bill.id,
      timestamp: parseISO(bill.appointment?.start ?? bill.dateOfService),
      dateDisplay: formatDistanceToNow(
        parseISO(bill.appointment?.start ?? bill.dateOfService),
        {
          addSuffix: true,
        }
      ),
      description: bill.primaryProvider?.displayName
        ? `Visit with ${bill.primaryProvider.displayName}`
        : "Visit",
    },
    ...claimBilledEvents,
    ...claimAdjudicatedEvents,
    // ...communicationEvents,
    ...billPayments,
    ...paymentEvents,
  ].sort((a, b) => compareAsc(a.timestamp, b.timestamp));

  // Add the bill paid event at the very end
  const billPaidTimestamp =
    activity.slice(-1)[0]?.timestamp ??
    lastPayment.paymentTransaction.transactedAt ??
    bill.paidAt;
  const billPaidEvent =
    (bill.status === "Reconciled" || bill.status === "Resolved") &&
    billPaidTimestamp
      ? [
          {
            id: bill.id + "-paid",
            timestamp: billPaidTimestamp,
            // timestamp: parseISO(
            //   bill.paidAt ?? lastPayment.paymentTransaction.transactedAt
            // ),
            dateDisplay: formatDistanceToNow(billPaidTimestamp, {
              addSuffix: true,
            }),
            description: "Bill paid",
            icon: (
              <SolidCheckCircleIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            ),
          },
        ]
      : [];
  activity = [...activity, ...billPaidEvent];

  return (
    <ul role="list" className="space-y-4 w-full">
      {activity.map((event, idx) => (
        <li key={event.id} className="relative flex sm:gap-x-4">
          <div
            className={classNames(
              idx === activity.length - 1 ? "h-6" : "-bottom-6",
              "absolute left-0 top-0 flex w-6 justify-center"
            )}
          >
            <div className="w-px bg-gray-200" />
          </div>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
            {event.icon ?? (
              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
            )}
          </div>
          <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
            {event.description}
          </p>
          <Tooltip
            trigger={
              <time
                dateTime={event.timestamp.toISOString()}
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
              >
                {event.dateDisplay}
              </time>
            }
            content={<>{formatDateMMDDYYYY(event.timestamp.toISOString())}</>}
          />
        </li>
      ))}
    </ul>
  );
};

const DEDUCTIBLE_HELP_TEXT =
  "Your deductible is the dollar amount you have to spend on covered care in a plan year before your insurance begins sharing the cost of your care. If you had not met your deductible before receiving this care, you pay for the cost of care until you meet your deductible.";
const COPAY_HELP_TEXT =
  "A copay is a fixed dollar amount you pay for certain types of covered care. Your copay varies depending on the specific type of care you receive.";
const COINSURANCE_HELP_TEXT =
  "Coinsurance is a way of sharing cost of services after you reach your deductible. Your coinsurance responsibility varies depending on the specific type of care you receive.";
const INSURANCE_DISCOUNT_HELP_TEXT =
  "As part of your plan, we contracted a maximum allowed charge for each service. The difference between the allowed charge and the provider billed amount is a plan discount to you.";
const INSURANCE_PAID_HELP_TEXT =
  "This is the amount your insurance paid after the plan discount and any of the costs you are responsible for, as defined by your plan.";

const ChargeDetail: React.FC<
  React.PropsWithChildren<{ bill: Bill; charge: Charge }>
> = ({ bill, charge }) => {
  const { setHelpDialog } = useHelpDialog();
  const billedToInsurance = bill.billedCoverages.length > 0;
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-base">
        <div className="flex flex-col px-1 sm:px-4 order-1">
          <div className="font-medium">Insurance benefits</div>
          {billedToInsurance && (
            <>
              <div className="flex justify-between">
                <div>
                  <button
                    onClick={() =>
                      setHelpDialog({
                        title: "Insurance Discount",
                        content: INSURANCE_DISCOUNT_HELP_TEXT,
                      })
                    }
                  >
                    <span className="decoration-dotted underline underline-offset-2">
                      Insurance Discount
                    </span>
                  </button>
                </div>
                <div>
                  {formatUSD(-(charge.chargeAmount - charge.allowedAmount))}
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <button
                    onClick={() =>
                      setHelpDialog({
                        title: "Insurance Paid",
                        content: INSURANCE_PAID_HELP_TEXT,
                      })
                    }
                  >
                    <span className="decoration-dotted underline underline-offset-2">
                      Insurance Paid
                    </span>
                  </button>
                </div>
                <div>{formatUSD(-charge.insuranceAmount)}</div>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col px-1 sm:px-4 order-3 sm:order-2">
          <div className="font-medium">Your Cost</div>
          {billedToInsurance && (
            <>
              <div className="flex justify-between">
                <div>
                  <button
                    onClick={() =>
                      setHelpDialog({
                        title: "Copay",
                        content: COPAY_HELP_TEXT,
                      })
                    }
                  >
                    <span className="decoration-dotted underline underline-offset-2">
                      Copay
                    </span>
                  </button>
                </div>
                <div>{formatUSD(-charge.copayAmount)}</div>
              </div>
              <div className="flex justify-between">
                <div>
                  <button
                    onClick={() =>
                      setHelpDialog({
                        title: "Coinsurance",
                        content: COINSURANCE_HELP_TEXT,
                      })
                    }
                  >
                    <span className="decoration-dotted underline underline-offset-2">
                      Coinsurance
                    </span>
                  </button>
                </div>
                <div>{formatUSD(-charge.coinsuranceAmount)}</div>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-1">
                  Applied towards
                  <button
                    onClick={() =>
                      setHelpDialog({
                        title: "Deductible",
                        content: DEDUCTIBLE_HELP_TEXT,
                      })
                    }
                  >
                    <span className="decoration-dotted underline underline-offset-2">
                      deductible
                    </span>
                  </button>
                </div>
                <div>{formatUSD(-charge.deductibleAmount)}</div>
              </div>
            </>
          )}
        </div>

        <div className="flex justify-between px-1 sm:px-4 order-2 sm:order-3">
          {billedToInsurance ? (
            <>
              <div>Total insurance benefits</div>
              <div className="font-semibold">
                {formatUSD(
                  -(charge.chargeAmount - charge.patientResponsibilityAmount)
                )}
              </div>
            </>
          ) : (
            <div className="italic">Not billed to insurance</div>
          )}
        </div>
        <div className="flex justify-between px-1 sm:px-4 order-4">
          <div>Your total cost</div>
          <div className="font-semibold">
            {formatUSD(-charge.patientResponsibilityAmount)}
          </div>
        </div>
      </div>
    </>
  );
};

const BillDetail: React.FC<
  React.PropsWithChildren<{
    billId: string;
    patient: Patient;
    showDownload: boolean;
  }>
> = ({ billId, patient, showDownload }) => {
  const analytics = useAnalytics();
  const { setHelpDialog } = useHelpDialog();
  const { data, loading } = useQuery<
    GetPortalPatientBillDetails,
    GetPortalPatientBillDetailsVariables
  >(GET_PORTAL_BILL_DETAILS, {
    variables: { billId },
    onError: (error) => {
      handleAuthorizationError(
        error,
        `/portal/${patient.organization.id}/${patient.id}`
      );
    },
  });

  if (loading || !data?.getPortalPatientBillDetails) return <>Loading...</>;

  const bill = data.getPortalPatientBillDetails;
  const billedToInsurance = bill.billedCoverages.length > 0;
  const { charges } = bill;

  const sendBillPdfDownloadEvent = () => {
    analytics?.track("Bill PDF Downloaded", {
      billId: bill.id,
      dateOfService: bill.dateOfService,
      status: bill.status,
      organizationId: patient.organization.id,
      organizationName: patient.organization.name,
      locationId: patient.location.id,
      locationName: patient.location.name,
    });
  };

  const sendExpandedPatientPortalChargeRow = () => {
    analytics?.track("Expanded Patient Portal Charge Row", {
      billId: bill.id,
      dateOfService: bill.dateOfService,
      status: bill.status,
      organizationId: patient.organization.id,
      organizationName: patient.organization.name,
      locationId: patient.location.id,
      locationName: patient.location.name,
    });
  };

  return (
    <div>
      {/* Mobile Table */}
      <table className="w-full text-xs sm:text-sm sm:hidden">
        <thead>
          <th className="p-1 text-left">Services</th>
          <th className="p-1 text-right">Patient Responsibility</th>
        </thead>
        <tbody>
          {charges.map((charge) => {
            return (
              <Disclosure as={Fragment} key={charge.id}>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      as="tr"
                      className={classNames(
                        "hover:bg-gray-100 cursor-pointer",
                        open ? "border border-gray-200" : ""
                      )}
                      onClick={() => sendExpandedPatientPortalChargeRow()}
                    >
                      <td className="p-1 text-left">
                        {charge.transaction.description}
                      </td>
                      <td className="p-1 text-right">
                        {formatUSD(-charge.patientResponsibilityAmount)}
                      </td>
                      <td>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "-rotate-180" : "rotate-0",
                            "h-4 w-4 transform text-gray-500"
                          )}
                          aria-hidden="true"
                        />
                      </td>
                    </Disclosure.Button>
                    <Disclosure.Panel
                      as="tr"
                      className={classNames(
                        open ? "border-b border-x border-gray-200 shadow" : ""
                      )}
                    >
                      <td colSpan={3} className="p-1">
                        <ChargeDetail bill={bill} charge={charge} />
                      </td>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            );
          })}
          <tr className="border-t font-medium">
            <td className="p-1">Total</td>
            <td className="p-1 text-right">
              {formatUSD(-bill.patientResponsibility)}
            </td>
          </tr>
        </tbody>
      </table>
      {/* Desktop Table */}
      <table className="w-full text-xs sm:text-sm hidden sm:inline-table">
        <thead>
          <th className="p-1 text-left">Services</th>
          <th className="p-1 text-left">Quantity</th>
          <th className="p-1 text-right">Charges</th>
          {billedToInsurance && (
            <th className="p-1 text-right">
              <button
                onClick={() =>
                  setHelpDialog({
                    title: "Insurance Discount",
                    content: INSURANCE_DISCOUNT_HELP_TEXT,
                  })
                }
              >
                <span className="font-bold decoration-dotted underline underline-offset-2">
                  Insurance Discount
                </span>
              </button>
            </th>
          )}
          {billedToInsurance && (
            <th className="p-1 text-right">
              <button
                onClick={() =>
                  setHelpDialog({
                    title: "Insurance Paid",
                    content: INSURANCE_PAID_HELP_TEXT,
                  })
                }
              >
                <span className="font-bold decoration-dotted underline underline-offset-2">
                  Insurance Paid
                </span>
              </button>
            </th>
          )}
          <th className="p-1 text-right">Patient Responsibility</th>
          <th></th>
        </thead>
        <tbody>
          {charges.map((charge) => {
            return (
              <Disclosure as={Fragment} key={charge.id}>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      as="tr"
                      className={classNames(
                        "hover:bg-gray-100 cursor-pointer",
                        open ? "border border-gray-200" : ""
                      )}
                      onClick={() => sendExpandedPatientPortalChargeRow()}
                    >
                      <td className="p-2 text-left">
                        {charge.transaction.description}
                      </td>
                      <td className="p-2 text-left">
                        {(charge.units ?? 1) +
                          (charge.units === 1 ? " unit" : " units")}
                      </td>
                      <td className="p-2 text-right">
                        {formatUSD(-charge.chargeAmount)}
                      </td>
                      {billedToInsurance && (
                        <td className="p-2 text-right">
                          {formatUSD(
                            -(charge.chargeAmount - charge.allowedAmount)
                          )}
                        </td>
                      )}
                      {billedToInsurance && (
                        <td className="p-2 text-right">
                          {formatUSD(-charge.insuranceAmount)}
                        </td>
                      )}
                      <td className="p-2 text-right">
                        {formatUSD(-charge.patientResponsibilityAmount)}
                      </td>
                      <td>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "-rotate-180" : "rotate-0",
                            "h-4 w-4 transform text-gray-500"
                          )}
                          aria-hidden="true"
                        />
                      </td>
                    </Disclosure.Button>
                    <Disclosure.Panel
                      as="tr"
                      className={classNames(
                        open ? "border-b border-x border-gray-200 shadow" : ""
                      )}
                    >
                      <td colSpan={6} className="p-4">
                        <ChargeDetail bill={bill} charge={charge} />
                      </td>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            );
          })}
          <tr className="border-t font-medium">
            <td className="p-1" colSpan={2}>
              Total
            </td>
            <td className="p-1 text-right">{formatUSD(-bill.chargeTotal)}</td>
            {billedToInsurance && (
              <td className="p-1 text-right">
                {formatUSD(-(bill.chargeTotal - bill.allowedTotal))}
              </td>
            )}
            {billedToInsurance && (
              <td className="p-1 text-right hidden sm:block">
                {formatUSD(-bill.insurancePaidTotal)}
              </td>
            )}
            <td className="p-1 text-right">
              {formatUSD(-bill.patientResponsibility)}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div className="flex flex-col-reverse sm:flex-row justify-between gap-2 px-1 py-2">
        <div className="flex flex-col">
          <h2 className="text-md py-1 font-semibold leading-6 text-gray-900">
            Activity
          </h2>
          <div className="lg:min-w-[24em]">
            <BillActivityTimeline bill={bill} patient={patient} />
          </div>
        </div>
        <div>
          <h2 className="text-md py-1 font-semibold leading-6 text-gray-900">
            Bill Summary
          </h2>
          <table className="text-sm w-full lg:min-w-[36em]">
            <tbody>
              <tr>
                <td>Total charges</td>
                <td className="text-right pl-1">
                  {formatUSD(-bill.chargeTotal)}
                </td>
              </tr>
              {billedToInsurance && (
                <tr>
                  <td>
                    Total
                    <button
                      onClick={() =>
                        setHelpDialog({
                          title: "Insurance Discount",
                          content: INSURANCE_DISCOUNT_HELP_TEXT,
                        })
                      }
                    >
                      <span className="pl-1 decoration-dotted underline underline-offset-2">
                        insurance discount
                      </span>
                    </button>
                  </td>
                  <td className="text-right pl-1">
                    {formatUSD(bill.chargeTotal - bill.allowedTotal)}
                  </td>
                </tr>
              )}
              {billedToInsurance && (
                <tr>
                  <td>
                    Total
                    <button
                      onClick={() =>
                        setHelpDialog({
                          title: "Insurance Paid",
                          content: INSURANCE_PAID_HELP_TEXT,
                        })
                      }
                    >
                      <span className="pl-1 decoration-dotted underline underline-offset-2">
                        amount paid by insurance
                      </span>
                    </button>
                  </td>
                  <td className="text-right pl-1">
                    {formatUSD(bill.insurancePaidTotal)}
                  </td>
                </tr>
              )}
              <tr>
                <td className="pt-2">Total patient responsibility</td>
                <td className="text-right pt-2 pl-1">
                  {formatUSD(-bill.patientResponsibility)}
                </td>
              </tr>
              {/* If part of the bill was marked as an other amount */}
              {isDefined(bill.toCollect.otherAmount) &&
                bill.toCollect.otherAmount !== 0 &&
                billedToInsurance && (
                  <>
                    {bill.toCollect.copayAmount && (
                      <tr>
                        <td className="pl-2">
                          <button
                            onClick={() =>
                              setHelpDialog({
                                title: "Copay",
                                content: COPAY_HELP_TEXT,
                              })
                            }
                          >
                            <span className="decoration-dotted underline underline-offset-2">
                              <ul className="list-disc list-inside">
                                <li>Copay</li>
                              </ul>
                            </span>
                          </button>
                        </td>
                        <td className="text-right">
                          {formatUSD(bill.toCollect.copayAmount)}
                        </td>
                      </tr>
                    )}
                    {isDefined(bill.toCollect.coinsuranceAmount) &&
                      bill.toCollect.coinsuranceAmount !== 0 && (
                        <tr>
                          <td className="pl-2">
                            <button
                              onClick={() =>
                                setHelpDialog({
                                  title: "Coinsurance",
                                  content: COINSURANCE_HELP_TEXT,
                                })
                              }
                            >
                              <span className="decoration-dotted underline underline-offset-2">
                                <ul className="list-disc list-inside">
                                  <li>Coinsurance</li>
                                </ul>
                              </span>
                            </button>
                          </td>
                          <td className="text-right">
                            {formatUSD(bill.toCollect.coinsuranceAmount)}
                          </td>
                        </tr>
                      )}
                    {isDefined(bill.toCollect.deductibleAmount) &&
                      bill.toCollect.deductibleAmount !== 0 && (
                        <tr>
                          <td className="pl-2">
                            <ul className="list-disc list-inside">
                              <li>
                                Towards
                                <button
                                  onClick={() =>
                                    setHelpDialog({
                                      title: "Deductible",
                                      content: DEDUCTIBLE_HELP_TEXT,
                                    })
                                  }
                                >
                                  <span className="decoration-dotted underline underline-offset-2 ml-1">
                                    deductible
                                  </span>
                                </button>
                              </li>
                            </ul>
                          </td>
                          <td className="text-right">
                            {formatUSD(bill.toCollect.deductibleAmount)}
                          </td>
                        </tr>
                      )}
                  </>
                )}
              <tr>
                <td className="pb-1">
                  Total amount paid by you so far towards visit
                </td>
                <td className="pb-1 pl-1 text-right">
                  {formatUSD(-bill.patientPaidTotal)}
                </td>
              </tr>
              <tr className="border-t">
                <td className="pt-1 text-base font-semibold ">
                  Total amount due
                </td>
                <td className="pt-1 pl-1 text-base font-semibold text-right">
                  {formatUSD(-bill.patientBalance)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {showDownload && (
        <div className="flex justify-around sm:justify-end w-full space-x-2 pt-2">
          {/* @ts-ignore */}
          <PDFDownloadLink
            document={<BillPDF bill={bill} patient={patient} />}
            fileName={`${
              isDefined(bill.dateOfService)
                ? toDate(bill.dateOfService)
                : bill.billCode ?? bill.id
            }.pdf`}
            // className="inline-flex items-center space-x-1 justify-center mt-0 sm:mt-[-40px] px-4 py-2 text-sm font-medium text-gray-900 border border-gray-300 rounded-md hover:bg-gray-200"
            className="inline-flex items-center space-x-1 justify-center mt-0 px-4 py-2 text-sm font-medium text-gray-900 border border-gray-300 rounded-md hover:bg-gray-200"
            onClick={() => sendBillPdfDownloadEvent()}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <>
                  <div>Downloading</div>
                  <DownloadIcon className="h-6 w-6" />
                </>
              ) : (
                <>
                  <div>Download</div>
                  <DownloadIcon className="h-6 w-6" />
                </>
              )
            }
          </PDFDownloadLink>
        </div>
      )}
    </div>
  );
};

type HelpDialogContent = { title: string; content: string };

const HelpDialog: React.FC<
  React.PropsWithChildren<{
    helpDialog: HelpDialogContent;
    setHelpDialog: (helpDialog: HelpDialogContent | null) => void;
  }>
> = ({ helpDialog, setHelpDialog }) => {
  return (
    <Transition.Root show={!!helpDialog} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => setHelpDialog(null)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-xl font-medium leading-6 text-gray-900"
              >
                {helpDialog.title}
              </Dialog.Title>
              <div className="mt-2 text-sm">{helpDialog.content}</div>

              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                  onClick={() => setHelpDialog(null)}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const DueDate: React.FC<
  React.PropsWithChildren<{ isPastPaidBill: boolean; dueDate: Date }>
> = ({ isPastPaidBill, dueDate }) => {
  return (
    <div className={isPastPaidBill ? "hidden" : "" + "flex justify-start"}>
      <span
        className={
          (new Date(dueDate) < new Date() ? "bg-red-200 " : "bg-green-200 ") +
          "text-xs text-gray-500 px-2 py-1 mt-1 rounded-lg my-1"
        }
      >
        {(new Date(dueDate) < new Date() ? "Past Due " : "Due ") +
          formatDate(dueDate)}
      </span>
    </div>
  );
};

const AutopayBadge: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <span className="text-xs text-green-500 px-2 py-1 mt-1 rounded-lg my-1 bg-green-200">
      Autopay enabled
    </span>
  );
};

enum BillCategory {
  Unpaid,
  Upcoming,
  Pending,
  Paid,
}

const BillList: React.FC<
  React.PropsWithChildren<{
    title: string;
    bills: BillSummary[];
    totalAmount?: number;
    category: BillCategory;
    patient: Patient;
    showDownload: boolean;
  }>
> = ({ title, bills, totalAmount, category, patient, showDownload }) => {
  const analytics = useAnalytics();
  return (
    // <div className="bg-white shadow rounded-md my-4">
    <div className="border border-gray-300 bg-white rounded-md">
      <div className="p-2 border-b">
        <div className="flex justify-between">
          <div className="text-gray-600">{title}</div>
          {isDefined(totalAmount) && (
            <div className="px-2">
              {totalAmount !== 0 ? formatUSD(totalAmount) : "$0.00"}
            </div>
          )}
        </div>
      </div>
      {bills.length === 0 ? (
        <div className="text-gray-600 text-sm flex justify-center py-2">
          {"No " + title.toLowerCase()}
        </div>
      ) : (
        <ul role="list" className="divide-y divide-gray-200">
          {bills.map((bill) => {
            const sendExpandedPatientPortalVisitRow = () => {
              analytics?.track("Expanded Patient Portal Visit Row", {
                billId: bill.id,
                dateOfService: bill.dateOfService,
                status: bill.status,
                organizationId: patient.organization.id,
                organizationName: patient.organization.name,
                locationId: patient.location.id,
                locationName: patient.location.name,
              });
            };

            const provider = bill.primaryProvider;
            const providerDisplay = provider?.displayName;
            const billedToInsurance = bill.billedCoverages.length > 0;
            return (
              <Disclosure as="div" key={bill.id}>
                {({ open }) => (
                  <>
                    <dt className="hover:bg-gray-50">
                      <Disclosure.Button
                        className="flex w-full px-4 md:px-8 py-4"
                        onClick={() => sendExpandedPatientPortalVisitRow()}
                      >
                        <div className="flex-col w-full">
                          <div className="flex justify-between">
                            <div className="text-md font-semibold text-left">
                              {bill.dateOfServiceDisplayLong}
                            </div>
                            <div className="text-md font-semibold justify-end">
                              {category === BillCategory.Paid
                                ? formatUSD(bill.patientPaidTotal)
                                : formatUSD(-bill.patientBalance)}
                            </div>
                          </div>

                          <div className="flex gap-2">
                            <p className="flex items-center text-sm text-gray-500">
                              <span>{providerDisplay}</span>
                            </p>
                            {category === BillCategory.Paid && (
                              <div>
                                <div className="flex gap-1 text-xs text-gray-500 p-1.5 rounded-lg bg-gray-100">
                                  Paid
                                </div>
                              </div>
                            )}
                            {isDefined(bill.dueDate) &&
                              (category === BillCategory.Unpaid &&
                              patient.autoPayEnabled &&
                              bill.paymentRequestTargets.length > 0 ? (
                                <AutopayBadge />
                              ) : (
                                <DueDate
                                  isPastPaidBill={
                                    category === BillCategory.Paid
                                  }
                                  dueDate={bill.dueDate}
                                />
                              ))}
                            {billedToInsurance && (
                              <div>
                                <div className="flex items-center gap-1 text-xs text-indigo-700 p-1.5 rounded-lg bg-indigo-100">
                                  Insurance Applied
                                  <ShieldCheckIcon className="h-4 w-4 text-indigo-700" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="pl-0 sm:pl-2">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform text-gray-500"
                            )}
                            aria-hidden="true"
                          />
                        </div>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel
                      as="dd"
                      className="pb-6 px-4 md:px-8 mt-3"
                    >
                      <BillDetail
                        billId={bill.id}
                        patient={patient}
                        showDownload={showDownload}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const PastBillsList: React.FC<
  React.PropsWithChildren<{ patient: Patient }>
> = ({ patient }) => {
  const { data, loading } = useQuery<
    GetPortalPatientPaidBills,
    GetPortalPatientPaidBillsVariables
  >(GET_PAID_BILLS, {
    variables: { id: patient.id },
    onError: (error) => {
      handleAuthorizationError(error, `/portal/${patient.organization.id}`);
    },
  });

  if (loading) return <div>Loading...</div>;

  return (
    <BillList
      title="Past paid visits"
      category={BillCategory.Paid}
      bills={data?.getPortalPatientPaidBills ?? []}
      patient={patient}
      showDownload={true}
    />
  );
};

const PatientZeroBalanceView: React.FC<React.PropsWithChildren<{}>> = ({}) => {
  return (
    <div className="pb-4">
      <div className="flex justify-center">
        <CheckCircleIcon className="text-green-500 w-8 h-8"></CheckCircleIcon>
      </div>
      <span className="text-xl flex justify-center text-gray-800 md:mt-1">
        All set
      </span>
      <span className="text-gray-500 flex justify-center text-xs mt-3 px-4 text-center">
        You have no balance due at this time. We will notify you when new bills
        are ready.
      </span>
    </div>
  );
};

const MobilePaymentsLayout: React.FC<
  React.PropsWithChildren<{
    billingTab: React.ReactNode;
    myAccountTab: React.ReactNode;
  }>
> = ({ billingTab, myAccountTab }) => {
  return (
    <div className="w-full max-w-md px-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-slate-200 p-1">
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-indigo-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-indigo-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-indigo-400 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            Billing
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-indigo-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-indigo-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-indigo-400 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            My Account
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel>{billingTab}</Tab.Panel>
          <Tab.Panel>{myAccountTab}</Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

const GET_PATIENT_PAYMENTS = gql`
  query GetPatientPayments($id: String!, $take: Int, $skip: Int) {
    getPatientPayments(id: $id, take: $take, skip: $skip) {
      payment {
        id
        patientAmount
        isPledgeRefund
        transaction {
          id
          transactedAt
          paymentAllocations {
            id
            amount
            createdAt
            chargeTransaction {
              id
              transactedAt
              description
              charge {
                id
                customCode
                bill {
                  id
                  dateOfServiceDisplay
                  billCode
                  status
                }
              }
            }
          }
          billPayments {
            id
            createdAt
            amount
            bill {
              id
              billCode
              dateOfServiceDisplay
              primaryProvider {
                id
                displayName
              }
            }
          }
        }
      }
      paymentIntent {
        id
        createdAt
        amount
        autoPay
        receiptCode
        payments {
          id
          patientAmount
          isPledgeRefund
          transaction {
            id
            transactedAt
            paymentAllocations {
              id
              amount
              createdAt
              chargeTransaction {
                id
                transactedAt
                description
                charge {
                  id
                  customCode
                  bill {
                    id
                    dateOfServiceDisplay
                    billCode
                    status
                  }
                }
              }
            }
            billPayments {
              id
              createdAt
              amount
              bill {
                id
                billCode
                dateOfServiceDisplay
                primaryProvider {
                  id
                  displayName
                }
              }
            }
          }
        }
        paymentIntentRefunds(where: { status: { equals: "succeeded" } }) {
          id
          amount
          createdAt
        }
      }
      transactedAt
    }
  }
`;

const PATIENT_PAYMENTS_COUNT = gql`
  query GetPatientPaymentsCount($id: String!) {
    getPatientPaymentsCount(id: $id)
  }
`;

const PaymentHistoryDialog: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ patient, open, setOpen }) => {
  const analytics = useAnalytics();
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const { data, loading } = useQuery<
    GetPatientPayments,
    GetPatientPaymentsVariables
  >(GET_PATIENT_PAYMENTS, {
    variables: { id: patient.id, take: pageSize, skip: page * pageSize },
  });
  const patientPaymentsCountResult = useQuery<
    GetPatientPaymentsCount,
    GetPatientPaymentsCountVariables
  >(PATIENT_PAYMENTS_COUNT, {
    variables: { id: patient.id },
  });
  const total = patientPaymentsCountResult?.data?.getPatientPaymentsCount ?? 0;

  const sendReceiptPdfDownloadEvent = (paymentIntentId: string) => {
    analytics?.track("Receipt PDF Downloaded", {
      paymentIntentId,
      organizationId: patient.organization.id,
      organizationName: patient.organization.name,
      locationId: patient.location.id,
      locationName: patient.location.name,
    });
  };

  const payments = new Map();
  const paymentsPage = data?.getPatientPayments ?? [];
  for (const row of paymentsPage) {
    payments.set(row?.payment?.id ?? row?.paymentIntent?.id, row);
  }
  const orderedPayments: PatientPayment[] = Array.from(payments.values()).sort(
    (a, b) => b.transactedAt
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="inline-block w-full max-w-xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="mt-4 flex justify-between px-6 lg:px-8">
                  <h2 className="font-semibold">Payment History</h2>
                  <button
                    type="button"
                    className="text-gray-500 hover:text-gray-300"
                    onClick={() => setOpen(false)}
                  >
                    <XIcon className="h-6 w-6" />
                  </button>
                </div>
                <div>
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                          >
                            Amount
                          </th>
                          <th scope="col" className="w-4 relative">
                            <span className="sr-only">Download Receipt</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {loading
                          ? Array.from({ length: 10 }, (_, index) => (
                              <tr key={index}>
                                <td colSpan={3} className="py-2">
                                  <div className="h-9 animate-pulse rounded-md bg-slate-100"></div>
                                </td>
                              </tr>
                            ))
                          : orderedPayments.map(
                              ({ payment, paymentIntent, transactedAt }) => {
                                const hasRefunds =
                                  paymentIntent &&
                                  paymentIntent.paymentIntentRefunds.length > 0;
                                const refundAmount = (
                                  paymentIntent?.paymentIntentRefunds ?? []
                                ).reduce(
                                  (acc, refund) => acc + refund.amount,
                                  0
                                );
                                const amount =
                                  payment?.patientAmount ??
                                  paymentIntent?.amount ??
                                  0;

                                const id = payment?.id ?? paymentIntent?.id;
                                return (
                                  <tr key={id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                      {formatDateMMDDYYYY(transactedAt)}
                                    </td>
                                    <td className="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-500">
                                      {formatUSD(amount)}
                                      {hasRefunds && (
                                        <Tooltip
                                          content={
                                            <div className="text-sm">
                                              Refunded {formatUSD(refundAmount)}
                                            </div>
                                          }
                                          trigger={
                                            <span className="text-green-500 text-sm ml-1">
                                              ({formatUSD(refundAmount)})
                                            </span>
                                          }
                                        />
                                      )}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {paymentIntent ? (
                                        <Link
                                          to={`/portal/${patient.organization.id}/${patient.id}/receipt/${paymentIntent.id}`}
                                          target="_blank"
                                        >
                                          <>
                                            <ExternalLinkIcon className="h-4 w-4" />
                                          </>
                                        </Link>
                                      ) : (
                                        <Tooltip
                                          content={
                                            <>Contact clinic for receipt</>
                                          }
                                          trigger={
                                            <ExternalLinkIcon className="h-4 w-4 opacity-50" />
                                          }
                                        />
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <nav
                  className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Showing{" "}
                      <span className="font-medium">{page * pageSize}</span> to{" "}
                      <span className="font-medium">
                        {Math.min((page + 1) * pageSize, total)}
                      </span>{" "}
                      of <span className="font-medium">{total}</span> results
                    </p>
                  </div>
                  <div className="flex flex-1 justify-between sm:justify-end">
                    <button
                      disabled={page === 0}
                      onClick={() => {
                        if (page > 0) {
                          setPage(page - 1);
                        }
                      }}
                      className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50"
                    >
                      Previous
                    </button>
                    <button
                      disabled={page >= Math.floor(total / pageSize)}
                      onClick={() => {
                        if (page < Math.floor(total / pageSize)) {
                          setPage(page + 1);
                        }
                      }}
                      className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50"
                    >
                      Next
                    </button>
                  </div>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const PaymentHistoryDialogButton: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
  }>
> = ({ patient }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button>
        <button
          className="text-sm text-indigo-500 hover:text-indigo-700"
          onClick={() => {
            setOpen(true);
          }}
        >
          View all
        </button>
      </button>
      {open && (
        <PaymentHistoryDialog patient={patient} open={open} setOpen={setOpen} />
      )}
    </>
  );
};

const MyAccountCards: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
  }>
> = ({ patient }) => {
  const analytics = useAnalytics();
  const location = useLocation();
  const sortOrder = [
    CoveragePriority.PRIMARY,
    CoveragePriority.SECONDARY,
    CoveragePriority.TERTIARY,
    null,
  ];

  const insurancePolicies = [...patient.insurancePolicies].sort(
    (a, b) => sortOrder.indexOf(a.priority) - sortOrder.indexOf(b.priority)
  );

  const sendReceiptPdfDownloadEvent = (paymentIntentId: string) => {
    analytics?.track("Receipt PDF Downloaded", {
      paymentIntentId,
      organizationId: patient.organization.id,
      organizationName: patient.organization.name,
      locationId: patient.location.id,
      locationName: patient.location.name,
    });
  };

  const nextAutoPayRequest = patient.nextAutopayPaymentRequest?.at(0);

  const payments = new Map();
  const paymentsPage = patient.patientPayments ?? [];
  for (const row of paymentsPage) {
    payments.set(row?.payment?.id ?? row?.paymentIntent?.id, row);
  }
  const orderedPayments: PatientPayment[] = Array.from(payments.values()).sort(
    (a, b) => b.transactedAt
  );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 text-sm gap-2 pt-2 pb-2 sm:pb-0">
      <Card>
        <div className="flex flex-col">
          <h2 className="font-semibold text-md">Billing Details</h2>
          <div className="flex gap-1 items-center">
            <IdentificationIcon className="h-4 w-4" />
            {patient.firstName} {patient.lastName}
          </div>
          <div className="flex gap-1 items-center">
            <MailIcon className="h-4 w-4" />
            {patient.email}
          </div>
          <div className="flex gap-1 items-center">
            <PhoneIcon className="h-4 w-4" />
            {patient.cellPhone}
          </div>
          {patient.location && (
            <div className="flex gap-1 items-center">
              <LocationMarkerIcon className="h-4 w-4" />
              {patient.location.name}
            </div>
          )}
        </div>
      </Card>
      <Card>
        <div className="flex flex-col w-full">
          <h2 className="flex justify-between items-center">
            <div className="font-semibold text-md">Payment Methods</div>
            <Link
              to={`${location.pathname}/settings`}
              className="text-sm text-indigo-500 hover:text-indigo-700"
            >
              Change
            </Link>
          </h2>
          {patient.defaultPaymentMethod ? (
            <>
              <div className="flex gap-1 items-center">
                <CreditCardIcon className="h-4 w-4" />
                {paymentMethodDisplay(patient.defaultPaymentMethod)}
              </div>
              {patient.defaultPaymentMethod.type !== "link" && (
                <div className="flex gap-1 items-center">
                  Expires:{" "}
                  {patient.defaultPaymentMethod.expirationMonth
                    ?.toString()
                    .padStart(2, "0")}
                  /{patient.defaultPaymentMethod.expirationYear}
                </div>
              )}
              <div className="flex gap-1 items-center">
                Autopay:
                {patient.enrolledInAutopay ? (
                  <span className="text-green-500">On</span>
                ) : (
                  <span className="text-red-500">Off</span>
                )}
              </div>
              {nextAutoPayRequest && nextAutoPayRequest.scheduledAt && (
                <div>
                  Next Autopay Scheduled:{" "}
                  {nextAutoPayRequest.amount && (
                    <>{formatUSD(nextAutoPayRequest.amount)} on </>
                  )}
                  {formatDateMMDDYYYY(nextAutoPayRequest.scheduledAt)}
                </div>
              )}
            </>
          ) : (
            <>
              <div className="flex gap-1 items-center">
                <CreditCardIcon className="h-4 w-4" />
                No saved payment methods
              </div>
              <Link
                to={`${location.pathname}/settings`}
                className="text-sm text-indigo-500 hover:text-indigo-700"
              >
                +Add a payment method
              </Link>
            </>
          )}
        </div>
      </Card>
      {insurancePolicies.length > 0 && (
        <Card>
          <div className="flex flex-col gap-1">
            <h2 className="font-semibold text-md">Active Insurance</h2>
            <ul>
              {insurancePolicies.map((policy) => (
                <li key={policy.id}>
                  {policy.payer.name} - {policy.memberId}
                </li>
              ))}
            </ul>
          </div>
        </Card>
      )}
      <Card>
        <div className="flex flex-col gap-1 w-full">
          <div className="flex justify-between items-center">
            <h2 className="font-semibold text-md ">Recent Payments</h2>
            {orderedPayments.length > 0 && (
              <PaymentHistoryDialogButton patient={patient} />
            )}
          </div>
          {orderedPayments.length === 0 ? (
            <div className="text-gray-700 py-2 border-t border-gray-200">
              No recent payments
            </div>
          ) : (
            <table className="min-w-full divide-y border-y border-gray-200 divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="pl-2 p-1 text-left text-sm font-semibold text-gray-900"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="p-1 text-right text-sm font-semibold text-gray-900"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="w-4 relative py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">Download Receipt</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {orderedPayments.map(
                  ({ payment, paymentIntent, transactedAt }) => {
                    const hasRefunds =
                      paymentIntent &&
                      paymentIntent.paymentIntentRefunds.length > 0;
                    const refundAmount = (
                      paymentIntent?.paymentIntentRefunds ?? []
                    ).reduce((acc, refund) => acc + refund.amount, 0);
                    const amount =
                      payment?.patientAmount ?? paymentIntent?.amount ?? 0;
                    const id = payment?.id ?? paymentIntent?.id;
                    return (
                      <tr key={id}>
                        <td className="whitespace-nowrap pl-2 p-1 text-sm text-gray-900">
                          {formatDateMMDDYYYY(transactedAt)}
                        </td>
                        <td className="whitespace-nowrap text-right p-1 text-sm text-gray-900">
                          {formatUSD(amount)}
                          {hasRefunds && (
                            <Tooltip
                              content={
                                <div className="text-sm">
                                  Refunded {formatUSD(refundAmount)}
                                </div>
                              }
                              trigger={
                                <span className="text-green-500 text-sm ml-1">
                                  ({formatUSD(refundAmount)})
                                </span>
                              }
                            />
                          )}
                        </td>
                        <td className="whitespace-nowrap p-1 text-sm text-gray-900">
                          {paymentIntent ? (
                            <Link
                              to={`/portal/${patient.organization.id}/${patient.id}/receipt/${paymentIntent.id}`}
                              target="_blank"
                            >
                              <>
                                <ExternalLinkIcon className="h-4 w-4" />
                              </>
                            </Link>
                          ) : (
                            <Tooltip
                              content={<>Contact clinic for receipt</>}
                              trigger={
                                <ExternalLinkIcon className="h-4 w-4 opacity-50" />
                              }
                            />
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          )}
        </div>
      </Card>
      {patient.organizationPatientGroup?.patients?.length && (
        <Card>
          <div className="flex flex-col gap-1 w-full divide-y">
            <h2 className="flex justify-between font-semibold text-md">
              <div>Linked Accounts</div>
              <div className="text-gray-500 font-light">Balance</div>
            </h2>
            {patient.organizationPatientGroup?.patients.map((p) => (
              <div key={patient.id} className="flex justify-between pt-2">
                <div className="flex gap-2 items-center">
                  {p.firstName} {p.lastName} ({p.location.name})
                  <Link
                    to={`/portal/${patient.organization.id}/${p.id}`}
                    target="_blank"
                  >
                    <ExternalLinkIcon className="h-4 w-4 text-gray-500" />
                  </Link>
                </div>
                <div>{formatUSD(-p.patientReadyBalance)}</div>
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>
  );
};

const BalancePaymentCard: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    setOpenPaymentModal: (mode: "fullPayment" | "partialPayment") => void;
  }>
> = ({ patient, setOpenPaymentModal }) => {
  return (
    <div className="col-span-4 md:col-span-1 flex border border-gray-300 bg-white rounded-md">
      <div className="w-full">
        <div className="grid grid-cols-2 text-sm px-5 pt-5 md:pb-5">
          <span className="col-span-1 justify-start text-gray-600">
            Total Due
          </span>
          <span className="col-span-1 justify-self-end text-lg">
            {formatUSD(-patient.patientStatementBalance.readyBalance)}
          </span>
        </div>
        {-patient.patientStatementBalance.readyBalance > 0 ? (
          <div className="py-2">
            <div className="mx-4 mt-5">
              <button
                onClick={() => {
                  setOpenPaymentModal("fullPayment");
                }}
                className="w-full flex justify-center p-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Pay total:{" "}
                {formatUSD(-patient.patientStatementBalance.readyBalance)}
              </button>
            </div>
            <div className="mt-4 text-sm">
              <button
                onClick={() => {
                  setOpenPaymentModal("partialPayment");
                }}
                className="w-full text-indigo-500 flex justify-center py-1 hover:underline"
              >
                Pay a partial amount
              </button>
            </div>
          </div>
        ) : (
          <PatientZeroBalanceView />
        )}
        <div>
          <div className="w-full flex justify-center text-xs text-gray-400">
            <ShieldCheckIcon className="h-4 w-4 my-2 ml-2 mr-1"></ShieldCheckIcon>{" "}
            <span className="my-2">Secure payments</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const BillListCards: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    unpaidBills: BillSummary[];
    inReviewBills: BillSummary[];
  }>
> = ({ patient, unpaidBills, inReviewBills }) => {
  return (
    <>
      <div className="pb-4 md:pb-10">
        <div className="pt-4">
          <BillList
            title="Visits ready to pay"
            category={BillCategory.Unpaid}
            bills={unpaidBills}
            totalAmount={-patient.patientStatementBalance.readyBalance}
            patient={patient}
            showDownload={true}
          />
        </div>
        <div className="my-10"></div>
        <BillList
          title="Pending visits"
          category={BillCategory.Upcoming}
          bills={inReviewBills}
          totalAmount={-patient.patientInReviewBalance}
          patient={patient}
          showDownload={false}
        />
        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button className="w-full">
                <span className="my-10 inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  {open ? "Hide" : "Show"} past paid visits
                </span>
              </Disclosure.Button>
              <Disclosure.Panel as="dd">
                {/* Delays loading past bills until expanded */}
                <PastBillsList patient={patient} />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};

const Payments: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    unpaidBills: BillSummary[];
    inReviewBills: BillSummary[];
  }>
> = ({ patient, unpaidBills, inReviewBills }) => {
  const analytics = useAnalytics();
  const { medium } = useUtmParams();
  const [openPaymentModal, setOpenPaymentModal] = useState<
    "fullPayment" | "partialPayment" | null
  >(null);
  useEffect(() => {
    analytics?.track("Patient Portal Viewed", {
      loggedIn: true,
      referralMedium: medium,
      patientId: patient.id,
      organizationId: patient.organization.id,
      organizationName: patient.organization.name,
      locationId: patient.location.id,
      locationName: patient.location.name,
    });
  }, []);

  const contactEmail =
    patient.location.primaryEmail ?? patient.organization.primaryEmail;

  const placeDisplayName =
    patient.location.locationDisplayName ??
    patient.location.name ??
    patient.organization.name;

  return (
    <div className="text-gray-900 flex flex-col">
      {patient.patientStatementBalance &&
      -patient.patientStatementBalance.readyBalance > 0 ? (
        <header className="sticky top-0 md:bg-slate-100 pb-3 rounded-b-lg">
          <div className="md:flex md:justify-end md:px-10">
            <button
              onClick={() => {
                setOpenPaymentModal("partialPayment");
              }}
              className="hidden md:visible w-full md:w-auto text-indigo-500 md:flex justify-center py-1 hover:underline md:mt-4 md:mx-6"
            >
              Pay a partial amount
            </button>
            <button
              onClick={() => {
                setOpenPaymentModal("fullPayment");
              }}
              className="hidden md:visible md:mt-3 md:flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Pay total:{" "}
              {formatUSD(
                -(patient.patientStatementBalance
                  ? patient.patientStatementBalance.readyBalance
                  : 0)
              )}
            </button>
          </div>
        </header>
      ) : null}
      {/* Mobile Layout */}
      <main className="sm:hidden px-1 lg:mx-20 lg:my-4">
        <div className="col-span-4 sm:col-span-3">
          <div className="py-4">
            <h1 className="text-2xl flex justify-center sm:justify-start">
              Hi, {patient.firstName}
            </h1>
            {/* TODO:  Maintain primary email for each org and add that here */}
            <div className="flex justify-center sm:justify-start">
              <p className="text-center sm:text-left">
                Your bills are ready and a payment is due.{" "}
                <span className="font-semibold">
                  Note that payments get applied to the oldest balance first.
                </span>{" "}
                Please{" "}
                {isDefined(contactEmail) ? (
                  <a
                    href={`mailto:${contactEmail}?subject=${encodeURIComponent(
                      `${placeDisplayName} - Contact Us (Patient portal)`
                    )}`}
                    className="text-indigo-500 underline"
                  >
                    contact
                  </a>
                ) : (
                  "contact"
                )}{" "}
                us if you have any questions.
              </p>
            </div>
          </div>
          <MobilePaymentsLayout
            billingTab={
              <div>
                <BalancePaymentCard
                  patient={patient}
                  setOpenPaymentModal={setOpenPaymentModal}
                />
                <BillListCards
                  patient={patient}
                  unpaidBills={unpaidBills}
                  inReviewBills={inReviewBills}
                />
              </div>
            }
            myAccountTab={<MyAccountCards patient={patient} />}
          />
        </div>
      </main>
      {/* Desktop Layout */}
      <main className="hidden sm:block px-1 lg:mx-20 lg:my-4">
        <div className="grid grid-cols-4 gap-8">
          <div className="col-span-4 sm:col-span-3">
            <h1 className="text-2xl flex justify-center sm:justify-start">
              Hi, {patient.firstName}
            </h1>
            {/* TODO:  Maintain primary email for each org and add that here */}
            <div className="flex justify-center sm:justify-start">
              <p className="text-center sm:text-left">
                Your bills are ready and a payment is due.{" "}
                <span className="font-semibold">
                  Note that payments get applied to the oldest balance first.
                </span>{" "}
                Please{" "}
                {isDefined(contactEmail) ? (
                  <a
                    href={`mailto:${contactEmail}?subject=${encodeURIComponent(
                      `${placeDisplayName} - Contact Us (Patient portal)`
                    )}`}
                    className="text-indigo-500 underline"
                  >
                    contact
                  </a>
                ) : (
                  "contact"
                )}{" "}
                us if you have any questions.
              </p>
            </div>
            <MyAccountCards patient={patient} />
          </div>
          <BalancePaymentCard
            patient={patient}
            setOpenPaymentModal={setOpenPaymentModal}
          />
        </div>
        <BillListCards
          patient={patient}
          unpaidBills={unpaidBills}
          inReviewBills={inReviewBills}
        />
      </main>
      <Transition.Root show={!!openPaymentModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="inline-block w-full max-w-xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="flex flex-col py-4 px-2 sm:px-8 border border-gray-200 rounded-lg">
                    <div className="flex justify-end pb-2">
                      <button
                        type="button"
                        className="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => {
                          setOpenPaymentModal(null);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <PartialOrFullPaymentForm
                      patientId={patient.id}
                      organizationName={patient.organization.name}
                      patientReadyBalance={
                        // Convert to positive amount
                        -patient.patientStatementBalance.readyBalance
                      }
                      paymentMethods={patient.paymentMethods}
                      askToSavePaymentMethod={true}
                      // If autopay is enabled, don't ask to enroll in autopay
                      askToEnrollInAutopay={!patient.enrolledInAutopay}
                      defaultPaymentMode={openPaymentModal ?? undefined}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export const PatientPayments: React.FC<
  React.PropsWithChildren<{ patientId: string }>
> = ({ patientId }) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, loading } = useQuery<
    GetPortalPatientBills,
    GetPortalPatientBillsVariables
  >(GET_READY_BILLS, {
    variables: { id: patientId },
    onError: (error) => {
      handleAuthorizationError(error, `/portal/${organizationId}/${patientId}`);
    },
  });

  if (loading || !data)
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );
  // TODO: Handle data.getPatientBills = null
  return (
    <Payments
      patient={data.getPortalPatientBills.patient!}
      unpaidBills={data.getPortalPatientBills.unpaidBills}
      inReviewBills={data.getPortalPatientBills.inReviewBills}
    />
  );
};

const CURRENT_SESSION = gql`
  query CurrentSession {
    currentSession {
      id
      user {
        __typename
        ... on PatientUser {
          id
        }
        ... on User {
          id
        }
      }
      expiresAt
    }
  }
`;

export const GET_PUBLIC_PATIENT_BILLING_DATA = gql`
  query GetPublicPatientBillingData($id: String!) {
    getPublicPatientBillingData(id: $id) {
      id
      supercededById
      organizationId
      firstName
      organizationLogoUrl
      organizationEmail
      organizationName
      locationId
      locationEmail
      locationName
      locationDisplayName
      maskedEmail
      maskedCellPhone
      communicationPreferences
      patientReadyBalance
      patientUserId
    }
  }
`;

const validatePaymentAmount = ({
  value,
  patientReadyBalance,
}: {
  value: string | undefined;
  patientReadyBalance: number;
}) => {
  if (value === undefined) {
    return {
      error: "Invalid Amount",
      number: 0,
    };
  } else {
    const number = Number.parseFloat(value);
    if (Number.isNaN(number) || number < 0) {
      return {
        error: "Invalid Amount",
        number: 0,
      };
      // Minimum payment amount is $1
    } else if (number * 100 <= 100) {
      return {
        error: "Payment amount must be greater than $1",
        number: 0,
      };
      // If number in cents is greater than the balance
    } else if (number * 100 > patientReadyBalance) {
      return {
        error: "Can't pay more than your statement balance",
        number: 0,
      };
    } else {
      return {
        error: null,
        number,
      };
    }
  }
};

const CHARGE_AMOUNT_TO_PATIENT_PAYMENT_METHOD = gql`
  mutation ChargeAmountToPatientPaymentMethod(
    $patientId: String!
    $paymentMethodId: String!
    $amount: Int!
  ) {
    chargeAmountToPatientPaymentMethod(
      patientId: $patientId
      paymentMethodId: $paymentMethodId
      amount: $amount
    ) {
      paymentIntentId
      errors {
        message
      }
    }
  }
`;

const CHARGE_BILL_AMOUNT_TO_PATIENT_PAYMENT_METHOD = gql`
  mutation ChargeBillAmountToPatientPaymentMethod(
    $patientId: String!
    $billId: String!
    $paymentMethodId: String!
    $amount: Int!
    $enrollInAutopay: Boolean
  ) {
    chargeBillAmountToPatientPaymentMethod(
      patientId: $patientId
      billId: $billId
      paymentMethodId: $paymentMethodId
      amount: $amount
      enrollInAutopay: $enrollInAutopay
    ) {
      paymentIntentId
      errors {
        message
      }
    }
  }
`;

const CHARGE_BILL_AMOUNTS_TO_PATIENT_PAYMENT_METHOD = gql`
  mutation ChargeBillAmountsToPatientPaymentMethod(
    $patientId: String!
    $billIds: [String!]!
    $paymentMethodId: String!
    $amount: Int!
    $enrollInAutopay: Boolean
    $estimated: Boolean
  ) {
    chargeBillAmountsToPatientPaymentMethod(
      patientId: $patientId
      billIds: $billIds
      paymentMethodId: $paymentMethodId
      amount: $amount
      enrollInAutopay: $enrollInAutopay
      estimated: $estimated
    ) {
      paymentIntentId
      errors {
        message
      }
    }
  }
`;

export const PartialOrFullPaymentForm: React.FC<
  React.PropsWithChildren<{
    patientReadyBalance: number;
    patientId: string;
    organizationName: string;
    paymentMethods: PaymentMethod[];
    askToSavePaymentMethod: boolean;
    askToEnrollInAutopay: boolean;
    defaultPaymentMode?: "fullPayment" | "partialPayment";
    billIds?: string[];
    estimated?: boolean;
  }>
> = ({
  patientReadyBalance,
  patientId,
  organizationName,
  paymentMethods,
  askToSavePaymentMethod,
  askToEnrollInAutopay,
  defaultPaymentMode = "fullPayment",
  billIds = undefined,
  estimated = false,
}) => {
  const apollo = useApolloClient();
  const analytics = useAnalytics();
  const defaultPaymentMethod =
    paymentMethods.find((pm) => pm.default) ?? paymentMethods[0];
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(defaultPaymentMethod ?? null);
  const [paymentAmount, setPaymentAmount] =
    useState<number>(patientReadyBalance);
  const [paymentPartialAmount, setPaymentPartialAmount] =
    useState<number>(patientReadyBalance);
  const [paymentMode, setPaymentMode] = useState<
    "fullPayment" | "partialPayment"
  >(defaultPaymentMode);
  const [autopayEnrollment, setAutopayEnrollment] = useState(false);
  const [amountError, setAmountError] = useState<string | null>(null);
  // Hide the payment form by default if there are any saved payment methods
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showStripeForm, setShowStripeForm] = useState(
    paymentMethods.length === 0
  );
  const [addPaymentMethodDialogOpen, setAddPaymentMethodDialogOpen] =
    useState(false);

  const [
    chargeBillAmountsToPatientPaymentMethod,
    chargeBillAmountsToPatientPaymentMethodResult,
  ] = useMutation<
    ChargeBillAmountsToPatientPaymentMethod,
    ChargeBillAmountsToPatientPaymentMethodVariables
  >(CHARGE_BILL_AMOUNTS_TO_PATIENT_PAYMENT_METHOD);
  const [
    chargeAmountToPatientPaymentMethod,
    chargeAmountToPatientPaymentMethodResult,
  ] = useMutation<
    ChargeAmountToPatientPaymentMethod,
    ChargeAmountToPatientPaymentMethodVariables
  >(CHARGE_AMOUNT_TO_PATIENT_PAYMENT_METHOD);

  const setToFullPayment = () => {
    setPaymentAmount(patientReadyBalance);
    setPaymentMode("fullPayment");
  };

  const setToPartialPayment = () => {
    setPaymentAmount(paymentPartialAmount);
    setPaymentMode("partialPayment");
  };

  const changePaymentMode = (mode: "fullPayment" | "partialPayment") => {
    if (mode === "fullPayment") {
      setToFullPayment();
    } else {
      setToPartialPayment();
    }
  };

  const onContinue = () => {
    setShowPaymentForm(true);
  };

  const initialPaymentDisplay = React.useMemo(
    () => (patientReadyBalance / 100).toFixed(2).toString(),
    [patientReadyBalance]
  );

  const validateAndSetPaymentAmount = (value: string | undefined) => {
    const { error, number } = validatePaymentAmount({
      value,
      patientReadyBalance,
    });
    setAmountError(error);
    setPaymentAmount(toCents(number));
    setPaymentPartialAmount(toCents(number));
  };

  const modes = ["fullPayment", "partialPayment"];

  // If the payment amount selection is valid to continue
  const valid = paymentMode === "fullPayment" || amountError === null;

  return (
    <div className="flex flex-col">
      <div className={classNames(showPaymentForm ? "hidden" : "")}>
        <div className="flex justify-between mb-2">
          <span className="text-xl">Total Due</span>
          <span className="text-2xl font-semibold">
            {formatUSD(patientReadyBalance)}
          </span>
        </div>
        <div>
          <div className="flex flex-col">
            <RadioGroup value={paymentMode} onChange={changePaymentMode}>
              <RadioGroup.Label className="sr-only">
                {" "}
                Privacy setting{" "}
              </RadioGroup.Label>
              <div className="-space-y-px rounded-md bg-white">
                <RadioGroup.Option
                  value={"fullPayment"}
                  className={({ checked }) =>
                    classNames(
                      checked
                        ? "bg-indigo-50 border-indigo-200 z-10"
                        : "border-gray-200",
                      "min-h relative border p-4 flex cursor-pointer focus:outline-none rounded-tl-md rounded-tr-md"
                    )
                  }
                >
                  {({ active, checked }) => (
                    <div className="flex items-center w-full min-h-[4em]">
                      <span
                        className={classNames(
                          checked
                            ? "bg-indigo-600 border-transparent"
                            : "bg-white border-gray-300",
                          active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                          "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <span className="ml-3 flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className={classNames(
                            checked ? "text-indigo-900" : "text-gray-900",
                            "block text-md font-medium"
                          )}
                        >
                          Pay full balance of{" "}
                          <span className="font-semibold">
                            {formatUSD(patientReadyBalance)}
                          </span>
                        </RadioGroup.Label>
                      </span>
                    </div>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  value={"partialPayment"}
                  className={({ checked }) =>
                    classNames(
                      checked
                        ? "bg-indigo-50 border-indigo-200 z-10"
                        : "border-gray-200",
                      "relative border p-4 flex cursor-pointer focus:outline-none rounded-bl-md rounded-br-md"
                    )
                  }
                >
                  {({ active, checked }) => (
                    <div className="flex items-center w-full min-h-[4em]">
                      <span
                        className={classNames(
                          checked
                            ? "bg-indigo-600 border-transparent"
                            : "bg-white border-gray-300",
                          active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                          "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <span className="ml-3 flex flex-col w-full">
                        <RadioGroup.Label
                          as="div"
                          className={classNames(
                            checked ? "text-indigo-900" : "text-gray-900",
                            "flex justify-between items-center text-md font-medium"
                          )}
                        >
                          Pay a partial amount
                          <div className="mt-1 relative rounded-md border max-w-[9em]">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-500 sm:text-md">
                                $
                              </span>
                            </div>
                            <CurrencyInput
                              id="partialAmount"
                              name="partialAmount"
                              placeholder="0.00"
                              defaultValue={initialPaymentDisplay}
                              decimalsLimit={2}
                              onValueChange={validateAndSetPaymentAmount}
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 py-1 sm:text-sm border-gray-300 rounded-md"
                            />
                            {paymentMode === "partialPayment" && amountError && (
                              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ExclamationCircleIcon
                                  className="h-5 w-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                        </RadioGroup.Label>
                      </span>
                    </div>
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>

            {paymentMode === "partialPayment" && amountError && (
              <div>
                <p
                  className="mt-2 text-sm text-red-600 text-right"
                  id="email-error"
                >
                  {amountError}
                </p>
              </div>
            )}
            <div className="mt-6">
              <SubmitButton onClick={onContinue} disabled={!valid}>
                Continue to Payment
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
      {showPaymentForm && (
        <div>
          <div className="flex justify-between items-center">
            <button
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-indigo-500 disabled:bg-gray-200 disabled:cursor-not-allowed"
              onClick={() => setShowPaymentForm(false)}
            >
              <span aria-hidden="true" className="pr-2">
                &larr;
              </span>
              Back to Payment Amount
            </button>
            <span className="text-2xl font-semibold">
              {formatUSD(paymentAmount)}
            </span>
          </div>
          <div className="pt-8 pb-4 flex flex-col gap-2">
            <div className="flex justify-between items-center">
              <h1 className="text-lg font-medium">Payment</h1>
              {/* No need to show button to togggle between card form and saved payment methods if there are no saved payment methods */}
              {paymentMethods.length > 0 && (
                <button
                  className="inline-flex justify-center items-center rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 disabled:cursor-not-allowed"
                  onClick={() => {
                    setShowStripeForm(!showStripeForm);
                  }}
                >
                  {showStripeForm ? "Back" : "+ Add New"}
                </button>
              )}
            </div>
            {showStripeForm || !selectedPaymentMethod ? (
              <StripeCheckout
                patientId={patientId}
                organizationName={organizationName}
                amount={paymentAmount}
                returnTo={window.location.href}
                askToSavePaymentMethod={askToSavePaymentMethod}
                askToEnrollInAutopay={askToEnrollInAutopay}
                billIds={billIds ?? null}
                estimated={estimated ?? false}
              />
            ) : (
              <>
                <RadioGroup
                  value={selectedPaymentMethod}
                  onChange={setSelectedPaymentMethod}
                >
                  <div className="mt-4 grid grid-cols-1 gap-y-4">
                    {paymentMethods.map((paymentMethod) => (
                      <RadioGroup.Option
                        key={paymentMethod.id}
                        value={paymentMethod}
                        className={({ checked, active }) =>
                          classNames(
                            checked ? "border-transparent" : "border-gray-300",
                            active
                              ? "border-indigo-500 ring-2 ring-indigo-500"
                              : "",
                            "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                          )
                        }
                      >
                        {({ checked, active }) => (
                          <>
                            <span className="flex flex-1">
                              <span className="flex flex-col">
                                <div className="flex flex-col">
                                  <div className="font-medium">
                                    <div className="flex items-center gap-1">
                                      <CardComponent
                                        cardBrand={paymentMethod.cardBrand}
                                        type={paymentMethod.type}
                                        className="h-4"
                                      />
                                      <span>
                                        {paymentMethodDisplay(paymentMethod)}
                                      </span>
                                      {paymentMethod.default && (
                                        <div>
                                          <Badge
                                            variant="info"
                                            text="Default"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {paymentMethod.type !== "link" && (
                                    <div className="text-gray-600">
                                      Expires {paymentMethod.expirationMonth}/
                                      {paymentMethod.expirationYear}
                                    </div>
                                  )}
                                </div>
                              </span>
                            </span>
                            <div>
                              <CheckCircleIcon
                                className={classNames(
                                  !checked ? "invisible" : "",
                                  "h-5 w-5 text-indigo-600"
                                )}
                                aria-hidden="true"
                              />
                              <span
                                className={classNames(
                                  active ? "border" : "border-2",
                                  checked
                                    ? "border-indigo-500"
                                    : "border-transparent",
                                  "pointer-events-none absolute -inset-px rounded-lg"
                                )}
                                aria-hidden="true"
                              />
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
                <Transition
                  as={Fragment}
                  show={askToEnrollInAutopay}
                  enter="transform transition duration-[400ms]"
                  enterFrom="opacity-0 -translate-y-6"
                  enterTo="opacity-100 translate-y-0"
                  leave="transform duration-200 transition ease-in-out"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 -translate-y-6"
                >
                  <div className="flex pt-2">
                    <div className="h-5">
                      <input
                        id="autopayEnrollment"
                        name="autopayEnrollment"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={() =>
                          setAutopayEnrollment(!autopayEnrollment)
                        }
                        defaultChecked={autopayEnrollment}
                      />
                    </div>
                    <div className="ml-2 text-sm font-light">
                      <label
                        htmlFor="autopayEnrollment"
                        className="text-gray-700"
                      >
                        <span className="font-medium">
                          Enroll in automatic payments
                        </span>{" "}
                        and allow {organizationName} to charge your card for
                        future outstanding bills instead of sending you an
                        invoice to pay.
                      </label>
                    </div>
                  </div>
                </Transition>
                <div className="pt-2">
                  <SubmitButton
                    type="button"
                    onClick={() => {
                      if (billIds) {
                        chargeBillAmountsToPatientPaymentMethod({
                          variables: {
                            patientId,
                            billIds,
                            paymentMethodId: selectedPaymentMethod.id,
                            amount: paymentAmount,
                            enrollInAutopay: askToEnrollInAutopay
                              ? autopayEnrollment
                              : undefined,
                          },
                          onCompleted: ({
                            chargeBillAmountsToPatientPaymentMethod,
                          }) => {
                            if (
                              chargeBillAmountsToPatientPaymentMethod.errors
                                .length === 0
                            ) {
                              const paymentIntentId =
                                chargeBillAmountsToPatientPaymentMethod.paymentIntentId;
                              analytics?.track(
                                "Patient Charged Patient Payment Method",
                                {
                                  patientId,
                                  billIds,
                                  paymentMethodId: selectedPaymentMethod.id,
                                  amount: paymentAmount,
                                }
                              );
                              const redirectUrl = new URL(window.location.href);

                              redirectUrl.searchParams.set(
                                "payment_intent",
                                paymentIntentId ?? ""
                              );

                              window.location.href = redirectUrl.toString();
                            } else {
                              for (const error of chargeBillAmountsToPatientPaymentMethod.errors) {
                                toast.error(error.message);
                              }
                            }
                          },
                          onError: (error) => {
                            toast.error("Error charging payment method");
                          },
                        });
                      } else {
                        chargeAmountToPatientPaymentMethod({
                          variables: {
                            patientId,
                            paymentMethodId: selectedPaymentMethod.id,
                            amount: paymentAmount,
                          },
                          onCompleted: ({
                            chargeAmountToPatientPaymentMethod,
                          }) => {
                            if (
                              chargeAmountToPatientPaymentMethod.errors
                                .length === 0
                            ) {
                              const paymentIntentId =
                                chargeAmountToPatientPaymentMethod.paymentIntentId;
                              analytics?.track(
                                "Patient Charged Patient Payment Method",
                                {
                                  patientId,
                                  paymentMethodId: selectedPaymentMethod.id,
                                  amount: paymentAmount,
                                }
                              );
                              const redirectUrl = new URL(window.location.href);

                              redirectUrl.searchParams.set(
                                "payment_intent",
                                paymentIntentId ?? ""
                              );

                              window.location.href = redirectUrl.toString();
                            } else {
                              for (const error of chargeAmountToPatientPaymentMethod.errors) {
                                toast.error(error.message);
                              }
                            }
                          },
                          onError: (error) => {
                            toast.error("Error charging payment method");
                          },
                        });
                      }
                    }}
                    loading={
                      chargeAmountToPatientPaymentMethodResult.loading ||
                      chargeBillAmountsToPatientPaymentMethodResult.loading
                    }
                  >
                    Charge {paymentMethodDisplay(selectedPaymentMethod!)}
                  </SubmitButton>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {addPaymentMethodDialogOpen && (
        <AddPaymentMethodDialog
          open={addPaymentMethodDialogOpen}
          setOpen={setAddPaymentMethodDialogOpen}
          patientId={patientId}
          onSuccessfulSetup={async () => {
            await apollo.refetchQueries({ include: [GET_READY_BILLS] });
          }}
        />
      )}
    </div>
  );
};

const PublicBillPortal: React.FC<
  React.PropsWithChildren<{ patient: PublicPatient }>
> = ({ patient }) => {
  const analytics = useAnalytics();
  const { medium } = useUtmParams();
  const client = useApolloClient();
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);

  useEffect(() => {
    // Attempt to identify the patientUser if there is one
    if (patient.patientUserId) {
      analytics?.identify(patient.patientUserId);
    }
    analytics?.track("Patient Portal Viewed", {
      loggedIn: false,
      referralMedium: medium,
      patientId: patient.id,
      organizationId: patient.organizationId,
      organizationName: patient.organizationName,
      locationId: patient.locationId,
      locationName: patient.locationName,
    });
  }, []);

  const onLogin = (data: LoginPatientUserOTP) => {
    if (data.loginPatientUserOTP?.id) {
      // Refetch the session cause there should be an active one and it'll
      // trigger a rerender that should go to the authenticated bill page
      client.refetchQueries({
        include: [CURRENT_SESSION],
      });
    }
  };

  const patientReadyBalance = -patient.patientReadyBalance;

  const locationDisplayName =
    patient.locationDisplayName ?? patient.locationName;

  return (
    <div>
      <div className="max-w-2xl mx-auto pt-8 sm:pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <div
          className={
            patientReadyBalance > 0
              ? `lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16`
              : `md:mt-20`
          }
        >
          <div className="flex flex-col text-center items-center">
            {patientReadyBalance <= 0 ? (
              <div className="flex justify-center">
                <CheckCircleIcon className="text-green-500 w-9 h-9"></CheckCircleIcon>
              </div>
            ) : null}
            <div className="text-2xl mt-2 mb-2">Hi, {patient?.firstName}</div>
            <div className="text-md mt-2">
              {patientReadyBalance > 0
                ? `You have new bills ready to view. You can pay your full balance
              here or verify your identity to view full details about your
              bills.`
                : `You have no balance due at this time. We will notify you when new bills
              are ready. To view your billing history, click below.`}
            </div>
            <div className="py-4">
              <button
                className="inline-flex justify-center max-w-xs px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-indigo-500 disabled:bg-gray-200 disabled:cursor-not-allowed"
                onClick={() => {
                  setOpenOtpModal(true);
                }}
              >
                View full bill details
              </button>
            </div>
          </div>
          {patientReadyBalance > 0 ? (
            <div className="pt-8 sm:pt-0">
              <div className="flex flex-col py-4 px-2 sm:px-8 border border-gray-200 bg-gray-50 rounded-lg">
                <PartialOrFullPaymentForm
                  patientReadyBalance={patientReadyBalance}
                  patientId={patient.id}
                  organizationName={patient.organizationName!}
                  paymentMethods={[]}
                  askToSavePaymentMethod={false}
                  askToEnrollInAutopay={false}
                />
              </div>
            </div>
          ) : null}
        </div>
        <Transition.Root show={openOtpModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setOpenOtpModal}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                  <OTPLogin
                    patientId={patient.id}
                    firstName={patient.firstName}
                    maskedEmail={patient.maskedEmail}
                    maskedCellPhone={patient.maskedCellPhone}
                    onLogin={onLogin}
                    organizationName={patient.organizationName}
                    locationDisplayName={locationDisplayName}
                  />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};

const PaymentSuccessScreen: React.FC<
  React.PropsWithChildren<{
    onClose: () => void;
    paymentIntentId: string;
    isAuthenticated: boolean;
    receiptEmail: string | null;
    patientId: string;
  }>
> = ({
  paymentIntentId,
  onClose,
  isAuthenticated,
  receiptEmail,
  patientId,
}) => {
  const [showSurvey, setShowSurvey] = useState(false);
  React.useEffect(() => {
    const timer = setTimeout(() => setShowSurvey(true), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  // TODO: query for PaymentIntent and display some additional info about the payment
  return (
    <div className="flex h-screen">
      <div className="m-auto flex flex-col items-center">
        <CheckCircleIcon className="w-12 h-12 text-green-500" />
        <p className="mt-2 text-2xl font-extrabold sm:text-3xl">
          We've received your payment!
        </p>
        {isDefined(receiptEmail) && (
          <p className="mt-2">A receipt is on it's way to {receiptEmail}</p>
        )}
        <div className="pt-8">
          <button
            onClick={onClose}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-indigo-500 disabled:bg-gray-200 disabled:cursor-not-allowed"
          >
            Back to bills
          </button>
        </div>
      </div>
      <FeedbackSurvey
        title="How was your payment experience?"
        showSurvey={showSurvey}
        setShowSurvey={setShowSurvey}
        feedbackType={
          isAuthenticated
            ? FeedbackType.PATIENT_AUTH_BILL_PORTAL
            : FeedbackType.PATIENT_UNAUTH_PAGE
        }
        patientId={patientId}
      />
    </div>
  );
};

const LOGIN_PATIENT_USER_MAGIC_LINK = gql`
  mutation LoginPatientUserMagicLink($magicToken: String!) {
    loginPatientUserMagicLink(magicToken: $magicToken) {
      id
    }
  }
`;

const MARK_BILLS_AS_VIEWED = gql`
  mutation MarkBillsAsViewed($patientPrimaryLinkedRecordId: String!) {
    markBillsAsViewed(
      patientPrimaryLinkedRecordId: $patientPrimaryLinkedRecordId
    )
  }
`;

export const PatientBillPortal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { isSessionExpired } = useAuth();
  const navigate = useNavigate();
  const params = useParams<{
    organizationId: string;
    patientId: string;
  }>();
  const organizationId = params.organizationId!;
  const patientId = params.patientId!;
  const analytics = useAnalytics();
  const client = useApolloClient();

  const { data: sessionData, loading: sessionLoading } =
    useQuery<CurrentSession>(CURRENT_SESSION);

  const [loginPatientUserMagicLink, result] = useMutation<
    LoginPatientUserMagicLink,
    LoginPatientUserMagicLinkVariables
  >(LOGIN_PATIENT_USER_MAGIC_LINK);

  const [markBillsAsViewed, markBillsAsViewedResult] = useMutation<
    MarkBillsAsViewed,
    MarkBillsAsViewedVariables
  >(MARK_BILLS_AS_VIEWED);

  const { data, loading } = useQuery<
    GetPublicPatientBillingData,
    GetPublicPatientBillingDataVariables
  >(GET_PUBLIC_PATIENT_BILLING_DATA, {
    variables: { id: patientId },
  });

  useEffect(() => {
    const supercededById = data?.getPublicPatientBillingData?.supercededById;
    if (supercededById) {
      console.log("Redirecting to superceded patient: ", supercededById);
      analytics?.track("Superceded Patient Portal Redirected", {
        patientId,
        supercededById,
      });
      navigate({
        pathname: `/portal/${organizationId}/${supercededById}`,
        search: window.location.search,
      });
    }
  }, [data]);

  const logout = () => {
    const redirectUrl = `/portal/${organizationId}/${patientId}`;
    window.location.href = `${constants.VITE_API_URL}/logout?redirectUrl=${redirectUrl}`;
  };

  const query = useQueryParams();
  const [showPaymentSuccessScreen, setShowPaymentSuccessScreen] = useState(
    !!query.get("payment_intent")
  );

  let [magicToken, setMagicToken] = useState("");
  let [isAuthenticated, setIsAuthenticated] = useState(false);
  let [magicLinkReady, setMagicLinkReady] = useState(false);
  let [magicLinkFinished, setMagicLinkFinished] = useState(false);

  useEffect(() => {
    if (!sessionLoading) {
      setIsAuthenticated(!!sessionData?.currentSession.user);
      setMagicToken(query.get("token") ?? "");
      const user = sessionData?.currentSession.user;
      // Skip marking bills as viewed if the logged in user is a ProviderUser
      if (user?.__typename !== "User") {
        markBillsAsViewed({
          variables: {
            patientPrimaryLinkedRecordId: patientId,
          },
        });
        // Identify the logged in user
        const userId =
          user?.id ?? data?.getPublicPatientBillingData?.patientUserId; // Fallback to public patient data
        if (userId) {
          analytics?.identify(userId);
        }
      }
      setMagicLinkReady(true);
    }
  }, [sessionData, sessionLoading]);

  useEffect(() => {
    if (magicLinkReady) {
      if (!isAuthenticated && magicToken && magicToken !== "") {
        loginPatientUserMagicLink({
          variables: { magicToken },
          onCompleted: (data) => {
            if (data.loginPatientUserMagicLink) {
              setIsAuthenticated(true);
              // Identify the logged in user
              analytics?.identify(data.loginPatientUserMagicLink.id);
            }
            setMagicLinkFinished(true);
          },
          onError: () => {
            setMagicLinkFinished(true);
          },
        });
      } else {
        setMagicLinkFinished(true);
      }
    }
  }, [isAuthenticated, magicToken, magicLinkReady]);

  // If the session is expired, log the user out
  useEffect(() => {
    if (isSessionExpired) {
      logout();
    }
  }, [isSessionExpired]);

  if (loading || sessionLoading || !magicLinkFinished) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );
  }

  const patient = data?.getPublicPatientBillingData;
  if (!patient) {
    return <NotFound />;
  }

  const onLogin = (data: LoginPatientUserOTP) => {
    if (data.loginPatientUserOTP?.id) {
      // Refetch the session cause there should be an active one and it'll
      // trigger a rerender that should go to the authenticated bill page
      client.refetchQueries({
        include: [CURRENT_SESSION],
      });
    }
  };

  const GenericLoginPage: React.FC<
    React.PropsWithChildren<{
      publicPatient: PublicPatient;
      description: string;
    }>
  > = ({ publicPatient, description }) => (
    <main className="mx-auto max-w-lg px-4 pt-10 pb-12 lg:pb-16">
      <div className="flex flex-col gap-4">
        <div>
          <h2 className="mt-10 text-center text-2xl font-bold">
            {description}
          </h2>
        </div>

        <OTPLogin
          patientId={publicPatient.id}
          firstName={publicPatient.firstName}
          maskedEmail={publicPatient.maskedEmail}
          maskedCellPhone={publicPatient.maskedCellPhone}
          onLogin={onLogin}
          organizationName={publicPatient.organizationName}
          locationDisplayName={
            publicPatient.locationDisplayName ?? publicPatient.locationName
          }
        />
      </div>
    </main>
  );

  return (
    <PortalLayout
      organizationId={data.getPublicPatientBillingData?.organizationId!}
    >
      <PatientPortalHeader
        logoUrl={patient.organizationLogoUrl}
        logout={logout}
        isAuthenticated={isAuthenticated}
        patient={patient}
        feedbackType={
          isAuthenticated
            ? FeedbackType.PATIENT_AUTH_BILL_PORTAL
            : FeedbackType.PATIENT_UNAUTH_PAGE
        }
      />

      <HelpDialogProvider
        organization={{
          id: patient.organizationId ?? "",
          name: patient.organizationName ?? "",
        }}
        location={{
          id: patient.locationId ?? "",
          name: patient.locationName ?? "",
        }}
      >
        <div className="mx-auto max-w-7xl">
          {showPaymentSuccessScreen ? (
            <PaymentSuccessScreen
              onClose={() => setShowPaymentSuccessScreen(false)}
              paymentIntentId={query.get("payment_intent")!}
              isAuthenticated={isAuthenticated}
              receiptEmail={patient.maskedEmail}
              patientId={patient.id}
            />
          ) : (
            <StripeProvider organizationId={organizationId}>
              {isAuthenticated ? (
                <Routes>
                  <Route
                    path="/settings"
                    element={<Settings publicPatient={patient} />}
                  />
                  <Route
                    path="/"
                    element={<PatientPayments patientId={patientId} />}
                  />
                  <Route
                    path="/visit/:appointmentId"
                    element={<PatientVisitPage patientId={patientId} />}
                  />
                  <Route
                    path="/receipt/:paymentIntentId"
                    element={<PatientReceipt />}
                  />
                </Routes>
              ) : (
                <Routes>
                  <Route
                    path="/settings"
                    element={
                      <GenericLoginPage
                        publicPatient={patient}
                        description="Sign in to manage your settings"
                      />
                    }
                  />
                  <Route
                    path="/visit/:appointmentId"
                    element={
                      <GenericLoginPage
                        publicPatient={patient}
                        description="Sign in to view your visit details"
                      />
                    }
                  />
                  <Route
                    path="/receipt/:paymentIntentId"
                    element={
                      <GenericLoginPage
                        publicPatient={patient}
                        description="Sign in to view your receipt"
                      />
                    }
                  />
                  <Route
                    path="/"
                    element={<PublicBillPortal patient={patient} />}
                  />
                </Routes>
              )}
            </StripeProvider>
          )}
        </div>
      </HelpDialogProvider>
    </PortalLayout>
  );
};

const HelpDialogContext = React.createContext<{
  helpDialog: HelpDialogContent | null;
  setHelpDialog: (helpDialog: HelpDialogContent | null) => void;
}>({ helpDialog: null, setHelpDialog: () => {} });

const HelpDialogProvider: React.FC<
  React.PropsWithChildren<{
    organization: { id: string; name: string };
    location: { id: string; name: string };
  }>
> = ({ organization, location, children }) => {
  const analytics = useAnalytics();
  const [helpDialog, setHelpDialog] = useState<HelpDialogContent | null>(null);
  const sendExpandedPatientPortalVisitRow = (helpDialog: HelpDialogContent) => {
    analytics?.track("Open Help Text Dialog", {
      title: helpDialog?.title,
      organizationId: organization.id,
      organizationName: organization.name,
      locationId: location.id,
      locationName: location.name,
    });
  };

  const setHelpDialogWithAnalytics = (helpDialog: HelpDialogContent | null) => {
    setHelpDialog(helpDialog);
    if (helpDialog) {
      sendExpandedPatientPortalVisitRow(helpDialog);
    }
  };

  return (
    <HelpDialogContext.Provider
      value={{
        helpDialog,
        setHelpDialog: setHelpDialogWithAnalytics,
      }}
    >
      {children}
      {helpDialog && (
        <HelpDialog
          helpDialog={helpDialog}
          setHelpDialog={setHelpDialogWithAnalytics}
        />
      )}
    </HelpDialogContext.Provider>
  );
};

const useHelpDialog = () => React.useContext(HelpDialogContext);
