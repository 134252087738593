import { Cross2Icon, DotsVerticalIcon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { gql, useQuery } from "@apollo/client";
import { PatientVisitRow } from ".";
import { useUser } from "../../../user-context";
import { DataTableFacetedFilter } from "../../../components/ui/table-helpers/data-table-faceted-filter";
import { Button } from "../../../components/ui/button";
import { AppointmentStatus } from "../../../generated/globalTypes";
import { isDefined } from "../../../utils";
import { VisitBillStatus } from "./types";

interface DataTableToolbarProps<TData extends { id: string }> {
  table: Table<TData>;
}

const APPOINTMENT_STATUS_OPTIONS = [
  {
    value: AppointmentStatus.Booked,
    label: "Booked",
  },
  {
    value: AppointmentStatus.CheckedIn,
    label: "Checked In",
  },
  {
    value: AppointmentStatus.Completed,
    label: "Complete",
  },
  {
    value: AppointmentStatus.Canceled,
    label: "Canceled",
  },
  {
    value: AppointmentStatus.NoShow,
    label: "No Show",
  },
  {
    value: null,
    label: "No Status",
  },
];

const BILLING_STATUS_OPTIONS = [
  {
    value: VisitBillStatus.AwaitingCharges,
    label: "Awaiting Charges",
  },
  {
    value: VisitBillStatus.ChargesEntered,
    label: "Charges Entered",
  },
  {
    value: VisitBillStatus.InReview,
    label: "In Review",
  },
  {
    value: VisitBillStatus.Ready,
    label: "Ready",
  },
  {
    value: VisitBillStatus.Paid,
    label: "Paid",
  },
  {
    value: VisitBillStatus.Archived,
    label: "Archived",
  },
];

export function DataTableToolbar({
  table,
}: DataTableToolbarProps<PatientVisitRow>) {
  const user = useUser()!;
  const isFiltered = table.getState().columnFilters.length > 0;

  const rows = table.getFilteredRowModel().rows;
  const providerOptions = Array.from(
    new Set(rows.map((row) => row.original.providerName).filter(isDefined))
  ).map((provider) => ({
    value: provider,
    label: provider,
  }));
  const accountTypeOptions = Array.from(
    new Set(rows.map((row) => row.original.accountType).filter(isDefined))
  ).map((accountType) => ({
    value: accountType,
    label: accountType,
  }));

  return (
    <div className="flex items-start justify-between gap-2">
      <div className="flex flex-1 items-center flex-wrap gap-2">
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={APPOINTMENT_STATUS_OPTIONS}
          />
        )}
        {table.getColumn("billStatus") && (
          <DataTableFacetedFilter
            column={table.getColumn("billStatus")}
            title="Billing Status"
            options={BILLING_STATUS_OPTIONS}
          />
        )}
        {table.getColumn("providerName") && (
          <DataTableFacetedFilter
            column={table.getColumn("providerName")}
            title="Provider"
            options={providerOptions}
          />
        )}
        {table.getColumn("accountType") && (
          <DataTableFacetedFilter
            column={table.getColumn("accountType")}
            title="Account"
            options={accountTypeOptions}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
}
