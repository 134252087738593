import React from "react";
import { Modal, SubmitButton } from "../../components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useUser } from "../../user-context";
import {
  ListExternalFeeSchedules,
  ListExternalFeeSchedulesVariables,
  ListExternalFeeSchedules_listExternalFeeSchedules as ExternalFeeSchedule,
} from "../../generated/ListExternalFeeSchedules";
import {
  ImportExternalFeeSchedule,
  ImportExternalFeeScheduleVariables,
} from "../../generated/ImportExternalFeeSchedule";
import { RadioGroup } from "@headlessui/react";
import { cn } from "../../utils";
import { Button } from "../../components/ui/button";
import { useAnalytics } from "../../analytics-context";

const LIST_EXTERNAL_FEE_SCHEDULES = gql`
  query ListExternalFeeSchedules($integrationId: String!) {
    listExternalFeeSchedules(integrationId: $integrationId) {
      id
      name
      feeCount
    }
  }
`;

const IMPORT_EXTERNAL_FEE_SCHEDULE = gql`
  mutation ImportExternalFeeSchedule(
    $integrationId: String!
    $feeScheduleId: String!
  ) {
    importExternalFeeSchedule(
      integrationId: $integrationId
      feeScheduleId: $feeScheduleId
    ) {
      errors {
        message
      }
      fees {
        code
        allowedAmount
        description
        modifier1
        modifier2
        modifier3
        modifier4
      }
    }
  }
`;

export type ImportedFee = {
  code: string;
  allowedAmount: number;
  description: string | null;
  modifier1: string | null;
  modifier2: string | null;
  modifier3: string | null;
  modifier4: string | null;
};

export const FeeScheduleImportDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  integrationId: string;
  onImport: (fee: ImportedFee[]) => void;
}> = ({ open, setOpen, integrationId, onImport }) => {
  const user = useUser()!;
  const analytics = useAnalytics();

  const [selected, setSelected] = React.useState<ExternalFeeSchedule | null>(
    null
  );

  const { data, loading, error } = useQuery<
    ListExternalFeeSchedules,
    ListExternalFeeSchedulesVariables
  >(LIST_EXTERNAL_FEE_SCHEDULES, {
    // Cache-first to avoid overfetching expensive query
    fetchPolicy: "cache-first",
    variables: {
      integrationId,
    },
  });

  const [importExternalFeeSchedule, importExternalFeeScheduleResult] =
    useMutation<ImportExternalFeeSchedule, ImportExternalFeeScheduleVariables>(
      IMPORT_EXTERNAL_FEE_SCHEDULE,
      {
        onCompleted: (data) => {
          const fees = data.importExternalFeeSchedule?.fees ?? [];
          onImport(fees);
          analytics?.track("External Fee Schedule Imported", {
            organizationId: user.organization.id,
            organizationName: user.organization.name,
            locationId: user.activeLocation.id,
            locationName: user.activeLocation.name,
            feeScheduleId: selected?.name,
          });
          setOpen(false);
        },
      }
    );

  const externalFeeSchedules = data?.listExternalFeeSchedules ?? [];

  return (
    <Modal open={open} setOpen={setOpen} size="lg">
      {loading ? (
        <div>Loading Fee Schedules...</div>
      ) : (
        <div>
          <div className="text-xl font-medium">
            Choose Fee Schedule to Import From
          </div>
          <div className="text-gray-700">
            This will replace any existing fees in this fee schedule.
          </div>
          <div className="pt-4">
            <RadioGroup value={selected} onChange={setSelected}>
              <RadioGroup.Label className="sr-only">
                Privacy setting
              </RadioGroup.Label>
              <div className="-space-y-px rounded-md bg-white">
                {externalFeeSchedules.map((feeSchedule, idx) => (
                  <RadioGroup.Option
                    key={feeSchedule.id}
                    value={feeSchedule}
                    className={({ checked }) =>
                      cn(
                        idx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                        idx === externalFeeSchedules.length - 1
                          ? "rounded-bl-md rounded-br-md"
                          : "",
                        checked
                          ? "z-10 border-indigo-200 bg-indigo-50"
                          : "border-gray-200",
                        "relative flex cursor-pointer border p-4 focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span
                          className={cn(
                            checked
                              ? "bg-indigo-600 border-transparent"
                              : "bg-white border-gray-300",
                            active
                              ? "ring-2 ring-offset-2 ring-indigo-600"
                              : "",
                            "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <span className="ml-3 flex justify-between w-full">
                          <RadioGroup.Label
                            as="span"
                            className={cn(
                              checked ? "text-indigo-900" : "text-gray-900",
                              "block text-sm font-medium"
                            )}
                          >
                            {feeSchedule.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={cn(
                              checked ? "text-indigo-700" : "text-gray-500",
                              "block text-sm"
                            )}
                          >
                            {feeSchedule.feeCount} fees
                          </RadioGroup.Description>
                        </span>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>

            <div className="flex justify-between pt-4">
              <Button
                variant="outline"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <div>
                <SubmitButton
                  loading={importExternalFeeScheduleResult.loading}
                  disabled={!selected}
                  onClick={() => {
                    importExternalFeeSchedule({
                      variables: {
                        integrationId,
                        feeScheduleId: selected!.id,
                      },
                    });
                  }}
                >
                  Import
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
