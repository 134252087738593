import { formatDistanceToNow, isBefore, parseISO } from "date-fns";
import { DollarSignIcon, MoreHorizontal } from "lucide-react";
import React, { useState } from "react";
import { WorkflowStepStatus, WorkflowStepStatusDisplay } from ".";
import { Card } from "../../../../components/card";
import { Button } from "../../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import { useFeatureFlags } from "../../../../hooks";
import { formatUSD, isDefined, mapNullable } from "../../../../utils";
import { AppointmentsRow } from "../columns";
import { CreateEstimateWizardDialog } from "../estimate-dialog";
import {
  EstimationEnabledDialogButton,
  EstimationErrorDisplay,
} from "./pre-visit-estimation";

export const postVisitEstimateIsScheduled = (row: AppointmentsRow) => {
  const estimationPausedAt = mapNullable(parseISO)(
    row.appointment.estimationPausedAt
  );
  const estimationOff = isDefined(estimationPausedAt);
  const preVisitEstimateScheduledAt = mapNullable(parseISO)(
    row.appointment.preVisitEstimateScheduledAt
  );
  const estimateScheduled =
    isDefined(preVisitEstimateScheduledAt) && isBefore(new Date(), row.start);
  return !estimationOff && estimateScheduled;
};

export const postVisitEstimateIsComplete = (row: AppointmentsRow) => {
  const lastPostVisitCollectionRequest = row.lastPostVisitCollectionRequest;
  const postVisitEstimated = isDefined(lastPostVisitCollectionRequest);
  return postVisitEstimated;
};

export const PostVisitEstimationWorkflowHoverCardContent: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const flags = useFeatureFlags();

  const lastPreVisitCollectionRequest = row.lastPreVisitCollectionRequest;
  const lastPostVisitCollectionRequest = row.lastPostVisitCollectionRequest;

  const postVisitEstimated = postVisitEstimateIsComplete(row);

  const estimateConfirmed =
    isDefined(lastPreVisitCollectionRequest) &&
    isDefined(lastPostVisitCollectionRequest) &&
    lastPreVisitCollectionRequest.amount ===
      lastPostVisitCollectionRequest.amount;
  const awaitingCharges = !row.chargeStatus;
  const estimateUnconfirmed =
    !awaitingCharges &&
    isDefined(lastPreVisitCollectionRequest) &&
    !isDefined(lastPostVisitCollectionRequest);
  const onlyPostVisitEstimate =
    !isDefined(lastPreVisitCollectionRequest) &&
    isDefined(lastPostVisitCollectionRequest);
  const estimateUpdated =
    lastPreVisitCollectionRequest &&
    lastPostVisitCollectionRequest &&
    lastPreVisitCollectionRequest.amount !==
      lastPostVisitCollectionRequest.amount;
  const increased =
    estimateUpdated &&
    lastPostVisitCollectionRequest.amount! >
      lastPreVisitCollectionRequest!.amount!;
  const decreased =
    estimateUpdated &&
    lastPostVisitCollectionRequest.amount! <
      lastPreVisitCollectionRequest!.amount!;

  const appointmentEstimationPausedAt = mapNullable(parseISO)(
    row.appointment.estimationPausedAt
  );
  const patientEstimationPausedAt = mapNullable(parseISO)(
    row.account.patient.estimationPausedAt
  );
  const estimationPausedAt =
    appointmentEstimationPausedAt ?? patientEstimationPausedAt;
  const estimationOff = isDefined(estimationPausedAt);

  let content = null;

  if (postVisitEstimated) {
    if (estimateConfirmed) {
      // If pre and post visit estimates are the same
      content = (
        <div className="text-wrap">
          {formatUSD(lastPostVisitCollectionRequest.amount)} estimate confirmed.
        </div>
      );
    } else if (increased) {
      // If post visit estimate is greater than pre-visit estimate
      content = (
        <div className="text-wrap">
          Finalized estimate increased from{" "}
          {formatUSD(lastPreVisitCollectionRequest.amount)} to{" "}
          {formatUSD(lastPostVisitCollectionRequest.amount)}.
        </div>
      );
    } else if (decreased) {
      // If post visit estimate is less than pre-visit estimate
      content = (
        <div className="text-wrap">
          Finalized estimate decreased from{" "}
          {formatUSD(lastPreVisitCollectionRequest.amount)} to{" "}
          {formatUSD(lastPostVisitCollectionRequest.amount)}.
        </div>
      );
    } else {
      content = (
        <div className="text-wrap">
          Finalized estimate of{" "}
          {formatUSD(lastPostVisitCollectionRequest!.amount)}.
        </div>
      );
    }
  } else if (estimationOff) {
    content = (
      <>
        <div className="text-wrap">
          {patientEstimationPausedAt ? (
            <>
              Automated estimation was turned off for this patient{" "}
              {formatDistanceToNow(patientEstimationPausedAt, {
                addSuffix: true,
              })}
              .
            </>
          ) : appointmentEstimationPausedAt ? (
            <>
              Automated estimation was turned off for this appointment{" "}
              {formatDistanceToNow(appointmentEstimationPausedAt, {
                addSuffix: true,
              })}
              .
            </>
          ) : (
            <>
              Automated estimation was turned off{" "}
              {formatDistanceToNow(estimationPausedAt, {
                addSuffix: true,
              })}
              .
            </>
          )}
        </div>
        {flags.automatedEstimatesEnabled && (
          <div className="flex justify-between gap-2 pt-1 border-t">
            <EstimationEnabledDialogButton row={row} />
          </div>
        )}
      </>
    );
  } else {
    content = (
      <>
        <div className="text-wrap">Estimate not finalized yet.</div>
        {row.appointment.lastEstimationError && (
          <EstimationErrorDisplay
            estimationError={row.appointment.lastEstimationError}
          />
        )}
      </>
    );
  }

  return (
    <Card>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <h2 className="font-semibold">Finalize Estimate</h2>
            <WorkflowStepStatusDisplay status={status} />
          </div>
        </div>
        {content}
      </div>
    </Card>
  );
};

export const PostVisitEstimationWorkflowNextAction: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const [open, setOpen] = useState(false);

  const lastPreVisitCollectionRequest =
    row.appointment.lastPreVisitCollectionRequest;

  let content = null;
  if (status === "action_required") {
    content = (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <Button
            variant="outline"
            size="sm"
            className="flex items-center gap-1"
            onClick={() => setOpen(true)}
          >
            <DollarSignIcon className="h-4 w-4" />
            {lastPreVisitCollectionRequest
              ? "Finalize Estimate"
              : "Create Estimate"}
          </Button>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => {
                setOpen(true);
              }}
            >
              Create Estimate
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  }
  return (
    <>
      {content}
      {open && (
        <CreateEstimateWizardDialog
          appointmentId={row.id}
          setOpen={setOpen}
          onComplete={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
