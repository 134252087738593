import React from "react";
import { gql, useQuery } from "@apollo/client";

import { useUser } from "../../user-context";
import { Table, Td } from "../../components/Table";
import {
  GetBillingGroups,
  GetBillingGroupsVariables,
  GetBillingGroups_billingGroups as BillingGroup,
} from "../../generated/GetBillingGroups";
import { Layout } from "../layout";

const GET_BILLING_GROUPS = gql`
  query GetBillingGroups($organizationId: String!) {
    billingGroups(where: { organizationId: { equals: $organizationId } }) {
      id
      name
      npi
    }
  }
`;

export const BillingGroupList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser();
  const { loading, error, data } = useQuery<
    GetBillingGroups,
    GetBillingGroupsVariables
  >(GET_BILLING_GROUPS, {
    variables: {
      organizationId: user!.organization.id,
    },
  });
  if (loading) return <>Loading</>;
  if (!data) return null;
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <Table
            columnDefs={[
              {
                header: "Name",
                cellFn: (billingGroup: BillingGroup) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {billingGroup.name}
                    </div>
                  </Td>
                ),
              },
              {
                header: "NPI",
                cellFn: (billingGroup: BillingGroup) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {billingGroup.npi}
                    </div>
                  </Td>
                ),
              },
            ]}
            rows={data.billingGroups}
          />
        </div>
      </div>
    </div>
  );
};

export const BillingGroups: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Layout
      header={
        <div className="flex flex-col">
          <h1 className="text-2xl font-semibold text-gray-900">
            Billing Groups
          </h1>
          <h2 className="text-lg">
            Organizations that bill under a Type 2 NPI.
          </h2>
        </div>
      }
      content={
        <div className="py-4">
          <BillingGroupList />
        </div>
      }
    />
  );
};
