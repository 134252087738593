import { useForm, FormProvider } from "react-hook-form";
import { SettingsLayout } from ".";
import {
  GetMyOrganization_me_organization as Organization,
  GetMyOrganization_me_organization_users as User,
} from "../../generated/GetMyOrganization";
import { toast } from "react-toastify";
import { gql, useMutation } from "@apollo/client";
import { Badge, Card, SubmitButton } from "../../components";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Button } from "../../components/ui/button";
import { useState } from "react";
import {
  UpdateUserName,
  UpdateUserNameVariables,
} from "../../generated/UpdateUserName";
import {
  MakeUserAdmin,
  MakeUserAdminVariables,
} from "../../generated/MakeUserAdmin";
import {
  DeactivateUser,
  DeactivateUserVariables,
} from "../../generated/DeactivateUser";
import {
  ActivateUser,
  ActivateUserVariables,
} from "../../generated/ActivateUser";

const UPDATE_USER_NAME = gql`
  mutation UpdateUserName(
    $id: String!
    $firstName: String!
    $lastName: String!
  ) {
    updateUserName(id: $id, firstName: $firstName, lastName: $lastName) {
      id
      firstName
      lastName
    }
  }
`;

const DEACTIVATE_USER = gql`
  mutation DeactivateUser($userId: String!) {
    deactivateUser(userId: $userId) {
      id
      deactivatedAt
    }
  }
`;

const ACTIVATE_USER = gql`
  mutation ActivateUser($userId: String!) {
    activateUser(userId: $userId) {
      id
      deactivatedAt
    }
  }
`;

type UserNameForm = {
  firstName: string;
  lastName: string;
};

const UpdateNameDialogButton: React.FC<{
  user: User;
}> = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [updateUserName, updateUserNameResult] = useMutation<
    UpdateUserName,
    UpdateUserNameVariables
  >(UPDATE_USER_NAME);
  const form = useForm<UserNameForm>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>Update name</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Please enter the new name for {user.firstName} {user.lastName}
          </DialogTitle>
          <DialogDescription>
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit((data) => {
                  updateUserName({
                    variables: {
                      id: user.id,
                      firstName: data.firstName,
                      lastName: data.lastName,
                    },
                    onCompleted: () => {
                      toast.success("Name updated");
                      setOpen(false);
                    },
                    onError: () => {
                      toast.error("Failed to update name");
                    },
                  });
                })}
              >
                <div className="flex flex-col gap-1 pt-2">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      required
                      {...form.register("firstName")}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      required
                      {...form.register("lastName")}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="flex justify-end gap-2 pt-2">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <div>
                      <SubmitButton disabled={updateUserNameResult.loading}>
                        Save
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const MAKE_USER_ADMIN = gql`
  mutation MakeUserAdmin($id: String!) {
    makeUserAdmin(id: $id) {
      id
      isAdmin
    }
  }
`;

const MakeAdminDialogButton: React.FC<{
  user: User;
}> = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [makeUserAdmin, makeUserAdminResult] = useMutation<
    MakeUserAdmin,
    MakeUserAdminVariables
  >(MAKE_USER_ADMIN);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        Make {user.firstName} {user.lastName} an admin
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to make {user.firstName} {user.lastName} an
            admin?
          </DialogTitle>
          <DialogDescription>
            <div>
              <div className="text-gray-600">
                They will have access to the full workspace settings.
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <SubmitButton
                    disabled={makeUserAdminResult.loading}
                    onClick={() => {
                      makeUserAdmin({
                        variables: {
                          id: user.id,
                        },
                        onCompleted: () => {
                          toast.success("User is now an admin");
                          setOpen(false);
                        },
                        onError: () => {
                          toast.error("Failed to make user an admin");
                        },
                      });
                    }}
                  >
                    Save
                  </SubmitButton>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const MakeUserActiveButton: React.FC<{
  user: User;
}> = ({ user }) => {
  const [open, setOpen] = useState(false);

  const [activateUser, activateUserResult] = useMutation<
    ActivateUser,
    ActivateUserVariables
  >(ACTIVATE_USER);

  const [deactivateUser, deactivateUserResult] = useMutation<
    DeactivateUser,
    DeactivateUserVariables
  >(DEACTIVATE_USER);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        {user.deactivatedAt ? <>Activate</> : <>Deactivate</>} {user.firstName}{" "}
        {user.lastName}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to{" "}
            {user.deactivatedAt ? <>activate</> : <>deactivate</>}{" "}
            {user.firstName} {user.lastName}
          </DialogTitle>
          <DialogDescription>
            <div>
              <div className="text-gray-600">
                {user.deactivatedAt
                  ? "They will be able to login again."
                  : "They will no longer be able to login."}
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <SubmitButton
                    disabled={
                      deactivateUserResult.loading || activateUserResult.loading
                    }
                    buttonClassName={
                      user.deactivatedAt
                        ? undefined
                        : "bg-red-600 hover:bg-red-700"
                    }
                    onClick={() => {
                      if (user.deactivatedAt) {
                        activateUser({
                          variables: {
                            userId: user.id,
                          },
                          onCompleted: () => {
                            toast.success("User has been reactivated");
                            setOpen(false);
                          },
                          onError: () => {
                            toast.error("Failed to reactivate user");
                          },
                        });
                      } else {
                        deactivateUser({
                          variables: {
                            userId: user.id,
                          },
                          onCompleted: () => {
                            toast.success("User is now deactivated");
                            setOpen(false);
                          },
                          onError: () => {
                            toast.error("Failed to deactivate user");
                          },
                        });
                      }
                    }}
                  >
                    {user.deactivatedAt ? <>Activate</> : <>Deactivate</>}
                  </SubmitButton>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export const WorkspaceMembersSettings: React.FC<{
  organization: Organization;
}> = ({ organization }) => {
  return (
    <SettingsLayout
      title="Members"
      description="Manage your workspace members."
    >
      <Card>
        <div className="flex flex-col w-full">
          <ul role="list" className="divide-y divide-gray-100">
            {organization.users.map((user) => (
              <li key={user.id} className="flex justify-between gap-x-6 py-5">
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {user.firstName} {user.lastName}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {user.email}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-gray-900">
                      {user.deactivatedAt ? (
                        <Badge variant="error" text="Deactivated" />
                      ) : user.isAdmin ? (
                        <Badge variant="info" text="Admin" />
                      ) : (
                        <Badge variant="info" text="Member" />
                      )}
                    </p>
                    <p className="mt-1 text-xs leading-5 text-gray-500">
                      {user.activeLocation.name}
                    </p>
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger className="rounded-full p-1 hover:bg-gray-100">
                      <DotsVerticalIcon className="text-gray-500 h-4" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <UpdateNameDialogButton user={user} />
                      </DropdownMenuItem>
                      {!user.isAdmin && (
                        <>
                          <DropdownMenuItem
                            onSelect={(e) => e.preventDefault()}
                          >
                            <MakeAdminDialogButton user={user} />
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onSelect={(e) => e.preventDefault()}
                          >
                            <MakeUserActiveButton user={user} />
                          </DropdownMenuItem>
                        </>
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Card>
    </SettingsLayout>
  );
};
