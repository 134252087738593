import React from "react";
import { CurrencyDollarIcon, MailIcon } from "@heroicons/react/outline";
import { gql, useQuery } from "@apollo/client";
import { compareDesc, format, formatDistanceToNow, parseISO } from "date-fns";

import { formatUSD, isDefined, toDateMMDDYYYY } from "../../../utils";
import {
  GetPatientActivity,
  GetPatientActivityVariables,
  GetPatientActivity_communications as Communication,
  GetPatientActivity_transactions as Transaction,
  GetPatientActivity_communications_bills as Bill,
  GetPatientActivity_paymentIntents as PaymentIntent,
} from "../../../generated/GetPatientActivity";
import { Card, Tooltip } from "../../../components";
import {
  CommunicationContentType,
  CommunicationErrorType,
  CommunicationType,
} from "../../../generated/globalTypes";
import { GetPatient_patient as Patient } from "../../../generated/GetPatient";
import { BalanceSummary } from "../balance-summary";
import { paymentMethodDisplay } from "../payment-method-display";

export const BILL_ACTIVITY_FIELDS = gql`
  fragment BillActivityFields on Bill {
    id
    createdAt
    dateOfService
    dateOfServiceDisplay
    appointment {
      id
      provider {
        id
        firstName
        lastName
        credentials
      }
    }
  }
`;

const GET_PATIENT_ACTIVITY = gql`
  ${BILL_ACTIVITY_FIELDS}
  query GetPatientActivity($id: String!) {
    communications(
      where: { patient: { is: { id: { equals: $id }, deletedAt: null } } }
    ) {
      id
      type
      contentType
      sentAt
      lastOpenedAt
      openCount
      lastErrorType
      lastErrorReasonDisplay
      sentBy {
        id
        firstName
        lastName
      }
      bills {
        ...BillActivityFields
      }
    }
    # PaymentIntents that failed and are autopay or have a patient payment method
    paymentIntents(
      where: {
        patient: { is: { id: { equals: $id }, deletedAt: null } }
        lastPaymentError: { not: { equals: null } }
        OR: [
          { autoPay: { equals: true } }
          { patientPaymentMethodId: { not: { equals: null } } }
        ]
      }
    ) {
      id
      createdAt
      lastPaymentError
      autoPay
      patientPaymentMethod {
        id
        type
        cardBrand
        lastFour
        expirationMonth
        expirationYear
      }
    }
    transactions(
      where: {
        account: { is: { patientId: { equals: $id }, deletedAt: null } }
        # Only patient payments
        payment: { is: { type: { equals: Patient } } }
      }
    ) {
      id
      transactedAt
      payment {
        id
        type
        method
        patientAmount
        isPledgeRefund
        paymentIntent {
          id
          status
          autoPay
        }
      }
      paymentAllocations {
        id
        chargeTransaction {
          id
          charge {
            id
            bill {
              ...BillActivityFields
            }
          }
        }
      }
    }
  }
`;

export const billCommunicationTypeDisplay = (
  billContentType: CommunicationContentType
) => {
  switch (billContentType) {
    case CommunicationContentType.BILL:
      return "Initial Invoice";
    case CommunicationContentType.REMINDER:
      return "Reminder";
    // TODO: Handle when due date is not 30d after sending
    case CommunicationContentType.TWENTY_SIX_DAYS_BEFORE_DUE_BILL_REMINDER:
      return "4 Day Reminder";
    case CommunicationContentType.SIXTEEN_DAYS_BEFORE_DUE_BILL_REMINDER:
      return "14 Day Reminder";
    case CommunicationContentType.SEVEN_DAYS_BEFORE_DUE_BILL_REMINDER:
      return "7 Days Before Due Reminder";
    case CommunicationContentType.DUE_DATE_BILL_REMINDER:
      return "Due Date Reminder";
    case CommunicationContentType.FIFTEEN_DAYS_AFTER_DUE_BILL_REMINDER:
      return "15 Days Overdue Reminder";
    case CommunicationContentType.THIRTY_DAYS_AFTER_DUE_BILL_REMINDER:
      return "30 Days Overdue Reminder";
    case CommunicationContentType.FORTY_FIVE_DAYS_AFTER_DUE_BILL_REMINDER:
      return "45 Days Overdue Reminder";
    case CommunicationContentType.SIXTY_DAYS_AFTER_DUE_BILL_REMINDER:
      return "60 Days Overdue Reminder";
    case CommunicationContentType.NINETY_DAYS_AFTER_DUE_BILL_REMINDER:
      return "90 Days Overdue Reminder";
    case CommunicationContentType.AUTOPAY_NOTIFICATION:
      return "Autopay Notification";
    case CommunicationContentType.AUTOPAY_FAILURE_NOTIFICATION:
      return "Autopay Failure Notification";
  }
  return billContentType;
};

export const communicationTypeDisplay = (
  communicationType: CommunicationType
) => {
  switch (communicationType) {
    case CommunicationType.EMAIL:
      return "email";
    case CommunicationType.TEXT:
      return "SMS";
  }
};

export const communicationErrorDisplay = (
  communicationErrorType: CommunicationErrorType
) => {
  switch (communicationErrorType) {
    case CommunicationErrorType.UNKNOWN:
      return "FAILED";
  }
  return communicationErrorType;
};

const paymentTargetsBlurb = (
  targets: {
    dateOfServiceDisplay: string;
    provider: {
      firstName: string | null;
      lastName: string | null;
      credentials: string | null;
    } | null;
  }[]
) => {
  const [first, ...rest] = targets;
  let blurb = "";
  if (first?.dateOfServiceDisplay) {
    blurb = `bill from ${toDateMMDDYYYY(first.dateOfServiceDisplay)}`;
    let providerBlurb = [
      [first?.provider?.firstName, first?.provider?.lastName].join(" "),
      first?.provider?.credentials,
    ]
      .filter((x) => !!x)
      .join(", ");
    if (providerBlurb) {
      blurb = blurb + ` with ${providerBlurb}`;
    }
    if (rest.length > 0) {
      blurb = blurb + ` and ${rest.length} other bills`;
    }
  } else if (targets.length > 0) {
    blurb = `for ${targets.length} bills`;
  }
  return blurb;
};

export type Activity = {
  timestamp: Date;
  event: Communication | Transaction | PaymentIntent;
};

export const ActivityFeedItem: React.FC<
  React.PropsWithChildren<{
    activity: Activity;
    patient: { displayName: string };
  }>
> = ({ activity: { event, timestamp }, patient }) => {
  switch (event.__typename) {
    case "Communication":
      const senderName = event.sentBy
        ? [event.sentBy.firstName, event.sentBy.lastName]
            .filter(isDefined)
            .join(" ") + " sent"
        : "Sent";
      return (
        <>
          <div>
            <div className="relative px-1">
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-1 ring-gray-200">
                <MailIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          <div className="text-sm text-gray-900 pb-2 w-full">
            <div>
              {senderName}{" "}
              <span className="font-semibold">
                {billCommunicationTypeDisplay(event.contentType)}
              </span>{" "}
              via {communicationTypeDisplay(event.type)} for a{" "}
              {paymentTargetsBlurb(
                event.bills
                  .filter((b) => isDefined(b.dateOfServiceDisplay))
                  .map((b) => ({
                    dateOfServiceDisplay: b.dateOfServiceDisplay!,
                    provider: null,
                  }))
              )}
              {event.lastErrorType && (
                <>
                  {" "}
                  but was
                  <Tooltip
                    trigger={
                      <span
                        className={`inline-flex ml-1 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800`}
                      >
                        {communicationErrorDisplay(event.lastErrorType)}
                      </span>
                    }
                    content={
                      <div className="max-w-xl">
                        {event.lastErrorReasonDisplay}
                      </div>
                    }
                  />
                </>
              )}{" "}
              <Tooltip
                trigger={
                  <span className="text-gray-500">
                    {formatDistanceToNow(timestamp)} ago
                  </span>
                }
                content={<div>{format(timestamp, "MM/dd/yyyy hh:mm aa")}</div>}
              />
            </div>
          </div>
        </>
      );
    case "PaymentIntent":
      const errorReason = event.lastPaymentError;
      const paymentMethod = event.patientPaymentMethod;
      return (
        <>
          <div>
            <div className="relative px-1">
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-1 ring-gray-200">
                <CurrencyDollarIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            </div>
          </div>
          <div className="text-sm text-gray-900 pb-2 w-full">
            <div>
              {event.autoPay ? "Autopay" : "Payment"} attempt
              {paymentMethod && (
                <> with {paymentMethodDisplay(paymentMethod)}</>
              )}{" "}
              failed with error:{" "}
              <span className="text-red-900">{errorReason}</span>{" "}
              <Tooltip
                trigger={
                  <span className="text-gray-500">
                    {formatDistanceToNow(timestamp)} ago
                  </span>
                }
                content={<div>{format(timestamp, "MM/dd/yyyy hh:mm aa")}</div>}
              />
            </div>
          </div>
        </>
      );
    case "Transaction":
      const payment = event.payment!;
      const patientAmount = payment?.patientAmount;
      if (!isDefined(patientAmount)) return <></>;

      const paymentTargets = [
        ...event.paymentAllocations
          .map((pa) => pa.chargeTransaction.charge?.bill)
          .filter((b) => isDefined(b?.dateOfServiceDisplay))
          .map((b) => ({
            dateOfServiceDisplay: b!.dateOfServiceDisplay!,
            provider: b!.appointment?.provider ?? null,
          })),
        // // Filter out EstimatedChargePayments with an associated bill because it should show up in the paymentAllocations
        // ...event.estimatedChargePayments
        //   .filter((ecp) => !ecp.estimatedCharge.estimate.bill)
        //   .map((ecp) => ecp.estimatedCharge.estimate.appointment)
        //   .filter(isDefined)
        //   .map((appt) => ({
        //     dateOfService: appt.start,
        //     provider: appt.provider ?? null,
        //   })),
      ];

      const targetsBlurb = paymentTargetsBlurb(paymentTargets);

      const isPledgePayment =
        payment.paymentIntent?.status === "succeeded" || payment.isPledgeRefund;
      const isAutoPayment = !!payment.paymentIntent?.autoPay;
      const isRefund = payment.isPledgeRefund;
      let transactionBlurb;
      if (isRefund) {
        transactionBlurb = "was refunded";
      } else {
        if (isAutoPayment) {
          transactionBlurb = "auto-paid";
        } else {
          transactionBlurb = "paid";
        }
      }
      // Negate the amount if it's a refund
      const transactionAmount = isRefund ? -patientAmount : patientAmount;
      return (
        <>
          <div>
            <div className="relative px-1">
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-1 ring-gray-200">
                <CurrencyDollarIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          <div className="text-sm text-gray-900 pb-2 w-full">
            <div>
              {patient.displayName} {transactionBlurb}{" "}
              {formatUSD(transactionAmount)} towards a {targetsBlurb}
              {isPledgePayment && (
                <>
                  {" "}
                  via
                  <span className="text-indigo-500"> Pledge</span>
                </>
              )}{" "}
              <Tooltip
                trigger={
                  <span className="text-gray-500">
                    {formatDistanceToNow(timestamp)} ago
                  </span>
                }
                content={<div>{format(timestamp, "MM/dd/yyyy hh:mm aa")}</div>}
              />
            </div>
          </div>
        </>
      );
  }
};

export const MemoizedActivityFeed: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    patientActivity: GetPatientActivity;
  }>
> = ({ patient, patientActivity }) => {
  const activities: Activity[] = React.useMemo(
    () =>
      [
        ...(patientActivity.communications ?? [])
          // Only show communications linked to bills for now
          .filter((c) => c.bills.length > 0)
          .map((c) => ({
            timestamp: c.sentAt ? parseISO(c.sentAt) : c.sentAt,
            event: c,
          })),
        ...(patientActivity.transactions ?? []).map((t) => ({
          timestamp: t.transactedAt ? parseISO(t.transactedAt) : t.transactedAt,
          event: t,
        })),
        ...(patientActivity.paymentIntents ?? []).map((t) => ({
          timestamp: parseISO(t.createdAt),
          event: t,
        })),
      ].sort((a, b) => compareDesc(a.timestamp, b.timestamp)),
    [patientActivity]
  );

  return (
    <ul role="list">
      {activities.map((activity, i) => (
        <li key={activity.event.id}>
          <div className="relative pb-4 text-gray-900">
            {i !== activities.length - 1 ? (
              <span
                className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />
            ) : null}
            <div className="relative flex items-start space-x-3">
              <ActivityFeedItem activity={activity} patient={patient} />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const FeedTimeline: React.FC<
  React.PropsWithChildren<{ patient: Patient }>
> = ({ patient }) => {
  const { loading, data } = useQuery<
    GetPatientActivity,
    GetPatientActivityVariables
  >(GET_PATIENT_ACTIVITY, {
    variables: {
      id: patient.id,
    },
  });

  if (loading || !data) return <div>Loading...</div>;
  return <MemoizedActivityFeed patient={patient} patientActivity={data} />;
};

export const ActivityFeed: React.FC<
  React.PropsWithChildren<{ patient: Patient }>
> = ({ patient }) => {
  return (
    <div>
      <div className="pb-3">
        <BalanceSummary patient={patient} />
      </div>
      <h2 className="text-xl pb-2">Recent Activity</h2>
      <FeedTimeline patient={patient} />
    </div>
  );
};
