import React, { useEffect } from "react";
import { useAnalytics } from "./analytics-context";
import { useAuth } from "./auth-context";
import { User } from "./auth-context";
import { Rings } from "./components/loading";
import { StripeProvider } from "./stripe";
import { useApolloClient } from "@apollo/client";

const UserContext = React.createContext<User | null>(null);

export const UserProvider: React.FC<React.PropsWithChildren<unknown>> = (
  props
) => {
  const {
    login,
    data: { user },
  } = useAuth();
  const analytics = useAnalytics();
  const client = useApolloClient();
  useEffect(() => {
    if (user) {
      // Identify the logged in user
      analytics?.identify(user.id, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      });
    }
  }, [user]);
  if (!user) {
    login();
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );
  }

  const activeLocation = user.activeLocation;
  // Assumes first integration is the external ledger source
  const externalLedgerName =
    activeLocation?.integrations?.at(0)?.name ?? "External";

  return (
    <StripeProvider organizationId={user!.organization.id}>
      <UserContext.Provider
        value={{ ...user, externalLedgerName }}
        {...props}
      />
    </StripeProvider>
  );
};

export const useUser = () => React.useContext(UserContext);
