import React from "react";
import { gql, useQuery } from "@apollo/client";
import {
  CheckCircleIcon,
  CurrencyDollarIcon,
  DeviceMobileIcon,
  KeyIcon,
  LightningBoltIcon,
  MailIcon,
  PaperAirplaneIcon,
  PlusIcon,
  ScaleIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import {
  compareAsc,
  compareDesc,
  format,
  isPast,
  parseISO,
  subSeconds,
} from "date-fns";

import {
  formatDateMMDDYYYY,
  formatUSD,
  isDefined,
  mapNullable,
  toDateMMDDYYYY,
} from "../../../utils";
import { Tooltip, Card } from "../../../components";
import { BillState } from "../../../generated/globalTypes";
import {
  billCommunicationTypeDisplay,
  communicationErrorDisplay,
  communicationTypeDisplay,
} from "../activity-feed";
import { SlideOut } from "../../../components/slideout";
import {
  GetBillDetails_bill_charges_transaction_chargeAllocations_paymentTransaction as PaymentTransaction,
  GetBillDetails_bill_charges as Charge,
} from "../../../generated/GetBillDetails";
import { Timeline, estimatedChargesDisplay, Event } from "../show";
import { BILL_CHARGE_FIELDS, DETAILED_ESTIMATE_FIELDS } from "../../../graphql";
import {
  GetVisitDetails,
  GetVisitDetailsVariables,
  GetVisitDetails_appointment_bill_charges_insuranceBillableCharges as InsuranceBillableCharge,
} from "../../../generated/GetVisitDetails";
import { VisitBillDisplayCard } from "../../shared/visit-bill-display-card";
import { Badge } from "../../../components/ui/badge";

const GET_VISIT_DETAILS = gql`
  ${DETAILED_ESTIMATE_FIELDS}
  ${BILL_CHARGE_FIELDS}
  query GetVisitDetails($id: String!) {
    appointment(where: { id: $id }) {
      id
      start
      end
      status
      appointmentLabelings {
        id
        appointmentLabel {
          id
          name
        }
      }
      account {
        id
        patient {
          id
          displayName
          paymentMethods(where: { detatchedAt: { equals: null } }) {
            id
            default
            type
            expirationMonth
            expirationYear
            cardBrand
            lastFour
          }
        }
      }
      provider {
        id
        displayName
      }
      insurancePolicies {
        id
        memberId
        payer {
          id
          name
        }
      }
      accumulatorAdjustments {
        id
        type
        insurancePolicy {
          id
          memberId
          payer {
            id
            name
          }
        }
        benefitAccumulatorAdjustments {
          id
          networkStatus
          coverageLevel
          providerServiceConfiguration {
            id
            name
          }
          appliedVisits
          appliedDeductible
          appliedOutOfPocket
        }
      }
      bill {
        id
        ...BillChargeFields
        billCode
        status
        communicationStatus
        dateOfService
        dateOfServiceDisplay
        dueDate
        firstBilledAt
        lastAdjudicatedAt
        inReviewOverrideOn
        patientBalance
        patientPaidTotal
        communications(orderBy: [{ sentAt: { sort: desc, nulls: last } }]) {
          id
          createdAt
          sentAt
          type
          contentType
          lastErrorType
          lastErrorReasonDisplay
        }
        charges {
          id
          customCode
          units
          insuranceBillableCharges {
            id
            status
            billedAt
            paidAt
            accountCoverage {
              id
              insurancePolicy {
                id
                memberId
                payer {
                  id
                  name
                }
              }
            }
          }
        }
        estimates(where: { status: { not: { equals: Voided } } }) {
          ...DetailedEstimateFields
        }
        paymentRequestTargets {
          paymentRequest {
            usedAccessCodeAt
            id
          }
        }
      }
    }
  }
`;

export const VisitSlideout: React.FC<
  React.PropsWithChildren<{
    appointmentId: string;
    onClose: () => void;
  }>
> = ({ appointmentId, onClose }) => {
  const { data, loading, error } = useQuery<
    GetVisitDetails,
    GetVisitDetailsVariables
  >(GET_VISIT_DETAILS, {
    variables: { id: appointmentId },
  });

  if (!data || loading) {
    return null;
  }

  const appointment = data.appointment!;
  const bill = appointment.bill.at(0);

  const estimates = bill?.estimates || [];
  const provider = bill?.primaryProvider ?? appointment.provider;

  const dateOfService = mapNullable(parseISO)(
    appointment.start ?? bill?.dateOfService
  );

  // TODO: add pendingAt to bill table
  const chargesEnteredEvent =
    bill && bill.charges && bill.charges.length > 0
      ? [
          {
            id: bill.charges[0].id,
            timestamp: parseISO(bill.charges[0].transaction.transactedAt),
            dateDisplay: format(
              parseISO(bill.charges[0].transaction.transactedAt),
              "MM/dd/yyyy hh:mm a"
            ),
            description: `Charges entered - ${bill.charges
              .map((c) => c.customCode)
              .filter(isDefined)
              .join(", ")}`,
            bgColorClass: "bg-gray-400",
            icon: <PlusIcon className="w-5 h-5 text-white" />,
          },
        ]
      : [];

  const uniqueBilledCharges = new Map<string, InsuranceBillableCharge>();

  const charges = bill?.charges ?? [];
  for (const charge of charges) {
    for (const billableCharge of charge.insuranceBillableCharges) {
      const accountCoverage = billableCharge.accountCoverage;
      if (!accountCoverage) continue;
      const existing = uniqueBilledCharges.get(accountCoverage.id);

      // If billableCharge is paid after existing charge, replace it
      // Or if billableCharge and existing both not paid, if billableCharge is billed after existing charge, replace it
      if (existing) {
        if (
          (billableCharge.paidAt &&
            existing.paidAt &&
            billableCharge.paidAt > existing.paidAt) ||
          (!billableCharge.paidAt &&
            !existing.paidAt &&
            billableCharge.billedAt > existing.billedAt)
        ) {
          uniqueBilledCharges.set(accountCoverage.id, billableCharge);
        }
      } else {
        uniqueBilledCharges.set(accountCoverage.id, billableCharge);
      }
    }
  }

  const claimBilledEvents = Array.from(uniqueBilledCharges)
    .filter(([id, insuranceBillableCharge]) => insuranceBillableCharge.billedAt)
    .map(([id, insuranceBillableCharge]) => {
      return {
        id: id + "-insurance-billed",
        timestamp: parseISO(insuranceBillableCharge.billedAt),
        dateDisplay: format(
          parseISO(insuranceBillableCharge.billedAt),
          "MM/dd/yyyy hh:mm a"
        ),
        description:
          "Claim billed to " +
            insuranceBillableCharge.accountCoverage!.insurancePolicy.payer
              .name ?? "Insurance",
        bgColorClass: "bg-gray-400",
        icon: <PaperAirplaneIcon className="w-5 h-5 text-white" />,
      };
    });
  const claimAdjudicatedEvents = Array.from(uniqueBilledCharges)
    .filter(([id, insuranceBillableCharge]) => insuranceBillableCharge.paidAt)
    .map(([id, insuranceBillableCharge]) => {
      return {
        id: id + "-insurance-adjudicated",
        timestamp: parseISO(insuranceBillableCharge.paidAt),
        dateDisplay: format(
          parseISO(insuranceBillableCharge.paidAt),
          "MM/dd/yyyy hh:mm a"
        ),
        description:
          "Claim paid by " +
            insuranceBillableCharge.accountCoverage!.insurancePolicy.payer
              .name ?? "Insurance",
        bgColorClass: "bg-gray-400",
        icon: <ScaleIcon className="w-5 h-5 text-white" />,
      };
    });

  const billPayments = (bill?.billPayments ?? []).flatMap((bp) => {
    let billPaymentEvents = [];
    if (bp.paymentTransaction.paymentAllocations.length > 0) {
      for (const pa of bp.paymentTransaction.paymentAllocations) {
        // If a payment is allocated to a charge that is not on this bill, then add an event
        if (pa.chargeTransaction.charge?.billId !== bill?.id) {
          billPaymentEvents.push({
            id: pa.id,
            timestamp: parseISO(pa.createdAt),
            dateDisplay: format(parseISO(pa.createdAt), "MM/dd/yyyy hh:mm a"),
            description: (
              <>
                <span className="font-medium text-gray-700">
                  {formatUSD(pa.amount)}
                </span>{" "}
                of {formatDateMMDDYYYY(bp.paymentTransaction.transactedAt)}{" "}
                payment reallocated towards charges{" "}
                {pa.chargeTransaction.customCode} on{" "}
                {formatDateMMDDYYYY(pa.chargeTransaction.transactedAt)}
              </>
            ),
            bgColorClass: "bg-gray-400",
            icon: <CurrencyDollarIcon className="w-5 h-5 text-white" />,
          });
        }
      }
    }
    const refunds = bp.paymentTransaction.payment?.refundedByPayments || [];
    for (const refund of refunds) {
      billPaymentEvents.push({
        id: refund.id,
        timestamp: parseISO(refund.transaction.transactedAt),
        dateDisplay: format(
          parseISO(refund.transaction.transactedAt),
          "MM/dd/yyyy hh:mm a"
        ),
        description: (
          <>
            <span className="font-medium text-gray-700">
              {formatUSD(-refund.patientAmount)}
            </span>{" "}
            of {formatDateMMDDYYYY(bp.paymentTransaction.transactedAt)} payment
            refunded
          </>
        ),
        bgColorClass: "bg-gray-400",
        icon: <CurrencyDollarIcon className="w-5 h-5 text-white" />,
      });
    }
    billPaymentEvents.push({
      id: bp.id,
      // Subtract a second to ensure this event is sorted before the charge payments
      timestamp: subSeconds(parseISO(bp.createdAt), 1),
      dateDisplay: format(parseISO(bp.createdAt), "MM/dd/yyyy hh:mm a"),
      description: (
        <>
          Payment of{" "}
          <span className="font-medium text-gray-700">
            {formatUSD(bp.amount)}
          </span>{" "}
          towards bill
        </>
      ),
      bgColorClass: "bg-gray-400",
      icon: <CurrencyDollarIcon className="w-5 h-5 text-white" />,
    });
    return billPaymentEvents;
  });

  const chargePayments = (bill?.charges ?? []).flatMap((c) =>
    c.transaction.chargeAllocations.map((ca) => ({ ...ca, charge: c }))
  );
  const lastPayment = chargePayments.sort((a, b) =>
    compareDesc(
      a.paymentTransaction.transactedAt,
      b.paymentTransaction.transactedAt
    )
  )[0];
  const payments = chargePayments
    // Filter out any payments that are already included in billPayments
    .filter(
      (cp) =>
        !(bill?.billPayments ?? []).some(
          (bp) => bp.paymentTransaction.id === cp.paymentTransaction.id
        )
    )
    .reduce(
      (acc, cp) => {
        const id = cp.paymentTransaction.id;
        const payment = acc[id] ?? {
          amount: 0,
          charges: [],
          paymentTransaction: cp.paymentTransaction,
        };
        return {
          ...acc,
          [id]: {
            ...payment,
            amount: payment.amount + cp.amount,
            charges: [...payment.charges, cp.charge],
          },
        };
      },
      {} as {
        [paymentId: string]: {
          amount: number;
          paymentTransaction: PaymentTransaction;
          charges: Charge[];
        };
      }
    );

  const billPaidEvent =
    bill &&
    (bill.status === BillState.Reconciled ||
      bill.status === BillState.Resolved) &&
    (bill.paidAt || lastPayment)
      ? [
          {
            id: bill.id + "-paid",
            timestamp: parseISO(
              bill.paidAt ?? lastPayment.paymentTransaction.transactedAt
            ),
            dateDisplay: format(
              parseISO(
                bill.paidAt ?? lastPayment.paymentTransaction.transactedAt
              ),
              "MM/dd/yyyy hh:mm a"
            ),
            description: "Bill paid",
            bgColorClass: "bg-green-400",
            icon: <CheckCircleIcon className="w-5 h-5 text-white" />,
          },
        ]
      : [];

  const paymentEvents = Object.entries(payments).map(([id, payment]) => ({
    id,
    timestamp: parseISO(payment.paymentTransaction.transactedAt),
    dateDisplay: format(
      parseISO(payment.paymentTransaction.transactedAt),
      "MM/dd/yyyy hh:mm a"
    ),
    description: (
      <>
        Payment of{" "}
        <span className="font-medium text-gray-700">
          {formatUSD(payment.amount)}
        </span>{" "}
        towards charges -{" "}
        {payment.charges
          .map((c) => c.customCode)
          .filter(isDefined)
          .join(", ")}
      </>
    ),
    bgColorClass: "bg-gray-400",
    icon: <CurrencyDollarIcon className="w-5 h-5 text-white" />,
  }));
  const communicationEvents = (bill?.communications ?? []).map((c) => ({
    id: c.id,
    timestamp: parseISO(c.sentAt),
    dateDisplay: format(parseISO(c.sentAt), "MM/dd/yyyy hh:mm a"),
    description: (
      <>
        {billCommunicationTypeDisplay(c.contentType)} sent via{" "}
        {communicationTypeDisplay(c.type)}
        {c.lastErrorType && (
          <>
            {" "}
            but was
            <Tooltip
              trigger={
                <span
                  className={`inline-flex ml-1 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800`}
                >
                  {communicationErrorDisplay(c.lastErrorType)}
                </span>
              }
              content={
                <div className="max-w-xl">{c.lastErrorReasonDisplay}</div>
              }
            />
          </>
        )}
      </>
    ),
    bgColorClass: "bg-gray-400",
    icon:
      c.type === "EMAIL" ? (
        <MailIcon className="w-5 h-5 text-white" />
      ) : (
        <DeviceMobileIcon className="w-5 h-5 text-white" />
      ),
  }));
  const estimateEvents = estimates
    .map((e) => {
      if (e.supercedes) {
        return [
          {
            id: e.id,
            timestamp: parseISO(e.createdAt),
            dateDisplay: format(parseISO(e.createdAt), "MM/dd/yyyy hh:mm a"),
            description: (
              <>
                Re-estimated bill with charges{" "}
                {estimatedChargesDisplay(e.estimatedCharges)} totaling{" "}
                <span className="font-medium text-gray-700">
                  {formatUSD(e.totalPatientResponsibility)}
                </span>
              </>
            ),
            bgColorClass: "bg-gray-400",
            icon: <LightningBoltIcon className="w-5 h-5 text-white" />,
          },
        ];
      }
      return [
        {
          id: e.id,
          timestamp: parseISO(e.createdAt),
          dateDisplay: format(parseISO(e.createdAt), "MM/dd/yyyy hh:mm a"),
          description: (
            <>
              Estimated bill with charges{" "}
              {estimatedChargesDisplay(e.estimatedCharges)} totaling{" "}
              <span className="font-medium text-gray-700">
                {formatUSD(e.totalPatientResponsibility)}
              </span>
            </>
          ),
          bgColorClass: "bg-gray-400",
          icon: <LightningBoltIcon className="w-5 h-5 text-white" />,
        },
      ];
    })
    .flat();
  const paymentRequestEvents = bill?.paymentRequestTargets
    ? bill.paymentRequestTargets.flatMap(({ paymentRequest }) =>
        paymentRequest.usedAccessCodeAt
          ? [
              {
                id: paymentRequest.id,
                timestamp: parseISO(paymentRequest.usedAccessCodeAt),
                dateDisplay: format(
                  parseISO(paymentRequest.usedAccessCodeAt),
                  "MM/dd/yyyy hh:mm a"
                ),
                description: "Access code for portal used",
                bgColorClass: "bg-gray-400",
                icon: <KeyIcon className="w-5 h-5 text-white" />,
              },
            ]
          : []
      )
    : [];

  const inReviewOverrideEvent =
    bill &&
    bill.inReviewOverrideOn &&
    [BillState.InReview, BillState.Ready].includes(bill.status)
      ? [
          {
            id: bill.id + "-inreview-override",
            timestamp: parseISO(bill.inReviewOverrideOn),
            dateDisplay: format(
              parseISO(bill.inReviewOverrideOn),
              "MM/dd/yyyy hh:mm a"
            ),
            description: "User override status to In Review",
            bgColorClass: "bg-gray-400",
            icon: <UserCircleIcon className="w-5 h-5 text-white" />,
          },
        ]
      : [];

  const events: Event[] = [
    ...estimateEvents,
    {
      id: appointment.id,
      timestamp: parseISO(appointment?.start ?? bill?.dateOfService),
      dateDisplay: format(
        parseISO(appointment?.start ?? bill?.dateOfService),
        "MM/dd/yyyy hh:mm a"
      ),
      description: `Visit with ${provider?.displayName ?? null}`,
      bgColorClass: "bg-gray-400",
      icon: <UserCircleIcon className="w-5 h-5 text-white" />,
    },
    ...chargesEnteredEvent,
    ...claimBilledEvents,
    ...claimAdjudicatedEvents,
    ...communicationEvents,
    ...billPayments,
    ...paymentEvents,
    ...billPaidEvent,
    ...inReviewOverrideEvent,
    ...paymentRequestEvents,
  ].sort((a, b) => compareAsc(a.timestamp, b.timestamp));

  return (
    <SlideOut
      open={true}
      onClose={onClose}
      title={
        <div className="flex flex-col">
          <div className="text-sm">Appointment Details</div>
        </div>
      }
    >
      <div className="flex flex-col space-y-2">
        <Card>
          <div className="flex flex-col divide-y gap-2 w-full">
            <div>
              <h1 className="truncate text-lg font-medium text-gray-900">
                Visit Summary
              </h1>
            </div>
            <div className="pt-2">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Provider
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {provider?.displayName}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Account</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {bill?.account?.accountType?.name}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                  <dd className="mt-1 text-sm text-gray-900 flex flex-col gap-1">
                    {appointment.status}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Date of Service
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {mapNullable((date: Date) => format(date, "MMM d, yyyy"))(
                      dateOfService
                    )}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Appointment Type
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {appointment.appointmentLabelings.map((labeling) => (
                      <Badge
                        key={labeling.appointmentLabel.id}
                        className="px-1 py-[1px] font-normal"
                      >
                        {labeling.appointmentLabel.name}
                      </Badge>
                    ))}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Due Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {bill?.dueDate && toDateMMDDYYYY(bill?.dueDate)}{" "}
                    {bill?.dueDate && isPast(parseISO(bill?.dueDate)) && (
                      <span className="ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                        Past Due
                      </span>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </Card>

        <VisitBillDisplayCard appointment={appointment} bill={bill} />

        <Card>
          <div className="flex flex-col divide-y gap-2 w-full">
            <div>
              <h1 className="truncate text-lg font-medium text-gray-900">
                Bill Timeline
              </h1>
            </div>
            <div className="pt-2">
              <Timeline events={events} />
            </div>
          </div>
        </Card>
      </div>
    </SlideOut>
  );
};
