import { AppointmentsRow, InsuranceVerificationCellRow } from "../columns";
import { Card, Tooltip } from "../../../../components";
import { WorkflowStepStatus, WorkflowStepStatusDisplay } from ".";
import {
  InsurancePolicyPriorityBadge,
  REQUEST_APPOINTMENT_ELIGIBILITY,
} from "../..";
import { Link } from "react-router-dom";
import { CheckCircleIcon, ClockIcon } from "@heroicons/react/outline";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import { Button } from "../../../../components/ui/button";
import { ClipboardCheckIcon, MoreHorizontal } from "lucide-react";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { formatDateMMDDYYYY } from "../../../../utils";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { OvalSpinner } from "../../../../components/loading";
import {
  RequestAppointmentEligibility,
  RequestAppointmentEligibilityVariables,
} from "../../../../generated/RequestAppointmentEligibility";
import { GetLocalAppointments_localAppointments_appointment_accountCoverages_insurancePolicy as InsurancePolicy } from "../../../../generated/GetLocalAppointments";

export const VerificationWorkflowHoverCardContent: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const policies = row.policies;
  const primary = policies.at(0);

  return (
    <Card>
      <div className="flex flex-col gap-y-1">
        <div className="flex items-center gap-2">
          <h2 className="font-semibold">Insurance Verification</h2>
          <WorkflowStepStatusDisplay status={status} />
        </div>
        <div className="text-wrap space-y-1">
          {primary?.active && row.appointment.nextScheduledVerificationDate && (
            <div>
              Automated verification scheduled for{" "}
              {formatDateMMDDYYYY(
                row.appointment.nextScheduledVerificationDate
              )}
              .
            </div>
          )}
          {primary?.terminationDate && (
            <div>
              Policy termed on {formatDateMMDDYYYY(primary.terminationDate)}.
            </div>
          )}
          <div className="flex flex-col">
            <h2 className="font-medium border-b">Policies</h2>

            {policies.length === 0 ? (
              <div className="italic text-gray-500">
                No policies available for account.
              </div>
            ) : (
              <div className="flex flex-col divide-y py-1">
                {policies.map((policy) => {
                  return (
                    <div key={policy.id} className="grid grid-cols-4 gap-2">
                      <div className="flex items-center gap-1 col-span-1">
                        {policy.priority && (
                          <InsurancePolicyPriorityBadge
                            priority={policy.priority}
                          />
                        )}
                        <div className="truncate">{policy.payer.name}</div>
                      </div>
                      <div className="flex items-center gap-1 truncate">
                        <Link
                          to={`../patients/${row.patientId}/insurances/${policy.id}`}
                          className="hover:text-gray-600"
                        >
                          {policy.memberId ? (
                            policy.memberId
                          ) : (
                            <span className="italic text-gray-400">
                              No Member ID
                            </span>
                          )}
                        </Link>
                      </div>
                      <div className="flex items-center gap-1 col-span-2">
                        <InsuranceVerificationCellRow
                          insurancePolicy={policy}
                          eligibilityRequest={
                            policy.mostRecentEligibilityRequest
                          }
                          appointment={row.appointment}
                          isPastDay={false}
                          referenceDate={new Date()}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

const VerifyDropdownMenuAction: React.FC<
  React.PropsWithChildren<{
    row: AppointmentsRow;
    policy: InsurancePolicy;
  }>
> = ({ row, policy, children }) => {
  const [requestAppointmentEligibility, requestAppointmentEligibilityResult] =
    useMutation<
      RequestAppointmentEligibility,
      RequestAppointmentEligibilityVariables
    >(REQUEST_APPOINTMENT_ELIGIBILITY, {});

  const eligibilityLoading = requestAppointmentEligibilityResult.loading;

  return (
    <DropdownMenuItem
      onClick={() => {
        requestAppointmentEligibility({
          variables: {
            appointmentId: row.appointment.id,
            insurancePolicyId: policy.id,
            // The date to check reverification status on
            referenceDate: row.appointment.start,
          },
          onCompleted: () => {
            toast.success("Eligibility request successful");
          },
          onError: () => {
            toast.error("Failed to check eligibility");
          },
        });
      }}
      disabled={eligibilityLoading || !policy.payer.eligibilityEnabled}
    >
      {eligibilityLoading && <OvalSpinner className="h-4 w-4" />}
      {children}
    </DropdownMenuItem>
  );
};

const VerifyButton: React.FC<
  React.PropsWithChildren<{
    row: AppointmentsRow;
    policy: InsurancePolicy;
  }>
> = ({ row, policy, children }) => {
  const [requestAppointmentEligibility, requestAppointmentEligibilityResult] =
    useMutation<
      RequestAppointmentEligibility,
      RequestAppointmentEligibilityVariables
    >(REQUEST_APPOINTMENT_ELIGIBILITY, {});

  const eligibilityLoading = requestAppointmentEligibilityResult.loading;

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={(e) => {
        // Prevent opening the appointment slideout
        e.stopPropagation();
        e.preventDefault();
        requestAppointmentEligibility({
          variables: {
            appointmentId: row.appointment.id,
            insurancePolicyId: policy.id,
            // The date to check reverification status on
            referenceDate: row.appointment.start,
          },
          onCompleted: () => {
            toast.success("Eligibility request successful");
          },
          onError: () => {
            toast.error("Failed to check eligibility");
          },
        });
      }}
      disabled={eligibilityLoading || !policy.payer.eligibilityEnabled}
      className="flex items-center gap-1"
    >
      {eligibilityLoading ? (
        <OvalSpinner className="h-3 w-3" />
      ) : (
        <ClipboardCheckIcon className="h-4 w-4" />
      )}
      {children}
    </Button>
  );
};

export const VerificationWorkflowNextAction: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const policies = row.policies;
  const primary = policies.at(0);

  if (status === "not_started") {
    if (primary) {
      return (
        <div className="flex justify-between items-center gap-1">
          <VerifyButton row={row} policy={primary}>
            Verify Eligibility
          </VerifyButton>
        </div>
      );
    }
  } else if (status === "skipped") {
    if (primary) {
      return (
        <div className="flex justify-between items-center gap-1">
          <VerifyButton row={row} policy={primary}>
            {primary.mostRecentEligibilityRequest ? "Re-verify" : "Verify"}{" "}
            Eligibility
          </VerifyButton>
        </div>
      );
    }
  } else if (status === "action_required") {
    if (primary) {
      return (
        <div className="flex justify-between items-center gap-1">
          <div className="flex items-center gap-1">
            <VerifyButton row={row} policy={primary}>
              {primary.mostRecentEligibilityRequest ? "Re-verify" : "Verify"}{" "}
              Eligibility
            </VerifyButton>
          </div>
          {policies.length > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button aria-haspopup="true" size="icon" variant="ghost">
                  <MoreHorizontal className="h-4 w-4" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="end"
                onClick={(e) => {
                  // Prevent opening the appointment slideout
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {policies.map((policy) => (
                  <VerifyDropdownMenuAction
                    key={policy.id}
                    row={row}
                    policy={policy}
                  >
                    {policy.mostRecentEligibilityRequest
                      ? "Re-verify"
                      : "Verify"}{" "}
                    {policy.payer.name} - {policy.memberId}
                  </VerifyDropdownMenuAction>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      );
    }
  } else if (status === "scheduled") {
    return (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <Tooltip
            content={
              <>
                Automated verification scheduled for{" "}
                {formatDateMMDDYYYY(
                  row.appointment.nextScheduledVerificationDate
                )}
              </>
            }
            trigger={
              <div className="flex items-center gap-1">
                <ClockIcon className="h-4 w-4 text-gray-400" />
                Verification Scheduled
              </div>
            }
          />
        </div>
        {policies.length > 0 && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button aria-haspopup="true" size="icon" variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
                <span className="sr-only">Toggle menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              onClick={(e) => {
                // Prevent opening the appointment slideout
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {policies.map((policy) => (
                <VerifyDropdownMenuAction
                  key={policy.id}
                  row={row}
                  policy={policy}
                >
                  {policy.mostRecentEligibilityRequest ? "Re-verify" : "Verify"}{" "}
                  {policy.payer.name} - {policy.memberId}
                </VerifyDropdownMenuAction>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    );
  } else if (status === "error") {
    // TODO: Handle specific verification errors
    const eligibilityErrors =
      primary?.mostRecentEligibilityRequest?.deduplicatedErrors ?? [];
    return (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <Tooltip
            content={
              <ul className="list-disc">
                {eligibilityErrors.map((e) => (
                  <li key={e.id}>{e.description}</li>
                ))}
              </ul>
            }
            trigger={
              <div className="flex items-center gap-1">
                <ExclamationTriangleIcon className="h-4 w-4 text-red-400" />
                Verification Error
              </div>
            }
          />
        </div>
        {policies.length > 0 && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button aria-haspopup="true" size="icon" variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
                <span className="sr-only">Toggle menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {policies.map((policy) => (
                <VerifyDropdownMenuAction
                  key={policy.id}
                  row={row}
                  policy={policy}
                >
                  {policy.mostRecentEligibilityRequest ? "Re-verify" : "Verify"}{" "}
                  {policy.payer.name} - {policy.memberId}
                </VerifyDropdownMenuAction>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    );
  }

  return null;
};
