import React, { createContext, useRef } from "react";
import {
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
} from "./components/ui/resizable";
import { ImperativePanelHandle } from "react-resizable-panels";

export const useDashboardResizable = () => {
  const context = React.useContext(DashboardResizableContext);
  if (context === undefined) {
    throw new Error(
      "useDashboardResizable must be used within a DashboardResizableProvider"
    );
  }
  return context;
};

const DashboardResizableContext = createContext<
  | {
      setPanelChild: React.Dispatch<React.SetStateAction<React.ReactNode>>;
      collapse: () => void;
    }
  | undefined
>(undefined);

export const DashboardResizable: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const ref = useRef<ImperativePanelHandle>(null);
  const [panelChild, setPanelChild] = React.useState<React.ReactNode>(null);

  const collapse = () => {
    ref.current?.collapse();
    setPanelChild(null);
  };

  return (
    <DashboardResizableContext.Provider
      value={{
        collapse,
        setPanelChild: (child) => {
          ref.current?.resize(33);
          setPanelChild(child);
        },
      }}
    >
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel className="!overflow-auto">{children}</ResizablePanel>
        <ResizableHandle withHandle={!!panelChild} disabled={!panelChild} />
        <ResizablePanel
          collapsible
          defaultSize={0}
          className="relative"
          ref={ref}
        >
          {panelChild}
        </ResizablePanel>
      </ResizablePanelGroup>
    </DashboardResizableContext.Provider>
  );
};
