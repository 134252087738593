import { useRef, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useUser } from "./user-context";
import { IntegrationType } from "./generated/globalTypes";
import { isDefined } from "./utils";

export const usePrevious = <T extends any>(value?: T) => {
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useUtmParams = () => {
  const params = useQueryParams();
  const source = params.get("utm_source");
  const medium = params.get("utm_medium");
  const campaign = params.get("utm_campaign");
  return { source, medium, campaign };
};

export type FeatureFlags = {
  // Whether the location has an integration that supports charges
  chargesSupported: boolean;
  // Whether the location has time of service collections enabled
  tosCollectionEnabled: boolean;
  // Whether the location has post-visit billing enabled
  postVisitBillingEnabled: boolean;
  benefitsProductEnabled: boolean;
  /**
   * Whether estimate creation is enabled
   */
  estimatesEnabled: boolean;
  automatedEstimatesEnabled: boolean;
  /**
   * Whether automated eligibility verification is enabled
   */
  automatedEligibilityVerificationEnabled: boolean;
  showMessagesTab: boolean;
  showTasksTab: boolean;
  patientAccountLinkingEnabled: boolean;
  appointmentsPageEnabled: boolean;
  // TODO: General approach to saving column preferences
  // Whether or not the Policy Confirmed column shows by default
  defaultPolicyConfirmedColumnInAppointmentList: boolean;
  verificationWorkflowEnabled: boolean;
  estimationWorkflowEnabled: boolean;
  stripeAccountActive: boolean;
  automatedAccumulatorTrackingEnabled: boolean;
  preVisitReminderEnabled: boolean;
  automatedPreVisitReminderEnabled: boolean;
  automatedTimeOfServiceChargingEnabled: boolean;
  showNotes: boolean;
  showBenefitsSettingsPage: boolean;
  useNewBillingDashboard: boolean;
};

export const useFeatureFlags = (): FeatureFlags => {
  const user = useUser();
  if (!user) {
    return {
      chargesSupported: false,
      tosCollectionEnabled: false,
      postVisitBillingEnabled: false,
      benefitsProductEnabled: false,
      estimatesEnabled: false,
      automatedEstimatesEnabled: false,
      automatedEligibilityVerificationEnabled: false,
      showMessagesTab: false,
      showTasksTab: false,
      patientAccountLinkingEnabled: false,
      appointmentsPageEnabled: false,
      defaultPolicyConfirmedColumnInAppointmentList: false,
      verificationWorkflowEnabled: false,
      estimationWorkflowEnabled: false,
      stripeAccountActive: false,
      automatedAccumulatorTrackingEnabled: false,
      preVisitReminderEnabled: false,
      automatedPreVisitReminderEnabled: false,
      automatedTimeOfServiceChargingEnabled: false,
      showNotes: false,
      showBenefitsSettingsPage: false,
      useNewBillingDashboard: false,
    };
  }

  const isPledgeUser = user.email.endsWith("@pledge.health");
  const isVidaUser =
    user.organization.id === "0ddbe70f-24f7-4c69-96dd-b7e694724239";
  const isHQChiroUser =
    user.organization.id === "18cebce7-0131-4e7f-86f1-751f3dda2256";
  const isTangeloUser =
    user.organization.id === "1abe0ead-ff82-48c7-802b-677e86044c4b";

  // Can turn on setup mode if estimates are enabled
  const estimateSetupMode =
    user.activeLocation.estimateSetupMode && user.organization.estimatesEnabled;

  // If in setup mode, only admins can access estimates
  const adminEstimateAccess = estimateSetupMode && !!user.isAdmin;

  // If in setup mode, only admins can access estimates
  const estimatesEnabled = estimateSetupMode
    ? adminEstimateAccess
    : user.organization.estimatesEnabled;
  const estimationWorkflowEnabled = estimateSetupMode
    ? adminEstimateAccess
    : user.activeLocation.estimationWorkflowEnabled;

  // TODO: move this to the integrations table
  const chargesSupported = user.activeLocation.integrations.some((i) =>
    [
      IntegrationType.Chirotouch,
      IntegrationType.Elation,
      // TODO: When the eCW billing integration is fixed, uncomment this
      // IntegrationType.EClinicalWorks,
    ].includes(i.type)
  );
  const tosCollectionEnabled =
    estimatesEnabled || user.organization.billingProductEnabled;
  const postVisitBillingEnabled = user.organization.billingProductEnabled;
  const stripeAccountActive = !!user.organization.stripeAccountId;

  const preVisitReminderEnabled = user.activeLocation.preVisitReminderEnabled;
  const appointmentsPageEnabled = user.organization.appointmentsPageEnabled;

  return {
    chargesSupported,
    tosCollectionEnabled,
    postVisitBillingEnabled,
    benefitsProductEnabled: user.organization.benefitsProductEnabled,
    // - Rules, Chargemasters, Estimates
    estimatesEnabled,
    automatedEstimatesEnabled: user.activeLocation.automatedEstimatesEnabled,
    automatedEligibilityVerificationEnabled:
      user.activeLocation.automatedEligibilityVerificationEnabled,
    showMessagesTab: false,
    // For now only tasks is external autopay
    showTasksTab: user.activeLocation.externalAutopayEnabled,
    patientAccountLinkingEnabled: true,
    appointmentsPageEnabled,
    defaultPolicyConfirmedColumnInAppointmentList: isHQChiroUser,
    // - Verification worklist, benefit mapping rules
    verificationWorkflowEnabled:
      user.activeLocation.verificationWorkflowEnabled,
    estimationWorkflowEnabled,
    stripeAccountActive,
    automatedAccumulatorTrackingEnabled:
      user.activeLocation.automatedAccumulatorTrackingEnabled,
    preVisitReminderEnabled,
    automatedPreVisitReminderEnabled: isDefined(
      user.activeLocation.preVisitReminderDaysAhead
    ),
    automatedTimeOfServiceChargingEnabled:
      user.activeLocation.automatedTimeOfServiceChargingEnabled,
    // TODO: Enable this for all appointmentsPageEnabled users
    showNotes: isTangeloUser || isPledgeUser,
    // TODO: Temporarily only show this to internal users
    showBenefitsSettingsPage: isPledgeUser,
    useNewBillingDashboard: true,
  };
};

/**
 * If the component is currently mounted
 * From https://stackoverflow.com/questions/58979309/checking-if-a-component-is-unmounted-using-react-hooks
 */
export function useIsMounted() {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  return isMounted;
}
