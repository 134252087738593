import React, { useState } from "react";
import { Switch } from "@headlessui/react";

import { useAuth } from "../auth-context";
import { classNames } from "../utils";

export const DemoToggle: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    data: { demo },
    setDemo,
  } = useAuth();
  const [enabled, setEnabled] = useState(demo);

  const onChange = (val: boolean) => {
    setDemo(val);
    setEnabled(val);
  };

  return (
    <div className="flex justify-between items-center space-x-2 py-2 px-4">
      <span className="text-sm text-gray-700">Demo Mode</span>
      <Switch
        checked={enabled}
        onChange={onChange}
        className={classNames(
          enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        )}
      >
        <span className="sr-only">Demo Mode</span>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </div>
  );
};
