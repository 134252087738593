/**
 * Help text used in benefits dipaly
 */

export const DEDUCTIBLE_HELP_TEXT =
  "The dollar amount you spend on covered services each year before your insurance company begins sharing the cost of care. If you are on a family health plan, this applies if you hit either your individual or family deductible.";
export const OOP_HELP_TEXT =
  "The maximum dollar amount you spend on covered services in a plan year. Once you've hit this amount, your plan pays 100% of covered services. If you are on a family health plan, this applies if you hit either your individual or family max.";
export const VISIT_LIMIT_HELP_TEXT =
  "Once you've used your covered visits, you pay 100%, regardless of your deductible or out of pocket max.";
export const SERVICE_DEDUCTIBLE_HELP_TEXT =
  "Once you've met this deductible, your insurance will begin sharing the cost of care for this type of service regardless of whether you met your overall plan deductible.";
