import React, { useEffect, useRef, useState } from "react";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { parseISO, format, endOfDay, startOfDay } from "date-fns";
import { CSVLink } from "react-csv";

import { useUser } from "../../user-context";
import { Td } from "../../components/Table";
import {
  GetPledgePayments,
  GetPledgePaymentsVariables,
  GetPledgePayments_getPledgePayments_payments as Payment,
  GetPledgePayments_getPledgePayments_payments_transaction_paymentAllocations_chargeTransaction_charge_bill as Bill,
} from "../../generated/GetPledgePayments";
import { isDefined, formatUSD, toDateMMDDYYYY } from "../../utils";
import { Badge, Card, GroupingTable } from "../../components";
import { OvalSpinner } from "../../components/loading";
import { useAnalytics } from "../../analytics-context";
import { Layout } from "../layout";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@radix-ui/react-hover-card";
import { toast } from "react-toastify";
import { PostPayment, PostPaymentVariables } from "../../generated/PostPayment";
import { Button, ButtonSize, ButtonVariant } from "../../components/ui/button";
import {
  SkipPaymentPosting,
  SkipPaymentPostingVariables,
} from "../../generated/SkipPaymentPosting";

const PAGE_SIZE = 15;

const GET_PLEDGE_PAYMENTS = gql`
  query GetPledgePayments(
    $organizationId: String!
    $locationId: String!
    $startDate: DateTime
    $endDate: DateTime
    $take: Int
    $skip: Int
  ) {
    getPledgePayments(
      organizationId: $organizationId
      locationId: $locationId
      startDate: $startDate
      endDate: $endDate
      take: $take
      skip: $skip
    ) {
      payments {
        id
        postingStatus
        postedAt
        stripeConnectedAccount {
          id
          name
        }
        paymentIntent {
          id
          autoPay
        }
        transaction {
          transactedAt
          patientAmount
          account {
            id
            patient {
              id
              displayName
            }
          }
          paymentAllocations {
            id
            amount
            chargeTransaction {
              charge {
                id
                bill {
                  id
                  dateOfServiceDisplay
                  primaryProvider {
                    id
                    firstName
                    lastName
                    displayName
                  }
                  account {
                    id
                    accountType {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          billPayments {
            id
            createdAt
            amount
            paymentTransaction {
              id
              transactedAt
              paymentAllocations {
                id
                createdAt
                amount
              }
            }
            bill {
              id
              billCode
              dateOfServiceDisplay
              primaryProvider {
                id
                firstName
                lastName
                displayName
              }
              account {
                id
                accountType {
                  id
                  name
                }
              }
            }
          }
        }
      }
      total
    }
  }
`;

type BillGroup = {
  bill: Pick<Bill, "account" | "dateOfServiceDisplay" | "primaryProvider">;
  allocated: number;
};

const groupChargesByBill = (payment: Payment) => {
  const groups = [
    ...payment.transaction.paymentAllocations.map((pa) => ({
      bill: pa.chargeTransaction.charge?.bill,
      amount: pa.amount,
    })),
    ...payment.transaction.billPayments
      .filter((bp) => {
        const unAllocated =
          bp.amount -
          bp.paymentTransaction.paymentAllocations.reduce(
            (acc, pa) => acc + pa.amount,
            0
          );
        return unAllocated !== 0;
      })
      .map((bp) => {
        const unAllocated =
          bp.amount -
          bp.paymentTransaction.paymentAllocations.reduce(
            (acc, pa) => acc + pa.amount,
            0
          );
        return {
          bill: bp.bill,
          amount: unAllocated,
        };
      }),
  ].reduce((accum: { [id: string]: BillGroup }, { bill, amount }) => {
    if (!bill) return accum;
    const group = accum[bill.id] ?? {
      allocated: 0,
      charges: [],
    };
    group.bill = bill;
    group.allocated = group.allocated + amount;
    // group.charges = [...group.charges, pa.chargeTransaction];
    return { ...accum, [bill.id]: group };
  }, {});
  return Object.values(groups);
};

const DownloadPaymentsButton: React.FC<
  React.PropsWithChildren<{
    dateRange: DateRange;
  }>
> = ({ dateRange }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const [getPledgePayments, getPledgePaymentsResult] = useLazyQuery<
    GetPledgePayments,
    GetPledgePaymentsVariables
  >(GET_PLEDGE_PAYMENTS, {
    fetchPolicy: "network-only",
  });

  const csvRef = useRef<any>();
  const [csvData, setCsvData] = useState<any[] | null>(null);

  const orgDefaultStripeConnectedAccount =
    user.organization.stripeConnectedAccounts.find((sca) => sca.default);

  // Necessary to trigger the download after the data has been set async
  useEffect(() => {
    if (csvData && csvRef.current && csvRef.current.link) {
      setTimeout(() => {
        // // Click the hidden CSVLink to download the file
        csvRef.current.link.click();
        setCsvData(null);
      });
    }
  }, [csvData]);

  return (
    <>
      <button
        onClick={async () => {
          const { data } = await getPledgePayments({
            fetchPolicy: "network-only",
            variables: {
              organizationId: user!.organization.id,
              locationId: user!.activeLocation.id,
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
            },
          });
          let rows = (data?.getPledgePayments?.payments ?? []).map((r) => {
            const providers = [
              r.transaction.paymentAllocations.map(
                (pa) => pa.chargeTransaction.charge?.bill?.primaryProvider
              ),
              r.transaction.billPayments.map((bp) => bp.bill.primaryProvider),
            ].filter(isDefined);
            const uniqueProviders = Array.from(
              new Set(
                providers
                  .flat()
                  .map((p) =>
                    [p?.firstName, p?.lastName].filter(isDefined).join(" ")
                  )
              )
            ).join(", ");
            return {
              name: r.transaction.account.patient.displayName,
              date: format(
                parseISO(r.transaction.transactedAt),
                "MM/dd/yyyy hh:mm aa"
              ),
              amount: formatUSD(r.transaction.patientAmount),
              autoPay: r.paymentIntent?.autoPay ? "Yes" : "No",
              account:
                r.stripeConnectedAccount?.name ??
                orgDefaultStripeConnectedAccount?.name,
              providers: uniqueProviders,
            };
          });
          setCsvData(rows);
          analytics?.track("Payments Exported", {
            organizationId: user.organization.id,
            organizationName: user.organization.name,
            locationId: user.activeLocation.id,
            locationName: user.activeLocation.name,
          });
        }}
        className="inline-flex items-center rounded-md border bg-white border-gray-300 px-4 py-2 h-full text-sm font-medium shadow-sm hover:bg-gray-100"
        disabled={getPledgePaymentsResult.loading}
      >
        {getPledgePaymentsResult.loading ? (
          <>
            Downloading <OvalSpinner className="text-indigo-600" />
          </>
        ) : (
          <>
            Download
            <DocumentDownloadIcon
              className="ml-2 -mr-1 h-5 w-5"
              aria-hidden="true"
            />
          </>
        )}
      </button>
      {csvData && (
        <CSVLink
          data={csvData}
          filename="payments.csv"
          ref={csvRef}
          className="hidden"
          target="_blank"
        />
      )}
    </>
  );
};

const SKIP_PAYMENT_POSTING = gql`
  mutation SkipPaymentPosting($paymentId: String!) {
    updateOnePayment(
      where: { id: $paymentId }
      data: { postingStatus: { set: Skipped } }
    ) {
      id
      postingStatus
      postedAt
    }
  }
`;

const SkipPaymentPostingButton: React.FC<{ paymentId: string }> = ({
  paymentId,
}) => {
  const user = useUser()!;
  const [skipPosting, skipPostingResult] = useMutation<
    SkipPaymentPosting,
    SkipPaymentPostingVariables
  >(SKIP_PAYMENT_POSTING);

  return (
    <Button
      type="button"
      variant="default"
      size="sm"
      onClick={async () => {
        await skipPosting({
          variables: {
            paymentId,
          },
          onCompleted: (data) => {
            toast.success("Payment posting skipped");
          },
          onError: (error) => {
            toast.error("Failed to skip payment posting");
          },
        });
      }}
      disabled={skipPostingResult.loading}
    >
      {skipPostingResult.loading ? (
        <>
          <OvalSpinner className="text-indigo-600" />
        </>
      ) : (
        <>Skip posting</>
      )}
    </Button>
  );
};

export const POST_PAYMENT = gql`
  mutation PostPayment($paymentId: String!) {
    postPayment(paymentId: $paymentId) {
      payment {
        id
        postingStatus
        postedAt
      }
      errors {
        message
      }
    }
  }
`;

export const PostPaymentButton: React.FC<
  React.PropsWithChildren<{
    paymentId: string;
    variant?: ButtonVariant;
    size?: ButtonSize;
  }>
> = ({
  paymentId,
  variant = "default",
  size = "default" as const,
  children,
}) => {
  const [postPayment, postPaymentResult] = useMutation<
    PostPayment,
    PostPaymentVariables
  >(POST_PAYMENT);

  return (
    <Button
      type="button"
      variant={variant}
      size={size}
      onClick={async () => {
        await postPayment({
          variables: {
            paymentId,
          },
          onCompleted: (data) => {
            if (data.postPayment.errors) {
              for (const error of data.postPayment.errors) {
                toast.error(error.message);
              }
            } else {
              toast.success("Payment posted!");
            }
          },
          onError: (error) => {
            toast.error("Failed to post payment");
          },
        });
      }}
      disabled={postPaymentResult.loading}
    >
      {postPaymentResult.loading ? (
        <>
          <OvalSpinner className="text-indigo-600" />
        </>
      ) : (
        <>{children}</>
      )}
    </Button>
  );
};

const PaymentsList: React.FC<
  React.PropsWithChildren<{ dateRange: DateRange }>
> = ({ dateRange }) => {
  const user = useUser()!;
  const [currentPage, setPageNum] = useState(1);

  const onPageChange = (num: number) => {
    setPageNum(num);
  };
  const skip = (currentPage - 1) * PAGE_SIZE;

  const { loading, error, data } = useQuery<
    GetPledgePayments,
    GetPledgePaymentsVariables
  >(GET_PLEDGE_PAYMENTS, {
    variables: {
      organizationId: user!.organization.id,
      locationId: user!.activeLocation.id,
      skip: skip,
      take: PAGE_SIZE,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    },
    context: {
      debounceKey: "GET_PLEDGE_PAYMENTS",
      debounceTimeout: 100,
    },
  });

  const rows = data?.getPledgePayments?.payments || [];
  const patientTotal = data?.getPledgePayments?.total ?? 0;

  const hasMultiplePaymentAccounts =
    user.organization.stripeConnectedAccounts.length > 1;

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <GroupingTable
            loading={loading}
            columnDefs={[
              // Empty column to add space for row expansion button
              {
                header: "",
                headerComponent: <div className="w-0"></div>,
                cellFn: () => <div className="w-0"></div>,
              },
              {
                header: "Patient Payment",
                cellFn: (row) => {
                  const patient = row.transaction.account.patient;
                  return (
                    <div className="flex space-x-1 text-gray-900 text-sm font-normal normal-case">
                      <Link
                        to={`/patients/${patient.id}`}
                        className="hover:text-gray-500 truncate"
                      >
                        {patient.displayName}
                      </Link>
                      <span>paid</span>
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        {row.subRows.length ?? 0} bills
                      </span>
                    </div>
                  );
                },
                colSpan: 2,
              },
              {
                header: "Posting Status",
                cellFn: (row) => {
                  const postingStatus = row.postingStatus;
                  if (postingStatus === "Failed") {
                    return (
                      <HoverCard>
                        <HoverCardTrigger>
                          <Badge variant="error" text="Failed" />
                        </HoverCardTrigger>
                        <HoverCardContent
                          side="top"
                          sideOffset={5}
                          className="z-50 max-w-sm"
                        >
                          <Card>
                            <div className="flex flex-col normal-case text-sm">
                              Payment posting failed.
                              <div className="pt-2 flex justify-between gap-2">
                                <SkipPaymentPostingButton paymentId={row.id} />
                                {user.activeLocation.integrations.some(
                                  (i) => i.supportsPrepaymentPosting
                                ) && (
                                  <PostPaymentButton
                                    paymentId={row.id}
                                    size="sm"
                                  >
                                    Retry posting to {user.externalLedgerName}{" "}
                                    now
                                  </PostPaymentButton>
                                )}
                              </div>
                            </div>
                          </Card>
                        </HoverCardContent>
                      </HoverCard>
                    );
                  }
                  if (postingStatus === "Pending") {
                    return (
                      <HoverCard>
                        <HoverCardTrigger>
                          <Badge variant="warning" text="Pending" />
                        </HoverCardTrigger>
                        <HoverCardContent
                          side="top"
                          sideOffset={5}
                          className="z-50 max-w-sm"
                        >
                          <Card>
                            <div className="flex flex-col normal-case text-sm">
                              Posting is pending for this payment. Waiting for
                              the bill to be finalized and then will be
                              automatically posted.
                              <div className="pt-2 flex justify-between gap-2">
                                <SkipPaymentPostingButton paymentId={row.id} />
                                {user.activeLocation.integrations.some(
                                  (i) => i.supportsPrepaymentPosting
                                ) && (
                                  <PostPaymentButton
                                    paymentId={row.id}
                                    size="sm"
                                  >
                                    Post to {user.externalLedgerName} now
                                  </PostPaymentButton>
                                )}
                              </div>
                            </div>
                          </Card>
                        </HoverCardContent>
                      </HoverCard>
                    );
                  }
                  if (postingStatus === "Skipped") {
                    return (
                      <HoverCard>
                        <HoverCardTrigger>
                          <Badge variant="info" text="Skipped" />
                        </HoverCardTrigger>
                        <HoverCardContent
                          side="top"
                          sideOffset={5}
                          className="z-50 max-w-sm"
                        >
                          <Card>
                            <div className="flex flex-col normal-case text-sm">
                              Skipped posting this payment.
                            </div>
                          </Card>
                        </HoverCardContent>
                      </HoverCard>
                    );
                  }
                  if (postingStatus === "Posting") {
                    return (
                      <HoverCard>
                        <HoverCardTrigger>
                          <Badge variant="info" text="Posting" />
                        </HoverCardTrigger>
                        <HoverCardContent
                          side="top"
                          sideOffset={5}
                          className="z-50 max-w-sm"
                        >
                          <Card>
                            <div className="flex flex-col normal-case text-sm">
                              This payment is currently being posted.
                            </div>
                          </Card>
                        </HoverCardContent>
                      </HoverCard>
                    );
                  }
                  if (postingStatus === "Posted") {
                    return (
                      <HoverCard>
                        <HoverCardTrigger>
                          <Badge variant="success" text="Posted" />
                        </HoverCardTrigger>
                        <HoverCardContent
                          side="top"
                          sideOffset={5}
                          className="z-50 max-w-sm"
                        >
                          <Card>
                            <div className="flex flex-col normal-case text-sm">
                              Posted to {user.externalLedgerName} at{" "}
                              {row.postedAt &&
                                format(
                                  parseISO(row.postedAt),
                                  "MM/dd/yyyy hh:mm a"
                                )}
                            </div>
                          </Card>
                        </HoverCardContent>
                      </HoverCard>
                    );
                  }
                  return null;
                },
              },
              {
                header: "Payment Date",
                cellFn: (row) => {
                  return (
                    <div className="text-sm font-normal text-gray-900">
                      {format(
                        parseISO(row.transaction.transactedAt),
                        "MM/dd/yyyy hh:mm a"
                      )}
                    </div>
                  );
                },
              },
              {
                header: "Autopay",
                cellFn: (row) => (
                  <div className="text-sm font-normal text-gray-900 normal-case">
                    {row.paymentIntent?.autoPay ? "Yes" : "No"}
                  </div>
                ),
              },
              {
                header: "Payment Amount",
                cellFn: (row) => (
                  <div className="text-sm font-normal text-gray-900">
                    {formatUSD(row.transaction.patientAmount)}
                  </div>
                ),
              },
              ...(hasMultiplePaymentAccounts
                ? [
                    {
                      header: "Payments Account",
                      cellFn: (row: Payment) => (
                        <div className="text-sm font-normal text-gray-900">
                          {row.stripeConnectedAccount?.name}
                        </div>
                      ),
                    },
                  ]
                : []),
            ]}
            subTableColumnDefs={[
              // Empty column to add space for row expansion button
              {
                header: "",
                cellFn: () => <Td className="w-0"></Td>,
              },
              {
                header: "Account",
                colSpan: 2,
                cellFn: (row: BillGroup) => (
                  <Td colSpan={2}>
                    <div className="text-sm text-gray-900">
                      {row.bill.account?.accountType?.name}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Provider Name",
                cellFn: (row: BillGroup) => {
                  return (
                    <Td>
                      <div className="text-sm text-gray-900">
                        {row.bill.primaryProvider?.displayName}
                      </div>
                    </Td>
                  );
                },
              },
              {
                header: "Date of Service",
                colSpan: 2,
                cellFn: (row: BillGroup) => {
                  return (
                    <Td colSpan={2}>
                      <div className="text-sm text-gray-900">
                        {row.bill.dateOfServiceDisplay &&
                          toDateMMDDYYYY(row.bill.dateOfServiceDisplay)}
                      </div>
                    </Td>
                  );
                },
              },
              {
                header: "Payment Amount",
                colSpan: hasMultiplePaymentAccounts ? 2 : 1,
                cellFn: (row: BillGroup) => {
                  return (
                    <Td colSpan={hasMultiplePaymentAccounts ? 2 : 1}>
                      <div className="text-sm text-gray-900">
                        {formatUSD(row.allocated)}
                      </div>
                    </Td>
                  );
                },
              },
            ]}
            rows={rows.map((row) => ({
              ...row,
              id: row.id,
              subRows: groupChargesByBill(row),
            }))}
            pagination={{
              currentPage,
              totalCount: patientTotal,
              onPageChange,
              pageSize: PAGE_SIZE,
              siblingCount: 0,
            }}
            expandButtonColumnIndex={1}
          />
        </div>
      </div>
    </div>
  );
};

type DateRange = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

export const Payments: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: undefined,
    endDate: undefined,
  });

  return (
    <Layout
      header={
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end">
          <div className="flex flex-col">
            <h1 className="text-2xl font-semibold text-gray-900">Payments</h1>
            <h2 className="text-lg text-gray-700">
              Latest payments collected via Pledge
            </h2>
          </div>
          <div className="flex flex-col sm:flex-row gap-1">
            <div date-rangepicker className="flex items-center">
              <input
                type="date"
                name="startDate"
                className="px-3 py-2  focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md"
                onChange={(e) => {
                  setDateRange({
                    startDate: e.target.value
                      ? startOfDay(parseISO(e.target.value))
                      : undefined,
                    endDate: dateRange.endDate,
                  });
                }}
              />
              <span className="mx-2 text-gray-500">to</span>
              <input
                type="date"
                name="endDate"
                className="px-3 py-2  focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md"
                onChange={(e) => {
                  setDateRange({
                    startDate: dateRange.startDate,
                    endDate: e.target.value
                      ? endOfDay(parseISO(e.target.value))
                      : undefined,
                  });
                }}
              />
            </div>
            <div>
              <DownloadPaymentsButton dateRange={dateRange} />
            </div>
          </div>
        </div>
      }
      content={
        <div className="py-4">
          <PaymentsList dateRange={dateRange} />
        </div>
      }
    />
  );
};
