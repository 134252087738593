import { differenceInDays, format, isValid, parse, parseISO } from "date-fns";
import { BillState } from "./generated/globalTypes";
import { GetMe_me as User } from "./generated/GetMe";

export const classNames = (
  ...classes: (string | null | undefined | boolean)[]
) => classes.filter(Boolean).join(" ");

export const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const accountingFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencySign: "accounting",
});
export const formatUSD = (cents: number) => {
  return (cents < 0 ? "-" : "") + usdFormatter.format(Math.abs(cents / 100));
};

export const formatAccounting = (cents: number) => {
  return accountingFormatter.format(cents / 100);
};

/**
 * Formats a float between 0.0 and 1.0 into a percentage with 2 decimals of precision
 */
export const formatPercentage = (decimal: number, precision?: number) =>
  `${(decimal * 100).toFixed(precision)}%`;

/**
 * Convert a fractional dollar amount to a whole number of cents
 */
export const toCents = (dollarAmount: number) => Math.round(100 * dollarAmount);

/**
 * Convert an amount in cents to a dollar amount
 */
export const toDollars = (cents: number) => cents / 100;

export const toDollarStr = (cents: number) => {
  return `${toDollars(cents).toFixed(2)}`;
};

/**
 * Converts ISO formatted timestamp into YYYY-MM-DD format
 */
export const toDate = (dateStr: string) => dateStr.substr(0, 10);

export const percentageToDecimal = (percentage: string | null) =>
  mapNullable((pcnt: string) => {
    const decimal = Number.parseFloat(pcnt);
    if (Number.isNaN(decimal)) return null;
    return (decimal / 100).toString();
  })(percentage);

export const decimalToPercentage = (decimal: string | null) =>
  mapNullable((dec: string) => {
    const decimal = Number.parseFloat(dec);
    if (Number.isNaN(decimal)) return null;
    return (decimal * 100).toFixed(2);
  })(decimal);

/**
 * `Number.parseInt` except returns null instead of NaN if not able to parse
 */
export const parseIntOrNull = (intStr: string) => {
  const int = Number.parseInt(intStr);
  if (Number.isNaN(int)) {
    return null;
  }
  return int;
};

/**
 * Converts ISO formatted timestamp into MM/dd/yyyy format
 * Does NOT convert to the browser's timezone
 */
export const toDateMMDDYYYY = (dateStr: string) => {
  const [year, month, day] = toDate(dateStr).split("-");
  return [month, day, year].join("/");
};

/**
 * Parses a date string into a Date object ignoring the time component
 */
export const parseDate = (dateStr: string) => {
  return parseISO(toDate(dateStr));
};

/**
 * Converts ISO formatted timestamp into HH:MM:SS format
 */
export const toTime = (dateStr: string) => dateStr.substr(11, 8);

/**
 * Converts ISO formatted timestamp into a Date object offset by the browser's
 * timezone's UTC offset so it displays correctly, e.g.
 * ```
 * toUtcDate("1992-12-15T05:00:00.000Z")
 * > Tue Dec 15 1992 00:00:00 GMT-0500 (Eastern Standard Time)
 * ```
 */
export const toUtcDate = (dateStr: string) => {
  const date = new Date(toDate(dateStr));
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

/**
 * Will convert to the browser's timezone before formatting
 */
export const formatDateMMDDYYYY = (dateStr: string) =>
  format(parseISO(dateStr), "MM/dd/yyyy");

/**
 * Typeguard to narrow to a non-undefined, non-null type
 */
export const isDefined = <T>(target: T | null | undefined): target is T =>
  target !== undefined && target !== null;

export const range = (start: number, stop: number, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);

export const toTitleCase = (str: string | undefined) => {
  if (str === undefined) return null;
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

/**
 * Returns a debounced function that only invokes after `ms` has passsed since
 * the last time it was invoked
 */
export const debounce = (fn: Function, ms: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

/**
 * Asynchronously copies the text to the user's clipboard
 */
export const copyTextToClipboard = async (text: string) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export const formatDaysToNow = (date: Date) => {
  const daysDiff = differenceInDays(date, new Date());
  if (daysDiff === 0) return "Today";
  if (daysDiff < 0) {
    return `${-daysDiff} days ago`;
  }
  return `in ${daysDiff} days`;
};

export const billStateDisplay = (bill: {
  status: BillState;
  charges: any[];
}) => {
  switch (bill.status) {
    case BillState.Estimated:
      if (bill.charges.length === 0) {
        return "Awaiting Charges";
      } else {
        return "Charges Entered";
      }
    case BillState.Pending:
      if (bill.charges.length === 0) {
        return "Awaiting Charges";
      } else {
        return "Charges Entered";
      }
    case BillState.InReview:
      return "In Review";
    case BillState.Ready:
      return "Ready";
    case BillState.Archived:
      return "Archived";
    default:
      return "Paid";
  }
};

export const oldBillStateDisplay = (billState: BillState) => {
  switch (billState) {
    case BillState.Estimated:
      return "Estimated";
    case BillState.Pending:
      return "Pending";
    case BillState.InReview:
      return "In Review";
    case BillState.Ready:
      return "Ready";
    case BillState.Archived:
      return "Archived";
    default:
      return "Paid";
  }
};

export const mapNullable =
  <T, U>(fn: (inner: T) => U) =>
  (inner: T | null | undefined) =>
    inner === null || inner === undefined ? null : fn(inner);

export const dateStrToMMDDYYYY = (dateStr: string) => {
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(5, 7);
  const day = dateStr.substring(8, 10);
  if (isNaN(Number(year)) || isNaN(Number(month)) || isNaN(Number(day))) {
    return null;
  }
  return `${month}/${day}/${year}`;
};

/**
 * Gets the number of days after the due date that a bill is considered
 * delinquent based on the user's organization's reminder workflow template
 */
export const getDelinquentDaysFromUserOrg = (user: User) => {
  const template = user.organization.reminderWorkflowTemplates.at(0);
  // Default to 90 days after due
  if (!template) return 90;
  if (template.ninetyDaysAfterReminder) return 90;
  if (template.sixtyDaysAfterReminder) return 60;
  if (template.fortyFiveDaysAfterReminder) return 45;
  if (template.thirtyDaysAfterReminder) return 30;
  if (template.fifteenDaysAfterReminder) return 15;
  return 90;
};

const UPPER_ALPHANUMERIC_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
/**
 * Returns a random alphanumeric string with only uppercase letters of the
 * given length
 */
export const randomUpperAlphaNumeric = (length: number) => {
  let result = "";
  for (let i = length; i > 0; --i) {
    result +=
      UPPER_ALPHANUMERIC_CHARS[
        Math.floor(Math.random() * UPPER_ALPHANUMERIC_CHARS.length)
      ];
  }
  return result;
};

const NUMERIC_CHARS = "0123456789";
/**
 * Returns a random numeric string of the given length
 */
export const randomNumeric = (length: number) => {
  let result = "";
  for (let i = length; i > 0; --i) {
    result += NUMERIC_CHARS[Math.floor(Math.random() * NUMERIC_CHARS.length)];
  }
  return result;
};

// TODO: Move this to the backend
export const eligibilityResponseErrorDisplay = (error: {
  field: string | null;
  description: string | null;
}) => {
  // If this is a AAA error, we can display the description as is
  if (error.field === "AAA") {
    return error.description;
  }
  if (!error.field) {
    return error.description ?? "Unknown error";
  }
  // Otherwise, we need to concatenate the field and description to get the full error message
  return `Error in field "${error.field}": ${error.description}`;
};

export const getNumberWithOrdinal = (n: number) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const formatBillCode = (billCode: string | null) =>
  billCode?.replace(/.{4}(?!$)/g, "$&-");

export const uniqueByKey = <T>(array: T[], key: keyof T) =>
  Array.from(new Map(array.map((item) => [item[key], item])).values());

export const accessObjectByPath = <T>(obj: T, path: string) => {
  return path.split(".").reduce((a, v) => (a as any)?.[v], obj);
};

/**
 * Converts PascalCase string into space-separated human-readable format
 */
export const pascalCaseToReadable = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1").trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// https://github.com/date-fns/date-fns/issues/1218
import enUS from "date-fns/locale/en-US";
import formatRelative from "date-fns/formatRelative";

const formatRelativeLocale: { [key: string]: string } = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: "MMM do, yyyy",
};

const locale = {
  ...enUS,
  formatRelative: (token: string) => formatRelativeLocale[token],
};

/**
 * date-fns format relative without the time
 */
export const formatRelativeDay = (date: Date, relative = new Date()) => {
  return formatRelative(date, relative, {
    locale: {
      ...locale,
    },
  });
};

export const parseMultiple = (
  dateString: string,
  formatString: string | string[],
  referenceDate: Date,
  options: { locale?: Locale } = {}
) => {
  let result;

  if (Array.isArray(formatString)) {
    for (let i = 0; i < formatString.length; i++) {
      result = parse(dateString, formatString[i], referenceDate, options);
      if (isValid(result)) {
        break;
      }
    }
  } else {
    result = parse(dateString, formatString, referenceDate, options);
  }

  return result;
};
