import React, { Fragment, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon, InboxInIcon } from "@heroicons/react/outline";
import { format, parseISO } from "date-fns";

import {
  INSURANCE_POLICY_SUMMARY_FIELDS,
  COVERAGE_BENEFIT_FIELDS,
} from "../../../graphql";
import { usePatientUser } from "../../patient-user-context";
import {
  classNames,
  formatPercentage,
  formatUSD,
  isDefined,
} from "../../../utils";
import { Rings } from "../../../components/loading";
import { NotFound } from "../../../components/404";
import {
  DEDUCTIBLE_HELP_TEXT,
  OOP_HELP_TEXT,
  VISIT_LIMIT_HELP_TEXT,
  SERVICE_DEDUCTIBLE_HELP_TEXT,
} from "./help-text";
import { PlanProgress } from "./plan-progress";
import { usePatientAuth } from "../../patient-auth-context";
import { NetworkBadge } from "../../../pages/patients/networkBadge";
import { FeedbackType } from "../../../generated/globalTypes";
import { FeedbackSurvey } from "../feedback-survey";
import {
  GetPatientPortalBenefits,
  GetPatientPortalBenefitsVariables,
  GetPatientPortalBenefits_patientInsurancePolicy as InsurancePolicy,
  GetPatientPortalBenefits_patientInsurancePolicy_inNetworkBenefits as Benefit,
} from "../../../generated/GetPatientPortalBenefits";
import { ServiceTypeCode } from "../../../pages/patients/eligibilities/types";
import { getCostSharingType } from "../../../pages/patients/insurances/show";

const formatPayerName = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const PatientInsurancePolicyBenefitsPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const params = useParams<{ insurancePolicyId: string }>();
  const insurancePolicyId = params.insurancePolicyId!;
  const user = usePatientUser();
  const { logout } = usePatientAuth();

  return user ? (
    <body>
      <main className="overflow-y-scroll mb-10 pb-5">
        <PatientBenefits insurancePolicyId={insurancePolicyId} />
      </main>
      <footer className="bg-indigo-50 fixed bottom-0 left-0 w-full flex justify-end">
        <a
          onClick={() => logout(insurancePolicyId)}
          className="inline-flex items-center justify-center my-2 mx-10 px-5 py-1 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 md:text-lg"
        >
          Done
        </a>
      </footer>
    </body>
  ) : (
    <>No user</>
  );
};

const GET_PATIENT_PORTAL_BENEFITS = gql`
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${COVERAGE_BENEFIT_FIELDS}
  query GetPatientPortalBenefits($insurancePolicyId: String!) {
    patientInsurancePolicy(id: $insurancePolicyId) {
      id
      updatedAt
      acceptedAt
      customPatientNote
      ...InsurancePolicySummaryFields
      planCoverageBenefit {
        inNetworkIndividualBenefit {
          ...CoverageBenefitFields
        }
        outOfNetworkIndividualBenefit {
          ...CoverageBenefitFields
        }
        inNetworkFamilyBenefit {
          ...CoverageBenefitFields
        }
        outOfNetworkFamilyBenefit {
          ...CoverageBenefitFields
        }
      }
      inNetworkBenefits: mostRecentCoverageBenefits(inNetwork: true) {
        ...CoverageBenefitFields
      }
      outOfNetworkBenefits: mostRecentCoverageBenefits(inNetwork: false) {
        ...CoverageBenefitFields
      }
      patient {
        id
        firstName
        lastName
        displayName
        email
        organizationId
        organization {
          logoUrl
          name
        }
      }
    }
  }
`;

const Benefits: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: InsurancePolicy;
  }>
> = ({ insurancePolicy }) => {
  const orgName = insurancePolicy.patient.organization.name;
  const inNetwork = insurancePolicy.inNetwork;

  const defaultNetworkStatus = inNetwork ? "in" : "out";
  const [networkStatus, setNetworkStatus] = useState<"in" | "out">(
    defaultNetworkStatus
  );
  const [notesDialogOpen, setNotesDialogOpen] = useState(false);
  const [noteBenefit, setNoteBenefit] = useState<{
    name: string;
    notes: string[];
  } | null>();
  const [helpDialog, setHelpDialog] = useState<{
    label: string;
    helpText: string;
  } | null>();
  const [showSurvey, setShowSurvey] = useState(false);

  const inNetworkIndvPlanBenefits = {
    hidden:
      insurancePolicy.planCoverageBenefit?.inNetworkIndividualBenefit
        ?.hidden === true,
    deductible: {
      remaining:
        insurancePolicy.planCoverageBenefit?.inNetworkIndividualBenefit
          ?.adjustedRemainingDeductible,
      max: insurancePolicy.planCoverageBenefit?.inNetworkIndividualBenefit
        ?.maxDeductible,
      metDeductible:
        insurancePolicy.planCoverageBenefit.inNetworkIndividualBenefit
          ?.adjustedMetDeductible ?? null,
    },
    outOfPocket: {
      remaining:
        insurancePolicy.planCoverageBenefit?.inNetworkIndividualBenefit
          ?.adjustedRemainingOutOfPocket,
      max: insurancePolicy.planCoverageBenefit?.inNetworkIndividualBenefit
        ?.maxOutOfPocket,
      metOutOfPocketMax:
        insurancePolicy.planCoverageBenefit.inNetworkIndividualBenefit
          ?.adjustedMetOutOfPocketMax ?? null,
    },
  };
  const outOfNetworkPlanBenefits = insurancePolicy.outOfNetworkBenefits.find(
    (cb) => cb.providerServiceConfiguration.serviceType === "30"
  );
  const outNetworkIndvPlanBenefits = {
    hidden:
      insurancePolicy.planCoverageBenefit?.outOfNetworkIndividualBenefit
        ?.hidden === true,
    deductible: {
      remaining:
        insurancePolicy.planCoverageBenefit?.outOfNetworkIndividualBenefit
          ?.adjustedRemainingDeductible,
      max: insurancePolicy.planCoverageBenefit?.outOfNetworkIndividualBenefit
        ?.maxDeductible,
      metDeductible:
        insurancePolicy.planCoverageBenefit.outOfNetworkIndividualBenefit
          ?.adjustedMetDeductible ?? null,
    },
    outOfPocket: {
      remaining:
        insurancePolicy.planCoverageBenefit?.outOfNetworkIndividualBenefit
          ?.adjustedRemainingOutOfPocket,
      max: insurancePolicy.planCoverageBenefit?.outOfNetworkIndividualBenefit
        ?.maxOutOfPocket,
      metOutOfPocketMax:
        insurancePolicy.planCoverageBenefit.outOfNetworkIndividualBenefit
          ?.adjustedMetOutOfPocketMax ?? null,
    },
  };

  const inNetworkFamPlanBenefits = {
    hidden:
      insurancePolicy.planCoverageBenefit?.inNetworkFamilyBenefit?.hidden ===
      true,
    deductible: {
      remaining:
        insurancePolicy.planCoverageBenefit?.inNetworkFamilyBenefit
          ?.adjustedRemainingDeductible,
      max: insurancePolicy.planCoverageBenefit?.inNetworkFamilyBenefit
        ?.maxDeductible,
      metDeductible:
        insurancePolicy.planCoverageBenefit.inNetworkFamilyBenefit
          ?.adjustedMetDeductible ?? null,
    },
    outOfPocket: {
      remaining:
        insurancePolicy.planCoverageBenefit?.inNetworkFamilyBenefit
          ?.adjustedRemainingOutOfPocket,
      max: insurancePolicy.planCoverageBenefit?.inNetworkFamilyBenefit
        ?.maxOutOfPocket,
      metOutOfPocketMax:
        insurancePolicy.planCoverageBenefit.inNetworkFamilyBenefit
          ?.adjustedMetOutOfPocketMax ?? null,
    },
  };
  const outNetworkFamPlanBenefits = {
    hidden:
      insurancePolicy.planCoverageBenefit?.outOfNetworkFamilyBenefit?.hidden ===
      true,
    deductible: {
      remaining:
        insurancePolicy.planCoverageBenefit?.outOfNetworkFamilyBenefit
          ?.adjustedRemainingDeductible,
      max: insurancePolicy.planCoverageBenefit?.outOfNetworkFamilyBenefit
        ?.maxDeductible,
      metDeductible:
        insurancePolicy.planCoverageBenefit.outOfNetworkFamilyBenefit
          ?.adjustedMetDeductible ?? null,
    },
    outOfPocket: {
      remaining:
        insurancePolicy.planCoverageBenefit?.outOfNetworkFamilyBenefit
          ?.adjustedRemainingOutOfPocket,
      max: insurancePolicy.planCoverageBenefit?.outOfNetworkFamilyBenefit
        ?.maxOutOfPocket,
      metOutOfPocketMax:
        insurancePolicy.planCoverageBenefit.outOfNetworkFamilyBenefit
          ?.adjustedMetOutOfPocketMax ?? null,
    },
  };

  const inNetworkOrganizationServiceBenefits = [
    ...insurancePolicy.inNetworkBenefits,
  ]
    .filter(
      (cb) =>
        cb.providerServiceConfiguration.serviceType !==
        ServiceTypeCode.HealthBenefitPlanCoverage
    )
    .sort(
      (a, b) =>
        (a.providerServiceConfiguration.priorityOrder ?? Infinity) -
        (b.providerServiceConfiguration.priorityOrder ?? Infinity)
    )
    .map((cb) => ({
      name: cb.providerServiceConfiguration.name,
      serviceTypeCode: cb.providerServiceConfiguration.serviceType,
      ...cb,
    }));
  const outNetworkOrganizationServiceBenefits = [
    ...insurancePolicy.outOfNetworkBenefits,
  ]
    .filter(
      (cb) =>
        cb.providerServiceConfiguration.serviceType !==
        ServiceTypeCode.HealthBenefitPlanCoverage
    )
    .sort(
      (a, b) =>
        (a.providerServiceConfiguration.priorityOrder ?? Infinity) -
        (b.providerServiceConfiguration.priorityOrder ?? Infinity)
    )
    .map((cb) => ({
      name: cb.providerServiceConfiguration.name,
      serviceTypeCode: cb.providerServiceConfiguration.serviceType,
      ...cb,
    }));

  const planBenefits =
    networkStatus === "in"
      ? { indv: inNetworkIndvPlanBenefits, fam: inNetworkFamPlanBenefits }
      : { indv: outNetworkIndvPlanBenefits, fam: outNetworkFamPlanBenefits };

  const organizationServiceBenefits = React.useMemo(() => {
    const organizationServiceBenefits: {
      [name: string]: {
        in?: Benefit;
        out?: Benefit;
        serviceTypeCode: string;
      };
    } = {};

    inNetworkOrganizationServiceBenefits
      ?.filter(
        (b) =>
          b.providerServiceConfiguration.serviceType !==
          ServiceTypeCode.HealthBenefitPlanCoverage
      )
      ?.forEach((benefit) => {
        const group = organizationServiceBenefits[benefit.name] || {};
        group.in = benefit;
        group.serviceTypeCode = benefit.serviceTypeCode;
        organizationServiceBenefits[benefit.name] = group;
      });

    outNetworkOrganizationServiceBenefits
      ?.filter(
        (b) =>
          b.providerServiceConfiguration.serviceType !==
          ServiceTypeCode.HealthBenefitPlanCoverage
      )
      ?.forEach((benefit) => {
        const group = organizationServiceBenefits[benefit.name] || {};
        group.out = benefit;
        group.serviceTypeCode = benefit.serviceTypeCode;
        organizationServiceBenefits[benefit.name] = group;
      });
    return organizationServiceBenefits;
  }, [
    inNetworkOrganizationServiceBenefits,
    outNetworkOrganizationServiceBenefits,
  ]);

  const openNoteDiaglog = (benefit: { name: string; notes: string[] }) => {
    setNotesDialogOpen(true);
    setNoteBenefit(benefit);
  };

  const indvDeductibleDefined =
    isDefined(planBenefits.indv.deductible.remaining) ||
    isDefined(planBenefits.indv.deductible.max);

  const indvOopDefined =
    isDefined(planBenefits.indv.outOfPocket.remaining) ||
    isDefined(planBenefits.indv.outOfPocket.max);

  const famDeductibleDefined =
    isDefined(planBenefits.fam.deductible.remaining) ||
    isDefined(planBenefits.fam.deductible.max);

  const famOopDefined =
    isDefined(planBenefits.fam.outOfPocket.remaining) ||
    isDefined(planBenefits.fam.outOfPocket.max);

  return (
    <div className="text-gray-900 flex flex-col">
      <div className="flex justify-between items-center h-16 bg-white border-b border-gray-300 px-4 lg:px-16">
        <img
          className="h-14 w-auto"
          // TODO: handle missing logo url
          src={insurancePolicy.patient.organization.logoUrl!}
          alt="Logo"
        />
        <div>
          <button
            className="inline-flex items-center space-x-1 justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={() => setShowSurvey(true)}
          >
            <div>Submit Feedback</div>
            <InboxInIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
      {/* Banner Message */}
      <div className="bg-indigo-50">
        <div className="max-w-4xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="font-medium text-indigo-900 text-sm">
              <span>
                Please find your insurance coverage details below for all the
                services {orgName} offers. We hope that this information will
                provide you clarity on what to expect prior to your appointment.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="p-2 lg:mx-auto lg:max-w-6xl">
        <div className="grid grid-cols-3 lg:gap-x-2 gap-y-2">
          <div className="col-span-3 lg:col-start-2 lg:col-span-2">
            <div className="px-2 border-b border-gray-200 rounded-md">
              <nav className="-mb-px flex" aria-label="Tabs">
                <button
                  className={classNames(
                    networkStatus === "in"
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm"
                  )}
                  aria-current={networkStatus === "in" ? "page" : undefined}
                  onClick={() => setNetworkStatus("in")}
                >
                  In Network
                </button>
                <button
                  className={classNames(
                    networkStatus === "out"
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm"
                  )}
                  aria-current={networkStatus === "out" ? "page" : undefined}
                  onClick={() => setNetworkStatus("out")}
                >
                  Out of Network
                </button>
              </nav>
            </div>
          </div>
          <section className="col-span-3 lg:col-span-1">
            {/* Plan Identificaton Card */}
            <div className="px-4 py-5 border-2 border-gray-200 rounded-md sm:px-5">
              <h2 className="text-xl">
                {insurancePolicy.patient.firstName}{" "}
                {insurancePolicy.patient.lastName}
              </h2>
              <div className="text-sm ">
                <div className="text-gray-500">
                  Benefits checked on{" "}
                  {format(
                    parseISO(
                      insurancePolicy.acceptedAt ?? insurancePolicy.updatedAt
                    ),
                    "MM/dd/yyyy"
                  )}
                </div>
                <div className="mt-2">
                  <span className="text-gray-500">Member ID</span>{" "}
                  {insurancePolicy.memberId}
                </div>
                <div className="mt-2">
                  <div className="flex justify-start">
                    <span className="text-gray-500">Payer</span>{" "}
                    <div className="ml-2">
                      <NetworkBadge orgPayerNetworkStatus={inNetwork} />
                    </div>
                  </div>
                  {formatPayerName(insurancePolicy.payer.name)}
                </div>
                {insurancePolicy.plan?.name && (
                  <div>
                    <span className="text-gray-500">Plan</span>{" "}
                    {formatPayerName(insurancePolicy.plan?.name)}
                  </div>
                )}
                <div className="text-xs text-gray-700">
                  {insurancePolicy.customPatientNote ? (
                    <p className="bg-gray-100 mt-1 pl-2 py-1 whitespace-pre-wrap">
                      {insurancePolicy.customPatientNote}
                    </p>
                  ) : (
                    <p className="bg-gray-100 mt-1 pl-2 py-1">
                      Please note that whether we're in-network with your
                      insurance may change based on your specific plan and the
                      provider you see. Please check with us before your
                      appointment.
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* Deductible and OOP Card */}
            <div className="bg-white px-4 py-5 border-2 border-gray-200 rounded-md sm:px-6 mt-2">
              <div className="flex flex-col text-xl pb-4">
                <h2 className="text-xl font-medium text-gray-900 pb-2 border-b">
                  Your Plan Progress
                </h2>
                <PlanProgress
                  planBenefits={planBenefits}
                  setHelpDialog={setHelpDialog}
                />
              </div>
              {!planBenefits.indv.hidden && (
                <div>
                  <h2 className="text-xl font-medium text-gray-900 pb-2 border-b">
                    Individual
                  </h2>
                  {indvDeductibleDefined || indvOopDefined ? (
                    <div>
                      {indvDeductibleDefined && (
                        <ProgressSection
                          label="Deductible"
                          helpText={DEDUCTIBLE_HELP_TEXT}
                          remaining={planBenefits.indv?.deductible?.remaining}
                          max={planBenefits.indv?.deductible?.max}
                          openHelpDialog={setHelpDialog}
                        />
                      )}
                      {indvOopDefined && (
                        <ProgressSection
                          label="Out of Pocket"
                          helpText={OOP_HELP_TEXT}
                          remaining={planBenefits.indv?.outOfPocket?.remaining}
                          max={planBenefits.indv?.outOfPocket?.max}
                          openHelpDialog={setHelpDialog}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="pt-2 text-lg">
                      No plan-level indivdual deductible or out-of-pocket max.
                    </div>
                  )}
                </div>
              )}
              {!planBenefits.fam.hidden && (
                <div>
                  <h2 className="text-xl font-medium text-gray-900 pb-2 border-b">
                    Family
                  </h2>
                  {famDeductibleDefined || famOopDefined ? (
                    <div>
                      {famDeductibleDefined && (
                        <ProgressSection
                          label="Deductible"
                          helpText={DEDUCTIBLE_HELP_TEXT}
                          remaining={planBenefits.fam?.deductible?.remaining}
                          max={planBenefits.fam?.deductible?.max}
                          openHelpDialog={setHelpDialog}
                        />
                      )}
                      {famOopDefined && (
                        <ProgressSection
                          label="Out of Pocket"
                          helpText={OOP_HELP_TEXT}
                          remaining={planBenefits.fam?.outOfPocket?.remaining}
                          max={planBenefits.fam?.outOfPocket?.max}
                          openHelpDialog={setHelpDialog}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="pt-2 text-lg">
                      No plan-level family deductible or out-of-pocket max.
                    </div>
                  )}
                </div>
              )}
            </div>
          </section>
          <section className="col-span-3 lg:col-span-2">
            {Object.entries(organizationServiceBenefits).map(([key, value]) => {
              const benefit = networkStatus === "in" ? value.in : value.out;

              // Don't display any hidden services
              if (benefit?.hidden === true) return null;

              if (!benefit) {
                return (
                  <div
                    className="p-2 mb-2 border-2 border-gray-200 rounded-md text-lg"
                    key={key}
                  >
                    <div className="pb-2 border-b border-gray-300 md:flex md:justify-between">
                      <h2 className="text-xl font-semibold text-gray-900">
                        {key}
                      </h2>
                    </div>
                    <div className="pt-2">No information</div>
                  </div>
                );
              }

              const copay = isDefined(benefit.copay)
                ? formatUSD(benefit.copay)
                : "Does not apply";
              const coinsurance = isDefined(benefit.coinsurance)
                ? formatPercentage(benefit.coinsurance)
                : "Does not apply";

              const copayNotes = benefit.copayBenefit?.notes ?? [];
              const coinsuranceNotes = benefit.coinsuranceBenefit?.notes ?? [];

              let limitationNotes = benefit.remainingVisitsBenefit?.notes
                ?.length
                ? benefit.remainingVisitsBenefit.notes
                : benefit.maxVisitsBenefit?.notes ?? [];

              // TODO: Make combined limits a real concept in our app
              let combinedLimitNotes: string[] = [];
              // if (isDefined(limitationNotes)) {
              //   limitationNotes.forEach((note) => {
              //     if (
              //       note.toLowerCase().includes("combined") ||
              //       note.toLowerCase().includes("comb limit") ||
              //       note.toLowerCase().includes("comb visit") ||
              //       note.toLowerCase().includes("shared with")
              //     ) {
              //       combinedLimitNotes.push(note);
              //     }
              //   });
              //   limitationNotes = limitationNotes.filter((note) => {
              //     return !combinedLimitNotes.includes(note);
              //   });
              // }

              const serviceLimitMet =
                isDefined(benefit.adjustedRemainingVisits) &&
                benefit.adjustedRemainingVisits === 0;

              const serviceDeductibleMax = benefit.maxDeductible;
              const serviceDeductibleRemaining =
                benefit.adjustedRemainingDeductible;

              const showCoinsurance =
                isDefined(benefit.coinsurance) &&
                (benefit!.coinsurance > 0 ||
                  !isDefined(benefit.copay) ||
                  benefit.copay === 0);
              const showCopay =
                isDefined(benefit.copay) &&
                (benefit!.copay > 0 || !showCoinsurance);
              const showLimitation =
                isDefined(benefit.adjustedRemainingVisits) ||
                isDefined(benefit.maxVisits);
              const showServiceDeductible =
                isDefined(serviceDeductibleRemaining) ||
                isDefined(serviceDeductibleMax);

              const noBenefits =
                !showCoinsurance &&
                !showCopay &&
                !showLimitation &&
                !showServiceDeductible;

              const deductibleApplies = benefit.deductibleApplies === true;
              const deductibleMet =
                benefit.adjustedMetDeductible === true ||
                planBenefits.indv.deductible.metDeductible === true ||
                planBenefits.fam.deductible.metDeductible === true;

              // TODO: Factor this out
              let copayCostSharingExplanation;
              let coinsuranceCostSharingExplanation;
              const costSharingType = getCostSharingType(benefit);
              if (costSharingType === "copay" || costSharingType === "both") {
                if (deductibleApplies) {
                  if (deductibleMet) {
                    copayCostSharingExplanation =
                      "per visit until out-of-pocket max is met";
                  } else {
                    copayCostSharingExplanation = "after deductible is met";
                  }
                } else {
                  copayCostSharingExplanation = "per visit";
                }
              }
              if (
                costSharingType === "coinsurance" ||
                costSharingType === "both"
              ) {
                if (deductibleApplies) {
                  if (deductibleMet) {
                    coinsuranceCostSharingExplanation =
                      "per visit until out-of-pocket max is met";
                  } else {
                    coinsuranceCostSharingExplanation =
                      "after deductible is met";
                  }
                } else {
                  coinsuranceCostSharingExplanation = "per visit";
                }
              }

              return (
                <div
                  className="p-2 mb-2 border-2 border-gray-200 rounded-md text-lg"
                  key={key}
                >
                  <div className="pb-2 border-b border-gray-300 md:flex md:justify-between">
                    <h2 className="text-xl font-semibold text-gray-900">
                      {benefit.providerServiceConfiguration.name}
                    </h2>
                    {serviceLimitMet && (
                      <span className="text-red-500 text-sm">
                        Annual service limit reached - Service not covered
                      </span>
                    )}
                  </div>
                  {noBenefits ? (
                    <div className="pt-2">No information</div>
                  ) : (
                    <ul className="pt-2 pl-5 list-disc">
                      {showCoinsurance && (
                        <li>
                          <div className="inline">
                            <span
                              className={
                                "text-gray-900" +
                                (!serviceLimitMet ? " font-bold" : "")
                              }
                            >
                              You pay {coinsurance}
                            </span>
                            {coinsuranceCostSharingExplanation && (
                              <span className="pl-1">
                                {coinsuranceCostSharingExplanation}
                              </span>
                            )}
                            {coinsuranceNotes.length > 0 && (
                              <button
                                className="p-1 ml-1 rounded-full hover:bg-gray-200 hover:text-white align-bottom"
                                onClick={() =>
                                  openNoteDiaglog({
                                    name: benefit.providerServiceConfiguration
                                      .name,
                                    notes: coinsuranceNotes,
                                  })
                                }
                              >
                                <InformationCircleIcon className="text-gray-600 h-5" />
                              </button>
                            )}
                          </div>
                        </li>
                      )}
                      {showCopay && (
                        <li>
                          <div className="inline">
                            <span
                              className={
                                "text-gray-900" +
                                (!serviceLimitMet ? " font-bold" : "")
                              }
                            >
                              You pay {copay}
                            </span>
                            {copayCostSharingExplanation && (
                              <span className="pl-1">
                                {copayCostSharingExplanation}
                              </span>
                            )}
                            {copayNotes.length > 0 && (
                              <button
                                className="p-1 ml-1 rounded-full hover:bg-gray-200 hover:text-white align-bottom"
                                onClick={() =>
                                  openNoteDiaglog({
                                    name: benefit.providerServiceConfiguration
                                      .name,
                                    notes: copayNotes,
                                  })
                                }
                              >
                                <InformationCircleIcon className="text-gray-600 h-5" />
                              </button>
                            )}
                          </div>
                        </li>
                      )}
                      {showLimitation && (
                        <li>
                          <div className="inline">
                            <span className="">
                              You have{" "}
                              {isDefined(benefit.adjustedRemainingVisits) && (
                                <>
                                  <span
                                    className={
                                      (benefit.adjustedRemainingVisits === 0 &&
                                        "text-red-500 font-bold") ||
                                      "text-gray-900 font-bold"
                                    }
                                  >
                                    {benefit.adjustedRemainingVisits} remaining
                                  </span>
                                </>
                              )}
                              <span>
                                {isDefined(benefit.maxVisits) ? " of " : ""}
                                {benefit.maxVisits}
                              </span>{" "}
                              <button
                                onClick={() =>
                                  setHelpDialog({
                                    label: "Visit Limit",
                                    helpText: VISIT_LIMIT_HELP_TEXT,
                                  })
                                }
                              >
                                <span className="decoration-dotted underline underline-offset-2">
                                  covered
                                </span>
                              </button>{" "}
                              visits
                            </span>
                            {limitationNotes.length > 0 && (
                              <button
                                className="p-1 ml-1 rounded-full hover:bg-gray-200 hover:text-white align-bottom"
                                onClick={() =>
                                  openNoteDiaglog({
                                    name: benefit.providerServiceConfiguration
                                      .name,
                                    notes: limitationNotes,
                                  })
                                }
                              >
                                <InformationCircleIcon className="text-gray-600 h-5" />
                              </button>
                            )}
                          </div>
                        </li>
                      )}
                      {combinedLimitNotes.length > 0 && (
                        <div className="bg-gray-50 border-gray-400 p-2">
                          <div className="ml-1 pl-4 text-gray-700">
                            {combinedLimitNotes.map((note) => {
                              return (
                                <li>
                                  <div className="text-sm">{note}</div>
                                </li>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {showServiceDeductible && (
                        <li>
                          <div className="inline">
                            {isDefined(serviceDeductibleMax) &&
                            serviceDeductibleMax === 0 ? (
                              <span>
                                Deductible does not apply to this service
                              </span>
                            ) : (
                              <span>
                                {isDefined(serviceDeductibleRemaining) &&
                                serviceDeductibleRemaining === 0 ? (
                                  <span>
                                    Your deductible{" "}
                                    {isDefined(serviceDeductibleMax) && (
                                      <span>
                                        {" "}
                                        of {formatUSD(serviceDeductibleMax)}
                                      </span>
                                    )}{" "}
                                    has been met
                                  </span>
                                ) : (
                                  <span>
                                    {isDefined(serviceDeductibleRemaining) &&
                                      serviceDeductibleRemaining !== 0 && (
                                        <span className="text-gray-900">
                                          {formatUSD(
                                            serviceDeductibleRemaining
                                          )}{" "}
                                          <button
                                            onClick={() =>
                                              setHelpDialog({
                                                label:
                                                  "Service level deductible",
                                                helpText:
                                                  SERVICE_DEDUCTIBLE_HELP_TEXT,
                                              })
                                            }
                                          >
                                            <span className="decoration-dotted underline underline-offset-2">
                                              deductible
                                            </span>
                                          </button>{" "}
                                          remaining of{" "}
                                        </span>
                                      )}{" "}
                                    {isDefined(serviceDeductibleMax) && (
                                      <span className="text-gray-900">
                                        {formatUSD(serviceDeductibleMax)} max
                                      </span>
                                    )}
                                  </span>
                                )}
                              </span>
                            )}
                          </div>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              );
            })}
          </section>
          <section className="col-span-3 lg:col-start-2 lg:col-span-2 py-2">
            <p className="text-sm">
              Disclaimer: Benefit coverage is determined by your specific policy
              documents, such as an Evidence of Coverage or Schedule of
              Benefits, and applicable laws and regulations that may require
              coverage for a specific service. Payment of benefits are subject
              to all terms, conditions, limitations, and exclusions of the
              contract with your insurance company at time of service.
            </p>
          </section>
        </div>

        <Transition.Root show={notesDialogOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => setNotesDialogOpen(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-medium leading-6 text-gray-900"
                  >
                    {noteBenefit?.name} Insurance Benefit Notes
                  </Dialog.Title>
                  <div className="mt-2 text-sm">
                    <ul className="list-disc pl-5">
                      {noteBenefit?.notes.map((note, i) => (
                        <li key={i}>{note}</li>
                      ))}
                    </ul>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                      onClick={() => setNotesDialogOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Help dialog */}
        <Transition.Root show={!!helpDialog} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => setHelpDialog(null)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-medium leading-6 text-gray-900"
                  >
                    {helpDialog?.label}
                  </Dialog.Title>
                  <div className="mt-2 text-sm">{helpDialog?.helpText}</div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                      onClick={() => setHelpDialog(null)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <FeedbackSurvey
          title="How helpful was this insurance benefits summary?"
          showSurvey={showSurvey}
          setShowSurvey={setShowSurvey}
          feedbackType={FeedbackType.PATIENT_BENEFITS}
          patientId={insurancePolicy.patient.id}
        />
      </div>
    </div>
  );
};

const PatientBenefits: React.FC<
  React.PropsWithChildren<{ insurancePolicyId: string }>
> = ({ insurancePolicyId }) => {
  const { loading, data } = useQuery<
    GetPatientPortalBenefits,
    GetPatientPortalBenefitsVariables
  >(GET_PATIENT_PORTAL_BENEFITS, {
    variables: { insurancePolicyId },
  });

  if (loading)
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );

  return data?.patientInsurancePolicy ? (
    <Benefits insurancePolicy={data.patientInsurancePolicy} />
  ) : (
    <NotFound />
  );
};

const ProgressSection: React.FC<
  React.PropsWithChildren<{
    label: string;
    helpText: string;
    remaining?: number | null;
    max?: number | null;
    openHelpDialog: (arg: { label: string; helpText: string } | null) => void;
  }>
> = ({ label, helpText, openHelpDialog, remaining, max }) => {
  const percentageSpent =
    isDefined(remaining) && !!max && formatPercentage(1 - remaining / max);
  return (
    <div className="py-4">
      <div className="flex">
        {isDefined(remaining) ? (
          <div className="w-full text-xl inline">
            <span className="font-semibold text-gray-900">
              {formatUSD(remaining)}
            </span>{" "}
            <button onClick={() => openHelpDialog({ label, helpText })}>
              <span className="decoration-dotted underline underline-offset-2 pr-1">
                {label}
              </span>
            </button>
            Remaining
          </div>
        ) : (
          isDefined(max) && (
            <div className="w-full text-xl inline">
              <span className="font-semibold text-gray-900">
                {formatUSD(max)}
              </span>{" "}
              <button onClick={() => openHelpDialog({ label, helpText })}>
                <span className="decoration-dotted underline underline-offset-2 pr-1">
                  {label}
                </span>
              </button>
              Max
            </div>
          )
        )}
      </div>
      <div className="flex">
        <div className="w-full bg-gray-200 h-3 rounded-lg">
          <div
            className="bg-green-600 h-3 rounded-lg"
            style={{ width: `${percentageSpent}` }}
          ></div>
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          {isDefined(remaining) && max && formatUSD(max - remaining) + " spent"}
        </div>
        <div>{max && "out of " + formatUSD(max)}</div>
      </div>
    </div>
  );
};
