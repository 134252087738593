import { gql, useQuery } from "@apollo/client";
import {
  GetPatientCredits,
  GetPatientCreditsVariables,
} from "../generated/GetPatientCredits";

const GET_PATIENT_CREDITS = gql`
  query GetPatientCredits($ids: [String!]!) {
    getPatientCredits(ids: $ids) {
      id
      totalPatientUnallocatedCredits
    }
  }
`;

export const usePatientCredits = (ids: string[], enabled = true) => {
  return useQuery<GetPatientCredits, GetPatientCreditsVariables>(
    GET_PATIENT_CREDITS,
    {
      variables: { ids },
      skip: !enabled || ids.length === 0,
      // Cache for 5 minutes
      fetchPolicy: "cache-first",
    }
  );
};
