import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {
  isValidPhoneNumber,
  formatPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import parsePhoneNumberFromString from "libphonenumber-js";
import "react-phone-number-input/style.css";
import { SettingsLayout } from "..";
import {
  GetMyOrganization_me_organization as Organization,
  GetMyOrganization_me_organization_locations as Location,
} from "../../../generated/GetMyOrganization";
import { Card, SubmitButton, Tooltip, Spinner } from "../../../components";
import { gql, useMutation } from "@apollo/client";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import {
  cn,
  formatUSD,
  getNumberWithOrdinal,
  isDefined,
  mapNullable,
  toCents,
  toDollars,
} from "../../../utils";
import { AutopayCycleTimeline } from "../../billing/batches";
import { OvalSpinner } from "../../../components/loading";
import { format } from "date-fns";
import React from "react";
import { DollarInput } from "../../../components/input";
import {
  UpdateLocation,
  UpdateLocationVariables,
} from "../../../generated/UpdateLocation";
import {
  UpdateLocationAndAutopayWorkflow,
  UpdateLocationAndAutopayWorkflowVariables,
} from "../../../generated/UpdateLocationAndAutopayWorkflow";
import {
  DisableAutopayCycle,
  DisableAutopayCycleVariables,
} from "../../../generated/DisableAutopayCycle";
import {
  EnableAutopayCycle,
  EnableAutopayCycleVariables,
} from "../../../generated/EnableAutopayCycle";
import { LocationBillingSettingsFragment } from "../../../graphql";

const ENABLE_AUTOPAY_CYCLE = gql`
  ${LocationBillingSettingsFragment}
  mutation EnableAutopayCycle($locationId: String!) {
    enableAutopayCycle(locationId: $locationId) {
      success
      location {
        ...LocationBillingSettingsFragment
      }
    }
  }
`;

const DISABLE_AUTOPAY_CYCLE = gql`
  ${LocationBillingSettingsFragment}
  mutation DisableAutopayCycle($locationId: String!) {
    disableAutopayCycle(locationId: $locationId) {
      success
      location {
        ...LocationBillingSettingsFragment
      }
    }
  }
`;

const UPDATE_LOCATION_AND_AUTOPAY_WORKFLOW = gql`
  ${LocationBillingSettingsFragment}
  mutation UpdateLocationAndAutopayWorkflow(
    $locationId: String!
    $data: LocationUpdateInput!
  ) {
    updateLocationAndAutopayWorkflow(locationId: $locationId, data: $data) {
      success
      location {
        ...LocationBillingSettingsFragment
      }
    }
  }
`;

const UPDATE_LOCATION = gql`
  ${LocationBillingSettingsFragment}
  mutation UpdateLocation($locationId: String!, $data: LocationUpdateInput!) {
    updateOneLocation(where: { id: $locationId }, data: $data) {
      ...LocationBillingSettingsFragment
    }
  }
`;

type LocationBillingFields = {
  billingEmail: string | null;
  billingPhone: string | null;
  billingCheckPayable: string | null;
  billingDefaultMessage: string | null;
};

const EditCloseDateDialog: React.FC<
  React.PropsWithChildren<{
    location: Location;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ location, open, setOpen }) => {
  const [
    updateLocationAndAutopayWorkflow,
    updateLocationAndAutopayWorkflowResult,
  ] = useMutation<
    UpdateLocationAndAutopayWorkflow,
    UpdateLocationAndAutopayWorkflowVariables
  >(UPDATE_LOCATION_AND_AUTOPAY_WORKFLOW);

  const [delay, setDelay] = useState<number>(
    location.balanceAutopayCycleCloseDate
  );

  const onSubmit = async () => {
    updateLocationAndAutopayWorkflow({
      variables: {
        locationId: location.id,
        data: {
          balanceAutopayCycleCloseDate: { set: delay },
        },
      },
      onCompleted: (data) => {
        if (data.updateLocationAndAutopayWorkflow.success) {
          toast.success("Settings updated");
          setOpen(false);
        } else {
          toast.error("Error updating settings");
        }
      },
      onError: () => {
        toast.error("Error updating settings");
      },
    });
  };

  const valid = isDefined(delay) && delay >= 0 && delay <= 28;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Billing Cycle Close Date
                    </Dialog.Title>
                    <div className="flex flex-col gap-2 mt-2">
                      <input
                        type="number"
                        defaultValue={location.balanceAutopayCycleCloseDate}
                        min={0}
                        max={28}
                        onChange={(e) => {
                          setDelay(parseInt(e.target.value));
                        }}
                        className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <SubmitButton
                    type="button"
                    onClick={onSubmit}
                    loading={updateLocationAndAutopayWorkflowResult.loading}
                    disabled={!valid}
                  >
                    Save
                  </SubmitButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const EditDelayDialog: React.FC<
  React.PropsWithChildren<{
    location: Location;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ location, open, setOpen }) => {
  const [
    updateLocationAndAutopayWorkflow,
    updateLocationAndAutopayWorkflowResult,
  ] = useMutation<
    UpdateLocationAndAutopayWorkflow,
    UpdateLocationAndAutopayWorkflowVariables
  >(UPDATE_LOCATION_AND_AUTOPAY_WORKFLOW);

  const [delay, setDelay] = useState(location.balanceAutopayChargeDelay);

  const onSubmit = async (data: any) => {
    updateLocationAndAutopayWorkflow({
      variables: {
        locationId: location.id,
        data: {
          balanceAutopayChargeDelay: { set: delay },
        },
      },
      onCompleted: (data) => {
        if (data.updateLocationAndAutopayWorkflow.success) {
          toast.success("Settings updated");
          setOpen(false);
        } else {
          toast.error("Error updating settings");
        }
      },
      onError: () => {
        toast.error("Error updating settings");
      },
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Charge Delay
                    </Dialog.Title>
                    <div className="flex flex-col gap-2 mt-2">
                      <input
                        type="number"
                        min={2}
                        max={27}
                        defaultValue={location.balanceAutopayChargeDelay}
                        onChange={(e) => setDelay(parseInt(e.target.value))}
                        className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <SubmitButton
                    type="button"
                    onClick={onSubmit}
                    loading={updateLocationAndAutopayWorkflowResult.loading}
                  >
                    Save
                  </SubmitButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const EditTimeDialog: React.FC<
  React.PropsWithChildren<{
    location: Location;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ location, open, setOpen }) => {
  const [
    updateLocationAndAutopayWorkflow,
    updateLocationAndAutopayWorkflowResult,
  ] = useMutation<
    UpdateLocationAndAutopayWorkflow,
    UpdateLocationAndAutopayWorkflowVariables
  >(UPDATE_LOCATION_AND_AUTOPAY_WORKFLOW);
  const [hourOfDay, setHourOfDay] = useState(location.balanceAutopayHourOfDay);
  const [minuteOfHour, setMinuteOfHour] = useState(
    location.balanceAutopayMinuteOfHour
  );
  const [timezone, setTimezone] = useState(location.balanceAutopayTimezone);

  const onSubmit = async (data: any) => {
    updateLocationAndAutopayWorkflow({
      variables: {
        locationId: location.id,
        data: {
          balanceAutopayHourOfDay: { set: hourOfDay },
          balanceAutopayMinuteOfHour: { set: minuteOfHour },
          balanceAutopayTimezone: { set: timezone },
        },
      },
      onCompleted: (data) => {
        if (data.updateLocationAndAutopayWorkflow.success) {
          toast.success("Settings updated");
          setOpen(false);
        } else {
          toast.error("Error updating settings");
        }
      },
      onError: () => {
        toast.error("Error updating settings");
      },
    });
  };

  const timezones: string[] = React.useMemo(
    () =>
      // @ts-ignore Assume the browser supports Intl
      Intl.supportedValuesOf("timeZone"),
    []
  );

  const defaultTime = `${location.balanceAutopayHourOfDay
    .toString()
    .padStart(2, "0")}:${location.balanceAutopayMinuteOfHour
    .toString()
    .padStart(2, "0")}`;

  const handleTimeChange = (time: string) => {
    const [hour, minute] = time.split(":");
    setHourOfDay(parseInt(hour));
    setMinuteOfHour(parseInt(minute));
  };

  const valid =
    isDefined(hourOfDay) &&
    isDefined(minuteOfHour) &&
    isDefined(timezone) &&
    hourOfDay >= 0 &&
    hourOfDay <= 23 &&
    minuteOfHour >= 0 &&
    minuteOfHour <= 59 &&
    timezones.includes(timezone);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Charge Notification/Charge Time
                    </Dialog.Title>
                    <div className="flex flex-col gap-2 mt-2">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Time of Day
                      </label>
                      <input
                        type="time"
                        defaultValue={defaultTime}
                        onChange={(e) => {
                          handleTimeChange(e.target.value);
                        }}
                        className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm"
                      />
                    </div>
                    <div className="flex flex-col gap-2 mt-2">
                      <select
                        id="balanceAutopayTimezone"
                        name="balanceAutopayTimezone"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={location.balanceAutopayTimezone}
                        onChange={(e) => setTimezone(e.target.value)}
                      >
                        {timezones.map((tz) => (
                          <option key={tz}>{tz}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <SubmitButton
                    type="button"
                    onClick={onSubmit}
                    loading={updateLocationAndAutopayWorkflowResult.loading}
                    disabled={!valid}
                  >
                    Save
                  </SubmitButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const EditMaxAutopayLimitDialog: React.FC<
  React.PropsWithChildren<{
    location: Location;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ location, open, setOpen }) => {
  const [updateLocation, updateLocationResult] = useMutation<
    UpdateLocation,
    UpdateLocationVariables
  >(UPDATE_LOCATION);
  const [defaultMaxAutopayLimit, setDefaultMaxAutopayLimit] = useState(
    location.defaultMaxAutopayLimit
  );

  const onSubmit = async (data: any) => {
    updateLocation({
      variables: {
        locationId: location.id,
        data: {
          defaultMaxAutopayLimit: { set: defaultMaxAutopayLimit },
        },
      },
      onCompleted: (data) => {
        toast.success("Settings updated");
      },
      onError: () => {
        toast.error("Error updating settings");
      },
    });
  };

  const valid =
    !isDefined(defaultMaxAutopayLimit) || defaultMaxAutopayLimit >= 100;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Default Max Autopay Limit
                    </Dialog.Title>
                    <div className="flex flex-col gap-2 mt-2">
                      <DollarInput
                        containerClassName="relative rounded-md border"
                        className="rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full min-h-[2em] pl-7 py-1 sm:text-sm border-gray-300"
                        placeholder="0.00"
                        decimalsLimit={2}
                        step={0.01}
                        min={1}
                        defaultValue={
                          defaultMaxAutopayLimit
                            ? toDollars(defaultMaxAutopayLimit)
                            : undefined
                        }
                        onValueChange={(amount) => {
                          const cents = mapNullable(toCents)(amount as any);
                          setDefaultMaxAutopayLimit(cents);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <SubmitButton
                    type="button"
                    onClick={onSubmit}
                    loading={updateLocationResult.loading}
                    disabled={!valid}
                  >
                    Save
                  </SubmitButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const LocationBillingSettings: React.FC<
  React.PropsWithChildren<{ location: Location }>
> = ({ location }) => {
  const [enableAutopayCycle, enableAutopayCycleResult] = useMutation<
    EnableAutopayCycle,
    EnableAutopayCycleVariables
  >(ENABLE_AUTOPAY_CYCLE);
  const [disableAutopayCycle, disableAutopayCycleResult] = useMutation<
    DisableAutopayCycle,
    DisableAutopayCycleVariables
  >(DISABLE_AUTOPAY_CYCLE);
  const [automatedPaymentsEnabled, setAutomatedPaymentsEnabled] = useState(
    location.balanceAutopayEnabled
  );
  const [externalAutopayEnabled, setExternalAutopayEnabled] = useState(
    location.externalAutopayEnabled
  );
  const [maxAutopayLimitEnabled, setMaxAutopayLimitEnabled] = useState(
    location.maxAutopayLimitEnabled
  );
  const [doubleSidedPaperEnabled, setDoubleSidedPaperEnabled] = useState(
    location.billingPaperDoubleSided || false
  );
  const [updateLocation, updateLocationResult] = useMutation<
    UpdateLocation,
    UpdateLocationVariables
  >(UPDATE_LOCATION);

  const [autopayCloseDateDialogOpen, setAutopayCloseDateDialogOpen] =
    useState(false);
  const [autopayChargeDelayDialogOpen, setAutopayChargeDelayDialogOpen] =
    useState(false);
  const [autopaySendTimeDialogOpen, setAutopaySendTimeDialogOpen] =
    useState(false);
  const [
    defaultMaxAutopayLimitDialogOpen,
    setDefaultMaxAutopayLimitDialogOpen,
  ] = useState(false);

  let scheduleTime = new Date();
  scheduleTime.setHours(location.balanceAutopayHourOfDay);
  scheduleTime.setMinutes(location.balanceAutopayMinuteOfHour);

  const toggleLocationBillingSettings = (val: boolean) => {
    if (val) {
      setAutomatedPaymentsEnabled(true);
      enableAutopayCycle({
        variables: { locationId: location.id },
        onCompleted: (data) => {
          if (data.enableAutopayCycle.success) {
            toast.success("Autopay enabled");
          } else {
            toast.error("Error enabling autopay");
            setAutomatedPaymentsEnabled(false);
          }
        },
        onError: (error) => {
          toast.error("Error enabling autopay");
          setAutomatedPaymentsEnabled(false);
        },
      });
    } else {
      setAutomatedPaymentsEnabled(false);
      disableAutopayCycle({
        variables: { locationId: location.id },
        onCompleted: (data) => {
          if (data.disableAutopayCycle.success) {
            toast.success("Autopay disabled");
          } else {
            toast.error("Error disabling autopay");
            setAutomatedPaymentsEnabled(true);
          }
        },
        onError: (error) => {
          toast.error("Error disabling autopay");
          setAutomatedPaymentsEnabled(true);
        },
      });
    }
  };

  const toggleLocationExternalAutopay = (val: boolean) => {
    setExternalAutopayEnabled(val);
    updateLocation({
      variables: {
        locationId: location.id,
        data: {
          externalAutopayEnabled: { set: val },
        },
      },
      onCompleted: (data) => {
        toast.success(`External autopay tasks ${val ? "enabled" : "disabled"}`);
      },
      onError: (error) => {
        toast.error("Error enabling external autopay tasks");
        setExternalAutopayEnabled(!val);
      },
    });
  };

  const toggleLocationMaxAutopayLimit = (val: boolean) => {
    setMaxAutopayLimitEnabled(val);
    updateLocation({
      variables: {
        locationId: location.id,
        data: {
          maxAutopayLimitEnabled: { set: val },
        },
      },
      onCompleted: (data) => {
        toast.success(`Max Autopay Limit ${val ? "enabled" : "disabled"}`);
      },
      onError: (error) => {
        toast.error("Error enabling Max Autopay Limit");
        setMaxAutopayLimitEnabled(!val);
      },
    });
  };

  const toggleDoubleSidedPapeStatements = (val: boolean) => {
    setDoubleSidedPaperEnabled(val);
    updateLocation({
      variables: {
        locationId: location.id,
        data: {
          billingPaperDoubleSided: { set: val },
        },
      },
      onCompleted: (data) => {
        toast.success(
          `Paper Statement Double-Sided ${val ? "enabled" : "disabled"}`
        );
      },
      onError: (error) => {
        toast.error("Error enabling Paper Statement Double-Sided");
        setDoubleSidedPaperEnabled(!val);
      },
    });
  };

  return (
    <>
      <dl className="w-full space-y-6 px-8 pb-6 divide-y divide-gray-200 text-sm leading-6">
        <Switch.Group as="div" className="flex pt-6">
          <Switch.Label
            as="dt"
            className="flex gap-1 items-center pr-6 font-medium text-gray-900"
            passive
          >
            Monthly Billing Cycles Enabled{" "}
            <Tooltip
              trigger={
                <InformationCircleIcon className="w-4 h-4 text-gray-500" />
              }
              content={
                <div className="max-w-sm text-center">
                  Turn on automated monthly billing cycles to automatically
                  charge your customers enrolled in autopay every month based on
                  your billing cycle settings.
                </div>
              }
            />
          </Switch.Label>
          <dd className="flex flex-auto items-center justify-end gap-2">
            {(enableAutopayCycleResult.loading ||
              disableAutopayCycleResult.loading) && (
              <OvalSpinner className="w-4 h-4 text-gray-700" />
            )}
            <Switch
              checked={automatedPaymentsEnabled}
              onChange={toggleLocationBillingSettings}
              className={cn(
                automatedPaymentsEnabled ? "bg-indigo-600" : "bg-gray-200",
                "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
              disabled={
                enableAutopayCycleResult.loading ||
                disableAutopayCycleResult.loading
              }
            >
              <span
                aria-hidden="true"
                className={cn(
                  automatedPaymentsEnabled
                    ? "translate-x-3.5"
                    : "translate-x-0",
                  "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </dd>
        </Switch.Group>
        <Transition
          enter="transition duration-300 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-200 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          show={automatedPaymentsEnabled}
        >
          <div className="flex flex-col pt-2">
            <div className="text-gray-700">Example Billing Cycle Schedule</div>
            <AutopayCycleTimeline location={location} />
          </div>

          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 flex items-center gap-1 sm:pr-6 sm:w-64">
              Cycle Close Date
              <Tooltip
                trigger={
                  <InformationCircleIcon className="w-4 h-4 text-gray-500" />
                }
                content={
                  <div className="max-w-sm text-center">
                    The day of the month the billing cycle closes. Patients
                    enrolled in autopay will be charged for the amount equal to
                    their ready balance at the end of this date. Patients are
                    notified of their scheduled payment the next day.
                  </div>
                }
              />
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                On the{" "}
                {getNumberWithOrdinal(location.balanceAutopayCycleCloseDate)} of
                the month
              </div>
              <button
                type="button"
                className="font-semibold text-indigo-600 hover:text-indigo-500 disabled:opacity-50"
                onClick={() => setAutopayCloseDateDialogOpen(true)}
                disabled={!automatedPaymentsEnabled}
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 flex items-center gap-1 sm:pr-6">
              Charge Delay
              <Tooltip
                trigger={
                  <InformationCircleIcon className="w-4 h-4 text-gray-500" />
                }
                content={
                  <div className="max-w-sm text-center">
                    Wait this many days after sending the patient their
                    scheduled payment notification before charging them.
                  </div>
                }
              />
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                Wait for {location.balanceAutopayChargeDelay} days after the
                cycle close date
              </div>
              <button
                type="button"
                className="font-semibold text-indigo-600 hover:text-indigo-500 disabled:opacity-50"
                onClick={() => setAutopayChargeDelayDialogOpen(true)}
                disabled={!automatedPaymentsEnabled}
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 flex items-center gap-1 sm:pr-6">
              Charge Time of Day
              <Tooltip
                trigger={
                  <InformationCircleIcon className="w-4 h-4 text-gray-500" />
                }
                content={
                  <div className="max-w-sm text-center">
                    The time of day the scheduled payment notification is sent
                    and the patient is charged.
                  </div>
                }
              />
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                At {format(scheduleTime, "hh:mm a")} in{" "}
                {location.balanceAutopayTimezone}
              </div>
              <button
                type="button"
                className="font-semibold text-indigo-600 hover:text-indigo-500 disabled:opacity-50"
                onClick={() => setAutopaySendTimeDialogOpen(true)}
                disabled={!automatedPaymentsEnabled}
              >
                Update
              </button>
            </dd>
          </div>
          <div>
            <Switch.Group as="div" className="flex pt-6">
              <Switch.Label
                as="dt"
                className="flex gap-1 items-center pr-6 font-medium text-gray-900"
                passive
              >
                Enable billing cycles for patients with external payment methods{" "}
                <Tooltip
                  trigger={
                    <InformationCircleIcon className="w-4 h-4 text-gray-500" />
                  }
                  content={
                    <div className="max-w-sm text-center">
                      For patients with no card on file in external system and
                      not in Pledge, create a task to charge their card on file
                      in the external system instead of automatically charging
                      their card on file in Pledge.
                    </div>
                  }
                />
              </Switch.Label>
              <dd className="flex flex-auto items-center justify-end gap-2">
                {updateLocationResult.loading && (
                  <OvalSpinner className="w-4 h-4 text-gray-700" />
                )}
                <Switch
                  checked={externalAutopayEnabled}
                  onChange={toggleLocationExternalAutopay}
                  className={cn(
                    externalAutopayEnabled ? "bg-indigo-600" : "bg-gray-200",
                    "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )}
                  disabled={updateLocationResult.loading}
                >
                  <span
                    aria-hidden="true"
                    className={cn(
                      externalAutopayEnabled
                        ? "translate-x-3.5"
                        : "translate-x-0",
                      "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </dd>
            </Switch.Group>
          </div>
        </Transition>
        <div className="flex pt-6">
          <dt className="flex gap-1 items-center pr-6 font-medium text-gray-900">
            Autopay Settings
          </dt>
        </div>
        <div>
          <Switch.Group as="div" className="flex pt-6 w-full">
            <Switch.Label
              as="dt"
              className="flex gap-1 items-center pr-6 font-medium text-gray-900"
              passive
            >
              Max Autopay Limit Enabled
              <Tooltip
                trigger={
                  <InformationCircleIcon className="w-4 h-4 text-gray-500" />
                }
                content={
                  <div className="max-w-sm text-center">
                    Allow patients to set a limit on the amount they are charged
                    for each Autopay.
                  </div>
                }
              />
            </Switch.Label>
            <dd className="flex flex-auto items-center justify-end gap-2">
              {updateLocationResult.loading && (
                <OvalSpinner className="w-4 h-4 text-gray-700" />
              )}
              <Switch
                checked={maxAutopayLimitEnabled}
                onChange={toggleLocationMaxAutopayLimit}
                className={cn(
                  maxAutopayLimitEnabled ? "bg-indigo-600" : "bg-gray-200",
                  "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                )}
                disabled={updateLocationResult.loading}
              >
                <span
                  aria-hidden="true"
                  className={cn(
                    maxAutopayLimitEnabled
                      ? "translate-x-3.5"
                      : "translate-x-0",
                    "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </dd>
          </Switch.Group>
          <Transition
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-200 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            show={maxAutopayLimitEnabled}
          >
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 flex items-center gap-1 sm:pr-6">
                Default Max Autopay Limit
                <Tooltip
                  trigger={
                    <InformationCircleIcon className="w-4 h-4 text-gray-500" />
                  }
                  content={
                    <div className="max-w-sm text-center">
                      Set the default limit on the amount patients are charged
                      for
                    </div>
                  }
                />
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {location.defaultMaxAutopayLimit ? (
                    formatUSD(location.defaultMaxAutopayLimit)
                  ) : (
                    <span className="text-gray-400">Not set</span>
                  )}
                </div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500 disabled:opacity-50"
                  onClick={() => setDefaultMaxAutopayLimitDialogOpen(true)}
                  disabled={!maxAutopayLimitEnabled}
                >
                  Update
                </button>
              </dd>
            </div>
          </Transition>
        </div>
        <div>
          <Switch.Group as="div" className="flex pt-6 w-full">
            <Switch.Label
              as="dt"
              className="flex gap-1 items-center pr-6 font-medium text-gray-900"
              passive
            >
              Double Sided Paper Statements Enabled
              <Tooltip
                trigger={
                  <InformationCircleIcon className="w-4 h-4 text-gray-500" />
                }
                content={
                  <div className="max-w-sm text-center">
                    Generated PDFs will be formatted for double-sided printer
                    settings
                  </div>
                }
              />
            </Switch.Label>
            <dd className="flex flex-auto items-center justify-end gap-2">
              {updateLocationResult.loading && (
                <OvalSpinner className="w-4 h-4 text-gray-700" />
              )}
              <Switch
                checked={doubleSidedPaperEnabled}
                onChange={toggleDoubleSidedPapeStatements}
                className={cn(
                  doubleSidedPaperEnabled ? "bg-indigo-600" : "bg-gray-200",
                  "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                )}
                disabled={updateLocationResult.loading}
              >
                <span
                  aria-hidden="true"
                  className={cn(
                    doubleSidedPaperEnabled
                      ? "translate-x-3.5"
                      : "translate-x-0",
                    "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </dd>
          </Switch.Group>
        </div>
      </dl>
      {autopayCloseDateDialogOpen && (
        <EditCloseDateDialog
          open={autopayCloseDateDialogOpen}
          setOpen={setAutopayCloseDateDialogOpen}
          location={location}
        />
      )}
      {autopayChargeDelayDialogOpen && (
        <EditDelayDialog
          open={autopayChargeDelayDialogOpen}
          setOpen={setAutopayChargeDelayDialogOpen}
          location={location}
        />
      )}
      {autopaySendTimeDialogOpen && (
        <EditTimeDialog
          open={autopaySendTimeDialogOpen}
          setOpen={setAutopaySendTimeDialogOpen}
          location={location}
        />
      )}
      {defaultMaxAutopayLimitDialogOpen && (
        <EditMaxAutopayLimitDialog
          open={defaultMaxAutopayLimitDialogOpen}
          setOpen={setDefaultMaxAutopayLimitDialogOpen}
          location={location}
        />
      )}
    </>
  );
};

const LocationBillingForm: React.FC<{ location: Location }> = ({
  location,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<LocationBillingFields>({
    defaultValues: {
      billingCheckPayable: location.billingCheckPayable,
      billingPhone:
        location.billingPhone &&
        parsePhoneNumberFromString(
          location.billingPhone,
          "US"
        )?.number.toString(),
      billingEmail: location.billingEmail,
      billingDefaultMessage: location.billingDefaultMessage,
    },
  });

  const [updateLocation, updateLocationResult] = useMutation<
    UpdateLocation,
    UpdateLocationVariables
  >(UPDATE_LOCATION);

  const onSubmit = (data: any) => {
    updateLocation({
      variables: {
        locationId: location.id,
        data: {
          billingEmail: { set: data.billingEmail },
          billingPhone: { set: formatPhoneNumber(data.billingPhone) },
          billingCheckPayable: { set: data.billingCheckPayable },
          billingDefaultMessage: { set: data.billingDefaultMessage },
        },
      },
      onCompleted: () => {
        toast.success("Billing settings updated");
      },
      onError: (error) => {
        toast.error("Error updating settings");
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="mt-5">
        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-4 lg:col-span-4">
              <label
                htmlFor="billingEmail"
                className={`block text-sm font-medium ${
                  errors.billingEmail ? "text-red-700" : "text-gray-700"
                }`}
              >
                Billing Email
              </label>
              <input
                {...register("billingEmail", {
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z]+\.[a-z]+$/,
                    message: "Invalid email format",
                  },
                })}
                className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
              />
              {errors.billingEmail && (
                <p className="mt-1 text-sm text-red-600 dark:text-red-500">
                  {errors.billingEmail.message}
                </p>
              )}
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                htmlFor="billingPhone"
                className={`block text-sm font-medium ${
                  errors.billingPhone ? "text-red-700" : "text-gray-700"
                }`}
              >
                Billing Phone Number
              </label>
              <PhoneInput
                defaultCountry="US"
                name="billingPhone"
                control={control}
                rules={{
                  validate: (value: string) =>
                    !value ||
                    isValidPhoneNumber(value) ||
                    "Invalid phone number",
                }}
                className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
              />
              {errors.billingPhone && (
                <p className="mt-1 text-sm text-red-600 dark:text-red-500">
                  {errors.billingPhone.message}
                </p>
              )}
            </div>

            <div className="col-span-6 sm:col-span-4 lg:col-span-3">
              <label
                htmlFor="billingCheckPayable"
                className={`block text-sm font-medium ${
                  errors.billingCheckPayable ? "text-red-700" : "text-gray-700"
                }`}
              >
                Billing Check Payable To{" "}
                <Tooltip
                  buttonClassName="align-text-bottom"
                  trigger={
                    <InformationCircleIcon className="w-4 h-4 text-gray-500" />
                  }
                  content={
                    <div className="max-w-sm text-center">
                      The name or entity to whom patients should make checks
                      payable (40 character limit)
                    </div>
                  }
                />
              </label>
              {errors.billingCheckPayable && (
                <p className="mt-1 text-sm text-red-600 dark:text-red-500">
                  {errors.billingCheckPayable.message}
                </p>
              )}
              <input
                type="text"
                {...register("billingCheckPayable", {
                  maxLength: { value: 40, message: "Field is too long" },
                })}
                className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
              />
            </div>

            <div className="col-span-6 sm:col-span-4 lg:col-span-4">
              <label
                htmlFor="billingDefaultMessage"
                className={`block text-sm font-medium ${
                  errors.billingDefaultMessage
                    ? "text-red-700"
                    : "text-gray-700"
                }`}
              >
                Default Billing Message{" "}
                <Tooltip
                  buttonClassName="align-text-bottom"
                  trigger={
                    <InformationCircleIcon className="w-4 h-4 text-gray-500" />
                  }
                  content={
                    <div className="max-w-sm text-center">
                      The default message to a patient you'd like to include in
                      their billing statement (200 character limit)
                    </div>
                  }
                />
                {errors.billingDefaultMessage && (
                  <p className="mt-1 text-sm text-red-600 dark:text-red-500">
                    {errors.billingDefaultMessage.message}
                  </p>
                )}
              </label>
              <textarea
                {...register("billingDefaultMessage", {
                  maxLength: { value: 200, message: "Description is too long" },
                })}
                className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                          resize-none
                        "
                rows={3}
              />
            </div>
          </div>
        </div>
      </Card>
      <div className="pt-2 pr-1 text-right">
        <div className="flex justify-end items-center">
          {updateLocationResult.loading && (
            <Spinner className="text-indigo-600 h-5" />
          )}
          <button
            type="submit"
            disabled={updateLocationResult.loading}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export const WorkspaceLocationBillingSettings: React.FC<{
  organization: Organization;
}> = ({ organization }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const location = organization.locations.find((l) => l.id === locationId)!;
  return (
    <SettingsLayout
      title="Billing Settings"
      description={`Manage your ${location.name} billing settings.`}
      key={locationId}
    >
      <Card>
        <LocationBillingSettings location={location} />
      </Card>
      <LocationBillingForm location={location} />
    </SettingsLayout>
  );
};
