import React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { PortalLayout } from "./portal-layout";
import {
  GetPublicOrganizationData,
  GetPublicOrganizationDataVariables,
} from "../../generated/GetPublicOrganizationData";
import { GET_PUBLIC_ORGANIZATION_DATA } from "../../graphql";
import { Rings } from "../../components/loading";
import { PagePublicOrgHeader } from "./identification";

const TermsOfServiceContent: React.FC<
  React.PropsWithChildren<{
    privacyPolicyUrl: string;
  }>
> = ({ privacyPolicyUrl }) => (
  <div className="max-w-prose mx-auto pt-8 pb-4 px-1 sm:px-0">
    <h1 className="text-2xl pb-2">
      <span className="font-semibold">Terms of Use</span> (Effective Date:
      January 2022)
    </h1>
    <h2 className="text-lg font-semibold">
      Please read these terms of use carefully before using this site
    </h2>
    <ol className="list-decimal list-inside space-y-2">
      <li>
        <span className="text-lg font-semibold">
          Agreement to the Terms of Use.
        </span>{" "}
        Subject to these Terms of Use, Pledge Health, Inc. (referred to herein
        as “Pledge,” “we,” “us” and “our”) governs your use of our web pages
        located at{" "}
        <a
          href="https://app.pledge.health"
          className="text-gray-600 hover:text-gray-500"
        >
          https://app.pledge.health
        </a>{" "}
        (the “Site”). Our Privacy Policy also governs your use of our Service
        and explains how we collect, safeguard and disclose information that
        results from your use of our web pages. Please read it {" "}
        <Link
          to={privacyPolicyUrl}
          className="text-gray-600 hover:text-gray-500"
        >
          here
        </Link>
        . Your agreement with us includes these Terms and our Privacy Policy
        (“Agreements”). You acknowledge that you have read and understood
        Agreements, and agree to be bound of them. If you do not agree with (or
        cannot comply with) Agreements, then you may not use the Service, but
        please let us know by emailing at support@pledge.health so we can try to
        find a solution. These Terms apply to all visitors, users and others who
        wish to access or use Service.
      </li>
      <li>
        <span className="text-lg font-semibold">
          Pledge's Relationship to Your Healthcare Provider (your "Provider")
        </span>{" "}
        Pledge is acting as a vendor and business associate of your Provider.
        When agreeing to these Terms of Use, you are agreeing with Pledge and
        not your Provider. When accessing your Provider's sites, you will be
        agreeing to a different terms of use than these Terms of Use.
      </li>
      <li>
        <span className="text-lg font-semibold">Use of the Site.</span> You may
        browse and use the Site solely for your personal, non-commercial
        purposes and in compliance with these Terms of Use. You may not
        distribute, modify, transmit, publish, reuse, repost or use the Site or
        any content made available on the Site: (i) for public or commercial
        purposes without the express written permission of Pledge; (ii) in a
        manner inconsistent with these Terms of Use; (iii) in violation of any
        applicable law, rule, regulation or order; or (iv) in a manner that
        could damage, disable, overburden or impair the Site or interfere with
        any other party's use or enjoyment of the Site. You will not use the
        Site for junk mail, 'spamming' solicitations (commercial or
        non-commercial), chain letters or bulk communications of any kind,
        including but not limited to distribution lists to any person who has
        not given specific permission to be included in such a list. You will
        not transmit, distribute, introduce or otherwise make available in any
        manner through the Site any computer virus, keyloggers, spyware, worms,
        Trojan horses, timebombs or other malicious or harmful programming
        (collectively, “Viruses”). We do not have an obligation to detect the
        presence of such Viruses. You may not use the Site in connection with
        requesting, harvesting, obtaining or storing any personal information,
        passwords, account information or information about other users of the
        Site. You may not use any data mining, robots, or similar data gathering
        and extraction tools in connection with the Site. If you download
        software or any other content from the Site, you do so at your own risk.
        If you access this Site from outside the United States, you accept full
        responsibility for compliance with local laws. You have the right to
        receive an account statement showing your account activity. You may view
        your account statement by logging into your account. In the event you
        request that we send you hard-copy records related to your account, we
        may charge you a fee of $10.00 per item requested but we won't charge
        you for records requested in connection with your good-faith assertion
        of an error in your account.
      </li>
      <li>
        <span className="text-lg font-semibold">Termination/Suspension.</span>{" "}
        We may suspend or terminate your use of the Site at any time, for any
        reason or for no reason. We reserve the right to change, suspend, or
        discontinue all or any aspect of the Site at any time without notice.
        You may close your account and terminate your relationship with us at
        any time without cost, but you will remain liable for all obligations
        related to your account even after the account is closed. When you close
        your account, we will cancel any scheduled or incomplete transactions.
        You must withdraw or transfer any balance from your account before
        closing it.
      </li>
      <li>
        <span className="text-lg font-semibold">
          Links to Third Party Sites.
        </span>{" "}
        Our Site may include links to third party sites (“Third Party Sites”),
        which are included to serve as a resource for our users. Links to any
        Third Party Sites are not intended to be and should not be interpreted
        as endorsements of the Third Party Sites or their respective policies or
        practices. Such Third Party Sites are not controlled by Pledge and
        therefore Pledge holds no responsibility for their content. We encourage
        you to review the terms of use and privacy policies of such Third Party
        Sites.
      </li>
      <li>
        <span className="text-lg font-semibold">Amendments to Terms.</span> We
        may amend these Terms of Use at any time by posting the amended terms on
        this site. It is your responsibility to review these Terms periodically.
        Your continued use of the Site following the posting of revised Terms
        means that you accept and agree to the changes. You are expected to
        check this page frequently so you are aware of any changes, as they are
        binding on you. By continuing to access or use our Site after any
        revisions become effective, you agree to be bound by the revised terms.
        If you do not agree to the new terms, you are no longer authorized to
        use Site.
      </li>
      <li>
        <span className="text-lg font-semibold">Disclaimer of Warranties.</span>{" "}
        YOU EXPRESSLY AGREE THAT USE OF THE SITE AND CONTENT HEREIN IS AT YOUR
        SOLE RISK. THE SITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
        PLEDGE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS OR
        IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. WE MAKE NO
        WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, OR THAT THE SITE OR
        YOUR USE THEREOF WILL BE UNINTERRUPTED, TIMELY, SECURE, CURRENT,
        ACCURATE, COMPLETE OR ERROR FREE. WE DO NOT GUARANTEE OR WARRANT THAT
        THE SITE WILL BE FREE OF INFECTION FROM VIRUSES, WORMS, OR OTHER CODE
        THAT HAS CONTAMINATING OR DESTRUCTIVE PROPERTIES. THE WARRANTY
        DISCLAIMERS SET FORTH IN THIS SECTION 8 DO NOT APPLY TO THE EXTENT
        PROHIBITED BY LAW. PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH
        PROHIBITIONS.
      </li>
      <li>
        <span className="text-lg font-semibold">Limitations of Liability.</span>{" "}
        IN NO EVENT WILL PLEDGE BE LIABLE FOR ANY DAMAGES, DIRECT OR INDIRECT,
        INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, INCIDENTAL OR PUNITIVE
        DAMAGES (AND DAMAGES FROM LOST PROFITS, INTERRUPTION OF BUSINESS, LOST
        DATA), WHETHER FORESEEABLE OR NOT, WHICH MAY ARISE OUT OF YOUR ACCESS
        TO, INABILITY TO ACCESS, OR YOUR USE OF, THE SITE. THE LIMITATIONS OF
        LIABILITY SET FORTH IN THIS SECTION 9 DO NOT APPLY TO THE EXTENT
        PROHIBITED BY LAW. PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH
        PROHIBITIONS. WITHOUT LIMITING ANY OTHER PROVISIONS IN THIS SECTION 9,
        PLEDGE'S MAXIMUM LIABILITY TO YOU FOR ANY REASON, AND YOUR SOLE AND
        EXCLUSIVE REMEDY FOR ANY CAUSE OR CLAIM WHATSOEVER, WILL BE LIMITED TO
        DIRECT DAMAGES ACTUALLY INCURRED BY YOU, BUT NOT EXCEEDING $100 OR THE
        MINIMUM AMOUNT PERMITTED BY APPLICABLE LAW, WHICHEVER IS LESS.
      </li>
      <li>
        <span className="text-lg font-semibold">Indemnification.</span> Unless
        prohibited by applicable law, you agree to indemnify and hold harmless
        Pledge and its officers, directors and employees, from and against all
        losses, expenses, damages and costs, including reasonable attorneys'
        fees, resulting from or related to your use of this Site, the violation
        of these Terms of Use by you, or the infringement by you, or any other
        user using your account, of any intellectual property or other right of
        any person or entity. IF YOU ARE OBLIGATED TO INDEMNIFY US HEREUNDER, WE
        MAY, IN OUR SOLE DISCRETION, CONTROL THE DEFENSE AND DISPOSITION OF ANY
        SUCH CLAIMS AT YOUR SOLE COST AND EXPENSE. WITHOUT LIMITING THE
        FOREGOING, YOU MAY NOT SETTLE, COMPROMISE OR IN ANY OTHER MANNER DISPOSE
        OF ANY THIRD-PARTY CLAIM WITHOUT OUR CONSENT.
      </li>
      <li>
        <span className="text-lg font-semibold">
          Remedies for Breach of these Terms of Use by You.
        </span>{" "}
        Without limiting any other rights or remedies, in the event that we
        determine, in our sole discretion, that you have breached any portion of
        these Terms of Use, or have otherwise demonstrated conduct that we deem
        to be inappropriate or unacceptable in our sole discretion, we may (i)
        warn you via e-mail that you have violated these Terms of Use; (ii)
        delete any content provided by you or your agent(s) to the Site; (iii)
        discontinue your access to the Site, (iv) notify and/or send your
        information or content to and/or fully cooperate with law enforcement
        authorities for further action; and/or (v) any other action which we
        deem to be appropriate. If your ability to access and/or use the Site or
        any other service provided to you by Pledge is discontinued by us
        hereunder, then you agree that you shall not attempt to re-register with
        or access the Site, any other service provided by us, through use of a
        different member name or otherwise.
      </li>
      <li>
        <span className="text-lg font-semibold">
          Personal Information/Privacy Policy.
        </span>{" "}
        We value your privacy and are committed to safeguarding your personal
        information. Please refer to our{" "}
        <Link
          to={privacyPolicyUrl}
          className="text-gray-600 hover:text-gray-500"
        >
          privacy policy
        </Link>{" "}
        for details about what information we collect on this Site and how we
        use and share such information.
      </li>
      <li>
        <span className="text-lg font-semibold">
          Copyright and Trademark Notice.
        </span>{" "}
        This Site is owned and operated by Pledge. All trademarks, service marks
        and company names are the property of their respective owners. Unless
        noted otherwise, all Site content, including the text, design, documents
        and images are the sole property of Pledge or licensed through a third
        party. Users may use the content of this Site only for personal use in
        accordance with these Terms of Use. No content may be reproduced,
        distributed, adapted or otherwise used without the prior written
        permission of Pledge or our licensors. Any unauthorized use of the
        content on this Site may violate copyright, trademark or other
        applicable laws.
      </li>
      <li>
        <span className="text-lg font-semibold">Governing Law. </span> These
        Terms shall be governed and construed in accordance with the laws of
        State of Delaware without regard to its conflict of law provisions. Our
        failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service and supersede and replace any
        prior agreements we might have had between us regarding Service.
      </li>
      <li>
        <span className="text-lg font-semibold">Account Notifications.</span>{" "}
        When you share your contact information with us, you are agreeing to
        automatic notifications. This means that you may receive occasional
        notifications about important account events (e.g., new statements are
        ready, an upcoming due date, etc.). Notifications may come via email,
        text message, and/or automated calls and pre-recorded voicemails.
      </li>
      <li>
        <span className="text-lg font-semibold">Miscellaneous.</span> In the
        event that any provision of these Terms of Use conflicts with applicable
        law, rule, regulation or order or if any provision is held invalid by a
        court with competent jurisdiction, then such provision will be deemed to
        be restated to reflect as nearly as possible the original intentions of
        the parties, and the remainder of these Terms of Use will remain in full
        force and effect. Any failure by Pledge to insist upon or enforce strict
        performance of any provision of the Terms of Use or to exercise any
        right under the Terms of Use will not be construed as a waiver or
        relinquishment of Pledge's right to assert or rely upon any such
        provision or right in that or any other instance, rather, the same will
        be and remain in full force and effect. You represent to Pledge that you
        have the authority to access and use the Site according to these Terms
        of Use. These Terms of Use constitute the entire understanding between
        the parties as to the subject matter hereof, and supersede all prior
        agreements and understandings.
      </li>
      <li>
        <span className="text-lg font-semibold">Contact.</span> We welcome your
        comments and questions. Please email us at: support@pledge.health
      </li>
    </ol>
  </div>
);

export const TermsOfServiceOrganization: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  // Optional locationId query parameter
  const { data, loading } = useQuery<
    GetPublicOrganizationData,
    GetPublicOrganizationDataVariables
  >(GET_PUBLIC_ORGANIZATION_DATA, {
    variables: { id: organizationId! },
  });
  if (loading || !data)
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );

  return (
    <PortalLayout organizationId={data.getPublicOrganizationData?.id!}>
      <PagePublicOrgHeader logoUrl={data.getPublicOrganizationData?.logoUrl!} />
      <TermsOfServiceContent
        privacyPolicyUrl={`/portal/${organizationId}/privacy-policy`}
      />
    </PortalLayout>
  );
};

export const TermsOfServiceUniversal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <PortalLayout>
      <PagePublicOrgHeader
        logoUrl={
          "https://s3.us-east-2.amazonaws.com/assets.pledge.health/pledge-logo.png"
        }
      />
      <TermsOfServiceContent privacyPolicyUrl="/paymybill/privacy-policy" />
    </PortalLayout>
  );
};
