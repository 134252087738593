import React from "react";

import {
  Icon,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
  Bold,
  Title,
} from "@tremor/react";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/outline";
import { FastForwardIcon } from "@heroicons/react/solid";
import { Button as UiButton } from "../../components/ui/button";
import { Button } from "../../components/Button";
import { ChevronLeftIcon, ChevronRightIcon, MinusCircle } from "lucide-react";
import { cn } from "../../utils";
import { Card } from "../../components/ui/card";

// Status for the New Patients Timeline
export enum InsurancePolicyRegistrationStage {
  Unverified = 0,
  Verified = 1,
  AwaitingSend = 2,
  Complete = 3,
}

export const WorklistProgressSection: React.FC<{
  currentIndex: number;
  total: number;
  next: () => void;
  previous: () => void;
  nextDisabled?: boolean;
}> = ({ currentIndex, total, next, previous, nextDisabled }) => {
  const progress = 100 * (currentIndex / total);
  return (
    <>
      <div className="flex justify-between pt-4">
        <div className="flex gap-2 items-center">
          <Metric>{currentIndex}</Metric> complete
        </div>
        <div className="flex gap-2 items-center">
          of <Metric>{total}</Metric> policies
        </div>
      </div>
      <ProgressBar value={progress} color="emerald" className="mt-1" />

      <div className="flex justify-between items-center pt-2">
        <UiButton
          variant="outline"
          className="h-8 w-8 p-0"
          onClick={() => previous()}
          disabled={currentIndex <= 0}
        >
          <span className="sr-only">Go to previous task</span>
          <ChevronLeftIcon className="h-4 w-4" />
        </UiButton>
        <UiButton
          variant="outline"
          className="h-8 w-8 p-0"
          onClick={() => next()}
          disabled={currentIndex >= total - 1 || nextDisabled}
        >
          <span className="sr-only">Go to next task</span>
          <ChevronRightIcon className="h-4 w-4" />
        </UiButton>
      </div>
    </>
  );
};

export const CompletionSection: React.FC<{
  completed: number;
  skipped: number;
  removed: number;
  onClose: () => void;
}> = ({ completed, removed, skipped, onClose }) => {
  return (
    <div className="flex flex-col gap-4 justify-center items-center pt-16">
      <div className="w-full p-4">
        <Card className="px-4 py-8 my-4">
          <div className="text-center">
            <Text className="!text-5xl">🎉</Text>
            <Title className="font-semibold">You're all done!</Title>
          </div>
          <List className="my-4">
            <ListItem>
              <div className="flex justify-start items-center truncate space-x-4">
                <Icon
                  variant="light"
                  icon={CheckCircleIcon}
                  size="md"
                  color="green"
                />
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>Completed</Bold>
                  </Text>
                </div>
              </div>
              <Text>{completed}</Text>
            </ListItem>
            <ListItem>
              <div className="flex justify-start items-center truncate space-x-4">
                <Icon
                  variant="light"
                  icon={FastForwardIcon}
                  size="md"
                  color="gray"
                />
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>Skipped</Bold>
                  </Text>
                </div>
              </div>
              <Text>{skipped}</Text>
            </ListItem>
            <ListItem>
              <div className="flex justify-start items-center truncate space-x-4">
                <Icon
                  variant="light"
                  icon={MinusCircle}
                  size="md"
                  color="red"
                />
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>Removed from Worklist</Bold>
                  </Text>
                </div>
              </div>
              <Text>{removed}</Text>
            </ListItem>
          </List>
          <div className="flex justify-center">
            <Button onClick={onClose}>Close</Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export const StepIcon: React.FC<{
  name: string;
  status: "complete" | "current" | "upcoming";
}> = ({ name, status }) => {
  if (status === "complete") {
    return (
      <div className="group">
        <span className="flex items-start">
          <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
            <CheckCircleIcon
              className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
              aria-hidden="true"
            />
          </span>
          <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
            {name}
          </span>
        </span>
      </div>
    );
  }
  if (status === "current") {
    return (
      <div className="flex items-start" aria-current="step">
        <span
          className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
          aria-hidden="true"
        >
          <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
          <span className="relative block h-2 w-2 rounded-full bg-indigo-600" />
        </span>
        <span className="ml-3 text-sm font-medium text-indigo-600">{name}</span>
      </div>
    );
  }
  if (status === "upcoming") {
    return (
      <div className="group">
        <div className="flex items-start">
          <div
            className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
            aria-hidden="true"
          >
            <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
          </div>
          <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
            {name}
          </p>
        </div>
      </div>
    );
  }
  return null;
};

export enum RegistrationStage {
  NeedsVerification = 0,
  NeedsAcceptance = 1,
  AwaitingSend = 2,
  Complete = 3,
  Skipped = 4,
}

export const TimelineStep: React.FC<
  React.PropsWithChildren<{
    status: "complete" | "current" | "upcoming";
    lastStep: boolean;
    name: string;
  }>
> = ({ status, lastStep, name, children }) => {
  return (
    <>
      {status === "complete" ? (
        <>
          {!lastStep ? (
            <div
              className="absolute left-3 top-4 -ml-[2px] mt-0.5 h-full w-1 bg-indigo-600"
              aria-hidden="true"
            />
          ) : null}
          <div className="group relative flex items-start">
            <span className="flex h-6 items-center">
              <span className="relative flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                <CheckIcon className="h-4 w-4 text-white" aria-hidden="true" />
              </span>
            </span>
            <span className="ml-2 flex min-w-0 w-full flex-col">
              <span className="text-sm font-medium capitalize">{name}</span>
              <div className="py-2">{children}</div>
            </span>
          </div>
        </>
      ) : status === "current" ? (
        <>
          {!lastStep ? (
            <div
              className="absolute left-3 top-4 -ml-[2px] mt-0.5 h-full w-1 bg-gray-300"
              aria-hidden="true"
            />
          ) : null}
          <div className="group relative flex items-start" aria-current="step">
            <span className="flex h-6 items-center" aria-hidden="true">
              <span className="relative flex h-6 w-6 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
              </span>
            </span>
            <span className="ml-2 flex min-w-0 w-full flex-col">
              <span className="text-sm font-medium capitalize text-indigo-600">
                {name}
              </span>
              <div className="py-2">{children}</div>
            </span>
          </div>
        </>
      ) : (
        <>
          {!lastStep ? (
            <div
              className="absolute left-3 top-4 -ml-[2px] mt-0.5 h-full w-1 bg-gray-300"
              aria-hidden="true"
            />
          ) : null}
          <div
            className={cn(
              "group relative flex items-center",
              !lastStep && "pb-4"
            )}
          >
            <span className="flex h-6 items-center" aria-hidden="true">
              <span className="relative flex h-6 w-6 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
              </span>
            </span>
            <span className="ml-2 flex min-w-0 w-full flex-col">
              <span className="text-sm font-medium capitalize text-gray-300">
                {name}
              </span>
              {/* <div className="py-2">{children}</div> */}
            </span>
          </div>
        </>
      )}
    </>
  );
};
