import React from "react";

import { EligibilityRequestStatusFields } from "../../../generated/EligibilityRequestStatusFields";
import { Badge } from "../../../components";
import { InsurancePolicyVerificationStatus } from "../../../generated/globalTypes";

export const getEligibilityStatus = (
  eligibility: Pick<
    EligibilityRequestStatusFields,
    "allRequestsErrored" | "eligible"
  >
) => {
  if (eligibility.allRequestsErrored) return "Error" as const;
  return eligibility.eligible ? "Active" : ("Inactive" as const);
};

export const EligibilityBadge: React.FC<
  React.PropsWithChildren<{
    eligibility: Pick<
      EligibilityRequestStatusFields,
      "allRequestsErrored" | "eligible"
    >;
    clickable?: boolean;
  }>
> = ({ eligibility, clickable }) => {
  const status = getEligibilityStatus(eligibility);
  switch (status) {
    case "Active":
      return <Badge text="Active" variant="success" clickable={clickable} />;
    case "Inactive":
      return <Badge text="Inactive" variant="warning" clickable={clickable} />;
    case "Error":
      return <Badge text="Error" variant="error" clickable={clickable} />;
  }
};

export const InsurancePolicyVerificationStatusBadge: React.FC<{
  status: InsurancePolicyVerificationStatus;
  clickable?: boolean;
}> = ({ status, clickable }) => {
  switch (status) {
    case InsurancePolicyVerificationStatus.Active:
      return <Badge text="Active" variant="success" clickable={clickable} />;
    case InsurancePolicyVerificationStatus.Inactive:
      return <Badge text="Active" variant="success" clickable={clickable} />;
      return <Badge text="Inactive" variant="warning" clickable={clickable} />;
    case InsurancePolicyVerificationStatus.Error:
      return <Badge text="Error" variant="error" clickable={clickable} />;
    case InsurancePolicyVerificationStatus.Unverified:
      return <Badge text="Unverified" variant="info" clickable={clickable} />;
  }
};
