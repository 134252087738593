import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useUser } from "../../user-context";
import { GetEditPatient_patient_insurancePolicies_payer as Payer } from "../../generated/GetEditPatient";
import { SubmitButton } from "../../components";
import { Layout, LoadingLayout } from "../layout";
import { useForm } from "react-hook-form";
import { GET_PAYERS } from "../payers";
import { GetPayers, GetPayersVariables } from "../../generated/GetPayers";
import { BirthSex } from "../../generated/globalTypes";
import { EDIT_PATIENT_FRAGMENT, PatientFormData } from "./edit";
import { PatientForm } from "./patientForm";
import {
  CreatePatient,
  CreatePatientVariables,
} from "../../generated/CreatePatient";
import { randomNumeric } from "../../utils";

const CREATE_PATIENT = gql`
  ${EDIT_PATIENT_FRAGMENT}
  mutation CreatePatient($data: PatientCreateInput!) {
    createOnePatient(data: $data) {
      ...EditPatientFragment
    }
  }
`;

const NewPatientForm: React.FC<
  React.PropsWithChildren<{
    payers: Payer[];
  }>
> = ({ payers }) => {
  const user = useUser()!;
  const [createPatient, createPatientResult] = useMutation<
    CreatePatient,
    CreatePatientVariables
  >(CREATE_PATIENT);

  const navigate = useNavigate();

  const methods = useForm<PatientFormData>({
    reValidateMode: "onChange",
    defaultValues: {
      birthSex: {
        label: "Female",
        value: BirthSex.FEMALE,
      },
    },
  });

  const onSubmit = async (data: PatientFormData) => {
    const patientId = uuidv4();
    const defaultVerificationWorkflowStatus =
      user.activeLocation.defaultVerificationWorkflowStatus ??
      user.activeLocation.verificationWorkflowStatuses.find(
        (s) => s.stage === "Todo"
      )!;
    createPatient({
      variables: {
        data: {
          id: patientId,
          organization: {
            connect: {
              id: user.organization.id,
            },
          },
          location: {
            connect: {
              id: user.activeLocation.id,
            },
          },
          firstName: data.firstName,
          lastName: data.lastName,
          dateOfBirth: data.dateOfBirth,
          birthSex: (data.birthSex.value ?? null) as BirthSex | null,
          email: !!data.email ? data.email : undefined,
          accountNumber: randomNumeric(12),
          accounts: {
            create: [
              {
                organization: {
                  connect: {
                    id: user.organization.id,
                  },
                },
                location: {
                  connect: {
                    id: user.activeLocation.id,
                  },
                },
              },
            ],
          },
          insurancePolicies: {
            create: data.insurancePolicies.map((ip) => ({
              memberId: ip.memberId,
              payer: {
                connect: {
                  id: ip.payer.value,
                },
              },
              groupId: !!ip.groupId ? ip.groupId : null,
              groupName: !!ip.groupName ? ip.groupName : null,
              effectiveDate: !!ip.effectiveDate ? ip.effectiveDate : null,
              renewalDate: !!ip.renewalDate ? ip.renewalDate : null,
              terminationDate: !!ip.terminationDate ? ip.terminationDate : null,
              active: true,
              deletedAt: ip.archived ? ip.deletedAt ?? new Date() : null,
              organization: {
                connect: {
                  id: user.organization.id,
                },
              },
              verificationWorkflowStatus: {
                connect: {
                  id: defaultVerificationWorkflowStatus.id,
                },
              },
            })),
          },
        },
      },
      onCompleted: (data) => {
        toast.success("Patient created");
        navigate(`/patients/${data.createOnePatient.id}`);
      },
      onError: () => {
        toast.error("Error creating patient");
      },
    });
  };

  const errors = methods.formState.errors;

  return (
    <PatientForm
      patient={null}
      methods={methods}
      payers={payers}
      onSubmit={onSubmit}
      formActions={
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link
            to={`/patients`}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </Link>
          <div>
            <SubmitButton loading={createPatientResult.loading} type="submit">
              Save
            </SubmitButton>
          </div>
        </div>
      }
    />
  );
};

export const NewPatient: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser()!;
  const { data, loading } = useQuery<GetPayers, GetPayersVariables>(
    GET_PAYERS,
    {
      variables: {
        organizationId: user.organization.id,
        locationId: user.activeLocation.id,
      },
    }
  );
  if (loading || !data) return <LoadingLayout header="New Patient" />;

  const payers = data?.payers ?? [];

  return (
    <Layout
      header={
        <div className="flex flex-col gap-4">
          <nav className="flex items-center justify-between pt-4">
            <div className="flex items-center gap-2">
              <Link
                className="font-medium text-gray-500 hover:text-gray-700"
                to="/patients"
              >
                Patients
              </Link>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <div className="font-medium text-gray-700">New Patient</div>
            </div>
          </nav>
        </div>
      }
      content={
        <div className="pt-8">
          <NewPatientForm payers={payers} />
        </div>
      }
    />
  );
};
