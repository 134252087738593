"use client";

import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { gql, useMutation } from "@apollo/client";
import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import { Table, ColumnFilter } from "@tanstack/react-table";
import { DialogClose } from "@radix-ui/react-dialog";
import { toast } from "react-toastify";
import { Divider } from "@tremor/react";
import { useEffect } from "react";

import { DataTableMeta } from "../../../pages/appointments/table/table";
import { Button } from "../button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../dialog";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "../dropdown-menu";
import { ServiceTypeCode } from "../../../pages/patients/eligibilities/types";
import { useState } from "react";
import { useUser } from "../../../user-context";
import { cn } from "../../../utils";
import { Checkbox } from "../../../components/ui/checkbox";
import { useFeatureFlags } from "../../../hooks";
import { GET_APPOINTMENT_VIEW_PREFERENCES } from "../../../pages/appointments/table/list";

export const UPDATE_APPOINTMENT_VIEW_PREFERENCES = gql`
  mutation UpdateAppointmentViewPreferences(
    $id: String!
    $appointmentViewPreferences: JSON!
  ) {
    updateOneUser(
      where: { id: $id }
      data: { appointmentViewPreferences: $appointmentViewPreferences }
    ) {
      id
    }
  }
`;

interface DataTableViewOptionsProps<TData> {
  table: Table<TData>;
  className?: string;
}

export function DataTableViewOptionsAppointments<TData>({
  table,
  className,
}: DataTableViewOptionsProps<TData>) {
  const { columnFilters } = table.options.meta as DataTableMeta;
  const columns = table.getAllColumns();

  const [updateAppointmentViewPreferences] = useMutation(
    UPDATE_APPOINTMENT_VIEW_PREFERENCES
  );

  const user = useUser()!;
  const flags = useFeatureFlags();

  const preferences = columns.reduce((finalObj, c) => {
    const isVisible = c.getIsVisible();
    const filter = columnFilters.find((f) => f.id === c.id);
    finalObj[c.id] = {
      visible: isVisible,
      filter,
    };
    return finalObj;
  }, {} as Record<string, { visible: boolean; filter: ColumnFilter | undefined }>);

  const providerServiceConfigurations = [
    ...user.organization.providerServiceConfiguration,
  ].sort((a, b) => (a.priorityOrder ?? 0) - (b.priorityOrder ?? 0));
  const headerRowEntries = Object.entries(preferences).map(
    ([id, { visible }]) => ({
      id,
      label: id.charAt(0).toUpperCase() + id.slice(1),
      checked: visible,
    })
  );

  const providerServiceConfigNames = providerServiceConfigurations.flatMap(
    (config) => {
      if (config.serviceType === ServiceTypeCode.HealthBenefitPlanCoverage) {
        return [
          config.name + " Ind Ded",
          config.name + " Fam Ded",
          config.name + " Ind OOP",
          config.name + " Fam OOP",
        ];
      } else {
        return [
          config.name + " Copay",
          config.name + " Coinsurance",
          config.name + " Deductible",
          config.name + " Out of Pocket",
          config.name + " Visit Limit",
          config.name + " Auth Required",
        ];
      }
    }
  );

  const filteredProviderServiceConfigEntries = headerRowEntries.filter(
    (entry) => {
      return (
        // Todo: Figure out why Actions column is behaving strangely when toggling it on/off
        !providerServiceConfigNames.includes(entry.id) && entry.id !== "Actions"
      );
    }
  );

  const calculateHeaderRow = () => [
    ...filteredProviderServiceConfigEntries,
    ...(flags.benefitsProductEnabled
      ? providerServiceConfigurations.map((config) => {
          if (
            config.serviceType === ServiceTypeCode.HealthBenefitPlanCoverage
          ) {
            const isIndDedVisible =
              preferences[config.name + " Ind Ded"]?.visible;
            const isFamDedVisible =
              preferences[config.name + " Fam Ded"]?.visible;
            const isIndOOPVisible =
              preferences[config.name + " Ind OOP"]?.visible;
            const isFamOOPVisible =
              preferences[config.name + " Fam OOP"]?.visible;
            return {
              id: config.name,
              label: config.name,
              checked: false,
              subLabels: [
                {
                  id: config.name + " Ind Ded",
                  label: "Ind Ded",
                  checked: isIndDedVisible,
                },
                {
                  id: config.name + " Fam Ded",
                  label: "Fam Ded",
                  checked: isFamDedVisible,
                },
                {
                  id: config.name + " Ind OOP",
                  label: "Ind OOP",
                  checked: isIndOOPVisible,
                },
                {
                  id: config.name + " Fam OOP",
                  label: "Fam OOP",
                  checked: isFamOOPVisible,
                },
              ],
            };
          }
          const isCopayVisible = preferences[config.name + " Copay"]?.visible;
          const isCoinsuranceVisible =
            preferences[config.name + " Coinsurance"]?.visible;
          const isDeductibleVisible =
            preferences[config.name + " Deductible"]?.visible;
          const isOutOfPocketVisible =
            preferences[config.name + " Out of Pocket"]?.visible;
          const isVisitLimitVisible =
            preferences[config.name + " Visit Limit"]?.visible;
          const isAuthRequiredVisible =
            preferences[config.name + " Auth Required"]?.visible;

          return {
            id: config.name,
            label: config.name,
            checked: false,
            subLabels: [
              {
                id: config.name + " Copay",
                label: "Copay",
                checked: isCopayVisible,
              },
              {
                id: config.name + " Coinsurance",
                label: "Coinsurance",
                checked: isCoinsuranceVisible,
              },
              {
                id: config.name + " Deductible",
                label: "Deductible",
                checked: isDeductibleVisible,
              },
              {
                id: config.name + " Out of Pocket",
                label: "Out of Pocket",
                checked: isOutOfPocketVisible,
              },
              {
                id: config.name + " Visit Limit",
                label: "Visit Limit",
                checked: isVisitLimitVisible,
              },
              {
                id: config.name + " Auth Required",
                label: "Auth Required",
                checked: isAuthRequiredVisible,
              },
            ],
          };
        })
      : []),
  ];

  const [headerRow, setHeaderRow] = useState<
    {
      id: string;
      label: string;
      checked: boolean;
      subLabels?: { id: string; label: string; checked: boolean }[];
    }[]
  >([...calculateHeaderRow()]);

  useEffect(() => {
    setHeaderRow([...calculateHeaderRow()]);
  }, [columns]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn("ml-auto hidden h-8 lg:flex", className)}
        >
          <MixerHorizontalIcon className="mr-2 h-4 w-4" />
          View
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex items-center justify-between space-x-1">
            <span>Toggle columns</span>
          </DialogTitle>
        </DialogHeader>
        <Divider className="my-0" />
        <div className="max-h-[60vh] overflow-y-auto grid gap-4">
          {headerRow.map(({ id, label, checked, subLabels }) => {
            const allSubLabelsChecked = subLabels?.every((sl) => sl.checked);
            return (
              <div className="flex flex-col gap-1">
                <div className="flex items-center space-x-2">
                  {subLabels ? (
                    <Checkbox
                      checked={allSubLabelsChecked}
                      onCheckedChange={(checked) => {
                        setHeaderRow((headerRow) =>
                          headerRow.map((row) =>
                            row.id === id && row.subLabels
                              ? {
                                  ...row,
                                  subLabels: row.subLabels.map((sl) => ({
                                    ...sl,
                                    checked: Boolean(checked.valueOf()),
                                  })),
                                }
                              : row
                          )
                        );
                      }}
                    />
                  ) : (
                    <Checkbox
                      id={label}
                      defaultChecked={checked}
                      checked={checked}
                      onCheckedChange={(checked) => {
                        setHeaderRow((headerRow) =>
                          headerRow.map((row) =>
                            row.id === id
                              ? {
                                  ...row,
                                  checked: Boolean(checked.valueOf()),
                                }
                              : row
                          )
                        );
                      }}
                    />
                  )}
                  <label
                    htmlFor="terms"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {label}
                  </label>
                </div>
                {subLabels && subLabels.length > 0 && (
                  <div className="grid grid-cols-2 gap-2 pt-1">
                    {subLabels.map(({ id, label, checked }) => (
                      <div className="flex items-center space-x-2 ml-4">
                        <Checkbox
                          id={label}
                          defaultChecked={checked}
                          checked={checked}
                          onCheckedChange={(checked) => {
                            setHeaderRow((headerRow) =>
                              headerRow.map((row) => {
                                const subLabels = row.subLabels
                                  ? row.subLabels.map((sl) =>
                                      sl.id === id
                                        ? {
                                            ...sl,
                                            checked: Boolean(checked.valueOf()),
                                          }
                                        : sl
                                    )
                                  : row.subLabels;
                                return row.id === id
                                  ? {
                                      ...row,
                                      subLabels,
                                      checked: Boolean(checked.valueOf()),
                                    }
                                  : { ...row, subLabels };
                              })
                            );
                          }}
                        />
                        <label
                          htmlFor="terms"
                          className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {label}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="flex justify-end space-x-2 mt-4">
          <DialogClose asChild>
            <Button
              onClick={async () => {
                headerRow.forEach((row) => {
                  if (row.subLabels) {
                    row.subLabels.forEach((subLabel) => {
                      const findColumn = columns.find(
                        (c) => c.id === subLabel.id
                      );
                      if (findColumn) {
                        findColumn.toggleVisibility(subLabel.checked);
                      }
                    });
                  }
                  const findColumn = columns.find((c) => c.id === row.id);
                  if (findColumn) {
                    findColumn.toggleVisibility(row.checked);
                  }
                });
                const preferences = headerRow.reduce((acc, row) => {
                  if (row.subLabels) {
                    row.subLabels.forEach((subLabel) => {
                      acc[subLabel.id] = { visible: subLabel.checked };
                    });
                    return acc;
                  }

                  acc[row.id] = {
                    visible: row.checked,
                  };
                  return acc;
                }, {} as Record<string, { visible: boolean; subLabels?: Record<string, { visible: boolean }> }>);
                await updateAppointmentViewPreferences({
                  variables: {
                    id: user.id,
                    appointmentViewPreferences: {
                      columns: { ...preferences },
                      filters: table.getState().columnFilters,
                    },
                  },
                  refetchQueries: [GET_APPOINTMENT_VIEW_PREFERENCES],
                });
                toast.success("View preferences saved");
              }}
            >
              Save View
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function DataTableViewOptions<TData>({
  table,
  className,
}: DataTableViewOptionsProps<TData>) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn("ml-auto hidden h-8 lg:flex", className)}
        >
          <MixerHorizontalIcon className="mr-2 h-4 w-4" />
          View
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[150px]">
        <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {table
          .getAllColumns()
          .filter(
            (column) =>
              typeof column.accessorFn !== "undefined" && column.getCanHide()
          )
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {column.id}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
