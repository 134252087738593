import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  format,
  intervalToDuration,
  formatDuration,
  parseISO,
  compareAsc,
} from "date-fns";
import * as Sentry from "@sentry/react";
import { useAnalytics } from "../../../analytics-context";
import { useUser } from "../../../user-context";
import {
  GetPaymentRequestBatch,
  GetPaymentRequestBatchVariables,
  GetPaymentRequestBatch_paymentRequestBatch as IPaymentRequestBatch,
  GetPaymentRequestBatch_paymentRequestBatch_paymentRequests as PaymentRequest,
} from "../../../generated/GetPaymentRequestBatch";
import {
  PaymentRequestBatchStatus,
  PaymentRequestStatus,
} from "../../../generated/globalTypes";
import { Layout, LoadingLayout } from "../../layout";
import {
  capitalize,
  classNames,
  formatDateMMDDYYYY,
  formatPercentage,
  formatUSD,
  isDefined,
  sleep,
} from "../../../utils";
import { Card, SubmitButton, Table, Tooltip } from "../../../components";
import { Td } from "../../../components/Table";
import { PaymentRequestBatchStatusBadge } from ".";
import { toast } from "react-toastify";
import {
  CancelPaymentRequestBatch,
  CancelPaymentRequestBatchVariables,
} from "../../../generated/CancelPaymentRequestBatch";
import {
  CalendarIcon,
  CheckIcon,
  CogIcon,
  CurrencyDollarIcon,
  CursorClickIcon,
  DeviceMobileIcon,
  DocumentDownloadIcon,
  ExclamationCircleIcon,
  KeyIcon,
  MailIcon,
  PlusIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  MarkPaymentRequestTaskAsComplete,
  MarkPaymentRequestTaskAsCompleteVariables,
} from "../../../generated/MarkPaymentRequestTaskAsComplete";
import {
  MarkPaymentRequestTaskAsIncomplete,
  MarkPaymentRequestTaskAsIncompleteVariables,
} from "../../../generated/MarkPaymentRequestTaskAsIncomplete";
import { SlideOut } from "../../../components/slideout";
import {
  GetPaymentRequestHistory,
  GetPaymentRequestHistoryVariables,
  GetPaymentRequestHistory_paymentRequest as PaymentRequestActivity,
  GetPaymentRequestHistory_paymentRequest_paymentRequestTargets_bill_billPayments_paymentTransaction as PaymentTransaction,
  GetPaymentRequestHistory_paymentRequest_paymentRequestTargets_bill_charges as Charge,
} from "../../../generated/GetPaymentRequestHistory";
import { Event, Timeline } from "../../patients/show";
import { communicationTypeDisplay } from "../../patients/activity-feed";
import { PatientBillsTable } from "../../../pages/tasks";
import {
  GET_SIDEBAR_COUNTS,
  PaymentRequestHistoryFragment,
} from "../../../graphql";
import {
  GetBatchProgress,
  GetBatchProgressVariables,
} from "../../../generated/GetBatchProgress";
import {
  GetPaymentRequestBatchStats,
  GetPaymentRequestBatchStatsVariables,
} from "../../../generated/GetPaymentRequestBatchStats";
import {
  GetPrintStatementUrl,
  GetPrintStatementUrlVariables,
} from "../../../generated/GetPrintStatementUrl";
import {
  GetPrintStatementUrlForBatch,
  GetPrintStatementUrlForBatchVariables,
} from "../../../generated/GetPrintStatementUrlForBatch";

export const PaymentRequestBatchFields = gql`
  fragment PaymentRequestBatchFields on PaymentRequestBatch {
    id
    status
    failureReason
    totalAmount
    startedAt
    completedAt
    canceledAt
    scheduledAt
    createdBy {
      id
      firstName
      lastName
    }
    canceledBy {
      id
      firstName
      lastName
    }
    s3Key
    paymentRequests {
      id
      status
      type
      failureReason
      amount
      startedAt
      createdAt
      communications {
        id
        type
        contentType
        lastErrorType
        lastErrorReasonDisplay
      }
      patient {
        id
        displayName
      }
      paymentRequestTargets {
        id
      }
      tasks {
        id
        type
        completedAt
      }
      printEnabled
      s3Key
    }
  }
`;

const GET_PAYMENT_REQUEST_BATCH = gql`
  ${PaymentRequestBatchFields}
  query GetPaymentRequestBatch($id: String!) {
    paymentRequestBatch(where: { id: $id }) {
      ...PaymentRequestBatchFields
    }
  }
`;

const GET_PAYMENT_REQUEST_BATCH_STATS = gql`
  query GetPaymentRequestBatchStats($id: String!) {
    paymentRequestBatch(where: { id: $id }) {
      id
      amountPaid
      paymentRequestsClicked
      averageTimeToFirstPayment
    }
  }
`;

const GET_PRINT_STATEMENT_URL = gql`
  query GetPrintStatementUrl($paymentRequestId: String!) {
    getPrintStatementUrl(paymentRequestId: $paymentRequestId) {
      success
      url
    }
  }
`;

const GET_PRINT_STATEMENT_URL_FOR_BATCH = gql`
  query GetPrintStatementUrlForBatch($batchId: String!) {
    getPrintStatementUrlForBatch(batchId: $batchId) {
      success
      url
    }
  }
`;

export const PaymentRequestStatusBadge: React.FC<
  React.PropsWithChildren<{
    status: PaymentRequestStatus;
  }>
> = ({ status }) => {
  const statuses: { [K in PaymentRequestStatus]: string } = {
    Scheduled: "text-slate-400 bg-slate-400/10",
    Running: "text-blue-400 bg-blue-400/10",
    Completed: "text-green-400 bg-green-400/10",
    Canceled: "text-rose-400 bg-rose-400/10",
    Failed: "text-rose-400 bg-rose-400/10",
  };

  return (
    <div className="flex items-center justify-end gap-x-2 sm:justify-start">
      <div
        className={classNames(statuses[status], "flex-none rounded-full p-1")}
      >
        <div className="h-1.5 w-1.5 rounded-full bg-current" />
      </div>
      <div>{status}</div>
    </div>
  );
};

export const MARK_PAYMENT_REQUEST_TASK_AS_COMPLETE = gql`
  mutation MarkPaymentRequestTaskAsComplete($id: String!) {
    markPaymentRequestTaskAsComplete(id: $id) {
      paymentRequest {
        id
        status
      }
      errors {
        message
      }
    }
  }
`;

export const MARK_PAYMENT_REQUEST_TASK_AS_INCOMPLETE = gql`
  mutation MarkPaymentRequestTaskAsIncomplete($id: String!) {
    markPaymentRequestTaskAsIncomplete(id: $id) {
      paymentRequest {
        id
        status
      }
      errors {
        message
      }
    }
  }
`;

const PaymentRequestActionCell: React.FC<
  React.PropsWithChildren<{
    paymentRequest: PaymentRequest;
  }>
> = ({ paymentRequest }) => {
  const [
    markPaymentRequestTaskAsComplete,
    markPaymentRequestTaskAsCompleteResult,
  ] = useMutation<
    MarkPaymentRequestTaskAsComplete,
    MarkPaymentRequestTaskAsCompleteVariables
  >(MARK_PAYMENT_REQUEST_TASK_AS_COMPLETE);
  const [
    markPaymentRequestTaskAsIncomplete,
    markPaymentRequestTaskAsIncompleteResult,
  ] = useMutation<
    MarkPaymentRequestTaskAsIncomplete,
    MarkPaymentRequestTaskAsIncompleteVariables
  >(MARK_PAYMENT_REQUEST_TASK_AS_INCOMPLETE);
  // No actions for non-task or canceled payment requests
  if (
    paymentRequest.type !== "Task" ||
    paymentRequest.status === PaymentRequestStatus.Canceled
  )
    return <Td />;
  if (paymentRequest.status === PaymentRequestStatus.Completed) {
    return (
      <Td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
          disabled={markPaymentRequestTaskAsIncompleteResult.loading}
          onClick={(e) => {
            markPaymentRequestTaskAsIncomplete({
              variables: { id: paymentRequest.id },
              onCompleted: (data) => {
                if (data.markPaymentRequestTaskAsIncomplete.errors.length > 0) {
                  toast.error(
                    data.markPaymentRequestTaskAsIncomplete.errors[0].message
                  );
                } else {
                  toast.success("Payment request task marked as incomplete");
                }
              },
              onError: () => {
                toast.error(
                  "Failed to mark payment request task as incomplete"
                );
              },
              refetchQueries: [
                GET_SIDEBAR_COUNTS,
                GET_PAYMENT_REQUEST_BATCH_STATS,
              ],
            });
            e.stopPropagation();
          }}
        >
          Mark as Incomplete
        </button>
      </Td>
    );
  }

  return (
    <Td>
      <button
        type="button"
        className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
        disabled={markPaymentRequestTaskAsCompleteResult.loading}
        onClick={(e) => {
          markPaymentRequestTaskAsComplete({
            variables: { id: paymentRequest.id },
            onCompleted: (data) => {
              if (data.markPaymentRequestTaskAsComplete.errors.length > 0) {
                toast.error(
                  data.markPaymentRequestTaskAsComplete.errors[0].message
                );
              } else {
                toast.success("Payment request task marked as complete");
              }
            },
            onError: () => {
              toast.error("Failed to mark payment request task as complete");
            },
            refetchQueries: [
              GET_SIDEBAR_COUNTS,
              GET_PAYMENT_REQUEST_BATCH_STATS,
            ],
          });
          e.stopPropagation();
        }}
      >
        Mark as Complete
      </button>
    </Td>
  );
};

const GET_BATCH_PROGRESS = gql`
  query GetBatchProgress($id: String!) {
    paymentRequestBatch(where: { id: $id }) {
      id
      status
      paymentRequests {
        id
        status
        s3Key
      }
    }
  }
`;

const paymentRequestIsComplete = (pr: Pick<PaymentRequest, "status">) =>
  pr.status === PaymentRequestStatus.Completed ||
  pr.status === PaymentRequestStatus.Canceled ||
  pr.status === PaymentRequestStatus.Failed;

const RunningBatchProgress: React.FC<
  React.PropsWithChildren<{
    batch: IPaymentRequestBatch;
    onComplete: () => void;
  }>
> = ({ batch, onComplete }) => {
  const { data, stopPolling } = useQuery<
    GetBatchProgress,
    GetBatchProgressVariables
  >(GET_BATCH_PROGRESS, {
    variables: { id: batch.id },
    pollInterval: 1000,
  });

  const progress =
    data?.paymentRequestBatch?.paymentRequests.filter(paymentRequestIsComplete)
      .length ?? 0;
  const total = batch.paymentRequests.length;

  useEffect(() => {
    if (progress === total) {
      stopPolling();
      // Wait a bit before calling onComplete to give the user a chance to see
      sleep(2000).then(onComplete);
    }
  }, [progress, total]);

  const percentage = formatPercentage(total === 0 ? 0 : progress / total);

  return (
    <div className="flex gap-2 items-end w-full">
      <div className="flex w-full flex-col">
        <div className="text-xs text-gray-500">
          {progress} of {total} run
        </div>
        <div className="overflow-hidden rounded-full bg-gray-200 mb-1">
          <div
            className="h-2 rounded-full bg-indigo-600 transition ease-in-out"
            style={{ width: percentage }}
          />
        </div>
      </div>
      <div className="text-xs text-gray-500">{percentage}</div>
    </div>
  );
};

const CANCEL_PAYMENT_REQUEST_BATCH = gql`
  ${PaymentRequestBatchFields}
  mutation CancelPaymentRequestBatch($id: String!) {
    cancelPaymentRequestBatch(id: $id) {
      paymentRequestBatch {
        ...PaymentRequestBatchFields
      }
    }
  }
`;

const GET_PAYMENT_REQUEST_HISTORY = gql`
  ${PaymentRequestHistoryFragment}
  query GetPaymentRequestHistory($id: String!) {
    paymentRequest(where: { id: $id }) {
      ...PaymentRequestHistoryFields
    }
  }
`;

const PaymentRequestSlideout: React.FC<
  React.PropsWithChildren<{
    paymentRequest: PaymentRequest;
    onClose: () => void;
  }>
> = ({ paymentRequest, onClose }) => {
  const { data, loading } = useQuery<
    GetPaymentRequestHistory,
    GetPaymentRequestHistoryVariables
  >(GET_PAYMENT_REQUEST_HISTORY, {
    variables: { id: paymentRequest.id },
  });
  return (
    <SlideOut
      onClose={onClose}
      title={
        <div className="flex flex-col">
          <div className="text-sm">{paymentRequest.patient.displayName}</div>
          <div className="flex gap-2 items-end">
            <div>
              <span className="text-2xl">
                {formatDateMMDDYYYY(
                  paymentRequest.startedAt ?? paymentRequest.createdAt
                )}
              </span>
            </div>
            <PaymentRequestStatusBadge status={paymentRequest.status} />
          </div>
        </div>
      }
    >
      {loading || !data?.paymentRequest ? (
        <div className="flex flex-col space-y-2">
          <div className="animate-pulse flex flex-col space-y-4 h-64" />
        </div>
      ) : (
        <div className="flex flex-col space-y-2">
          <h3 className="font-semibold text-lg">Bills to pay</h3>
          <PatientBillsTable
            loading={loading}
            bills={
              data.paymentRequest.paymentRequestTargets.map((t) => t.bill) ?? []
            }
          />

          <h3 className="font-semibold text-lg">Related Activity</h3>
          <Card>
            <div className="pt-4 w-full">
              <PaymentRequestTimeline paymentRequest={data.paymentRequest} />
            </div>
          </Card>
        </div>
      )}
    </SlideOut>
  );
};

export const PaymentRequestTimeline: React.FC<
  React.PropsWithChildren<{
    paymentRequest: PaymentRequestActivity;
  }>
> = ({ paymentRequest }) => {
  let events = [];
  if (paymentRequest.scheduledAt) {
    const paymentRequestScheduledEvent: Event = {
      id: paymentRequest.id + "-scheduled",
      timestamp: parseISO(paymentRequest.createdAt),
      dateDisplay: format(
        parseISO(paymentRequest.createdAt),
        "MM/dd/yyyy hh:mm a"
      ),
      description: `Payment request scheduled for ${format(
        parseISO(paymentRequest.scheduledAt),
        "MM/dd/yyyy"
      )}`,
      bgColorClass: "bg-gray-400",
      icon: <CalendarIcon className="w-5 h-5 text-white" />,
    };
    events.push(paymentRequestScheduledEvent);
  }
  if (paymentRequest.canceledAt) {
    const paymentRequestStartedEvent: Event = {
      id: paymentRequest.id + "-canceled",
      timestamp: parseISO(paymentRequest.canceledAt),
      dateDisplay: format(
        parseISO(paymentRequest.canceledAt),
        "MM/dd/yyyy hh:mm a"
      ),
      description: `Payment request canceled`,
      bgColorClass: "bg-gray-400",
      icon: <XIcon className="w-5 h-5 text-white" />,
    };
    events.push(paymentRequestStartedEvent);
  }
  if (paymentRequest.failedAt && paymentRequest.failureReason) {
    const paymentRequestStartedEvent: Event = {
      id: paymentRequest.id + "-failed",
      timestamp: parseISO(paymentRequest.failedAt),
      dateDisplay: format(
        parseISO(paymentRequest.failedAt),
        "MM/dd/yyyy hh:mm a"
      ),
      description: (
        <>
          Payment request failed with error{" "}
          <span className="bg-red-100 border-red-500 text-red-700 px-1 py-0.5 rounded-md whitespace-nowrap">
            {paymentRequest.failureReason}
          </span>
        </>
      ),
      bgColorClass: "bg-gray-400",
      icon: <XIcon className="w-5 h-5 text-white" />,
    };
    events.push(paymentRequestStartedEvent);
  }
  if (paymentRequest.type === "Task" && paymentRequest.completedAt) {
    const paymentRequestStartedEvent: Event = {
      id: paymentRequest.id + "-completed",
      timestamp: parseISO(paymentRequest.completedAt),
      dateDisplay: format(
        parseISO(paymentRequest.completedAt),
        "MM/dd/yyyy hh:mm a"
      ),
      description: `Payment request${
        isDefined(paymentRequest.amount)
          ? " for " + formatUSD(paymentRequest.amount)
          : ""
      } completed`,
      bgColorClass: "bg-green-400",
      icon: <CheckIcon className="w-5 h-5 text-white" />,
    };
    events.push(paymentRequestStartedEvent);
  }
  if (paymentRequest.usedAccessCodeAt) {
    const paymentRequestStartedEvent: Event = {
      id: paymentRequest.id + "-access-code-used",
      timestamp: parseISO(paymentRequest.usedAccessCodeAt),
      dateDisplay: format(
        parseISO(paymentRequest.usedAccessCodeAt),
        "MM/dd/yyyy hh:mm a"
      ),
      description: `Access code for portal used`,
      bgColorClass: "bg-gray-400",
      icon: <KeyIcon className="w-5 h-5 text-white" />,
    };
    events.push(paymentRequestStartedEvent);
  }
  const paymentIntent = paymentRequest.paymentIntent;
  if (paymentIntent) {
    if (paymentIntent.status === "succeeded") {
      const paymentSucceededEvent: Event = {
        id: paymentIntent.id + "-paid",
        timestamp: parseISO(paymentIntent.createdAt),
        dateDisplay: format(
          parseISO(paymentIntent.createdAt),
          "MM/dd/yyyy hh:mm a"
        ),
        description: `Autopay completed`,
        bgColorClass: "bg-green-400",
        icon: <CheckIcon className="w-5 h-5 text-white" />,
      };
      events.push(paymentSucceededEvent);
    } else if (paymentIntent.lastPaymentError) {
      const paymentFailedEvent: Event = {
        id: paymentIntent.id + "-failed",
        timestamp: parseISO(paymentIntent.createdAt),
        dateDisplay: format(
          parseISO(paymentIntent.createdAt),
          "MM/dd/yyyy hh:mm a"
        ),
        description: (
          <>
            Autopay failed with error{" "}
            <span className="bg-red-100 border-red-500 text-red-700 px-1 py-0.5 rounded-md whitespace-nowrap">
              {paymentIntent.lastPaymentError}
            </span>
          </>
        ),
        bgColorClass: "bg-red-400",
        icon: <XCircleIcon className="w-5 h-5 text-white" />,
      };
      events.push(paymentFailedEvent);
    }
  }
  const chargePayments = paymentRequest.paymentRequestTargets.flatMap((prt) =>
    prt.bill.charges.flatMap((c) =>
      c.transaction.chargeAllocations.map((ca) => ({ ...ca, charge: c }))
    )
  );
  const payments = chargePayments.reduce(
    (acc, cp) => {
      const id = cp.paymentTransaction.id;
      const payment = acc[id] ?? {
        amount: 0,
        charges: [],
        paymentTransaction: cp.paymentTransaction,
      };
      return {
        ...acc,
        [id]: {
          ...payment,
          amount: payment.amount + cp.amount,
          charges: [...payment.charges, cp.charge],
        },
      };
    },
    {} as {
      [paymentId: string]: {
        amount: number;
        paymentTransaction: PaymentTransaction;
        charges: Charge[];
      };
    }
  );
  const paymentEvents = Object.entries(payments).map(([id, payment]) => ({
    id,
    timestamp: parseISO(payment.paymentTransaction.transactedAt),
    dateDisplay: format(
      parseISO(payment.paymentTransaction.transactedAt),
      "MM/dd/yyyy"
    ),
    description: (
      <>
        Payment of{" "}
        <span className="font-medium text-gray-700">
          {formatUSD(payment.amount)}
        </span>
      </>
    ),
    bgColorClass: "bg-gray-400",
    icon: <CurrencyDollarIcon className="w-5 h-5 text-white" />,
  }));
  events = [...events, ...paymentEvents];

  const communicationEvents = paymentRequest.communications.flatMap((c) => {
    const failed = c.lastErrorType !== null;
    let description;
    if (failed) {
      description = (
        <>
          {capitalize(communicationTypeDisplay(c.type))} failed to send
          {c.handle && ` to ${c.handle}`} with error{" "}
          <span className="bg-red-100 border-red-500 text-red-700 px-1 py-0.5 rounded-md whitespace-nowrap">
            {c.lastErrorReasonDisplay}
          </span>
        </>
      );
    } else {
      description = `${capitalize(communicationTypeDisplay(c.type))} sent${
        c.handle ? ` to ${c.handle}` : ""
      }`;
    }
    const commEvents = [
      {
        id: c.id + "-sent",
        timestamp: parseISO(c.sentAt),
        dateDisplay: format(parseISO(c.sentAt), "MM/dd/yyyy hh:mm a"),
        description,
        bgColorClass: failed ? "bg-red-400" : "bg-gray-400",
        icon:
          c.type === "EMAIL" ? (
            <MailIcon className="w-5 h-5 text-white" />
          ) : (
            <DeviceMobileIcon className="w-5 h-5 text-white" />
          ),
      },
    ];
    if (c.firstClickedAt) {
      commEvents.push({
        id: c.id + "-clicked",
        timestamp: parseISO(c.firstClickedAt),
        dateDisplay: format(parseISO(c.firstClickedAt), "MM/dd/yyyy hh:mm a"),
        description: `Payment link in ${communicationTypeDisplay(
          c.type
        )} clicked`,
        bgColorClass: "bg-gray-400",
        icon: <CursorClickIcon className="w-5 h-5 text-white" />,
      });
    }
    return commEvents;
  });
  events = [...events, ...communicationEvents];

  events = events.sort((a, b) => compareAsc(a.timestamp, b.timestamp));
  return <Timeline events={events} />;
};

export const PaymentRequestBatch: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const params = useParams<{ id: string }>();
  const id = params.id!;

  const analytics = useAnalytics();
  const user = useUser();

  const [downloadButtonEnabled, setDownloadButtonEnabled] = useState(true);

  const [selectedPaymentRequest, setSelectedPaymentRequest] =
    useState<PaymentRequest | null>();
  const { loading, error, data, refetch } = useQuery<
    GetPaymentRequestBatch,
    GetPaymentRequestBatchVariables
  >(GET_PAYMENT_REQUEST_BATCH, {
    variables: { id },
  });

  const getPaymentRequestBatchStats = useQuery<
    GetPaymentRequestBatchStats,
    GetPaymentRequestBatchStatsVariables
  >(GET_PAYMENT_REQUEST_BATCH_STATS, {
    variables: { id },
  });

  const [getPrintStatementUrl, getPrintStatementUrlResult] = useLazyQuery<
    GetPrintStatementUrl,
    GetPrintStatementUrlVariables
  >(GET_PRINT_STATEMENT_URL);

  const [getPrintStatementUrlForBatch, getPrintStatementUrlForBatchResult] =
    useLazyQuery<
      GetPrintStatementUrlForBatch,
      GetPrintStatementUrlForBatchVariables
    >(GET_PRINT_STATEMENT_URL_FOR_BATCH);

  const [cancelPaymentRequestBatch, cancelPaymentRequestBatchResult] =
    useMutation<CancelPaymentRequestBatch, CancelPaymentRequestBatchVariables>(
      CANCEL_PAYMENT_REQUEST_BATCH
    );

  if (loading) return <LoadingLayout />;

  const batch = data?.paymentRequestBatch ?? null;

  if (batch == null) {
    return <div>Batch not found</div>;
  }

  const totalRequests = batch.paymentRequests.length;
  const totalBills = batch.paymentRequests.reduce(
    (sum, pr) => sum + pr.paymentRequestTargets.length,
    0
  );
  const numberCompleted = batch.paymentRequests.filter(
    (pr) => pr.status === PaymentRequestStatus.Completed
  ).length;
  const numberFailed = batch.paymentRequests.filter(
    (pr) => pr.status === PaymentRequestStatus.Failed
  ).length;

  const anyPaymentRequestLinks = batch.paymentRequests.some(
    (pr) => pr.type === "Link"
  );
  const anyPaymentRequestAutopays = batch.paymentRequests.some(
    (pr) => pr.type === "Autopay"
  );
  const anyPaymentRequestTasks = batch.paymentRequests.some(
    (pr) => pr.type === "Task"
  );

  const batchStats = getPaymentRequestBatchStats.data?.paymentRequestBatch;

  const stats: {
    name: string;
    value: number | string;
    unit?: string;
    loading?: boolean;
  }[] = [
    { name: "Number of Patients", value: totalRequests },
    { name: "Number of Bills", value: totalBills },
    {
      name: "Amount Requested",
      value: isDefined(batch.totalAmount)
        ? formatUSD(batch.totalAmount)
        : "N/A",
    },
    {
      name: "Amount Paid",
      loading: getPaymentRequestBatchStats.loading,
      value: !batchStats ? "N/A" : formatUSD(batchStats.amountPaid),
    },
    {
      name: "Average Time to First Payment",
      loading: getPaymentRequestBatchStats.loading,
      value:
        !batchStats || batchStats.averageTimeToFirstPayment === 0
          ? "N/A"
          : formatDuration(
              intervalToDuration({
                start: 0,
                end: batchStats.averageTimeToFirstPayment,
              })
            ),
    },
    {
      name: "Collection Rate",
      value:
        isDefined(batch.totalAmount) && batchStats
          ? formatPercentage(batchStats.amountPaid / batch.totalAmount, 1)
          : "N/A",
    },
    ...(anyPaymentRequestLinks || anyPaymentRequestAutopays
      ? [
          {
            name: "Error Rate",
            value:
              totalRequests === 0
                ? "N/A"
                : formatPercentage(numberFailed / totalRequests, 1),
          },
        ]
      : []),
    ...(anyPaymentRequestTasks
      ? [
          {
            name: "Completion Rate",
            value:
              totalRequests === 0
                ? "N/A"
                : formatPercentage(numberCompleted / totalRequests, 1),
          },
        ]
      : []),
    ...(anyPaymentRequestLinks || anyPaymentRequestAutopays
      ? [
          {
            name: "Click Rate",
            loading: getPaymentRequestBatchStats.loading,
            value:
              !batchStats || totalRequests === 0
                ? "N/A"
                : formatPercentage(
                    batchStats.paymentRequestsClicked / totalRequests,
                    1
                  ),
          },
        ]
      : []),
  ];

  const isActive =
    batch.status === PaymentRequestBatchStatus.Running ||
    batch.status === PaymentRequestBatchStatus.Scheduled;

  const createdBy = batch.createdBy
    ? [batch.createdBy.firstName, batch.createdBy.lastName].join(" ")
    : null;

  return (
    <Layout
      header={
        <div className="flex gap-4 w-full">
          <h1 className="text-2xl font-semibold text-gray-900 flex gap-2">
            <PaymentRequestBatchStatusBadge status={batch.status} />
            {/* at{" "}
            {format(
              parseISO(batch.startedAt ?? batch.scheduledAt),
              "MM/dd/yyyy hh:mm a"
            )} */}
          </h1>
          {isActive && (
            <SubmitButton
              type="button"
              className="rounded bg-red-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={() => {
                cancelPaymentRequestBatch({
                  variables: { id },
                  onCompleted: () => {
                    toast.success("Payment request batch canceled");
                  },
                  onError: (error) => {
                    toast.error("Failed to cancel payment request batch");
                  },
                  refetchQueries: [GET_PAYMENT_REQUEST_BATCH_STATS],
                });
              }}
              loading={cancelPaymentRequestBatchResult.loading}
            >
              Cancel
            </SubmitButton>
          )}
          {batch.status === PaymentRequestBatchStatus.Running && (
            <RunningBatchProgress batch={batch} onComplete={() => refetch()} />
          )}
        </div>
      }
      content={
        <div className="flex flex-col gap-4 pt-4">
          <div className="flex items-center gap-2 divide-x">
            {createdBy ? (
              <div className="pl-2">Created by {createdBy}</div>
            ) : (
              <div className="text-sm text-gray-900 flex gap-1 pl-2">
                <CogIcon className="w-4" />
                <span>Automated</span>
              </div>
            )}
            {batch.status === PaymentRequestBatchStatus.Scheduled && (
              <div className="pl-2">
                Scheduled for{" "}
                {format(parseISO(batch.scheduledAt), "MM/dd/yyyy hh:mm a")}
              </div>
            )}
            {batch.status === PaymentRequestBatchStatus.Canceled && (
              <div className="pl-2">
                Canceled at{" "}
                {format(parseISO(batch.canceledAt), "MM/dd/yyyy hh:mm a")}
              </div>
            )}
            {batch.status !== PaymentRequestBatchStatus.Scheduled &&
              batch.startedAt && (
                <div className="pl-2">
                  Started at{" "}
                  {format(parseISO(batch.startedAt), "MM/dd/yyyy hh:mm a")}
                </div>
              )}
            {batch.status === PaymentRequestBatchStatus.Completed &&
              batch.completedAt && (
                <div className="pl-2">
                  Completed at{" "}
                  {format(parseISO(batch.completedAt), "MM/dd/yyyy hh:mm a")}
                </div>
              )}
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div
                key={stat.name}
                className="border border-gray/5 py-6 px-4 sm:px-6 lg:px-8 bg-white"
              >
                <p className="text-sm font-medium leading-6 text-gray-400">
                  {stat.name}
                </p>
                {stat.loading ? (
                  <div className="flex flex-col gap-1">
                    <div className="animate-pulse h-5 bg-gray-200 rounded-md" />
                    <div className="animate-pulse h-5 bg-gray-200 rounded-md" />
                  </div>
                ) : (
                  <p className="mt-2 flex items-baseline gap-x-2">
                    <span className="text-xl sm:text-2xl lg:text-4xl font-semibold tracking-tight">
                      {stat.value}
                    </span>
                    {stat.unit ? (
                      <span className="text-sm text-gray-400">{stat.unit}</span>
                    ) : null}
                  </p>
                )}
              </div>
            ))}
          </div>

          <div className="flex flex-col">
            <div className="flex justify-between">
              <h2 className="text-lg font-medium">Payment Requests</h2>
              {batch.paymentRequests.filter((pr) => pr.printEnabled).length >=
                1 && (
                <button
                  className="disabled:cursor-not-allowed bg-indigo-500 hover:bg-indigo-600 disabled:bg-indigo-500 text-white font-bold py-2 px-4 rounded"
                  disabled={Boolean(!batch.s3Key || !downloadButtonEnabled)}
                  onClick={async () => {
                    setDownloadButtonEnabled(false);
                    getPrintStatementUrlForBatch({
                      variables: { batchId: batch.id },
                      onCompleted: (data) => {
                        setDownloadButtonEnabled(true);
                        if (
                          data.getPrintStatementUrlForBatch.success &&
                          data.getPrintStatementUrlForBatch.url
                        ) {
                          const a = document.createElement("a");
                          a.href = data.getPrintStatementUrlForBatch.url;
                          a.click();
                          analytics?.track("Paper Statement PDF Downloaded", {
                            patientId: batch.paymentRequests[0]?.patient.id,
                            organizationId: user?.organization.id,
                            organizationName: user?.organization.name,
                            locationId: user?.activeLocation.id,
                            locationName: user?.activeLocation.name,
                            page: 'BatchPage',
                            downloadAll: true,
                          });
                          toast.success("Your statements have downloaded!");
                        } else {
                          toast.error("Unable to download print statements");
                        }
                      },
                      onError: (e) => {
                        setDownloadButtonEnabled(true);
                        toast.error("Unable to download print statements");
                        Sentry.captureException(e);
                      },
                    });
                  }}
                >
                  <div className="flex items-center">
                    <p className="text-sm">Download All</p>
                    <DocumentDownloadIcon className="-mr-1 ml-2 h-6 w-6" />
                  </div>
                </button>
              )}
            </div>
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 py-4">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <Table
                  columnDefs={[
                    {
                      header: "Patient",
                      cellFn: (pr: PaymentRequest) => (
                        <Td>
                          <div className="text-gray-900">
                            <Link to={`/patients/${pr.patient.id}`}>
                              {pr.patient.displayName}
                            </Link>
                          </div>
                        </Td>
                      ),
                    },
                    {
                      header: "Type",
                      cellFn: (pr: PaymentRequest) => (
                        <Td>
                          <div className="text-gray-900">{pr.type}</div>
                        </Td>
                      ),
                    },
                    {
                      header: "Amount Requested",
                      cellFn: (pr: PaymentRequest) => (
                        <Td>
                          <div className="text-gray-900">
                            {isDefined(pr.amount) && formatUSD(pr.amount)}
                          </div>
                        </Td>
                      ),
                    },
                    {
                      header: "Bills Sent",
                      cellFn: (pr: PaymentRequest) => (
                        <Td>
                          <div className="text-gray-900">
                            {pr.paymentRequestTargets.length}
                          </div>
                        </Td>
                      ),
                    },
                    {
                      header: "Status",
                      cellFn: (pr: PaymentRequest) => {
                        let commFailureDescription;
                        if (
                          pr.communications.some(
                            (c) => c.lastErrorType !== null
                          )
                        ) {
                          commFailureDescription = `Error sending ${pr.communications
                            .filter((c) => c.lastErrorType !== null)
                            .map((c) => communicationTypeDisplay(c.type))
                            .join(" and ")}`;
                        }
                        return (
                          <Td>
                            <div className="text-gray-900 flex gap-2">
                              <PaymentRequestStatusBadge status={pr.status} />
                              {commFailureDescription && (
                                <Tooltip
                                  trigger={
                                    <ExclamationCircleIcon className="w-4 h-4 text-red-600" />
                                  }
                                  content={commFailureDescription}
                                />
                              )}
                            </div>
                          </Td>
                        );
                      },
                    },
                    {
                      header: "",
                      cellFn: (pr: PaymentRequest) => (
                        <PaymentRequestActionCell paymentRequest={pr} />
                      ),
                    },
                    {
                      header: "",
                      cellFn: (pr: PaymentRequest) => {
                        return (
                          <Td>
                            {pr.s3Key && (
                              <button
                                className="align-bottom"
                                disabled={!downloadButtonEnabled}
                                onClick={(e) => {
                                  setDownloadButtonEnabled(false);
                                  getPrintStatementUrl({
                                    variables: { paymentRequestId: pr.id },
                                    onCompleted: (data) => {
                                      setDownloadButtonEnabled(true);
                                      if (
                                        data.getPrintStatementUrl.success &&
                                        data.getPrintStatementUrl.url
                                      ) {
                                        const a = document.createElement("a");
                                        a.href = data.getPrintStatementUrl.url;
                                        a.click();
                                        analytics?.track("Paper Statement PDF Downloaded", {
                                          patientId: batch.paymentRequests[0]?.patient.id,
                                          organizationId: user?.organization.id,
                                          organizationName: user?.organization.name,
                                          locationId: user?.activeLocation.id,
                                          locationName: user?.activeLocation.name,
                                          page: 'BatchPage',
                                        });
                                        toast.success(
                                          "Your statement has downloaded!"
                                        );
                                      } else {
                                        toast.error(
                                          "Unable to retrieve statement"
                                        );
                                      }
                                    },
                                    onError: (e) => {
                                      setDownloadButtonEnabled(true);
                                      toast.error(
                                        "Unable to retrieve statement"
                                      );
                                      Sentry.captureException(e);
                                    },
                                  });
                                  e.stopPropagation();
                                }}
                              >
                                <DocumentDownloadIcon className="hover:fill-gray-300 -mr-1 h-6 w-6" />
                              </button>
                            )}
                          </Td>
                        );
                      },
                    },
                  ]}
                  rows={batch.paymentRequests}
                  onRowClick={(row) => {
                    setSelectedPaymentRequest(row);
                  }}
                />
              </div>
            </div>
          </div>
          {selectedPaymentRequest && (
            <PaymentRequestSlideout
              paymentRequest={selectedPaymentRequest}
              onClose={() => setSelectedPaymentRequest(null)}
            />
          )}
        </div>
      }
    />
  );
};
