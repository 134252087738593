import {
  ColumnDef,
  ColumnFiltersState,
  Table as ReactTable,
  Row,
  SortingState,
  TableOptions,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import * as React from "react";

import { OvalSpinner } from "../../components/loading";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { cn } from "../../utils";
import { DataTableToolbar } from "./data-table-toolbar";
import { ChargemasterRow } from "./list";

interface DataTableProps<TValue> {
  columns: ColumnDef<ChargemasterRow, TValue>[];
  defaultColumnFilters?: ColumnFiltersState;
  data: ChargemasterRow[];
}

const MemoizedTableRow = React.memo(
  ({
    row,
    onRowClick,
    columns,
  }: {
    row: Row<ChargemasterRow>;
    onRowClick?: (row: ChargemasterRow) => void;
    columns: ColumnDef<ChargemasterRow, any>[];
  }) => (
    <TableRow
      key={row.id}
      data-state={row.getIsSelected() && "selected"}
      onClick={() => {
        onRowClick?.(row.original);
      }}
      className={cn("divide-x", onRowClick && "cursor-pointer")}
    >
      {row.getVisibleCells().map((cell) => (
        <TableCell
          key={cell.id}
          className={cn(
            "whitespace-nowrap p-2",
            cell.column.columnDef.className
          )}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
    </TableRow>
  ),
  (prevProps, nextProps) => {
    return (
      prevProps.row.id === nextProps.row.id &&
      prevProps.row.getIsSelected() === nextProps.row.getIsSelected()
    );
  }
);

export function DataTable<TValue>({
  columns,
  data,
  defaultColumnFilters,
  children,
  onRowClick,
  loading,
  loadingMore,
  isPending,
  onArchiveFilterChange,
}: DataTableProps<TValue> & {
  children?: (table: ReactTable<ChargemasterRow>) => React.ReactElement;
  onRowClick?: (row: ChargemasterRow) => void;
  loading?: boolean;
  loadingMore?: boolean;
  isPending: boolean;
  onArchiveFilterChange: (archiveFilterOn: boolean) => void;
}) {
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      archived: false,
    });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    defaultColumnFilters ?? []
  );
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "lastUsedAt",
      desc: true,
    },
  ]);

  const tableConfig = React.useMemo<TableOptions<ChargemasterRow>>(
    () => ({
      data,
      columns,
      state: {
        sorting,
        columnVisibility,
        rowSelection,
        columnFilters,
        globalFilter,
      },
      enableRowSelection: true,
      onRowSelectionChange: setRowSelection,
      onSortingChange: setSorting,
      onColumnFiltersChange: setColumnFilters,
      onColumnVisibilityChange: setColumnVisibility,
      onGlobalFilterChange: setGlobalFilter,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
    }),
    [
      data,
      columns,
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter,
    ]
  );

  const table = useReactTable<ChargemasterRow>(tableConfig);

  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 35,
    overscan: 20,
  });

  const archiveFilter: string[] | undefined = table
    .getColumn("archived")
    ?.getFilterValue() as any;
  React.useEffect(() => {
    const archiveFilterOn = archiveFilter?.includes("Archived") ?? false;
    onArchiveFilterChange(archiveFilterOn);
  }, [archiveFilter]);

  return (
    <>
      {children && children(table)}
      <div className="space-y-4">
        <div className="w-full">
          <DataTableToolbar table={table} />
        </div>
        <div className="border bg-white relative">
          <div
            ref={tableContainerRef}
            className="max-h-[85vh] overflow-auto relative"
          >
            <div className="min-w-full inline-block align-middle">
              <Table>
                <TableHeader className="sticky top-0 bg-white z-50">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id} className="divide-x">
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead
                            key={header.id}
                            className="p-2 bg-white border-b"
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    <>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          style={{
                            height: `${
                              virtualizer.getVirtualItems()[0]?.start ?? 0
                            }px`,
                            padding: 0,
                          }}
                        />
                      </TableRow>
                      {virtualizer.getVirtualItems().map((virtualRow) => {
                        const row = rows[virtualRow.index];
                        return (
                          <MemoizedTableRow
                            key={row.id}
                            row={row}
                            onRowClick={onRowClick}
                            columns={columns}
                          />
                        );
                      })}
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          style={{
                            height: `${
                              virtualizer.getTotalSize() -
                              (virtualizer.getVirtualItems()[
                                virtualizer.getVirtualItems().length - 1
                              ]?.end ?? 0)
                            }px`,
                            padding: 0,
                          }}
                        />
                      </TableRow>
                    </>
                  ) : loading ? (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center p-2"
                      >
                        <div className="w-full flex justify-center">
                          <OvalSpinner />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center p-2"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
