import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { gql, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";

import { useUser } from "../../user-context";
import { Card, Button } from "../../components";
import { Layout } from "../layout";
import {
  GetFeeSchedules,
  GetFeeSchedulesVariables,
  GetFeeSchedules_feeSchedules as FeeSchedule,
} from "../../generated/GetFeeSchedules";

export const FEESCHEDULE_LIST_FRAGMENT = gql`
  fragment FeeScheduleListFragment on FeeSchedule {
    id
    createdAt
    updatedAt
    name
    validAfter
    validBefore
    _count {
      scheduledServiceFees
    }
    payerFeeSchedules {
      id
      payer {
        id
        name
      }
    }
  }
`;

export const GET_FEESCHEDULES = gql`
  ${FEESCHEDULE_LIST_FRAGMENT}
  query GetFeeSchedules($locationId: String!, $pending: Boolean!) {
    feeSchedules(
      where: {
        locationId: { equals: $locationId }
        pending: { equals: $pending }
      }
      orderBy: { updatedAt: desc }
    ) {
      ...FeeScheduleListFragment
    }
  }
`;

const EmptyFeeSchedules: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className="text-center">
    <svg
      className="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
    <h3 className="mt-2 text-sm font-medium text-gray-900">No Fee Schedules</h3>
    <p className="mt-1 text-sm text-gray-500">
      Get started by creating a new fee schedule.
    </p>
    <div className="mt-6">
      <Link
        to="/fee-schedules/new"
        className="flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        New Fee Schedule
      </Link>
    </div>
  </div>
);

export const FeeScheduleList: React.FC<
  React.PropsWithChildren<{ isPending: boolean }>
> = ({ isPending }) => {
  const user = useUser()!;
  const navigate = useNavigate();
  const { loading, error, data } = useQuery<
    GetFeeSchedules,
    GetFeeSchedulesVariables
  >(GET_FEESCHEDULES, {
    variables: {
      locationId: user!.activeLocation.id,
      pending: isPending,
    },
  });
  if (loading) return <>Loading</>;
  if (!data) return null;
  if (data.feeSchedules.length === 0) {
    return (
      <Card>
        <div className="flex items-center justify-center w-full py-4">
          <EmptyFeeSchedules />
        </div>
      </Card>
    );
  }

  return (
    <Card className="px-0 py-0">
      <div className="flex flex-col divide-y divide-gray-200 w-full">
        {data.feeSchedules.map((feeSchedule) => {
          const payerFeeSchedules = feeSchedule.payerFeeSchedules;
          return (
            <div
              key={feeSchedule.id}
              className="w-full flex justify-between gap-1 pt-3 p-4"
            >
              <div className="flex flex-col gap-1 truncate">
                <div className="text-lg font-medium">{feeSchedule.name}</div>
                <div className="flex gap-4">
                  <div>
                    <label className="text-gray-700 text-sm">Payers</label>
                    <div className="flex gap-1 flex-wrap">
                      {feeSchedule.payerFeeSchedules
                        .slice(0, 3)
                        .map((p) => p.payer.name)
                        .join(", ")}
                      {payerFeeSchedules.length > 3 && (
                        <>, and {payerFeeSchedules.length - 3} others...</>
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-700 text-sm">
                      Number of Fees
                    </label>
                    <div className="flex gap-1 flex-wrap">
                      {feeSchedule._count?.scheduledServiceFees}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <Link
                  key={feeSchedule.id}
                  to={`/fee-schedules/${feeSchedule.id}/edit`}
                  className="bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 bg-indigo-500 hover:bg-indigo-400 cursor-pointer inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                >
                  Edit
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export const FeeSchedules: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser()!;
  const [isPending, setIsPending] = useState(false);
  const togglePending = () => {
    setIsPending(!isPending);
  };
  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Fee Schedules
          </h1>
          <div className="flex justify-between">
            {user.isPledgeUser && (
              <Button onClick={togglePending}>
                Toggle Pending {isPending ? "Off" : "On"}
              </Button>
            )}
            <Link
              to="/fee-schedules/new"
              className="ml-5 flex px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              New Fee Schedule
            </Link>
          </div>
        </div>
      }
      content={
        <div className="py-4">
          <FeeScheduleList isPending={isPending} />
        </div>
      }
    />
  );
};
