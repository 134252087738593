import { gql, useQuery } from "@apollo/client";
import { Disclosure } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import * as HoverCard from "@radix-ui/react-hover-card";
import { TriangleRightIcon } from "@radix-ui/react-icons";
import { BadgeDelta } from "@tremor/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAnalytics } from "../../analytics-context";
import { Tooltip } from "../../components";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import {
  GetChargemasterPeekDetails,
  GetChargemasterPeekDetailsVariables,
} from "../../generated/GetChargemasterPeekDetails";
import {
  GetVisitDetails_appointment as Appointment,
  GetVisitDetails_appointment_accumulatorAdjustments_benefitAccumulatorAdjustments as BenefitAccumulatorAdjustment,
  GetVisitDetails_appointment_bill as Bill,
  GetVisitDetails_appointment_bill_charges as Charge,
  GetVisitDetails_appointment_bill_toCollect_visitCollectionRequest_estimate as Estimate,
  GetVisitDetails_appointment_bill_charges_insuranceBillableCharges as InsuranceBillableCharge,
  GetVisitDetails_appointment_insurancePolicies as InsurancePolicy,
  GetVisitDetails_appointment_bill_toCollect_visitCollectionRequest as VisitCollectionRequest,
} from "../../generated/GetVisitDetails";
import { BillCollectionMode, BillState } from "../../generated/globalTypes";
import { useFeatureFlags } from "../../hooks";
import { useUser } from "../../user-context";
import { cn, formatUSD, isDefined, mapNullable } from "../../utils";
import { BillSummary, EstimateSummary } from "../appointments";
import { DepositPreviewCard } from "../appointments/table/columns";
import { CreateEstimateWizardDialogButton } from "../appointments/table/estimate-dialog";
import { SaltedCharges } from "../appointments/table/estimate-form";
import { BillActionDropdown } from "../billing";
import { CoverageLevel } from "../patients/eligibilities/types";
import { VisitBillStatusIndicator, getBillStatus } from "../patients/visits";
import { CollectDepositButton } from "./collect-deposit-button";

export type VisitDisplayCardAppointment = Appointment;

const UnestimatedSummary: React.FC<{ bill: Bill | null }> = ({ bill }) => {
  const charges = bill?.charges ?? [];
  if (charges.length > 0) {
    return (
      <div className="flex flex-col">
        <div className="flex gap-2">
          <div className="text-sm text-gray-500">
            Charges have been entered for this visit:
          </div>
          <div className="flex gap-1 flex-wrap">
            {charges.map((charge, i) => (
              <>
                {charge.chargemaster ? (
                  <ChargemasterBadge
                    chargemaster={charge.chargemaster}
                    units={charge.units}
                  />
                ) : (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border hover:bg-gray-50">
                    {charge.customCode}
                    {charge.units && (
                      <span className="text-gray-500 pl-1">
                        x {charge.units}
                      </span>
                    )}
                  </span>
                )}
                {i === charges.length - 1 ? null : (
                  <span className="text-gray-500">&#8226;</span>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col">
      <div>
        <div className="text-sm text-gray-500">
          No charges have been entered for this visit
        </div>
      </div>
    </div>
  );
};

const BillDisplayCard: React.FC<{ bill: Bill | null }> = ({ bill }) => {
  if (!bill) {
    return <UnestimatedSummary bill={bill} />;
  }

  return (
    <div className="pt-2">
      <BillSummary bill={bill} />
    </div>
  );
};

const GET_CHARGEMASTER_PEEK_DETAILS = gql`
  query GetChargemasterPeekDetails($id: String!) {
    chargemaster(where: { id: $id }) {
      id
      code
      description
      modifier1
      modifier2
      modifier3
      modifier4
      amount
      chargemasterGroup {
        id
        cashPay
        scheduledServiceFees {
          id
          feeSchedule {
            id
            name
          }
        }
        chargeTemplateCharges(orderBy: { priority: asc }) {
          id
          chargeTemplate {
            id
            name
          }
        }
      }
    }
  }
`;

const ChargemasterPeekDetailsCard: React.FC<{
  chargemaster: Chargemaster;
}> = ({ chargemaster }) => {
  const { data, loading } = useQuery<
    GetChargemasterPeekDetails,
    GetChargemasterPeekDetailsVariables
  >(GET_CHARGEMASTER_PEEK_DETAILS, {
    variables: {
      id: chargemaster.id,
    },
  });
  if (loading || !data) return <>Loading...</>;

  const details = data.chargemaster!;

  const scheduledServiceFees =
    details.chargemasterGroup?.scheduledServiceFees ?? [];
  const chargeTemplateCharges =
    details.chargemasterGroup?.chargeTemplateCharges ?? [];
  return (
    <div className="flex flex-col pt-1 gap-1">
      <div className="flex justify-between gap-4">
        <div>
          {details.chargemasterGroup?.cashPay ? (
            <Badge>Cash Only</Badge>
          ) : (
            <Badge>Insurance Applies</Badge>
          )}
        </div>
      </div>
      <div className="flex justify-between gap-4">
        <div>Fee Schedules</div>
        <div className="text-gray-500">
          {scheduledServiceFees.length === 0 ? (
            <span className="text-gray-500 italic">Unused</span>
          ) : (
            <>
              {scheduledServiceFees.slice(0, 2).map((fee, i) => {
                return <div key={fee.id}>{fee.feeSchedule.name}</div>;
              })}
              {scheduledServiceFees.length > 3 && (
                <>and {scheduledServiceFees.length - 3} others...</>
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex justify-between gap-4">
        <div>Charge Templates</div>
        <div className="text-gray-500">
          {chargeTemplateCharges.length === 0 ? (
            <span className="text-gray-500 italic">Unused</span>
          ) : (
            <>
              {chargeTemplateCharges.slice(0, 2).map((ctc, i) => {
                return <div key={ctc.id}>{ctc.chargeTemplate.name}</div>;
              })}
              {chargeTemplateCharges.length > 3 && (
                <>and {chargeTemplateCharges.length - 3} others...</>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

type Chargemaster = {
  id: string;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description: string | null;
  chargemasterGroupId: string | null;
};

export const ChargemasterBadge: React.FC<{
  chargemaster: Chargemaster;
  units?: number;
  trigger?: React.ReactNode;
}> = ({ chargemaster, units, trigger }) => {
  const modifiers = [
    chargemaster.modifier1,
    chargemaster.modifier2,
    chargemaster.modifier3,
    chargemaster.modifier4,
  ]
    .filter((m) => !!m)
    .join(" | ");

  return (
    <HoverCard.Root openDelay={100}>
      <HoverCard.Trigger>
        {trigger ?? (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border hover:bg-gray-50">
            {chargemaster.code}
            {modifiers && ` - ${modifiers}`}
            {units && <span className="text-gray-500 pl-1">x {units}</span>}
          </span>
        )}
      </HoverCard.Trigger>
      <HoverCard.Content
        side="top"
        sideOffset={5}
        align="center"
        alignOffset={-200}
      >
        <div className="flex flex-col bg-white text-gray-900 drop-shadow-xl text-sm rounded-md p-4 border">
          {chargemaster.chargemasterGroupId ? (
            <Link
              to={`/chargemaster/${chargemaster.chargemasterGroupId}`}
              className="text-lg font-medium hover:text-gray-500"
            >
              {chargemaster.code}
              {modifiers && ` - ${modifiers}`}
            </Link>
          ) : (
            <div className="text-lg font-medium">
              {chargemaster.code}
              {modifiers && ` - ${modifiers}`}
            </div>
          )}
          <div className="text-sm text-gray-500">
            {chargemaster.description}
          </div>
          <ChargemasterPeekDetailsCard chargemaster={chargemaster} />
        </div>
      </HoverCard.Content>
    </HoverCard.Root>
  );
};

const EstimateDisclosurePanel: React.FC<{
  bill: Bill | null;
  appointment: VisitDisplayCardAppointment | null;
  estimate: Estimate | null;
  collectionRequest: VisitCollectionRequest | null;
}> = ({ bill, appointment, estimate, collectionRequest }) => {
  const user = useUser();
  const analytics = useAnalytics();
  useEffect(() => {
    analytics?.track("Estimate Disclosure Opened", {
      organizationId: user?.organization.id,
      organizationName: user?.organization.name,
      locationId: user?.activeLocation.id,
      locationName: user?.activeLocation.name,
    });
  }, []);
  return (
    <div className="flex flex-col py-4 px-2 border rounded-md divide-y gap-1">
      <div className="flex justify-between items-center">
        <dt className="text-sm text-gray-600">Estimated Responsibility</dt>

        <div className="text-sm font-medium">
          {mapNullable(formatUSD)(collectionRequest?.amount) ?? (
            <span className="text-gray-500 font-normal">None</span>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center pt-2 pb-1">
        <dt className="text-sm text-gray-600">Charge Template</dt>
        <dd className="text-sm text-gray-700">
          {estimate?.chargeTemplate?.name ?? (
            <span className="text-gray-500">None</span>
          )}
        </dd>
      </div>

      <div className="flex justify-between items-center pt-2 pb-1">
        <dt className="text-sm text-gray-600">Fee Schedule</dt>
        <dd className="text-sm text-gray-700">
          {estimate?.feeSchedule?.name ?? (
            <span className="text-gray-500">None</span>
          )}
        </dd>
      </div>

      <div>
        <div className="flex justify-between items-center py-1">
          <div className="flex items-center space-x-2">
            <dt className="text-sm text-gray-600">Line Items</dt>
            {estimate?.saltedBill?.appointment?.id && (
              <SaltedCharges
                appointmentId={estimate.saltedBill.appointment.id}
              />
            )}
          </div>

          {appointment ? (
            bill?.toCollect?.visitCollectionRequest ? (
              <CreateEstimateWizardDialogButton
                appointmentId={appointment.id}
                text="Edit Estimate"
              />
            ) : (
              <CreateEstimateWizardDialogButton
                appointmentId={appointment.id}
              />
            )
          ) : null}
        </div>
        {estimate && bill ? (
          <EstimateSummary bill={bill} />
        ) : collectionRequest ? (
          <DepositPreviewCard deposit={collectionRequest} />
        ) : null}
      </div>
    </div>
  );
};

const BenefitAccumulatorAdjustmentDisplay: React.FC<{
  benefitAccumulatorAdjustment: BenefitAccumulatorAdjustment;
}> = ({ benefitAccumulatorAdjustment }) => {
  const parts = [];
  if (benefitAccumulatorAdjustment.networkStatus) {
    parts.push(
      benefitAccumulatorAdjustment.networkStatus
        ? "In Network"
        : "Out of Network"
    );
  }
  if (benefitAccumulatorAdjustment.coverageLevel) {
    if (
      benefitAccumulatorAdjustment.coverageLevel === CoverageLevel.Individual
    ) {
      parts.push("Individual");
    } else if (
      benefitAccumulatorAdjustment.coverageLevel === CoverageLevel.Family
    ) {
      parts.push("Family");
    } else {
      parts.push(benefitAccumulatorAdjustment.coverageLevel);
    }
  }
  parts.push(benefitAccumulatorAdjustment.providerServiceConfiguration.name);
  return <>{parts.join(" ")}</>;
};

export const VisitBillSummary: React.FC<{
  appointment: VisitDisplayCardAppointment | null;
  bill: Bill | null;
}> = ({ appointment, bill }) => {
  const user = useUser();
  const flags = useFeatureFlags();
  const billFinalized =
    isDefined(bill) &&
    [
      BillState.Archived,
      BillState.InReview,
      BillState.Ready,
      BillState.Reconciled,
      BillState.Resolved,
    ].includes(bill.status);
  const collectionRequest = bill?.toCollect?.visitCollectionRequest;
  const estimate = collectionRequest?.estimate;

  const defaultBillStatus = getBillStatus(bill);

  const charges = bill?.charges ?? [];
  const claims = charges.reduce<{
    [policyId: string]: {
      policy: InsurancePolicy;
      charges: (Charge & { ibc: InsuranceBillableCharge })[];
    };
  }>((acc, charge) => {
    for (const ibc of charge.insuranceBillableCharges) {
      if (!ibc.accountCoverage) continue;
      let claim = acc[ibc.accountCoverage.insurancePolicy.id] ?? {
        policy: ibc.accountCoverage.insurancePolicy,
        charges: [],
      };

      claim.charges.push({
        ...charge,
        ibc,
      });
      acc[ibc.accountCoverage.insurancePolicy.id] = claim;
    }
    return acc;
  }, {});

  const accumulatorAdjustments = appointment?.accumulatorAdjustments ?? [];

  const balance = bill?.toCollect.patientBalance ?? 0;

  const usingEstimate =
    bill?.toCollect.collectionMode === "Deposit" ||
    bill?.toCollect.collectionMode === "Estimate";

  return (
    <>
      <div className="flex flex-col divide-y gap-1">
        {flags.chargesSupported && (
          <>
            <div className="flex justify-between items-center pt-2 pb-1">
              <dt className="text-sm font-medium text-gray-900">
                Billing Status
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <VisitBillStatusIndicator billStatus={defaultBillStatus} />
              </dd>
            </div>
            <div className="flex justify-between items-center pt-2 pb-1">
              <dt className="text-sm font-medium text-gray-900">Balance</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 font-semibold flex items-center gap-1">
                {usingEstimate && (
                  <Tooltip
                    content={<>Balance is based on the estimate</>}
                    trigger={
                      <LightningBoltIcon className="h-4 w-4 text-gray-500" />
                    }
                  />
                )}
                {formatUSD(balance)}
              </dd>
            </div>
          </>
        )}

        {flags.estimatesEnabled && (
          <Disclosure as="div" defaultOpen={!billFinalized && !!estimate}>
            <Disclosure.Button
              className={cn(
                "w-full flex justify-between items-center gap-2 group"
              )}
            >
              {({ open }) => (
                <>
                  <div className="grow flex justify-between items-center pt-2 pb-1">
                    <dt className="text-sm font-medium flex items-center gap-2 rounded-md group-hover:bg-gray-100 px-1">
                      Estimate
                      <TriangleRightIcon
                        className={cn(
                          "h-4 w-4 text-gray-500 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                          open && "rotate-90"
                        )}
                      />
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {collectionRequest ? (
                        estimate?.chargesDifferFromBill ? (
                          <Tooltip
                            content={
                              <>
                                Entered charges differ than those used in
                                estimate
                              </>
                            }
                            trigger={
                              <div className="flex items-center gap-1">
                                <ExclamationCircleIcon className="h-4 w-4 text-yellow-500" />
                                Stale Estimate
                              </div>
                            }
                          />
                        ) : (
                          <>Estimated</>
                        )
                      ) : (
                        <>Unestimated</>
                      )}
                    </dd>
                  </div>
                </>
              )}
            </Disclosure.Button>
            <Disclosure.Panel>
              <EstimateDisclosurePanel
                bill={bill}
                appointment={appointment}
                estimate={estimate ?? null}
                collectionRequest={collectionRequest ?? null}
              />
            </Disclosure.Panel>
          </Disclosure>
        )}

        {charges.length > 0 && (
          <Disclosure as="div" defaultOpen={billFinalized}>
            <Disclosure.Button
              className={cn(
                "w-full flex justify-between items-center gap-2 group"
              )}
            >
              {({ open }) => (
                <>
                  <div className="grow flex justify-between items-center pt-2 pb-1">
                    <dt className="text-sm font-medium flex items-center gap-2 rounded-md group-hover:bg-gray-100 px-1">
                      Line Items
                      <TriangleRightIcon
                        className={cn(
                          "h-4 w-4 text-gray-500 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                          open && "rotate-90"
                        )}
                      />
                    </dt>
                    <div className="text-sm text-gray-700">
                      {charges.length} charge
                      {charges.length === 1 ? "" : "s"}
                    </div>
                  </div>
                </>
              )}
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="flex flex-col py-4 px-2 border rounded-md divide-y gap-1">
                {user?.externalLedgerName && (
                  <>Charges displayed from {user?.externalLedgerName}.</>
                )}
                <BillDisplayCard bill={bill} />
              </div>
            </Disclosure.Panel>
          </Disclosure>
        )}

        <Disclosure as="div">
          <Disclosure.Button
            className={cn(
              "w-full flex justify-between items-center gap-2 group"
            )}
          >
            {({ open }) => (
              <>
                <div className="grow flex justify-between items-center pt-2 pb-1">
                  <dt className="text-sm font-medium flex items-center gap-2 rounded-md group-hover:bg-gray-100 px-1">
                    Claims
                    <TriangleRightIcon
                      className={cn(
                        "h-4 w-4 text-gray-500 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                        open && "rotate-90"
                      )}
                    />
                  </dt>
                  <div className="text-sm text-gray-700">
                    {Object.keys(claims).length} claim
                    {Object.keys(claims).length === 1 ? "" : "s"}
                  </div>
                </div>
              </>
            )}
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className="flex flex-col py-4 px-2 border rounded-md divide-y gap-1">
              {Object.keys(claims).length == 0 && (
                <div className="text-gray-500 text-sm">No claims</div>
              )}
              {Object.values(claims).map((claim) => {
                const processed = claim.charges.every(
                  (c) => c.ibc.status === "Processed"
                );
                const billed = claim.charges.every(
                  (c) =>
                    c.ibc.status === "Processed" || c.ibc.status === "Billed"
                );
                const pending = !processed && !billed;
                const status = pending
                  ? "Pending"
                  : processed
                  ? "Finalized"
                  : "Billed";
                return (
                  <div key={claim.policy.id} className="pt-1 pb-2">
                    <div className="flex justify-between">
                      <div>
                        {claim.policy.payer.name} - {claim.policy.memberId}
                      </div>
                      <div>{status}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Disclosure.Panel>
        </Disclosure>

        {flags.automatedAccumulatorTrackingEnabled && (
          <Disclosure as="div">
            <Disclosure.Button
              className={cn(
                "w-full flex justify-between items-center gap-2 group"
              )}
            >
              {({ open }) => (
                <>
                  <div className="grow flex justify-between items-center pt-2 pb-1">
                    <dt className="text-sm font-medium flex items-center gap-2 rounded-md group-hover:bg-gray-100 px-1">
                      Accumulator Adjustments
                      <TriangleRightIcon
                        className={cn(
                          "h-4 w-4 text-gray-500 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                          open && "rotate-90"
                        )}
                      />
                    </dt>
                    <div className="text-sm text-gray-700">
                      {accumulatorAdjustments.length} adjustment
                      {accumulatorAdjustments.length === 1 ? "" : "s"}
                    </div>
                  </div>
                </>
              )}
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="flex flex-col py-4 px-2 border rounded-md divide-y gap-1">
                {accumulatorAdjustments.length === 0 && (
                  <div className="text-gray-500 text-sm">
                    No benefit accumulator adjustments
                  </div>
                )}
                {accumulatorAdjustments.map((adjustment) => {
                  console.log({ adjustment });
                  return (
                    <div key={adjustment.id}>
                      <div className="grow flex justify-between items-center pt-2 pb-1">
                        <dt className="text-sm font-medium flex items-center gap-2 rounded-md group-hover:bg-gray-100 px-1">
                          Insurance Policy
                        </dt>
                        <div className="text-sm text-gray-700">
                          {adjustment.insurancePolicy.payer.name} -{" "}
                          {adjustment.insurancePolicy.memberId}
                        </div>
                      </div>
                      <div className="grow flex justify-between items-center pt-2 pb-1">
                        <dt className="text-sm font-medium flex items-center gap-2 rounded-md group-hover:bg-gray-100 px-1">
                          Type
                        </dt>
                        <div className="text-sm text-gray-700">
                          {adjustment.type}
                        </div>
                      </div>
                      <table className="border-separate border-spacing-0.5 pt-2 pb-1 gap-1">
                        {adjustment.benefitAccumulatorAdjustments.map((baa) => (
                          <tr key={baa.id}>
                            <th className="text-sm px-1">
                              <div className="flex text-left font-normal">
                                <BenefitAccumulatorAdjustmentDisplay
                                  benefitAccumulatorAdjustment={baa}
                                />
                              </div>
                            </th>
                            <th className="px-1">
                              <div className="flex flex-wrap gap-1 font-normal">
                                {baa.appliedDeductible !== 0 && (
                                  <div className="text-sm">
                                    <BadgeDelta
                                      deltaType="decrease"
                                      isIncreasePositive={false}
                                    >
                                      Deductible:{" "}
                                      {formatUSD(baa.appliedDeductible)}
                                    </BadgeDelta>
                                  </div>
                                )}
                                {baa.appliedOutOfPocket !== 0 && (
                                  <div className="text-sm">
                                    <BadgeDelta
                                      deltaType="decrease"
                                      isIncreasePositive={false}
                                    >
                                      Out of Pocket:{" "}
                                      {formatUSD(baa.appliedOutOfPocket)}
                                    </BadgeDelta>
                                  </div>
                                )}
                                {baa.appliedVisits !== 0 && (
                                  <div className="text-sm">
                                    <BadgeDelta
                                      deltaType="decrease"
                                      isIncreasePositive={true}
                                    >
                                      Visits: {baa.appliedVisits}
                                    </BadgeDelta>
                                  </div>
                                )}
                              </div>
                            </th>
                          </tr>
                        ))}
                      </table>
                    </div>
                  );
                })}
              </div>
            </Disclosure.Panel>
          </Disclosure>
        )}
      </div>
    </>
  );
};

export const VisitBillDisplayCard: React.FC<{
  appointment: VisitDisplayCardAppointment | null;
  bill: Bill;
}> = ({ appointment, bill }) => {
  const flags = useFeatureFlags();
  const paymentMethods = appointment?.account.patient.paymentMethods ?? [];
  return (
    <Card>
      <div className="flex flex-col divide-y gap-2 w-full py-2 px-4">
        <div className="flex justify-between items-center">
          <h1 className="truncate text-lg font-medium text-gray-900">
            Bill Details
          </h1>

          {flags.estimatesEnabled && !bill && appointment && (
            <>
              {paymentMethods.length > 0 ? (
                <CollectDepositButton appointment={appointment} />
              ) : (
                <Button disabled={true}>Collect Deposit</Button>
              )}
            </>
          )}

          {bill && <BillActionDropdown bill={bill} />}
        </div>

        <VisitBillSummary appointment={appointment} bill={bill} />
      </div>
    </Card>
  );
};
