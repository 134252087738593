import React, { Fragment, useState } from "react";
import { Dialog, Transition, Menu, Disclosure } from "@headlessui/react";
import {
  CashIcon,
  UserGroupIcon,
  UsersIcon,
  XIcon,
  CreditCardIcon,
  SelectorIcon,
  CheckIcon,
  ChevronDownIcon,
  CalendarIcon,
  LibraryIcon,
  ChevronDoubleRightIcon,
  MailIcon,
  ClipboardListIcon,
  ClipboardCheckIcon,
  AdjustmentsIcon,
  CalculatorIcon,
  TableIcon,
} from "@heroicons/react/outline";
import { matchPath, Link, useLocation } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import * as Popover from "@radix-ui/react-popover";

import { classNames, isDefined, sleep } from "../utils";
import { useUser } from "../user-context";
import { DisableableLink, Tooltip } from ".";
import { Location, User } from "../auth-context";
import {
  SwitchLocation,
  SwitchLocationVariables,
} from "../generated/SwitchLocation";
import { OvalSpinner } from "./loading";
import { DemoToggle } from "./demo-toggle";
import { toast } from "react-toastify";
import { useFeatureFlags } from "../hooks";
import { GET_SIDEBAR_COUNTS } from "../graphql";
import { WalletCardsIcon } from "lucide-react";

const SWITCH_LOCATION = gql`
  mutation SwitchLocation($locationId: String!) {
    switchLocation(locationId: $locationId) {
      id
    }
  }
`;

export const Avatar: React.FC<
  React.PropsWithChildren<{ initials: string; loading: boolean }>
> = ({ initials, loading }) => {
  return (
    <div className="w-10 h-10 relative flex justify-center items-center rounded-full bg-indigo-300 text-xl text-white uppercase">
      {loading ? <OvalSpinner className="text-white" /> : initials}
    </div>
  );
};

const AccountSwitcher: React.FC<
  React.PropsWithChildren<{
    user: User;
    logout: () => void;
    collapsed: boolean;
  }>
> = ({ user, logout, collapsed }) => {
  const [switchLocation, switchLocationResult] = useMutation<
    SwitchLocation,
    SwitchLocationVariables
  >(SWITCH_LOCATION);

  const initials = [user.firstName?.charAt(0), user.lastName?.charAt(0)]
    .filter(isDefined)
    .join("");

  const onSwitchLocation = (location: Pick<Location, "id" | "name">) => {
    switchLocation({
      variables: { locationId: location.id },
      onCompleted: async ({ switchLocation }) => {
        if (switchLocation?.id) {
          toast.success(`Switching to ${location.name}...`);
          await sleep(1500);
          window.location.reload();
        } else {
          toast.error(`Failed to switch to ${location.name}`);
        }
      },
    });
  };

  return (
    <Menu as="div" className="relative inline-block text-left max-w-[15.25rem]">
      <div>
        <Menu.Button
          className={classNames(
            "group w-full bg-gray-100 rounded-md py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200",
            collapsed ? "px-1" : "px-3.5"
          )}
        >
          <span className="flex w-full justify-between items-center">
            <span className="flex min-w-0 items-center justify-between space-x-3">
              <Avatar
                initials={initials}
                loading={switchLocationResult.loading}
              />
              <span
                className={classNames(
                  "flex-1 flex flex-col min-w-0",
                  collapsed ? "hidden" : ""
                )}
              >
                <span className="text-gray-900 text-sm font-medium truncate">
                  {user.activeLocation.name}
                </span>
                <span className="text-gray-500 text-xs truncate">
                  {user.email}
                </span>
              </span>
            </span>
            <SelectorIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 mx-3 min-w-[12em] origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
          <div className="py-1">
            <span className="px-4 py-2 text-sm text-gray-700">
              Switch locations
            </span>
          </div>
          <div className="py-1">
            {user.organization.locations.map((location) => (
              <Menu.Item key={location.id}>
                {({ active }: { active: boolean }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full"
                    )}
                    onClick={() => onSwitchLocation(location)}
                  >
                    <div className="flex justify-between items-center">
                      <span>{location.name}</span>
                      {location.id === user.activeLocation.id && (
                        <CheckIcon className="h-5 w-5 font-semibold text-gray-700" />
                      )}
                    </div>
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
          <div className="py-1">
            {user.isAdmin ? (
              <Menu.Item>
                {({ active }: { active: boolean }) => (
                  <Link
                    to="/settings"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-left text-gray-700 w-full"
                    )}
                  >
                    Settings
                  </Link>
                )}
              </Menu.Item>
            ) : null}
            <Menu.Item>
              <DemoToggle />
            </Menu.Item>
            <Menu.Item>
              {({ active }: { active: boolean }) => (
                <button
                  onClick={logout}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm text-left w-full"
                  )}
                >
                  Logout
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

type SideBarNavigation =
  | {
      to: string;
      name: string;
      Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
      disabled: boolean;
      children?: undefined;
      count?: number;
    }
  | {
      to: string;
      name: string;
      Icon: (props: any) => JSX.Element;
      disabled: boolean;
      children: { name: string; to: string }[];
      count?: number;
    };

export const Sidebar: React.FC<
  React.PropsWithChildren<{
    setSidebarOpen: (open: boolean) => void;
    sidebarOpen: boolean;
    logout: () => void;
  }>
> = ({ setSidebarOpen, sidebarOpen, logout }) => {
  const location = useLocation();
  const user = useUser()!;
  const flags = useFeatureFlags();

  const [now] = useState(new Date());

  const { data } = useQuery(GET_SIDEBAR_COUNTS, {
    variables: {
      now,
      organizationId: user.organization.id,
    },
  });

  // NOTE: Commenting out the pages we want to hide while we're just testing benefits
  const sidebarNavigation: SideBarNavigation[] = [
    ...(flags.appointmentsPageEnabled
      ? [
          {
            to: "/appointments",
            name: "Appointments",
            Icon: CalendarIcon,
            disabled: false,
          },
        ]
      : []),
    {
      to: "/patients",
      name: "Patients",
      Icon: UserGroupIcon,
      disabled: false, // FIXME: Always enabled for now
    },
    ...(flags.verificationWorkflowEnabled
      ? [
          {
            to: "/verifications",
            name: "Verifications",
            Icon: ClipboardCheckIcon,
            disabled: false, // FIXME: Always enabled for now
          },
        ]
      : []),
    ...(flags.estimatesEnabled && flags.estimationWorkflowEnabled
      ? [
          {
            to: "/estimations",
            name: "Estimations",
            Icon: CalculatorIcon,
            disabled: false, // FIXME: Always enabled for now
          },
        ]
      : []),
    {
      to: "/billing",
      name: "Billing",
      Icon: CashIcon,
      disabled: !flags.postVisitBillingEnabled,
      children: [
        {
          name: "Bills",
          to: "/billing",
        },
        {
          name: "Batches",
          to: "/billing/batches",
        },
      ],
    },
    {
      to: "/payments",
      name: "Payments",
      Icon: CreditCardIcon,
      disabled: !flags.stripeAccountActive,
    },
    ...(flags.showTasksTab
      ? [
          {
            to: "/tasks?status=Todo",
            name: "Tasks",
            Icon: ClipboardListIcon,
            disabled: false,
            count: data?.overdueTasksCount?._count?.id,
          },
        ]
      : []),
    {
      to: "/providers",
      name: "Providers",
      Icon: UsersIcon,
      disabled: false, // FIXME: Always enabled for now
      children: [],
      // children: [
      //   {
      //     name: "Providers",
      //     to: "/providers",
      //   },
      //   {
      //     name: "Billing Groups",
      //     to: "/billing-groups",
      //   },
      // ],
    },
    {
      to: "/payers",
      name: "Payers",
      Icon: LibraryIcon,
      disabled: !flags.benefitsProductEnabled,
      ...(flags.benefitsProductEnabled && flags.estimatesEnabled
        ? {
            children: [
              {
                name: "Payers",
                to: "/payers",
              },
              // {
              //   name: "Fee Schedules",
              //   to: "/feeSchedules",
              // },
              {
                name: "Fee Schedules",
                to: "/fee-schedules",
              },
            ],
          }
        : {}),
    },
    ...(flags.estimatesEnabled
      ? [
          {
            to: "/chargemaster",
            name: "Chargemaster",
            Icon: TableIcon,
            disabled: false,
            children: [
              {
                name: "Chargemaster",
                to: "/chargemaster",
              },
              {
                name: "Charge Templates",
                to: "/chargemaster/templates",
              },
            ],
          },
        ]
      : []),
    ...(flags.verificationWorkflowEnabled || flags.estimatesEnabled
      ? [
          {
            to: "/rules",
            name: "Rules",
            Icon: AdjustmentsIcon,
            disabled: false,
          },
        ]
      : []),
    ...(flags.showMessagesTab
      ? [
          {
            to: "/messages",
            name: "Messages",
            Icon: MailIcon,
            disabled: false,
          },
        ]
      : []),
  ];

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 sm:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="px-1 flex justify-center">
                <AccountSwitcher
                  user={user}
                  logout={logout}
                  collapsed={false}
                />
              </div>
              <div className="mt-5 flex-1 h-0">
                <nav className="px-2 space-y-1">
                  {sidebarNavigation.map(
                    ({ to, name, Icon, disabled, children, count }, i) => {
                      const trimmedTo = to.split("?")[0];
                      const current = matchPath(trimmedTo, location.pathname);
                      if (children && children.length > 0) {
                        return (
                          <Disclosure as="div" key={i} className="space-y-1">
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  disabled={disabled}
                                  className={classNames(
                                    disabled
                                      ? "text-gray-400"
                                      : current
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                    "w-full group flex justify-center items-center px-2 py-2 text-base font-medium rounded-md"
                                  )}
                                >
                                  <Icon
                                    className={classNames(
                                      current
                                        ? "text-gray-900"
                                        : "text-gray-600 group-hover:text-gray-500",
                                      "mr-3 flex-shrink-0 h-6 w-6"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {name}
                                  <ChevronDownIcon
                                    className={classNames(
                                      disabled
                                        ? "text-gray-400"
                                        : open
                                        ? "text-gray-900"
                                        : "text-gray-600 -rotate-90",
                                      "ml-auto h-5 w-5 group-hover:text-gray-500"
                                    )}
                                    aria-hidden="true"
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-4 space-y-1">
                                  {children.map(
                                    ({ to: childTo, name: childName }) => (
                                      <Link
                                        key={childTo}
                                        to={childTo}
                                        className={classNames(
                                          matchPath(childTo, location.pathname)
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        )}
                                      >
                                        {childName}
                                      </Link>
                                    )
                                  )}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        );
                      }
                      return (
                        <DisableableLink
                          to={to}
                          key={i}
                          disabled={disabled}
                          className={classNames(
                            disabled
                              ? "text-gray-400"
                              : current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex justify-between items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <div className="flex items-center">
                            <Icon
                              className={classNames(
                                disabled
                                  ? "text-gray-400"
                                  : current
                                  ? "text-gray-900"
                                  : "text-gray-600 group-hover:text-gray-500",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {name}
                          </div>
                          {isDefined(count) ? (
                            <span
                              className={classNames(
                                count > 0
                                  ? "bg-red-100 text-red-600"
                                  : "bg-gray-100 text-gray-900",
                                "ml-3 rounded-full py-0.5 px-2.5 text-xs font-medium"
                              )}
                            >
                              {count}
                            </span>
                          ) : null}
                        </DisableableLink>
                      );
                    }
                  )}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Large desktop sidebar default uncollapsed */}
      <div className="hidden xl:flex xl:flex-shrink-0">
        <DesktopSidebar
          logout={logout}
          sidebarNavigation={sidebarNavigation}
          defaultCollapsed={false}
        />
      </div>

      {/* Medium desktop sidebar default collapsed */}
      <div className="hidden sm:flex sm:flex-shrink-0 xl:hidden">
        <DesktopSidebar
          logout={logout}
          sidebarNavigation={sidebarNavigation}
          defaultCollapsed={true}
        />
      </div>
    </>
  );
};

const DesktopSidebar: React.FC<
  React.PropsWithChildren<{
    logout: () => void;
    sidebarNavigation: SideBarNavigation[];
    defaultCollapsed: boolean;
  }>
> = ({ logout, sidebarNavigation, defaultCollapsed }) => {
  const user = useUser()!;
  const location = useLocation();
  const [desktopMenuCollapsed, setDesktopMenuCollapsed] =
    useState(defaultCollapsed);

  return (
    <div
      className={classNames(
        "flex flex-col print:hidden",
        desktopMenuCollapsed ? "" : "w-64"
      )}
    >
      <div className="flex flex-col flex-grow border-r border-gray-300 pt-5 bg-white">
        <div className="px-1 flex justify-center">
          <AccountSwitcher
            user={user}
            logout={logout}
            collapsed={desktopMenuCollapsed}
          />
        </div>
        <div className="mt-5 flex-grow flex flex-col">
          <nav className="flex-1 flex-col px-2 bg-white space-y-1">
            {sidebarNavigation.map(
              ({ to, name, Icon, count, disabled, children }, i) => {
                const trimmedTo = to.split("?")[0];
                const current = matchPath(trimmedTo, location.pathname);
                if (children && children.length > 0) {
                  const anyChildrenMatched = children.some(({ to: childTo }) =>
                    matchPath(childTo, location.pathname)
                  );
                  if (desktopMenuCollapsed) {
                    return (
                      <div className="flex justify-center" key={i}>
                        <Tooltip
                          content={<>{name}</>}
                          trigger={
                            <Popover.Root>
                              <Popover.Trigger asChild>
                                <button
                                  aria-label="Update dimensions"
                                  className={classNames(
                                    disabled
                                      ? "text-gray-400"
                                      : anyChildrenMatched
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                    "p-2 rounded-md font-medium text-base"
                                  )}
                                >
                                  <div>
                                    <Icon
                                      className={classNames(
                                        current
                                          ? "text-gray-900"
                                          : "text-gray-600 group-hover:text-gray-500",
                                        "flex-shrink-0 h-6 w-6"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </div>
                                </button>
                              </Popover.Trigger>
                              <Popover.Portal>
                                <Popover.Content
                                  className="p-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 space-y-1 !z-[6]"
                                  sideOffset={20}
                                  side="right"
                                >
                                  {children.map(
                                    ({ to: childTo, name: childName }) => (
                                      <Link
                                        key={childTo}
                                        to={childTo}
                                        className={classNames(
                                          matchPath(childTo, location.pathname)
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        )}
                                      >
                                        {childName}
                                      </Link>
                                    )
                                  )}
                                  <Popover.Arrow color="white" fill="white" />
                                </Popover.Content>
                              </Popover.Portal>
                            </Popover.Root>
                          }
                        />
                      </div>
                    );
                  }
                  return (
                    <Disclosure as="div" key={i} className="space-y-1">
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            disabled={disabled}
                            className={classNames(
                              disabled
                                ? "text-gray-400"
                                : current
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                              "w-full group flex justify-center items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <Icon
                              className={classNames(
                                current
                                  ? "text-gray-900"
                                  : "text-gray-600 group-hover:text-gray-500",
                                "flex-shrink-0 h-6 w-6",
                                desktopMenuCollapsed ? "mr-0" : "mr-3"
                              )}
                              aria-hidden="true"
                            />
                            {desktopMenuCollapsed ? null : name}
                            {desktopMenuCollapsed ? null : (
                              <ChevronDownIcon
                                className={classNames(
                                  disabled
                                    ? "text-gray-400"
                                    : open
                                    ? "text-gray-900"
                                    : "text-gray-600 -rotate-90",
                                  "ml-auto h-5 w-5 group-hover:text-gray-500"
                                )}
                                aria-hidden="true"
                              />
                            )}
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 space-y-1">
                            {children.map(
                              ({ to: childTo, name: childName }, i) => (
                                <Link
                                  key={i}
                                  to={childTo}
                                  className={classNames(
                                    matchPath(childTo, location.pathname)
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                    "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                  )}
                                >
                                  {childName}
                                </Link>
                              )
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  );
                }
                if (desktopMenuCollapsed) {
                  return (
                    <div className="flex justify-center" key={i}>
                      <Tooltip
                        content={<>{name}</>}
                        trigger={
                          <DisableableLink
                            to={to}
                            disabled={disabled}
                            className={classNames(
                              disabled
                                ? "text-gray-400"
                                : current
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                              "group flex justify-center items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <Icon
                              className={classNames(
                                disabled
                                  ? "text-gray-400"
                                  : current
                                  ? "text-gray-900"
                                  : "text-gray-600 group-hover:text-gray-500",
                                "flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                          </DisableableLink>
                        }
                      />
                    </div>
                  );
                }
                return (
                  <DisableableLink
                    key={i}
                    to={to}
                    disabled={disabled}
                    className={classNames(
                      disabled
                        ? "text-gray-400"
                        : current
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex justify-between items-center w-full px-2 py-2 text-base font-medium rounded-md"
                    )}
                  >
                    <div className="flex items-center">
                      <Icon
                        className={classNames(
                          disabled
                            ? "text-gray-400"
                            : current
                            ? "text-gray-900"
                            : "text-gray-600 group-hover:text-gray-500",
                          "flex-shrink-0 h-6 w-6 mr-4"
                        )}
                        aria-hidden="true"
                      />
                      {name}
                    </div>
                    {isDefined(count) ? (
                      <span
                        className={classNames(
                          count > 0
                            ? "bg-red-100 text-red-600"
                            : "bg-gray-100 text-gray-900",
                          "ml-3 rounded-full py-0.5 px-2.5 text-xs font-medium"
                        )}
                      >
                        {count}
                      </span>
                    ) : null}
                  </DisableableLink>
                );
              }
            )}
          </nav>
        </div>
        <div className="flex justify-end items-center border-t border-gray-300">
          <Tooltip
            content={
              <>
                {desktopMenuCollapsed ? "Expand Sidebar" : "Collapse Sidebar"}
              </>
            }
            trigger={
              <button
                onClick={() => {
                  setDesktopMenuCollapsed(!desktopMenuCollapsed);
                }}
                className={classNames(
                  "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                  desktopMenuCollapsed ? "justify-center" : "",
                  "group flex items-center px-2 py-2 text-base font-medium rounded-md gap-2"
                )}
              >
                <ChevronDoubleRightIcon
                  className={classNames(
                    "w-6 h-6",
                    desktopMenuCollapsed ? "" : "rotate rotate-180"
                  )}
                />
              </button>
            }
          />
        </div>
      </div>
    </div>
  );
};
