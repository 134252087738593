import { gql, useMutation } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { toast } from "react-toastify";
import { Badge } from "../../components";
import { CardComponent } from "../../components/card-icons";
import { OvalSpinner } from "../../components/loading";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { CurrencyInput } from "../../components/ui/input";
import { classNames, cn, isDefined, toCents } from "../../utils";
import { CreateEstimateAppointment } from "../appointments";
import { paymentMethodDisplay } from "../patients/payment-method-display";
import { VisitDisplayCardAppointment } from "./visit-bill-display-card";
import { VISIT_COLLECTION_REQUEST_FIELDS } from "../../graphql";

const COLLECT_PRE_VISIT_DEPOSIT = gql`
  ${VISIT_COLLECTION_REQUEST_FIELDS}
  mutation CollectPreVisitDeposit(
    $appointmentId: String!
    $amount: Int!
    $paymentMethodId: String!
  ) {
    collectPreVisitDeposit(
      appointmentId: $appointmentId
      amount: $amount
      paymentMethodId: $paymentMethodId
    ) {
      ...VisitCollectionRequestFields
    }
  }
`;

export const CollectDepositButton: React.FC<{
  appointment: VisitDisplayCardAppointment;
}> = ({ appointment }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [collectPreVisitDeposit, result] = useMutation(
    COLLECT_PRE_VISIT_DEPOSIT
  );

  const parsedAmount = toCents(parseFloat(amount));

  const handleCollectDeposit = async () => {
    try {
      await collectPreVisitDeposit({
        variables: {
          appointmentId: appointment.id,
          amount: parsedAmount,
          paymentMethodId: selectedPaymentMethod,
        },
      });
      setIsOpen(false);
      toast.success("Deposit collected successfully");
    } catch (error) {
      toast.error("Error collecting deposit");
    }
  };

  const paymentMethods = appointment.account.patient.paymentMethods;

  const isValid =
    isDefined(amount) &&
    !Number.isNaN(parseFloat(amount)) &&
    parsedAmount >= 100 &&
    !!selectedPaymentMethod;

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Collect Deposit</Button>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Collect Pre-Visit Deposit</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <CurrencyInput
              decimalsLimit={2}
              onValueChange={(value) => setAmount(value || "")}
              value={amount}
              placeholder="Deposit Amount"
              min={1}
            />
            <RadioGroup
              value={selectedPaymentMethod}
              onChange={setSelectedPaymentMethod}
            >
              <RadioGroup.Label className="sr-only">
                Payment Method
              </RadioGroup.Label>
              <div className="space-y-2">
                {paymentMethods.map((paymentMethod) => (
                  <RadioGroup.Option
                    key={paymentMethod.id}
                    value={paymentMethod.id}
                    className={({ active, checked }) =>
                      cn(
                        checked ? "border-transparent" : "border-gray-300",
                        active
                          ? "border-indigo-500 ring-2 ring-indigo-500"
                          : "",
                        "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <div className="flex flex-col">
                              <div className="font-medium">
                                <div className="flex items-center gap-1">
                                  <CardComponent
                                    cardBrand={paymentMethod.cardBrand}
                                    type={paymentMethod.type}
                                    className="h-4"
                                  />
                                  <span>
                                    {paymentMethodDisplay(paymentMethod)}
                                  </span>
                                  {paymentMethod.default && (
                                    <div>
                                      <Badge variant="info" text="Default" />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {paymentMethod.type !== "link" && (
                                <div className="text-gray-600">
                                  Expires {paymentMethod.expirationMonth}/
                                  {paymentMethod.expirationYear}
                                </div>
                              )}
                            </div>
                          </span>
                        </span>
                        <div>
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? "invisible" : "",
                              "h-5 w-5 text-indigo-600"
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? "border" : "border-2",
                              checked
                                ? "border-indigo-500"
                                : "border-transparent",
                              "pointer-events-none absolute -inset-px rounded-lg"
                            )}
                            aria-hidden="true"
                          />
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          <DialogFooter>
            <Button disabled={!isValid} onClick={handleCollectDeposit}>
              {result.loading ? <OvalSpinner /> : <>Collect Deposit</>}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
