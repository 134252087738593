import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  Table as ReactTable,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";

import { DataTablePagination } from "../../../components/ui/table-helpers/data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
import { cn } from "../../../utils";
import { HorizontalPadding } from "../../layout";
import { RuleExplorerRow } from "./columns";
import { OvalSpinner } from "../../../components/loading";
import { useDashboardResizable } from "../../../dashboard-resizable";

interface DataTableProps<TValue> {
  columns: ColumnDef<RuleExplorerRow, TValue>[];
  defaultColumnFilters?: ColumnFiltersState;
  data: RuleExplorerRow[];
}

export function DataTable<TValue>({
  columns,
  data,
  defaultColumnFilters,
  children,
  loading,
  loadingMore,
}: DataTableProps<TValue> & {
  children?: (table: ReactTable<RuleExplorerRow>) => React.ReactElement;
  loading?: boolean;
  loadingMore?: boolean;
}) {
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    defaultColumnFilters ?? []
  );
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "start",
      desc: false,
    },
  ]);

  const statusFilter = columnFilters.find((filter) => filter.id === "status");
  const completeFilterActive =
    Array.isArray(statusFilter?.value) &&
    statusFilter.value.every((v) => v === "Complete");

  React.useEffect(() => {
    setColumnVisibility((columnVisibility) => ({
      ...columnVisibility,
      lastEstimationError: !completeFilterActive,
    }));
  }, [completeFilterActive]);

  const { setPanelChild } = useDashboardResizable();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    autoResetPageIndex: false,
  });

  return (
    <>
      {children && children(table)}
      <div className="space-y-4">
        <HorizontalPadding>
          <div className="w-full">
            <DataTableToolbar table={table} />
          </div>
        </HorizontalPadding>
        <Table className="max-h-[80vh] border bg-white">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className="sticky top-0 z-10 bg-white"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell, i) => (
                    <TableCell
                      key={cell.id}
                      className={cn(
                        "whitespace-nowrap"
                        // CSS to make the last column sticky
                        // "last:sticky last:right-0  last:bg-white last:drop-shadow"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : loading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <div className="w-full flex justify-center">
                    <OvalSpinner />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {/* <HorizontalPadding>
          <div className="w-full">
            <DataTablePagination
              table={table}
              loading={loading || loadingMore}
            />
          </div>
        </HorizontalPadding> */}
    </>
  );
}
