import React, { Fragment, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { useUser } from "../../user-context";
import { Table, Td } from "../../components/Table";
import { GetCommunications_communications as Communication } from "../../generated/GetCommunications";
import { format, parseISO } from "date-fns";
import { capitalize, classNames } from "../../utils";
import { billCommunicationTypeDisplay } from "../patients/activity-feed";
import { Tooltip } from "../../components";
import { Listbox, Transition } from "@headlessui/react";
import { Layout } from "../layout";

const GET_COMMUNICATIONS = gql`
  query GetCommunications(
    $where: CommunicationWhereInput!
    $skip: Int
    $take: Int
  ) {
    communications(
      where: $where
      skip: $skip
      take: $take
      orderBy: [{ createdAt: desc }]
    ) {
      id
      createdAt
      sentAt
      type
      contentType
      handle
      lastErrorType
      lastErrorReasonDisplay
      patient {
        id
        displayName
      }
    }
    aggregateCommunication(where: $where) {
      _count {
        id
      }
    }
  }
`;

const PAGE_SIZE = 15;

export const MessagesList: React.FC<
  React.PropsWithChildren<{ messageStatus: MessageStatus }>
> = ({ messageStatus }) => {
  const user = useUser()!;

  const [currentPage, setPageNum] = useState(1);
  const changePageNum = (num: number) => {
    setPageNum(num);
  };

  const skip = (currentPage - 1) * PAGE_SIZE;

  const { loading, error, data } = useQuery(GET_COMMUNICATIONS, {
    variables: {
      where: {
        ...(messageStatus === "All"
          ? {}
          : messageStatus === "Delivered"
          ? { lastErrorReason: { equals: null } }
          : { lastErrorReason: { not: null } }),
        patient: {
          is: {
            organizationId: { equals: user.organization.id },
            locationId: { equals: user.activeLocation.id },
            deletedAt: { equals: null },
          },
        },
      },
      skip,
      take: PAGE_SIZE,
    },
  });

  const communications = data?.communications ?? [];
  const countResult = data?.aggregateCommunication?._count?.id ?? 0;

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <Table
            loading={loading}
            columnDefs={[
              {
                header: "Sent At",
                cellFn: (communication: Communication) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {format(
                        parseISO(
                          communication.sentAt ?? communication.createdAt
                        ),
                        "MMM d, yyyy h:mm a"
                      )}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Patient",
                cellFn: (communication: Communication) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {communication.patient && (
                        <Link
                          to={`/patients/${communication.patient.id}`}
                          className="text-sm text-gray-900 hover:text-gray-500"
                        >
                          {communication.patient.displayName}
                        </Link>
                      )}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Type",
                cellFn: (communication: Communication) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {billCommunicationTypeDisplay(communication.contentType)}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Channel",
                cellFn: (communication: Communication) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {capitalize(communication.type.toLowerCase())}
                    </div>
                  </Td>
                ),
              },
              {
                header: "To",
                cellFn: (communication: Communication) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {communication.handle}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Status",
                cellFn: (communication: Communication) => {
                  const failed = communication.lastErrorReasonDisplay !== null;
                  return (
                    <Td>
                      <div
                        className={classNames(
                          "text-sm",
                          failed ? "text-red-700" : "text-green-700"
                        )}
                      >
                        {failed ? "Failed" : "Delivered"}
                      </div>
                    </Td>
                  );
                },
              },
              {
                header: "Error",
                cellFn: (communication: Communication) => (
                  <Td>
                    <div className="text-sm text-gray-900 truncate max-w-md">
                      <Tooltip
                        content={
                          <div className="max-w-md">
                            {communication.lastErrorReasonDisplay}
                          </div>
                        }
                        trigger={
                          <div className="max-w-md truncate">
                            {communication.lastErrorReasonDisplay}
                          </div>
                        }
                      />
                    </div>
                  </Td>
                ),
              },
            ]}
            rows={communications}
            pagination={{
              currentPage: currentPage,
              totalCount: countResult,
              pageSize: PAGE_SIZE,
              onPageChange: changePageNum,
              siblingCount: 1,
            }}
          />
        </div>
      </div>
    </div>
  );
};

type MessageStatus = "All" | "Delivered" | "Failed";

export const Messages: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [status, setStatus] = useState<MessageStatus>("All");

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">Messages</h1>
          <div>
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700"
            >
              Filter by Status
            </label>
            <Listbox value={status} onChange={setStatus}>
              {({ open }) => (
                <div className="relative">
                  <Listbox.Button className="relative w-full min-w-[12em] h-10 cursor-default rounded-md border border-gray-300 bg-white pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    {status}
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value="All"
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              All
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value="Delivered"
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              Delivered
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value="Failed"
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              Failed
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    </Listbox.Options>
                  </Transition>
                </div>
              )}
            </Listbox>
          </div>
        </div>
      }
      content={
        <div className="py-4">
          <MessagesList messageStatus={status} />
        </div>
      }
    />
  );
};
