import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../utils";

export interface OTPOption {
  id: string;
  method: string;
  display: string;
}

export const OTPSelection: React.FC<React.PropsWithChildren<{
  otpOptions: OTPOption[];
  onSelect: (option: OTPOption) => void;
}>> = ({ otpOptions, onSelect }) => {
  const initialSelection = otpOptions[0];
  const [selected, setSelected] = useState(initialSelection);

  useEffect(() => {
    // Default the selection to the first otpOption
    onSelect(initialSelection);
  }, []);

  const onChange = (otpOption: OTPOption) => {
    setSelected(otpOption);
    onSelect(otpOption);
  };

  return (
    <RadioGroup value={selected} onChange={onChange}>
      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
      <div className="space-y-4">
        {otpOptions.map((option) => (
          <RadioGroup.Option
            key={option.id}
            value={option}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-gray-300",
                active ? "border-indigo-500 ring-2 ring-indigo-500" : "",
                "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className="font-medium text-gray-900"
                    >
                      {option.display}
                    </RadioGroup.Label>
                  </div>
                </div>
                <RadioGroup.Description
                  as="div"
                  className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right"
                >
                  <div className="ml-1 text-gray-500 sm:ml-0">
                    {option.method}
                  </div>
                </RadioGroup.Description>
                <div
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-indigo-500" : "border-transparent",
                    "absolute -inset-px rounded-lg pointer-events-none"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
