import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import {
  LoginPatientUser,
  LoginPatientUserVariables,
} from "../../generated/LoginPatientUser";
import {
  GetPublicPatientData,
  GetPublicPatientDataVariables,
} from "../../generated/GetPublicPatientData";
import { NotFound } from "../../components/404";
import { constants } from "../../constants";
import { DateInput } from "../../components";

const LOGIN_PATIENT_USER = gql`
  mutation LoginPatientUser(
    $patientId: String!
    $lastName: String!
    $dateOfBirth: DateTime!
  ) {
    loginPatientUser(
      patientId: $patientId
      lastName: $lastName
      dateOfBirth: $dateOfBirth
    ) {
      id
    }
  }
`;

const GET_PUBLIC_PATIENT = gql`
  query GetPublicPatientData($insurancePolicyId: String!) {
    getPublicPatientData(insurancePolicyId: $insurancePolicyId) {
      id
      firstName
      organizationLogoUrl
    }
  }
`;

export const PatientLogin: React.FC<
  React.PropsWithChildren<{ login: () => void }>
> = ({ login }) => {
  const params = useParams<{ insurancePolicyId: string }>();
  const insurancePolicyId = params.insurancePolicyId!;
  const { loading, error, data } = useQuery<
    GetPublicPatientData,
    GetPublicPatientDataVariables
  >(GET_PUBLIC_PATIENT, {
    variables: {
      insurancePolicyId,
    },
  });
  const [failure, setFailure] = useState(false);
  const [loginPatient, result] = useMutation<
    LoginPatientUser,
    LoginPatientUserVariables
  >(LOGIN_PATIENT_USER);
  const methods = useForm();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  if (loading) return <></>;

  if (data?.getPublicPatientData === null) return <NotFound />;

  const onSubmit = async (form: any) => {
    await loginPatient({
      variables: {
        patientId: data!.getPublicPatientData!.id,
        lastName: form.lastName,
        dateOfBirth: form.dateOfBirth,
      },
      onCompleted: (data) => {
        if (data.loginPatientUser) {
          toast.success("Logged in");
          login();
        } else {
          setFailure(true);
        }
      },
      onError: (error) => {
        toast.error("Something went wrong.");
      },
    });
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-36 w-auto"
            // TODO: handle empty logo url
            src={data?.getPublicPatientData?.organizationLogoUrl!}
            alt="Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Hi, {data?.getPublicPatientData?.firstName}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please verify your identity to view your insurance coverage
            information
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <FormProvider {...methods}>
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="lastName"
                      type="text"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      {...register("lastName")}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="dateOfBirth"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date Of Birth
                  </label>
                  <div className="mt-1">
                    <DateInput name="dateOfBirth" required control={control} />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Verify
                  </button>
                </div>
                {failure && (
                  <div>
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      No match found.
                    </p>
                  </div>
                )}
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};
