import React from "react";
import { gql, useApolloClient, useQuery } from "@apollo/client";

import { Rings } from "../components/loading";
import { GetLoggedInPortalUser } from "../generated/GetLoggedInPortalUser";
import { constants } from "../constants";

export type PortalUser = {
  __typename: "PatientUser" | "User";
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
};

export interface Auth {
  login: () => void;
  logout: (redirectUrl: string) => void;
  data: { user: PortalUser | null };
}

const PatientAuthContext = React.createContext<Auth>({
  login: () => {},
  logout: (redirectUrl: string) => {},
  data: { user: null },
});

const GET_LOGGED_IN_PORTAL_USER = gql`
  query GetLoggedInPortalUser {
    getLoggedInPortalUser {
      ... on PatientUser {
        __typename
        id
        patientUserEmail: email
      }
      ... on User {
        __typename
        id
        firstName
        lastName
        userEmail: email
      }
    }
  }
`;

const PatientAuthProvider: React.FC<React.PropsWithChildren<any>> = (props) => {
  const client = useApolloClient();
  const { loading, error, data } = useQuery<GetLoggedInPortalUser>(
    GET_LOGGED_IN_PORTAL_USER
  );
  if (loading)
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );

  const portalUser = data?.getLoggedInPortalUser;

  const normalizedUser = portalUser
    ? {
        ...portalUser,
        email:
          "patientUserEmail" in portalUser
            ? portalUser.patientUserEmail
            : portalUser.userEmail,
      }
    : portalUser;

  return (
    <PatientAuthContext.Provider
      value={{
        data: { user: normalizedUser },
        // Retry getting the user from an active session
        login: () => {
          client.refetchQueries({ include: [GET_LOGGED_IN_PORTAL_USER] });
        },
        logout: (coverageId: string) => {
          const redirectUrl = `/portal/benefits/${coverageId}`;
          window.location.href = `${constants.VITE_API_URL}/logout?redirectUrl=${redirectUrl}`;
        },
      }}
      {...props}
    />
  );
};

const usePatientAuth = () => React.useContext(PatientAuthContext);

export { PatientAuthProvider, usePatientAuth };
