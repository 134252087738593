import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";

import { GetMe_me_organization_planTypes as PlanType } from "../../generated/GetMe";
import { InsuranceType } from "../../generated/globalTypes";
import { cn } from "../../utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

// Helper function to group and sort plan types
const groupPlanTypes = (planTypes: PlanType[]) => {
  const groups = planTypes.reduce((acc, planType) => {
    const group = acc.get(planType.insuranceType) || [];
    group.push(planType);
    acc.set(planType.insuranceType, group);
    return acc;
  }, new Map<InsuranceType, PlanType[]>());

  // Sort each group alphabetically by name
  groups.forEach((group) => {
    group.sort((a, b) => a.name.localeCompare(b.name));
  });

  // Define display order and labels for insurance types
  const groupOrder = [
    {
      type: InsuranceType.COMMERCIAL,
      label: "Commercial Plans",
    },
    {
      type: InsuranceType.MEDICARE,
      label: "Medicare Plans",
    },
    {
      type: InsuranceType.MEDIGAP,
      label: "Medicare Supplement Plans",
    },
    {
      type: InsuranceType.MEDICAID,
      label: "Medicaid Plans",
    },
    {
      type: InsuranceType.TRICARE,
      label: "Tricare Plans",
    },
    {
      type: InsuranceType.CHAMPVA,
      label: "CHAMPVA Plans",
    },
    {
      type: InsuranceType.WORKERS_COMP,
      label: "Workers Comp Plans",
    },
    {
      type: InsuranceType.AUTO,
      label: "Auto Plans",
    },
    {
      type: InsuranceType.OTHER,
      label: "Other Plans",
    },
  ];

  return groupOrder
    .map(({ type, label }) => ({
      label,
      plans: groups.get(type) || [],
    }))
    .filter((group) => group.plans.length > 0);
};

interface PlanTypeComboboxProps {
  insurancePolicy: {
    id: string;
    planType: {
      id: string;
      name: string;
    } | null;
  };
  onClear?: () => void;
  planTypes: PlanType[];
  onSelect: (planTypeId: string) => void;
}

export function PlanTypeCombobox({
  insurancePolicy,
  onClear,
  planTypes,
  onSelect,
}: PlanTypeComboboxProps) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(insurancePolicy.planType?.id ?? "");
  const [search, setSearch] = React.useState("");

  const filteredPlanTypes = planTypes.filter((pt) =>
    pt.name.toLowerCase().includes(search.toLowerCase())
  );
  const groupedPlanTypes = groupPlanTypes(filteredPlanTypes);
  const selectedPlanType = filteredPlanTypes.find((pt) => pt.id === value);

  const handleSelect = async (newValue: string) => {
    setValue(newValue);
    setOpen(false);
    onSelect(newValue);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between text-xs h-full"
        >
          {selectedPlanType ? selectedPlanType.name : "Select plan type..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] max-h-[300px] overflow-y-auto p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search plan types..."
            value={search}
            onValueChange={(newSearch) => {
              setSearch(newSearch);
            }}
            className="h-9"
          />
          <CommandEmpty>No plan type found.</CommandEmpty>
          {groupedPlanTypes.map((group) => (
            <CommandGroup key={group.label} heading={group.label}>
              {group.plans.map((planType) => (
                <CommandItem
                  key={planType.id}
                  value={planType.id}
                  onSelect={handleSelect}
                >
                  {planType.name}
                  <Check
                    className={cn(
                      "ml-auto h-4 w-4",
                      value === planType.id ? "opacity-100" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          ))}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
