import React from "react";
import { Layout } from "../../layout";
import { BenefitMappingForm } from "./benefit-mapping-form";
import { BenefitAssignmentRuleSimulatorTable } from "./show";
import { Condition, Visit } from "../../../evaluate-rule";
import { GetBenefitMapping_benefitMapping as BenefitMapping } from "../../../generated/GetBenefitMapping";
import { useLocation } from "react-router-dom";

export const NewBenefitAssignmentRule: React.FC = () => {
  const location = useLocation();
  const state: {
    benefitMapping?: BenefitMapping | null;
  } | null = location.state;
  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Create New Rule
          </h1>
        </div>
      }
      content={
        <BenefitMappingForm benefitMapping={state?.benefitMapping ?? null}>
          {({ conditions }) => (
            <div>
              <BenefitAssignmentRuleSimulatorTable
                conditions={conditions as Condition<keyof Visit>[]}
              />
            </div>
          )}
        </BenefitMappingForm>
      }
    />
  );
};
