import React, { useState, useEffect } from "react";
import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";
import * as Sentry from "@sentry/react";

import { constants } from "./constants";
import { Rings } from "./components/loading";

const AnalyticsContext = React.createContext<Analytics | null>(null);

export const AnalyticsProvider: React.FC<React.PropsWithChildren<unknown>> = (
  props
) => {
  const [analyticsLoaded, setAnalyticsLoaded] = useState<boolean>(false);
  const [analytics, setAnalytics] = useState<Analytics | null>(null);

  useEffect(() => {
    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({
        writeKey: constants.VITE_SEGMENT_KEY!,
      });
      setAnalytics(response);
      setAnalyticsLoaded(true);
    };
    // Don't load the segment client if VITE_SEGMENT_KEY is "", used to
    // turn off analytics tracking in dev
    if (constants.VITE_SEGMENT_KEY === "") {
      setAnalytics(null);
      setAnalyticsLoaded(true);
    } else {
      loadAnalytics().catch((e) => {
        Sentry.captureMessage("Segment client failed to load");
        console.warn("Segment client failed to load");
        setAnalytics(null);
        setAnalyticsLoaded(true);
      });
    }
  }, []);
  if (!analyticsLoaded) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );
  }
  return <AnalyticsContext.Provider value={analytics} {...props} />;
};

export const useAnalytics = () => React.useContext(AnalyticsContext);
