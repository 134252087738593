import { Cross2Icon, DotsVerticalIcon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { gql, useQuery } from "@apollo/client";

import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { DataTableViewOptions } from "../../components/ui/table-helpers/data-table-view-options";
import { DataTableFacetedFilter } from "../../components/ui/table-helpers/data-table-faceted-filter";

import { GetPayers, GetPayersVariables } from "../../generated/GetPayers";
import { useUser } from "../../user-context";
import { GET_PAYERS } from "../payers";
import { SkipForward } from "lucide-react";
import { VerificationWorkflowReason } from "../../generated/globalTypes";
import { BulkWorklistItemActionMenu } from "./list";
import { useDashboardResizable } from "../../dashboard-resizable";
import { EstimationSidePanel } from "./side-panel";
import { EstimateSyncStatus, EstimationWorklistRow } from "./columns";
import { GET_ACCOUNT_TYPES } from "../billing";
import {
  GetAccountTypes,
  GetAccountTypesVariables,
} from "../../generated/GetAccountTypes";
import { useAnalytics } from "../../analytics-context";

interface DataTableToolbarProps<TData extends { id: string }> {
  table: Table<TData>;
}

const REASON_OPTIONS: {
  value: string;
  label: string;
}[] = [
  {
    value: VerificationWorkflowReason.NewPolicy,
    label: "New Policy",
  },
  {
    value: VerificationWorkflowReason.Reverification,
    label: "Reverification",
  },
];

const SYNC_STATUS_OPTIONS: {
  value: string;
  label: EstimateSyncStatus;
}[] = [
  {
    value: "Synced",
    label: "Synced",
  },
  {
    value: "Previously Synced",
    label: "Previously Synced",
  },
  {
    value: "Not Synced",
    label: "Not Synced",
  },
  {
    value: "Unable to Sync",
    label: "Unable to Sync",
  },
];

const ESTIMATION_WORKLIST_FILTER_OPTIONS = gql`
  query EstimationWorklistFilterOptions($locationId: String!) {
    payers(where: { locationId: { equals: $locationId } }) {
      id
      name
    }
    accountTypes(
      where: { locationId: { equals: $locationId } }
      orderBy: [{ name: asc }]
    ) {
      id
      name
    }
    providers(
      where: { primaryLocationId: { equals: $locationId } }
      orderBy: [{ firstName: { sort: asc, nulls: last } }]
    ) {
      id
      displayName
    }
    appointmentLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: [{ name: asc }]
    ) {
      id
      name
    }
  }
`;

export function DataTableToolbar({
  table,
}: DataTableToolbarProps<EstimationWorklistRow>) {
  const user = useUser()!;
  const analytics = useAnalytics();
  const { setPanelChild } = useDashboardResizable();
  const integrations = user.activeLocation.integrations;
  const integrationsSupportingSync = integrations.filter(
    (integration) => integration.supportsOnDemandSync
  );
  // const { openInsurancePolicyWorklistSplitPane } = useSplitPane();
  const payersResult = useQuery<GetPayers, GetPayersVariables>(GET_PAYERS, {
    variables: {
      organizationId: user.organization.id,
      locationId: user.activeLocation.id,
    },
  });
  const accountTypesResult = useQuery<
    GetAccountTypes,
    GetAccountTypesVariables
  >(GET_ACCOUNT_TYPES, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });
  const filterOptionsResult = useQuery(ESTIMATION_WORKLIST_FILTER_OPTIONS, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });

  const statusOptions = user.activeLocation.estimationWorkflowStatuses.map(
    (s) => ({ value: s.name, label: s.name })
  );

  const payerOptions = Array.from(
    new Set(payersResult.data?.payers.map((p) => p.name) ?? [])
  ).map((payer) => ({ value: payer, label: payer }));

  const accountTypeOptions = Array.from(
    new Set(accountTypesResult?.data?.accountTypes?.map((p) => p.name) ?? [])
  ).map((payer) => ({ value: payer, label: payer }));

  const providerOptions = Array.from(
    new Set(
      filterOptionsResult?.data?.providers?.map((p) => p.displayName) ?? []
    )
  ).map((provider) => ({ value: provider, label: provider }));

  const appointmentLabelOptions = Array.from(
    new Set(
      filterOptionsResult?.data?.appointmentLabels?.map((p) => p.name) ?? []
    )
  ).map((payer) => ({ value: payer, label: payer }));

  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-start justify-between gap-2">
      <div className="flex flex-1 items-center flex-wrap gap-2">
        <Input
          placeholder="Filter by Patient or Member ID"
          value={table.getState().globalFilter ?? ""}
          onChange={(event) => table.setGlobalFilter(event.target.value)}
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statusOptions}
          />
        )}
        {table.getColumn("patientName") && (
          <DataTableFacetedFilter
            column={table.getColumn("patientName")}
            title="Account"
            options={accountTypeOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("payerName") && (
          <DataTableFacetedFilter
            column={table.getColumn("payerName")}
            title="Payer"
            options={payerOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("providerName") && (
          <DataTableFacetedFilter
            column={table.getColumn("providerName")}
            title="Provider"
            options={providerOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("appointmentLabels") && (
          <DataTableFacetedFilter
            column={table.getColumn("appointmentLabels")}
            title="Appointment Label"
            options={appointmentLabelOptions}
            sortByCount={true}
            arrayValue={true}
          />
        )}
        {table.getColumn("syncStatus") && (
          <DataTableFacetedFilter
            column={table.getColumn("syncStatus")}
            title="Sync Status"
            options={SYNC_STATUS_OPTIONS}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <Button
        className="h-8"
        size="sm"
        disabled={table.getFilteredRowModel().rows.length === 0}
        onClick={() => {
          setPanelChild(
            <EstimationSidePanel
              ids={table
                .getFilteredRowModel()
                .rows.map((row) => row.original.id)}
            />
          );
          analytics?.track("Verification Worklist Batch Opened", {
            organizationId: user.organization.id,
            organizationName: user.organization.name,
            locationId: user.activeLocation.id,
            locationName: user.activeLocation.name,
            count: table.getFilteredRowModel().rows.length,
          });
          table.resetRowSelection();
        }}
        type="button"
      >
        Execute Tasks
        <SkipForward className="ml-2 h-4 w-4" />
      </Button>
      <BulkWorklistItemActionMenu
        worklistRows={table.getSortedRowModel().rows.map((row) => row.original)}
        trigger={
          <Button variant="outline" size="sm" className="h-8" type="button">
            <DotsVerticalIcon className="h-4 w-4" />
          </Button>
        }
        onClose={() => {
          table.resetRowSelection();
        }}
      />
      <DataTableViewOptions table={table} />
    </div>
  );
}
