import React, { Fragment, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { FormProvider, useForm } from "react-hook-form";

import { FeedbackType } from "../../generated/globalTypes";
import {
  submitPatientFeedback,
  submitPatientFeedbackVariables,
} from "../../generated/submitPatientFeedback";
import { classNames, isDefined } from "../../utils";
import { SubmitButton } from "../../components";

const SUBMIT_PATIENT_FEEDBACK = gql`
  mutation submitPatientFeedback(
    $rating: Int!
    $comment: String
    $feedbackType: FeedbackType!
    $patientId: String
  ) {
    submitPatientFeedback(
      rating: $rating
      comment: $comment
      feedbackType: $feedbackType
      patientId: $patientId
    ) {
      id
    }
  }
`;

const emojis = [
  { name: 1, Emoji: () => <div>&#128577;</div> },
  { name: 2, Emoji: () => <div>&#128533;</div> },
  { name: 3, Emoji: () => <div>&#128528;</div> },
  { name: 4, Emoji: () => <div>&#128578;</div> },
  { name: 5, Emoji: () => <div>&#128513;</div> },
];

export const FeedbackSurvey: React.FC<React.PropsWithChildren<{
  title: string;
  showSurvey: boolean;
  setShowSurvey: (show: boolean) => void;
  feedbackType: FeedbackType;
  patientId?: string;
}>> = ({ title, showSurvey, setShowSurvey, feedbackType, patientId }) => {
  const [submitPatientFeedback, result] = useMutation<
    submitPatientFeedback,
    submitPatientFeedbackVariables
  >(SUBMIT_PATIENT_FEEDBACK);
  const [selectedEmoji, setSelectedEmoji] = useState<number>();
  const [submitted, setSubmitted] = useState(false);
  const methods = useForm();
  const { handleSubmit, register } = methods;

  const onSubmit = async (data: any) => {
    await submitPatientFeedback({
      variables: {
        rating: selectedEmoji!,
        comment: data.comment,
        feedbackType,
        patientId,
      },
      onCompleted: () => {
        setSubmitted(true);
      },
      onError: () => {
        setShowSurvey(false);
      },
    });
  };

  return (
    <Transition.Root show={showSurvey} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShowSurvey}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                <Dialog.Panel>
                  <div
                    className={classNames("pt-2", submitted ? "" : "hidden")}
                  >
                    Thanks for submitting your feedback!
                    <div className="mt-2 flex justify-between">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-indigo-500"
                        onClick={() => setShowSurvey(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <FormProvider {...methods}>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className={submitted ? "hidden" : ""}
                    >
                      <div className="mt-2 px-2">
                        <RadioGroup
                          value={selectedEmoji}
                          onChange={setSelectedEmoji}
                        >
                          <div className="text-sm flex justify-between">
                            {emojis.map(({ name, Emoji }) => (
                              <RadioGroup.Option
                                key={name}
                                value={name}
                                className={({ active, checked }) =>
                                  classNames(
                                    active && checked ? "ring-[4px]" : "",
                                    !active && checked ? "ring-[4px]" : "",
                                    "relative rounded-full text-4xl hover:scale-125 h-9 w-9 flex items-center justify-center cursor-pointer focus:outline-none"
                                  )
                                }
                              >
                                <RadioGroup.Label as="p" className="sr-only">
                                  {name}
                                </RadioGroup.Label>
                                <button type="button">
                                  <Emoji />
                                </button>
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>

                      <div className="mt-2 p-1">
                        <textarea
                          rows={3}
                          className="block w-full py-2 px-1 rounded-md border-2 border-gray-200 resize-none focus:ring-0 sm:text-sm"
                          placeholder="Anything you'd like us to know?"
                          defaultValue={""}
                          {...register("comment")}
                        />
                      </div>

                      <div className="mt-2 flex justify-between">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-indigo-500"
                          onClick={() => setShowSurvey(false)}
                        >
                          Close
                        </button>
                        <div className="flex items-center">
                          <SubmitButton
                            type="submit"
                            disabled={
                              !isDefined(selectedEmoji) || result.loading
                            }
                            loading={result.loading}
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-indigo-500 disabled:bg-gray-200 disabled:cursor-not-allowed"
                          >
                            Submit Feedback
                          </SubmitButton>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
