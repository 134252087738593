import React, { useState } from "react";
import { format, parseISO } from "date-fns";
import { Switch } from "@headlessui/react";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { IntegrationStatus } from "../../components/IntegrationStatus";
import { classNames, toDate, isDefined } from "../../utils";
import { PatientHeaderFields as Patient } from "../../generated/PatientHeaderFields";
import {
  PauseReminderWorkflow,
  PauseReminderWorkflowVariables,
} from "../../generated/PauseReminderWorkflow";
import {
  ResumeReminderWorkflow,
  ResumeReminderWorkflowVariables,
} from "../../generated/ResumeReminderWorkflow";
import {
  StartReminderWorkflow,
  StartReminderWorkflowVariables,
} from "../../generated/StartReminderWorkflow";
import { OvalSpinner } from "../../components/loading";
import { useUser } from "../../user-context";

export const PAUSE_REMINDER_WORKFLOW = gql`
  mutation PauseReminderWorkflow($patientId: String!) {
    pauseReminderWorkflow(patientId: $patientId) {
      reminderWorkflow {
        id
        pausedAt
        startedAt
        nextReminderDate
      }
      errors {
        message
      }
    }
  }
`;

export const RESUME_REMINDER_WORKFLOW = gql`
  mutation ResumeReminderWorkflow($patientId: String!) {
    resumeReminderWorkflow(patientId: $patientId) {
      reminderWorkflow {
        id
        pausedAt
        startedAt
        nextReminderDate
      }
      errors {
        message
      }
    }
  }
`;

export const START_REMINDER_WORKFLOW = gql`
  mutation StartReminderWorkflow($patientId: String!) {
    startReminderWorkflow(patientId: $patientId) {
      reminderWorkflow {
        id
        pausedAt
        startedAt
        nextReminderDate
      }
      errors {
        message
      }
    }
  }
`;

export const ReminderSummary: React.FC<React.PropsWithChildren<{ patient: Patient }>> = ({
  patient,
}) => {
  const reminderWorkflowId = patient.reminderWorkflow?.id;
  const reminderWorkflowOn =
    isDefined(patient.reminderWorkflow) &&
    !isDefined(patient.reminderWorkflow?.pausedAt);
  const [enabled, setEnabled] = useState(reminderWorkflowOn);

  const [pauseReminderWorkflow, pauseReminderWorkflowResult] = useMutation<
    PauseReminderWorkflow,
    PauseReminderWorkflowVariables
  >(PAUSE_REMINDER_WORKFLOW, {
    onCompleted: (data) => {
      if (data.pauseReminderWorkflow.errors.length > 0) {
        toast.error(data.pauseReminderWorkflow.errors[0].message);
        setEnabled(
          data.pauseReminderWorkflow.reminderWorkflow?.pausedAt === null ?? true
        );
      } else {
        toast.success("Paused reminders");
      }
    },
    onError: (error) => {
      toast.error("Failed to pause reminders");
      setEnabled(true);
    },
  });

  const [resumeReminderWorkflow, resumeReminderWorkflowResult] = useMutation<
    ResumeReminderWorkflow,
    ResumeReminderWorkflowVariables
  >(RESUME_REMINDER_WORKFLOW, {
    onCompleted: (data) => {
      if (data.resumeReminderWorkflow.errors.length > 0) {
        toast.error(data.resumeReminderWorkflow.errors[0].message);
        setEnabled(
          data.resumeReminderWorkflow.reminderWorkflow?.pausedAt === null ??
            false
        );
      } else {
        toast.success("Started reminders");
      }
    },
    onError: (error) => {
      toast.error("Failed to start reminders");
      setEnabled(false);
    },
  });

  const [startReminderWorkflow, startReminderWorkflowResult] = useMutation<
    StartReminderWorkflow,
    StartReminderWorkflowVariables
  >(START_REMINDER_WORKFLOW, {
    onCompleted: (data) => {
      if (data.startReminderWorkflow.errors.length > 0) {
        toast.error(data.startReminderWorkflow.errors[0].message);
        setEnabled(
          data.startReminderWorkflow.reminderWorkflow?.pausedAt === null ??
            false
        );
      } else {
        toast.success("Started reminders");
      }
    },
    onError: (error) => {
      toast.error("Failed to start reminders");
      setEnabled(false);
    },
  });

  const toggleReminders = React.useCallback(
    async (toggle: boolean) => {
      setEnabled(toggle);
      if (toggle) {
        if (reminderWorkflowId) {
          await resumeReminderWorkflow({
            variables: { patientId: patient.id },
          });
        } else {
          await startReminderWorkflow({ variables: { patientId: patient.id } });
        }
      } else {
        await pauseReminderWorkflow({ variables: { patientId: patient.id } });
      }
    },
    [patient.id, reminderWorkflowOn]
  );

  const remindersLoading =
    pauseReminderWorkflowResult.loading ||
    resumeReminderWorkflowResult.loading ||
    startReminderWorkflowResult.loading;
  return (
    <div className="flex items-center space-x-2">
      <span className="text-sm font-semibold text-gray-500">
        Bill Reminders
      </span>{" "}
      <Switch
        checked={enabled}
        onChange={toggleReminders}
        disabled={remindersLoading}
        className={classNames(
          enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        )}
      >
        <span className="sr-only">Enable reminders</span>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
      {remindersLoading ? (
        <OvalSpinner className="w-4 h-4" />
      ) : reminderWorkflowOn ? (
        isDefined(patient.reminderWorkflow?.nextReminderDate) ? (
          <span className="text-sm text-gray-900">
            Next reminder scheduled on{" "}
            {format(
              parseISO(toDate(patient.reminderWorkflow?.nextReminderDate!)),
              "MMMM do, yyyy"
            )}
          </span>
        ) : (
          <span className="text-sm text-gray-900">
            Reminders will be scheduled when bills are sent
          </span>
        )
      ) : (
        patient.reminderWorkflow && (
          <span className="text-sm text-gray-900">
            Reminders paused on{" "}
            {format(
              parseISO(patient.reminderWorkflow!.pausedAt!),
              "MMMM do, yyyy"
            )}
            {patient.reminderWorkflow?.lastPauseReason &&
              ` automatically due to ${patient.reminderWorkflow.lastPauseReason}`}
          </span>
        )
      )}
    </div>
  );
};

export const PatientHeader: React.FC<React.PropsWithChildren<{
  patient: Patient;
  endContent: React.ReactNode;
}>> = ({ patient, endContent }) => {
  const user = useUser();
  return (
    <div className="flex flex-col w-full print:flex-row print:justify-between">
      <div className="flex justify-between items-end">
        <div className="flex space-x-1 items-center">
          <h1 className="text-2xl font-semibold text-gray-900">
            {patient.displayName}
          </h1>

          {patient.integrationLinks.length > 0 && (
            <IntegrationStatus
              patientId={patient.id}
              integrationLinks={patient.integrationLinks}
            />
          )}
        </div>
        {endContent}
      </div>
      <div className="flex space-x-4 items-center pt-1">
        <div className="flex items-center space-x-1">
          <span className="text-sm font-semibold text-gray-500">DOB</span>{" "}
          <span className="text-sm text-gray-900">
            {patient.dateOfBirth
              ? format(parseISO(toDate(patient.dateOfBirth)), "MM/dd/yyyy")
              : "N/A"}
          </span>
        </div>
        <div className="flex items-center space-x-1">
          <span className="text-sm font-semibold text-gray-500">Sex</span>{" "}
          <span className="text-sm text-gray-900">
            {patient.birthSex ?? "N/A"}
          </span>
        </div>
      </div>
    </div>
  );
};
