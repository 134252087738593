import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "../../../components/ui/table-helpers/data-table-column-header";
import {
  GetAppointmentsMatchingTemplates_appointmentsMatchingTemplate as Appointment,
  GetAppointmentsMatchingTemplates_appointmentsMatchingTemplate_bill_charges as Charge,
} from "../../../generated/GetAppointmentsMatchingTemplates";
import { format, formatDistanceToNow } from "date-fns";
import { cn } from "../../../utils";
import { ChargemasterBadge } from "../../shared/visit-bill-display-card";
import { Badge } from "../../../components/ui/badge";

export type ChargeTemplatePreviewRow = {
  id: string;
  appointment: Appointment;
  start: Date | null;
  patientName: string;
  providerName: string | null;
  providerTaxonomyCode: string | null;
  appointmentLabels: string[];
  accountType: string | null;
  insurancePolicies: string[];
  codes: string[];
  charges: Charge[];
  chargeTemplate: string | null;
  match: boolean;
};

export const columns: ColumnDef<ChargeTemplatePreviewRow>[] = [
  {
    id: "start",
    accessorKey: "start",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Appt Date" />
    ),
    cell: ({ row }) => {
      if (row.original.start) {
        return <div>{format(row.original.start, "MM/dd/yyyy")}</div>;
      }
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.start);
    },
  },
  {
    id: "patientName",
    accessorKey: "patientName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Patient" />
    ),
    cell: ({ row }) => {
      return <div>{row.original.patientName}</div>;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.patientName);
    },
  },
  {
    id: "accountType",
    accessorKey: "accountType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Account" />
    ),
    cell: ({ row }) => {
      return <div>{row.original.accountType}</div>;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.accountType);
    },
  },
  {
    id: "providerName",
    accessorKey: "providerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Provider" />
    ),
    cell: ({ row }) => {
      return <div>{row.original.providerName}</div>;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.providerName);
    },
  },
  {
    id: "providerTaxonomyCode",
    accessorKey: "providerTaxonomyCode",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Provider Classification" />
    ),
    cell: ({ row }) => {
      return <div>{row.original.providerTaxonomyCode}</div>;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.providerTaxonomyCode);
    },
  },
  {
    id: "appointmentLabels",
    accessorKey: "appointmentLabels",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Appt Type" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center gap-1">
          {row.original.appointment.appointmentLabelings.map((labeling) => (
            <Badge
              key={labeling.appointmentLabel.id}
              className="px-1 py-[1px] font-normal"
            >
              {labeling.appointmentLabel.name}
            </Badge>
          ))}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return row.original.appointmentLabels.some((label) =>
        value.includes(label)
      );
    },
  },
  {
    id: "payer",
    accessorKey: "payer",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payers" />
    ),
    cell: ({ row }) => {
      return (
        <div className="truncate max-w-64">
          {row.original.insurancePolicies.join(", ")}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.some((v: any) =>
        row.original.insurancePolicies.some((ip) => ip === v)
      );
    },
  },
  {
    id: "codes",
    accessorKey: "codes",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charges" />
    ),
    cell: ({ row }) => {
      if (row.original.charges.length === 0) {
        return <div className="text-gray-400">No charges</div>;
      }
      return (
        <div className="flex gap-1 flex-wrap">
          {row.original.charges.map((c) =>
            c.chargemaster ? (
              <ChargemasterBadge
                chargemaster={c.chargemaster}
                units={c.units ?? 1}
              />
            ) : (
              <div className="text-gray-400">{c.customCode}</div>
            )
          )}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.some((v: any) => row.original.charges.some((c) => c === v));
    },
  },
  {
    id: "chargeTemplate",
    accessorKey: "chargeTemplate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charge Template" />
    ),
    cell: ({ row }) => {
      return (
        <div className={cn(row.original.match && "text-green-500")}>
          {row.original.chargeTemplate}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.chargeTemplate);
    },
  },
];
