// @ts-nocheck
import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { format, parseISO } from "date-fns";

import { GetPortalPatientBills_getPortalPatientBills_patient as Patient } from "../../../generated/GetPortalPatientBills";
import { GetPortalPatientBillDetails_getPortalPatientBillDetails as Bill } from "../../../generated/GetPortalPatientBillDetails";
import { isDefined, formatUSD } from "../../../utils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Helvetica",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
});

const Header: React.FC<React.PropsWithChildren<{ bill: Bill; patient: Patient }>> = ({
  bill,
  patient,
}) => {
  const billCodeDisplay = bill.billCode?.replace(/.{4}(?!$)/g, "$&-");
  return (
    <View
      style={{
        width: "100%",
        backgroundColor: "rgb(243 244 246)", // bg-gray-100
        display: "flex",
        padding: "24px",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={{
              uri: patient.organization.logoUrl,
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
            style={{
              height: 96,
              width: "auto",
            }}
            cache={false}
          />
        </View>
        <View
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontSize: 24,
              ...styles.bold,
            }}
          >
            {patient.organization.name}
          </Text>
          <Text
            style={{
              fontSize: 12,
              paddingTop: 8,
              ...styles.bold,
            }}
          >
            Bill ID:
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {billCodeDisplay}
          </Text>
          <Text
            style={{
              fontSize: 12,
              paddingTop: 8,
              ...styles.bold,
            }}
          >
            Date of Service:
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {bill.dateOfServiceDisplayLong}
          </Text>
          {isDefined(bill.dueDate) && (
            <>
              <Text
                style={{
                  fontSize: 12,
                  paddingTop: 8,
                  ...styles.bold,
                }}
              >
                Due Date:
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: "rgb(107 114 128)", // text-gray-500
                }}
              >
                {bill.dueDate
                  ? format(parseISO(bill.dueDate), "MMMM do, yyyy")
                  : "N/A"}
              </Text>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

const BillingAddressSection: React.FC<React.PropsWithChildren<{
  patient: Patient;
}>> = ({ patient }) => {
  const patientName = [patient.firstName, patient.lastName].join(" ");
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        padding: "24px",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              paddingTop: 8,
              ...styles.bold,
            }}
          >
            Bill To:
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {patientName}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {[patient.address1, patient.address2].filter(isDefined).join(", ")}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {[patient.city, patient.state, patient.postalCode]
              .filter(isDefined)
              .join(", ")}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              paddingTop: 8,
              ...styles.bold,
            }}
          >
            Bill From:
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {patient.location?.name}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {[patient.location?.address1, patient.location?.address2]
              .filter(isDefined)
              .join(", ")}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(107 114 128)", // text-gray-500
            }}
          >
            {[
              patient.location?.city,
              patient.location?.state,
              patient.location?.postalCode,
            ]
              .filter(isDefined)
              .join(", ")}
          </Text>
        </View>
      </View>
    </View>
  );
};

const LineItemsSection: React.FC<React.PropsWithChildren<{
  bill: Bill;
}>> = ({ bill }) => {
  const total = bill.charges.reduce(
    (acc, c) => acc + c.patientResponsibilityAmount,
    0
  );
  const adjustmentsTotal = bill.adjustmentsTotal;
  const patientPaymentsTotal = bill.patientPaymentsTotal;
  const dueTotal = bill.patientBalance;
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          backgroundColor: "rgb(243 244 246)", // bg-gray-50
          fontSize: 9,
          textAlign: "center",
          padding: 4,
          ...styles.bold,
        }}
      >
        <View style={{ flex: 1 }}>
          <Text
            style={{
              ...styles.bold,
            }}
          >
            Code
          </Text>
        </View>
        <View style={{ flex: 4 }}>
          <Text>Description</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Units</Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text>Billed Amount</Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text>Insurance Paid</Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text>Patient Responsibility</Text>
        </View>
      </View>
      {/* Line Items */}
      {bill.charges.map((charge) => {
        const serviceCodeDisplay =
          charge.serviceCode?.code ?? charge.customCode;
        return (
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              fontSize: 9,
              textAlign: "center",
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            <View style={{ flex: 1 }}>
              <Text>{serviceCodeDisplay}</Text>
            </View>
            <View style={{ flex: 4 }}>
              <Text>{charge.transaction.description}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text>{charge.units}</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text>{formatUSD(-charge.allowedAmount)}</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text>{formatUSD(-charge.insuranceAmount)}</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text>{formatUSD(-charge.patientResponsibilityAmount)}</Text>
            </View>
          </View>
        );
      })}
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        {/* Empty column */}
        <View style={{ flex: 5 }}></View>
        <View
          style={{
            flex: 7,
            borderTopWidth: 1,
            borderTopColor: "rgb(17 24 39)", // text-gray-900
            borderTopStyle: "solid",
            display: "flex",
            flexDirection: "column",
            fontSize: 9,
            marginLeft: 20,
            marginRight: 20,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View
            style={{
              display: "flex",
              paddingTop: 8,
              paddingBottom: 8,
              ...styles.bold,
            }}
          >
            <View style={{ flex: 5, textAlign: "left" }}>
              <Text>Bill Total</Text>
            </View>
            <View style={{ flex: 2, textAlign: "right" }}>
              <Text>{formatUSD(-total)}</Text>
            </View>
          </View>
          {adjustmentsTotal !== 0 && (
            <View
              style={{
                display: "flex",
                paddingTop: 8,
                paddingBottom: 8,
              }}
            >
              <View style={{ flex: 5, textAlign: "left" }}>
                <Text>Adjustments</Text>
              </View>
              <View style={{ flex: 2, textAlign: "right" }}>
                <Text>
                  {adjustmentsTotal !== 0 && "-"}
                  {formatUSD(adjustmentsTotal)}
                </Text>
              </View>
            </View>
          )}
          <View
            style={{
              display: "flex",
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            <View style={{ flex: 5, textAlign: "left" }}>
              <Text>Patient Paid</Text>
            </View>
            <View style={{ flex: 2, textAlign: "right" }}>
              <Text>
                {patientPaymentsTotal !== 0 && "-"}
                {formatUSD(patientPaymentsTotal)}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        {/* Empty column */}
        <View style={{ flex: 5 }}></View>
        <View
          style={{
            flex: 7,
            borderTopWidth: 1,
            borderTopColor: "rgb(17 24 39)", // text-gray-900
            borderTopStyle: "solid",
            display: "flex",
            flexDirection: "column",
            fontSize: 9,
            marginLeft: 20,
            marginRight: 20,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View
            style={{
              display: "flex",
              textAlign: "center",
              paddingTop: 8,
              ...styles.bold,
            }}
          >
            <View style={{ flex: 5, textAlign: "left" }}>
              <Text>Amount Due</Text>
            </View>
            <View style={{ flex: 2, textAlign: "right" }}>
              <Text>{formatUSD(-dueTotal)}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export const BillPDF: React.FC<React.PropsWithChildren<{ bill: Bill; patient: Patient }>> = ({
  bill,
  patient,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Header bill={bill} patient={patient} />
        </View>
        <View>
          <BillingAddressSection patient={patient} />
        </View>
        <View>
          <LineItemsSection bill={bill} />
        </View>
      </Page>
    </Document>
  );
};
