import React from "react";
import { Badge } from "../../components";

export const NetworkBadge: React.FC<React.PropsWithChildren<{
  orgPayerNetworkStatus: boolean;
}>> = ({ orgPayerNetworkStatus }) => {
  if (!orgPayerNetworkStatus) {
    return <Badge text="Out of Network" variant="warning" />;
  }
  return <Badge text="In Network" variant="success" />;
};
