import React, { useEffect, useState } from "react";
import { Card } from "../../components/ui/card";
import { Badge } from "../../components/ui/badge";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { PatientSummary } from "../patients/profile";
import { InsurancePolicyRow } from "../worklists/policies/split-pane";
import {
  CompletionSection,
  WorklistProgressSection,
} from "../worklists/split-pane";
import { useDashboardResizable } from "../../dashboard-resizable";
import { ChevronUpIcon, XIcon } from "@heroicons/react/outline";
import { format, formatRelative, parseISO } from "date-fns";
import { Button } from "../../components/ui/button";
import { useUser } from "../../user-context";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  EligibilityRequestStatus,
  WorkflowStage,
} from "../../generated/globalTypes";
import { toast } from "react-toastify";
import { OvalSpinner } from "../../components/loading";
import { EstimationWorkflowStatusIndicator } from "./list";
import {
  GetEstimationWorkflowAppointmentDetails,
  GetEstimationWorkflowAppointmentDetailsVariables,
  GetEstimationWorkflowAppointmentDetails_appointment as Appointment,
  GetEstimationWorkflowAppointmentDetails_appointment_insurancePolicies as InsurancePolicy,
} from "../../generated/GetEstimationWorkflowAppointmentDetails";
import {
  GetEstimationWorkflowWorklist,
  GetEstimationWorkflowWorklistVariables,
} from "../../generated/GetEstimationWorkflowWorklist";
import { CreateEstimateWizard } from "../worklists/policies/create-estimate-task";
import { cn, formatUSD, isDefined } from "../../utils";
import {
  GetLocationChargeTemplates,
  GetLocationChargeTemplatesVariables,
} from "../../generated/GetLocationChargeTemplates";
import { GET_LOCATION_CHARGE_TEMPLATES } from "../appointments/table/estimate-dialog";
import {
  COVERAGE_BENEFIT_FIELDS,
  ESTIMATE_FIELDS,
  INSURANCE_POLICY_SUMMARY_FIELDS,
  VISIT_COLLECTION_REQUEST_FIELDS,
} from "../../graphql";
import {
  DepositPreviewCard,
  EstimatePreviewCard,
} from "../appointments/table/columns";
import { ServiceBenefitHoverCard } from "../patients/insurances/show";
import { Disclosure } from "@headlessui/react";
import {
  VerifyBenefitsDropdownButton,
  VerifyButton,
} from "../verifications/side-panel";
import { GetMe_me_organization_providerServiceConfiguration as ProviderServiceConfiguration } from "../../generated/GetMe";

const GET_ESTIMATION_WORKFLOW_APPOINTMENT_DETAILS = gql`
  ${ESTIMATE_FIELDS}
  ${VISIT_COLLECTION_REQUEST_FIELDS}
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${COVERAGE_BENEFIT_FIELDS}
  query GetEstimationWorkflowAppointmentDetails(
    $id: String!
    $referenceDate: DateTime!
  ) {
    appointment(where: { id: $id }) {
      id
      createdAt
      updatedAt

      # Estimation Workflow state
      estimationWorkflowStatus {
        id
        name
        stage
      }
      lastEstimationWorkflowStartedAt
      lastEstimationWorkflowCompletedAt
      lastEstimationWorkflowCanceledAt
      estimationWorkflowActiveAt
      estimationWorkflowArchivedAt
      lastEstimationError
      appointmentLabelings {
        id
        appointmentLabel {
          id
          name
        }
      }
      start
      end
      provider {
        id
        displayName
        eligibilityEnabled
      }
      estimates(orderBy: { createdAt: desc }, take: 1) {
        ...EstimateFields
      }
      mostRecentVisitCollectionRequest {
        ...VisitCollectionRequestFields
      }
      matchingBenefitMappings {
        insurancePolicy {
          id
        }
        benefitMappings {
          id
          name
          priority
          providerServiceConfiguration {
            id
            name
          }
        }
      }
      recommendedChargeTemplate {
        id
        name
      }
      recommendedSaltingCharges {
        saltedAppointmentId
        saltedBillId
        charges {
          id
          customCode
          chargemaster {
            id
            code
            modifier1
            modifier2
            modifier3
            modifier4
            description
            chargemasterGroupId
            chargemasterGroup {
              id
              code
              modifier1
              modifier2
              modifier3
              modifier4
              description
              primaryChargemaster {
                id
                code
                modifier1
                modifier2
                modifier3
                modifier4
                description
                chargemasterGroupId
              }
            }
          }
          units
          allowedAmount
        }
      }
      insurancePolicies {
        id
        priority
        memberId
        ...InsurancePolicySummaryFields
        payer {
          id
          name
        }
        mostRecentEligibilityRequest {
          id
          createdAt
          status
          eligible
          allRequestsErrored
          deduplicatedErrors {
            id
            field
            description
            followupAction
          }
          reverificationStatus(referenceDate: $referenceDate) {
            needsReverification
            reason
          }
        }
        # Get coverage benefits for both in and out of network
        inNetworkBenefits: mostRecentCoverageBenefits(inNetwork: true) {
          id
          combinedCoverageBenefit {
            # Don't fetch id otherwise it'll collied with actual coverage benefit
            # id
            copay
            coinsurance
            remainingDeductible
            remainingOutOfPocket
            remainingVisits
            deductibleApplies
            nonCovered
            adjustedRemainingDeductible
            adjustedRemainingOutOfPocket
          }
          providerServiceConfiguration {
            id
            name
          }
          ...CoverageBenefitFields
        }
        outOfNetworkBenefits: mostRecentCoverageBenefits(inNetwork: false) {
          id
          combinedCoverageBenefit {
            # Don't fetch id otherwise it'll collied with actual coverage benefit
            # id
            copay
            coinsurance
            remainingDeductible
            remainingOutOfPocket
            remainingVisits
            deductibleApplies
            nonCovered
            adjustedRemainingDeductible
            adjustedRemainingOutOfPocket
          }
          providerServiceConfiguration {
            id
            name
          }
          ...CoverageBenefitFields
        }
      }
      account {
        id
        accountType {
          id
          name
        }
        patient {
          id
          displayName
          dateOfBirth
          birthSex
          email
          integrationLinks {
            id
            createdAt
            lastCheckedAt
            integration {
              id
              name
              supportsOnDemandPatientSync
            }
            integrationLinkSyncs(orderBy: { createdAt: desc }, take: 1) {
              id
              integrationLinkSyncErrors {
                id
                message
              }
            }
          }
        }
      }
    }
  }
`;

const CANCEL_APPOINTMENT_ESTIMATION_WORKFLOW = gql`
  mutation CancelAppointmentEstimationWorkflow($id: String!) {
    cancelAppointmentEstimationWorkflow(appointmentId: $id) {
      id
      lastEstimationWorkflowCanceledAt
      estimationWorkflowStatus {
        id
        name
        stage
      }
    }
  }
`;

const UPDATE_APPOINTMENT_ESTIMATION_WORKFLOW_STATUS = gql`
  mutation UpdateAppointmentEstimationWorkflowStatus(
    $id: String!
    $statusId: String!
  ) {
    updateOneAppointment(
      where: { id: $id }
      data: { estimationWorkflowStatus: { connect: { id: $statusId } } }
    ) {
      id
      estimationWorkflowStatus {
        id
        name
        stage
      }
    }
  }
`;

const CreateEstimateSection: React.FC<{
  appointment: Appointment;
}> = ({ appointment }) => {
  const user = useUser()!;
  const estimate = appointment?.estimates?.at(0);

  const deposit = appointment?.mostRecentVisitCollectionRequest;
  const existingEstimate = isDefined(deposit?.estimate);
  const existingDeposit = deposit?.estimate === null;

  const insurancePolicy = appointment.insurancePolicies.at(0);

  const [showWizard, setShowWizard] = useState(
    !existingDeposit && !existingEstimate
  );

  const chargeTemplatesResult = useQuery<
    GetLocationChargeTemplates,
    GetLocationChargeTemplatesVariables
  >(GET_LOCATION_CHARGE_TEMPLATES, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });

  if (!appointment || chargeTemplatesResult.loading) return null;
  const chargeTemplates = chargeTemplatesResult.data?.chargeTemplates ?? [];

  return (
    <div>
      {!showWizard ? (
        <div className="pt-2 pr-2">
          {existingEstimate && estimate && (
            <EstimatePreviewCard estimate={estimate} />
          )}
          {existingDeposit && <DepositPreviewCard deposit={deposit} />}

          <div className="flex justify-end pt-4">
            <Button
              onClick={() => {
                setShowWizard(true);
              }}
            >
              New Estimate
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {(existingDeposit || existingEstimate) && (
            <div className="flex justify-end">
              <Button
                type="button"
                onClick={() => {
                  setShowWizard(false);
                }}
              >
                Back to Estimate
              </Button>
            </div>
          )}
          <CreateEstimateWizard
            appointment={appointment}
            insurancePolicy={insurancePolicy}
            chargeTemplates={chargeTemplates}
            patientId={appointment.account.patient.id}
            closeWizard={() => {
              setShowWizard(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

const InsurancePolicyDisclosureRow: React.FC<{
  appointment: Appointment;
  insurancePolicy: InsurancePolicy;
  priority: number;
  patientId: string;
  providerServiceConfigurations: ProviderServiceConfiguration[];
}> = ({
  appointment,
  insurancePolicy,
  priority,
  patientId,
  providerServiceConfigurations,
}) => {
  const apollo = useApolloClient();
  const coverageBenefits = insurancePolicy.inNetwork
    ? insurancePolicy.inNetworkBenefits
    : insurancePolicy.outOfNetworkBenefits;
  return (
    <Disclosure as="div">
      <Disclosure.Button className="w-full flex justify-between pt-1 gap-2">
        {({ open }) => (
          <>
            <div className="grow">
              <InsurancePolicyRow
                insurancePolicy={insurancePolicy}
                priority={insurancePolicy.priority}
                patientId={patientId}
                onEligibilityRequestCompleted={(data) => {
                  if (
                    data.eligibilityRequest?.status &&
                    data.eligibilityRequest.status !==
                      EligibilityRequestStatus.InProgress
                  ) {
                    apollo.refetchQueries({
                      include: [GET_ESTIMATION_WORKFLOW_APPOINTMENT_DETAILS],
                    });
                  }
                }}
              />
            </div>
            <ChevronUpIcon
              className={cn(
                "h-6 w-6 text-gray-500 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                open && "rotate-180"
              )}
            />
          </>
        )}
      </Disclosure.Button>

      <Disclosure.Panel className="py-2">
        <div className="text-sm font-medium text-gray-900">Benefits</div>
        <div className="flex flex-wrap gap-1 pt-1">
          {[...providerServiceConfigurations]
            .sort(
              (a, b) =>
                (a.priorityOrder ?? Infinity) - (b.priorityOrder ?? Infinity)
            )
            .map((config) => {
              const cb = coverageBenefits.find(
                (b) => b.providerServiceConfiguration.id === config.id
              );
              if (!cb) {
                return (
                  <ServiceBenefitHoverCard
                    key={config.id}
                    trigger={
                      <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-600/10">
                        {config.shortName ?? config.name}
                      </span>
                    }
                    coverageBenefit={null}
                    patientId={patientId}
                    providerServiceConfiguration={config}
                    insurancePolicy={insurancePolicy}
                    inNetwork={insurancePolicy.inNetwork}
                  />
                );
              }
              if (cb.empty) {
                return (
                  <ServiceBenefitHoverCard
                    key={cb.id}
                    trigger={
                      <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-600/10">
                        {cb.providerServiceConfiguration.shortName ??
                          cb.providerServiceConfiguration.name}
                      </span>
                    }
                    coverageBenefit={cb}
                    patientId={patientId}
                    providerServiceConfiguration={
                      cb.providerServiceConfiguration
                    }
                    insurancePolicy={insurancePolicy}
                    inNetwork={cb.networkStatus}
                  />
                );
              }
              if (cb.nonCovered) {
                return (
                  <ServiceBenefitHoverCard
                    key={cb.id}
                    trigger={
                      <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {cb.providerServiceConfiguration.shortName ??
                          cb.providerServiceConfiguration.name}
                      </span>
                    }
                    coverageBenefit={cb}
                    patientId={patientId}
                    providerServiceConfiguration={
                      cb.providerServiceConfiguration
                    }
                    insurancePolicy={insurancePolicy}
                    inNetwork={cb.networkStatus}
                  />
                );
              }

              return (
                <ServiceBenefitHoverCard
                  key={cb.id}
                  trigger={
                    <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {cb.providerServiceConfiguration.shortName ??
                        cb.providerServiceConfiguration.name}
                    </span>
                  }
                  coverageBenefit={cb}
                  patientId={patientId}
                  providerServiceConfiguration={cb.providerServiceConfiguration}
                  insurancePolicy={insurancePolicy}
                  inNetwork={cb.networkStatus}
                />
              );
            })}

          <div className="w-full flex justify-end">
            <VerifyButton
              insurancePolicy={insurancePolicy}
              appointment={appointment}
              patientId={appointment.account.patient.id}
              refetchQueries={[GET_ESTIMATION_WORKFLOW_APPOINTMENT_DETAILS]}
            />
            <VerifyBenefitsDropdownButton
              insurancePolicyId={insurancePolicy.id}
              patientId={appointment.account.patient.id}
            />
          </div>
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
};

const EstimationWorkflowExecutionViewWrapper: React.FC<{
  id: string;
}> = ({ id }) => {
  const [now] = useState(new Date());
  const { data, loading } = useQuery<
    GetEstimationWorkflowAppointmentDetails,
    GetEstimationWorkflowAppointmentDetailsVariables
  >(GET_ESTIMATION_WORKFLOW_APPOINTMENT_DETAILS, {
    variables: {
      id,
      referenceDate: now,
    },
  });
  if (loading || !data) {
    return <div>Loading...</div>;
  }
  return <EstimationWorkflowExecutionView appointment={data.appointment!} />;
};

const EstimationWorkflowExecutionView: React.FC<{
  appointment: Appointment;
}> = ({ appointment }) => {
  const user = useUser()!;
  const estimationWorkflowStatuses =
    user.activeLocation.estimationWorkflowStatuses;
  const [estimationWorkflowStatusId, setEstimationWorkflowStatusId] = useState(
    appointment.estimationWorkflowStatus.id
  );

  // Update the status if it changes
  useEffect(() => {
    setEstimationWorkflowStatusId(appointment.estimationWorkflowStatus.id);
  }, [appointment.estimationWorkflowStatus.id]);

  const patientId = appointment.account.patient.id;

  const [updateEstimationWorkflowStatus, updateEstimationWorkflowStatusResult] =
    useMutation(UPDATE_APPOINTMENT_ESTIMATION_WORKFLOW_STATUS);

  const visitCollectionRequest = appointment.mostRecentVisitCollectionRequest;

  const providerServiceConfigurations =
    user.organization.providerServiceConfiguration ?? [];

  return (
    <>
      <Card>
        <div className="flex flex-col p-3 gap-1 divide-y">
          <PatientSummary
            patient={{
              ...appointment.account.patient,
            }}
          />

          <div className="pt-2 pb-1 mt-1 flex flex-col gap-1">
            <div className="font-medium">Appointment Details</div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-medium">Date</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <div className="[&:first-letter]:capitalize">
                  {formatRelative(parseISO(appointment.start), new Date())}
                  {isDefined(appointment.end) &&
                    `- ${format(parseISO(appointment.end), "h:mm aa")}`}
                </div>
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-medium">Account</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {appointment.account.accountType?.name}
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-medium">Appointment Labels</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <div className="flex items-center gap-1">
                  {appointment.appointmentLabelings.map((labeling) => (
                    <Badge
                      key={labeling.appointmentLabel.id}
                      className="px-1 py-[1px] font-normal"
                    >
                      {labeling.appointmentLabel.name}
                    </Badge>
                  ))}
                </div>
              </dd>
            </div>
            <div className="flex justify-between items-center">
              <dt className="text-sm font-medium">Provider</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {appointment.provider?.displayName}
              </dd>
            </div>
          </div>
        </div>
      </Card>

      <dl className="divide-y divide-gray-100 px-1">
        <div className="py-6 flex justify-between items-center">
          <dt className="font-medium text-gray-900">Workflow Status</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <div className="flex items-center gap-2">
              <Select
                value={estimationWorkflowStatusId}
                onValueChange={(value) => {
                  const oldStatusId = estimationWorkflowStatusId;
                  setEstimationWorkflowStatusId(value);
                  updateEstimationWorkflowStatus({
                    variables: {
                      id: appointment.id,
                      statusId: value,
                    },
                    onCompleted: () => {
                      toast.success("Status updated");
                    },
                    onError: () => {
                      toast.error("Failed to update status");
                      setEstimationWorkflowStatusId(oldStatusId);
                    },
                  });
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select a status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Workflow Status</SelectLabel>
                    {estimationWorkflowStatuses.map((status) => (
                      <SelectItem value={status.id}>
                        <div className="flex items-center gap-2">
                          <EstimationWorkflowStatusIndicator status={status} />
                          {status.name}
                        </div>
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </dd>
        </div>

        <div className="py-6 flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <dt className="text-sm font-medium text-gray-900">
              Insurance Policies
            </dt>
          </div>

          <div className="flex flex-col divide-y gap-1">
            {appointment.insurancePolicies.map((insurancePolicy) => {
              return (
                <InsurancePolicyDisclosureRow
                  key={insurancePolicy.id}
                  appointment={appointment}
                  insurancePolicy={insurancePolicy}
                  priority={insurancePolicy.priority}
                  patientId={appointment.account.patient.id}
                  providerServiceConfigurations={providerServiceConfigurations}
                />
              );
            })}
          </div>
        </div>

        <div className="py-6 flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <dt className="text-sm font-medium text-gray-900">
              Latest Estimate
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {isDefined(visitCollectionRequest) ? (
                <span>{formatUSD(visitCollectionRequest.amount)}</span>
              ) : (
                <span className="text-gray-500 italic">Not estimated</span>
              )}
            </dd>
          </div>

          <CreateEstimateSection appointment={appointment} />
        </div>
      </dl>
    </>
  );
};

const GET_ESTIMATION_WORKFLOW_WORKLIST = gql`
  query GetEstimationWorkflowWorklist($ids: [String!]!) {
    appointments(where: { id: { in: $ids } }) {
      id
      estimationWorkflowStatus {
        id
        name
        stage
      }
    }
  }
`;

const EstimationWorkflowCompletionSection: React.FC<{
  ids: string[];
  onClose: () => void;
}> = ({ ids, onClose }) => {
  const total = ids.length;
  const { data, loading } = useQuery<
    GetEstimationWorkflowWorklist,
    GetEstimationWorkflowWorklistVariables
  >(GET_ESTIMATION_WORKFLOW_WORKLIST, {
    variables: {
      ids,
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const worklist = data?.appointments ?? [];
  const completed = worklist.reduce(
    (sum, policy) =>
      sum +
      (policy.estimationWorkflowStatus.stage === WorkflowStage.Complete
        ? 1
        : 0),
    0
  );
  const removed = worklist.reduce(
    (sum, policy) =>
      sum +
      (policy.estimationWorkflowStatus.stage === WorkflowStage.Canceled
        ? 1
        : 0),
    0
  );
  const skipped = total - completed - removed;

  return (
    <CompletionSection
      completed={completed}
      skipped={skipped}
      removed={removed}
      onClose={onClose}
    />
  );
};

export const EstimationSidePanel: React.FC<{ ids: string[] }> = ({ ids }) => {
  const { collapse } = useDashboardResizable();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const total = ids.length;
  const currentId = ids[currentIndex];
  const [cancel, cancelResult] = useMutation(
    CANCEL_APPOINTMENT_ESTIMATION_WORKFLOW
  );
  const { data, loading } = useQuery<
    GetEstimationWorkflowWorklist,
    GetEstimationWorkflowWorklistVariables
  >(GET_ESTIMATION_WORKFLOW_WORKLIST, {
    variables: {
      ids,
    },
  });
  const worklist = data?.appointments ?? [];
  const currentItem = worklist.find((policy) => policy.id === currentId);
  const completed =
    currentItem?.estimationWorkflowStatus.stage === WorkflowStage.Complete;
  const canceled =
    currentItem?.estimationWorkflowStatus.stage === WorkflowStage.Canceled;

  return (
    <div className="h-screen flex flex-col px-4 bg-white border-l border-gray-300 print:hidden">
      <div className="flex justify-between items-center pt-4">
        <h2 className="text-xl text-medium">Estimation Worklist</h2>

        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={collapse}
        >
          <span className="sr-only">Close side pane</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <WorklistProgressSection
        currentIndex={currentIndex}
        total={total}
        next={() => setCurrentIndex(currentIndex + 1)}
        previous={() => setCurrentIndex(currentIndex - 1)}
        nextDisabled={currentIndex === total - 1}
      />

      <div className="py-2 h-full flex flex-col">
        <div className="h-full max-h-[calc(100vh-14rem)] overflow-y-auto overflow-x-hidden flex flex-col gap-4">
          {currentIndex === total ? (
            <EstimationWorkflowCompletionSection
              ids={ids}
              onClose={() => {
                collapse();
              }}
            />
          ) : (
            <EstimationWorkflowExecutionViewWrapper id={currentId} />
          )}
        </div>
      </div>

      {currentIndex !== total && (
        <div className="flex border-t mt-2 py-4 gap-2">
          <div className="w-full flex justify-between">
            <Button
              variant="destructive"
              size="lg"
              type="button"
              disabled={canceled || cancelResult.loading}
              onClick={() => {
                cancel({
                  variables: {
                    id: currentId,
                  },
                  onCompleted: () => {
                    toast.success("Policy canceled");
                  },
                  onError: () => {
                    toast.error("Failed to cancel policy");
                  },
                });
              }}
            >
              {cancelResult.loading ? (
                <OvalSpinner className="h-4 w-4" />
              ) : (
                <>Cancel</>
              )}
            </Button>
            <Button
              size="lg"
              variant={completed ? "default" : "outline"}
              onClick={() => {
                setCurrentIndex(currentIndex + 1);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
