import React from "react";
import { Layout } from "../../layout";
import { DepositMappingForm } from "./deposit-mapping-form";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { Condition, Visit } from "../../../evaluate-rule";
import {
  GetDeposits,
  GetDepositsVariables,
} from "../../../generated/GetDeposits";
import { useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import { GetDepositMapping_depositMapping as DepositMapping } from "../../../generated/GetDepositMapping";
import { useLocation } from "react-router-dom";

export const NewDepositRule: React.FC = () => {
  const user = useUser()!;
  const location = useLocation();
  const state: {
    depositMapping?: DepositMapping | null;
  } | null = location.state;

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Create New Rule
          </h1>
        </div>
      }
      content={
        <DepositMappingForm depositMapping={state?.depositMapping ?? null}>
          {({ conditions }) => (
            <div>
              <BenefitAssignmentRuleSimulatorTable
                conditions={conditions as Condition<keyof Visit>[]}
              />
            </div>
          )}
        </DepositMappingForm>
      }
    />
  );
};
