import React from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useQuery } from "@apollo/client";

import {
  GetPublicOrganizationData,
  GetPublicOrganizationDataVariables,
} from "./generated/GetPublicOrganizationData";
import { GET_PUBLIC_ORGANIZATION_DATA } from "./graphql";
import { constants } from "./constants";
import { StripeConnectAccountType } from "./generated/globalTypes";

const StripeContext = React.createContext<Promise<Stripe | null>>(
  Promise.resolve(null)
);

export const StripeProvider: React.FC<React.PropsWithChildren<{ organizationId: string }>> = (props) => {
  const { data, loading } = useQuery<
    GetPublicOrganizationData,
    GetPublicOrganizationDataVariables
  >(GET_PUBLIC_ORGANIZATION_DATA, {
    variables: { id: props.organizationId },
  });

  const stripeConnectedAccountType =
    data?.getPublicOrganizationData?.stripeConnectAccountType;
  const stripeAccount =
    stripeConnectedAccountType === StripeConnectAccountType.Express
      ? undefined
      : data?.getPublicOrganizationData?.stripeAccountId ?? undefined;

  const stripePromise = React.useMemo(() => {
    if (data?.getPublicOrganizationData) {
      return loadStripe(constants.VITE_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount,
      });
    }
  }, [data?.getPublicOrganizationData?.stripeAccountId]);

  if (loading) return <div></div>;

  // TODO: clearly can't use this for orgs without billing enabled!
  if (
    stripeConnectedAccountType !== StripeConnectAccountType.Express &&
    !data?.getPublicOrganizationData?.stripeAccountId
  )
    return <>{props.children}</>;

  return <StripeContext.Provider value={stripePromise!} {...props} />;
};

export const useStripeContext = () => React.useContext(StripeContext);
