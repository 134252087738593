import React from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { FeeScheduleForm } from "./fee-schedule-form";
import { Layout } from "../layout";
import { toCents } from "../../utils";
import {
  UpsertFeeSchedule,
  UpsertFeeScheduleVariables,
} from "../../generated/UpsertFeeSchedule";
import { UPSERT_FEESCHEDULE } from "./edit";
import { useUser } from "../../user-context";
import { useAnalytics } from "../../analytics-context";

const CreateFeeScheduleForm: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const [upsertFeeSchedule, result] = useMutation<
    UpsertFeeSchedule,
    UpsertFeeScheduleVariables
  >(UPSERT_FEESCHEDULE);

  const onSubmit = async (data: FeeScheduleForm) => {
    const fees = data.scheduledServiceFees.map((fee) => ({
      id: fee.id,
      code: fee.code?.trim(),
      name: fee.name,
      modifier1: fee.modifier1?.trim(),
      modifier2: fee.modifier2?.trim(),
      modifier3: fee.modifier3?.trim(),
      modifier4: fee.modifier4?.trim(),
      allowedAmount: toCents(fee.allowedAmount!),
      delete: false,
    }));

    upsertFeeSchedule({
      variables: {
        id: null,
        name: data.name!,
        payerIds: data.payerFeeSchedules ?? [],
        payerIdsToDelete: [],
        fees: fees.map((fee) => ({
          id: fee.id,
          code: fee.code!,
          name: fee.name!,
          modifier1: fee.modifier1 ?? "",
          modifier2: fee.modifier2 ?? "",
          modifier3: fee.modifier3 ?? "",
          modifier4: fee.modifier4 ?? "",
          allowedAmount: fee.allowedAmount,
          delete: false,
        })),
      },
      onCompleted: (data) => {
        toast.success("Fee Schedule Created");
        analytics?.track("Fee Schedule Created", {
          organizationId: user.organization.id,
          organizationName: user.organization.name,
          locationId: user.activeLocation.id,
          locationName: user.activeLocation.name,
          feeScheduleId: data.upsertFeeSchedule.id,
        });
        navigate("/fee-schedules");
      },
      onError: () => {
        toast.error("Error creating Fee Schedule");
      },
    });
  };

  return (
    <FeeScheduleForm
      loading={result.loading}
      submit={onSubmit}
      submitText="Create Fee Schedule"
    />
  );
};

export const NewFeeSchedule: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            New Fee Schedule
          </h1>
        </div>
      }
      content={
        <div className="flex flex-col gap-4 py-8">
          <CreateFeeScheduleForm />
        </div>
      }
    />
  );
};
