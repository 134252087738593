import React from "react";
import { FullWidthLayout, HorizontalPadding, Layout } from "../layout";
import { AppointmentsTable } from "./table/list";
import { parse, startOfDay } from "date-fns";

function getStartOfDay(dateString: string | null = null): Date {
  if (dateString) {
    // If a date string is provided, parse it and return the start of that day
    const parsedDate: Date = parse(dateString, "yyyy-MM-dd", new Date());
    return startOfDay(parsedDate);
  } else {
    // If no date string is provided, return the start of today in the browser's timezone
    return startOfDay(new Date());
  }
}

export const AppointmentsList: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const dateStr = queryParams.get("date");
  const start = getStartOfDay(dateStr);

  return (
    <FullWidthLayout
      header={
        <HorizontalPadding>
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold text-gray-900">
              Appointments
            </h1>
          </div>
        </HorizontalPadding>
      }
      content={
        <div className="py-4">
          <AppointmentsTable date={start} />
        </div>
      }
    />
  );
};
