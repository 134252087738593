import { gql, useQuery } from "@apollo/client";
import { RulesHeader } from "..";
import { FullWidthLayout, HorizontalPadding, Layout } from "../../layout";
import { RuleExplorerRow, columns } from "./columns";
import { DataTable } from "./table";
import { useUser } from "../../../user-context";
import { useState } from "react";
import { endOfDay, parseISO } from "date-fns";
import {
  GetRuleExplorerVisits,
  GetRuleExplorerVisitsVariables,
  GetRuleExplorerVisits_getRuleExplorerVisits_bill_charges_chargemaster as Chargemaster,
} from "../../../generated/GetRuleExplorerVisits";
import { isDefined } from "../../../utils";

const GET_RULE_EXPLORER_VISITS = gql`
  query GetRuleExplorerVisits(
    $take: Int!
    $after: DateTime
    $before: DateTime
  ) {
    getRuleExplorerVisits(after: $after, before: $before, take: $take) {
      id
      start
      end
      appointmentLabelings {
        id
        appointmentLabel {
          id
          name
        }
      }
      account {
        id
        patient {
          id
          displayName
          patientLabelings {
            id
            patientLabel {
              id
              name
            }
          }
        }
        accountType {
          id
          name
        }
      }
      insurancePolicies {
        id
        payer {
          id
          name
        }
      }
      provider {
        id
        displayName
        providerTaxonomyCode {
          id
          code
          displayName
        }
      }
      bill {
        id
        charges {
          id
          units
          customCode
          chargemaster {
            id
            code
            modifier1
            modifier2
            modifier3
            modifier4
            description
            chargemasterGroupId
          }
        }
        activeEstimate {
          id
          chargeTemplate {
            id
            name
          }
          feeSchedule {
            id
            name
          }
        }
      }
    }
  }
`;

export const RuleExplorerPage: React.FC = () => {
  const user = useUser()!;
  const [before] = useState(endOfDay(new Date()));
  const { data, loading } = useQuery<
    GetRuleExplorerVisits,
    GetRuleExplorerVisitsVariables
  >(GET_RULE_EXPLORER_VISITS, {
    variables: {
      after: undefined,
      before,
      take: 1000,
    },
  });

  const visits = data?.getRuleExplorerVisits ?? [];

  const tableData: RuleExplorerRow[] = visits.map((visit) => {
    const bill = visit.bill.at(0);
    const activeEstimate = bill?.activeEstimate;
    return {
      id: visit.id,
      patientName: visit.account.patient.displayName ?? null,
      start: parseISO(visit.start),
      end: visit.end ? parseISO(visit.end) : null,
      providerName: visit.provider?.displayName ?? null,
      providerClassification:
        visit.provider?.providerTaxonomyCode?.displayName ?? null,
      appointmentLabels: visit.appointmentLabelings.map(
        (labeling) => labeling.appointmentLabel.name
      ),
      patientLabels: visit.account.patient.patientLabelings.map(
        (labeling) => labeling.patientLabel.name
      ),
      accountType: visit.account.accountType?.name,
      payerName: visit.insurancePolicies[0]?.payer.name ?? null,
      charges: bill?.charges ?? [],
      // codes: bill?.charges?.map((c) => c.customCode)?.join(", ") ?? null,
      codes:
        bill?.charges
          ?.map((c) => c.chargemaster?.chargemasterGroupId)
          ?.filter(isDefined) ?? [],
      feeSchedule: activeEstimate?.feeSchedule?.name ?? null,
      chargeTemplate: activeEstimate?.chargeTemplate?.name ?? null,
    };
  });

  return (
    <FullWidthLayout
      header={
        <HorizontalPadding>
          <RulesHeader />
        </HorizontalPadding>
      }
      content={
        <div className="flex flex-col">
          <div className="py-4">
            <DataTable
              data={tableData}
              columns={columns}
              loading={loading}
              defaultColumnFilters={[]}
            />
          </div>
        </div>
      }
    />
  );
};
