import React from "react";

export const Layout: React.FC<
  React.PropsWithChildren<{
    header: React.ReactNode;
    content: React.ReactNode;
  }>
> = ({ header, content }) => (
  <div className="flex flex-col pt-4 print:pt-0 px-2 lg:px-8 w-full max-w-[2048px] mx-auto">
    {header}
    {content}
  </div>
);

export const FullWidthLayout: React.FC<
  React.PropsWithChildren<{
    header: React.ReactNode;
    content: React.ReactNode;
  }>
> = ({ header, content }) => (
  <div className="flex flex-col pt-4 print:pt-0 w-full">
    {header}
    {content}
  </div>
);

export const LoadingLayout: React.FC<
  React.PropsWithChildren<{
    header?: React.ReactNode;
    content?: React.ReactNode;
  }>
> = ({ header, content }) => {
  return (
    <Layout
      header={
        <div className="flex flex-col">
          {header ?? (
            <div className="h-16 animate-pulse rounded-md bg-slate-100" />
          )}
        </div>
      }
      content={
        content ?? (
          <div className="flex flex-col gap-4">
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          </div>
        )
      }
    />
  );
};

// Mimic the regular layout spacing
export const HorizontalPadding: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <div className="flex flex-col px-2 lg:px-8 w-full max-w-[2048px] mx-auto">
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end">
      {children}
    </div>
  </div>
);
