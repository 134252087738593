import React from "react";
import { gql, useQuery } from "@apollo/client";
import { compareDesc, parseISO } from "date-fns";
import {
  Activity,
  ActivityFeedItem,
  BILL_ACTIVITY_FIELDS,
} from "../patients/activity-feed";
import {
  GetBillActivity,
  GetBillActivityVariables,
} from "../../generated/GetBillActivity";

const GET_BILL_ACTIVITY = gql`
  ${BILL_ACTIVITY_FIELDS}
  query GetBillActivity($id: String!) {
    bill(where: { id: $id }) {
      id
      communications {
        id
        type
        contentType
        sentAt
        lastOpenedAt
        openCount
        lastErrorType
        lastErrorReasonDisplay
        sentBy {
          id
          firstName
          lastName
        }
        bills {
          ...BillActivityFields
        }
      }
    }
  }
`;

export const BillActivityTimeline: React.FC<
  React.PropsWithChildren<{
    billId: string;
    patient: { displayName: string };
  }>
> = ({ billId, patient }) => {
  const { loading, data } = useQuery<GetBillActivity, GetBillActivityVariables>(
    GET_BILL_ACTIVITY,
    {
      variables: {
        id: billId,
      },
    }
  );

  if (loading || !data) return <div>Loading...</div>;

  const activities: Activity[] = [
    ...(data.bill?.communications ?? []).map((c) => ({
      timestamp: c.sentAt ? parseISO(c.sentAt) : c.sentAt,
      event: c,
    })),
  ].sort((a, b) => compareDesc(a.timestamp, b.timestamp));

  if (activities.length === 0) {
    return <p className="italic text-gray-700">No activity yet.</p>;
  }

  return (
    <ul role="list" className="-mb-8">
      {activities.map((activity, i) => (
        <li key={activity.event.id}>
          <div className="relative pb-4 text-gray-900">
            {i !== activities.length - 1 ? (
              <span
                className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />
            ) : null}
            <div className="relative flex items-start space-x-3">
              <ActivityFeedItem activity={activity} patient={patient} />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
