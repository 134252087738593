import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { useQueryParams } from "../../hooks";
import { gql, useQuery } from "@apollo/client";
import { formatUSD } from "../../utils";
import { format, parseISO } from "date-fns";
import {
  GetPrintStatementPatientBills,
  GetPrintStatementPatientBillsVariables,
  GetPrintStatementPatientBills_getPortalPatientBills_patient as Patient,
  GetPrintStatementPatientBills_getPortalPatientBills_unpaidBills as Bill,
  GetPrintStatementPatientBills_getPortalPatientBills_patient_location as Location,
  GetPrintStatementPatientBills_getPortalPatientBills_patient_organization as Organization,
} from "../../generated/GetPrintStatementPatientBills";

const GET_PRINT_STATEMENT_PATIENT_BILLS = gql`
  query GetPrintStatementPatientBills($id: String!) {
    getPortalPatientBills(patientId: $id) {
      patient {
        firstName
        lastName
        address1
        address2
        city
        state
        postalCode
        accountNumber
        location {
          name
          address1
          address2
          city
          state
          postalCode
          primaryEmail
          phone
          billingEmail
          billingPhone
          billingCheckPayable
          billingDefaultMessage
        }
        patientStatementBalance {
          readyBalance
        }
        organization {
          logoUrl
          primaryEmail
          phone
        }
      }
      unpaidBills {
        id
        dateOfService
        dateOfServiceDisplayLong
        dueDate
        toCollect {
          patientBalance
        }
        primaryProvider {
          displayName
        }
        charges {
          transaction {
            description
            paymentAllocations {
              amount
            }
          }
          chargeAmount
          allowedAmount
          insuranceAmount
          patientResponsibilityAmount
          copayAmount
          patientPaid
          patientBalance
        }
        billedCoverages {
          id
        }
      }
    }
  }
`;

const FIRST_PAGE_LINE_ITEM_ROW_NUM = 10;
const SECOND_PAGE_LINE_ITEM_ROW_NUM = 20;
const ALL_OTHER_PAGES_LINE_ITEM_ROW_NUM = 36;

type Coordinates = {
  billStart: number;
  billEnd: number;
  chargeStart: number;
  chargeEnd: number;
};

const BillHeader: React.FC<
  React.PropsWithChildren<{
    logoUrl: string | undefined;
    location: Location;
  }>
> = ({ logoUrl, location }) => (
  <div className="flex justify-between items-center pt-4 pr-4 pl-4">
    <div>
      <p className="text-stone-500 font-semibold">{location.name}</p>
      <p>
        {location.address1}, {location.address2}
      </p>
      <p>
        {location.city}, {location.state}, {location.postalCode}
      </p>
    </div>
    <div className="w-4/12 text-right">
      <p className="pb-2">
        Statement printed: {new Date().toLocaleDateString()}
      </p>
      <img
        className="float-right print:h-[0.8in] print:max-w-[3in]"
        src={logoUrl}
      />
    </div>
  </div>
);

const PatientInformation: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    location: Location;
    organization: Organization;
  }>
> = ({ patient, location, organization }) => (
  <div className="col-start-1 col-end-4 pl-4 pr-4 pb-4">
    <div className="font-semibold">
      <p>
        {patient.firstName} {patient.lastName}
      </p>
      <p>
        {patient.address1 ? patient.address1 + "," : ""} {patient.address2}
      </p>
      <p>
        {patient.city ? patient.city + "," : ""}{" "}
        {patient.state ? patient.state + "," : ""} {patient.postalCode}
      </p>
    </div>
    {location.billingEmail ||
    location.primaryEmail ||
    organization.primaryEmail ? (
      <div className="mt-7">
        <h2 className="text-indigo-500 text-2xl">Questions?</h2>
        <p>
          Email us at{" "}
          {location.billingEmail ||
            location.primaryEmail ||
            organization.primaryEmail}
        </p>
      </div>
    ) : null}
  </div>
);

const Appointment: React.FC<
  React.PropsWithChildren<{
    bill: Bill;
    chargeStart: number;
    chargeEnd: number;
    index: number;
  }>
> = ({ bill, chargeStart, chargeEnd, index }) => {
  const charges = bill.charges.slice(chargeStart, chargeEnd);

  return (
    <>
      <div className="pb-2">
        <div className="w-100 bg-indigo-100 pl-2 print:h-[.21in]">
          <p className="align-middle font-semibold">
            {bill.dateOfServiceDisplayLong} {chargeStart > 0 ? "(cont.)" : ""}
            {bill.primaryProvider?.displayName && (
              <span className="pl-1 font-normal text-sm">
                with {bill.primaryProvider?.displayName}
              </span>
            )}
          </p>
        </div>
      </div>
      <table className="text-xs w-full table-fixed">
        <tbody>
          {charges.map((charge) => {
            const patientPayments =
              charge.transaction.paymentAllocations.reduce(
                (acc, curr) => acc + curr.amount,
                0
              );
            return (
              <>
                <tr>
                  <td className="mt-3 w-80 px-3 text-left">
                    <div className="bottom-full print:h-[.26in]">
                      {charge.transaction.description}
                    </div>
                  </td>
                  <td className="px-3 text-right">
                    <div className="print:h-[.26in]">
                      {formatUSD(-charge.chargeAmount)}
                    </div>
                  </td>
                  <td className="px-3 text-right">
                    <div className="print:h-[.26in]">
                      {formatUSD(charge.chargeAmount - charge.allowedAmount)}
                    </div>
                  </td>
                  <td className="px-3 text-right">
                    <div className="print:h-[.26in]">
                      {formatUSD(charge.insuranceAmount)}
                    </div>
                  </td>
                  <td className="px-3 text-right">
                    <div className="print:h-[.26in]">
                      {formatUSD(-charge.patientPaid)}
                    </div>
                  </td>
                  <td className="px-3 text-right">
                    <div className="print:h-[.26in]">
                      {formatUSD(-charge.patientBalance)}
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const BillDetails: React.FC<
  React.PropsWithChildren<{
    bills: Bill[];
    coordinates: Coordinates;
  }>
> = ({ bills, coordinates }) => {
  const { billStart, billEnd, chargeEnd, chargeStart } = coordinates;
  const lastBill =
    billEnd === bills.length &&
    chargeEnd === bills[bills.length - 1].charges.length;
  return (
    <>
      <div className="pb-2">
        <table className="text-xs w-full table-fixed">
          <tr className="text-sm">
            <th className="w-80 px-3 text-left"></th>
            <th className="px-3 text-right">
              <div className="print:h-[.35in]">Charge Amount</div>
            </th>
            <th className="px-3 text-right">
              <div className="print:h-[.35in]">Insurance Covered</div>
            </th>
            <th className="px-3 text-right">
              <div className="print:h-[.35in]">
                Insurance
                <br /> Paid
              </div>
            </th>
            <th className="px-3 text-right">
              <div className="print:h-[.35in]">Past Patient Payments</div>
            </th>
            <th className="px-3 text-right">
              <div className="print:h-[.35in]">Patient Balance</div>
            </th>
          </tr>
        </table>
      </div>
      {bills.slice(billStart - 1, billEnd).map((bill, index) => {
        return (
          <>
            <Appointment
              // If we're processing first bill in batch, use chargeStart
              // If processing last bill, use chargeEnd
              chargeStart={index === 0 ? chargeStart : 0}
              chargeEnd={
                index === billEnd - billStart ? chargeEnd : bill.charges.length
              }
              bill={bill}
              key={index}
              index={index}
            />
          </>
        );
      })}
      {!lastBill ? (
        <p className="text-right text-xs pr-2">
          Your statement continues on the next page
        </p>
      ) : (
        <p className="text-right font-semibold pr-2">
          Total Patient Balance{" "}
          {formatUSD(
            bills.reduce((acc, curr) => acc + curr.toCollect.patientBalance, 0)
          )}
        </p>
      )}
    </>
  );
};

const PaymentInstructions: React.FC<
  React.PropsWithChildren<{
    location: Location;
  }>
> = ({ location }) => (
  <div className="p-2 border-t">
    <p className="text-l italic font-medium">
      A message from your billing team:
    </p>
    <p>{location.billingDefaultMessage}</p>
  </div>
);

const ViewAndPayOnline: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    total: string;
    location: Location;
    organization: Organization;
    accessCode: string;
  }>
> = ({ patient, total, location, accessCode, organization }) => (
  <div className="">
    <p className="pt-2 text-center border-b border-dashed text-xs">
      Detach lower portion and return in enclosed envelope
    </p>
    <div className="flex pr-2">
      <div className="h-1/2 w-7/12 pt-2 pr-4 pl-4">
        <div className="w-full h-full border-2 bg-indigo-100 border-indigo-100 overflow-hidden rounded-lg">
          <div className="p-2 flex items-center mb-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              height="20px"
              width="20px"
              version="1.1"
              viewBox="0 0 389.981 389.981"
              className="mr-2 ml-1"
            >
              <g>
                <path d="M145.407,248.988l-2.775,26.209c-0.224,2.115,0.46,4.225,1.883,5.806c1.422,1.581,3.449,2.483,5.576,2.483h79.301   c2.126,0,4.153-0.903,5.576-2.483c1.422-1.581,2.106-3.691,1.883-5.806l-2.775-26.209h98.777c15.374,0,27.881-12.507,27.881-27.881   V27.881C360.733,12.507,348.225,0,332.852,0H40.63C25.256,0,12.749,12.507,12.749,27.881v193.226   c0,15.374,12.507,27.881,27.881,27.881H145.407z M221.055,268.487h-62.629l2.064-19.499h58.5L221.055,268.487z M40.63,15h292.222   c7.103,0,12.881,5.778,12.881,12.881V197.99H27.749V27.881C27.749,20.778,33.527,15,40.63,15z M27.749,221.107v-8.117h317.983   v8.117c0,7.103-5.778,12.881-12.881,12.881H40.63C33.527,233.988,27.749,228.21,27.749,221.107z" />
                <path d="M332.645,333.876c1.36-2.24,1.453-5.027,0.244-7.352l-14.813-28.499c-1.291-2.483-3.856-4.041-6.655-4.041H62.061   c-2.798,0-5.364,1.558-6.655,4.041l-14.813,28.499c-1.208,2.325-1.116,5.112,0.244,7.352s3.791,3.607,6.411,3.607h278.986   C328.854,337.484,331.285,336.116,332.645,333.876z M59.599,322.484l7.016-13.499h240.251l7.017,13.499H59.599z" />
                <path d="M345.733,350.982h-12c-17.368,0-31.499,14.13-31.499,31.499c0,4.142,3.358,7.5,7.5,7.5h59.997c4.142,0,7.5-3.358,7.5-7.5   C377.232,365.113,363.102,350.982,345.733,350.982z M319.04,374.981c2.735-5.337,8.295-8.999,14.693-8.999h12   c6.398,0,11.958,3.662,14.694,8.999H319.04z" />
              </g>
            </svg>
            <h2 className="text-xl font-semibold text-black">
              View & Pay Online
            </h2>
          </div>
          <div className="pl-3 pb-2 flex justify-between items-center">
            <div>
              <p className="text-lg font-semibold mb-2">
                https://app.pledge.health/paymybill
              </p>
              <div className="flex flex-col">
                <span className="text-lg">
                  Access Code <span className="font-bold">{accessCode}</span>
                </span>
              </div>
            </div>
            <div className="pr-10 mb-5">
              <QRCodeSVG
                height="100"
                width="100"
                value={`${window.location.origin}/paymybill?accessCode=${accessCode}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ml-auto mr-auto mb-auto mt-8 w-5/12 border-indigo-200 border-2 rounded-xl">
        <div className="p-4">
          <p>
            <span className="font-semibold">Account Holder:</span>{" "}
            {patient.firstName} {patient.lastName}
          </p>
          <p>
            <span className="font-semibold">Access Code:</span> {accessCode}
          </p>
          <p>
            <span className="font-semibold">Statement Amount:</span> {total}
          </p>
        </div>
      </div>
    </div>
    <div className="ml-5 grid grid-cols-4">
      <div className="pl-4 pt-10 col-span-2">
        <p className="text-lg font-bold">{location.name}</p>
        <p className="text-lg">
          {location.address1}
          {location.address2 ? `, ${location.address2}` : ""}
        </p>
        <p className="text-lg">
          {location.city}, {location.state}, {location.postalCode}
        </p>
      </div>
      {location.billingPhone || location.phone || organization.phone ? (
        <div className="p-4 pl-10">
          <div>
            <div className="p-2 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                height="20px"
                width="20px"
                version="1.1"
                viewBox="0 0 202.592 202.592"
                className="mr-2 ml-1"
              >
                <g>
                  <g>
                    <path d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761    c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874    c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769    c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816    c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055    c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747    C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507    c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235    C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497    c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282    c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067    c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279    C193.5,169.446,193.31,173.537,190.683,176.164z" />
                  </g>
                </g>
              </svg>
              <h2 className="text-lg text-black">Pay By Phone</h2>
            </div>
            <div className="pl-3 pb-2 flex justify-between items-center">
              <div>
                <p className="mb-2">
                  Call{" "}
                  {location.billingPhone ||
                    location.phone ||
                    organization.phone}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-4"></div>
      )}
      {location.billingCheckPayable ? (
        <div className="p-4">
          <div>
            <div className="p-2 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -256 1850 1850"
                id="svg2989"
                version="1.1"
                width="20px"
                height="20px"
                className="mr-2 ml-1"
              >
                <g transform="matrix(1,0,0,-1,37.966102,1282.678)" id="g2991">
                  <path
                    d="m 1664,32 v 768 q -32,-36 -69,-66 -268,-206 -426,-338 -51,-43 -83,-67 -32,-24 -86.5,-48.5 Q 945,256 897,256 h -1 -1 Q 847,256 792.5,280.5 738,305 706,329 674,353 623,396 465,528 197,734 160,764 128,800 V 32 Q 128,19 137.5,9.5 147,0 160,0 h 1472 q 13,0 22.5,9.5 9.5,9.5 9.5,22.5 z m 0,1051 v 11 13.5 q 0,0 -0.5,13 -0.5,13 -3,12.5 -2.5,-0.5 -5.5,9 -3,9.5 -9,7.5 -6,-2 -14,2.5 H 160 q -13,0 -22.5,-9.5 Q 128,1133 128,1120 128,952 275,836 468,684 676,519 682,514 711,489.5 740,465 757,452 774,439 801.5,420.5 829,402 852,393 q 23,-9 43,-9 h 1 1 q 20,0 43,9 23,9 50.5,27.5 27.5,18.5 44.5,31.5 17,13 46,37.5 29,24.5 35,29.5 208,165 401,317 54,43 100.5,115.5 46.5,72.5 46.5,131.5 z m 128,37 V 32 q 0,-66 -47,-113 -47,-47 -113,-47 H 160 Q 94,-128 47,-81 0,-34 0,32 v 1088 q 0,66 47,113 47,47 113,47 h 1472 q 66,0 113,-47 47,-47 47,-113 z"
                    id="path2993"
                    inkscape:connector-curvature="0"
                    fill="#000000"
                  />
                </g>
              </svg>
              <h2 className="text-lg font-sembold text-black">Pay By Check</h2>
            </div>
            <div className="pl-3 pb-2 flex justify-between items-center">
              <div>
                <p className="mb-2">
                  Make checks payable to:{" "}
                  <span className="font-bold">
                    {location.billingCheckPayable}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-4"></div>
      )}
    </div>
  </div>
);

const AccountSummary: React.FC<
  React.PropsWithChildren<{
    dueDate: string | null;
    total: string;
    accessCode: string;
  }>
> = ({ dueDate, total, accessCode }) => {
  return (
    <div className="p-4 col-end-7 col-span-3">
      <div className="w-full border overflow-hidden rounded-lg">
        <div className="p-3 bg-indigo-200 text-right">
          <h2 className="text-lg font-semibold text-black">Account Summary</h2>
        </div>
        <div className="px-2 flex justify-between my-2">
          <span className="font-medium text-gray-600">Access Code</span>
          <span className="font-medium text-gray-600">{accessCode}</span>
        </div>
        {dueDate && (
          <div className="px-2 flex justify-between my-2">
            <span className="font-medium text-gray-600">Due Date</span>
            <span className="font-medium text-gray-600">
              {format(parseISO(dueDate), "MMMM do, yyyy")}
            </span>
          </div>
        )}
        <div className="px-2 flex justify-between my-2">
          <span className="text-xl font-semibold text-gray-800">
            Total Payment Due
          </span>
          <span className="text-xl font-semibold text-gray-800">{total}</span>
        </div>
      </div>
    </div>
  );
};

const findOldestBill = (bills: Bill[]) => {
  return bills.reduce((prev, current) =>
    new Date(prev.dateOfService) < new Date(current.dateOfService)
      ? prev
      : current
  );
};

const sortBills = (bills: Bill[]) => {
  return bills.toSorted(
    (a, b) =>
      new Date(a.dateOfService).getTime() - new Date(b.dateOfService).getTime()
  );
};

export const PaperStatement = () => {
  const query = useQueryParams();
  const patientId = query.get("patientId");
  const accessCode = query.get("accessCode");
  const { data: patientBillsData, loading: patientBillsLoading } = useQuery<
    GetPrintStatementPatientBills,
    GetPrintStatementPatientBillsVariables
  >(GET_PRINT_STATEMENT_PATIENT_BILLS, {
    variables: { id: patientId! },
  });

  if (patientBillsLoading || !patientBillsData) {
    return null;
  }

  const total = formatUSD(
    -patientBillsData.getPortalPatientBills.patient.patientStatementBalance
      .readyBalance
  );

  const sortedBills = sortBills(
    patientBillsData.getPortalPatientBills.unpaidBills
  );

  const findPageStart = (
    billNumberEnd: number,
    chargeNumberEnd: number,
    billChargeLength: number
  ) => ({
    // If we've reached end of bill, start at next bill and reset charge number
    nextPageBillStart:
      chargeNumberEnd === billChargeLength ? billNumberEnd + 1 : billNumberEnd,
    nextPageChargeStart:
      chargeNumberEnd === billChargeLength ? 0 : chargeNumberEnd,
  });

  const findPageEnd = (
    startingBillNum: number,
    startingBillChargeNum: number,
    maxEntries: number,
    bills: Bill[]
  ) => {
    let total = 0;
    let billNumber = startingBillNum - 1;
    let billChargeNumber = 0;
    let billChargeLength = 0;

    for (let i = startingBillNum - 1; i < bills.length; i++) {
      const currentBill = bills[i];

      total += 1;

      if (total >= maxEntries) {
        break;
      }

      billNumber += 1;

      billChargeNumber = startingBillNum - 1 === i ? startingBillChargeNum : 0;
      billChargeLength = currentBill.charges.length;

      loop2: for (
        let j = billChargeNumber;
        j < currentBill.charges.length;
        j++
      ) {
        total += 1;
        billChargeNumber += 1;
        if (total >= maxEntries) {
          break loop2;
        }
      }
    }
    return {
      billNumber: billNumber,
      billChargeNumber,
      billChargeLength,
    };
  };

  const findCoordinates = () => {
    const coordinates = [];
    let pageStart = 1;
    let chargeStart = 0;
    let pageNum = 0;

    const pageNumMap = [
      FIRST_PAGE_LINE_ITEM_ROW_NUM,
      SECOND_PAGE_LINE_ITEM_ROW_NUM,
    ];

    while (pageStart <= sortedBills.length) {
      const { billNumber, billChargeNumber, billChargeLength } = findPageEnd(
        pageStart,
        chargeStart,
        pageNumMap[pageNum]
          ? pageNumMap[pageNum]
          : ALL_OTHER_PAGES_LINE_ITEM_ROW_NUM,
        sortedBills
      );

      coordinates.push({
        billStart: pageStart,
        billEnd: billNumber,
        chargeStart: chargeStart,
        chargeEnd: billChargeNumber,
      });

      const { nextPageBillStart, nextPageChargeStart } = findPageStart(
        billNumber,
        billChargeNumber,
        billChargeLength
      );

      pageStart = nextPageBillStart;
      chargeStart = nextPageChargeStart;

      pageNum++;
    }

    return coordinates;
  };

  const [firstPageCoordinates, secondPageCoordinates, ...restOfCoordinates] =
    findCoordinates();

  return (
    <div className="mr-2 ml-2 font-[sans-serif]">
      <div className="print:h-[3.15in]">
        <BillHeader
          logoUrl={
            patientBillsData.getPortalPatientBills.patient.organization
              .logoUrl || undefined
          }
          location={patientBillsData.getPortalPatientBills.patient.location}
        />
        <div className="grid grid-cols-6 gap-4">
          <PatientInformation
            location={patientBillsData.getPortalPatientBills.patient.location}
            patient={patientBillsData.getPortalPatientBills.patient}
            organization={
              patientBillsData.getPortalPatientBills.patient.organization
            }
          />
          <AccountSummary
            dueDate={
              patientBillsData.getPortalPatientBills.unpaidBills.length > 0 &&
              findOldestBill(patientBillsData.getPortalPatientBills.unpaidBills)
                .dueDate
            }
            total={total}
            accessCode={accessCode!}
          />
        </div>
      </div>
      <div className="print:h-[4.1in]">
        <BillDetails
          coordinates={{
            billStart: firstPageCoordinates.billStart,
            billEnd: firstPageCoordinates.billEnd,
            chargeStart: firstPageCoordinates.chargeStart,
            chargeEnd: firstPageCoordinates.chargeEnd,
          }}
          bills={sortedBills}
        />
        {patientBillsData.getPortalPatientBills.patient.location
          .billingDefaultMessage && (
          <PaymentInstructions
            location={patientBillsData.getPortalPatientBills.patient.location}
          />
        )}
      </div>
      <div className="print:h-[3.6in]">
        <ViewAndPayOnline
          patient={patientBillsData.getPortalPatientBills.patient}
          total={total}
          location={patientBillsData.getPortalPatientBills.patient.location}
          organization={
            patientBillsData.getPortalPatientBills.patient.organization
          }
          accessCode={accessCode!}
        />
      </div>
      {secondPageCoordinates && (
        <div className="pt-2">
          <div className="print:h-[7in]">
            <BillDetails
              coordinates={{
                billStart: secondPageCoordinates.billStart,
                billEnd: secondPageCoordinates.billEnd,
                chargeStart: secondPageCoordinates.chargeStart,
                chargeEnd: secondPageCoordinates.chargeEnd,
              }}
              bills={sortedBills}
            />
          </div>
          <div className="print:h-[4in] print:w-[8.5in]"></div>
        </div>
      )}
      {restOfCoordinates &&
        restOfCoordinates.map(
          ({ billStart, billEnd, chargeStart, chargeEnd }, index) => (
            <div className="pt-2">
              <div className="print:h-[11in]">
                <BillDetails
                  bills={sortedBills}
                  coordinates={{
                    billStart: billStart,
                    billEnd: billEnd,
                    chargeStart: chargeStart,
                    chargeEnd: chargeEnd,
                  }}
                  key={index}
                />
              </div>
            </div>
          )
        )}
    </div>
  );
};
