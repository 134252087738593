import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { Fragment, useState } from "react";

import { Transition } from "@headlessui/react";
import { ArchiveIcon, PaperAirplaneIcon } from "@heroicons/react/outline";
import { Table } from "@tanstack/react-table";
import { parseISO } from "date-fns";
import * as RQ from "react-query";
import { toast } from "react-toastify";
import { useAnalytics } from "../../../analytics-context";
import { constants } from "../../../constants";
import {
  BulkToggleBillsOnHold,
  BulkToggleBillsOnHoldVariables,
} from "../../../generated/BulkToggleBillsOnHold";
import { BillState } from "../../../generated/globalTypes";
import { BULK_TOGGLE_BILLS_ON_HOLD } from "../../../graphql";
import { useFeatureFlags } from "../../../hooks";
import { useUser } from "../../../user-context";
import { isDefined } from "../../../utils";
import { HorizontalPadding } from "../../layout";
import {
  BatchUpdateBillsStatusConfirmationDialog,
  BillStats,
  BulkToggleBillsOnHoldConfirmationDialog,
  DownloadBillsButton,
  DownloadDelinquentPatientsDialog,
  DownloadSentPatientsDialog,
  isArchiveable,
  isPendable,
  isReadyable,
  isReviewable,
  isSendaable,
  SendBillsBatchDialog,
} from "../index"; // Import the existing BillStats component
import { BillsRow, columns } from "./columns";
import { DataTable } from "./table";

const BILL_LIST_FRAGMENT = gql`
  fragment BillListFragment on Bill {
    id
    dateOfService
    dateOfServiceDisplay
    status
    patientBalance
    allowedTotal
    patientResponsibility
    dueDate
    onHoldAt
    communicationStatus
    charges {
      id
      units
      allowedAmount
      insuranceAmount
      patientBalance
      transaction {
        id
        customCode
        description
        chargeAllocations {
          id
          createdAt
          amount
        }
      }
    }
    account {
      id
      accountType {
        id
        name
      }
      patient {
        id
        displayName
        autoPayEnabled
        # totalPatientUnallocatedCredits
        paymentIntents(
          where: { autoPay: { equals: true } }
          orderBy: [{ createdAt: desc }]
          take: 1
        ) {
          id
          lastPaymentError
        }
        reminderWorkflow {
          id
          nextReminderDate
          pausedAt
        }
      }
    }
    primaryProvider {
      id
      displayName
    }
    paymentRequestTargets {
      id
      paymentRequest {
        id
        status
        scheduledAt
      }
    }
    communications {
      id
      sentAt
      type
      contentType
      createdAt
      lastErrorType
      lastErrorReasonDisplay
    }
  }
`;

export const GET_LOCAL_BILLS = gql`
  ${BILL_LIST_FRAGMENT}
  query GetLocalBills($date: DateTime!) {
    localBills(date: $date) @client {
      ...BillListFragment
    }
  }
`;

const GET_LOCAL_IN_REVIEW_BILLS = gql`
  ${BILL_LIST_FRAGMENT}
  query GetLocalInReviewBills($date: DateTime!) {
    localInReviewBills(date: $date) @client {
      ...BillListFragment
    }
  }
`;

const GET_LOCAL_READY_BILLS = gql`
  ${BILL_LIST_FRAGMENT}
  query GetLocalReadyBills($date: DateTime!) {
    localReadyBills(date: $date) @client {
      ...BillListFragment
    }
  }
`;

const GET_LOCAL_ARCHIVED_BILLS = gql`
  ${BILL_LIST_FRAGMENT}
  query GetLocalArchivedBills($date: DateTime!) {
    localArchivedBills(date: $date) @client {
      ...BillListFragment
    }
  }
`;

const BillStatsList: React.FC<
  React.PropsWithChildren<{
    stats: {
      inReviewCount?: number;
      inReviewSum?: number;
    };
    table: Table<BillsRow>;
    loading: boolean;
    inReviewLoading: boolean;
    readyLoading: boolean;
  }>
> = ({ stats, table, loading, inReviewLoading, readyLoading }) => {
  // Filter functions
  const setReadyUnsentFilter = () => {
    table.getColumn("status")?.setFilterValue([BillState.Ready]);
    // Add additional filter for unsent bills
    table.setColumnFilters((prev) => [
      { id: "status", value: [BillState.Ready] },
      { id: "sent", value: ["Not Sent"] },
      { id: "scheduled", value: ["Not Scheduled"] },
    ]);
  };

  const setReadyScheduledFilter = () => {
    table.setColumnFilters((prev) => [
      { id: "status", value: [BillState.Ready] },
      { id: "scheduled", value: ["Scheduled"] },
    ]);
  };

  const setReadySentFilter = () => {
    table.setColumnFilters((prev) => [
      { id: "status", value: [BillState.Ready] },
      { id: "sent", value: ["Sent"] },
      { id: "scheduled", value: ["Not Scheduled"] },
      { id: "reminder", value: ["Not Enrolled"] },
    ]);
  };

  const setReadyRemindingFilter = () => {
    table.setColumnFilters((prev) => [
      { id: "status", value: [BillState.Ready] },
      { id: "sent", value: ["Sent"] },
      { id: "scheduled", value: ["Not Scheduled"] },
      { id: "reminder", value: ["Enrolled"] },
    ]);
  };

  const setRemindersExhaustedFilter = () => {
    table.setColumnFilters((prev) => [
      { id: "status", value: [BillState.Ready] },
      { id: "sent", value: ["Sent"] },
      { id: "scheduled", value: ["Not Scheduled"] },
      { id: "reminder", value: ["Exhausted"] },
    ]);
  };

  const setInReviewFilter = () => {
    table.setColumnFilters((prev) => [
      { id: "status", value: [BillState.InReview] },
      { id: "onHold", value: ["false"] },
    ]);
  };

  const setOnHoldFilter = () => {
    table.setColumnFilters((prev) => [
      { id: "status", value: [BillState.InReview] },
      { id: "onHold", value: ["true"] },
    ]);
  };

  return (
    <BillStats
      {...stats}
      loading={loading}
      inReviewLoading={inReviewLoading}
      readyLoading={readyLoading}
      setReadyUnsentFilter={setReadyUnsentFilter}
      setReadyScheduledFilter={setReadyScheduledFilter}
      setReadySentFilter={setReadySentFilter}
      setReadyRemindingFilter={setReadyRemindingFilter}
      setRemindersExhaustedFilter={setRemindersExhaustedFilter}
      setOnHoldFilter={setOnHoldFilter}
      setInReviewFilter={setInReviewFilter}
    />
  );
};

export const BottomDrawer: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    selectedRows: BillsRow[];
    totalCount: number;
    selectAll: () => void;
    deselect: () => void;
    allSelected: boolean;
    ActionButtons: React.ReactNode;
  }>
> = ({
  open,
  setOpen,
  selectedRows,
  totalCount,
  selectAll,
  allSelected,
  deselect,
  ActionButtons,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <div>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div className="flex h-full flex-col bg-indigo-600 text-white py-6 shadow-xl">
            <div className="px-4 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 pl-16">
                  <div className="contents gap-1">
                    {selectedRows.length} patients
                    <span className="text-gray-300">selected</span>
                  </div>
                  {!allSelected && (
                    <button
                      onClick={selectAll}
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                    >
                      Select All {totalCount} bills
                    </button>
                  )}
                  <button
                    onClick={deselect}
                    className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                  >
                    Deselect
                  </button>
                </div>
                <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                  {ActionButtons}
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

const DEFAULT_PAGE_SIZE = 1000;

export const BillsTable: React.FC = () => {
  const user = useUser()!;
  const apollo = useApolloClient();
  const flags = useFeatureFlags();
  const analytics = useAnalytics();

  const [sendBillsBatchDialogOpen, setSendBillsBatchDialogOpen] =
    useState(false);
  const [updateBillStatusModalOpen, setUpdateBillStatusModalOpen] =
    useState(false);
  const [
    exportDelinquentPatientsDialogOpen,
    setExportDelinquentPatientsDialogOpen,
  ] = useState(false);
  const [
    exportSentBillsPatientsDialogOpen,
    setExportSentBillsPatientsDialogOpen,
  ] = useState(false);
  const [activeModalBillStatus, setActiveModalBillStatus] =
    useState<BillState | null>();
  const [bulkToggleOnHoldModalOpen, setBulkToggleOnHoldModalOpen] =
    useState(false);
  const [bulkToggleOnHoldPutOnHold, setBulkToggleOnHoldPutOnHold] =
    useState(false);

  const [archivedPage, setArchivedPage] = useState<number | null>(null);
  const [archivedTotal, setArchivedTotal] = useState<number | null>(null);
  const [pendingPage, setPendingPage] = useState<number | null>(null);
  const [pendingTotal, setPendingTotal] = useState<number | null>(null);
  const [pendingPageSize, setPendingPageSize] = useState<number | null>(1000);
  const [archivedPageSize, setArchivedPageSize] = useState<number | null>(1000);

  const bulkToggleOnHold = async (putOnHold: boolean) => {
    setBulkToggleOnHoldPutOnHold(putOnHold);
    setBulkToggleOnHoldModalOpen(true);
  };

  const [bulkToggleBillsOnHold, bulkToggleBillsOnHoldResult] = useMutation<
    BulkToggleBillsOnHold,
    BulkToggleBillsOnHoldVariables
  >(BULK_TOGGLE_BILLS_ON_HOLD);

  const localBillsRes = useQuery(GET_LOCAL_BILLS);

  // Separate queries for in-review and ready bills
  const inReviewBillsResult = RQ.useQuery({
    queryKey: ["billsList", "InReview"],
    queryFn: () =>
      fetch(`${constants.VITE_GRAPHQL_URL}/api/bills?status=InReview`, {
        credentials: "include",
        headers: {
          "x-pledge-demo": window.localStorage.getItem("demo") ?? "false",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const data = {
            localBills: res.bills,
          };
          apollo.writeQuery({
            query: GET_LOCAL_BILLS,
            data,
          });
          apollo.cache.evict({ fieldName: "getPatientCredits" });
          apollo.cache.gc();
          return data;
        }),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const readyBillsResult = RQ.useQuery({
    queryKey: ["billsList", "Ready"],
    queryFn: () =>
      fetch(`${constants.VITE_GRAPHQL_URL}/api/bills?status=Ready`, {
        credentials: "include",
        headers: {
          "x-pledge-demo": window.localStorage.getItem("demo") ?? "false",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const data = {
            localBills: res.bills,
          };
          apollo.writeQuery({
            query: GET_LOCAL_BILLS,
            data,
          });
          return data;
        }),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  // Separate query for archived bills
  const archivedBillsResult = RQ.useQuery({
    queryKey: ["billsList", "Archived", archivedPage],
    queryFn: () =>
      fetch(
        `${constants.VITE_GRAPHQL_URL}/api/bills?status=Archived&page=${archivedPage}&pageSize=${archivedPageSize}`,
        {
          credentials: "include",
          headers: {
            "x-pledge-demo": window.localStorage.getItem("demo") ?? "false",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setArchivedTotal(res.pagination.total);
          const data = {
            localArchivedBills: res.bills,
          };
          apollo.writeQuery({
            query: GET_LOCAL_BILLS,
            data: {
              localBills: res.bills,
            },
          });
          return data;
        }),
    enabled: archivedPage !== null, // Only load when user explicitly requests archived bills
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  // Separate query for pending bills
  const pendingBillsResult = RQ.useQuery({
    queryKey: ["billsList", "Pending", pendingPage],
    queryFn: () =>
      fetch(
        `${constants.VITE_GRAPHQL_URL}/api/bills?status=Pending&page=${pendingPage}&pageSize=${pendingPageSize}`,
        {
          credentials: "include",
          headers: {
            "x-pledge-demo": window.localStorage.getItem("demo") ?? "false",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setPendingTotal(res.pagination.total);
          const data = {
            localBills: res.bills,
          };
          apollo.writeQuery({
            query: GET_LOCAL_BILLS,
            data,
          });
          return data;
        }),
    enabled: pendingPage !== null, // Only load when user explicitly requests pending bills
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const loadingMore =
    inReviewBillsResult.isFetching ||
    readyBillsResult.isFetching ||
    archivedBillsResult.isFetching ||
    pendingBillsResult.isFetching;

  // Combine the loading states based on which bills we're viewing
  // const currentStatus = table?.getColumn("status")?.getFilterValue();
  const currentStatus = BillState.InReview;
  const loading =
    currentStatus === BillState.InReview
      ? inReviewBillsResult.isLoading
      : currentStatus === BillState.Ready
      ? readyBillsResult.isLoading
      : inReviewBillsResult.isLoading || readyBillsResult.isLoading;

  const rows = localBillsRes.data?.localBills ?? [];
  const archivedBills = rows.filter(
    (b: any) => b.status === BillState.Archived
  );
  const pendingBills = rows.filter((b: any) => b.status === BillState.Pending);

  const tableData: BillsRow[] = rows.map((row) => {
    const patient = row.account.patient;
    const scheduled = row.paymentRequestTargets?.some(
      (prt) => prt.paymentRequest?.status === "Scheduled"
    );
    const enrolled =
      !!patient.reminderWorkflow?.id && !patient.reminderWorkflow?.pausedAt;
    const active = !!patient.reminderWorkflow?.nextReminderDate;
    const exhausted = enrolled && !active;
    const notEnrolled = !active && !exhausted;

    const reminder = notEnrolled
      ? "Not Enrolled"
      : exhausted
      ? "Exhausted"
      : "Enrolled";

    return {
      id: row.id,
      bill: row,
      patientName: row.account.patient.displayName.trim(),
      dateOfService: parseISO(row.dateOfService),
      status: row.status,
      communicationStatus: row.communicationStatus,
      patientBalance: -row.patientBalance,
      accountType: row.account.accountType?.name ?? null,
      dueDate: row.dueDate,
      onHoldAt: row.onHoldAt,
      allowedTotal: -row.allowedTotal,
      patientResponsibility: row.patientResponsibility,
      provider: row.primaryProvider,
      providerName: row.primaryProvider?.displayName ?? "",
      chargeCodes: row.charges
        .map((charge) => charge.transaction.customCode)
        .filter(isDefined),
      patient: row.account.patient,
      scheduled: scheduled ? "Scheduled" : "Not Scheduled",
      sent: row.dueDate ? "Sent" : "Not Sent",
      reminder,
    };
  });

  // Calculate stats from rows with proper types
  const stats = React.useMemo(() => {
    return rows.reduce(
      (
        acc: {
          inReviewCount: number;
          inReviewSum: number;
          onHoldCount: number;
          onHoldSum: number;
          unsentCount: number;
          unsentSum: number;
          scheduledCount: number;
          scheduledSum: number;
          sentCount: number;
          sentSum: number;
          remindingCount: number;
          remindingSum: number;
          exhaustedCount: number;
          exhaustedSum: number;
        },
        bill: any
      ) => {
        const amount = bill.patientBalance;

        if (bill.status === BillState.InReview) {
          if (bill.onHoldAt) {
            acc.onHoldCount = (acc.onHoldCount || 0) + 1;
            acc.onHoldSum = (acc.onHoldSum || 0) + amount;
          } else {
            acc.inReviewCount = (acc.inReviewCount || 0) + 1;
            acc.inReviewSum = (acc.inReviewSum || 0) + amount;
          }
        }

        if (bill.status === BillState.Ready) {
          // Not shared bills
          if (!bill.dueDate) {
            acc.unsentCount = (acc.unsentCount || 0) + 1;
            acc.unsentSum = (acc.unsentSum || 0) + amount;
          }
          // Scheduled bills
          else if (
            bill.paymentRequestTargets?.some(
              (prt: { paymentRequest?: { status: string } }) =>
                prt.paymentRequest?.status === "Scheduled"
            )
          ) {
            acc.scheduledCount = (acc.scheduledCount || 0) + 1;
            acc.scheduledSum = (acc.scheduledSum || 0) + amount;
          }
          // Sent bills without reminders
          else if (
            !bill.account.patient.reminderWorkflow ||
            bill.account.patient.reminderWorkflow.pausedAt
          ) {
            acc.sentCount = (acc.sentCount || 0) + 1;
            acc.sentSum = (acc.sentSum || 0) + amount;
          }
          // Bills with active reminders
          else if (bill.account.patient.reminderWorkflow?.nextReminderDate) {
            acc.remindingCount = (acc.remindingCount || 0) + 1;
            acc.remindingSum = (acc.remindingSum || 0) + amount;
          }
          // Bills with exhausted reminders
          else {
            acc.exhaustedCount = (acc.exhaustedCount || 0) + 1;
            acc.exhaustedSum = (acc.exhaustedSum || 0) + amount;
          }
        }

        return acc;
      },
      {
        inReviewCount: 0,
        inReviewSum: 0,
        onHoldCount: 0,
        onHoldSum: 0,
        unsentCount: 0,
        unsentSum: 0,
        scheduledCount: 0,
        scheduledSum: 0,
        sentCount: 0,
        sentSum: 0,
        remindingCount: 0,
        remindingSum: 0,
        exhaustedCount: 0,
        exhaustedSum: 0,
      }
    );
  }, [rows]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <DataTable
          data={tableData}
          columns={columns}
          loading={loading}
          loadingMore={loadingMore}
          onRowClick={(row) => {
            if (flags.tosCollectionEnabled && flags.chargesSupported) {
              analytics?.track("Bill Sidebar Opened", {
                billId: row.bill.id,
                organizationId: user.organization.id,
                organizationName: user.organization.name,
                locationId: user.activeLocation.id,
                locationName: user.activeLocation.name,
              });
              setSelectedBill(row);
            }
          }}
          defaultColumnFilters={[
            {
              id: "status",
              value: [BillState.InReview],
            },
            {
              id: "onHold",
              value: ["false"],
            },
          ]}
          onColumnFiltersChange={(filters) => {
            const statusFilter = filters.find((f) => f.id === "status")
              ?.value as BillState[] | undefined;
            const showingArchived = statusFilter?.includes(BillState.Archived);
            const showingPending = statusFilter?.includes(BillState.Pending);

            if (showingArchived && archivedPage === null) {
              setArchivedPage(0);
            }
            if (showingPending && pendingPage === null) {
              setPendingPage(0);
            }
          }}
          toolbarProps={{
            archivedBills:
              archivedBills.length > 0
                ? {
                    count: archivedBills.length,
                    total: archivedTotal ?? 0,
                    onLoadMore: () => {
                      setArchivedPage((archivedPage ?? 0) + 1);
                      setArchivedPageSize(DEFAULT_PAGE_SIZE);
                    },
                    onLoadAll: () => {
                      setArchivedPage(0);
                      setArchivedPageSize(null);
                    },
                    loading: loadingMore,
                  }
                : undefined,
            pendingBills:
              pendingBills.length > 0
                ? {
                    count: pendingBills.length,
                    total: pendingTotal ?? 0,
                    onLoadMore: () => {
                      setPendingPage((pendingPage ?? 0) + 1);
                      setPendingPageSize(DEFAULT_PAGE_SIZE);
                    },
                    onLoadAll: () => {
                      setPendingPage(0);
                      setPendingPageSize(null);
                    },
                    loading: loadingMore,
                  }
                : undefined,
          }}
        >
          {(table) => {
            const selectedRows = table.getSelectedRowModel().rows;

            const allChecked = table.getIsAllRowsSelected();

            const billTotal = table.getPaginationRowModel().rows.length;

            const allReadyableBills = selectedRows.every((r) =>
              isReadyable(r.original.bill)
            );
            const allReviewableBills = selectedRows.every((r) =>
              isReviewable(r.original.bill)
            );
            const allPendableBills = selectedRows.every((r) =>
              isPendable(r.original.bill)
            );
            const allArchivableBills = selectedRows.every((r) =>
              isArchiveable(r.original.bill)
            );
            const allSendableBills = selectedRows.every((r) =>
              isSendaable(r.original.bill)
            );
            const allHoldableBills = selectedRows.every(
              (r) => r.original.status === BillState.InReview
            );
            const allOnHoldBills =
              allHoldableBills &&
              selectedRows.every((row) => row.original.onHoldAt !== null);
            const allNotOnHoldBills =
              allHoldableBills &&
              selectedRows.every((row) => row.original.onHoldAt === null);

            const confirmBulkToggleOnHold = async () => {
              try {
                const { data } = await bulkToggleBillsOnHold({
                  variables: {
                    where: {
                      id: { in: selectedRows.map((row) => row.original.id) },
                    },
                    putOnHold: bulkToggleOnHoldPutOnHold,
                  },
                });

                if (data?.bulkToggleBillsOnHold.errors.length === 0) {
                  toast.success(
                    `Successfully ${
                      bulkToggleOnHoldPutOnHold ? "put" : "removed"
                    } bills ${bulkToggleOnHoldPutOnHold ? "on" : "from"} hold`
                  );
                  table.resetRowSelection();
                } else {
                  data?.bulkToggleBillsOnHold.errors.forEach((error) => {
                    toast.error(error.message);
                  });
                }
              } catch (error) {
                console.error("Error toggling bills on hold:", error);
                toast.error("An error occurred while updating bills");
              }
            };

            return (
              <>
                <HorizontalPadding>
                  <BillStatsList
                    stats={stats}
                    table={table}
                    loading={loading}
                    inReviewLoading={inReviewBillsResult.isLoading}
                    readyLoading={readyBillsResult.isLoading}
                  />
                </HorizontalPadding>

                {sendBillsBatchDialogOpen && (
                  <SendBillsBatchDialog
                    open={sendBillsBatchDialogOpen}
                    setOpen={setSendBillsBatchDialogOpen}
                    billFilterParams={{
                      id: { in: selectedRows.map((row) => row.original.id) },
                    }}
                    billTotal={billTotal}
                  />
                )}
                {updateBillStatusModalOpen && (
                  <BatchUpdateBillsStatusConfirmationDialog
                    open={updateBillStatusModalOpen}
                    setOpen={setUpdateBillStatusModalOpen}
                    billFilterParams={{
                      id: { in: selectedRows.map((row) => row.original.id) },
                    }}
                    deselectAll={() => {
                      table.resetRowSelection();
                      table.resetPageIndex();
                    }}
                    selected={allChecked ? billTotal : selectedRows.length}
                    billStatus={activeModalBillStatus!}
                  />
                )}
                {exportDelinquentPatientsDialogOpen && (
                  <DownloadDelinquentPatientsDialog
                    open={exportDelinquentPatientsDialogOpen}
                    setOpen={setExportDelinquentPatientsDialogOpen}
                  />
                )}
                {exportSentBillsPatientsDialogOpen && (
                  <DownloadSentPatientsDialog
                    open={exportSentBillsPatientsDialogOpen}
                    setOpen={setExportSentBillsPatientsDialogOpen}
                  />
                )}
                {bulkToggleOnHoldModalOpen && (
                  <BulkToggleBillsOnHoldConfirmationDialog
                    open={bulkToggleOnHoldModalOpen}
                    setOpen={setBulkToggleOnHoldModalOpen}
                    selected={allChecked ? billTotal : selectedRows.length}
                    putOnHold={bulkToggleOnHoldPutOnHold}
                    onConfirm={confirmBulkToggleOnHold}
                    loading={bulkToggleBillsOnHoldResult.loading}
                  />
                )}
                <div
                  className="fixed bottom-0 right-0 w-full"
                  style={{ zIndex: 2 }}
                >
                  <BottomDrawer
                    open={selectedRows.length > 0}
                    setOpen={() => table.resetRowSelection()}
                    selectedRows={selectedRows.map((r) => r.original)}
                    deselect={() => table.resetRowSelection()}
                    selectAll={() => table.toggleAllRowsSelected()}
                    allSelected={table.getIsAllRowsSelected()}
                    totalCount={table.getFilteredRowModel().rows.length}
                    ActionButtons={
                      <>
                        <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                          <DownloadBillsButton
                            getBillsVariables={{} as any} // Just use the selected row ids
                            selectedRows={selectedRows.map((r) => r.original)}
                            allSelected={false} // Set to false so it uses the selected row ids
                          />
                          {allPendableBills && (
                            <button
                              type="button"
                              onClick={() => {
                                setUpdateBillStatusModalOpen(true);
                                setActiveModalBillStatus(BillState.Pending);
                              }}
                              className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                            >
                              Mark as Pending
                            </button>
                          )}
                          {allReviewableBills && (
                            <button
                              type="button"
                              onClick={() => {
                                setUpdateBillStatusModalOpen(true);
                                setActiveModalBillStatus(BillState.InReview);
                              }}
                              className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                            >
                              Mark as In Review
                            </button>
                          )}
                          {allOnHoldBills && (
                            <button
                              type="button"
                              className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                              onClick={() => {
                                bulkToggleOnHold(false);
                              }}
                            >
                              Remove from Hold
                            </button>
                          )}
                          {allNotOnHoldBills && (
                            <button
                              type="button"
                              className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                              onClick={() => {
                                bulkToggleOnHold(true);
                              }}
                            >
                              Put on Hold
                            </button>
                          )}
                          {allReadyableBills && (
                            <button
                              type="button"
                              onClick={() => {
                                setUpdateBillStatusModalOpen(true);
                                setActiveModalBillStatus(BillState.Ready);
                              }}
                              className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                            >
                              Mark as Ready
                            </button>
                          )}
                          {allSendableBills && (
                            <button
                              type="button"
                              className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                              onClick={() => {
                                setSendBillsBatchDialogOpen(true);
                              }}
                            >
                              Send Bills{" "}
                              <PaperAirplaneIcon className="ml-1 w-4 h-4" />
                            </button>
                          )}
                          {allArchivableBills && (
                            <button
                              type="button"
                              className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                              onClick={() => {
                                setUpdateBillStatusModalOpen(true);
                                setActiveModalBillStatus(BillState.Archived);
                              }}
                            >
                              Archive <ArchiveIcon className="ml-1 w-4 h-4" />
                            </button>
                          )}
                        </div>
                      </>
                    }
                  />
                </div>
              </>
            );
          }}
        </DataTable>
      </div>
    </>
  );
};
