import { gql, useQuery } from "@apollo/client";
import { compareAsc, format, parseISO } from "date-fns";
import React from "react";

import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "../../../components/ui/table-helpers/data-table-column-header";
import {
  GetPatientLedger_bills as Bill,
  GetPatientLedger,
  GetPatientLedgerVariables,
  GetPatientLedger_transactions as Transaction,
} from "../../../generated/GetPatientLedger";
import { TransactionType } from "../../../generated/globalTypes";
import { useUser } from "../../../user-context";
import { formatAccounting, isDefined } from "../../../utils";
import { DataTable } from "./table";

export const GET_PATIENT_LEDGER = gql`
  query GetPatientLedger($patientId: String!) {
    transactions(
      where: { account: { is: { patientId: { equals: $patientId } } } }
      orderBy: [{ transactedAt: asc }, { id: asc }]
    ) {
      id
      transactedAt
      type
      patientAmount
      insuranceAmount
      customCode
      description
      account {
        id
        accountType {
          id
          name
        }
      }
      payment {
        id
        paymentIntent {
          id
        }
        transaction {
          id
          paymentAllocations {
            id
            amount
            chargeTransaction {
              id
              charge {
                id
                billId
              }
            }
          }
        }
      }
      charge {
        id
        billId
      }
    }
    bills(
      where: {
        account: { is: { patientId: { equals: $patientId } } }
        status: { in: [Estimated, Pending] }
      }
      orderBy: { dateOfService: { sort: asc, nulls: last } }
    ) {
      id
      dateOfService
      appointment {
        id
        start
      }
      toCollect {
        patientResponsibility
        chargeAmount
      }
      primaryProvider {
        id
        displayName
      }
      account {
        id
        accountType {
          id
          name
        }
      }
      billPayments {
        id
        amount
        paymentTransactionId
      }
      charges {
        id
        transaction {
          id
          chargeAllocations {
            id
            amount
            paymentTransactionId
          }
        }
      }
    }
  }
`;

export type PatientLedgerRow = {
  id: string;
  accountType: string | null;
  transactedAt: Date;
  transaction: Transaction | null;
  bill: Bill | null;
  pledgePayment: boolean;
  type: TransactionType | "Visit";
  patientAmount: number;
  customCode: string | null;
  description: string | null;
  balance: number;
  externalAmount: number;
  externalBalance: number;
};

export const columns: ColumnDef<PatientLedgerRow>[] = [
  {
    id: "id",
    accessorKey: "id",
    sortingFn: "basic",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="id" />
    ),
  },
  {
    id: "transactedAt",
    accessorKey: "transactedAt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell: ({ row }) => {
      return (
        <div className="[&:first-letter]:capitalize">
          {format(row.original.transactedAt, "MMM d, yyyy h:mm aa")}
        </div>
      );
    },
  },
  {
    id: "accountType",
    accessorKey: "accountType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Account" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "type",
    accessorKey: "type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Type" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "pledgePayment",
    accessorKey: "pledgePayment",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Pledge Payment" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "customCode",
    accessorKey: "customCode",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Code" />
    ),
  },
  {
    id: "description",
    accessorKey: "description",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Description" />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {row.original.description}{" "}
          {row.original.pledgePayment && (
            <>
              via <span className="text-indigo-500">Pledge</span>
            </>
          )}
        </div>
      );
    },
  },
  {
    id: "externalAmount",
    accessorKey: "externalAmount",
    header: ({ column }) => {
      return (
        <DataTableColumnHeader
          column={column}
          title="Transaction Amount"
          className="justify-end"
        />
      );
    },
    cell: ({ row }) => {
      return (
        <div className="text-right pr-2">
          {formatLedgerCurrency(-row.original.externalAmount)}
        </div>
      );
    },
  },
  {
    id: "externalBalance",
    accessorKey: "externalBalance",
    header: ({ column }) => {
      const user = useUser()!;
      return (
        <DataTableColumnHeader
          column={column}
          title={`${user.externalLedgerName} Balance`}
          className="justify-end"
        />
      );
    },
    cell: ({ row }) => {
      return (
        <div className="text-right pr-2">
          {formatLedgerCurrency(-row.original.externalBalance)}
        </div>
      );
    },
  },
  {
    id: "patientAmount",
    accessorKey: "patientAmount",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Applied Amount"
        className="justify-end"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-right pr-2">
          {formatLedgerCurrency(-row.original.patientAmount)}
        </div>
      );
    },
  },
  {
    id: "balance",
    accessorKey: "balance",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Balance"
        className="justify-end"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-right pr-2">
          {formatLedgerCurrency(-row.original.balance)}
        </div>
      );
    },
  },
];

const formatLedgerCurrency = (amount: number) => {
  const normalized = amount === 0 ? 0 : amount;
  return formatAccounting(normalized);
};

const getBillTimestamp = (bill: Bill) => {
  return bill.appointment
    ? parseISO(bill.appointment.start)
    : parseISO(bill.dateOfService);
};

export const PatientLedger: React.FC<{ patientId: string }> = ({
  patientId,
}) => {
  const { data, loading } = useQuery<
    GetPatientLedger,
    GetPatientLedgerVariables
  >(GET_PATIENT_LEDGER, {
    variables: {
      patientId,
    },
  });

  const transactions = data?.transactions ?? [];
  const bills = (data?.bills ?? []).filter((bill) =>
    isDefined(bill.dateOfService)
  );

  const estimatedBills = new Set(bills.map((bill) => bill.id));
  const transactionsNotInEstimatedBills = transactions.filter(
    (transaction) => !estimatedBills.has(transaction.charge?.billId ?? "")
  );

  const rows = [
    ...transactionsNotInEstimatedBills.map((transaction) => ({
      timestamp: parseISO(transaction.transactedAt),
      transaction,
    })),
    ...bills.map((bill) => ({
      timestamp: getBillTimestamp(bill),
      bill,
    })),
  ].sort((a, b) => compareAsc(a.timestamp, b.timestamp));

  let balance = 0;
  let externalBalance = 0;
  const tableData: PatientLedgerRow[] = rows.map((row) => {
    if ("transaction" in row) {
      const transaction = row.transaction;
      const pledgePayment = isDefined(transaction.payment?.paymentIntent);
      const patientAmount = transaction.patientAmount;

      return {
        id: transaction.id,
        transactedAt: parseISO(transaction.transactedAt),
        transaction,
        bill: null,
        accountType: transaction.account?.accountType?.name ?? null,
        type: transaction.type,
        customCode: transaction.customCode,
        description: transaction.description,
        patientAmount,
        externalAmount: transaction.patientAmount,
        balance: (balance += patientAmount),
        externalBalance: (externalBalance += transaction.patientAmount),
        pledgePayment,
      };
    } else {
      const bill = row.bill!;

      return {
        id: bill.id,
        transactedAt: getBillTimestamp(bill),
        transaction: null,
        bill,
        accountType: bill.account?.accountType?.name ?? null,
        type: "Visit",
        customCode: null,
        description:
          "Visit " +
          (bill.primaryProvider
            ? "with " + bill.primaryProvider.displayName
            : "") +
          " on " +
          format(getBillTimestamp(bill), "MMM d, yyyy"),
        patientAmount: -bill.toCollect.patientResponsibility,
        externalAmount: -bill.toCollect.chargeAmount,
        balance: (balance -= bill.toCollect.patientResponsibility),
        externalBalance: (externalBalance -= bill.toCollect.chargeAmount),
        pledgePayment: false,
      };
    }
  });

  return (
    <div className="flex flex-col gap-8">
      <DataTable
        data={tableData}
        columns={columns}
        loading={loading}
        defaultColumnFilters={[]}
      />
    </div>
  );
};
