import React from "react";
import { Layout } from "../../layout";
import { FeeScheduleMappingForm } from "./fee-schedule-mapping-form";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { Condition, Visit } from "../../../evaluate-rule";
import {
  GetFeeSchedules,
  GetFeeSchedulesVariables,
} from "../../../generated/GetFeeSchedules";
import { useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import { GET_FEESCHEDULES } from "../../fee-schedules";
import { GetFeeScheduleMapping_feeScheduleMapping as FeeScheduleMapping } from "../../../generated/GetFeeScheduleMapping";
import { useLocation } from "react-router-dom";

export const NewFeeScheduleRule: React.FC = () => {
  const user = useUser()!;
  const location = useLocation();
  const state: {
    feeScheduleMapping?: FeeScheduleMapping | null;
  } | null = location.state;
  const { data, loading, error } = useQuery<
    GetFeeSchedules,
    GetFeeSchedulesVariables
  >(GET_FEESCHEDULES, {
    variables: {
      locationId: user.activeLocation.id,
      pending: false,
    },
  });

  const feeSchedules = data?.feeSchedules ?? [];

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Create New Rule
          </h1>
        </div>
      }
      content={
        loading ? (
          <div className="flex flex-col gap-4">
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          </div>
        ) : (
          <FeeScheduleMappingForm
            feeScheduleMapping={state?.feeScheduleMapping ?? null}
            feeSchedules={feeSchedules}
          >
            {({ conditions }) => (
              <div>
                <BenefitAssignmentRuleSimulatorTable
                  conditions={conditions as Condition<keyof Visit>[]}
                />
              </div>
            )}
          </FeeScheduleMappingForm>
        )
      }
    />
  );
};
