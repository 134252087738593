import React from "react";
import { Layout, LoadingLayout } from "../../layout";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { Condition, Visit } from "../../../evaluate-rule";
import { gql, useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import { useParams } from "react-router-dom";
import {
  GetPreVisitMapping,
  GetPreVisitMappingVariables,
} from "../../../generated/GetPreVisitMapping";
import { PRE_VISIT_MAPPING_FIELDS } from "../../../graphql";
import { PreVisitMappingForm } from "./pre-visit-mapping-form";

const GET_PRE_VISIT_MAPPING = gql`
  ${PRE_VISIT_MAPPING_FIELDS}
  query GetPreVisitMapping($id: String!) {
    preVisitMapping(where: { id: $id }) {
      ...PreVisitMappingFields
    }
  }
`;

export const EditPreVisitRule: React.FC = () => {
  const params = useParams<{ id: string }>();
  const user = useUser()!;
  const { data, loading, error } = useQuery<
    GetPreVisitMapping,
    GetPreVisitMappingVariables
  >(GET_PRE_VISIT_MAPPING, {
    variables: {
      id: params.id!,
    },
  });

  const preVisitMapping = data?.preVisitMapping;

  if (loading || !preVisitMapping) {
    return <LoadingLayout />;
  }

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Edit Pre-Visit Rule
          </h1>
        </div>
      }
      content={
        loading ? (
          <div className="flex flex-col gap-4">
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          </div>
        ) : (
          <PreVisitMappingForm preVisitMapping={preVisitMapping}>
            {({ conditions }) => (
              <div>
                <BenefitAssignmentRuleSimulatorTable
                  conditions={conditions as Condition<keyof Visit>[]}
                />
              </div>
            )}
          </PreVisitMappingForm>
        )
      }
    />
  );
};
