import React from "react";
import { gql, useApolloClient, useQuery } from "@apollo/client";

import { Card } from "../../../components";
import { SlideOut } from "../../../components/slideout";
import { compareDesc, format, parseISO } from "date-fns";
import {
  formatRelativeDay,
  formatUSD,
  isDefined,
  mapNullable,
} from "../../../utils";
import {
  GetLedgerTransactionDetails,
  GetLedgerTransactionDetailsVariables,
} from "../../../generated/GetLedgerTransactionDetails";
import { VisitBillStatusIndicator, getBillStatus } from "../visits";
import { BILL_CHARGE_FIELDS } from "../../../graphql";
import { ChargeInsuranceState } from "../../../generated/globalTypes";
import {
  CheckCircleIcon,
  ClockIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/outline";
import { RefundPaymentDialog } from "../show";
import { Button } from "../../../components/ui/button";
import { VisitBillDisplayCard } from "../../shared/visit-bill-display-card";
import {
  GetLedgerBillDetails,
  GetLedgerBillDetailsVariables,
} from "../../../generated/GetLedgerBillDetails";
import { useUser } from "../../../user-context";
import { useFeatureFlags } from "../../../hooks";
import { PostPaymentButton } from "../../payments";
import { GET_PATIENT_LEDGER } from ".";

const GET_LEDGER_BILL_DETAILS = gql`
  ${BILL_CHARGE_FIELDS}
  query GetLedgerBillDetails($id: String!) {
    bill(where: { id: $id }) {
      id
      account {
        id
        patient {
          id
          insurancePolicies {
            id
            memberId
            payer {
              id
              name
            }
          }
        }
      }
      dateOfService
      status
      charges {
        id
        insuranceBillableCharges {
          id
          status
          accountCoverage {
            id
            insurancePolicy {
              id
            }
          }
        }
      }
      ...BillChargeFields
    }
  }
`;

const LedgerBillSlideout: React.FC<
  React.PropsWithChildren<{
    billId: string;
    onClose: () => void;
  }>
> = ({ billId, onClose }) => {
  const flags = useFeatureFlags();
  const { data, loading, error } = useQuery<
    GetLedgerBillDetails,
    GetLedgerBillDetailsVariables
  >(GET_LEDGER_BILL_DETAILS, {
    variables: { id: billId },
  });

  if (!data || loading) {
    return null;
  }

  const bill = data.bill!;

  const billStatus = getBillStatus(bill);

  return (
    <SlideOut
      open={true}
      onClose={onClose}
      title={
        <div className="flex flex-col">
          <div className="text-sm">
            {format(parseISO(bill.dateOfService), "MM/dd/yyyy")} Bill
          </div>
        </div>
      }
    >
      <div className="flex flex-col space-y-2">
        <Card>
          <div className="flex flex-col divide-y gap-2 w-full">
            <div>
              <h1 className="truncate text-lg font-medium text-gray-900">
                Bill Summary
              </h1>
            </div>
            <div className="pt-2">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
                {flags.chargesSupported && (
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Status
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <VisitBillStatusIndicator billStatus={billStatus} />
                    </dd>
                  </div>
                )}
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Provider
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {bill.primaryProvider?.displayName}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Account</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {bill.account.accountType?.name}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </Card>

        <VisitBillDisplayCard bill={bill} appointment={bill.appointment} />
      </div>
    </SlideOut>
  );
};

const GET_LEDGER_TRANSACTION_DETAILS = gql`
  query GetLedgerTransactionDetails($id: String!) {
    transaction(where: { id: $id }) {
      id
      transactedAt
      type
      patientAmount
      customCode
      description
      payment {
        id
        isPledgeRefund
        appliedAmount
        postingStatus
        postedAt
        appliedTargets {
          applied
          amount
          bill {
            id
            dateOfService
            primaryProvider {
              id
              displayName
            }
            appointment {
              id
              start
            }
          }
          transaction {
            id
            transactedAt
            description
          }
        }
        stripeConnectedAccount {
          id
          name
        }
        paymentIntent {
          id
          amount
          autoPay
          receiptCode
        }
        refundedByPayments {
          id
          transaction {
            id
            patientAmount
            transactedAt
            description
          }
        }
      }
      billPayments {
        id
      }
      charge {
        id
        customCode
        bill {
          id
          billCode
          dateOfService
          status
        }
        chargeAmount
        allowedAmount
        insuranceAmount
        patientResponsibilityAmount
        patientPaid
        patientBalance
        insuranceBillableCharges {
          id
          status
          billedAt
          paidAt
          accountCoverage {
            id
            priority
            insurancePolicy {
              id
              priority
              memberId
              payer {
                id
                name
              }
            }
          }
        }
        transaction {
          id
          chargeAllocations {
            id
            amount
            paymentTransaction {
              id
              transactedAt
              description
            }
          }
        }
      }

      adjustment {
        id
        type
      }
    }
  }
`;

export const ChargeInsuranceStateIndicator: React.FC<{
  chargeInsuranceState: ChargeInsuranceState;
}> = ({ chargeInsuranceState }) => {
  switch (chargeInsuranceState) {
    case ChargeInsuranceState.Pending:
      return (
        <div className="flex items-center gap-1.5 text-sm text-gray-700">
          <ClockIcon className="w-4 h-4 text-yellow-500" /> Pending
        </div>
      );
    case ChargeInsuranceState.Billed:
      return (
        <div className="flex items-center gap-1.5 text-sm text-gray-700">
          <PaperAirplaneIcon className="w-4 h-4" /> Archived
        </div>
      );
    case ChargeInsuranceState.Processed:
      return (
        <div className="flex items-center gap-1.5 text-sm text-gray-700">
          <CheckCircleIcon className="w-4 h-4 text-green-500" /> Processed
        </div>
      );
  }
};

const RefundPaymentDialogButton: React.FC<{
  paymentIntent: { id: string; autoPay: boolean; receiptCode: string };
}> = ({ paymentIntent }) => {
  const apollo = useApolloClient();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button variant="secondary" onClick={() => setOpen(true)}>
        Refund Payment
      </Button>
      <RefundPaymentDialog
        open={open}
        setOpen={() => setOpen(false)}
        paymentIntent={paymentIntent}
        onSuccess={() => {
          // In 3 seconds refetch the ledger
          setTimeout(() => {
            apollo.refetchQueries({
              include: [GET_PATIENT_LEDGER],
            });
          }, 3000);
        }}
      />
    </>
  );
};

export const LedgerTransactionSlideout: React.FC<
  React.PropsWithChildren<{
    transactionId: string;
    onClose: () => void;
  }>
> = ({ transactionId, onClose }) => {
  const user = useUser()!;
  const { data, loading, error } = useQuery<
    GetLedgerTransactionDetails,
    GetLedgerTransactionDetailsVariables
  >(GET_LEDGER_TRANSACTION_DETAILS, {
    variables: { id: transactionId },
  });

  if (!data || loading) {
    return null;
  }

  const transaction = data.transaction!;

  const isPayment =
    transaction.type === "Payment" ||
    (transaction.type === "Adjustment" && transaction.patientAmount > 0);
  const isCharge =
    transaction.type === "Charge" ||
    (transaction.type === "Adjustment" && transaction.patientAmount < 0);
  const isPledgePayment = isDefined(transaction.payment?.paymentIntent);

  const paymentAllocations = (transaction.payment?.appliedTargets ?? [])
    .map((target) => {
      if (target.bill) {
        return {
          date: parseISO(
            target.bill.appointment?.start ?? target.bill.dateOfService
          ),
          description: (
            <>Visit with {target.bill.primaryProvider?.displayName}</>
          ),
          amount: target.amount,
          applied: target.applied,
        };
      }
      if (target.transaction) {
        return {
          date: parseISO(target.transaction.transactedAt),
          description: target.transaction.description,
          amount: target.amount,
          applied: target.applied,
        };
      }
      return null;
    })
    .filter(isDefined)
    .sort((a, b) => compareDesc(a.date, b.date));

  const chargeAllocations =
    transaction.charge?.transaction?.chargeAllocations ?? [];

  const patientPaid = chargeAllocations.reduce((sum, a) => sum + a.amount, 0);

  const ledgerAmount =
    transaction.type === "Payment"
      ? transaction.patientAmount
      : -transaction.patientAmount;

  const billings = transaction.charge?.insuranceBillableCharges ?? [];

  const paymentRefunds = transaction.payment?.refundedByPayments ?? [];

  const refundable =
    !transaction.payment?.isPledgeRefund &&
    isDefined(transaction.payment?.paymentIntent);
  const postable =
    transaction.payment?.paymentIntent &&
    user.activeLocation.integrations.some((i) => i.supportsPrepaymentPosting) &&
    (transaction.payment.postingStatus === "Pending" ||
      transaction.payment.postingStatus === "Failed");

  return (
    <SlideOut
      open={true}
      onClose={onClose}
      title={
        <div className="flex flex-col">
          <div className="text-sm">
            {format(parseISO(transaction.transactedAt), "MM/dd/yyyy")}{" "}
            Transaction
          </div>
        </div>
      }
    >
      <div className="flex flex-col space-y-2">
        <Card>
          <div className="flex flex-col divide-y gap-2 w-full">
            <div className="flex justify-between items-center">
              <h1 className="truncate text-lg font-medium text-gray-900">
                Transaction Summary
              </h1>
              <div className="flex gap-2">
                {postable && (
                  <PostPaymentButton
                    paymentId={transaction.payment!.id}
                    variant="secondary"
                  >
                    Post payment to {user.externalLedgerName}
                  </PostPaymentButton>
                )}
                {refundable && (
                  <RefundPaymentDialogButton
                    paymentIntent={transaction.payment!.paymentIntent!}
                  />
                )}
              </div>
            </div>
            <div className="pt-2">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {transaction.type}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Description
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {transaction.description}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Transaction Amount
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {formatUSD(ledgerAmount)}
                  </dd>
                </div>
                {isPayment && (
                  <>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Applied Amount
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {isDefined(transaction.payment?.appliedAmount) ? (
                          formatUSD(transaction.payment.appliedAmount)
                        ) : (
                          <span className="text-gray-500 italic">N/A</span>
                        )}
                      </dd>
                    </div>
                    {transaction.payment?.paymentIntent && (
                      <>
                        {transaction.payment.postingStatus && (
                          <>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Posting Status
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {transaction.payment.postingStatus}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Posted At
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {mapNullable((d: string) =>
                                  format(parseISO(d), "MM/dd/yyyy hh:mm a")
                                )(transaction.payment.postedAt) ?? (
                                  <span className="text-gray-500 italic">
                                    Not posted yet
                                  </span>
                                )}
                              </dd>
                            </div>
                          </>
                        )}
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Receipt Number
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {transaction.payment.paymentIntent.receiptCode}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Autopay
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {transaction.payment.paymentIntent.autoPay
                              ? "Yes"
                              : "No"}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Payment Account
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {transaction.payment.stripeConnectedAccount
                              ?.name ?? (
                              <span className="text-gray-500 italic">N/A</span>
                            )}
                          </dd>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Source</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {isPledgePayment ? "Pledge" : "Imported"}
                  </dd>
                </div>
                {transaction.charge && (
                  <div className="grid grid-cols-3 col-span-2">
                    <div className="col-span-3 text-sm border-b pb-1 mb-1">
                      Charge Details
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Charge Amount
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatUSD(-transaction.charge.chargeAmount)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Allowed Amount
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatUSD(-transaction.charge.allowedAmount)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Insurance Paid
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatUSD(-transaction.charge.insuranceAmount)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Patient Resp
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatUSD(
                          -transaction.charge.patientResponsibilityAmount
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Patient Paid
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatUSD(patientPaid)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Patient Balance
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatUSD(-transaction.charge.patientBalance)}
                      </dd>
                    </div>
                  </div>
                )}
              </dl>
            </div>
          </div>
        </Card>

        {transaction.charge && billings.length > 0 && (
          <Card>
            <div className="flex flex-col divide-y gap-2 w-full">
              <div>
                <h1 className="truncate text-lg font-medium text-gray-900">
                  Claims
                </h1>
              </div>
              <div className="divide-y flex flex-col gap-2">
                {billings.map((billing) => {
                  return (
                    <div>
                      <div className="flex justify-between items-center">
                        <div>
                          <span className="font-semibold">
                            {billing.accountCoverage.insurancePolicy.payer.name}
                          </span>{" "}
                          <span className="text-gray-500">
                            ({billing.accountCoverage.insurancePolicy.memberId})
                          </span>
                        </div>
                        <div>
                          <ChargeInsuranceStateIndicator
                            chargeInsuranceState={billing.status}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 pt-1">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Billed On
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {mapNullable((date: string) =>
                              formatRelativeDay(parseISO(date))
                            )(billing.billedAt)}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Paid On
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {mapNullable((date: string) =>
                              formatRelativeDay(parseISO(date))
                            )(billing.paidAt)}
                          </dd>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Card>
        )}

        {isPayment && (
          <Card>
            <div className="flex flex-col divide-y gap-2 w-full">
              <div>
                <h1 className="truncate text-lg font-medium text-gray-900">
                  Charges paid towards
                </h1>
              </div>
              <table className="border w-full">
                <thead className="divide-x">
                  <th scope="col" className="text-sm p-1">
                    DOS
                  </th>
                  <th scope="col" className="text-sm p-1">
                    Description
                  </th>
                  <th scope="col" className="text-sm p-1 text-right">
                    Applied
                  </th>
                </thead>
                <tbody className="space-y-1 divide-y border">
                  {paymentAllocations.length === 0 && (
                    <tr>
                      <td colSpan={3} className="text-center text-gray-500">
                        No allocations
                      </td>
                    </tr>
                  )}
                  {paymentAllocations.map((allocation) => (
                    <tr className="divide-x">
                      <td className="p-1">
                        {format(allocation.date, "MM/dd/yyyy")}{" "}
                      </td>
                      <td className="p-1 truncate">{allocation.description}</td>
                      <td className="p-1 text-right font-medium">
                        {formatUSD(allocation.applied)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {paymentRefunds.length > 0 && (
                <div className="pt-2">
                  <div>
                    <h1 className="truncate text-lg font-medium text-gray-900">
                      Refunds
                    </h1>
                  </div>
                  <table className="border w-full">
                    <thead className="divide-x">
                      <th scope="col" className="text-sm p-1">
                        Transacted At
                      </th>
                      <th scope="col" className="text-sm p-1">
                        Description
                      </th>
                      <th scope="col" className="text-sm p-1 text-right">
                        Amount
                      </th>
                    </thead>
                    <tbody className="space-y-1 divide-y border">
                      {paymentRefunds.map((refund) => (
                        <tr className="divide-x">
                          <td className="p-1">
                            {mapNullable((d: string) =>
                              format(parseISO(d), "MM/dd/yyyy hh:mm a")
                            )(refund.transaction.transactedAt)}
                          </td>
                          <td className="p-1 truncate">
                            {refund.transaction.description}
                          </td>
                          <td className="p-1 text-right text-gray-700">
                            {formatUSD(refund.transaction.patientAmount)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Card>
        )}

        {transaction.charge && (
          <Card>
            <div className="flex flex-col divide-y gap-2 w-full">
              <div>
                <h1 className="truncate text-lg font-medium text-gray-900">
                  Payments towards this charge
                </h1>
              </div>
              <table className="border w-full">
                <thead className="divide-x">
                  <th scope="col" className="text-sm p-1">
                    DOS
                  </th>
                  <th scope="col" className="text-sm p-1">
                    Description
                  </th>
                  <th scope="col" className="text-sm p-1 text-right">
                    Amount
                  </th>
                </thead>
                <tbody className="space-y-1 divide-y border">
                  {chargeAllocations.length === 0 && (
                    <tr>
                      <td colSpan={3} className="text-center text-gray-500">
                        No allocations
                      </td>
                    </tr>
                  )}
                  {chargeAllocations.map((allocation) => (
                    <tr className="divide-x">
                      <td className="p-1">
                        {format(
                          parseISO(allocation.paymentTransaction.transactedAt),
                          "MM/dd/yyyy"
                        )}{" "}
                      </td>
                      <td className="p-1 truncate">
                        {allocation.paymentTransaction.description}
                      </td>
                      <td className="p-1 text-right text-gray-700">
                        {formatUSD(allocation.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        )}
      </div>
    </SlideOut>
  );
};

export const LedgerSlideout: React.FC<
  React.PropsWithChildren<{
    transactionId: string | null;
    billId: string | null;
    onClose: () => void;
  }>
> = ({ transactionId, billId, onClose }) => {
  if (transactionId) {
    return (
      <LedgerTransactionSlideout
        transactionId={transactionId}
        onClose={onClose}
      />
    );
  }
  if (billId) {
    return <LedgerBillSlideout billId={billId} onClose={onClose} />;
  }
  return null;
};
