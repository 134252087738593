import React from "react";
import { Route, Routes } from "react-router-dom";
import { StytchProvider, initStytch } from "@stytch/stytch-react";
import "react-toastify/dist/ReactToastify.css";

import { PatientUserProvider } from "./patient-user-context";
import { PatientAuthProvider } from "./patient-auth-context";
import { PatientBenefitsPage } from "./pages/benefits";
import { NotFound } from "../components/404";
import { PatientBillPortal } from "./pages/payments";
import { PatientIdentification } from "./pages/identification";
import { constants } from "../constants";
import { TermsOfServiceOrganization } from "./pages/terms-of-service";
import { PrivacyPolicyOrganization } from "./pages/privacy-policy";
import { PatientInsurancePolicyBenefitsPage } from "./pages/benefits/snapshot-display";

const stytch = initStytch(constants.VITE_STYTCH_PUBLIC_TOKEN);

export const PatientPortal: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <StytchProvider stytch={stytch}>
      <Routes>
        <Route
          path="benefits/:insurancePolicyId"
          element={
            <PatientAuthProvider>
              <PatientUserProvider>
                <PatientBenefitsPage />
              </PatientUserProvider>
            </PatientAuthProvider>
          }
        />
        <Route
          path=":organizationId/terms-of-service"
          element={<TermsOfServiceOrganization />}
        />
        <Route
          path=":organizationId/privacy-policy"
          element={<PrivacyPolicyOrganization />}
        />
        <Route
          path=":organizationId/benefits/:insurancePolicyId"
          element={
            <PatientAuthProvider>
              <PatientUserProvider>
                <PatientInsurancePolicyBenefitsPage />
              </PatientUserProvider>
            </PatientAuthProvider>
          }
        />
        <Route
          path=":organizationId/:patientId/*"
          element={<PatientBillPortal />}
        />
        <Route path=":organizationId" element={<PatientIdentification />} />
        <Route
          path="*"
          element={
            <div className="min-h-screen flex flex-col justify-center">
              <NotFound />
            </div>
          }
        ></Route>
      </Routes>
    </StytchProvider>
  );
};
