import { Fragment, useState } from "react";
import { SettingsLayout } from ".";
import {
  GetMyOrganization_me_organization as Organization,
  GetMyOrganization_me_organization_integrations as Integration,
  GetMyOrganization_me_organization_integrations_charmIntegrationMetadata as CharmIntegrationMetadata,
} from "../../generated/GetMyOrganization";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../../components/ui/dropdown-menu";
import { formatDistanceToNow, parseISO } from "date-fns";
import { Card, SubmitButton } from "../../components";
import { Button } from "../../components/ui/button";
import { isDefined } from "../../utils";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Transition, Dialog } from "@headlessui/react";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { CopyToClipboardButton } from "../patients/eligibilities/full-eligiblity-display";
import { IntegrationType } from "../../generated/globalTypes";

const UPDATE_CHARM_INTEGRATION_API_KEY = gql`
  mutation UpdateCharmIntegrationApiKey(
    $integrationId: String!
    $apiKey: String!
  ) {
    updateCharmIntegrationApiKey(
      integrationId: $integrationId
      apiKey: $apiKey
    ) {
      id
      type
      name
      location {
        id
        name
      }
      integrationSyncs(take: 1, orderBy: { createdAt: desc }) {
        id
        createdAt
      }
      charmIntegrationMetadata {
        linked
        hasApiKey
        hasRefreshToken
        clientId
        authorizationUrl
      }
    }
  }
`;

const isCharmIntegration = (
  integration: Integration
): integration is Integration & {
  charmIntegrationMetadata: CharmIntegrationMetadata;
} =>
  integration.type === IntegrationType.Charm &&
  isDefined(integration.charmIntegrationMetadata);

const CharmApiKeyDialog: React.FC<
  React.PropsWithChildren<{
    integration: Integration & {
      charmIntegrationMetadata: CharmIntegrationMetadata;
    };
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ integration, open, setOpen }) => {
  const [updateCharmIntegrationApiKey, updateCharmIntegrationApiKeyResult] =
    useMutation<
      UpdateCharmIntegrationApiKey,
      UpdateCharmIntegrationApiKeyVariables
    >(UPDATE_CHARM_INTEGRATION_API_KEY);
  const [apiKey, setApiKey] = useState<string | null>(null);

  const onSubmit = async () => {
    updateCharmIntegrationApiKey({
      variables: {
        integrationId: integration.id,
        apiKey: apiKey!,
      },
      onCompleted: (data) => {
        toast.success("Api key updated");
      },
      onError: () => {
        toast.error("Error updating Api key");
      },
    });
  };

  const valid = isDefined(apiKey);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Set API Key
                    </Dialog.Title>
                    <div className="flex flex-col gap-2 mt-2">
                      <input
                        type="text"
                        onChange={(e) => setApiKey(e.target.value)}
                        placeholder="Add the Charm API Key"
                        className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-sm text-gray-500 italic pt-2">
                  <ul className="list-disc list-inside">
                    <li>Login in to the Practice Admin Account.</li>
                    <li>
                      Go to 'Settings' {">"} 'Account' {">"} 'API Keys' {">"}{" "}
                      'Add New Key' section.
                    </li>
                    <li>
                      Enter the Developer Name as "Pledge Health", enter the
                      Client ID
                      <div className="flex justify-center items-center gap-2 p-1 bg-gray-100 rounded-md border border-gray-200">
                        <span className="font-medium text-gray-900">
                          {integration.charmIntegrationMetadata.clientId}
                        </span>
                        <CopyToClipboardButton
                          copyText={
                            integration.charmIntegrationMetadata.clientId
                          }
                        />
                      </div>
                      and click "Add".
                    </li>
                    <li>
                      The API Key will be created and displayed on the page.
                    </li>
                  </ul>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <SubmitButton
                    type="button"
                    onClick={onSubmit}
                    loading={updateCharmIntegrationApiKeyResult.loading}
                    disabled={!valid}
                  >
                    Save
                  </SubmitButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const CharmApiKeyDialogButton: React.FC<{
  integration: Integration & {
    charmIntegrationMetadata: CharmIntegrationMetadata;
  };
}> = ({ integration }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={() => setOpen(true)}
      >
        Add API Key
      </button>
      {open && (
        <CharmApiKeyDialog
          integration={integration}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

const IntegrationActionMenu: React.FC<{
  integration: Integration;
}> = ({ integration }) => {
  const [charmApiKeyDialogOpen, setCharmApiKeyDialogOpen] = useState(false);
  // For now only show menu for Charm
  if (!isCharmIntegration(integration)) return null;
  // For now only action is updating api key so hide if no api key set
  if (!integration.charmIntegrationMetadata?.hasApiKey) return null;
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="sm" className="h-8" type="button">
            <DotsVerticalIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[150px]">
          <DropdownMenuItem
            onClick={() => {
              setCharmApiKeyDialogOpen(true);
            }}
          >
            Update Api Key
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {charmApiKeyDialogOpen && (
        <CharmApiKeyDialog
          integration={integration}
          open={charmApiKeyDialogOpen}
          setOpen={setCharmApiKeyDialogOpen}
        />
      )}
    </>
  );
};

export const IntegrationSection: React.FC<
  React.PropsWithChildren<{
    organization: Organization;
  }>
> = ({ organization }) => {
  const integrations = organization.integrations;
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 col-span-2">
      {integrations.map((integration) => {
        const lastSyncedAt = integration.integrationSyncs?.at(0)?.createdAt;
        const isCharm = isCharmIntegration(integration);
        const charmMetadata = integration.charmIntegrationMetadata;
        const isLinked = isCharm ? charmMetadata?.linked === true : true;
        const hasCharmApiKey = isCharm
          ? charmMetadata?.hasApiKey === true
          : false;
        const hasCharmRefreshToken = isCharm
          ? charmMetadata?.hasRefreshToken === true
          : false;
        return (
          <Card key={integration.id}>
            <div className="flex flex-col gap-2 text-center justify-center w-full p-2">
              <div className="flex justify-between gap-2">
                <div className="flex-1"></div>

                <div className="flex-1 text-lg font-medium">
                  {integration.name}
                </div>

                <div className="flex-1 flex justify-end">
                  <IntegrationActionMenu integration={integration} />
                </div>
              </div>
              {integration.location && (
                <span className="text-gray-600 text-sm">
                  {integration.location.name}
                </span>
              )}
              {isLinked ? (
                <div className="flex items-center justify-center gap-1">
                  <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                    <div className="h-2 w-2 rounded-full bg-emerald-500" />
                  </div>
                  Account Linked
                </div>
              ) : (
                <div className="flex items-center justify-center gap-1">
                  <div className="flex-none rounded-full bg-gray-500/20 p-1">
                    <div className="h-2 w-2 rounded-full bg-gray-500" />
                  </div>
                  Unlinked
                </div>
              )}
              {lastSyncedAt && (
                <span className="text-gray-600 text-sm">
                  Last synced{" "}
                  {formatDistanceToNow(parseISO(lastSyncedAt), {
                    addSuffix: true,
                  })}
                  .
                </span>
              )}
              {isCharm && (
                <>
                  {hasCharmRefreshToken === false && (
                    <div className="flex justify-center">
                      <a
                        href={charmMetadata?.authorizationUrl}
                        className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Link Charm Account
                      </a>
                    </div>
                  )}
                  {hasCharmRefreshToken === false &&
                    hasCharmApiKey === false && <>then</>}
                  {hasCharmApiKey === false && (
                    <div className="flex justify-center">
                      <CharmApiKeyDialogButton integration={integration} />
                    </div>
                  )}
                </>
              )}
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export const WorkspaceIntegrationSettings: React.FC<{
  organization: Organization;
}> = ({ organization }) => {
  return (
    <SettingsLayout
      title="Integrations"
      description="Manage your integration settings."
    >
      <IntegrationSection organization={organization} />
    </SettingsLayout>
  );
};
