import React from "react";

import { classNames } from "../utils";

type Variant = "success" | "warning" | "error" | "info";

const variantToColors = (variant: Variant, clickable?: boolean) => {
  switch (variant) {
    case "success":
      return `bg-green-100 text-green-800 ring-green-500/10 ${
        clickable ? "hover:bg-green-200" : ""
      }`;
    case "warning":
      return `bg-yellow-100 text-yellow-800 ring-yellow-500/10 ${
        clickable ? "hover:bg-yellow-200" : ""
      }`;
    case "error":
      return `bg-red-100 text-red-800 ring-red-500/10 ${
        clickable ? "hover:bg-red-200" : ""
      }`;
    case "info":
      return `bg-gray-100 text-gray-600 ring-gray-500/10 ${
        clickable ? "hover:bg-gray-200" : ""
      }`;
  }
};

export const Badge: React.FC<React.PropsWithChildren<{
  text: string;
  variant: Variant;
  className?: string;
  clickable?: boolean;
}>> = ({ text, variant, className, clickable }) => (
  <span
    className={classNames(
      // "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
      "truncate inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
      variantToColors(variant, clickable),
      className || ""
    )}
  >
    {text}
  </span>
);
