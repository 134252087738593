import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon } from "@heroicons/react/outline";

import { User } from "../auth-context";
import { classNames } from "../utils";
import { Link } from "react-router-dom";
import { DemoToggle } from "./demo-toggle";

export const Header: React.FC<React.PropsWithChildren<{
  user: User;
  logout: () => void;
  setSidebarOpen: (open: boolean) => void;
}>> = ({ user, logout, setSidebarOpen }) => (
  <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow sm:hidden print:hidden">
    <button
      type="button"
      className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      onClick={() => setSidebarOpen(true)}
    >
      <span className="sr-only">Open sidebar</span>
      <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
    </button>
    <div className="flex-1 px-4 flex justify-end">
      <div className="ml-4 flex items-center sm:ml-6">
        {/* Profile dropdown */}
        <Menu as="div" className="ml-3 relative">
          <div>
            <Menu.Button className="max-w-xs flex items-center text-lg text-indigo-500 hover:bg-gray-100 px-4 py-2 rounded-lg">
              <span className="sr-only">Open user menu</span>
              {[user.firstName, user.lastName].join(" ") || user.email}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              {user.isAdmin ? (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/settings"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-2 py-2 text-sm text-gray-700 w-full text-center"
                      )}
                    >
                      Settings
                    </Link>
                  )}
                </Menu.Item>
              ) : null}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={logout}
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700 w-full"
                    )}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                <DemoToggle />
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  </div>
);
