import { gql, useMutation } from "@apollo/client";
import {
  CheckIcon,
  ClockIcon,
  ExternalLinkIcon,
  PauseIcon,
  PlayIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  formatDistanceToNow,
  isAfter,
  isBefore,
  parseISO,
  format,
} from "date-fns";
import { BellIcon, CircleDashed, MoreHorizontal } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { WorkflowStepStatus, WorkflowStepStatusDisplay } from ".";
import { Card, Tooltip, Badge } from "../../../../components";
import { OvalSpinner } from "../../../../components/loading";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import { ValidationStatus } from "../../../../generated/globalTypes";
import {
  PauseAppointmentPreVisitReminder,
  PauseAppointmentPreVisitReminderVariables,
} from "../../../../generated/PauseAppointmentPreVisitReminder";
import {
  ResumeAppointmentPreVisitReminder,
  ResumeAppointmentPreVisitReminderVariables,
} from "../../../../generated/ResumeAppointmentPreVisitReminder";
import {
  SendPreVisitReminder,
  SendPreVisitReminderVariables,
} from "../../../../generated/SendPreVisitReminder";
import { useFeatureFlags } from "../../../../hooks";
import { useUser } from "../../../../user-context";
import { isDefined, mapNullable, formatUSD } from "../../../../utils";
import { AppointmentsRow } from "../columns";

export const SEND_PRE_VISIT_REMINDER = gql`
  mutation SendPreVisitReminder($appointmentId: String!) {
    sendPreVisitReminder(appointmentId: $appointmentId) {
      id
      mostRecentPreVisitReminder {
        id
        type
        contentType
        handle
        sentAt
        visitCollectionRequestId
      }
    }
  }
`;

export const PAUSE_APPOINTMENT_PRE_VISIT_REMINDER = gql`
  mutation PauseAppointmentPreVisitReminder(
    $appointmentId: String!
    $preVisitReminderPausedAt: DateTime!
  ) {
    updateOneAppointment(
      where: { id: $appointmentId }
      data: { preVisitReminderPausedAt: { set: $preVisitReminderPausedAt } }
    ) {
      id
      preVisitReminderPausedAt
      account {
        id
        patient {
          id
          preVisitReminderPausedAt
        }
      }
    }
  }
`;

export const RESUME_APPOINTMENT_PRE_VISIT_REMINDER = gql`
  mutation ResumeAppointmentPreVisitReminder($appointmentId: String!) {
    updateOneAppointment(
      where: { id: $appointmentId }
      data: { preVisitReminderPausedAt: { set: null } }
    ) {
      id
      preVisitReminderPausedAt
      account {
        id
        patient {
          id
          preVisitReminderPausedAt
        }
      }
    }
  }
`;

const PAUSE_PATIENT_PRE_VISIT_REMINDER = gql`
  mutation PausePatientPreVisitReminder(
    $patientId: String!
    $preVisitReminderPausedAt: DateTime!
  ) {
    updateOnePatient(
      where: { id: $patientId }
      data: { preVisitReminderPausedAt: { set: $preVisitReminderPausedAt } }
    ) {
      id
      preVisitReminderPausedAt
    }
  }
`;

const RESUME_PATIENT_PRE_VISIT_REMINDER = gql`
  mutation ResumePatientPreVisitReminder($patientId: String!) {
    updateOnePatient(
      where: { id: $patientId }
      data: { preVisitReminderPausedAt: { set: null } }
    ) {
      id
      preVisitReminderPausedAt
    }
  }
`;

const PreVisitChecklist: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const user = useUser()!;

  const patient = row.account.patient;
  const cardOnFileEnabled =
    user.activeLocation.preVisitCardOnFileInput !== "Hidden";
  const financialPolicyEnabled =
    user.activeLocation.preVisitFinancialPolicyInput !== "Hidden";
  const cardOnFileComplete =
    row.account.patient.paymentMethods.filter((pm) => !pm.detatchedAt).length >
    0;
  const financialPolicyComplete =
    !row.account.patient.financialPolicyConsentRequired;
  const communication = row.appointment.mostRecentPreVisitReminder;
  const otherPatientAppointmentCommunications =
    row.otherPatientAppointments.some((a) =>
      isDefined(a.mostRecentPreVisitReminder?.sentAt)
    );
  const sent =
    isDefined(communication?.sentAt) || otherPatientAppointmentCommunications;
  const checkedIn = isDefined(row.appointment.checkedInAt);

  const completed: { name: string; status: ValidationStatus }[] = [];
  const missing: { name: string; status: ValidationStatus }[] = [];
  if (cardOnFileEnabled) {
    if (cardOnFileComplete) {
      completed.push({
        name: "Save payment method",
        status: user.activeLocation.preVisitCardOnFileInput,
      });
    } else {
      missing.push({
        name: "Save payment method",
        status: user.activeLocation.preVisitCardOnFileInput,
      });
    }
  }
  if (financialPolicyEnabled) {
    if (financialPolicyComplete) {
      completed.push({
        name: "Consent to financial policy",
        status: user.activeLocation.preVisitFinancialPolicyInput,
      });
    } else {
      missing.push({
        name: "Consent to financial policy",
        status: user.activeLocation.preVisitFinancialPolicyInput,
      });
    }
  }
  if (sent) {
    if (checkedIn) {
      completed.push({
        name: "Review Estimate",
        status: ValidationStatus.Optional,
      });
    } else {
      missing.push({
        name: "Review Estimate",
        status: ValidationStatus.Optional,
      });
    }
  }

  const patientPreVisitReminderPaused = isDefined(
    row.account.patient.preVisitReminderPausedAt
  );
  const appointmentPreVisitReminderPaused = isDefined(
    row.appointment.preVisitReminderPausedAt
  );
  const preVisitReminderPaused =
    patientPreVisitReminderPaused || appointmentPreVisitReminderPaused;

  const preVisitReminderMapping = row.appointment.preVisitReminderMapping;

  const hasPreVisitReminderReason =
    patient.estimateCommunicationEnrolled ||
    !patient.sentPreVisitReminder ||
    patient.missingRequiredPreVisitFields;

  return (
    <div className="flex flex-col divide-y border-t my-1 text-sm">
      {status === "skipped" && !preVisitReminderMapping && (
        <div className="py-1">
          No pre-visit reminder rule matched, skipping.{" "}
          <Link
            to={{
              pathname: "/rules",
              hash: "#pre-visits",
            }}
            className="text-indigo-500 hover:underline"
          >
            View pre-visit reminder rules.
          </Link>
        </div>
      )}

      {hasPreVisitReminderReason && (
        <div className="py-1">
          <div>Pre-visit Reminder Reasons</div>
          <ul className="list-disc list-inside pl-1">
            {preVisitReminderMapping && (
              <li>
                <Link
                  to={`/rules/pre-visits/${preVisitReminderMapping.id}/edit`}
                  className="text-indigo-500 hover:underline"
                >
                  {preVisitReminderMapping.name}
                </Link>{" "}
                pre-visit reminder rule matched
              </li>
            )}
            {patient.estimateCommunicationEnrolled && (
              <li>Patient opted-in to be sent estimates</li>
            )}
            {!patient.sentPreVisitReminder && (
              <li>Never been sent pre-visit reminder</li>
            )}
            {patient.missingRequiredPreVisitFields && (
              <li>Missing required information</li>
            )}
          </ul>
        </div>
      )}
      <div className="grid gap-2 py-1">
        <div className="flex items-center justify-between gap-2">
          <h4 className="font-semibold">Completed Tasks</h4>
          <span className="text-muted-foreground text-sm">
            {completed.length}/{completed.length + missing.length}
          </span>
        </div>
        <div className="grid gap-2">
          {completed.map((task) => (
            <div className="flex items-center justify-between gap-1">
              <span className="text-sm">{task.name}</span>
              <CheckIcon className="w-4 h-4 text-green-500" />
            </div>
          ))}
          {missing.map((task) => {
            if (task.status === "Required") {
              return (
                <div className="flex items-center justify-between gap-1">
                  <div>
                    <span className="text-sm">{task.name}</span>
                    <span className="text-xs text-red-500"> (Required)</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <XIcon className="w-4 h-4 text-red-500" />
                  </div>
                </div>
              );
            }
            if (task.status === "Optional") {
              return (
                <div className="flex items-center justify-between gap-1">
                  <div>
                    <span className="text-sm">{task.name}</span>
                    <span className="text-xs text-yellow-500"> (Optional)</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <XIcon className="w-4 h-4 text-yellow-500" />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      {preVisitReminderPaused && (
        <div className="text-sm text-gray-500">
          Pre-visit reminders paused{" "}
          {patientPreVisitReminderPaused
            ? "for patient"
            : "for this appointment"}
        </div>
      )}
    </div>
  );
};

export const PreVisitReminderEnabledDialogButton: React.FC<{
  row: AppointmentsRow;
}> = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pauseAppointmentPreVisitReminder] = useMutation<
    PauseAppointmentPreVisitReminder,
    PauseAppointmentPreVisitReminderVariables
  >(PAUSE_APPOINTMENT_PRE_VISIT_REMINDER);
  const [resumeAppointmentPreVisitReminder] = useMutation<
    ResumeAppointmentPreVisitReminder,
    ResumeAppointmentPreVisitReminderVariables
  >(RESUME_APPOINTMENT_PRE_VISIT_REMINDER);
  const [pausePatientPreVisitReminder] = useMutation(
    PAUSE_PATIENT_PRE_VISIT_REMINDER
  );
  const [resumePatientPreVisitReminder] = useMutation(
    RESUME_PATIENT_PRE_VISIT_REMINDER
  );

  const appointmentPreVisitReminderPaused =
    !!row.appointment.preVisitReminderPausedAt;
  const patientPreVisitReminderPaused =
    !!row.account.patient.preVisitReminderPausedAt;
  const paused =
    appointmentPreVisitReminderPaused || patientPreVisitReminderPaused;

  const handleConfirm = async (scope: "appointment" | "patient") => {
    if (scope === "appointment") {
      if (appointmentPreVisitReminderPaused) {
        await resumeAppointmentPreVisitReminder({
          variables: { appointmentId: row.appointment.id },
          onCompleted: () => {
            toast.success("Pre-visit reminder resumed");
          },
          onError: () => {
            toast.error("Failed to resume pre-visit reminder");
          },
        });
      } else {
        await pauseAppointmentPreVisitReminder({
          variables: {
            appointmentId: row.appointment.id,
            preVisitReminderPausedAt: new Date().toISOString(),
          },
          onCompleted: () => {
            toast.success("Pre-visit reminder paused");
          },
          onError: () => {
            toast.error("Failed to pause pre-visit reminder");
          },
        });
      }
    } else {
      if (patientPreVisitReminderPaused) {
        await resumePatientPreVisitReminder({
          variables: { patientId: row.account.patient.id },
          onCompleted: () => {
            toast.success("Pre-visit reminder resumed");
          },
          onError: () => {
            toast.error("Failed to resume pre-visit reminder");
          },
        });
      } else {
        await pausePatientPreVisitReminder({
          variables: {
            patientId: row.account.patient.id,
            preVisitReminderPausedAt: new Date().toISOString(),
          },
          onCompleted: () => {
            toast.success("Pre-visit reminder paused");
          },
          onError: () => {
            toast.error("Failed to pause pre-visit reminder");
          },
        });
      }
    }
    setIsOpen(false);
  };

  let dialogTitle = "";
  let dialogContent = "";

  if (appointmentPreVisitReminderPaused && patientPreVisitReminderPaused) {
    dialogTitle = "Enable Automated Pre-visit Reminders";
    dialogContent =
      "Do you want to enable automated pre-visit reminders for this appointment or for all of this patient's appointments?";
  } else if (
    !appointmentPreVisitReminderPaused &&
    !patientPreVisitReminderPaused
  ) {
    dialogTitle = "Disable Automated Pre-visit Reminders";
    dialogContent =
      "Do you want to disable automated pre-visit reminders for this appointment or for all of this patient's appointments?";
  } else if (appointmentPreVisitReminderPaused) {
    dialogTitle = "Enable Appointment Pre-visit Reminders";
    dialogContent =
      "Do you want to enable automated pre-visit reminders for this appointment?";
  } else {
    dialogTitle = "Disable Patient Pre-visit Reminders";
    dialogContent =
      "Do you want to disable automated pre-visit reminders for all of this patient's appointments?";
  }

  return (
    <>
      <Button
        type="button"
        variant="secondary"
        size="sm"
        onClick={() => setIsOpen(true)}
      >
        {paused ? (
          <>
            <PlayIcon className="h-4 w-4" />
            <span className="ml-1">Resume</span>
          </>
        ) : (
          <>
            <PauseIcon className="h-4 w-4" />
            <span className="ml-1">Pause</span>
          </>
        )}{" "}
      </Button>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogDescription>{dialogContent}</DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex justify-between">
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <div className="flex gap-2">
              {appointmentPreVisitReminderPaused && (
                <Button onClick={() => handleConfirm("appointment")}>
                  Enable for Appointment
                </Button>
              )}
              {patientPreVisitReminderPaused && (
                <Button onClick={() => handleConfirm("patient")}>
                  Enable for Patient
                </Button>
              )}
              {!appointmentPreVisitReminderPaused &&
                !patientPreVisitReminderPaused && (
                  <Button onClick={() => handleConfirm("appointment")}>
                    Disable for Appointment
                  </Button>
                )}
              {!appointmentPreVisitReminderPaused &&
                !patientPreVisitReminderPaused && (
                  <Button onClick={() => handleConfirm("patient")}>
                    Disable for Patient
                  </Button>
                )}
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const PreVisitReminderHoverCardContent: React.FC<{
  status: WorkflowStepStatus;
  row: AppointmentsRow;
}> = ({ status, row }) => {
  const flags = useFeatureFlags();
  const user = useUser()!;
  // TODO: Card on file specific request
  const [sendRequest, result] = useMutation<
    SendPreVisitReminder,
    SendPreVisitReminderVariables
  >(SEND_PRE_VISIT_REMINDER);
  const appointment = row.appointment;
  const patient = appointment.account.patient;
  const externalPaymentMethod = patient.accounts
    .flatMap((account) => account.externalPaymentMethods)
    .at(0);
  const paymentMethods = patient.paymentMethods;
  const both = paymentMethods.length > 0 && externalPaymentMethod;

  const communication = row.appointment.mostRecentPreVisitReminder;
  const mostRecentOtherPatientAppointmentCommunication =
    row.otherPatientAppointments.reduce((latest, current) => {
      return new Date(current.start) > new Date(latest.start)
        ? current
        : latest;
    }, row.otherPatientAppointments[0])?.mostRecentPreVisitReminder;
  const preVisitReminderScheduledAt = mapNullable(parseISO)(
    row.appointment.preVisitReminderScheduledAt
  );
  const sent =
    isDefined(communication?.sentAt) ||
    isDefined(mostRecentOtherPatientAppointmentCommunication?.sentAt);

  const checkedIn = isDefined(row.appointment.checkedInAt);

  const afterVisit = isAfter(new Date(), row.end ?? row.start);
  const appointmentPreVisitReminderPausedAt = mapNullable(parseISO)(
    row.appointment.preVisitReminderPausedAt
  );
  const patientPreVisitReminderPausedAt = mapNullable(parseISO)(
    row.account.patient.preVisitReminderPausedAt
  );
  const preVisitReminderPausedAt =
    appointmentPreVisitReminderPausedAt ?? patientPreVisitReminderPausedAt;
  const preVisitReminderOff = isDefined(preVisitReminderPausedAt);

  const scheduled =
    !sent &&
    isDefined(preVisitReminderScheduledAt) &&
    isBefore(new Date(), preVisitReminderScheduledAt);
  const notShared =
    !sent &&
    isDefined(preVisitReminderScheduledAt) &&
    isAfter(new Date(), preVisitReminderScheduledAt);

  let content = null;

  if (checkedIn) {
    content = (
      <div className="text-wrap">
        Patient checked in
        <PreVisitChecklist row={row} status={status} />
      </div>
    );
  } else if (preVisitReminderOff) {
    content = (
      <>
        <div className="text-wrap">
          {patientPreVisitReminderPausedAt ? (
            <>
              Automated pre-visit reminder was turned off for this patient{" "}
              {formatDistanceToNow(patientPreVisitReminderPausedAt, {
                addSuffix: true,
              })}
              .
            </>
          ) : appointmentPreVisitReminderPausedAt ? (
            <>
              Automated pre-visit reminder was turned off for this appointment{" "}
              {formatDistanceToNow(appointmentPreVisitReminderPausedAt, {
                addSuffix: true,
              })}
              .
            </>
          ) : (
            <>
              Automated pre-visit reminder was turned off{" "}
              {formatDistanceToNow(preVisitReminderPausedAt, {
                addSuffix: true,
              })}
              .
            </>
          )}
        </div>
        {flags.automatedPreVisitReminderEnabled && (
          <div className="flex justify-between gap-2 pt-1 border-t">
            <PreVisitReminderEnabledDialogButton row={row} />
          </div>
        )}
      </>
    );
  } else if (row.appointment.missingRequiredVisitCollectionRequest) {
    content = (
      <>
        <div className="text-wrap">
          {status !== "skipped" && (
            <>Waiting estimate before sending pre-visit reminder</>
          )}
          <PreVisitChecklist row={row} status={status} />
        </div>
        {flags.automatedPreVisitReminderEnabled && (
          <div className="flex justify-between gap-2 pt-1 border-t">
            <PreVisitReminderEnabledDialogButton row={row} />
          </div>
        )}
      </>
    );
  } else if (scheduled) {
    content = (
      <>
        <div className="text-wrap">
          Pre-visit reminder scheduled to send{" "}
          {formatDistanceToNow(preVisitReminderScheduledAt, {
            addSuffix: true,
          })}
          <PreVisitChecklist row={row} status={status} />
        </div>
        <div className="flex justify-between gap-2 pt-1 border-t">
          {flags.automatedPreVisitReminderEnabled && (
            <PreVisitReminderEnabledDialogButton row={row} />
          )}
          <SendPreVisitReminderButton row={row}>
            Send Now
          </SendPreVisitReminderButton>
        </div>
      </>
    );
  } else if (notShared) {
    if (afterVisit) {
      content = (
        <div className="text-wrap">
          Pre-visit reminder was scheduled to send{" "}
          {formatDistanceToNow(preVisitReminderScheduledAt, {
            addSuffix: true,
          })}
          <PreVisitChecklist row={row} status={status} />
        </div>
      );
    } else {
      content = (
        <>
          <div className="text-wrap">
            Pre-visit reminder scheduled to send{" "}
            {formatDistanceToNow(preVisitReminderScheduledAt, {
              addSuffix: true,
            })}
            <PreVisitChecklist row={row} status={status} />
          </div>
          <div className="flex justify-between gap-2 pt-1 border-t">
            {flags.automatedPreVisitReminderEnabled && (
              <PreVisitReminderEnabledDialogButton row={row} />
            )}
            <SendPreVisitReminderButton row={row}>
              Send Now
            </SendPreVisitReminderButton>
          </div>
        </>
      );
    }
  } else if (sent) {
    content = (
      <div className="text-wrap">
        Pre-visit reminder sent to patient{" "}
        {formatDistanceToNow(
          parseISO(
            communication?.sentAt ??
              mostRecentOtherPatientAppointmentCommunication?.sentAt
          ),
          {
            addSuffix: true,
          }
        )}
        <PreVisitChecklist row={row} status={status} />
      </div>
    );
  } else {
    content = (
      <>
        <div className="text-wrap">
          <PreVisitChecklist row={row} status={status} />
        </div>
        <div className="flex justify-end">
          <SendPreVisitReminderButton row={row}>
            Send Now
          </SendPreVisitReminderButton>
        </div>
      </>
    );
  }

  return (
    <Card>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <h2 className="font-semibold">Pre-visit Reminder</h2>
            <WorkflowStepStatusDisplay status={status} />
          </div>
          <Link
            to={`/portal/${user.organization.id}/${row.patientId}/visit/${row.id}`}
            target="_blank"
          >
            <ExternalLinkIcon className="h-4 w-4 text-gray-400" />
          </Link>
        </div>
        {content}
      </div>
    </Card>
  );
};

const SendPreVisitReminderButton: React.FC<
  React.PropsWithChildren<{
    row: AppointmentsRow;
    onSend?: () => void;
  }>
> = ({ row, children, onSend }) => {
  const [sendRequest, result] = useMutation<
    SendPreVisitReminder,
    SendPreVisitReminderVariables
  >(SEND_PRE_VISIT_REMINDER);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const handleSendRequest = () => {
    sendRequest({
      variables: {
        appointmentId: row.appointment.id,
      },
      onCompleted: () => {
        toast.success("Pre-visit reminder sent");
        if (onSend) onSend();
      },
      onError: () => {
        toast.error("Failed to send pre-visit reminder");
      },
    });
  };

  const handleButtonClick = () => {
    if (row.otherPatientAppointments.length > 0) {
      setIsDialogOpen(true);
    } else {
      setIsConfirmationDialogOpen(true);
    }
  };

  const allAppointmentsEstimated = row.otherPatientAppointments.every(
    (a) => !a.missingRequiredVisitCollectionRequest
  );

  return (
    <>
      <Button
        size="sm"
        variant="outline"
        onClick={handleButtonClick}
        disabled={result.loading}
        className="flex items-center gap-1"
      >
        {result.loading ? (
          <OvalSpinner className="h-3 w-3" />
        ) : (
          <BellIcon className="h-4 w-4" />
        )}
        {children}
      </Button>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {allAppointmentsEstimated ? "Multiple" : "Missing"} Appointments
            </DialogTitle>
          </DialogHeader>
          <div>
            This patient has multiple appointments scheduled for this day. You
            can only send one pre-visit reminder to a patient for appointments
            scheduled for the same day. Pledge will include estimates for all
            the visits for this day in the pre-visit reminder. Please ensure the
            other appointments have estimates attached, if applicable, before
            sending this.
          </div>
          <ul className="list-disc list-inside pl-1">
            {row.otherPatientAppointments.map((appointment) => (
              <li key={appointment.id} className="flex items-center">
                Appointment on{" "}
                {format(parseISO(appointment.start), "MMM do h:mm aa")} -
                Estimate:{" "}
                {appointment.mostRecentVisitCollectionRequest?.amount ? (
                  formatUSD(appointment.mostRecentVisitCollectionRequest.amount)
                ) : (
                  <Badge className="ml-2" variant="error" text="Missing" />
                )}
              </li>
            ))}
          </ul>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            {allAppointmentsEstimated ? (
              <Button
                onClick={() => {
                  handleSendRequest();
                  setIsDialogOpen(false);
                }}
              >
                Send Now
              </Button>
            ) : (
              <Button
                variant="secondary"
                onClick={() => {
                  handleSendRequest();
                  setIsDialogOpen(false);
                }}
              >
                Send Anyway
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isConfirmationDialogOpen}
        onOpenChange={setIsConfirmationDialogOpen}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Send Pre-visit Reminder</DialogTitle>
            <div>
              Are you sure you want to send a pre-visit reminder for this
              appointment?
            </div>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsConfirmationDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              onClick={() => {
                handleSendRequest();
                setIsConfirmationDialogOpen(false);
              }}
            >
              Send Now
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const PreVisitReminderWorkflowNextAction: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const communication = row.appointment.mostRecentPreVisitReminder;
  const mostRecentOtherPatientAppointmentCommunication =
    row.otherPatientAppointments
      .filter(
        (a) =>
          !a.missingRequiredVisitCollectionRequest &&
          a.mostRecentPreVisitReminder
      )
      .reduce((latest, current) => {
        return new Date(current.start) > new Date(latest.start)
          ? current
          : latest;
      }, row.otherPatientAppointments[0])?.mostRecentPreVisitReminder;

  const preVisitReminderScheduledAt = mapNullable(parseISO)(
    row.appointment.preVisitReminderScheduledAt
  );
  const sent = isDefined(communication?.sentAt);
  if (status === "not_started") {
    // TODO: Implement actions
    return (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <SendPreVisitReminderButton row={row}>
            Send Visit Reminder
          </SendPreVisitReminderButton>
        </div>
      </div>
    );
  }
  if (status === "action_required") {
    return (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <SendPreVisitReminderButton row={row}>
            Send Visit Reminder
          </SendPreVisitReminderButton>
        </div>
      </div>
    );
  }
  if (status === "scheduled" && preVisitReminderScheduledAt) {
    return (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <Tooltip
            content={
              <>
                Pre-visit reminder scheduled to send{" "}
                {formatDistanceToNow(preVisitReminderScheduledAt, {
                  addSuffix: true,
                })}
              </>
            }
            trigger={
              <div className="flex items-center gap-1">
                <ClockIcon className="h-4 w-4 text-gray-400" />
                Reminder Scheduled
              </div>
            }
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Send Reminder Now</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  }
  if (
    status === "pending" &&
    (communication?.sentAt ||
      mostRecentOtherPatientAppointmentCommunication?.sentAt)
  ) {
    return (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <Tooltip
            content={
              <>
                Pre-visit reminder sent to patient{" "}
                {formatDistanceToNow(
                  parseISO(
                    communication?.sentAt ??
                      mostRecentOtherPatientAppointmentCommunication?.sentAt
                  ),
                  {
                    addSuffix: true,
                  }
                )}
              </>
            }
            trigger={
              <div className="flex items-center gap-1">
                <CircleDashed className="h-4 w-4 text-blue-500" />
                Reminder Sent
              </div>
            }
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Send Reminder Again</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  }
  return null;
};
