import React, { Fragment, useState } from "react";
import {
  useController,
  useFormContext,
  Controller,
  Control,
  FieldValues,
  ControllerRenderProps,
} from "react-hook-form";
import { Combobox, Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  SelectorIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import * as dateFns from "date-fns";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import InputMask from "react-input-mask";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";

import { classNames, isDefined, range } from "../utils";
import { Tooltip } from "./Tooltip";
import { OvalSpinner } from "./loading";

export const DateInput = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > &
    Partial<ReactDatePickerProps> & {
      name: string;
      control: Control<FieldValues, any> | undefined;
      minYear?: number;
      maxYear?: number;
    }
) => {
  return (
    <Controller
      {...props}
      render={({ field }) => (
        <DatePicker
          field={field}
          onChange={(date: any) => {
            field.onChange(date);
          }}
          {...props}
        />
      )}
    />
  );
};

/**
 * Styled wrapper around react-datepicker
 */
export const DatePicker: React.FC<
  React.PropsWithChildren<
    Partial<ReactDatePickerProps> & {
      field: ControllerRenderProps<FieldValues, string>;
      minYear?: number;
      maxYear?: number;
    }
  >
> = (props) => {
  const { field } = props;
  const minYear = props.minYear ?? 1900;
  const maxYear = props.maxYear ?? dateFns.getYear(new Date()) + 1;
  const years = React.useMemo(
    () => range(minYear, maxYear, 1),
    [minYear, maxYear]
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const { className, ...rest } = props;
  return (
    <div className="relative">
      <div
        className={classNames(
          "appearance-none block w-full pl-3 pr-8 py-2 border bg-white border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
          className ?? ""
        )}
      >
        {/* @ts-expect-error */}
        <ReactDatePicker
          placeholderText="Select date"
          onChange={(date: any) => {
            field.onChange(date);
          }}
          {...rest}
          popperClassName="text-center"
          calendarClassName="isolate grid grid-cols-7 gap-px rounded-lg text-sm shadow ring-1 ring-gray-200 day-tile"
          // @ts-expect-error
          customInput={<InputMask mask="99/99/9999" />}
          customInputRef="inputRef"
          dayClassName={(day: Date) => {
            const isSelected = dateFns.isSameDay(day, field.value);
            const isToday = dateFns.isToday(day);
            return classNames(
              "col-span-1 p-1 hover:bg-gray-100 focus:z-10 bg-white",
              isSelected || isToday ? "font-semibold" : "",
              isSelected ? "text-white" : "",
              !isSelected && !isToday ? "text-gray-900" : "",
              isToday && !isSelected ? "text-indigo-600" : ""
            );
          }}
          renderDayContents={(day, date) => {
            const isSelected = dateFns.isSameDay(date!, field.value);
            const isToday = dateFns.isToday(date!);
            return (
              <time
                dateTime={date!.toDateString()}
                className={classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  isSelected && isToday ? "bg-indigo-600" : "",
                  isSelected && !isToday ? "bg-gray-900" : ""
                )}
              >
                {day}
              </time>
            );
          }}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="flex justify-between items-center text-gray-900 py-1">
              <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div className="grid grid-cols-2 gap-1 font-semibold">
                <div className="col-span-1">
                  <select
                    value={dateFns.getYear(date)}
                    onChange={({ target: { value } }) =>
                      changeYear(value as any)
                    }
                    className="mt-1  w-full py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-span-1">
                  <select
                    value={months[dateFns.getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                    className="mt-1 block w-full py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          )}
          selected={field.value}
        />
        {isDefined(field.value) && (
          <div className="absolute inset-y-0 right-0 mr-1">
            <button className="p-1 h-full rounded-md flex items-center text-gray-400 hover:text-indigo-600">
              <XCircleIcon
                className="h-5 w-5"
                aria-hidden="true"
                onClick={() => field.onChange(null)}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

// Hide the date input, just show the calendar picker. Don't rely on react-form-hook
export const UncontrolledDatePicker: React.FC<
  React.PropsWithChildren<
    Partial<ReactDatePickerProps> & {
      minYear?: number;
      maxYear?: number;
      onSelect: (date: Date) => void;
      defaultValue?: Date;
    }
  >
> = (props) => {
  const [value, setValue] = useState(props.defaultValue ?? new Date());
  const minYear = props.minYear ?? 1900;
  const maxYear = props.maxYear ?? dateFns.getYear(new Date()) + 1;
  const years = React.useMemo(
    () => range(minYear, maxYear, 1),
    [minYear, maxYear]
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const { className, ...rest } = props;
  return (
    <div className="relative">
      <div className={classNames("w-full h-0", className ?? "")}>
        {/* @ts-expect-error */}
        <ReactDatePicker
          placeholderText="Select date"
          onChange={(date: any) => {
            setValue(date);
            props.onSelect(date);
          }}
          {...rest}
          startOpen={true}
          popperClassName="text-center"
          popperPlacement="top-end"
          calendarClassName="isolate grid grid-cols-7 gap-px rounded-lg text-sm shadow ring-1 ring-gray-200 day-tile"
          // customInput={<InputMask mask="99/99/9999" />}
          customInput={<></>}
          customInputRef="inputRef"
          dayClassName={(day: Date) => {
            const isSelected = dateFns.isSameDay(day, value);
            const isToday = dateFns.isToday(day);
            return classNames(
              "col-span-1 p-1 hover:bg-gray-100 focus:z-10 bg-white",
              isSelected || isToday ? "font-semibold" : "",
              isSelected ? "text-white" : "",
              !isSelected && !isToday ? "text-gray-900" : "",
              isToday && !isSelected ? "text-indigo-600" : ""
            );
          }}
          renderDayContents={(day, date) => {
            const isSelected = dateFns.isSameDay(date!, value);
            const isToday = dateFns.isToday(date!);
            return (
              <time
                dateTime={date!.toDateString()}
                className={classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  isSelected && isToday ? "bg-indigo-600" : "",
                  isSelected && !isToday ? "bg-gray-900" : ""
                )}
              >
                {day}
              </time>
            );
          }}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="flex justify-between items-center text-gray-900 py-1">
              <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div className="grid grid-cols-2 gap-1 font-semibold">
                <div className="col-span-1">
                  <select
                    value={dateFns.getYear(date)}
                    onChange={({ target: { value } }) =>
                      changeYear(value as any)
                    }
                    className="mt-1  w-full py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-span-1">
                  <select
                    value={months[dateFns.getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                    className="mt-1 block w-full py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          )}
          selected={value}
        />
      </div>
    </div>
  );
};

export const TimeInput = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
) => (
  <input
    type="time"
    className="mt-1 px-3 py-2  focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md"
  />
);

type Option = { value: string; name: string };

export const TypeaheadCombobox: React.FC<
  React.PropsWithChildren<{
    name: string;
    initialOptions: Option[];
    required?: boolean;
    disabled?: boolean;
    query: (inputValue: string) => Promise<Option[]>;
  }>
> = ({ name, initialOptions, query, required, disabled }) => {
  const [options, setOptions] = useState(initialOptions);

  const onChange = async (inputValue: string) => {
    const newOptions = await query(inputValue);
    setOptions(newOptions);
  };

  return (
    <ControlledCombobox name={name} disabled={disabled} required={required}>
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-1 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm disabled:bg-gray-50"
          onChange={(event) => onChange(event.target.value)}
          displayValue={(option: Option | null) => {
            return option?.name ?? "";
          }}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {options.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option: Option) => (
              <Combobox.Option
                key={option.value}
                value={option}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 px-2 text-center",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected ? "font-semibold" : ""
                      )}
                    >
                      {option.name}
                    </span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </ControlledCombobox>
  );
};

const ControlledCombobox: React.FC<
  React.PropsWithChildren<{
    name: string;
    disabled?: boolean;
    required?: boolean;
  }>
> = ({ children, name, disabled, required }) => {
  const { control, getFieldState } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules: { required },
  });

  const { error } = getFieldState(name);

  return (
    <Combobox
      as="div"
      onChange={field.onChange}
      value={field.value}
      name={field.name}
      disabled={disabled}
      className={error ? "border-2 border-red-500 rounded-lg" : ""}
    >
      {children}
    </Combobox>
  );
};

export const DollarInput: React.FC<
  React.PropsWithChildren<
    CurrencyInputProps & {
      error?: boolean;
      errorMessage?: string;
      onBlur?: (amount: string | undefined) => void;
      containerClassName?: string;
    }
  >
> = ({ error, errorMessage, containerClassName, ...props }) => {
  const [amount, setAmount] = useState<string | undefined>();
  return (
    <div
      className={
        containerClassName ? containerClassName : "relative rounded-md border"
      }
    >
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <span className="text-gray-500 sm:text-sm">$</span>
      </div>
      <CurrencyInput
        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 py-1 sm:text-sm border-gray-300 rounded-md"
        decimalsLimit={2}
        placeholder="0.00"
        {...props}
        onValueChange={(value) => {
          setAmount(value);
          if (isDefined(props.onValueChange)) {
            props.onValueChange(value);
          }
        }}
        onBlur={() => {
          if (isDefined(props.onBlur)) {
            props.onBlur(amount);
          }
        }}
      />
      {error && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          {errorMessage ? (
            <Tooltip
              content={errorMessage}
              trigger={
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              }
            />
          ) : (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
        </div>
      )}
    </div>
  );
};

export const LoadingMultiListBox: React.FC<
  React.PropsWithChildren<{ text?: string }>
> = ({ text = "Loading" }) => {
  return (
    <div>
      <div className="relative w-full min-w-[12em] h-10 cursor-default rounded-md border border-gray-300 bg-white pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
        <span className="flex h-full items-center truncate">
          {text}
          <span className="ml-2 p-1 rounded-full bg-gray-50 group w-6 h-6 inline-flex items-center justify-center">
            <OvalSpinner className="w-4 h-4" />
          </span>
        </span>
      </div>
    </div>
  );
};

export const MultiListBox: React.FC<
  React.PropsWithChildren<{
    options: { value: string; label: string; count?: number }[];
    defaultOptions: { value: string; label: string }[];
    selectedOptions: { value: string; label: string }[];
    onSelectedOptionsChange: (
      selectedOptions: { value: string; label: string }[]
    ) => void;
    placeholder: string;
  }>
> = ({
  options,
  defaultOptions,
  selectedOptions,
  onSelectedOptionsChange,
  placeholder,
}) => {
  const [selected, setSelected] =
    useState<{ value: string; label: string }[]>(defaultOptions);
  let selectedDisplay: string;
  if (selected.length === options.length) {
    selectedDisplay = "All selected";
  } else if (selected.length === 0) {
    selectedDisplay = "None selected";
  } else {
    selectedDisplay =
      selected
        .slice(0, 3)
        .map((option) => option.label)
        .join(", ") + (selected.length > 3 ? "..." : "");
  }
  return (
    <Listbox
      by="value"
      value={selected}
      onChange={(selection) => {
        setSelected(selection);
        onSelectedOptionsChange(selection);
      }}
      multiple
    >
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="relative w-full min-w-[12em] h-10 cursor-default rounded-md border border-gray-300 bg-white pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
              <span className="block truncate">
                {selectedDisplay}
                <span className="ml-2 p-1 rounded-full bg-gray-50 group w-6 h-6 inline-flex items-center justify-center">
                  {selected.length}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 min-w-[20em] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {selected.length > 0 && (
                  <button
                    onClick={() => setSelected([])}
                    className="relative w-full cursor-default select-none border-b py-2 text-gray-900 hover:bg-gray-100"
                  >
                    <span>Clear selection</span>
                  </button>
                )}
                {options.length > 0 && selected.length === 0 && (
                  <button
                    onClick={() => setSelected(options)}
                    className="relative w-full cursor-default select-none border-b py-2 text-gray-900 hover:bg-gray-100"
                  >
                    <span>Select All</span>
                  </button>
                )}
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-gray-100" : "text-gray-900",
                        "relative cursor-default select-none py-2 px-3 flex items-center"
                      )
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <div
                          className={classNames(
                            "mr-2 flex h-4 w-4 items-center justify-center rounded border border-indigo-500",
                            selected
                              ? "bg-indigo-500 text-white"
                              : "opacity-50 [&_svg]:invisible"
                          )}
                        >
                          <CheckIcon
                            className={classNames(
                              "h-4 w-4",
                              !selected && "invisible"
                            )}
                          />
                        </div>

                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.label}
                        </span>
                        {isDefined(option.count) && (
                          <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                            {option.count}
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
