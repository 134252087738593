import React, { useState } from "react";
import { HorizontalPadding, Layout } from "../../layout";
import { Button } from "../../../components/ui/button";
import { Link } from "react-router-dom";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import { Card, Modal, Tooltip } from "../../../components";
import {
  GetChargeTemplates,
  GetChargeTemplatesVariables,
  GetChargeTemplates_chargeTemplates as ChargeTemplate,
} from "../../../generated/GetChargeTemplates";
import { PlusIcon, SearchIcon } from "@heroicons/react/outline";
import { formatDistanceToNow, parseISO } from "date-fns";
import { useFormContext } from "react-hook-form";
import * as uuid from "uuid";
import { TextInput, Text, Flex, Bold, List, ListItem } from "@tremor/react";
import {
  CodeSearchCommand,
  GET_LOCATION_CHARGEMASTER_GROUPS,
} from "../../appointments/table/estimate-dialog";
import { formatPercentage } from "../../../utils";
import { Shapes } from "lucide-react";
import {
  GetCommonChargeTemplates,
  GetCommonChargeTemplatesVariables,
  GetCommonChargeTemplates_commonCodesOverLast30 as CommonTemplate,
} from "../../../generated/GetCommonChargeTemplates";
import { ChargemasterBadge } from "../../shared/visit-bill-display-card";
import {
  GetLocationChargemasterGroups_chargemasterGroupsByLastUsed_chargemasterGroup as ChargemasterGroup,
  GetLocationChargemasterGroups,
} from "../../../generated/GetLocationChargemasterGroups";
import { ChargeTemplateForm } from "./show";

export const GET_CHARGE_TEMPLATES = gql`
  query GetChargeTemplates($locationId: String!, $pending: Boolean!) {
    chargeTemplates(
      where: {
        locationId: { equals: $locationId }
        pending: { equals: $pending }
      }
    ) {
      id
      name
      createdAt
      updatedAt
      chargeTemplateCharges(orderBy: { priority: asc }) {
        id
        chargemasterGroup {
          id
          code
          modifier1
          modifier2
          description
        }
        units
      }
    }
  }
`;

type ChargeTemplateForm = {
  id: string;
  name: string;
  chargeTemplateCharges: {
    id: string;
    chargemasterGroup:
      | {
          id: string;
          code: string;
        }
      | undefined;
    units: number;
  }[];
};

export const CodeInput: React.FC<{
  name: `chargeTemplateCharges.${number}.chargemasterGroup.code`;
  onSelect: (chargemasterGroup: ChargemasterGroup) => void;
}> = ({ name, onSelect }) => {
  const [open, setOpen] = useState(false);
  const methods = useFormContext<ChargeTemplateForm>();
  return (
    <>
      <TextInput
        placeholder="Enter a code"
        required
        {...methods.register(name)}
        onClick={() => {
          setOpen(true);
        }}
      />
      {open && (
        <CodeSearchCommand open={open} setOpen={setOpen} onSelect={onSelect} />
      )}
    </>
  );
};

export const CodeSearchCommandButton: React.FC<{
  onSelect: (chargemasterGroup: ChargemasterGroup) => void;
}> = ({ onSelect }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        onClick={() => {
          setOpen(true);
        }}
      >
        <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
      </button>
      {open && (
        <CodeSearchCommand open={open} setOpen={setOpen} onSelect={onSelect} />
      )}
    </>
  );
};

export const UPSERT_CHARGE_TEMPLATE = gql`
  mutation UpsertChargeTemplate(
    $id: String!
    $create: ChargeTemplateCreateInput!
    $update: ChargeTemplateUpdateInput!
  ) {
    upsertOneChargeTemplate(
      where: { id: $id }
      create: $create
      update: $update
    ) {
      id
      name
    }
  }
`;

export const ChargeTemplateFormDialog: React.FC<{
  chargeTemplate: ChargeTemplate | null;
  setOpen: (open: boolean) => void;
}> = ({ chargeTemplate, setOpen }) => {
  const apollo = useApolloClient();
  const chargemasterGroupResult = useQuery<GetLocationChargemasterGroups>(
    GET_LOCATION_CHARGEMASTER_GROUPS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const chargemasterGroups = (
    chargemasterGroupResult.data?.chargemasterGroupsByLastUsed ?? []
  ).map((cg) => cg.chargemasterGroup);

  return (
    <Modal open={true} setOpen={setOpen}>
      <ChargeTemplateForm
        chargeTemplate={chargeTemplate}
        chargemasterGroups={chargemasterGroups}
        onSuccess={() => {
          setOpen(false);
          apollo.refetchQueries({ include: [GET_CHARGE_TEMPLATES] });
        }}
      />
    </Modal>
  );
};

const ChargeTemplateEmptyState: React.FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Card>
        <div className="text-center w-full py-2">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No charge templates
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by creating a new charge template.
          </p>
          <div className="mt-6">
            <Button
              className="bg-indigo-500 hover:bg-indigo-400 cursor-pointer"
              onClick={() => {
                setOpen(true);
              }}
            >
              <div className="flex">
                <PlusIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                New Charge Template
              </div>
            </Button>
          </div>
        </div>
      </Card>
      {open && (
        <ChargeTemplateFormDialog
          chargeTemplate={null}
          setOpen={() => setOpen(false)}
        />
      )}
    </>
  );
};

const EditChargeTemplateButton: React.FC<{
  chargeTemplate: ChargeTemplate;
}> = ({ chargeTemplate }) => {
  const [open, setOpen] = useState(false);
  return (
    <Link
      to={`/chargemaster/templates/${chargeTemplate.id}`}
      className="bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 bg-indigo-500 hover:bg-indigo-400 cursor-pointer inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
    >
      Edit
    </Link>
  );
};

const ChargeTemplatesList: React.FC<{ chargeTemplates: ChargeTemplate[] }> = ({
  chargeTemplates,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between w-full">
          <div className="text-lg font-medium pb-2">Templates</div>
          <Button
            type="button"
            className="bg-indigo-500 hover:bg-indigo-400 cursor-pointer"
            onClick={() => {
              setOpen(true);
            }}
          >
            New Template
          </Button>
        </div>

        {chargeTemplates.length === 0 ? (
          <ChargeTemplateEmptyState />
        ) : (
          <Card>
            <ul role="list" className="divide-y divide-gray-100 w-full">
              {chargeTemplates.map((chargeTemplate) => (
                <li
                  key={chargeTemplate.id}
                  className="flex items-center justify-between gap-x-6 py-5"
                >
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {chargeTemplate.name}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Charges:{" "}
                        {chargeTemplate.chargeTemplateCharges.map(
                          (chargeTemplateCharge, i) => (
                            <>
                              {i > 0 && ", "}
                              <span key={chargeTemplateCharge.id}>
                                {chargeTemplateCharge.chargemasterGroup.code} x{" "}
                                {chargeTemplateCharge.units}
                              </span>
                            </>
                          )
                        )}
                      </p>
                      <svg
                        viewBox="0 0 2 2"
                        className="h-0.5 w-0.5 fill-current"
                      >
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      Updated{" "}
                      {formatDistanceToNow(parseISO(chargeTemplate.updatedAt), {
                        addSuffix: true,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <EditChargeTemplateButton chargeTemplate={chargeTemplate} />
                  </div>
                </li>
              ))}
            </ul>
          </Card>
        )}
      </div>
      {open && (
        <ChargeTemplateFormDialog chargeTemplate={null} setOpen={setOpen} />
      )}
    </>
  );
};

const GET_COMMON_CHARGE_TEMPLATES = gql`
  query GetCommonChargeTemplates($locationId: String!) {
    commonCodesOverLast30(locationId: $locationId) {
      combos {
        chargemasterGroup {
          id
          code
          modifier1
          modifier2
          modifier3
          modifier4
          description
          primaryChargemaster {
            id
            code
            modifier1
            modifier2
            modifier3
            modifier4
            description
            chargemasterGroupId
          }
        }
        units
      }
      occurrences
      percentage
      chargeTemplate {
        id
        name
        chargeTemplateCharges(orderBy: { priority: asc }) {
          id
          chargemasterGroup {
            id
            code
          }
          units
        }
      }
    }
  }
`;

const CommonChargeDialogButton: React.FC<{
  commonTemplate: CommonTemplate;
}> = ({ commonTemplate }) => {
  const [open, setOpen] = useState(false);
  const chargeTemplate = commonTemplate.chargeTemplate;

  let template = chargeTemplate ?? {
    id: uuid.v4(),
    chargeTemplateCharges: commonTemplate.combos.map((combo) => ({
      id: uuid.v4(),
      chargemasterGroup: combo.chargemasterGroup,
      units: combo.units,
    })),
  };

  return (
    <>
      {chargeTemplate ? (
        <Tooltip
          trigger={
            <Button
              variant="ghost"
              className="p-1 rounded-md h-fit"
              onClick={() => {
                setOpen(true);
              }}
            >
              <Shapes className="h-4 w-4 text-indigo-400 fill-indigo-400" />
            </Button>
          }
          content={<>{chargeTemplate.name}</>}
        />
      ) : (
        <Tooltip
          trigger={
            <Button
              variant="ghost"
              className="p-1 rounded-md h-fit"
              onClick={() => {
                setOpen(true);
              }}
            >
              <Shapes className="h-4 w-4 text-gray-300 fill-gray-300" />
            </Button>
          }
          content={<>Create a new template from these codes</>}
        />
      )}
      {open && (
        <ChargeTemplateFormDialog
          chargeTemplate={template}
          setOpen={() => setOpen(false)}
        />
      )}
    </>
  );
};

const CommonChargeTemplateListCard: React.FC = () => {
  const user = useUser()!;
  const { data, loading } = useQuery<
    GetCommonChargeTemplates,
    GetCommonChargeTemplatesVariables
  >(GET_COMMON_CHARGE_TEMPLATES, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });

  const commonTemplates = data?.commonCodesOverLast30 ?? [];

  return (
    <Card className="flex flex-col">
      <Flex justifyContent="between">
        <div className="text-lg font-semibold">Common Charges</div>
        <Text>Last 30 days</Text>
      </Flex>
      <Flex className="mt-6">
        <Text>
          <Bold>Codes</Bold>
        </Text>
        <Text>
          <Bold>Occurrences (% of total)</Bold>
        </Text>
      </Flex>
      <List className="mt-1">
        {loading ? (
          <>
            <ListItem className="animate-pulse h-8 w-full bg-slate-100 my-1" />
            <ListItem className="animate-pulse h-8 w-full bg-slate-100 my-1" />
            <ListItem className="animate-pulse h-8 w-full bg-slate-100 my-1" />
            <ListItem className="animate-pulse h-8 w-full bg-slate-100 my-1" />
            <ListItem className="animate-pulse h-8 w-full bg-slate-100 my-1" />
          </>
        ) : (
          <>
            {commonTemplates.map((template, i) => (
              <ListItem key={i}>
                <Flex justifyContent="start" className="truncate space-x-2.5">
                  <CommonChargeDialogButton commonTemplate={template} />
                  <div className="flex flex-wrap gap-1">
                    {template.combos.map((combo) => (
                      <div key={combo.chargemasterGroup.id}>
                        <ChargemasterBadge
                          chargemaster={
                            combo.chargemasterGroup.primaryChargemaster
                          }
                          units={combo.units}
                        />
                      </div>
                    ))}
                  </div>
                </Flex>
                <Text className="flex gap-1 items-center">
                  {template.occurrences}{" "}
                  <span className="text-gray-400">
                    ({formatPercentage(template.percentage / 100)})
                  </span>
                </Text>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </Card>
  );
};

export const ChargeTemplatesPage: React.FC = () => {
  const user = useUser()!;
  const [isPending, setIsPending] = useState(false);
  const { data, loading, error } = useQuery<
    GetChargeTemplates,
    GetChargeTemplatesVariables
  >(GET_CHARGE_TEMPLATES, {
    variables: {
      locationId: user.activeLocation.id,
      pending: isPending,
    },
  });

  const chargeTemplates = data?.chargeTemplates ?? [];
  const togglePending = () => {
    setIsPending(!isPending);
  }

  return (
    <Layout
      header={
        <HorizontalPadding>
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold text-gray-900">
              Charge Templates
            </h1>
            {user.isPledgeUser && (
              <Button className="ml-5" onClick={togglePending}>
                Toggle Pending {isPending ? "Off" : "On"}
              </Button>
            )}
          </div>
        </HorizontalPadding>
      }
      content={
        <HorizontalPadding>
          <div className="w-full py-4">
            <div className="flex gap-8">
              <div className="min-w-[24rem] pt-14">
                <CommonChargeTemplateListCard />
              </div>
              <div className="w-full max-h-[90vh] overflow-auto">
                <ChargeTemplatesList chargeTemplates={chargeTemplates} />
              </div>
            </div>
          </div>
        </HorizontalPadding>
      }
    />
  );
};
