import React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { PortalLayout } from "./portal-layout";
import {
  GetPublicOrganizationData,
  GetPublicOrganizationDataVariables,
} from "../../generated/GetPublicOrganizationData";
import { GET_PUBLIC_ORGANIZATION_DATA } from "../../graphql";
import { Rings } from "../../components/loading";
import { PagePublicOrgHeader } from "./identification";

const PrivacyPolicyContent: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className="max-w-prose mx-auto pt-8 pb-4 px-1 sm:px-0">
    <h1 className="text-2xl pb-2">
      <span className="font-semibold">Privacy Policy</span> (Effective Date:
      January 2022)
    </h1>
    <ol className="list-decimal list-inside space-y-2">
      <li>
        <span className="text-lg font-semibold">Introduction.</span> Welcome to
        Pledge Health, Inc. Pledge Health, Inc. (“us”, “we”, or “our”) operates
        https://pledge.health (hereinafter referred to as “Service”). Our
        Privacy Policy governs your visit to https://pledge.health, and explains
        how we collect, safeguard and disclose information that results from
        your use of our Service. We use your data to provide and improve
        Service. By using Service, you agree to the collection and use of
        information in accordance with this policy. Unless otherwise defined in
        this Privacy Policy, the terms used in this Privacy Policy have the same
        meanings as in our Terms and Conditions. Our Terms and Conditions
        (“Terms”) govern all use of our Service and together with the Privacy
        Policy constitutes your agreement with us (“agreement”).
      </li>
      <li>
        <span className="text-lg font-semibold">Definitions</span> SERVICE means
        the https://pledge.health website operated by Pledge Health, Inc.
        PERSONAL DATA means data about a living individual who can be identified
        from those data (or from those and other information either in our
        possession or likely to come into our possession). USAGE DATA is data
        collected automatically either generated by the use of Service or from
        Service infrastructure itself (for example, the duration of a page
        visit). COOKIES are small files stored on your device (computer or
        mobile device). DATA CONTROLLER means a natural or legal person who
        (either alone or jointly or in common with other persons) determines the
        purposes for which and the manner in which any personal data are, or are
        to be, processed. For the purpose of this Privacy Policy, we are a Data
        Controller of your data. DATA PROCESSORS (OR SERVICE PROVIDERS) means
        any natural or legal person who processes the data on behalf of the Data
        Controller. We may use the services of various Service Providers in
        order to process your data more effectively. DATA SUBJECT is any living
        individual who is the subject of Personal Data. THE USER is the
        individual using our Service. The User corresponds to the Data Subject,
        who is the subject of Personal Data.
      </li>
      <li>
        <span className="text-lg font-semibold">
          Information Collection and Use
        </span>{" "}
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </li>
      <li>
        <span className="text-lg font-semibold">Types of Data Collected</span>{" "}
        <h2 className="font-semibold">Personal Data</h2>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you (“Personal Data”). Personally identifiable information may
        include, but is not limited to:
        <ol className="list-decimal list-inside">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Cookies and Usage Data</li>
        </ol>
        We may use your Personal Data to contact you with newsletters, marketing
        or promotional materials and other information that may be of interest
        to you. You may opt out of receiving any, or all, of these
        communications from us by emailing at support@pledge.health.
        <h2 className="font-semibold">Usage Data</h2>
        We may also collect information that your browser sends whenever you
        visit our Service or when you access Service by or through a mobile
        device (“Usage Data”). This Usage Data may include information such as
        your computer's Internet Protocol address (e.g. IP address), browser
        type, browser version, the pages of our Service that you visit, the time
        and date of your visit, the time spent on those pages, unique device
        identifiers and other diagnostic data. When you access Service with a
        mobile device, this Usage Data may include information such as the type
        of mobile device you use, your mobile device unique ID, the IP address
        of your mobile device, your mobile operating system, the type of mobile
        Internet browser you use, unique device identifiers and other diagnostic
        data.
        <h2 className="font-semibold">Tracking Cookies Data</h2>
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold certain information. Cookies are files with a
        small amount of data which may include an anonymous unique identifier.
        Cookies are sent to your browser from a website and stored on your
        device. Other tracking technologies are also used such as beacons, tags
        and scripts to collect and track information and to improve and analyze
        our Service. You can instruct your browser to refuse all cookies or to
        indicate when a cookie is being sent. However, if you do not accept
        cookies, you may not be able to use some portions of our Service.
        Examples of Cookies we use:
        <ol className="list-decimal list-inside">
          <li>
            Session Cookies: We use Session Cookies to operate our Service.
          </li>
          <li>
            Preference Cookies: We use Preference Cookies to remember your
            preferences and various settings.
          </li>
          <li>
            Security Cookies: We use Security Cookies for security purposes.
          </li>
        </ol>
      </li>
      <li>
        <span className="text-lg font-semibold">Use of Data</span> Pledge
        Health, Inc. uses the collected data for various purposes:
        <ol className="list-decimal list-inside">
          <li>to provide and maintain our Service;</li>
          <li>to notify you about changes to our Service;</li>
          <li>
            to allow you to participate in interactive features of our Service
            when you choose to do so;
          </li>
          <li>to provide customer support;</li>
          <li>
            to gather analysis or valuable information so that we can improve
            our Service;
          </li>
          <li>to monitor the usage of our Service;</li>
          <li>to detect, prevent and address technical issues;</li>
          <li>to fulfill any other purpose for which you provide it;</li>
          <li>
            to carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection;
          </li>
          <li>
            {" "}
            to provide you with notices about your account and/or subscription,
            including expiration and renewal notices, email-instructions, etc.;
          </li>
          <li>
            {" "}
            to provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information;
          </li>
          <li>
            {" "}
            in any other way we may describe when you provide the information;
          </li>
          <li> for any other purpose with your consent.</li>
        </ol>
      </li>
      <li>
        <span className="text-lg font-semibold">Retention of Data</span> We will
        retain your Personal Data only for as long as is necessary for the
        purposes set out in this Privacy Policy. We will retain and use your
        Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies. We will also retain Usage Data for internal
        analysis purposes. Usage Data is generally retained for a shorter
        period, except when this data is used to strengthen the security or to
        improve the functionality of our Service, or we are legally obligated to
        retain this data for longer time periods.
      </li>
      <li>
        <span className="text-lg font-semibold">Transfer of Data</span> Your
        information, including Personal Data, may be transferred to - and
        maintained on - computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction. If you are located
        outside United States and choose to provide information to us, please
        note that we transfer the data, including Personal Data, to United
        States and process it there. Your consent to this Privacy Policy
        followed by your submission of such information represents your
        agreement to that transfer. Pledge Health, Inc. will take all the steps
        reasonably necessary to ensure that your data is treated securely and in
        accordance with this Privacy Policy and no transfer of your Personal
        Data will take place to an organisation or a country unless there are
        adequate controls in place including the security of your data and other
        personal information.
      </li>
      <li>
        <span className="text-lg font-semibold">Disclosure of Data</span> We may
        disclose personal information that we collect, or you provide:
        <h2 className="font-semibold">Disclosure for Law Enforcement.</h2>
        Under certain circumstances, we may be required to disclose your
        Personal Data if required to do so by law or in response to valid
        requests by public authorities.
        <h2 className="font-semibold">Business Transaction.</h2>
        If we or our subsidiaries are involved in a merger, acquisition or asset
        sale, your Personal Data may be transferred.
        <h2 className="font-semibold">
          Other cases. We may disclose your information also:
        </h2>
        to our subsidiaries and affiliates;
      </li>
      <li>
        <span className="text-lg font-semibold">Security of Data</span> The
        security of your data is important to us but remember that no method of
        transmission over the Internet or method of electronic storage is 100%
        secure. While we strive to use commercially acceptable means to protect
        your Personal Data, we cannot guarantee its absolute security.
      </li>
      <li>
        <span className="text-lg font-semibold">Service Providers</span> We may
        employ third party companies and individuals to facilitate our Service
        (“Service Providers”), provide Service on our behalf, perform
        Service-related services or assist us in analysing how our Service is
        used. These third parties have access to your Personal Data only to
        perform these tasks on our behalf and are obligated not to disclose or
        use it for any other purpose
      </li>
      <li>
        <span className="text-lg font-semibold">Analytics</span> We may use
        third-party Service Providers to monitor and analyze the use of our
        Service.
        <h2 className="font-semibold">Google Analytics</h2>
        Google Analytics is a web analytics service offered by Google that
        tracks and reports website traffic. Google uses the data collected to
        track and monitor the use of our Service. This data is shared with other
        Google services. Google may use the collected data to contextualise and
        personalise the ads of its own advertising network. For more information
        on the privacy practices of Google, please visit the Google Privacy
        Terms web page: https://policies.google.com/privacy?hl=en We also
        encourage you to review the Google's policy for safeguarding your data:
        https://support.google.com/analytics/answer/6004245.
        <h2 className="font-semibold">Mixpanel</h2>
        Mixpanel is provided by Mixpanel Inc. You can prevent Mixpanel from
        using your information for analytics purposes by opting-out. To opt-out
        of Mixpanel service, please visit this page:
        https://mixpanel.com/optout/ For more information on what type of
        information Mixpanel collects, please visit the Terms of Use page of
        Mixpanel: https://mixpanel.com/terms/
      </li>
      <li>
        <span className="text-lg font-semibold">Payments</span> We may provide
        paid products and/or services within Service. In that case, we use
        third-party services for payment processing (e.g. payment processors).
        We will not store or collect your payment card details. That information
        is provided directly to our third-party payment processors whose use of
        your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information. The
        payment processors we work with are:
        <h2 className="font-semibold">Stripe</h2>
        Their Privacy Policy can be viewed at: https://stripe.com/us/privacy
      </li>
      <li>
        <span className="text-lg font-semibold">Links to Other Sites</span> Our
        Service may contain links to other sites that are not operated by us. If
        you click a third party link, you will be directed to that third party's
        site. We strongly advise you to review the Privacy Policy of every site
        you visit. We have no control over and assume no responsibility for the
        content, privacy policies or practices of any third party sites or
        services.
      </li>
      <li>
        <span className="text-lg font-semibold">Children's Privacy</span> Our
        Services are not intended for use by children under the age of 18
        (“Child” or “Children”). We do not knowingly collect personally
        identifiable information from Children under 18. If you become aware
        that a Child has provided us with Personal Data, please contact us. If
        we become aware that we have collected Personal Data from Children
        without verification of parental consent, we take steps to remove that
        information from our servers.
      </li>
      <li>
        <span className="text-lg font-semibold">
          Changes to This Privacy Policy
        </span>{" "}
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. We will
        let you know via email and/or a prominent notice on our Service, prior
        to the change becoming effective and update “effective date” at the top
        of this Privacy Policy. You are advised to review this Privacy Policy
        periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
      </li>
      <li>
        <span className="text-lg font-semibold">Contact Us</span> If you have
        any questions about this Privacy Policy, please contact us: By email:
        support@pledge.health.
      </li>
    </ol>
  </div>
);

export const PrivacyPolicyOrganization: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  // Optional locationId query parameter
  const { data, loading } = useQuery<
    GetPublicOrganizationData,
    GetPublicOrganizationDataVariables
  >(GET_PUBLIC_ORGANIZATION_DATA, {
    variables: { id: organizationId! },
  });
  if (loading || !data)
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );

  return (
    <PortalLayout organizationId={data.getPublicOrganizationData?.id!}>
      <PagePublicOrgHeader logoUrl={data.getPublicOrganizationData?.logoUrl!} />
      <PrivacyPolicyContent />
    </PortalLayout>
  );
};

export const PrivacyPolicyUniversal: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <PortalLayout>
    <PagePublicOrgHeader
      logoUrl={
        "https://s3.us-east-2.amazonaws.com/assets.pledge.health/pledge-logo.png"
      }
    />
    <PrivacyPolicyContent />
  </PortalLayout>
);
