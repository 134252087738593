import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";
import { classNames } from "../utils";
import { OvalSpinner, Spinner } from "./loading";
import { cn } from "../utils";

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  loading?: boolean;
};

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  loading,
  ...props
}) => (
  <div className="flex items-center">
    <button
      type="button"
      className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
      disabled={loading}
      {...props}
    >
      {loading ? <OvalSpinner /> : children}
    </button>
  </div>
);

export const SubmitButton: React.FC<React.PropsWithChildren<ButtonProps & { spinnerClassName?: string, buttonClassName?: string }>> = ({ children, loading, spinnerClassName, buttonClassName, ...props }) => (
  <button
    type="submit"
    disabled={loading}
    className={cn(
      "w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center disabled:bg-opacity-50 disabled:cursor-not-allowed",
      buttonClassName
    )}
    {...props}
  >
    {loading ? (
      <OvalSpinner className={spinnerClassName ?? "text-white"} />
    ) : (
      children
    )}
  </button>
);

export const DropDownButton: React.FC<React.PropsWithChildren<ButtonProps & { items: { name: string; onClick: () => void }[] }>> = ({ children, loading, items, ...props }) => (
  <div className="flex items-center">
    {loading && <Spinner className="text-indigo-600" />}
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      <button
        type="button"
        className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-l-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
        disabled={loading}
        {...props}
      >
        {children}
      </button>
      <Menu as="span" className="-ml-px relative block">
        <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-transparent border-l-gray-50 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {items.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <button
                      onClick={item.onClick}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full text-left px-4 py-2 text-sm"
                      )}
                    >
                      {item.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </span>
  </div>
);
