import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { useUser } from "../../user-context";
import { useNavigate } from "react-router-dom";
import { ProviderForm } from "./providerForm";
import { Card } from "../../components";
import { Layout } from "../layout";
import { ProviderType } from "../../generated/globalTypes";
import {
  CreateProvider,
  CreateProviderVariables,
} from "../../generated/CreateProvider";
import { GET_PROVIDER_FORM_OPTIONS } from "./edit";

const CREATE_PROVIDER = gql`
  mutation CreateProvider(
    $organizationId: String!
    $locationId: String!
    $type: String!
    $organizationName: String
    $firstName: String
    $lastName: String
    $npi: String
    $inNetworkPayerIds: [String!]!
    $outOfNetworkPayerIds: [String!]!
  ) {
    createAProvider(
      type: $type
      firstName: $firstName
      lastName: $lastName
      organizationName: $organizationName
      npi: $npi
      organizationId: $organizationId
      primaryLocation: $locationId
      inNetworkPayerIds: $inNetworkPayerIds
      outOfNetworkPayerIds: $outOfNetworkPayerIds
    ) {
      success
      provider {
        id
        type
        firstName
        lastName
        organizationName
        npi
      }
      errors {
        message
      }
    }
  }
`;

const CreateProviderForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const user = useUser()!;
  const [createProvider, result] = useMutation<
    CreateProvider,
    CreateProviderVariables
  >(CREATE_PROVIDER);

  const optionsResult = useQuery(GET_PROVIDER_FORM_OPTIONS, {
    variables: { locationId: user.activeLocation.id },
  });

  const payers = optionsResult.data?.payers || [];

  const onSubmit = async (data: ProviderForm) => {
    createProvider({
      variables: {
        type: data.type as ProviderType,
        organizationId: user.organization.id,
        locationId: user.activeLocation.id,
        firstName: data.firstName,
        lastName: data.lastName,
        organizationName: data.organizationName,
        npi: data.npi,
        inNetworkPayerIds: data.inNetworkPayers,
        outOfNetworkPayerIds: data.outNetworkPayers,
      },
    });
  };

  if (result?.data?.createAProvider?.success) {
    toast.success("Provider Created");
    navigate("/providers");
  } else {
    result?.data?.createAProvider?.errors.map((error) => {
      toast.error(error.message);
    });
  }

  return (
    <ProviderForm
      loading={result.loading}
      submit={onSubmit}
      submitText="Create Provider"
      defaultValues={{
        type: "Individual",
        firstName: null,
        lastName: null,
        organizationName: null,
        npi: null,
        inNetworkPayers: [],
        outNetworkPayers: [],
      }}
      payers={payers}
    />
  );
};

export const NewProvider: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">New Provider</h1>
        </div>
      }
      content={
        <div className="py-4">
          <Card className="rounded-lg">
            <div className="w-full px-4 py-5 sm:p-6">
              <CreateProviderForm />
            </div>
          </Card>
        </div>
      }
    />
  );
};
