import * as React from "react";
import * as ReactCurrencyInput from "react-currency-input-field";

import { cn } from "../../utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  adornment?: React.ReactNode;
  adornmentPosition?: "start" | "end";
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const adornmentPosition = props.adornmentPosition ?? "start";
    return (
      <div
        className={cn(
          "flex h-10 items-center rounded-md border border-input bg-white text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2",
          props.adornment && adornmentPosition === "start" && "pl-3",
          props.adornment && adornmentPosition === "end" && "pr-3",
          className
        )}
      >
        {props.adornment && adornmentPosition === "start" && props.adornment}
        <input
          {...props}
          type={type}
          ref={ref}
          className={cn(
            "w-full bg-background rounded-md px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
        />
        {props.adornment && adornmentPosition === "end" && props.adornment}
      </div>
      //   <input
      //     className={cn(
      //       "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      //       className
      //     )}
      //     type={type}
      //     ref={ref}
      //     {...props}
      //   />
      // </div>
    );
  }
);
Input.displayName = "Input";

export { Input };

const CurrencyInput = React.forwardRef<
  HTMLInputElement,
  ReactCurrencyInput.CurrencyInputProps
>(({ className, type, ...props }, ref) => {
  return (
    <ReactCurrencyInput.default
      className={cn(
        "flex h-10 w-full min-w-[6rem] rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      prefix="$"
      decimalsLimit={2}
      ref={ref}
      {...props}
    />
  );
});
CurrencyInput.displayName = "CurrencyInput";

export { CurrencyInput };
