import React from "react";
import { classNames } from "../utils";

export const Card: React.FC<
  React.PropsWithChildren<{ id?: string; className?: string }> &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
> = ({ id, children, className, ...props }) => (
  <div
    id={id}
    {...props}
    className={classNames(
      "px-4 py-2 flex shadow-sm ring-1 ring-gray-900/5 bg-white rounded-md",
      className ?? ""
    )}
  >
    {children}
  </div>
);
