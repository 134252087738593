import {
  CurrencyDollarIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { ColumnDef } from "@tanstack/react-table";
import {
  formatDistanceToNow,
  isAfter,
  isBefore,
  isPast,
  parseISO,
} from "date-fns";
import { Link } from "react-router-dom";
import { isArchiveable, isPendable, isReviewable } from "..";
import { Spinner } from "../../../components/loading";
import { Tooltip } from "../../../components/Tooltip";
import { Checkbox } from "../../../components/ui/checkbox";
import { DataTableColumnHeader } from "../../../components/ui/table-helpers/data-table-column-header";
import {
  GetLocalInReviewBills_localInReviewBills as Bill,
  GetLocalInReviewBills_localInReviewBills_account_patient as Patient,
  GetLocalInReviewBills_localInReviewBills_primaryProvider as Provider,
} from "../../../generated/GetLocalInReviewBills";
import {
  BillCommunicationState,
  BillState,
} from "../../../generated/globalTypes";
import { usePatientCredits } from "../../../hooks/use-patient-credits";
import {
  cn,
  formatDateMMDDYYYY,
  formatUSD,
  toDateMMDDYYYY,
} from "../../../utils";
import {
  BillActionDropdown,
  BillShareStatusCell,
  ChargesHoverCard,
  isReadyable,
} from "../index";
import { DateFilterType } from "./data-table-toolbar";

// Add status indicator components
const StatusIndicator = ({ colorClass }: { colorClass: string }) => (
  <div className={`w-[10px] h-[10px] rounded-full ${colorClass}`} />
);

const PendingStatusIndicator = () => (
  <StatusIndicator colorClass="bg-yellow-600" />
);
const InReviewStatusIndicator = () => (
  <StatusIndicator colorClass="bg-yellow-500" />
);
const ReadyStatusIndicator = () => <StatusIndicator colorClass="bg-blue-600" />;
const PaidStatusIndicator = () => <StatusIndicator colorClass="bg-green-600" />;
const ArchivedStatusIndicator = () => (
  <StatusIndicator colorClass="bg-gray-500" />
);

export type BillsRow = {
  id: string;
  bill: Bill;
  patientName: string;
  dateOfService: Date;
  status: BillState;
  communicationStatus: BillCommunicationState | null;
  patientBalance: number;
  accountType: string | null;
  dueDate: string | null;
  onHoldAt: string | null;
  allowedTotal: number;
  patientResponsibility: number;
  provider: Provider | null;
  providerName: string;
  chargeCodes: string[];
  patient: Patient;
  sent: "Sent" | "Not Sent";
  scheduled: "Scheduled" | "Not Scheduled";
  reminder: "Enrolled" | "Exhausted" | "Not Enrolled";
};

export const columns: ColumnDef<BillsRow>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "patient",
    accessorKey: "patientName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Patient" />
    ),
    cell: ({ row, table }) => {
      const patient = row.original.patient;
      const lastPaymentError = patient.paymentIntents[0]?.lastPaymentError;

      // Get all visible patient IDs for batch fetching
      const visiblePatientIds = table
        .getRowModel()
        .rows.map((row) => row.original.patient.id);

      // Only fetch patient credits if we're viewing in review or ready bills for perf reasons
      const enabled =
        [BillState.InReview, BillState.Ready].includes(row.original.status) &&
        visiblePatientIds.length > 0;
      const { data, loading } = usePatientCredits(visiblePatientIds, enabled);

      const patients = data?.getPatientCredits ?? [];
      const patientCredit =
        patients.find((p) => p.id === patient.id)
          ?.totalPatientUnallocatedCredits ?? 0;

      return (
        <div className="flex gap-1 items-center">
          <Link
            to={`/patients/${patient.id}`}
            className="text-sm text-gray-900 hover:text-gray-500"
          >
            {patient.displayName}
          </Link>
          {patient.autoPayEnabled && (
            <Tooltip
              trigger={
                <CurrencyDollarIcon
                  className={cn(
                    "w-5 h-5",
                    lastPaymentError ? "text-red-500" : "text-green-500"
                  )}
                />
              }
              content={
                lastPaymentError
                  ? `Autopay Enabled but last payment failed with error: ${lastPaymentError}`
                  : "Autopay Enabled"
              }
            />
          )}
          {loading ? (
            <Spinner className="w-4 h-4 text-gray-200" />
          ) : patientCredit > 0 ? (
            <Tooltip
              trigger={
                <InformationCircleIcon className="h-4 w-4 text-yellow-500" />
              }
              content={
                <>This patient has a {formatUSD(patientCredit)} credit</>
              }
            />
          ) : (
            <div className="h-4 w-4" />
          )}
          {row.original.bill.charges?.length > 0 && (
            <ChargesHoverCard bill={row.original.bill} />
          )}
        </div>
      );
    },
  },
  {
    id: "accountType",
    accessorKey: "accountType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Account" />
    ),
    cell: ({ row }) => {
      return <div className="text-sm">{row.original.accountType}</div>;
    },
  },
  {
    id: "status",
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      let indicator;
      switch (row.original.status) {
        case BillState.Pending:
          indicator = <PendingStatusIndicator />;
          break;
        case BillState.InReview:
          indicator = <InReviewStatusIndicator />;
          break;
        case BillState.Ready:
          indicator = <ReadyStatusIndicator />;
          break;
        case BillState.Reconciled:
          indicator = <PaidStatusIndicator />;
          break;
        case BillState.Resolved:
          indicator = <PaidStatusIndicator />;
          break;
        case BillState.Archived:
          indicator = <ArchivedStatusIndicator />;
          break;
      }
      return (
        <div className="text-sm">
          <div className="flex items-center gap-1">
            {indicator}
            {row.original.status}
          </div>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.some((v: string) => v === row.original.status);
    },
  },
  {
    id: "communicationStatus",
    accessorKey: "communicationStatus",
    header: "Communication",
    cell: ({ row }) => {
      return <BillShareStatusCell bill={row.original.bill} />;
    },
    filterFn: (row, id, value) => {
      return value.some((v: string) => v === row.original.communicationStatus);
    },
  },
  {
    id: "provider",
    accessorKey: "providerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Provider" />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-sm text-gray-900">
          {row.original.provider?.displayName}
        </div>
      );
    },
  },
  {
    id: "dateOfService",
    accessorKey: "dateOfService",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="DOS" />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-sm text-gray-900">
          {row.original.bill.dateOfServiceDisplay &&
            toDateMMDDYYYY(row.original.bill.dateOfServiceDisplay)}
        </div>
      );
    },
    filterFn: (
      row,
      id,
      value: { from?: Date; to?: Date; type?: DateFilterType }
    ) => {
      const date = row.original.dateOfService;
      const { from, to, type } = value;

      if (type === "before" && to) {
        return isBefore(date, to);
      }
      if (type === "after" && from) {
        return isAfter(date, from);
      }
      if (type === "range" && from && to) {
        return isAfter(date, from) && isBefore(date, to);
      }
      if (type === "range" && from) {
        return isAfter(date, from);
      }
      if (type === "range" && to) {
        return isBefore(date, to);
      }
      return true;
    },
  },
  {
    id: "dueDate",
    accessorKey: "dueDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Due Date" />
    ),
    cell: ({ row }) => {
      const dueDate = row.original.dueDate;
      return (
        <div
          className={cn(
            "text-sm font-normal",
            dueDate && isPast(parseISO(dueDate))
              ? "text-red-600"
              : "text-gray-900"
          )}
        >
          {dueDate && toDateMMDDYYYY(dueDate)}
        </div>
      );
    },
  },
  {
    id: "onHold",
    accessorKey: "onHoldAt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="On Hold" />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-sm">
          {row.original.onHoldAt
            ? formatDistanceToNow(parseISO(row.original.onHoldAt), {
                addSuffix: true,
              })
            : "Not On Hold"}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      if (value.includes("true") && row.original.onHoldAt) return true;
      if (value.includes("false") && !row.original.onHoldAt) return true;
      return false;
    },
  },
  {
    id: "nextReminder",
    accessorKey: "patient.reminderWorkflow",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Next Reminder" />
    ),
    cell: ({ row }) => {
      const workflow = row.original.patient.reminderWorkflow;
      const nextReminderDate = workflow?.nextReminderDate;
      const enrolled = workflow?.id && !workflow?.pausedAt;

      return (
        <div className="text-sm font-normal">
          {!enrolled ? (
            <span className="italic text-gray-700">Not Enrolled</span>
          ) : nextReminderDate ? (
            <span
              className={cn(
                nextReminderDate && isPast(parseISO(nextReminderDate))
                  ? "text-red-600"
                  : "text-gray-900"
              )}
            >
              {formatDateMMDDYYYY(nextReminderDate)}
            </span>
          ) : (
            <span className="text-gray-900">Reminders Exhausted</span>
          )}
        </div>
      );
    },
  },
  {
    id: "allowedTotal",
    accessorKey: "allowedTotal",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Allowed Amt."
        className="text-right"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-sm text-gray-900 text-right">
          {formatUSD(-row.original.allowedTotal)}
        </div>
      );
    },
  },
  {
    id: "patientResponsibility",
    accessorKey: "patientResponsibility",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Patient Resp."
        className="text-right"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-sm text-gray-900 text-right">
          {formatUSD(-row.original.patientResponsibility)}
        </div>
      );
    },
  },
  {
    id: "patientBalance",
    accessorKey: "patientBalance",
    header: ({ column }) => (
      <div>
        <DataTableColumnHeader
          column={column}
          title="Patient Balance"
          className="justify-end"
        />
      </div>
    ),
    cell: ({ row }) => {
      return (
        <div className="text-sm font-semibold text-gray-900 text-right">
          {formatUSD(row.original.patientBalance)}
        </div>
      );
    },
  },
  {
    id: "scheduled",
    accessorKey: "scheduled",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Scheduled" />
    ),
    filterFn: (row, id, value) => {
      return value.some((v: string) => v === row.original.scheduled);
    },
  },
  {
    id: "sent",
    accessorKey: "sent",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sent" />
    ),
    filterFn: (row, id, value) => {
      return value.some((v: string) => v === row.original.sent);
    },
  },
  {
    id: "reminder",
    accessorKey: "reminder",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Reminder" />
    ),
    filterFn: (row, id, value) => {
      return value.some((v: string) => v === row.original.reminder);
    },
  },
  {
    id: "chargeCodes",
    accessorKey: "chargeCodes",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charge Code" />
    ),
    filterFn: (row, id, value) => {
      return value.some((v: string) => row.original.chargeCodes.includes(v));
    },
  },
  {
    id: "actions",
    header: "",
    // cell: ({ row }) => <ActionDropdownCell bill={row.original.bill} />,
    cell: ({ row }) => {
      const pendable = isPendable(row.original.bill);
      const reviewable = isReviewable(row.original.bill);
      const readyable = isReadyable(row.original.bill);
      const archiveable = isArchiveable(row.original.bill);

      // If no actions, don't display
      if (!pendable && !reviewable && !readyable && !archiveable) {
        return null;
      }

      return <BillActionDropdown bill={row.original.bill} />;
    },
  },
];
