import React from "react";
import { Layout, LoadingLayout } from "../../layout";
import { DepositMappingForm } from "./deposit-mapping-form";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { Condition, Visit } from "../../../evaluate-rule";
import { gql, useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import { useParams } from "react-router-dom";
import {
  GetDepositMapping,
  GetDepositMappingVariables,
} from "../../../generated/GetDepositMapping";
import { DEPOSIT_MAPPING_FIELDS } from "../../../graphql";

const GET_FEE_SCHEDULE_MAPPING = gql`
  ${DEPOSIT_MAPPING_FIELDS}
  query GetDepositMapping($id: String!) {
    depositMapping(where: { id: $id }) {
      ...DepositMappingFields
    }
  }
`;

export const EditDepositRule: React.FC = () => {
  const params = useParams<{ id: string }>();
  const user = useUser()!;
  const { data, loading, error } = useQuery<
    GetDepositMapping,
    GetDepositMappingVariables
  >(GET_FEE_SCHEDULE_MAPPING, {
    variables: {
      id: params.id!,
    },
  });

  const depositMapping = data?.depositMapping;

  if (loading || !depositMapping) {
    return <LoadingLayout />;
  }

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Edit Fee Schedule Rule
          </h1>
        </div>
      }
      content={
        loading ? (
          <div className="flex flex-col gap-4">
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          </div>
        ) : (
          <DepositMappingForm depositMapping={depositMapping}>
            {({ conditions }) => (
              <div>
                <BenefitAssignmentRuleSimulatorTable
                  conditions={conditions as Condition<keyof Visit>[]}
                />
              </div>
            )}
          </DepositMappingForm>
        )
      }
    />
  );
};
