import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartjsTooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

import {
  GetLocalAppointments_localAppointments_appointment,
  GetLocalAppointments_localAppointments_appointment_bill as Bill,
  GetLocalAppointments_localAppointments_appointment_accountCoverages_insurancePolicy_mostRecentEligibilityRequest as EligibilityRequest,
  GetLocalAppointments_localAppointments_appointment_accountCoverages_insurancePolicy as InsurancePolicy,
  GetLocalAppointments_localAppointments_appointment_mostRecentVisitCollectionRequest_coverageBenefit as CoverageBenefit,
  GetLocalAppointments_localAppointments_appointment_account_patient_paymentMethods as PaymentMethod,
} from "../../generated/GetLocalAppointments";
import {
  classNames,
  toDateMMDDYYYY,
  formatPercentage,
  formatUSD,
  isDefined,
  billStateDisplay,
  eligibilityResponseErrorDisplay,
  cn,
} from "../../utils";
import { Badge, Tooltip } from "../../components";
import {
  CalendarIcon,
  IdentificationIcon,
  CheckIcon,
  ClockIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";
import { DollarInput } from "../../components/input";
import {
  BillCollectionMode,
  BillState,
  CoveragePriority,
  UserEnteredBenefitType,
} from "../../generated/globalTypes";
import { Control, Controller } from "react-hook-form";
import { EligibilityBadge } from "../patients/eligibilities/eligibilityBadge";
import {
  INSURANCE_POLICY_SUMMARY_FIELDS,
  ELIGIBILITY_REQUEST_STATUS_FIELD,
  COVERAGE_BENEFIT_FIELDS,
  APPOINTMENT_FIELDS,
} from "../../graphql";
import {
  GetPatientCheckoutDetails_bills as UnpaidBill,
  GetPatientCheckoutDetails_bills_toCollect_estimate_estimatedCharges as EstimatedCharge,
} from "../../generated/GetPatientCheckoutDetails";
import {
  ArchivedStatusIndicator,
  InReviewStatusIndicator,
  PaidStatusIndicator,
  ReadyStatusIndicator,
  StatusIndicator,
} from "../billing";
import * as HoverCard from "@radix-ui/react-hover-card";
import { AutoEstimateIndicator } from "../shared/Tooltips";
import { useFeatureFlags } from "../../hooks";
import { useAnalytics } from "../../analytics-context";
import { ServiceBenefitCell } from "../patients/insurances/show";
import { ShieldOff } from "lucide-react";
import { AssignBenefitsDialog } from "./assign-benefits-dialog";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import {
  CheckEligibilityRequestStatus,
  CheckEligibilityRequestStatusVariables,
} from "../../generated/CheckEligibilityRequestStatus";

ChartJS.register(ArcElement, ChartjsTooltip, Legend);

type Appointment = GetLocalAppointments_localAppointments_appointment & {
  account: {
    patient: {
      patientReadyBalance: number;
      paymentMethods: PaymentMethod[];
    };
  };
};

export const REQUEST_APPOINTMENT_ELIGIBILITY = gql`
  ${APPOINTMENT_FIELDS}
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${COVERAGE_BENEFIT_FIELDS}
  mutation RequestAppointmentEligibility(
    $insurancePolicyId: String!
    $appointmentId: String!
    $referenceDate: DateTime!
    $synchronous: Boolean
  ) {
    requestAppointmentEligibility(
      insurancePolicyId: $insurancePolicyId
      appointmentId: $appointmentId
      synchronous: $synchronous
    ) {
      eligibilityRequest {
        id
        createdAt
        inNetwork
        status
        appointment {
          ...AppointmentFields
          accountCoverages {
            id
            insurancePolicy {
              id
              mostRecentPriorEligibilityRequest {
                id
                status
                reverificationStatus(referenceDate: $referenceDate) {
                  needsReverification
                  reason
                }
              }
            }
          }
          appointmentBenefitAssignments(orderBy: { createdAt: desc }, take: 1) {
            id
            combinedCoverageBenefit {
              ...CoverageBenefitFields
            }
          }
        }
        insurancePolicy {
          id
          ...InsurancePolicySummaryFields
          insurancePolicyVerificationStatus
          insurancePolicyBenefitState {
            status
            needsVerificationReason
          }
          mostRecentCoverageBenefits {
            ...CoverageBenefitFields
          }
          acceptedAt
          acceptedBy {
            id
            firstName
            lastName
          }
          eligibilityRequests(orderBy: { createdAt: desc }) {
            id
            createdAt
            automated
            rawEligibilityRequestResponses {
              id
              requestedServiceTypeCode
              requestedServiceTypeDisplay
            }
            requestedBy {
              id
              firstName
              lastName
            }
            insurancePolicy {
              id
              payer {
                id
                name
              }
              plan {
                id
                name
              }
              patient {
                id
              }
            }
            ...EligibilityRequestStatusFields
          }
        }
        eligible
        accountCoverage {
          id
          plan {
            id
            name
          }
          payer {
            id
          }
        }
        requestedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const mapping = {
  [CoveragePriority.PRIMARY]: 1,
  [CoveragePriority.SECONDARY]: 2,
  [CoveragePriority.TERTIARY]: 3,
};

export const InsurancePolicyPriorityBadge: React.FC<{
  priority: CoveragePriority;
}> = ({ priority }) => {
  return (
    <span className="flex h-5 w-5 shrink-0 items-center justify-center rounded-md border bg-gray-100 border-gray-50 text-[0.625rem] font-medium text-gray-400">
      {mapping[priority]}
    </span>
  );
};

export const TermedInsurancePolicyBadge: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: Pick<InsurancePolicy, "terminationDate"> | null;
    patientId: string;
  }>
> = ({ insurancePolicy, patientId }) => {
  return (
    <Tooltip
      content={
        <div className="flex flex-col">
          <div>
            Insurance policy ended
            {insurancePolicy?.terminationDate
              ? ` on ${toDateMMDDYYYY(insurancePolicy.terminationDate)}`
              : ""}
            .
          </div>
        </div>
      }
      trigger={
        <Link to={`/patients/${patientId}`}>
          <Badge text="Inactive" variant="warning" clickable={true} />
        </Link>
      }
    />
  );
};

const CHECK_ELIGIBILITY_REQUEST_STATUS = gql`
  query CheckEligibilityRequestStatus($id: String!) {
    eligibilityRequest(where: { id: $id }) {
      id
      status
      eligible
      allRequestsErrored
      deduplicatedErrors {
        id
        field
        description
        followupAction
      }
    }
  }
`;

export const EligibilityStatusBadge: React.FC<
  React.PropsWithChildren<{
    eligibilityRequest: EligibilityRequest;
    patientId: string;
    insurancePolicyId: string;
    onCompleted?: (data: CheckEligibilityRequestStatus) => void;
  }>
> = ({ eligibilityRequest, patientId, insurancePolicyId, onCompleted }) => {
  // If the eligibility request is in progress, poll for status updates
  const [pollAttempts, setPollAttempts] = useState(0);
  const {} = useQuery<
    CheckEligibilityRequestStatus,
    CheckEligibilityRequestStatusVariables
  >(CHECK_ELIGIBILITY_REQUEST_STATUS, {
    variables: { id: eligibilityRequest.id },
    fetchPolicy: "network-only",
    // Required to trigger the onCompleted callback on each poll
    notifyOnNetworkStatusChange: true,
    pollInterval: 2_000,
    skip: eligibilityRequest.status !== "InProgress",
    skipPollAttempt: () => {
      const skip = pollAttempts > 10;
      setPollAttempts(pollAttempts + 1);
      return skip;
    },
    onCompleted,
  });

  const lastVerifiedAtBlurb = `Last verified${
    eligibilityRequest.automated ? " automatically" : ""
  } at ${format(parseISO(eligibilityRequest.createdAt), "yyyy-MM-dd hh:mm")}${
    !eligibilityRequest.automated && eligibilityRequest.requestedBy
      ? ` by ${[
          eligibilityRequest.requestedBy.firstName,
          eligibilityRequest.requestedBy.lastName,
        ].join(" ")}`
      : ""
  }`;

  const linkTo = `/patients/${patientId}/insurances/${insurancePolicyId}`;

  if (eligibilityRequest.status === "InProgress") {
    return (
      <Tooltip
        content={<>Eligibility request is processing</>}
        trigger={
          <Link to={linkTo}>
            <Badge text="In Progress" variant="info" clickable={true} />
          </Link>
        }
      />
    );
  }

  // If the eligibility request shows the coverage is not active, don't consider reverification status
  // cause we'll show the coverage is not active using the EligibilityBadge
  if (
    eligibilityRequest.eligible &&
    eligibilityRequest.reverificationStatus.needsReverification
  ) {
    return (
      <Tooltip
        content={
          <div className="flex flex-col">
            <div>{eligibilityRequest.reverificationStatus.reason}.</div>
            <div>{lastVerifiedAtBlurb}</div>
          </div>
        }
        trigger={
          <Link to={linkTo}>
            <Badge
              text="Reverification Needed"
              variant="warning"
              clickable={true}
            />
          </Link>
        }
      />
    );
  }

  return (
    <Tooltip
      content={
        <div className="flex flex-col">
          <div>{lastVerifiedAtBlurb}</div>
          {eligibilityRequest.allRequestsErrored && (
            <div className="flex flex-col">
              <span>
                {eligibilityRequest.deduplicatedErrors.length} errors found.
              </span>
              <ul className="list-disc list-inside">
                {eligibilityRequest.deduplicatedErrors.map((error) => (
                  <li key={error.id}>
                    {eligibilityResponseErrorDisplay(error)}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      }
      trigger={
        <Link to={linkTo}>
          <EligibilityBadge eligibility={eligibilityRequest} clickable={true} />
        </Link>
      }
    />
  );
};

export type CreateEstimateAppointment = Pick<
  Appointment,
  "id" | "start" | "account" | "provider" | "accountCoverages"
>;

// TODO: move to shared lib
export const ControlledDollarInput: React.FC<
  React.PropsWithChildren<{
    control: Control<any>;
    name: string;
    onValueChange?: (amount: string | null) => void;
    disabled?: boolean;
    required?: boolean;
    dirty?: boolean;
  }>
> = ({ control, name, onValueChange, disabled, required, dirty }) => {
  // default to noop
  const noop = () => {};
  const onValChange = onValueChange ?? noop;
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: {
          isNumber: (v) => {
            if (!isDefined(v)) return true;
            const number = Number.parseFloat(v);
            return !Number.isNaN(number);
          },
        },
        required,
      }}
      render={({ field: { onChange, name, value } }) => (
        <DollarInput
          containerClassName="relative rounded-md border"
          className={classNames(
            "rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full min-h-[2em] pl-7 py-1 sm:text-sm border-gray-300",
            dirty ? "bg-yellow-100" : ""
          )}
          placeholder="0.00"
          decimalsLimit={2}
          name={name}
          value={value}
          step={0.01}
          min={0}
          required={required}
          onValueChange={(amount) => {
            onChange(amount ?? null);
            // Optional hook
            onValChange(amount ?? null);
          }}
          disabled={disabled}
        />
      )}
    />
  );
};

const DeductibleProgress: React.FC<{
  adjustedRemainingDeductible: number | null;
  maxDeductible: number | null;
}> = ({ adjustedRemainingDeductible, maxDeductible }) => {
  let progress = 0;
  if (isDefined(adjustedRemainingDeductible)) {
    if (isDefined(maxDeductible)) {
      progress = 1 - adjustedRemainingDeductible / maxDeductible;
    } else {
      // If only the remaining deductible is defined, set progress based on overall remaining
      if (adjustedRemainingDeductible === 0) {
        progress = 1;
      } else if (adjustedRemainingDeductible < 300) {
        progress == 0.67;
      } else if (adjustedRemainingDeductible < 500) {
        progress = 0.34;
      }
    }
  }
  return (
    <div className="flex">
      {[0.33, 0.66, 1].map((threshold, i) => (
        <div
          key={i}
          className={cn(
            "h-4 w-1.5 rounded-lg",
            progress >= threshold ? "bg-emerald-500" : "bg-slate-300",
            i === 0 ? "" : "ml-0.5"
          )}
        />
      ))}
    </div>
    // <Tracker
    //   className="h-4 w-6"
    //   data={[0.33, 0.66, 1].map((threshold) => ({
    //     color: progress >= threshold ? "emerald" : "slate",
    //   }))}
    // />
  );
};

export const CoverageBenefitCompactDisplay: React.FC<{
  benefits: CoverageBenefit;
}> = ({ benefits }) => {
  const zeroCopay = benefits.copay === 0;
  const zeroCoinsurance = benefits.coinsurance === "0";

  let copayApplies = isDefined(benefits.copay);
  let coinsuranceApplies = isDefined(benefits.coinsurance);

  // If the copay is $0 and the coinsurance is defined and non zero, then the copay doesn't apply
  if (copayApplies && zeroCopay && coinsuranceApplies && !zeroCoinsurance) {
    copayApplies = false;
  }
  if (coinsuranceApplies && zeroCoinsurance && copayApplies && !zeroCopay) {
    coinsuranceApplies = false;
  }

  const showDeductible =
    benefits.deductibleApplies === false ||
    isDefined(benefits.adjustedRemainingDeductible) ||
    isDefined(benefits.maxDeductible);

  const oopMet = benefits.adjustedRemainingOutOfPocket === 0;
  const dedMet = benefits.adjustedRemainingDeductible === 0;

  const authRequired = benefits.authRequired;

  if (benefits.nonCovered) {
    return (
      <div key={benefits.id} className="flex items-center gap-4">
        <div className="text-red-700 text-sm">Not covered</div>
      </div>
    );
  }

  if (benefits.empty) {
    return (
      <div key={benefits.id} className="flex items-center gap-4">
        <div className="text-gray-700 italic text-sm">No benefits tracked</div>
      </div>
    );
  }

  return (
    <div
      key={benefits.id}
      className="flex items-center gap-2 border border-slate-100 p-1 rounded-md"
    >
      {copayApplies && (
        <div className="flex items-baseline gap-1 text-base">
          <span className="text-sm text-gray-500">CP</span>{" "}
          {formatUSD(benefits.copay!)}
        </div>
      )}
      {coinsuranceApplies && (
        <div className="flex items-baseline gap-1 text-base">
          <span className="text-sm text-gray-500">CI</span>{" "}
          {formatPercentage(benefits.coinsurance!)}
        </div>
      )}
      {showDeductible && (
        <div className="flex items-center gap-1 text-base">
          <span className="text-sm text-gray-500">Ded</span>{" "}
          {benefits.deductibleApplies ? (
            dedMet ? (
              <div className="flex items-center gap-1 text-base">
                <CheckIcon className="w-4 h-4 text-green-500" />
              </div>
            ) : (
              <DeductibleProgress
                remainingDeductible={benefits.adjustedRemainingDeductible}
                maxDeductible={benefits.maxDeductible}
              />
            )
          ) : (
            <ShieldOff className="w-4 h-4 text-gray-500" />
          )}
        </div>
      )}
      {oopMet && (
        <div className="flex items-baseline gap-1 text-base">
          <span className="text-sm text-gray-500">OOP</span>{" "}
          <CheckIcon className="w-4 h-4 text-green-500" />
        </div>
      )}
      {authRequired && (
        <ExclamationTriangleIcon className="h-4 w-4 text-red-500" />
      )}
    </div>
  );
};

const lightSky = {
  class: "bg-sky-100",
  hex: "#e0f2fe",
};
const mediumSky = {
  class: "bg-sky-400",
  hex: "#38bdf8",
};
const darkSky = {
  class: "bg-sky-600",
  hex: "#0284c7",
};
const lightGreen = {
  class: "bg-green-100",
  hex: "#bbf7d0",
};
const mediumGreen = {
  class: "bg-green-400",
  hex: "#4ade80",
};
const darkGreen = {
  class: "bg-green-600",
  hex: "#16a34a",
};
const emerald = {
  class: "bg-emerald-400",
  hex: "#34d399",
};
const red = {
  class: "bg-red-500",
  hex: "#ef4444",
};
const yellow = {
  class: "bg-amber-200",
  hex: "#fde68a",
};
const amber = {
  class: "bg-amber-400",
  hex: "#fbbf24",
};
const gray = {
  class: "bg-slate-100",
  hex: "#f1f5f9",
};

export const AppointmentStats: React.FC<
  React.PropsWithChildren<{
    totalAppointments: number;
    pastAppointments: number;
    currentAppointments: number;
    upcomingAppointments: number;
    appointmentsInsuranceVerifiable: number;
    activeInsuranceAppointments: number;
    inactiveInsuranceAppointments: number;
    reverificationInsuranceAppointments?: number;
    errorInsuranceAppointments: number;
    unverifiedInsuranceAppointments: number;
    estimatedAppointments: number;
    todaysEstimatedCharges: number;
    todaysCollectionTarget?: number;
    paidAppointments: number;
    todaysPayments: number;
    setStatusFilter: (filter: "Past" | "Current" | "Upcoming" | null) => void;
    setInsuranceVerificationFilter: (
      filter:
        | "Active"
        | "Inactive"
        | "NeedsReverification"
        | "Error"
        | "Unverified"
        | null
    ) => void;
    setEstimationFilter: (filter: "Estimated" | "Unestimated" | null) => void;
    setPaymentFilter: (filter: "Paid" | "Unpaid" | null) => void;
  }>
> = ({
  totalAppointments,
  pastAppointments,
  currentAppointments,
  upcomingAppointments,
  appointmentsInsuranceVerifiable,
  activeInsuranceAppointments,
  inactiveInsuranceAppointments,
  reverificationInsuranceAppointments,
  errorInsuranceAppointments,
  unverifiedInsuranceAppointments,
  estimatedAppointments,
  todaysEstimatedCharges,
  todaysCollectionTarget,
  paidAppointments,
  todaysPayments,
  setStatusFilter,
  setInsuranceVerificationFilter,
  setEstimationFilter,
  setPaymentFilter,
}) => {
  const flags = useFeatureFlags();
  const appointmentChartData: ChartData<"doughnut", number[], string> = {
    labels: ["Past", "Current", "Upcoming"],
    datasets: [
      {
        data: [pastAppointments, currentAppointments, upcomingAppointments],
        backgroundColor: [lightSky.hex, mediumSky.hex, darkSky.hex],
        borderColor: [lightSky.hex, mediumSky.hex, darkSky.hex],
        borderWidth: 1,
      },
    ],
  };
  const insuranceChartData: ChartData<"doughnut", number[], string> = {
    labels: [
      "Active",
      "Inactive",
      ...(isDefined(reverificationInsuranceAppointments)
        ? ["Reverification"]
        : []),
      "Error",
      "Unverified",
    ],
    datasets: [
      {
        data: [
          activeInsuranceAppointments,
          inactiveInsuranceAppointments,
          ...(isDefined(reverificationInsuranceAppointments)
            ? [reverificationInsuranceAppointments]
            : []),
          errorInsuranceAppointments,
          unverifiedInsuranceAppointments,
        ],
        backgroundColor: [
          emerald.hex,
          yellow.hex,
          ...(isDefined(reverificationInsuranceAppointments)
            ? [amber.hex]
            : []),
          red.hex,
          gray.hex,
        ],
        borderColor: [
          emerald.hex,
          yellow.hex,
          ...(isDefined(reverificationInsuranceAppointments)
            ? [amber.hex]
            : []),
          red.hex,
          gray.hex,
        ],
        borderWidth: 1,
      },
    ],
  };
  const estimateChartData: ChartData<"doughnut", number[], string> = {
    labels: ["Estimated", "Unestimated"],
    datasets: [
      {
        data: [
          estimatedAppointments,
          totalAppointments - estimatedAppointments,
        ],
        backgroundColor: [emerald.hex, gray.hex],
        borderColor: [emerald.hex, gray.hex],
        borderWidth: 1,
      },
    ],
  };
  const paymentsChartData: ChartData<"doughnut", number[], string> = {
    labels: ["Paid", "Unpaid"],
    datasets: [
      {
        data: [paidAppointments, totalAppointments - paidAppointments],
        backgroundColor: [emerald.hex, gray.hex],
        borderColor: [emerald.hex, gray.hex],
        borderWidth: 1,
      },
    ],
  };

  let columns = 1;
  if (flags.benefitsProductEnabled) {
    columns += 1;
  }
  if (flags.estimatesEnabled) {
    columns += 1;
  }
  if (
    flags.chargesSupported &&
    (flags.postVisitBillingEnabled || flags.tosCollectionEnabled)
  ) {
    columns += 1;
  }
  let gridColClass;
  if (columns === 2) gridColClass = "md:grid-cols-2";
  if (columns === 3) gridColClass = "md:grid-cols-3";
  if (columns === 4) gridColClass = "md:grid-cols-4";
  return (
    <dl
      className={`w-full grid divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow grid-cols-1 ${gridColClass} md:divide-y-0 md:divide-x`}
    >
      <div className="px-4 py-5 sm:py-4 sm:px-8 flex col-span-1">
        <div className="text-gray-900 w-full">
          <div className="flex justify-between flex-wrap">
            <div className="flex flex-col">
              <dt className="flex text-base font-normal">
                Today's Appointments
              </dt>
              <div className="relative w-24">
                <Doughnut
                  data={appointmentChartData}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                        position: "left",
                      },
                    },
                    layout: {
                      padding: 4,
                    },
                  }}
                />
                <div className="w-full h-full absolute top-[50%] left-0 mt-[-10px] text-center text-sm font-medium">
                  {totalAppointments !== 0 && totalAppointments}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center text-sm">
              <button
                className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                onClick={() => setStatusFilter("Past")}
              >
                <div className="flex gap-1 items-center text-gray-700">
                  <StatusIndicator colorClass={lightSky.class} />
                  Past
                </div>
                <div className="font-medium text-gray-900">
                  {pastAppointments}
                </div>
              </button>
              <button
                className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                onClick={() => setStatusFilter("Current")}
              >
                <div className="flex gap-1 items-center text-gray-700">
                  <StatusIndicator colorClass={mediumSky.class} />
                  Current
                </div>
                <div className="font-medium text-gray-900">
                  {currentAppointments}
                </div>
              </button>
              <button
                className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                onClick={() => setStatusFilter("Upcoming")}
              >
                <div className="flex gap-1 items-center text-gray-700">
                  <StatusIndicator colorClass={darkSky.class} />
                  Upcoming
                </div>
                <div className="font-medium text-gray-900">
                  {upcomingAppointments}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {flags.benefitsProductEnabled && (
        <div className="px-4 py-5 sm:py-4 sm:px-8 flex col-span-1">
          <div className="text-gray-900 w-full">
            <div className="flex justify-between flex-wrap">
              <div className="flex flex-col">
                <dt className="flex text-base font-normal">
                  Insurance Verified
                </dt>
                <div className="relative w-24">
                  <Doughnut
                    data={insuranceChartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                          position: "left",
                        },
                      },
                      layout: {
                        padding: 4,
                      },
                    }}
                  />
                  <div className="w-full h-full absolute top-[50%] left-0 mt-[-10px] text-center text-sm font-medium">
                    {appointmentsInsuranceVerifiable !== 0 &&
                      formatPercentage(
                        activeInsuranceAppointments /
                          appointmentsInsuranceVerifiable,
                        0
                      )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center text-sm">
                <button
                  className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                  onClick={() => setInsuranceVerificationFilter("Active")}
                >
                  <div className="flex gap-1 items-center text-gray-700">
                    <StatusIndicator colorClass={emerald.class} />
                    Active
                  </div>
                  <div className="font-medium text-gray-900">
                    {activeInsuranceAppointments}
                  </div>
                </button>
                <button
                  className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                  onClick={() => setInsuranceVerificationFilter("Inactive")}
                >
                  <div className="flex gap-1 items-center text-gray-700">
                    <StatusIndicator colorClass={yellow.class} />
                    Inactive
                  </div>
                  <div className="font-medium text-gray-900">
                    {inactiveInsuranceAppointments}
                  </div>
                </button>
                {isDefined(reverificationInsuranceAppointments) && (
                  <button
                    className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                    onClick={() =>
                      setInsuranceVerificationFilter("NeedsReverification")
                    }
                  >
                    <div className="flex gap-1 items-center text-gray-700">
                      <StatusIndicator colorClass={amber.class} />
                      Reverification
                    </div>
                    <div className="font-medium text-gray-900">
                      {reverificationInsuranceAppointments}
                    </div>
                  </button>
                )}
                <button
                  className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                  onClick={() => setInsuranceVerificationFilter("Error")}
                >
                  <div className="flex gap-1 items-center text-gray-700">
                    <StatusIndicator colorClass={red.class} />
                    Error
                  </div>
                  <div className="font-medium text-gray-900">
                    {errorInsuranceAppointments}
                  </div>
                </button>
                <button
                  className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                  onClick={() => setInsuranceVerificationFilter("Unverified")}
                >
                  <div className="flex gap-1 items-center text-gray-700">
                    <StatusIndicator colorClass={gray.class} />
                    Unverified
                  </div>
                  <div className="font-medium text-gray-900">
                    {unverifiedInsuranceAppointments}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {flags.estimatesEnabled && (
        <div className="px-4 py-5 sm:py-4 sm:px-8 flex col-span-1">
          <div className="text-gray-900 w-full">
            <div className="flex justify-between flex-wrap">
              <div className="flex flex-col">
                <dt className="flex text-base font-normal">
                  Appointments Estimated
                </dt>
                <div className="relative w-24">
                  <Doughnut
                    data={estimateChartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                          position: "left",
                        },
                      },
                      layout: {
                        padding: 4,
                      },
                    }}
                  />
                  <div className="w-full h-full absolute top-[50%] left-0 mt-[-10px] text-center text-sm font-medium">
                    {totalAppointments !== 0 &&
                      formatPercentage(
                        estimatedAppointments / totalAppointments,
                        0
                      )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center text-sm">
                <button
                  className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                  onClick={() => setEstimationFilter("Estimated")}
                >
                  <div className="flex gap-1 items-center text-gray-700">
                    <StatusIndicator colorClass={emerald.class} />
                    Estimated
                  </div>
                  <div className="font-medium text-gray-900">
                    {estimatedAppointments}
                  </div>
                </button>
                <button
                  className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                  onClick={() => setEstimationFilter("Unestimated")}
                >
                  <div className="flex gap-1 items-center text-gray-700">
                    <StatusIndicator colorClass={gray.class} />
                    Unestimated
                  </div>
                  <div className="font-medium text-gray-900">
                    {totalAppointments - estimatedAppointments}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {flags.chargesSupported &&
        (flags.postVisitBillingEnabled || flags.tosCollectionEnabled) && (
          <div className="px-4 py-5 sm:py-4 sm:px-8 flex col-span-1">
            <div className="text-gray-900 w-full">
              <div className="flex justify-between flex-wrap">
                <div className="flex flex-col">
                  <dt className="flex text-base font-normal">
                    Appointments Paid
                  </dt>
                  <div className="relative w-24">
                    <Doughnut
                      data={paymentsChartData}
                      options={{
                        plugins: {
                          legend: {
                            display: false,
                            position: "left",
                          },
                        },
                        layout: {
                          padding: 4,
                        },
                      }}
                    />
                    <div className="w-full h-full absolute top-[50%] left-0 mt-[-10px] text-center text-sm font-medium">
                      {totalAppointments !== 0 &&
                        formatPercentage(
                          paidAppointments / totalAppointments,
                          0
                        )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center text-sm">
                  <button
                    className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                    onClick={() => setPaymentFilter("Paid")}
                  >
                    <div className="flex gap-1 items-center text-gray-700">
                      <StatusIndicator colorClass={emerald.class} />
                      Paid
                    </div>
                    <div className="font-medium text-gray-900">
                      {paidAppointments}
                    </div>
                  </button>
                  <button
                    className="flex justify-between gap-4 py-0.5 px-2 hover:bg-gray-100 rounded-md"
                    onClick={() => setPaymentFilter("Unpaid")}
                  >
                    <div className="flex gap-1 items-center text-gray-700">
                      <StatusIndicator colorClass={gray.class} />
                      Unpaid
                    </div>
                    <div className="font-medium text-gray-900">
                      {totalAppointments - paidAppointments}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
    </dl>
  );
};

export const NextPollCountdown: React.FC<
  React.PropsWithChildren<{ nextPoll: Date }>
> = ({ nextPoll }) => {
  const [countdown, setCountdown] = useState(
    formatDistanceToNow(nextPoll, { addSuffix: true })
  );
  useEffect(() => {
    let myInterval = setInterval(() => {
      setCountdown(
        formatDistanceToNow(nextPoll, { addSuffix: true, includeSeconds: true })
      );
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <div className="text-sm hidden 2xl:flex flex-wrap truncate">
      Refreshing appointments {countdown}
    </div>
  );
};

export const InsurancePolicyDisplay: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: Pick<
      InsurancePolicy,
      | "effectiveDate"
      | "renewalDate"
      | "terminationDate"
      | "active"
      | "payer"
      | "memberId"
      | "priority"
    > & {
      mostRecentPriorEligibilityRequest: Pick<
        EligibilityRequest,
        "id" | "createdAt"
      > | null;
    };
    patientId: string;
  }>
> = ({ insurancePolicy, patientId }) => {
  const hasPlanDates =
    isDefined(insurancePolicy.effectiveDate) ||
    isDefined(insurancePolicy.renewalDate) ||
    isDefined(insurancePolicy.terminationDate);
  const planDates = [
    insurancePolicy.effectiveDate
      ? toDateMMDDYYYY(insurancePolicy.effectiveDate)
      : "NA",
    insurancePolicy.active
      ? insurancePolicy.renewalDate
        ? toDateMMDDYYYY(insurancePolicy.renewalDate)
        : "NA"
      : insurancePolicy.terminationDate
      ? toDateMMDDYYYY(insurancePolicy.terminationDate)
      : "NA",
  ].join("-");
  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="pt-2 flex justify-between gap-2">
        <div className="flex flex-col items-start">
          <div className="font-medium truncate">
            {insurancePolicy.payer.name}
          </div>
          <div className="flex items-center gap-1 text-gray-700 text-sm">
            {insurancePolicy.memberId && (
              <div className="flex items-center gap-1">
                <Tooltip
                  trigger={<IdentificationIcon className="h-4" />}
                  content={<>Member ID</>}
                />
                {insurancePolicy.memberId}
              </div>
            )}
            {hasPlanDates && (
              <div className="flex items-center gap-1">
                <Tooltip
                  trigger={<CalendarIcon className="h-4" />}
                  content={<>Plan Dates</>}
                />
                {planDates}
              </div>
            )}
          </div>
        </div>
        <div className="space-x-1">
          {insurancePolicy.priority && (
            <Badge text={insurancePolicy.priority} variant="info" />
          )}
          <Badge
            text={insurancePolicy.active ? "Active" : "Inactive"}
            variant={insurancePolicy.active ? "success" : "warning"}
          />
        </div>
      </div>
      {insurancePolicy.mostRecentPriorEligibilityRequest ? (
        <div className="flex gap-2">
          <div className="italic text-gray-600">
            Last verified at{" "}
            {format(
              parseISO(
                insurancePolicy.mostRecentPriorEligibilityRequest.createdAt
              ),
              "yyyy-MM-dd hh:mm bb"
            )}
          </div>
          <Link
            to={`/patients/${patientId}/eligibilities/${insurancePolicy.mostRecentPriorEligibilityRequest.id}`}
            className="whitespace-nowrap font-medium text-indigo-700 hover:text-indigo-600"
          >
            View eligibility request
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      ) : (
        <div className="flex gap-2">
          <div className="italic text-gray-600">
            No eligibility request present
          </div>
        </div>
      )}
    </div>
  );
};

export const BillStatusDisplay: React.FC<
  React.PropsWithChildren<{
    bill: Pick<UnpaidBill, "status"> & { charges: { id: string }[] };
  }>
> = ({ bill }) => {
  const indicator = getBillIndicator(bill);
  const chargesEntered = bill.charges.length > 0;
  let tooltip;
  switch (bill.status) {
    case BillState.Estimated:
      if (chargesEntered) {
        tooltip =
          "Patient responsibility estimated and waiting for charges to be entered.";
        break;
      } else {
        tooltip =
          "Estimated responsibility for visits that have not had charges entered yet or bills that have are waiting for insurance to process.";
        break;
      }
    case BillState.Pending:
      if (chargesEntered) {
        tooltip =
          "Patient responsibility estimated and waiting for charges to be entered.";
        break;
      } else {
        tooltip =
          "Estimated responsibility for visits that have not had charges entered yet or bills that have are waiting for insurance to process.";
        break;
      }
    // return <PendingStatusIndicator />;
    case BillState.InReview:
      tooltip =
        "Charges have been finalized and is awaiting review to be marked as ready.";
      break;
    case BillState.Ready:
      tooltip =
        "Bill has been reviewed as ready for the patient to pay. This is now available to pay in the patient portal.";
      break;
    case BillState.Reconciled:
      tooltip = "The bill has been paid in full.";
      break;
    case BillState.Resolved:
      tooltip = "The bill has been paid in full.";
      break;
    case BillState.Archived:
      tooltip = "The bill has been archived.";
      break;
  }

  return (
    <Tooltip
      trigger={
        <div className="flex items-center gap-1">
          {indicator}
          {billStateDisplay(bill)}
        </div>
      }
      content={<div className="max-w-md text-center">{tooltip}</div>}
    />
  );
};

export const getBillIndicator = (
  bill: (Pick<UnpaidBill, "status"> & { charges: { id: string }[] }) | null
) => {
  if (!bill?.charges) {
    return <ClockIcon className="w-4 h-4 text-gray-300" />;
  }
  const chargesEntered = bill.charges.length > 0;
  switch (bill.status) {
    case BillState.Estimated:
      if (chargesEntered) {
        return <CheckCircleIcon className="w-4 h-4 text-green-500" />;
      } else {
        return <ClockIcon className="w-4 h-4 text-gray-300" />;
      }
    case BillState.Pending:
      if (chargesEntered) {
        return <CheckCircleIcon className="w-4 h-4 text-green-500" />;
      } else {
        return <ClockIcon className="w-4 h-4 text-gray-300" />;
      }
    // return <PendingStatusIndicator />;
    case BillState.InReview:
      return <InReviewStatusIndicator />;
    case BillState.Ready:
      return <ReadyStatusIndicator />;
    case BillState.Reconciled:
      return <PaidStatusIndicator />;
    case BillState.Resolved:
      return <PaidStatusIndicator />;
    case BillState.Archived:
      return <ArchivedStatusIndicator />;
  }
};

export const EstimateSummary: React.FC<{ bill: UnpaidBill }> = ({ bill }) => {
  const estimate = bill.toCollect.estimate!;
  const finalized = bill.toCollect.collectionMode === BillCollectionMode.Bill;

  const estimatedInsuranceResponsibility =
    estimate.allowedAmount - estimate.totalPatientResponsibility;

  const deduplicatedCharges = estimate.estimatedCharges.reduce<{
    [chargemasterId: string]: EstimatedCharge & {
      patient: number;
      insurance: number;
    };
  }>((acc, charge) => {
    const existingCharge = acc[charge.chargemaster.id];
    if (existingCharge) {
      return {
        ...acc,
        [charge.chargemaster.id]: {
          ...existingCharge,
          patient: existingCharge.patient + charge.totalPatientResponsibility,
          insurance: existingCharge.insurance + charge.insuranceAmount,
        },
      };
    }
    return {
      ...acc,
      [charge.chargemaster.id]: {
        ...charge,
        patient: charge.totalPatientResponsibility,
        insurance: charge.insuranceAmount,
      },
    };
  }, {});

  // TODO
  const notCoveredAmount = 0;
  return (
    <>
      <table className="divide-y border w-full">
        <thead className="divide-x">
          <th scope="col" className="text-sm p-1">
            Code
          </th>
          <th scope="col" className="text-sm p-1">
            Units
          </th>
          <th scope="col" className="text-sm p-1">
            Description
          </th>
          <th scope="col" className="text-sm p-1">
            Allowed Amt
          </th>
          <th scope="col" className="text-sm p-1">
            Insurance Resp.
          </th>
          <th scope="col" className="text-sm p-1">
            Patient Resp.
          </th>
        </thead>
        <tbody className="space-y-1 divide-y">
          {Object.entries(deduplicatedCharges)
            .sort((a, b) =>
              a[1].chargemaster.code.localeCompare(b[1].chargemaster.code)
            )
            .map(([code, charge]) => {
              return (
                <tr className="divide-x">
                  <td className="p-1 text-center">
                    {charge.chargemaster.code}
                  </td>
                  <td className="p-1 text-center">{charge.units}</td>
                  <td className="p-1 truncate whitespace-nowrap max-w-[12em]">
                    {charge.chargemaster.description ?? (
                      <span className="text-gray-500 italic">
                        Not available
                      </span>
                    )}
                  </td>
                  <td className="p-1 text-right">
                    {formatUSD(charge.allowedAmount)}
                  </td>
                  <td className="p-1 text-right">
                    {formatUSD(charge.insurance)}
                  </td>
                  <td className="p-1 text-right">
                    {formatUSD(charge.patient)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="grid grid-cols-2">
        <div className="m-auto py-1 w-36">
          <CostSharingDoughnutChart
            patientResponsibility={estimate.totalPatientResponsibility}
            insuranceResponsibility={estimatedInsuranceResponsibility}
            copayAmount={estimate.copayAmount}
            coinsuranceAmount={estimate.coinsuranceAmount}
            deductibleAmount={estimate.deductibleAmount}
            otherAmount={
              estimate.totalPatientResponsibility -
              estimate.copayAmount -
              estimate.coinsuranceAmount -
              estimate.deductibleAmount
            }
            taxAmount={0}
          />
        </div>
        <dl className="space-y-1 text-sm p-1 text-gray-500">
          <div className="flex justify-between">
            <dt>Total Allowed Amount</dt>
            <dd className="text-gray-900">
              {formatUSD(estimate.allowedAmount)}
            </dd>
          </div>
          <div className="flex justify-between">
            <dt className="flex gap-1 items-center">
              {estimatedInsuranceResponsibility > 0 && (
                <StatusIndicator colorClass={lightSky.class} />
              )}
              Insurance Responsibility
            </dt>
            <dd className="text-gray-900">
              {formatUSD(estimatedInsuranceResponsibility)}
            </dd>
          </div>
          <div className="flex justify-between">
            <dt className="flex items-center gap-1">
              Patient Responsibility
              <AutoEstimateIndicator />
            </dt>
            <dd className="text-gray-900">
              {formatUSD(estimate.totalPatientResponsibility)}
            </dd>
          </div>
          {estimate.copayAmount > 0 && (
            <div className="flex justify-between">
              <dt className="flex gap-1 items-center">
                <StatusIndicator colorClass={lightGreen.class} />
                Copay
              </dt>
              <dd className="text-gray-900">
                {formatUSD(estimate.copayAmount)}
              </dd>
            </div>
          )}
          {estimate.coinsuranceAmount > 0 && (
            <div className="flex justify-between">
              <dt className="flex gap-1 items-center">
                <StatusIndicator colorClass={indigo.class} />
                Coinsurance
              </dt>
              <dd className="text-gray-900">
                {formatUSD(estimate.coinsuranceAmount)}
              </dd>
            </div>
          )}
          {estimate.deductibleAmount > 0 && (
            <div className="flex justify-between">
              <dt className="flex gap-1 items-center">
                <StatusIndicator colorClass={fucshia.class} />
                Deductible
              </dt>
              <dd className="text-gray-900">
                {formatUSD(estimate.deductibleAmount)}
              </dd>
            </div>
          )}
          {notCoveredAmount > 0 && (
            <div className="flex justify-between">
              <dt className="flex gap-1 items-center">
                <StatusIndicator colorClass={lightRed.class} />
                Not Covered
              </dt>
              <dd className="text-gray-900">{formatUSD(notCoveredAmount)}</dd>
            </div>
          )}
          {/* Don't display the payments and balance using a final bill */}
          {!finalized && (
            <>
              <div className="flex justify-between">
                <dt className="flex">Patient Paid</dt>
                <dd className="text-gray-900">
                  {formatUSD(bill.toCollect.patientPaid)}
                </dd>
              </div>
              <div className="flex justify-between text-gray-900">
                <dt className="flex font-semibold">Patient Owes</dt>
                <dd className="font-semibold">
                  {formatUSD(bill.toCollect.patientBalance)}
                </dd>
              </div>
            </>
          )}
        </dl>
      </div>
    </>
  );
};

export const BillSummary: React.FC<{ bill: UnpaidBill }> = ({ bill }) => {
  const notCoveredAmount = getBillNotCoveredAmount(bill);

  const allowedAmount = -bill.charges.reduce(
    (sum, c) => sum + c.allowedAmount,
    0
  );
  const patientResponsibility = -bill.charges.reduce(
    (sum, c) => sum + c.patientResponsibilityAmount,
    0
  );
  const insuranceResponsibility = allowedAmount - patientResponsibility;
  const copayAmount = -bill.charges.reduce((sum, c) => sum + c.copayAmount, 0);
  const coinsuranceAmount = -bill.charges.reduce(
    (sum, c) => sum + c.coinsuranceAmount,
    0
  );
  const deductibleAmount = -bill.charges.reduce(
    (sum, c) => sum + c.deductibleAmount,
    0
  );
  const otherAmount = -bill.charges.reduce((sum, c) => sum + c.otherAmount, 0);
  const taxAmount = -bill.charges.reduce(
    (sum, c) => sum + (c.taxAmount ?? 0),
    0
  );
  const patientBalance = patientResponsibility - bill.toCollect.patientPaid;

  return (
    <>
      <table className="divide-y border w-full">
        <thead className="divide-x">
          <th scope="col" className="text-sm p-1">
            Code
          </th>
          <th scope="col" className="text-sm p-1">
            Units
          </th>
          <th scope="col" className="text-sm p-1">
            Description
          </th>
          <th scope="col" className="text-sm p-1">
            Allowed Amt
          </th>
          <th scope="col" className="text-sm p-1">
            Insurance Resp.
          </th>
          <th scope="col" className="text-sm p-1">
            Patient Resp.
          </th>
        </thead>
        <tbody className="space-y-1 divide-y">
          {[...bill.charges]
            .sort((a, b) =>
              (a.customCode ?? "").localeCompare(b.customCode ?? "")
            )
            .map((c) => {
              return (
                <tr className="divide-x">
                  <td className="p-1 text-center">{c.customCode}</td>
                  <td className="p-1 text-center">{c.units}</td>
                  <td className="p-1 truncate whitespace-nowrap max-w-[12em]">
                    {c.transaction.description
                      ? c.transaction.description
                      : c.chargemaster?.chargemasterGroup?.description}
                  </td>
                  <td className="p-1 text-right">
                    {formatUSD(-c.allowedAmount)}
                  </td>
                  <td className="p-1 text-right">
                    {formatUSD(
                      -c.allowedAmount - -c.patientResponsibilityAmount
                    )}
                  </td>
                  <td className="p-1 text-right">
                    {formatUSD(-c.patientResponsibilityAmount)}
                    {/* <EstimatedPatientResponsibilityForCharge charge={c} /> */}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="grid grid-cols-2">
        <div className="m-auto py-1 w-36">
          <CostSharingDoughnutChart
            patientResponsibility={patientResponsibility}
            insuranceResponsibility={insuranceResponsibility}
            copayAmount={copayAmount}
            coinsuranceAmount={coinsuranceAmount}
            deductibleAmount={deductibleAmount}
            otherAmount={otherAmount}
            taxAmount={taxAmount}
          />
        </div>
        <dl className="space-y-1 text-sm p-1 text-gray-500">
          <div className="flex justify-between">
            <dt>Total Allowed Amount</dt>
            <dd className="text-gray-900">{formatUSD(allowedAmount)}</dd>
          </div>
          <div className="flex justify-between">
            <dt className="flex gap-1 items-center">
              {insuranceResponsibility > 0 && (
                <StatusIndicator colorClass={lightSky.class} />
              )}
              Insurance Responsibility
            </dt>
            <dd className="text-gray-900">
              {formatUSD(insuranceResponsibility)}
            </dd>
          </div>
          <div className="flex justify-between">
            <dt className="flex items-center gap-1">
              Patient Responsibility
              {(bill.toCollect.collectionMode === "Estimate" ||
                bill.toCollect.collectionMode === "Deposit") && (
                <AutoEstimateIndicator />
              )}
            </dt>
            <dd className="text-gray-900">
              {formatUSD(patientResponsibility)}
            </dd>
          </div>
          {copayAmount > 0 ? (
            <div className="flex justify-between">
              <dt className="flex gap-1 items-center">
                <StatusIndicator colorClass={lightGreen.class} />
                Copay
              </dt>
              <dd className="text-gray-900">{formatUSD(copayAmount)}</dd>
            </div>
          ) : null}
          {coinsuranceAmount > 0 ? (
            <div className="flex justify-between">
              <dt className="flex gap-1 items-center">
                <StatusIndicator colorClass={indigo.class} />
                Coinsurance
              </dt>
              <dd className="text-gray-900">{formatUSD(coinsuranceAmount)}</dd>
            </div>
          ) : null}
          {bill.toCollect.deductibleAmount &&
          bill.toCollect.deductibleAmount > 0 ? (
            <div className="flex justify-between">
              <dt className="flex gap-1 items-center">
                <StatusIndicator colorClass={fucshia.class} />
                Deductible
              </dt>
              <dd className="text-gray-900">
                {formatUSD(bill.toCollect.deductibleAmount)}
              </dd>
            </div>
          ) : null}
          {otherAmount > 0 ? (
            <div className="flex justify-between">
              <dt className="flex gap-1 items-center">
                <StatusIndicator colorClass={orange.class} />
                Other
              </dt>
              <dd className="text-gray-900">{formatUSD(otherAmount)}</dd>
            </div>
          ) : null}
          {notCoveredAmount > 0 && (
            <div className="flex justify-between">
              <dt className="flex gap-1 items-center">
                <StatusIndicator colorClass={lightRed.class} />
                Not Covered
              </dt>
              <dd className="text-gray-900">{formatUSD(notCoveredAmount)}</dd>
            </div>
          )}
          <div className="flex justify-between">
            <dt className="flex">Patient Paid</dt>
            <dd className="text-gray-900">
              {formatUSD(bill.toCollect.patientPaid)}
            </dd>
          </div>
          <div className="flex justify-between text-gray-900">
            <dt className="flex font-semibold">Patient Owes</dt>
            <dd className="font-semibold">{formatUSD(patientBalance)}</dd>
          </div>
        </dl>
      </div>
    </>
  );
};

const lightRed = {
  class: "bg-red-200",
  hex: "#fecaca",
};
const lightYellow = {
  class: "bg-yellow-100",
  hex: "#fef9c3",
};
const fucshia = {
  class: "bg-fuchsia-200",
  hex: "#f5d0fe",
};
const indigo = {
  class: "bg-indigo-200",
  hex: "#c7d2fe",
};
const orange = {
  class: "bg-orange-200",
  hex: "#fed7aa",
};

const getBillNotCoveredAmount = (bill: Pick<UnpaidBill, "toCollect">) => {
  const allowedAmount = bill.toCollect.allowedAmountTotal ?? 0;
  const insuranceResponsibility =
    allowedAmount - bill.toCollect.patientResponsibility;
  return (
    (bill.toCollect.allowedAmountTotal ?? 0) -
    insuranceResponsibility -
    (bill.toCollect.coinsuranceAmount ?? 0) -
    (bill.toCollect.copayAmount ?? 0) -
    (bill.toCollect.deductibleAmount ?? 0) -
    (bill.toCollect.otherAmount ?? 0)
  );
};

const CostSharingDoughnutChart: React.FC<{
  patientResponsibility: number;
  insuranceResponsibility: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  otherAmount: number;
  taxAmount: number;
}> = ({
  patientResponsibility,
  insuranceResponsibility,
  copayAmount,
  coinsuranceAmount,
  deductibleAmount,
  otherAmount,
  taxAmount,
}) => {
  return (
    <Doughnut
      data={{
        labels: [
          "Insurance Covers",
          "Copay",
          "Coinsurance",
          "Deductible",
          "Tax Amount",
          "Other",
          // "Not Covered",
        ],
        datasets: [
          {
            data: [
              insuranceResponsibility,
              copayAmount,
              coinsuranceAmount,
              deductibleAmount,
              taxAmount,
              otherAmount,
              // notCoveredAmount,
            ],
            backgroundColor: [
              lightSky.hex,
              lightGreen.hex,
              indigo.hex,
              fucshia.hex,
              lightYellow.hex,
              orange.hex,
              lightRed.hex,
            ],
            borderColor: [
              lightSky.hex,
              lightGreen.hex,
              indigo.hex,
              fucshia.hex,
              lightYellow.hex,
              orange.hex,
              // lightRed.hex,
            ],
            borderWidth: 1,
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
            // position: "bottom",
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                return `${formatUSD(context.parsed)}`;
              },
            },
          },
        },
        layout: {
          padding: 4,
        },
      }}
    />
  );
};
