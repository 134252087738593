import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  Table as ReactTable,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { PatientVisitRow } from ".";
import { OvalSpinner } from "../../../components/loading";
import { DataTablePagination } from "../../../components/ui/table-helpers/data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
import { cn } from "../../../utils";
import { VisitSlideout } from "./visit-slideout";
import { LedgerSlideout } from "../ledger/ledger-slideout";

interface DataTableProps<TValue> {
  columns: ColumnDef<PatientVisitRow, TValue>[];
  defaultColumnFilters?: ColumnFiltersState;
  data: PatientVisitRow[];
}

export function DataTable<TValue>({
  columns,
  data,
  defaultColumnFilters,
  children,
  loading,
  loadingMore,
}: DataTableProps<TValue> & {
  children?: (table: ReactTable<PatientVisitRow>) => React.ReactElement;
  loading?: boolean;
  loadingMore?: boolean;
}) {
  const [selectedRow, setSelectedRow] =
    React.useState<PatientVisitRow | null>();
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    defaultColumnFilters ?? []
  );
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "start",
      desc: true,
    },
  ]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    autoResetPageIndex: false,
  });

  return (
    <>
      {children && children(table)}
      <div className="space-y-4">
        <div className="w-full">
          <DataTableToolbar table={table} />
        </div>
        <div className="border bg-white max-h-[80vh] overflow-x-auto">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className="sticky top-0 z-10 p-2"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    onClick={() => {
                      setSelectedRow(row.original);
                    }}
                  >
                    {row.getVisibleCells().map((cell, i) => (
                      <TableCell
                        key={cell.id}
                        className={cn(
                          "whitespace-nowrap p-2"
                          // CSS to make the last column sticky
                          // "last:sticky last:right-0  last:bg-white last:drop-shadow"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center p-2"
                  >
                    <div className="w-full flex justify-center">
                      <OvalSpinner />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center p-2"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="w-full">
          <DataTablePagination table={table} loading={loading || loadingMore} />
        </div>
      </div>
      {selectedRow &&
        (selectedRow.appointment ? (
          <VisitSlideout
            appointmentId={selectedRow.appointment.id}
            onClose={() => {
              setSelectedRow(null);
            }}
          />
        ) : (
          <LedgerSlideout
            transactionId={null}
            billId={selectedRow.id ?? null}
            onClose={() => {
              setSelectedRow(null);
            }}
          />
        ))}
    </>
  );
}
