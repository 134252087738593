import { ApolloError } from "@apollo/client";

const AUTHORIZATION_ERROR_CODE = "FORBIDDEN";

export const handleAuthorizationError = (
  error: ApolloError,
  redirect: string
) => {
  if (
    error.graphQLErrors.some(
      (e) => e.extensions?.code === AUTHORIZATION_ERROR_CODE
    )
  ) {
    window.location.href = redirect;
  }
};
