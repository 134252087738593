import React from "react";
import { Layout, LoadingLayout } from "../../layout";
import { FeeScheduleMappingForm } from "./fee-schedule-mapping-form";
import { BenefitAssignmentRuleSimulatorTable } from "../benefit-mappings/show";
import { Condition, Visit } from "../../../evaluate-rule";
import {
  GetFeeSchedules,
  GetFeeSchedulesVariables,
} from "../../../generated/GetFeeSchedules";
import { gql, useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import { useParams } from "react-router-dom";
import {
  GetFeeScheduleMapping,
  GetFeeScheduleMappingVariables,
} from "../../../generated/GetFeeScheduleMapping";
import { FEE_SCHEDULE_MAPPING_FIELDS } from "../../../graphql";
import { GET_FEESCHEDULES } from "../../fee-schedules";

const GET_FEE_SCHEDULE_MAPPING = gql`
  ${FEE_SCHEDULE_MAPPING_FIELDS}
  query GetFeeScheduleMapping($id: String!) {
    feeScheduleMapping(where: { id: $id }) {
      ...FeeScheduleMappingFields
    }
  }
`;

export const EditFeeScheduleRule: React.FC = () => {
  const params = useParams<{ id: string }>();
  const user = useUser()!;
  const { data, loading, error } = useQuery<
    GetFeeScheduleMapping,
    GetFeeScheduleMappingVariables
  >(GET_FEE_SCHEDULE_MAPPING, {
    variables: {
      id: params.id!,
    },
  });
  const feeSchedulesResult = useQuery<
    GetFeeSchedules,
    GetFeeSchedulesVariables
  >(GET_FEESCHEDULES, {
    variables: {
      locationId: user.activeLocation.id,
      pending: false,
    },
  });

  const feeScheduleMapping = data?.feeScheduleMapping;
  const feeSchedules = feeSchedulesResult.data?.feeSchedules ?? [];

  if (loading || !feeScheduleMapping) {
    return <LoadingLayout />;
  }

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">
            Edit Fee Schedule Rule
          </h1>
        </div>
      }
      content={
        loading ? (
          <div className="flex flex-col gap-4">
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
            <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          </div>
        ) : (
          <FeeScheduleMappingForm
            feeScheduleMapping={feeScheduleMapping}
            feeSchedules={feeSchedules}
          >
            {({ conditions }) => (
              <div>
                <BenefitAssignmentRuleSimulatorTable
                  conditions={conditions as Condition<keyof Visit>[]}
                />
              </div>
            )}
          </FeeScheduleMappingForm>
        )
      }
    />
  );
};
