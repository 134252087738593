import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import React, { Fragment, useMemo, useState } from "react";
import { GetPatientBills_bills_account as Account } from "../generated/GetPatientBills";
import { BillState } from "../generated/globalTypes";

import { classNames } from "../utils";
import { OvalSpinner } from "./loading";

export const Table = <T extends { id: string }>({
  columnDefs,
  rows,
  onRowClick,
  footer,
  pagination,
  loading,
  verticalLines,
}: {
  columnDefs: {
    header: string;
    headerComponent?: React.ReactNode;
    cellFn: (row: T) => React.ReactNode;
  }[];
  rows: T[];
  onRowClick?: (row: T) => void;
  footer?: React.ReactNode;
  pagination?: {
    totalCount: number;
    siblingCount: number;
    currentPage: number;
    pageSize: number;
    onPageChange: (page: number) => void;
  };
  loading?: boolean;
  verticalLines?: boolean;
}) => {
  return (
    <div className="overflow-auto border bg-white border-gray-300 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300 table-auto">
        <thead className="bg-gray-50">
          <tr
            className={classNames(
              verticalLines ? "divide-x divide-gray-300" : ""
            )}
          >
            {columnDefs.map(({ header, headerComponent }, index) => (
              <th
                scope="col"
                key={header || index}
                className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase"
              >
                {headerComponent ?? header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => onRowClick && onRowClick(row)}
              className={classNames(
                verticalLines ? "divide-x divide-gray-300" : "",
                onRowClick ? "hover:bg-gray-50 cursor-pointer" : ""
              )}
            >
              {columnDefs.map(({ cellFn }, i) => (
                <Fragment key={i}>{cellFn(row)}</Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {loading ? (
        <div className="flex min-h-[50vh]">
          <div className="m-auto">
            <OvalSpinner className="text-indigo-300 h-8 w-8" />
          </div>
        </div>
      ) : (
        rows.length === 0 && <div className="text-center py-2">No data</div>
      )}
      {footer && <div className="border-t border-gray-200">{footer}</div>}
      {pagination && (
        <PaginationComponent
          onPageChange={pagination.onPageChange}
          totalCount={pagination.totalCount}
          siblingCount={pagination.siblingCount}
          currentPage={pagination.currentPage}
          pageSize={pagination.pageSize}
        />
      )}
    </div>
  );
};

export const FullWidthTable = <T extends { id: string }>({
  columnDefs,
  rows,
  onRowClick,
  footer,
  pagination,
  loading,
  verticalLines,
}: {
  columnDefs: {
    header: string;
    headerComponent?: React.ReactNode;
    cellFn: (row: T) => React.ReactNode;
  }[];
  rows: T[];
  onRowClick?: (row: T) => void;
  footer?: React.ReactNode;
  pagination?: {
    totalCount: number;
    siblingCount: number;
    currentPage: number;
    pageSize: number;
    onPageChange: (page: number) => void;
  };
  loading?: boolean;
  verticalLines?: boolean;
}) => {
  return (
    <div className="overflow-auto border-y bg-white">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr
            className={classNames(
              "bg-white",
              verticalLines ? "divide-x divide-gray-300" : ""
            )}
          >
            {columnDefs.map(({ header, headerComponent }, index) => (
              <th
                scope="col"
                key={header || index}
                className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase"
              >
                {headerComponent ?? header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => onRowClick && onRowClick(row)}
              className={classNames(
                verticalLines ? "divide-x divide-gray-300" : "",
                onRowClick ? "hover:bg-gray-50 cursor-pointer" : ""
              )}
            >
              {columnDefs.map(({ cellFn }, i) => (
                <Fragment key={i}>{cellFn(row)}</Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {loading ? (
        <div className="flex min-h-[50vh]">
          <div className="m-auto">
            <OvalSpinner className="text-indigo-300 h-8 w-8" />
          </div>
        </div>
      ) : (
        rows.length === 0 && <div className="text-center py-2">No data</div>
      )}
      {footer && <div className="border-t border-gray-200">{footer}</div>}
      {pagination && (
        <PaginationComponent
          onPageChange={pagination.onPageChange}
          totalCount={pagination.totalCount}
          siblingCount={pagination.siblingCount}
          currentPage={pagination.currentPage}
          pageSize={pagination.pageSize}
        />
      )}
    </div>
  );
};

// https://tailwindui.com/components/application-ui/lists/tables#component-3fc4f58b97988d00656bc99fbd1a81ae
export const GroupedRowTable = <
  G extends { rows: (T & { classNames?: string })[]; key: string },
  T extends { id: string }
>({
  columnDefs,
  groupings,
  groupingRowFn,
  onRowClick,
  footer,
  pagination,
  loading,
}: {
  columnDefs: {
    header: string;
    headerComponent?: React.ReactNode;
    cellFn: (row: T) => React.ReactNode;
  }[];
  groupings: G[];
  groupingRowFn: (grouping: G) => React.ReactNode;
  onRowClick?: (row: T) => void;
  footer?: React.ReactNode;
  pagination?: {
    totalCount: number;
    siblingCount: number;
    currentPage: number;
    pageSize: number;
    onPageChange: (page: number) => void;
  };
  loading?: boolean;
}) => {
  return (
    <div className="border bg-white border-gray-300 rounded-lg overflow-y-scroll max-h-[80vh]">
      <table className="min-w-full divide-y divide-gray-300 table-auto">
        <thead className="bg-gray-50 rounded-t-lg sticky top-0">
          <tr className="rounded-t-lg">
            {columnDefs.map(({ header, headerComponent }, index) => (
              <th
                scope="col"
                key={header || index}
                className={classNames(
                  "px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase",
                  index === 0 ? "rounded-tl-lg" : "",
                  index === columnDefs.length - 1 ? "rounded-tr-lg" : ""
                )}
              >
                {headerComponent ?? header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 rounded-b-lg">
          {groupings.map((grouping, index) => (
            <Fragment key={grouping.key}>
              <tr className="border-t border-gray-200">
                <th
                  colSpan={columnDefs.length}
                  scope="colgroup"
                  className="bg-gray-50 px-2 py-2 text-left text-sm font-semibold text-gray-900"
                >
                  {groupingRowFn(grouping)}
                </th>
              </tr>
              {grouping.rows.map((row, index) => (
                <tr
                  key={row.id}
                  onClick={() => onRowClick && onRowClick(row)}
                  className={classNames(
                    // "border-t border-gray-200",
                    row.classNames ?? "border-t border-gray-200",
                    onRowClick ? "hover:bg-gray-50 cursor-pointer" : "",
                    index === 0 ? "rounded-lb-lg" : "",
                    index === grouping.rows.length - 1 ? "rounded-rb-lg" : ""
                  )}
                >
                  {columnDefs.map(({ cellFn }, i) => (
                    <Fragment key={i}>{cellFn(row)}</Fragment>
                  ))}
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
      {loading ? (
        <div className="flex min-h-[50vh]">
          <div className="m-auto">
            <OvalSpinner className="text-indigo-300 h-8 w-8" />
          </div>
        </div>
      ) : (
        groupings.length === 0 && (
          <div className="text-center py-2">No data</div>
        )
      )}
      {footer && <div className="border-t border-gray-200">{footer}</div>}
      {pagination && (
        <PaginationComponent
          onPageChange={pagination.onPageChange}
          totalCount={pagination.totalCount}
          siblingCount={pagination.siblingCount}
          currentPage={pagination.currentPage}
          pageSize={pagination.pageSize}
        />
      )}
    </div>
  );
};

export const HeaderlessGroupingTable = <
  // Grouping extends Bill,
  Grouping extends {
    id: string;
    status: BillState;
    dateOfServiceDisplay: string | null;
    charges: Sub[];
    account: Account;
  },
  Sub
>({
  columnDefs,
  subTableColumnDefs,
  rows,
  loading,
  expandButtonColumnIndex,
}: {
  columnDefs: {
    header: string;
    headerComponent?: React.ReactNode;
    cellFn: (row: Grouping) => React.ReactNode;
    colSpan?: number;
  }[];
  subTableColumnDefs: {
    cellFn: (bill: Sub) => React.ReactNode;
    colSpan?: number;
  }[];
  rows: Grouping[];
  loading?: boolean;
  expandButtonColumnIndex?: number;
}) => {
  return (
    <div className="bg-white sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300 table-auto">
        <thead>
          <tr>
            {columnDefs.map(({ header, headerComponent, colSpan }, index) => (
              <th
                scope="col"
                colSpan={colSpan || 1}
                key={header || index}
                className={classNames(
                  "px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase",
                  index === 0 ? "w-6" : ""
                )}
              >
                {headerComponent ?? header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200">
          {rows.map((row) => (
            <Fragment key={row.id}>
              <GroupingHeaderLessRow
                columnDefs={columnDefs}
                subTableColumnDefs={subTableColumnDefs}
                row={row}
                expandButtonColumnIndex={expandButtonColumnIndex}
              />
            </Fragment>
          ))}
        </tbody>
      </table>
      {loading ? (
        <div className="flex min-h-[50vh]">
          <div className="m-auto">
            <OvalSpinner className="text-indigo-300 h-8 w-6" />
          </div>
        </div>
      ) : (
        rows.length === 0 && <div className="text-center py-2">No data</div>
      )}
    </div>
  );
};

export const Td: React.FC<
  React.PropsWithChildren<
    { colSpan?: number; className?: string } & React.DetailedHTMLProps<
      React.TdHTMLAttributes<HTMLTableDataCellElement>,
      HTMLTableDataCellElement
    >
  >
> = ({ children, colSpan, className, ...rest }) => (
  <td
    className={classNames("px-2 py-2 whitespace-nowrap", className || "")}
    colSpan={colSpan ?? 1}
    {...rest}
  >
    {children}
  </td>
);

export const PaginationComponent = (props: {
  onPageChange: any;
  totalCount: any;
  siblingCount: number;
  currentPage: any;
  pageSize: any;
}) => {
  const { onPageChange, totalCount, siblingCount, currentPage, pageSize } =
    props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (!paginationRange) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:rounded-b-lg">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          onClick={onPrevious}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={onNext}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {Math.min((currentPage - 1) * pageSize + 1, totalCount)}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {Math.min(currentPage * pageSize, totalCount)}
            </span>{" "}
            of <span className="font-medium">{totalCount}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 ${
                currentPage === 1 ? "pointer-events-none" : ""
              }`}
              onClick={onPrevious}
              disabled={currentPage === 1}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {paginationRange.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <span
                    key={index}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                  >
                    ...
                  </span>
                );
              }
              return (
                <button
                  key={index}
                  className={
                    pageNumber === currentPage
                      ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                      : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                  }
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              );
            })}
            <button
              className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 ${
                currentPage === lastPage ? "pointer-events-none" : ""
              }`}
              onClick={onNext}
              disabled={currentPage === lastPage}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

const DOTS = "...";
const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = (props: {
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(props.totalCount / props.pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = props.siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(
      props.currentPage - props.siblingCount,
      1
    );
    const rightSiblingIndex = Math.min(
      props.currentPage + props.siblingCount,
      totalPageCount
    );

    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * props.siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * props.siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [props.totalCount, props.pageSize, props.siblingCount, props.currentPage]);

  return paginationRange;
};

export const GroupingTable = <
  Grouping extends { id: string; subRows: Sub[] },
  Sub
>({
  columnDefs,
  subTableColumnDefs,
  rows,
  footer,
  pagination,
  loading,
  expandButtonColumnIndex,
}: {
  columnDefs: {
    header: string;
    headerComponent?: React.ReactNode;
    cellFn: (row: Grouping) => React.ReactNode;
    colSpan?: number;
  }[];
  subTableColumnDefs: {
    header: string;
    headerComponent?: React.ReactNode;
    cellFn: (bill: Sub) => React.ReactNode;
    colSpan?: number;
  }[];
  rows: Grouping[];
  footer?: React.ReactNode;
  pagination?: {
    totalCount: number;
    siblingCount: number;
    currentPage: number;
    pageSize: number;
    onPageChange: (page: number) => void;
  };
  loading?: boolean;
  expandButtonColumnIndex?: number;
}) => {
  return (
    <div className="border bg-white border-gray-300 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300 table-auto sm:rounded-t-lg">
        <thead className="bg-gray-50 sm:rounded-t-lg">
          <tr>
            {columnDefs.map(({ header, headerComponent, colSpan }, index) => (
              <th
                scope="col"
                colSpan={colSpan || 1}
                key={header || index}
                className={classNames(
                  "px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase",
                  index === 0 ? "w-6 sm:rounded-tl-lg" : "",
                  index === columnDefs.length - 1 ? "sm:rounded-tr-lg" : ""
                )}
              >
                {headerComponent ?? header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 sm:rounded-b-lg">
          {rows.map((row, idx) => (
            <Fragment key={row.id}>
              <GroupingRow
                columnDefs={columnDefs}
                subTableColumnDefs={subTableColumnDefs}
                row={row}
                expandButtonColumnIndex={expandButtonColumnIndex}
                lastRow={rows.length - 1 === idx}
              />
            </Fragment>
          ))}
        </tbody>
      </table>
      {loading ? (
        <div className="flex min-h-[50vh]">
          <div className="m-auto">
            <OvalSpinner className="text-indigo-300 h-8 w-6" />
          </div>
        </div>
      ) : (
        rows.length === 0 && <div className="text-center py-2">No data</div>
      )}
      {footer && <div className="border-t border-gray-200">{footer}</div>}
      {pagination && (
        <PaginationComponent
          onPageChange={pagination.onPageChange}
          totalCount={pagination.totalCount}
          siblingCount={pagination.siblingCount}
          currentPage={pagination.currentPage}
          pageSize={pagination.pageSize}
        />
      )}
    </div>
  );
};

const GroupingHeaderLessRow = <
  Grouping extends {
    id: string;
    status: BillState;
    dateOfServiceDisplay: string | null;
    charges: Sub[];
    account: Account;
  },
  Sub
>({
  row,
  columnDefs,
  subTableColumnDefs,
  expandButtonColumnIndex,
}: {
  columnDefs: {
    header: string;
    headerComponent?: React.ReactNode;
    cellFn: (grouping: Grouping) => React.ReactNode;
    colSpan?: number;
  }[];
  subTableColumnDefs: {
    cellFn: (sub: Sub) => React.ReactNode;
    colSpan?: number;
  }[];
  row: Grouping;
  expandButtonColumnIndex?: number;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <tr className="border-t border-gray-200">
        {columnDefs.map(({ cellFn, colSpan }, index) => (
          <th
            scope="col"
            colSpan={colSpan || 1}
            key={index}
            className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase"
          >
            {index === expandButtonColumnIndex ? (
              <div className="flex space-x-1">
                <button onClick={() => setOpen(!open)}>
                  <ChevronUpIcon
                    className={`${
                      open ? "transform rotate-180" : ""
                    } w-5 h-5 self-center`}
                  />
                </button>
                {cellFn(row)}
              </div>
            ) : (
              cellFn(row)
            )}
          </th>
        ))}
      </tr>
      {row.charges.map((subRow) => (
        <tr hidden={!open} className="bg-blue-50">
          {subTableColumnDefs.map(({ cellFn }, i) => (
            <Fragment key={i}>{cellFn(subRow)}</Fragment>
          ))}
        </tr>
      ))}
    </Fragment>
  );
};

const GroupingRow = <Grouping extends { subRows: Sub[] }, Sub>({
  row,
  columnDefs,
  subTableColumnDefs,
  expandButtonColumnIndex,
  lastRow,
}: {
  columnDefs: {
    header: string;
    headerComponent?: React.ReactNode;
    cellFn: (grouping: Grouping) => React.ReactNode;
    colSpan?: number;
  }[];
  subTableColumnDefs: {
    header: string;
    headerComponent?: React.ReactNode;
    cellFn: (sub: Sub) => React.ReactNode;
    colSpan?: number;
  }[];
  row: Grouping;
  expandButtonColumnIndex?: number;
  lastRow: boolean;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <tr className="border-t border-gray-200">
        {columnDefs.map(({ cellFn, colSpan }, index) => (
          <th
            scope="col"
            colSpan={colSpan || 1}
            key={index}
            className={classNames(
              "px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase",
              index === 0 ? "w-6 sm:rounded-bl-lg" : "",
              index === columnDefs.length - 1 ? "sm:rounded-br-lg" : ""
            )}
          >
            {index === expandButtonColumnIndex ? (
              <div className="flex space-x-1 -ml-6">
                <button onClick={() => setOpen(!open)}>
                  <ChevronUpIcon
                    className={`${
                      open ? "transform rotate-180" : ""
                    } w-5 h-5 self-center`}
                  />
                </button>
                {cellFn(row)}
              </div>
            ) : (
              cellFn(row)
            )}
          </th>
        ))}
      </tr>
      <tr hidden={!open} className="bg-gray-50">
        {subTableColumnDefs.map(
          ({ header, headerComponent, colSpan }, index) => (
            <th
              scope="col"
              colSpan={colSpan || 1}
              key={header || index}
              className="px-2 py-1.5 text-left text-xs font-medium text-gray-500 uppercase"
            >
              {headerComponent ?? header}
            </th>
          )
        )}
      </tr>
      {row.subRows.map((subRow) => (
        <tr hidden={!open} className="bg-blue-50">
          {subTableColumnDefs.map(({ cellFn }, i) => (
            <Fragment key={i}>{cellFn(subRow)}</Fragment>
          ))}
        </tr>
      ))}
    </Fragment>
  );
};
