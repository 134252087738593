import { pascalCaseToReadable } from "../../../utils";

export enum BenefitCode {
  ActiveCoverage = "1",
  ActiveFullRiskCapitation = "2",
  ActiveServicesCapitated = "3",
  ActiveServicesCapitatedtoPrimaryCarePhysician = "4",
  ActivePendingInvestigation = "5",
  Inactive = "6",
  InactivePendingEligibilityUpdate = "7",
  InactivePendingInvestigation = "8",
  CoInsurance = "A",
  CoPayment = "B",
  Deductible = "C",
  BenefitDescription = "D",
  Exclusions = "E",
  Limitations = "F",
  OutofPocketStopLoss = "G",
  Unlimited = "H",
  NonCovered = "I",
  CostContainment = "J",
  Reserve = "K",
  PrimaryCareProvider = "L",
  PreexistingCondition = "M",
  ServicesRestrictedtoFollowingProvider = "N",
  NotDeemedaMedicalNecessity = "O",
  BenefitDisclaimer = "P",
  SecondSurgicalOpinionRequired = "Q",
  OtherorAdditionalPayor = "R",
  PriorYearHistory = "S",
  CardReportedLostStolen = "T",
  ContactFollowingEntityforEligibilityorBenefitInformation = "U",
  CannotProcess = "V",
  OtherSourceofData = "W",
  HealthCareFacility = "X",
  SpendDown = "Y",
  PatientReimbursement = "AA",
  CopaymentMaximum = "AB",
  CoinsuranceMaximum = "AC",
  CoverageBasis = "CB",
  ManagedCareCoordinator = "MC",
  Waiver = "WV",
}

export enum YesNoResponse {
  No = "N",
  Unknown = "U",
  NotApplicable = "W",
  Yes = "Y",
}

export interface Response {
  controlNumber?: string;
  reassociationKey?: string;
  tradingPartnerId?: string;
  tradingPartnerServiceId?: string;
  provider?: Provider;
  subscriber?: ResponseMember;
  dependents?: Array<ResponseMember>;
  payer?: Payer;
  planInformation?: PlanInformation;
  planDateInformation?: PlanDateInformation;
  planStatus?: Array<PlanStatus>;
  benefitsInformation?: Array<BenefitsInformation>;
  errors?: Array<Error>;
  status?: string;
  transactionSetAcknowledgement?: string;
  implementationTransactionSetSyntaxError?: string;
  x12?: string;
}

export interface Provider {
  organizationName?: string;
  firstName?: string;
  lastName?: string;
  // Not from generated types?
  providerFirstName?: string;
  // Not from generated types?
  providerName?: string;
  npi?: string;
  serviceProviderNumber?: string;
  payorId?: string;
  taxId?: string;
  ssn?: string;
  pharmacyProcessorNumber?: string;
  servicesPlanID?: string;
  employersId?: string;
  providerCode?: string;
  referenceIdentification?: string;
  providerType?: string;
}

export interface ResponseMember {
  healthCareDiagnosisCodes?: Array<HealthCareDiagnosisCode>;
  memberId?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
  gender?: string;
  entityIdentifier?: string;
  entityType?: string;
  uniqueHealthIdentifier?: string;
  dateOfBirth?: string;
  informationStatusCode?: string;
  employmentStatusCode?: string;
  governmentServiceAffiliationCode?: string;
  description?: string;
  militaryServiceRankCode?: string;
  dateTimePeriodFormatQualifier?: string;
  dateTimePeriod?: string;
  endDateTimePeriod?: string;
  startDateTimePeriod?: string;
  ssn?: string;
  groupNumber?: string;
  planNumber?: string;
  planNetworkIdNumber?: string;
  relationToSubscriber?: string;
  relationToSubscriberCode?: string;
  insuredIndicator?: string;
  maintenanceTypeCode?: string;
  maintenanceReasonCode?: string;
  birthSequenceNumber?: string;
  address?: Address;
  provider?: Provider;
}

export interface HealthCareDiagnosisCode {
  diagnosisTypeCode?: string;
  diagnosisCode?: string;
}

export interface PlanInformation {
  stateLicenseNumber?: string;
  medicareProviderNumber?: string;
  medicaidProviderNumber?: string;
  facilityIdNumber?: string;
  personalIdentificationNumber?: string;
  planNumber?: string;
  planDescription?: string;
  policyNumber?: string;
  memberId?: string;
  caseNumber?: string;
  familyUnitNumber?: string;
  groupNumber?: string;
  groupDescription?: string;
  referralNumber?: string;
  alternativeListId?: string;
  classOfContractCode?: string;
  coverageListId?: string;
  contractNumber?: string;
  medicalRecordIdentificationNumber?: string;
  electronicDevicePin?: string;
  submitterIdentificationNumber?: string;
  patientAccountNumber?: string;
  hicNumber?: string;
  drugFormularyNumber?: string;
  priorAuthorizationNumber?: string;
  idCardSerialNumber?: string;
  idCardNumber?: string;
  centersForMedicareAndMedicaidServicesNPI?: string;
  issueNumber?: string;
  insurancePolicyNumber?: string;
  userIdentification?: string;
  medicalAssistanceCategory?: string;
  eligibilityCategory?: string;
  planNetworkIdNumber?: string;
  planNetworkIdDescription?: string;
  facilityNetworkIdentificationNumber?: string;
  medicaidRecipientIdNumber?: string;
  priorIdNumber?: string;
  socialSecurityNumber?: string;
  federalTaxpayersIdentificationNumber?: string;
  agencyClaimNumber?: string;
}

export interface PlanDateInformation {
  discharge?: string;
  issue?: string;
  effectiveDateOfChange?: string;
  periodStart?: string;
  periodEnd?: string;
  completion?: string;
  coordinationOfBenefits?: string;
  plan?: string;
  benefit?: string;
  primaryCareProvider?: string;
  latestVisitOrConsultation?: string;
  eligibility?: string;
  added?: string;
  cobraBegin?: string;
  cobraEnd?: string;
  premiumPaidToDateBegin?: string;
  premiumPaidToDateEnd?: string;
  planBegin?: string;
  planEnd?: string;
  benefitBegin?: string;
  benefitEnd?: string;
  eligibilityBegin?: string;
  eligibilityEnd?: string;
  enrollment?: string;
  admission?: string;
  dateOfDeath?: string;
  certification?: string;
  service?: string;
  policyEffective?: string;
  policyExpiration?: string;
  dateOfLastUpdate?: string;
  status?: string;
}

export interface Payer {
  entityIdentifier?: string;
  entityType?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  middleName?: string;
  suffix?: string;
  employersId?: string;
  federalTaxpayersIdNumber?: string;
  naic?: string;
  npi?: string;
  centersForMedicareAndMedicaidPlanId?: string;
  payorIdentification?: string;
  contactInformation?: ContactInformation;
  etin?: string;
}

export interface BenefitsInformation {
  code?: string;
  name?: string;
  description?: string;
  coverageLevelCode?: string;
  coverageLevel?: string;
  serviceTypeCodes?: Array<string>;
  serviceTypes?: Array<string>;
  insuranceTypeCode?: string;
  insuranceType?: string;
  planCoverage?: string;
  timeQualifierCode?: string;
  timeQualifier?: string;
  benefitAmount?: string;
  benefitPercent?: string;
  quantityQualifierCode?: string;
  quantityQualifier?: string;
  benefitQuantity?: string;
  authOrCertIndicator?: string;
  inPlanNetworkIndicatorCode?: string;
  inPlanNetworkIndicator?: string;
  headerLoopIdentifierCode?: string;
  trailerLoopIdentifierCode?: string;
  benefitsAdditionalInformation?: BenefitsAdditionalInformation;
  benefitsDateInformation?: BenefitsDateInformation;
  benefitsRelatedEntity?: BenefitsRelatedEntity;
  benefitsRelatedEntities?: Array<BenefitsRelatedEntity>;
  benefitsServiceDelivery?: Array<BenefitsServiceDelivery>;
  additionalInformation?: Array<AdditionalInformation>;
  eligibilityAdditionalInformation?: EligibilityAdditionalInformation;
  eligibilityAdditionalInformationList?: Array<EligibilityAdditionalInformation>;
  contactInformation?: Array<ContactInformation>;
}

export interface AdditionalInformation {
  description?: string;
}

export interface BenefitsAdditionalInformation {
  stateLicenseNumber?: string;
  medicareProviderNumber?: string;
  medicaidProviderNumber?: string;
  facilityIdNumber?: string;
  personalIdentificationNumber?: string;
  planNumber?: string;
  policyNumber?: string;
  memberId?: string;
  caseNumber?: string;
  familyUnitNumber?: string;
  groupNumber?: string;
  referralNumber?: string;
  alternativeListId?: string;
  classOfContractCode?: string;
  coverageListId?: string;
  contractNumber?: string;
  medicalRecordIdentificationNumber?: string;
  electronicDevicePin?: string;
  submitterIdentificationNumber?: string;
  patientAccountNumber?: string;
  hicNumber?: string;
  drugFormularyNumber?: string;
  priorAuthorizationNumber?: string;
  idCardSerialNumber?: string;
  idCardNumber?: string;
  centersForMedicareAndMedicaidServicesNPI?: string;
  issueNumber?: string;
  insurancePolicyNumber?: string;
  userIdentification?: string;
  medicalAssistanceCategory?: string;
  eligibilityCategory?: string;
  planNetworkIdNumber?: string;
  // NOTE: Added in from Availity response
  planNetworkName?: string;
  facilityNetworkIdentificationNumber?: string;
  medicaidRecepientIdNumber?: string;
  priorIdNumber?: string;
  socialSecurityNumber?: string;
  federalTaxpayersIdentificationNumber?: string;
  agencyClaimNumber?: string;
}

export interface BenefitsDateInformation {
  discharge?: string;
  issue?: string;
  effectiveDateOfChange?: string;
  periodStart?: string;
  periodEnd?: string;
  completion?: string;
  coordinationOfBenefits?: string;
  plan?: string;
  benefit?: string;
  primaryCareProvider?: string;
  latestVisitOrConsultation?: string;
  eligibility?: string;
  added?: string;
  cobraBegin?: string;
  cobraEnd?: string;
  premiumPaidtoDateBegin?: string;
  premiumPaidToDateEnd?: string;
  planBegin?: string;
  planEnd?: string;
  benefitBegin?: string;
  benefitEnd?: string;
  eligibilityBegin?: string;
  eligibilityEnd?: string;
  enrollment?: string;
  admission?: string;
  dateOfDeath?: string;
  certification?: string;
  service?: string;
  policyEffective?: string;
  policyExpiration?: string;
  dateOfLastUpdate?: string;
  status?: string;
}

export interface PlanStatus {
  statusCode?: string;
  status?: string;
  planDetails?: string;
  serviceTypeCodes?: Array<string>;
  serviceTypes?: Array<string>;
}

export interface BenefitsRelatedEntity {
  entityIdentifier?: string;
  entityType?: string;
  entityName?: string;
  entityFirstname?: string;
  entityMiddlename?: string;
  entitySuffix?: string;
  entityIdentification?: string;
  entityIdentificationValue?: string;
  entityRelationship?: string;
  address?: Address;
  contactInformation?: ContactInformation;
  providerInformation?: ProviderInformation;
}

export interface Address {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export interface ContactInformation {
  name?: string;
  type?: string;
  contacts?: Array<Contacts>;
}

export interface Contacts {
  communicationMode?: string;
  communicationNumber?: string;
}

export interface ProviderInformation {
  providerCode?: string;
  referenceIdentification?: string;
}

export interface BenefitsServiceDelivery {
  quantityQualifierCode?: string;
  quantityQualifier?: string;
  quantity?: string;
  unitForMeasurementCode?: string;
  sampleSelectionModulus?: string;
  timePeriodQualifierCode?: string;
  timePeriodQualifier?: string;
  numOfPeriods?: string;
  deliveryOrCalendarPatternCode?: string;
  deliveryPatternTimeCode?: string;
  unitForMeasurementQualifierCode?: string;
  unitForMeasurementQualifier?: string;
  deliveryOrCalendarPatternQualifierCode?: string;
  deliveryOrCalendarPatternQualifier?: string;
  deliveryPatternTimeQualifierCode?: string;
  deliveryPatternTimeQualifier?: string;
}

export interface EligibilityAdditionalInformation {
  codeListQualifierCode?: string;
  industryCode?: string;
  codeCategory?: string;
  injuredBodyPartName?: string;
}

export enum CoverageLevel {
  ChildrenOnly = "CHD",
  DependentsOnly = "DEP",
  EmployeeAndOneDependent = "E1D",
  EmployeeAndTwoDependents = "E2D",
  EmployeeAndThreeDependents = "E3D",
  EmployeeAndOneOrMoreDependents = "E5D",
  EmployeeAndTwoOrMoreDependents = "E6D",
  EmployeeAndThreeOrMoreDependents = "E7D",
  EmployeeAndFourOrMoreDependents = "E8D",
  EmployeeAndFiveOrMoreDependents = "E9D",
  EmployeeAndChildren = "ECH",
  EmployeeOnly = "EMP",
  EmployeeAndSpouse = "ESP",
  Family = "FAM",
  Individual = "IND",
  SubscriberAnd1child = "S1C",
  SubscriberAnd1OrMoreChildren = "S5C",
  SubscriberAnd2OrMoreChildren = "S6C",
  SpouseandChildren = "SPC",
  SpouseOnly = "SPO",
  SubscriberSpouseAnd1child = "SS1",
  SubscriberSpouseAnd1OrMoreChildren = "SS5",
  SubscriberSpouseAnd2OrMoreChildren = "SS6",
  SubscriberAndSpouse = "SSP",
  TwoParty = "TWO",
}

/**
 * Code defining periods
 * https://edi.stedi.com/x12-005010/element/615
 */
export enum TimePeriodQualifierCode {
  ChargeablePeriods = "1",
  PeriodsHeld = "2",
  FreePeriods = "3",
  SaturdaysSundaysAndHolidays = "4",
  OtherAllowancePeriods = "5",
  Hour = "6",
  Day = "7",
  NotApplicable = "8",
  InitialVisit = "9",
  MutuallyDefined = "Z",
  SixHours = "10",
  TwelveHours = "11",
  EighteenHours = "12",
  TwentyFourHours = "13",
  DebitDays = "14",
  CreditDays = "15",
  ExcessDays = "16",
  HazardousDays = "17",
  Holidays = "18",
  SaturdaysAndSundays = "19",
  SundaysAndHolidays = "20",
  Years = "21",
  ServiceYear = "22",
  CalendarYear = "23",
  YearToDate = "24",
  Contract = "25",
  Episode = "26",
  Visit = "27",
  Outlier = "28",
  Remaining = "29",
  Exceeded = "30",
  NotExceeded = "31",
  Lifetime = "32",
  LifetimeRemaining = "33",
  Month = "34",
  Week = "35",
  Admission = "36",
  ThreeMonths = "37",
}

/**
 * Code defining quantity
 * https://www.stedi.com/edi/x12-005010/element/673
 * NOTE: Just defining a subset of the codes here
 */
export enum QuantityQualifierCode {
  QuantityApproved = "QA",
  QuantityUsed = "99",
}

/**
 * Code identifying the classification of service
 * https://edi.stedi.com/x12-005010/element/1365
 */
export enum ServiceTypeCode {
  MedicalCare = "1",
  Surgical = "2",
  Consultation = "3",
  DiagnosticXRay = "4",
  DiagnosticLab = "5",
  RadiationTherapy = "6",
  Anesthesia = "7",
  SurgicalAssistance = "8",
  OtherMedical = "9",
  NonescroworNonimpoundService = "B",
  EscroworImpoundService = "C",
  BloodCharges = "10",
  UsedDurableMedicalEquipment = "11",
  DurableMedicalEquipmentPurchase = "12",
  AmbulatoryServiceCenterFacility = "13",
  RenalSuppliesintheHome = "14",
  AlternateMethodDialysis = "15",
  ChronicRenalDiseaseCRDEquipment = "16",
  PreAdmissionTesting = "17",
  DurableMedicalEquipmentRental = "18",
  PneumoniaVaccine = "19",
  SecondSurgicalOpinion = "20",
  ThirdSurgicalOpinion = "21",
  SocialWork = "22",
  DiagnosticDental = "23",
  Periodontics = "24",
  Restorative = "25",
  Endodontics = "26",
  MaxillofacialProsthetics = "27",
  AdjunctiveDentalServices = "28",
  HealthBenefitPlanCoverage = "30",
  BenefitDisclaimer = "31",
  PlanWaitingPeriod = "32",
  Chiropractic = "33",
  ChiropracticOfficeVisits = "34",
  DentalCare = "35",
  DentalCrowns = "36",
  DentalAccident = "37",
  Orthodontics = "38",
  Prosthodontics = "39",
  OralSurgery = "40",
  RoutinePreventiveDental = "41",
  HomeHealthCare = "42",
  HomeHealthPrescriptions = "43",
  HomeHealthVisits = "44",
  Hospice = "45",
  RespiteCare = "46",
  Hospital = "47",
  HospitalInpatient = "48",
  HospitalRoomandBoard = "49",
  HospitalOutpatient = "50",
  HospitalEmergencyAccident = "51",
  HospitalEmergencyMedical = "52",
  HospitalAmbulatorySurgical = "53",
  LongTermCare = "54",
  MajorMedical = "55",
  MedicallyRelatedTransportation = "56",
  AirTransportation = "57",
  Cabulance = "58",
  LicensedAmbulance = "59",
  GeneralBenefits = "60",
  InvitroFertilization = "61",
  MRICATScan = "62",
  DonorProcedures = "63",
  Acupuncture = "64",
  NewbornCare = "65",
  Pathology = "66",
  SmokingCessation = "67",
  WellBabyCare = "68",
  Maternity = "69",
  Transplants = "70",
  AudiologyExam = "71",
  InhalationTherapy = "72",
  DiagnosticMedical = "73",
  PrivateDutyNursing = "74",
  ProstheticDevice = "75",
  Dialysis = "76",
  OtologicalExam = "77",
  Chemotherapy = "78",
  AllergyTesting = "79",
  Immunizations = "80",
  RoutinePhysical = "81",
  FamilyPlanning = "82",
  Infertility = "83",
  Abortion = "84",
  AIDS = "85",
  EmergencyServices = "86",
  Cancer = "87",
  Pharmacy = "88",
  FreeStandingPrescriptionDrug = "89",
  MailOrderPrescriptionDrug = "90",
  BrandNamePrescriptionDrug = "91",
  GenericPrescriptionDrug = "92",
  Podiatry = "93",
  PodiatryOfficeVisits = "94",
  PodiatryNursingHomeVisits = "95",
  ProfessionalPhysician = "96",
  Anesthesiologist = "97",
  ProfessionalPhysicianVisitOffice = "98",
  ProfessionalPhysicianVisitInpatient = "99",
  ProfessionalPhysicianVisitOutpatient = "A0",
  ProfessionalPhysicianVisitNursingHome = "A1",
  ProfessionalPhysicianVisitSkilledNursingFacility = "A2",
  ProfessionalPhysicianVisitHome = "A3",
  Psychiatric = "A4",
  PsychiatricRoomandBoard = "A5",
  Psychotherapy = "A6",
  PsychiatricInpatient = "A7",
  PsychiatricOutpatient = "A8",
  Rehabilitation = "A9",
  RehabilitationRoomandBoard = "AA",
  RehabilitationInpatient = "AB",
  RehabilitationOutpatient = "AC",
  OccupationalTherapy = "AD",
  PhysicalMedicine = "AE",
  SpeechTherapy = "AF",
  SkilledNursingCare = "AG",
  SkilledNursingCareRoomandBoard = "AH",
  SubstanceAbuse = "AI",
  Alcoholism = "AJ",
  DrugAddiction = "AK",
  VisionOptometry = "AL",
  Frames = "AM",
  RoutineExam = "AN",
  Lenses = "AO",
  NonmedicallyNecessaryPhysical = "AQ",
  ExperimentalDrugTherapy = "AR",
  BurnCare = "B1",
  BrandNamePrescriptionDrugFormulary = "B2",
  BrandNamePrescriptionDrugNonFormulary = "B3",
  IndependentMedicalEvaluation = "BA",
  PartialHospitalizationPsychiatric = "BB",
  DayCarePsychiatric = "BC",
  CognitiveTherapy = "BD",
  MassageTherapy = "BE",
  PulmonaryRehabilitation = "BF",
  CardiacRehabilitation = "BG",
  Pediatric = "BH",
  Nursery = "BI",
  Skin = "BJ",
  Orthopedic = "BK",
  Cardiac = "BL",
  Lymphatic = "BM",
  Gastrointestinal = "BN",
  Endocrine = "BP",
  Neurology = "BQ",
  Eye = "BR",
  InvasiveProcedures = "BS",
  Gynecological = "BT",
  Obstetrical = "BU",
  ObstetricalGynecological = "BV",
  MailOrderPrescriptionDrugBrandName = "BW",
  MailOrderPrescriptionDrugGeneric = "BX",
  PhysicianVisitOfficeSick = "BY",
  PhysicianVisitOfficeWell = "BZ",
  CoronaryCare = "C1",
  PrivateDutyNursingInpatient = "CA",
  PrivateDutyNursingHome = "CB",
  SurgicalBenefitsProfessionalPhysician = "CC",
  SurgicalBenefitsFacility = "CD",
  MentalHealthProviderInpatient = "CE",
  MentalHealthProviderOutpatient = "CF",
  MentalHealthFacilityInpatient = "CG",
  MentalHealthFacilityOutpatient = "CH",
  SubstanceAbuseFacilityInpatient = "CI",
  SubstanceAbuseFacilityOutpatient = "CJ",
  ScreeningXray = "CK",
  Screeninglaboratory = "CL",
  MammogramHighRiskPatient = "CM",
  MammogramLowRiskPatient = "CN",
  FluVaccination = "CO",
  EyewearandEyewearAccessories = "CP",
  CaseManagement = "CQ",
  Dermatology = "DG",
  DurableMedicalEquipment = "DM",
  DiabeticSupplies = "DS",
  GenericPrescriptionDrugFormulary = "GF",
  GenericPrescriptionDrugNonFormulary = "GN",
  Allergy = "GY",
  IntensiveCare = "IC",
  MentalHealth = "MH",
  NeonatalIntensiveCare = "NI",
  Oncology = "ON",
  PhysicalTherapy = "PT",
  Pulmonary = "PU",
  Renal = "RN",
  ResidentialPsychiatricTreatment = "RT",
  TransitionalCare = "TC",
  TransitionalNurseryCare = "TN",
  UrgentCare = "UC",
  //
  // Non-X12 005010 codes
  //
  /**
   * Nutrition and diet counseling such as: weight management, eating disorders,
   * pregnancy, pediatric, food allergy, diabetes, celiac disease
   */
  Nutrition = "E28",
}

/**
 * Listed below are place of service codes and descriptions. These codes should
 * be used on professional claims to specify the entity where service(s) were
 * rendered. Check with individual payers (e.g., Medicare, Medicaid, other
 * private insurance) for reimbursement policies regarding these codes.
 * Database (updated September 2021)
 * https://www.cms.gov/Medicare/Coding/place-of-service-codes/Place_of_Service_Code_Set
 */
export enum PlaceOfService {
  /**
   * A facility or location where drugs and other medically related items and
   * services are sold, dispensed, or otherwise provided directly to patients.
   */
  Pharmacy = "01",
  /**
   * The location where health services and health related services are provided
   * or received, through telecommunication technology. Patient is not located
   * in their home when receiving health services or health related services
   * through telecommunication technology.
   */
  TelehealthProvidedOtherThanInPatientsHome = "02",
  /**
   * A facility whose primary purpose is education.
   */
  School = "03",
  /**
   * A facility or location whose primary purpose is to provide temporary
   * housing to homeless individuals (e.g., emergency shelters, individual or
   * family shelters).
   */
  HomelessShelter = "04",
  /**
   * A facility or location, owned and operated by the Indian Health Service,
   * which provides diagnostic, therapeutic (surgical and non-surgical), and
   * rehabilitation services to American Indians and Alaska Natives who do not
   * require hospitalization.  (Effective January 1, 2003)
   */
  IndianHealthServiceFreeStandingFacility = "05",
  /**
   * A facility or location, owned and operated by the Indian Health Service,
   * which provides diagnostic, therapeutic (surgical and non-surgical), and
   * rehabilitation services rendered by, or under the supervision of,
   * physicians to American Indians and Alaska Natives admitted as inpatients or
   * outpatients.
   */
  IndianHealthServiceProviderBasedFacility = "06",
  /**
   * A facility or location owned and operated by a federally recognized
   * American Indian or Alaska Native tribe or tribal organization under a 638
   * agreement, which provides diagnostic, therapeutic (surgical and
   * non-surgical), and rehabilitation services to tribal members who do not
   * require hospitalization.  (Effective January 1, 2003)
   */
  Tribal638FreeStandingFacility = "07",
  /**
   * A facility or location owned and operated by a federally recognized
   * American Indian or Alaska Native tribe or tribal organization under a 638
   * agreement, which provides diagnostic, therapeutic (surgical and
   * non-surgical), and rehabilitation services to tribal members admitted as
   * inpatients or outpatients.
   */
  Tribal638ProviderBasedFacility = "08",
  /**
   * A prison, jail, reformatory, work farm, detention center, or any other
   * similar facility maintained by either Federal, State or local authorities
   * for the purpose of confinement or rehabilitation of adult or juvenile
   * criminal offenders.
   */
  PrisonCorrectionalFacility = "09",
  /**
   * The location where health services and health related services are provided
   * or received, through telecommunication technology. Patient is located in
   * their home (which is a location other than a hospital or other facility
   * where the patient receives care in a private residence) when receiving
   * health services or health related services through telecommunication
   * technology.
   */
  TelehealthProvidedInPatientsHome = "10",
  /**
   * Location, other than a hospital, skilled nursing facility (SNF), military
   * treatment facility, community health center, State or local public health
   * clinic, or intermediate care facility (ICF), where the health professional
   * routinely provides health examinations, diagnosis, and treatment of illness
   * or injury on an ambulatory basis.
   */
  Office = "11",
  /**
   * Location, other than a hospital or other facility, where the patient
   * receives care in a private residence.
   */
  Home = "12",
  /**
   * Congregate residential facility with self-contained living units providing
   * assessment of each resident's needs and on-site support 24 hours a day, 7
   * days a week, with the capacity to deliver or arrange for services including
   * some health care and other services.
   */
  AssistedLivingFacility = "13",
  /**
   * A residence, with shared living areas, where clients receive supervision
   * and other services such as social and/or behavioral services, custodial
   * service, and minimal services (e.g., medication administration).
   */
  GroupHome = "14",
  /**
   * A facility/unit that moves from place-to-place equipped to provide
   * preventive, screening, diagnostic, and/or treatment services.
   */
  MobileUnit = "15",
  /**
   * A short term accommodation such as a hotel, camp ground, hostel, cruise
   * ship or resort where the patient receives care, and which is not identified
   * by any other POS code.
   */
  TemporaryLodging = "16",
  /**
   * A walk-in health clinic, other than an office, urgent care facility,
   * pharmacy or independent clinic and not described by any other Place of
   * Service code, that is located within a retail operation and provides, on an
   * ambulatory basis, preventive and primary care services. (This code is
   * available for use immediately with a final effective date of May 1, 2010)
   */
  WalkInRetailHealthClinic = "17",
  /**
   * A location, not described by any other POS code, owned or operated by a
   * public or private entity where the patient is employed, and where a health
   * professional provides on-going or episodic occupational medical,
   * therapeutic or rehabilitative services to the individual. (This code is
   * available for use effective January 1, 2013 but no later than May 1, 2013)
   */
  PlaceOfEmploymentWorksite = "18",
  /**
   * A portion of an off-campus hospital provider based department which
   * provides diagnostic, therapeutic (both surgical and nonsurgical), and
   * rehabilitation services to sick or injured persons who do not require
   * hospitalization or institutionalization.  (Effective January 1, 2016)
   */
  OffCampusOutpatientHospital = "19",
  /**
   * Location, distinct from a hospital emergency room, an office, or a clinic,
   * whose purpose is to diagnose and treat illness or injury for unscheduled,
   * ambulatory patients seeking immediate medical attention.
   */
  UrgentCareFacility = "20",
  /**
   * A facility, other than psychiatric, which primarily provides diagnostic,
   * therapeutic (both surgical and nonsurgical), and rehabilitation services
   * by, or under, the supervision of physicians to patients admitted for a
   * variety of medical conditions.
   */
  InpatientHospital = "21",
  /**
   * A portion of a hospital’s main campus which provides diagnostic,
   * therapeutic (both surgical and nonsurgical), and rehabilitation services to
   * sick or injured persons who do not require hospitalization or
   * institutionalization.  (Description change effective January 1, 2016)
   */
  OnCampusOutpatientHospital = "22",
  /**
   * A portion of a hospital where emergency diagnosis and treatment of illness
   * or injury is provided.
   */
  EmergencyRoomHospital = "23",
  /**
   * A freestanding facility, other than a physician's office, where surgical
   * and diagnostic services are provided on an ambulatory basis.
   */
  AmbulatorySurgicalCenter = "24",
  /**
   * A facility, other than a hospital's maternity facilities or a physician's
   * office, which provides a setting for labor, delivery, and immediate
   * post-partum care as well as immediate care of new born infants.
   */
  BirthingCenter = "25",
  /**
   * A medical facility operated by one or more of the Uniformed Services.
   * Military Treatment Facility (MTF) also refers to certain former U.S. Public
   * Health Service (USPHS) facilities now designated as Uniformed Service
   * Treatment Facilities (USTF).
   */
  MilitaryTreatmentFacility = "26",
  /**
   * A facility which primarily provides inpatient skilled nursing care and
   * related services to patients who require medical, nursing, or
   * rehabilitative services but does not provide the level of care or treatment
   * available in a hospital.
   */
  SkilledNursingFacility = "31",
  /**
   * A facility which primarily provides to residents skilled nursing care and
   * related services for the rehabilitation of injured, disabled, or sick
   * persons, or, on a regular basis, health-related care services above the
   * level of custodial care to other than individuals with intellectual
   * disabilities.
   */
  NursingFacility = "32",
  /**
   * A facility which provides room, board and other personal assistance
   * services, generally on a long-term basis, and which does not include a
   * medical component.
   */
  CustodialCareFacility = "33",
  /**
   * A facility, other than a patient's home, in which palliative and supportive
   * care for terminally ill patients and their families are provided.
   */
  Hospice = "34",
  /**
   * A land vehicle specifically designed, equipped and staffed for lifesaving
   * and transporting the sick or injured.
   */
  AmbulanceLand = "41",
  /**
   * An air or water vehicle specifically designed, equipped and staffed for
   * lifesaving and transporting the sick or injured.
   */
  AmbulanceAirOrWater = "42",
  /**
   * A location, not part of a hospital and not described by any other Place of
   * Service code, that is organized and operated to provide preventive,
   * diagnostic, therapeutic, rehabilitative, or palliative services to
   * outpatients only.
   */
  IndependentClinic = "49",
  /**
   * A facility located in a medically underserved area that provides Medicare
   * beneficiaries preventive primary medical care under the general direction
   * of a physician.
   */
  FederallyQualifiedHealthCenter = "50",
  /**
   * A facility that provides inpatient psychiatric services for the diagnosis
   * and treatment of mental illness on a 24-hour basis, by or under the
   * supervision of a physician.
   */
  InpatientPsychiatricFacility = "51",
  /**
   * A facility for the diagnosis and treatment of mental illness that provides
   * a planned therapeutic program for patients who do not require full time
   * hospitalization, but who need broader programs than are possible from
   * outpatient visits to a hospital-based or hospital-affiliated facility.
   */
  PsychiatricFacilityPartialHospitalization = "52",
  /**
   * A facility that provides the following services: outpatient services,
   * including specialized outpatient services for children, the elderly,
   * individuals who are chronically ill, and residents of the CMHC's mental
   * health services area who have been discharged from inpatient treatment at a
   * mental health facility; 24 hour a day emergency care services; day
   * treatment, other partial hospitalization services, or psychosocial
   * rehabilitation services; screening for patients being considered for
   * admission to State mental health facilities to determine the
   * appropriateness of such admission; and consultation and education services.
   */
  CommunityMentalHealthCenter = "53",
  /**
   * A facility which primarily provides health-related care and services above
   * the level of custodial care to individuals but does not provide the level
   * of care or treatment available in a hospital or SNF.
   */
  IntermediateCareFacilityIndividualsWithIntellectualDisabilities = "54",
  /**
   * A facility which provides treatment for substance (alcohol and drug) abuse
   * to live-in residents who do not require acute medical care. Services
   * include individual and group therapy and counseling, family counseling,
   * laboratory tests, drugs and supplies, psychological testing, and room and
   * board.
   */
  ResidentialSubstanceAbuseTreatmentFacility = "55",
  /**
   * A facility or distinct part of a facility for psychiatric care which
   * provides a total 24-hour therapeutically planned and professionally staffed
   * group living and learning environment.
   */
  PsychiatricResidentialTreatmentCenter = "56",
  /**
   * A location which provides treatment for substance (alcohol and drug) abuse
   * on an ambulatory basis.  Services include individual and group therapy and
   * counseling, family counseling, laboratory tests, drugs and supplies, and
   * psychological testing.
   */
  NonResidentialSubstanceAbuseTreatmentFacility = "57",
  /**
   * A location that provides treatment for opioid use disorder on an ambulatory
   * basis. Services include methadone and other forms of Medication Assisted
   * Treatment (MAT). (Effective January 1, 2020)
   */
  NonResidentialOpioidTreatmentFacility = "58",
  /**
   * A location where providers administer pneumococcal pneumonia and influenza
   * virus vaccinations and submit these services as electronic media claims,
   * paper claims, or using the roster billing method. This generally takes
   * place in a mass immunization setting, such as, a public health center,
   * pharmacy, or mall but may include a physician office setting.
   */
  MassImmunizationCenter = "60",
  /**
   * A facility that provides comprehensive rehabilitation services under the
   * supervision of a physician to inpatients with physical disabilities.
   * Services include physical therapy, occupational therapy, speech pathology,
   * social or psychological services, and orthotics and prosthetics services.
   */
  ComprehensiveInpatientRehabilitationFacility = "61",
  /**
   * A facility that provides comprehensive rehabilitation services under the
   * supervision of a physician to outpatients with physical disabilities.
   * Services include physical therapy, occupational therapy, and speech
   * pathology services.
   */
  ComprehensiveOutpatientRehabilitationFacility = "62",
  /**
   * A facility other than a hospital, which provides dialysis treatment,
   * maintenance, and/or training to patients or caregivers on an ambulatory or
   * home-care basis.
   */
  EndStageRenalDiseaseTreatmentFacility = "65",
  /**
   * A facility maintained by either State or local health departments that
   * provides ambulatory primary medical care under the general direction of a
   * physician.
   */
  PublicHealthClinic = "71",
  /**
   * A certified facility which is located in a rural medically underserved area
   * that provides ambulatory primary medical care under the general direction
   * of a physician.
   */
  RuralHealthClinic = "72",
  /**
   * A laboratory certified to perform diagnostic and/or clinical tests
   * independent of an institution or a physician's office.
   */
  IndependentLaboratory = "81",
  /**
   * Other place of service not identified above.
   */
  OtherPlaceOfService = "99",
}

export const placeOfServiceCodeToName = (
  placeOfService: PlaceOfService
): string => {
  for (const key in PlaceOfService) {
    if (PlaceOfService[key as keyof typeof PlaceOfService] === placeOfService) {
      return pascalCaseToReadable(key);
    }
  }
  return placeOfService;
};

export const serviceTypeCodeToName = (serviceType: ServiceTypeCode): string => {
  for (const key in ServiceTypeCode) {
    if (ServiceTypeCode[key as keyof typeof ServiceTypeCode] === serviceType) {
      return `${pascalCaseToReadable(key)} (${serviceType})`;
    }
  }
  return serviceType;
};
