import React from "react";
import { FullWidthLayout, HorizontalPadding } from "../layout";
import { ChargemasterGrid } from "./list";

export const ChargeMaster: React.FC = () => {
  return (
    <FullWidthLayout
      header={
        <HorizontalPadding>
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold text-gray-900">
              Chargemaster
            </h1>
          </div>
        </HorizontalPadding>
      }
      content={
        <div className="py-4">
          <ChargemasterGrid />
        </div>
      }
    />
  );
};
