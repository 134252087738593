import { gql, useQuery } from "@apollo/client";
import * as Tremor from "@tremor/react";
import { differenceInMinutes, parseISO, startOfDay } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";
import { BenefitMappingBadge } from ".";
import { GET_ME_BOOTSTRAP } from "../../../auth-context";
import { Card } from "../../../components";
import {
  Condition,
  Visit,
  evaluateBenefitAssignmentRule,
} from "../../../evaluate-rule";
import {
  GetBenefitMapping_benefitMapping as BenefitMapping,
  GetBenefitMapping,
  GetBenefitMappingVariables,
} from "../../../generated/GetBenefitMapping";
import {
  GetMeBootstrap_me_activeLocation_accountTypes as AccountType,
  GetMeBootstrap,
} from "../../../generated/GetMeBootstrap";
import {
  GetNextAppointments,
  GetNextAppointmentsVariables,
} from "../../../generated/GetNextAppointments";
import { Comparison } from "../../../generated/globalTypes";
import { BENEFIT_MAPPING_FIELDS } from "../../../graphql";
import { useUser } from "../../../user-context";
import { isDefined, mapNullable } from "../../../utils";
import { Layout, LoadingLayout } from "../../layout";
import { BenefitMappingForm } from "./benefit-mapping-form";
import { columns } from "./benefit-mapping-preview/columns";
import { DataTable } from "./benefit-mapping-preview/table";

export type VisitAttributes =
  | keyof Visit
  | "remainingDeductible"
  | "remainingOutOfPocket"
  | "remainingVisits";

const ATTRIBUTES: { label: string; value: VisitAttributes }[] = [
  { label: "Account Type", value: "accountTypeId" },
  { label: "Appointment Type", value: "appointmentType" },
  { label: "Appointment Label", value: "appointmentLabelId" },
  { label: "Patient Label", value: "patientLabelId" },
  { label: "Provider", value: "providerId" },
  { label: "Provider Classification", value: "providerTaxonomyCodeId" },
  { label: "Primary Policy Payer", value: "payerId" },
  { label: "Any Policy Payer", value: "anyPayerIds" },
  { label: "In Network", value: "inNetwork" },
  // { label: "Plan", value: "planId" },
  { label: "Charge Codes", value: "chargemasterGroupIds" },
  { label: "Code Groups", value: "codeGroups" },
  { label: "Any Visit Charge Codes", value: "anyVisitChargemasterGroupIds" },
  { label: "Remaining Deductible", value: "remainingDeductible" },
  { label: "Remaining Out of Pocket", value: "remainingOutOfPocket" },
  { label: "Remaining Visits", value: "remainingVisits" },
];

export const COMPARISONS = [
  // { label: "equals", value: Comparison.Equals },
  // { label: "does not equal", value: Comparison.NotEquals },
  { label: "contains", value: Comparison.Contains },
  { label: "is one of", value: Comparison.In },
  { label: "is not one of", value: Comparison.NotIn },
];

const MultiSelect = <T,>(
  props: Omit<Tremor.MultiSelectProps, "value" | "onValueChange"> & {
    value: T | T[] | undefined;
    onValueChange: (value: T | T[]) => void;
  }
) => <Tremor.MultiSelect {...(props as any)} />;
const MultiSelectItem = <T,>(
  props: Omit<Tremor.MultiSelectItemProps, "value"> & {
    value: T | T[] | undefined;
  }
) => <Tremor.MultiSelectItem {...(props as any)} />;
const SearchSelect = <T,>(
  props: Omit<Tremor.SearchSelectProps, "value" | "onValueChange"> & {
    value: T | T[] | undefined;
    onValueChange: (value: T | T[]) => void;
  }
) => <Tremor.SearchSelect {...(props as any)} />;
const SearchSelectItem = <T,>(
  props: Omit<Tremor.SearchSelectItemProps, "value"> & {
    value: T | T[] | undefined;
  }
) => <Tremor.SearchSelectItem {...(props as any)} />;

const AccountTypeSelect: React.FC<{
  onChange: (value: AccountType | AccountType[]) => void;
  value: AccountType | AccountType[] | undefined;
  accountTypes: AccountType[];
  isMulti: boolean;
}> = ({ onChange, value, accountTypes, isMulti }) => {
  if (isMulti) {
    return (
      <MultiSelect value={value} onValueChange={onChange}>
        {accountTypes.map((accountType) => (
          <MultiSelectItem
            key={accountType.id}
            className="hover:bg-gray-50"
            value={accountType}
          >
            {accountType.name}
          </MultiSelectItem>
        ))}
      </MultiSelect>
    );
  }
  return (
    <SearchSelect value={value} onValueChange={onChange}>
      {accountTypes.map((accountType) => (
        <SearchSelectItem
          key={accountType.id}
          className="hover:bg-gray-50"
          value={accountType}
        >
          {accountType.name}
        </SearchSelectItem>
      ))}
    </SearchSelect>
  );
};

export const attributeTypeDisplay = (attributeType: string) => {
  const attr = ATTRIBUTES.find((attr) => attr.value === attributeType);
  if (attributeType === "groupName") {
    return "Group Name";
  }
  if (attributeType === "appointmentDuration") {
    return "Appointment Duration (minutes)";
  }
  return attr?.label ?? attributeType;
};

export const comparisonDisplay = (comparison: Comparison) => {
  switch (comparison) {
    case Comparison.Equals:
      return "equals";
    case Comparison.NotEquals:
      return "does not equal";
    case Comparison.Contains:
      return "contains";
    case Comparison.In:
      return "is one of";
    case Comparison.NotIn:
      return "is not one of";
    case Comparison.GreaterThan:
      return "is greater than";
    case Comparison.GreaterThanOrEqual:
      return "is greater than or equal to";
    case Comparison.LessThan:
      return "is less than";
    case Comparison.LessThanOrEqual:
      return "is less than or equal to";
    case Comparison.IsMet:
      return "is met";
  }
  return comparison;
};

export const AttributeDisplay: React.FC<{
  attribute: string;
  attributeType: keyof Visit;
  comparison: Comparison;
}> = ({ attribute, attributeType, comparison }) => {
  // This works because we dedupe and use the cache
  const { data, loading } = useQuery<GetMeBootstrap>(GET_ME_BOOTSTRAP, {
    fetchPolicy: "cache-first",
  });
  const ready = !loading && data;

  if (ready && attributeType === "accountTypeId") {
    const accountTypes = data.me?.activeLocation.accountTypes ?? [];
    if (Array.isArray(attribute)) {
      const names = attribute.map((id) => {
        const accountType = accountTypes.find(
          (accountType) => accountType.id === id
        );
        return (
          <BenefitMappingBadge>
            {accountType?.name ?? attribute}
          </BenefitMappingBadge>
        );
      });
      return names;
    }
    const accountType = accountTypes.find(
      (accountType) => accountType.id === attribute
    );
    return (
      <BenefitMappingBadge>
        {accountType?.name ?? attribute}
      </BenefitMappingBadge>
    );
  }
  if (ready && attributeType === "appointmentLabelId") {
    const appointmentLabels = data.me?.activeLocation.appointmentLabels ?? [];
    if (Array.isArray(attribute)) {
      const names = attribute.map((id) => {
        const appointmentLabel = appointmentLabels.find(
          (appointmentLabel) => appointmentLabel.id === id
        );
        return (
          <BenefitMappingBadge>
            {appointmentLabel?.name ?? attribute}
          </BenefitMappingBadge>
        );
      });
      return names;
    }
    const appointmentLabel = appointmentLabels.find(
      (appointmentLabel) => appointmentLabel.id === attribute
    );
    return (
      <BenefitMappingBadge>
        {appointmentLabel?.name ?? attribute}
      </BenefitMappingBadge>
    );
  }
  if (ready && attributeType === "patientLabelId") {
    const patientLabels = data.me?.activeLocation.patientLabels ?? [];
    if (Array.isArray(attribute)) {
      const names = attribute.map((id) => {
        const patientLabel = patientLabels.find(
          (patientLabel) => patientLabel.id === id
        );
        return (
          <BenefitMappingBadge>
            {patientLabel?.name ?? attribute}
          </BenefitMappingBadge>
        );
      });
      return names;
    }
    const patientLabel = patientLabels.find(
      (patientLabel) => patientLabel.id === attribute
    );
    return (
      <BenefitMappingBadge>
        {patientLabel?.name ?? attribute}
      </BenefitMappingBadge>
    );
  }
  if (ready && attributeType === "providerTaxonomyCodeId") {
    const providerTaxonomyCodes = data.publicProviderTaxonomyCodes;
    if (Array.isArray(attribute)) {
      const names = attribute.map((id) => {
        const providerTaxonomyCode = providerTaxonomyCodes.find(
          (providerTaxonomyCode) => providerTaxonomyCode.id === id
        );
        return (
          <BenefitMappingBadge>
            {providerTaxonomyCode?.displayName ?? attribute}
          </BenefitMappingBadge>
        );
      });
      return names;
    }
    const providerTaxonomyCode = providerTaxonomyCodes.find(
      (providerTaxonomyCode) => providerTaxonomyCode.id === attribute
    );
    return (
      <BenefitMappingBadge>
        {providerTaxonomyCode?.displayName ?? attribute}
      </BenefitMappingBadge>
    );
  }
  if (ready && attributeType === "payerId") {
    const payers = data.me?.activeLocation.payers ?? [];
    if (Array.isArray(attribute)) {
      const names = attribute.map((id) => {
        const payer = payers.find((payer) => payer.id === id);
        return (
          <BenefitMappingBadge>{payer?.name ?? attribute}</BenefitMappingBadge>
        );
      });
      return names;
    }
    const payer = payers.find((payer) => payer.id === attribute);
    return (
      <BenefitMappingBadge>{payer?.name ?? attribute}</BenefitMappingBadge>
    );
  }
  if (ready && attributeType === "anyPayerIds") {
    const payers = data.me?.activeLocation.payers ?? [];
    if (Array.isArray(attribute)) {
      const names = attribute.map((id) => {
        const payer = payers.find((payer) => payer.id === id);
        return (
          <BenefitMappingBadge>{payer?.name ?? attribute}</BenefitMappingBadge>
        );
      });
      return names;
    }
    const payer = payers.find((payer) => payer.id === attribute);
    return (
      <BenefitMappingBadge>{payer?.name ?? attribute}</BenefitMappingBadge>
    );
  }
  if (ready && attributeType === "providerId") {
    const providers = data.me?.activeLocation.providers ?? [];
    if (Array.isArray(attribute)) {
      const names = attribute.map((id) => {
        const provider = providers.find((provider) => provider.id === id);
        return (
          <BenefitMappingBadge>
            {provider?.displayName ?? attribute}
          </BenefitMappingBadge>
        );
      });
      return names;
    }
    const provider = providers.find((provider) => provider.id === attribute);
    return (
      <BenefitMappingBadge>
        {provider?.displayName ?? attribute}
      </BenefitMappingBadge>
    );
  }
  if (
    ready &&
    (attributeType === "chargemasterGroupIds" ||
      attributeType === "anyVisitChargemasterGroupIds")
  ) {
    const chargemasterGroups = data.me?.activeLocation.chargemasterGroups ?? [];
    if (Array.isArray(attribute)) {
      const names = attribute.map((id) => {
        const chargemasterGroup = chargemasterGroups.find(
          (chargemasterGroup) => chargemasterGroup.id === id
        );
        return (
          <BenefitMappingBadge>
            {chargemasterGroup ? (
              <>
                {chargemasterGroup.code} - {chargemasterGroup.description}
              </>
            ) : (
              attribute
            )}
          </BenefitMappingBadge>
        );
      });
      return names;
    }
    const chargemasterGroup = chargemasterGroups.find(
      (chargemasterGroup) => chargemasterGroup.id === attribute
    );
    return (
      <BenefitMappingBadge>
        {chargemasterGroup ? (
          <>
            {chargemasterGroup.code} - {chargemasterGroup.description}
          </>
        ) : (
          attribute
        )}
      </BenefitMappingBadge>
    );
  }
  if (comparison === Comparison.IsMet) {
    if (attribute === "true")
      return <BenefitMappingBadge>Yes</BenefitMappingBadge>;
    if (attribute === "false")
      return <BenefitMappingBadge>No</BenefitMappingBadge>;
  }
  if (Array.isArray(attribute)) {
    return (
      <>
        {attribute.map((a) => (
          <BenefitMappingBadge>{a}</BenefitMappingBadge>
        ))}
      </>
    );
  }
  return <BenefitMappingBadge>{attribute}</BenefitMappingBadge>;
};

export const GET_NEXT_APPOINTMENTS = gql`
  query GetNextAppointments(
    $startOfDay: DateTime!
    $locationId: String!
    $take: Int!
  ) {
    appointments(
      where: { locationId: { equals: $locationId }, start: { lt: $startOfDay } }
      orderBy: { start: { sort: desc, nulls: last } }
      take: $take
    ) {
      id
      start
      end
      benefitStatus
      appointmentType
      appointmentLabelings {
        id
        appointmentLabel {
          id
          name
        }
      }
      account {
        id
        accountType {
          id
          name
        }
        patient {
          id
          displayName
        }
      }
      provider {
        id
        displayName
        providerTaxonomyCode {
          id
          displayName
          code
        }
      }
      insurancePolicies {
        id
        payer {
          id
          name
        }
      }
      bill {
        id
        charges {
          id
          codeGroups
          chargemaster {
            id
            code
            chargemasterGroupId
          }
        }
      }
    }
  }
`;

export const BenefitAssignmentRuleSimulatorTable: React.FC<{
  conditions: Condition<keyof Visit>[];
}> = ({ conditions }) => {
  const user = useUser()!;
  const { data, loading } = useQuery<
    GetNextAppointments,
    GetNextAppointmentsVariables
  >(GET_NEXT_APPOINTMENTS, {
    variables: {
      startOfDay: startOfDay(new Date()),
      locationId: user.activeLocation.id,
      take: 100,
    },
  });

  if (loading || !data) {
    return (
      <Card>
        <div className="flex flex-col gap-4">
          <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          <div className="h-8 animate-pulse rounded-md bg-slate-100" />
          <div className="h-8 animate-pulse rounded-md bg-slate-100" />
        </div>
      </Card>
    );
  }

  const appointments = data.appointments;
  return (
    <Card>
      <div className="overflow-auto w-full p-2">
        <div className="text-lg font-medium pb-2">Recent Appointments</div>
        <DataTable
          data={[...appointments].map((appointment) => {
            const visit: Omit<Visit, "payerId"> = {
              providerId: appointment.provider?.id ?? null,
              providerTaxonomyCodeId:
                appointment.provider?.providerTaxonomyCode?.id ?? null,
              appointmentType: appointment.appointmentType ?? null,
              appointmentLabelId: appointment.appointmentLabelings.map(
                (labeling) => labeling.appointmentLabel.name
              ),
              accountTypeId: appointment.account.accountType?.id ?? null,
              chargemasterGroupIds:
                appointment.bill
                  .flatMap((b) =>
                    b.charges?.map((c) => c.chargemaster?.chargemasterGroupId)
                  )
                  .filter(isDefined) ?? [],
              codeGroups:
                appointment.bill
                  .flatMap((b) => b.charges?.flatMap((c) => c.codeGroups))
                  .filter(isDefined) ?? [],
              appointmentDuration:
                appointment.start && appointment.end
                  ? differenceInMinutes(
                      parseISO(appointment.end),
                      parseISO(appointment.start)
                    )
                  : null,
              // TODO: Get benefits to evaluate benefit-based rules
              benefits: [],
            };
            return {
              id: appointment.id,
              appointment,
              start: mapNullable(parseISO)(appointment.start),
              patientName: appointment.account.patient.displayName,
              providerName: appointment.provider?.displayName ?? null,
              accountType: appointment.account.accountType?.name ?? null,
              appointmentType: appointment.appointmentType ?? null,
              appointmentLabels: appointment.appointmentLabelings.map(
                (labeling) => labeling.appointmentLabel.name
              ),
              charges:
                appointment.bill
                  .flatMap((b) => b.charges?.map((c) => c.chargemaster?.code))
                  .filter(isDefined) ?? [],
              providerTaxonomyCode:
                appointment.provider?.providerTaxonomyCode?.displayName ?? null,
              insurancePolicies: appointment.insurancePolicies.map(
                (ip) => ip.payer.name
              ),
              match: appointment.insurancePolicies.some((ip) =>
                evaluateBenefitAssignmentRule(
                  { ...visit, payerId: ip.payer.id },
                  {
                    benefitMappingConditions: conditions,
                  }
                )
              ),
            };
          })}
          // @ts-ignore
          columns={columns}
        />
      </div>
    </Card>
  );
};

const GET_BENEFIT_MAPPING = gql`
  ${BENEFIT_MAPPING_FIELDS}
  query GetBenefitMapping($id: String!) {
    benefitMapping(where: { id: $id }) {
      ...BenefitMappingFields
    }
  }
`;

const BenefitAssignmentRuleDisplay: React.FC<{
  benefitMapping: BenefitMapping;
}> = ({ benefitMapping }) => {
  return (
    <BenefitMappingForm benefitMapping={benefitMapping}>
      {({ conditions }) => (
        <div>
          <BenefitAssignmentRuleSimulatorTable
            conditions={
              conditions.map((c) => ({
                providerServiceConfigurationId: null,
                ...c,
              })) as Condition<keyof Visit>[]
            }
          />
        </div>
      )}
    </BenefitMappingForm>
  );
};

export const BenefitAssignmentRule: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useQuery<
    GetBenefitMapping,
    GetBenefitMappingVariables
  >(GET_BENEFIT_MAPPING, {
    variables: { id: id! },
  });

  if (loading || !data) {
    return <LoadingLayout />;
  }

  const rule = data.benefitMapping!;

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">{rule.name}</h1>
        </div>
      }
      content={<BenefitAssignmentRuleDisplay benefitMapping={rule} />}
    />
  );
};
