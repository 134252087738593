import React, { useEffect } from "react";

import { useAnalytics } from "../analytics-context";
import { usePatientAuth, PortalUser } from "./patient-auth-context";
import { PatientLogin } from "./pages/login";

const PatientUserContext = React.createContext<PortalUser | null>(null);

export const PatientUserProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const {
    login,
    data: { user },
  } = usePatientAuth();
  const analytics = useAnalytics();
  useEffect(() => {
    if (user) {
      // Identify the logged in user
      analytics?.identify(user.id, {
        email: user.email,
      });
    }
  }, [user]);
  if (!user) {
    return <PatientLogin login={login} />;
  }
  return <PatientUserContext.Provider value={user} {...props} />;
};

export const usePatientUser = () => React.useContext(PatientUserContext);
