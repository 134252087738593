import React from "react";
import { gql } from "@apollo/client";

import { FullWidthLayout, HorizontalPadding } from "../layout";
import { InsurancePolicyWorklist } from "./list";

export const ACCEPT_MULTIPLE_INSURANCE_POLICIES = gql`
  mutation AcceptMultipleInsurancePolicies($insurancePolicyIds: [String!]!) {
    acceptMultipleInsurancePolicies(insurancePolicyIds: $insurancePolicyIds) {
      id
      acceptedAt
      acceptedBy {
        id
      }
    }
  }
`;

export const UNACCEPT_MULTIPLE_INSURANCE_POLICIES = gql`
  mutation UnacceptMultipleInsurancePolicies($insurancePolicyIds: [String!]!) {
    unacceptMultipleInsurancePolicies(insurancePolicyIds: $insurancePolicyIds) {
      id
      acceptedAt
      acceptedBy {
        id
      }
    }
  }
`;

export const VerificationWorkflow: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <FullWidthLayout
      header={
        <HorizontalPadding>
          <div className="flex flex-col pt-4">
            <h1 className="text-2xl font-semibold text-gray-900">
              Verification Worklist
            </h1>
          </div>
        </HorizontalPadding>
      }
      content={
        <div className="flex flex-col">
          <div className="py-4">
            <InsurancePolicyWorklist />
          </div>
        </div>
      }
    />
  );
};
