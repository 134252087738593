import React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../utils";
import { Button } from "./button";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Badge } from "./badge";
import fuzzysort from "fuzzysort";

interface Option {
  value: string;
  label: string;
}

interface SearchableComboboxProps {
  value: string | string[];
  options: Option[];
  onSelect: (value: string | string[]) => void;
  placeholder: string;
  emptyMessage: string;
  isMulti?: boolean;
}

const normalizeScore = (score: number | null) => {
  const MIN_SCORE = -3000;
  if (score === null || score < MIN_SCORE) return 0;
  return (score - MIN_SCORE) / -MIN_SCORE;
};

export const SearchableCombobox: React.FC<SearchableComboboxProps> = ({
  value,
  options,
  onSelect,
  placeholder,
  emptyMessage,
  isMulti = false,
}) => {
  const selected = options.filter((option) =>
    Array.isArray(value) ? value.includes(option.value) : value === option.value
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between w-full h-fit min-w-10",
            value && "text-muted-foreground"
          )}
        >
          {selected.length > 0 ? (
            <div className="flex gap-1 flex-wrap">
              {selected.map((option) => (
                <Badge
                  key={option.value}
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {option.label}
                </Badge>
              ))}
            </div>
          ) : (
            placeholder
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-full"
        side="bottom"
        avoidCollisions={false}
      >
        <Command
          filter={(item, search) => {
            const label = options.find((o) => o.value === item)?.label;
            const targetString = label + " " + item;
            const result = fuzzysort.single(search, targetString);
            console.log({ result, search, targetString });
            return normalizeScore(result?.score ?? null);
          }}
        >
          <CommandInput
            placeholder={`Search ${placeholder.toLowerCase()}...`}
          />
          <CommandEmpty>{emptyMessage}</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-y-auto">
            {options.map((option) => (
              <CommandItem
                key={option.value}
                value={option.value}
                onSelect={() => {
                  if (isMulti) {
                    const newValue = Array.isArray(value) ? value : [value];
                    if (newValue.includes(option.value)) {
                      onSelect(newValue.filter((v) => v !== option.value));
                    } else {
                      onSelect([...newValue, option.value]);
                    }
                  } else {
                    onSelect(option.value);
                  }
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    (
                      Array.isArray(value)
                        ? value.includes(option.value)
                        : value === option.value
                    )
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
                {option.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
