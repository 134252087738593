import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AdjustmentsIcon,
  CurrencyDollarIcon,
  DotsVerticalIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { format, isValid, parseISO } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { Tooltip } from "../../components";
import { Table, Td } from "../../components/Table";
import { Button } from "../../components/ui/button";
import {
  GetPatients,
  GetPatientsVariables,
  GetPatients_patients as Patient,
} from "../../generated/GetPatients";
import { QueryMode } from "../../generated/globalTypes";
import {
  UpdatePatient,
  UpdatePatientVariables,
} from "../../generated/UpdatePatient";
import { useUser } from "../../user-context";
import { classNames, isDefined, parseMultiple, toDate } from "../../utils";
import { Layout } from "../layout";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";
import { OvalSpinner } from "../../components/loading";

const GET_PATIENTS = gql`
  query GetPatients($skip: Int, $take: Int, $where: PatientWhereInput!) {
    patients(
      where: $where
      skip: $skip
      take: $take
      orderBy: [{ updatedAt: desc }]
    ) {
      id
      deletedAt
      displayName
      dateOfBirth
      email
      cellPhone
      enrolledInAutopay
      paymentIntents(
        where: { autoPay: { equals: true } }
        orderBy: [{ createdAt: desc }]
        take: 1
      ) {
        id
        status
        lastPaymentError
      }
      accounts {
        id
        accountType {
          id
          name
        }
      }
    }
    aggregatePatient(where: $where) {
      _count {
        id
      }
    }
  }
`;

const PAGE_SIZE = 15;

const CreateNewPatient: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new patient.
      </p>
      <div className="mt-6">
        <Link
          to="/patients/new"
          className="flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Patient
        </Link>
      </div>
    </div>
  );
};

const EmptyPatients: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className="text-center">
    <svg
      className="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
    <h3 className="mt-2 text-sm font-medium text-gray-900">No patients</h3>
    <CreateNewPatient />
  </div>
);

const UPDATE_PATIENT = gql`
  mutation UpdatePatient($id: String!, $data: PatientUpdateInput!) {
    updateOnePatient(where: { id: $id }, data: $data) {
      id
      deletedAt
      accounts {
        id
        accountType {
          id
          name
        }
      }
    }
  }
`;

const ActionDropdownCell: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
  }>
> = ({ patient }) => {
  const [updatePatient, updatePatientResult] = useMutation<
    UpdatePatient,
    UpdatePatientVariables
  >(UPDATE_PATIENT);

  const archivePatient = async (id: string) => {
    await updatePatient({
      variables: {
        id: id,
        data: {
          deletedAt: { set: new Date() },
        },
      },
      refetchQueries: [GET_PATIENTS],
      onCompleted: () => {
        toast.success("Patient archived");
      },
      onError: () => {
        toast.error("Failed to archive patient");
      },
    });
  };

  const unarchivePatient = async (id: string) => {
    await updatePatient({
      variables: {
        id: id,
        data: {
          deletedAt: { set: null },
        },
      },
      refetchQueries: [GET_PATIENTS],
      onCompleted: () => {
        toast.success("Patient unarchived");
      },
      onError: () => {
        toast.error("Failed to unarchive patient");
      },
    });
  };

  return (
    <Td
      className="flex"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1 divide-y">
              {patient.deletedAt ? (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm w-full"
                      )}
                      disabled={updatePatientResult.loading}
                      onClick={() => unarchivePatient(patient.id)}
                    >
                      Unarchive
                    </button>
                  )}
                </Menu.Item>
              ) : (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm w-full"
                      )}
                      disabled={updatePatientResult.loading}
                      onClick={() => archivePatient(patient.id)}
                    >
                      Archive
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </Td>
  );
};

const PatientList: React.FC<
  React.PropsWithChildren<{ name: string; showArchived: boolean }>
> = ({ name, showArchived }) => {
  const user = useUser()!;
  const navigate = useNavigate();

  const [currentPage, setPageNum] = useState(1);
  const changePageNum = (num: number) => {
    setPageNum(num);
  };

  const skip = name === "" ? (currentPage - 1) * PAGE_SIZE : 0;
  const names = name.split(" ") ?? [];
  const firstName = names[0] ?? "";
  const lastName = names[1] ?? "";

  const dob = parseMultiple(
    name,
    [
      "MM/dd/yyyy",
      "yyyy-MM-dd",
      "MMddyyyy",
      "Mddyyyy",
      "Mdyyyy",
      "M/dd/yyyy",
      "M/d/yyyy",
    ],
    new Date()
  );
  const validDob = isValid(dob);

  const { loading, error, data } = useQuery<GetPatients, GetPatientsVariables>(
    GET_PATIENTS,
    {
      variables: {
        skip: skip,
        take: PAGE_SIZE,
        where: {
          organizationId: { equals: user.organization.id },
          locationId: { equals: user.activeLocation.id },
          deletedAt: showArchived ? undefined : { equals: null },
          OR: [
            {
              AND: [
                {
                  firstName: {
                    startsWith: firstName,
                    mode: QueryMode.insensitive,
                  },
                },
                {
                  lastName: {
                    startsWith: lastName,
                    mode: QueryMode.insensitive,
                  },
                },
              ],
            },
            {
              AND: [
                {
                  firstName: {
                    startsWith: lastName,
                    mode: QueryMode.insensitive,
                  },
                },
                {
                  lastName: {
                    startsWith: firstName,
                    mode: QueryMode.insensitive,
                  },
                },
              ],
            },
            ...(validDob
              ? [
                  {
                    dateOfBirth: {
                      equals: dob,
                    },
                  },
                ]
              : []),
          ],
        },
      },
    }
  );

  // If the name filter changes, reset the page number to 1
  useEffect(() => {
    setPageNum(1);
  }, [name]);

  if (name === "" && !loading && data?.patients.length === 0)
    return (
      <div className="mx-auto max-w-2xl py-16">
        <EmptyPatients />
      </div>
    );

  const countResult = data?.aggregatePatient?._count?.id ?? 0;
  const rows = data?.patients ?? [];
  const hasAccountTypes = user.organization.accountTypes.length > 0;

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <Table
            loading={loading}
            columnDefs={[
              {
                header: "Name",
                cellFn: (patient: Patient) => {
                  const lastPaymentError =
                    patient.paymentIntents.at(0)?.lastPaymentError;
                  return (
                    <Td>
                      <div className="flex gap-1 items-center">
                        <div className="text-sm text-gray-900">
                          {patient.displayName}
                        </div>
                        {patient.deletedAt && (
                          <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            Archived
                          </span>
                        )}
                        {patient.enrolledInAutopay && (
                          <Tooltip
                            trigger={
                              <CurrencyDollarIcon
                                className={classNames(
                                  "w-5 h-5",
                                  lastPaymentError
                                    ? "text-red-500"
                                    : "text-green-500"
                                )}
                              />
                            }
                            content={
                              lastPaymentError
                                ? `Autopay Enabled but last payment failed with error: ${lastPaymentError}`
                                : "Autopay Enabled"
                            }
                          />
                        )}
                      </div>
                    </Td>
                  );
                },
              },
              ...(hasAccountTypes
                ? [
                    {
                      header: "Accounts",
                      cellFn: (patient: Patient) => (
                        <Td>
                          <div className="text-sm text-gray-900 truncate">
                            {patient.accounts
                              .map((a) => a.accountType?.name)
                              .filter(isDefined)
                              .join(", ")}
                          </div>
                        </Td>
                      ),
                    },
                  ]
                : []),
              {
                header: "Date of Birth",
                cellFn: (patient: Patient) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {isDefined(patient.dateOfBirth) &&
                        format(
                          parseISO(toDate(patient.dateOfBirth)),
                          "MM/dd/yyyy"
                        )}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Email",
                cellFn: (patient: Patient) => (
                  <Td>
                    <div className="text-sm text-gray-900">{patient.email}</div>
                  </Td>
                ),
              },
              {
                header: "Phone",
                cellFn: (patient: Patient) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {patient.cellPhone}
                    </div>
                  </Td>
                ),
              },
              {
                header: "",
                cellFn: (patient: Patient) => (
                  <ActionDropdownCell patient={patient} />
                ),
              },
            ]}
            rows={rows}
            onRowClick={(patient) => navigate(`/patients/${patient.id}`)}
            pagination={{
              currentPage: currentPage,
              totalCount: countResult,
              pageSize: PAGE_SIZE,
              onPageChange: changePageNum,
              siblingCount: 1,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const SYNC_NEW_PATIENTS = gql`
  mutation SyncNewPatients($integrationId: String!) {
    syncNewPatients(integrationId: $integrationId) {
      importedPatientsCount
      errors {
        message
      }
    }
  }
`;

export const Patients: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser()!;
  const [name, setName] = useState("");
  const changeFilter = (e: { target: { value: any } }) => {
    setName(e.target.value);
  };
  const [showArchived, setShowArchived] = useState(false);

  const [syncNewPatients, { loading: syncNewPatientsLoading }] = useMutation(
    SYNC_NEW_PATIENTS,
    {
      onCompleted: (data) => {
        if (data.syncNewPatients.errors.length > 0) {
          toast.error(
            `Error syncing new patients: ${data.syncNewPatients.errors[0].message}`
          );
        } else if (data.syncNewPatients.importedPatientsCount > 0) {
          toast.success(
            `Successfully imported ${data.syncNewPatients.importedPatientsCount} new patients.`
          );
        } else {
          toast.info("No new patients to import.");
        }
      },
      onError: (error) => {
        toast.error(`Error syncing new patients: ${error.message}`);
      },
      refetchQueries: [GET_PATIENTS],
    }
  );
  const integrationsSupportingSync = user.activeLocation.integrations.filter(
    (integration) => integration.supportsOnDemandNewPatientSync
  );

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">Patients</h1>
          <div className="flex items-center gap-4">
            <Button asChild>
              <Link to="/patients/new">New Patient</Link>
            </Button>
            {integrationsSupportingSync.length > 0 && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    {syncNewPatientsLoading ? (
                      <OvalSpinner className="h-4 w-4" />
                    ) : (
                      <MoreHorizontal className="h-4 w-4" />
                    )}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {integrationsSupportingSync.map((integration) => (
                    <DropdownMenuItem
                      key={integration.id}
                      onClick={() =>
                        syncNewPatients({
                          variables: { integrationId: integration.id },
                        })
                      }
                      disabled={syncNewPatientsLoading}
                    >
                      {syncNewPatientsLoading
                        ? "Syncing..."
                        : `Sync new patients from ${integration.name}`}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
      }
      content={
        <div className="pt-2">
          <div className="flex justify-between">
            <div className="flex border-2 rounded">
              <input
                type="text"
                className="px-4 py-2 w-80 text-xs"
                placeholder="Search by name or date of birth"
                value={name}
                onChange={changeFilter}
              />
            </div>

            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <AdjustmentsIcon className="h-5 w-5 text-gray-500" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <div className="px-4 py-2 text-sm flex justify-between text-gray-900">
                          <div>Show Archived</div>

                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            checked={showArchived}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) => {
                              setShowArchived(e.target.checked);
                            }}
                          />
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="py-4">
            <PatientList name={name} showArchived={showArchived} />
          </div>
        </div>
      }
    />
  );
};
