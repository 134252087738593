import React, { Fragment, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon, InboxInIcon } from "@heroicons/react/outline";
import { format, parseISO } from "date-fns";

import {
  INSURANCE_POLICY_SUMMARY_FIELDS,
  ELIGIBILITY_REQUEST_COMMON_FIELDS,
  ELIGIBILITY_REQUEST_STATUS_FIELD,
  PATIENT_HEADER_FIELDS,
  SELECTED_BENEFIT_FIELDS,
} from "../../../graphql";
import { usePatientUser } from "../../patient-user-context";
import {
  classNames,
  formatPercentage,
  formatUSD,
  isDefined,
} from "../../../utils";
import {
  GetLatestPortalBenefitsForInsurancePolicy,
  GetLatestPortalBenefitsForInsurancePolicyVariables,
  GetLatestPortalBenefitsForInsurancePolicy_latestPortalBenefitsForInsurancePolicy as LatestBenefits,
  GetLatestPortalBenefitsForInsurancePolicy_latestPortalBenefitsForInsurancePolicy_inNetworkIndvPlanBenefits as SelectedBenefit,
} from "../../../generated/GetLatestPortalBenefitsForInsurancePolicy";
import { toQuantityQualifierString } from "../../../pages/patients/eligibilities/show";
import { Rings } from "../../../components/loading";
import { NotFound } from "../../../components/404";
import {
  DEDUCTIBLE_HELP_TEXT,
  OOP_HELP_TEXT,
  VISIT_LIMIT_HELP_TEXT,
  SERVICE_DEDUCTIBLE_HELP_TEXT,
} from "./help-text";
import { PlanProgress } from "./plan-progress";
import { usePatientAuth } from "../../patient-auth-context";
import { NetworkBadge } from "../../../pages/patients/networkBadge";
import { FeedbackType } from "../../../generated/globalTypes";
import { FeedbackSurvey } from "../feedback-survey";
import { ServiceTypeCode } from "../../../pages/patients/eligibilities/types";

const formatPayerName = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const PatientBenefitsPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const params = useParams<{ insurancePolicyId: string }>();
  const insurancePolicyId = params.insurancePolicyId!;
  const user = usePatientUser();
  const { logout } = usePatientAuth();

  return user ? (
    <body>
      <main className="overflow-y-scroll mb-10 pb-5">
        <PatientBenefits insurancePolicyId={insurancePolicyId} />
      </main>
      <footer className="bg-indigo-50 fixed bottom-0 left-0 w-full flex justify-end">
        <a
          onClick={() => logout(insurancePolicyId)}
          className="inline-flex items-center justify-center my-2 mx-10 px-5 py-1 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 md:text-lg"
        >
          Done
        </a>
      </footer>
    </body>
  ) : (
    <>No user</>
  );
};

const GET_PATIENT_ELIGIBILITY = gql`
  ${SELECTED_BENEFIT_FIELDS}
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  query GetLatestPortalBenefitsForInsurancePolicy($insurancePolicyId: String!) {
    latestPortalBenefitsForInsurancePolicy(
      insurancePolicyId: $insurancePolicyId
    ) {
      id
      createdAt
      insurancePolicy {
        ...InsurancePolicySummaryFields
        customPatientNote
        patient {
          id
          firstName
          lastName
          displayName
          email
          organizationId
          organization {
            logoUrl
            name
          }
        }
      }
      inNetworkIndvPlanBenefits: planBenefits(
        inNetwork: true
        coverageLevel: IND
      ) {
        ...SelectedBenefitFields
      }
      outNetworkIndvPlanBenefits: planBenefits(
        inNetwork: false
        coverageLevel: IND
      ) {
        ...SelectedBenefitFields
      }
      inNetworkFamPlanBenefits: planBenefits(
        inNetwork: true
        coverageLevel: FAM
      ) {
        ...SelectedBenefitFields
      }
      outNetworkFamPlanBenefits: planBenefits(
        inNetwork: false
        coverageLevel: FAM
      ) {
        ...SelectedBenefitFields
      }
      inNetworkServiceBenefits: serviceBenefits(inNetwork: true) {
        ...SelectedBenefitFields
      }
      outNetworkServiceBenefits: serviceBenefits(inNetwork: false) {
        ...SelectedBenefitFields
      }
      inNetworkOrganizationServiceBenefits: organizationServiceBenefits(
        inNetwork: true
      ) {
        ...SelectedBenefitFields
      }
      outNetworkOrganizationServiceBenefits: organizationServiceBenefits(
        inNetwork: false
      ) {
        ...SelectedBenefitFields
      }
      ...EligibilityRequestStatusFields
    }
  }
`;

const Benefits: React.FC<
  React.PropsWithChildren<{
    latestBenefits: LatestBenefits;
    orgPayerNetworkStatus: boolean;
    orgName: String;
  }>
> = ({ latestBenefits, orgPayerNetworkStatus, orgName }) => {
  const defaultNetworkStatus = orgPayerNetworkStatus ? "in" : "out";
  const [networkStatus, setNetworkStatus] = useState<"in" | "out">(
    defaultNetworkStatus
  );
  const [notesDialogOpen, setNotesDialogOpen] = useState(false);
  const [noteBenefit, setNoteBenefit] = useState<{
    name: string;
    notes: string[];
  } | null>();
  const [helpDialog, setHelpDialog] = useState<{
    label: string;
    helpText: string;
  } | null>();
  const [showSurvey, setShowSurvey] = useState(false);

  const {
    insurancePolicy,
    inNetworkIndvPlanBenefits,
    inNetworkFamPlanBenefits,
    outNetworkIndvPlanBenefits,
    outNetworkFamPlanBenefits,
    inNetworkOrganizationServiceBenefits,
    outNetworkOrganizationServiceBenefits,
  } = latestBenefits;
  const planBenefits =
    networkStatus === "in"
      ? { indv: inNetworkIndvPlanBenefits, fam: inNetworkFamPlanBenefits }
      : { indv: outNetworkIndvPlanBenefits, fam: outNetworkFamPlanBenefits };

  const organizationServiceBenefits = React.useMemo(() => {
    const organizationServiceBenefits: {
      [name: string]: {
        in?: SelectedBenefit;
        out?: SelectedBenefit;
        serviceTypeCode: string;
      };
    } = {};

    inNetworkOrganizationServiceBenefits?.forEach((benefit) => {
      const group = organizationServiceBenefits[benefit.name] || {};
      group.in = benefit;
      group.serviceTypeCode = benefit.serviceTypeCode;
      organizationServiceBenefits[benefit.name] = group;
    });

    outNetworkOrganizationServiceBenefits?.forEach((benefit) => {
      const group = organizationServiceBenefits[benefit.name] || {};
      group.out = benefit;
      group.serviceTypeCode = benefit.serviceTypeCode;
      organizationServiceBenefits[benefit.name] = group;
    });
    return organizationServiceBenefits;
  }, [
    inNetworkOrganizationServiceBenefits,
    outNetworkOrganizationServiceBenefits,
  ]);

  const openNoteDiaglog = (benefit: { name: string; notes: string[] }) => {
    setNotesDialogOpen(true);
    setNoteBenefit(benefit);
  };

  const indvDeductibleDefined =
    isDefined(planBenefits.indv.deductible.remaining) ||
    isDefined(planBenefits.indv.deductible.max);

  const indvOopDefined =
    isDefined(planBenefits.indv.outOfPocket.remaining) ||
    isDefined(planBenefits.indv.outOfPocket.max);

  const famDeductibleDefined =
    isDefined(planBenefits.fam.deductible.remaining) ||
    isDefined(planBenefits.fam.deductible.max);

  const famOopDefined =
    isDefined(planBenefits.fam.outOfPocket.remaining) ||
    isDefined(planBenefits.fam.outOfPocket.max);

  return (
    <div className="text-gray-900 flex flex-col">
      <div className="flex justify-between items-center h-16 bg-white border-b border-gray-300 px-4 lg:px-16">
        <img
          className="h-14 w-auto"
          // TODO: handle missing logo url
          src={latestBenefits.insurancePolicy.patient.organization.logoUrl!}
          alt="Logo"
        />
        <div>
          <button
            className="inline-flex items-center space-x-1 justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={() => setShowSurvey(true)}
          >
            <div>Submit Feedback</div>
            <InboxInIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
      {/* Banner Message */}
      <div className="bg-indigo-50">
        <div className="max-w-4xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="font-medium text-indigo-900 text-sm">
              {insurancePolicy.customPatientNote ? (
                <span className="whitespace-pre-wrap">
                  {insurancePolicy.customPatientNote}
                </span>
              ) : (
                <span>
                  Please find your insurance coverage details below for all the
                  services {orgName} offers. We hope that this information will
                  provide you clarity on what to expect prior to your
                  appointment.
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="p-2 lg:mx-auto lg:max-w-6xl">
        <div className="grid grid-cols-3 lg:gap-x-2 gap-y-2">
          <div className="col-span-3 lg:col-start-2 lg:col-span-2">
            <div className="px-2 border-b border-gray-200 rounded-md">
              <nav className="-mb-px flex" aria-label="Tabs">
                <button
                  className={classNames(
                    networkStatus === "in"
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm"
                  )}
                  aria-current={networkStatus === "in" ? "page" : undefined}
                  onClick={() => setNetworkStatus("in")}
                >
                  In Network
                </button>
                <button
                  className={classNames(
                    networkStatus === "out"
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm"
                  )}
                  aria-current={networkStatus === "out" ? "page" : undefined}
                  onClick={() => setNetworkStatus("out")}
                >
                  Out of Network
                </button>
              </nav>
            </div>
          </div>
          <section className="col-span-3 lg:col-span-1">
            {/* Plan Identificaton Card */}
            <div className="px-4 py-5 border-2 border-gray-200 rounded-md sm:px-5">
              <h2 className="text-xl">
                {insurancePolicy.patient.firstName}{" "}
                {insurancePolicy.patient.lastName}
              </h2>
              <div className="text-sm ">
                <div className="text-gray-500">
                  Benefits checked on{" "}
                  {format(parseISO(latestBenefits.createdAt), "MM/dd/yyyy")}
                </div>
                <div className="mt-2">
                  <span className="text-gray-500">Member ID</span>{" "}
                  {insurancePolicy.memberId}
                </div>
                <div className="mt-2">
                  <div className="flex justify-start">
                    <span className="text-gray-500">Payer</span>{" "}
                    <div className="ml-2">
                      <NetworkBadge
                        orgPayerNetworkStatus={orgPayerNetworkStatus}
                      />
                    </div>
                  </div>
                  {formatPayerName(insurancePolicy.payer.name)}
                  <div className="text-xs text-gray-700">
                    <p className="bg-gray-100 mt-1 pl-2 py-1">
                      Please note that whether we're in-network with your
                      insurance may change based on your specific plan and the
                      provider you see. Please check with us before your
                      appointment.
                    </p>
                  </div>
                </div>
                {insurancePolicy.plan?.name && (
                  <div>
                    <span className="text-gray-500">Plan</span>{" "}
                    {formatPayerName(insurancePolicy.plan?.name)}
                  </div>
                )}
              </div>
            </div>
            {/* Deductible and OOP Card */}
            <div className="bg-white px-4 py-5 border-2 border-gray-200 rounded-md sm:px-6 mt-2">
              <div className="flex flex-col text-xl pb-4">
                <h2 className="text-xl font-medium text-gray-900 pb-2 border-b">
                  Your Plan Progress
                </h2>
                <PlanProgress
                  planBenefits={planBenefits}
                  setHelpDialog={setHelpDialog}
                />
              </div>
              <div>
                <h2 className="text-xl font-medium text-gray-900 pb-2 border-b">
                  Individual
                </h2>
                {indvDeductibleDefined || indvOopDefined ? (
                  <div>
                    {indvDeductibleDefined && (
                      <ProgressSection
                        label="Deductible"
                        helpText={DEDUCTIBLE_HELP_TEXT}
                        remaining={planBenefits.indv?.deductible?.remaining}
                        max={planBenefits.indv?.deductible?.max}
                        openHelpDialog={setHelpDialog}
                      />
                    )}
                    {indvOopDefined && (
                      <ProgressSection
                        label="Out of Pocket"
                        helpText={OOP_HELP_TEXT}
                        remaining={planBenefits.indv?.outOfPocket?.remaining}
                        max={planBenefits.indv?.outOfPocket?.max}
                        openHelpDialog={setHelpDialog}
                      />
                    )}
                  </div>
                ) : (
                  <div className="pt-2 text-lg">
                    No plan-level indivdual deductible or out-of-pocket max.
                  </div>
                )}
              </div>
              <div>
                <h2 className="text-xl font-medium text-gray-900 pb-2 border-b">
                  Family
                </h2>
                {famDeductibleDefined || famOopDefined ? (
                  <div>
                    {famDeductibleDefined && (
                      <ProgressSection
                        label="Deductible"
                        helpText={DEDUCTIBLE_HELP_TEXT}
                        remaining={planBenefits.fam?.deductible?.remaining}
                        max={planBenefits.fam?.deductible?.max}
                        openHelpDialog={setHelpDialog}
                      />
                    )}
                    {famOopDefined && (
                      <ProgressSection
                        label="Out of Pocket"
                        helpText={OOP_HELP_TEXT}
                        remaining={planBenefits.fam?.outOfPocket?.remaining}
                        max={planBenefits.fam?.outOfPocket?.max}
                        openHelpDialog={setHelpDialog}
                      />
                    )}
                  </div>
                ) : (
                  <div className="pt-2 text-lg">
                    No plan-level family deductible or out-of-pocket max.
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="col-span-3 lg:col-span-2">
            {Object.entries(organizationServiceBenefits)
              .filter(
                ([key, value]) =>
                  value.serviceTypeCode !==
                  ServiceTypeCode.HealthBenefitPlanCoverage
              )
              .map(([key, value]) => {
                const benefit = networkStatus === "in" ? value.in : value.out;
                const copay = isDefined(benefit?.copay.amount)
                  ? formatUSD(benefit!.copay.amount)
                  : "Does not apply";
                const coinsurance = isDefined(benefit?.coinsurance.percentage)
                  ? formatPercentage(benefit!.coinsurance.percentage)
                  : "Does not apply";

                const copayNotes =
                  benefit?.copay?.amountBenefits?.[0]?.notes || [];
                const coinsuranceNotes =
                  benefit?.coinsurance?.percentageBenefits?.[0]?.notes || [];

                let limitationNotes =
                  benefit?.limitation?.remainingBenefits?.[0]?.notes ||
                  benefit?.limitation?.maxBenefits?.[0]?.notes ||
                  [];
                // TODO: Make combined limits a real concept in our app
                let combinedLimitNotes: string[] = [];
                if (isDefined(limitationNotes)) {
                  limitationNotes.forEach((note) => {
                    if (
                      note.toLowerCase().includes("combined") ||
                      note.toLowerCase().includes("comb limit") ||
                      note.toLowerCase().includes("comb visit") ||
                      note.toLowerCase().includes("shared with")
                    ) {
                      combinedLimitNotes.push(note);
                    }
                  });
                  limitationNotes = limitationNotes.filter((note) => {
                    return !combinedLimitNotes.includes(note);
                  });
                }

                const serviceLimitMet =
                  isDefined(benefit?.limitation.remaining) &&
                  benefit?.limitation.remaining === 0;

                const serviceDeductibleMax = benefit?.deductible?.max;
                const serviceDeductibleRemaining =
                  benefit?.deductible.remaining;

                const showCoinsurance =
                  isDefined(benefit?.coinsurance.percentage) &&
                  (benefit!.coinsurance.percentage > 0 ||
                    !isDefined(benefit?.copay.amount) ||
                    benefit?.copay.amount === 0);
                const showCopay =
                  isDefined(benefit?.copay.amount) &&
                  (benefit!.copay.amount > 0 || !showCoinsurance);
                const showLimitation =
                  isDefined(benefit?.limitation?.remaining) ||
                  isDefined(benefit?.limitation?.max);
                const showServiceDeductible =
                  isDefined(serviceDeductibleRemaining) ||
                  isDefined(serviceDeductibleMax);

                const noBenefits =
                  !showCoinsurance &&
                  !showCopay &&
                  !showLimitation &&
                  !showServiceDeductible;

                return (
                  <div
                    className="p-2 mb-2 border-2 border-gray-200 rounded-md text-lg"
                    key={key}
                  >
                    <div className="pb-2 border-b border-gray-300 md:flex md:justify-between">
                      <h2 className="text-xl font-semibold text-gray-900">
                        {benefit?.name}
                      </h2>
                      {serviceLimitMet && (
                        <span className="text-red-500 text-sm">
                          Annual service limit reached - Service not covered
                        </span>
                      )}
                    </div>
                    {noBenefits ? (
                      <div className="pt-2">No information</div>
                    ) : (
                      <ul className="pt-2 pl-5 list-disc">
                        {showCoinsurance && (
                          <li>
                            <div className="inline">
                              <span
                                className={
                                  "text-gray-900" +
                                  (!serviceLimitMet ? " font-bold" : "")
                                }
                              >
                                You pay {coinsurance}
                              </span>
                              {benefit?.coinsurance?.patientExplanation && (
                                <span className="pl-1">
                                  {benefit.coinsurance.patientExplanation}
                                </span>
                              )}
                              {coinsuranceNotes.length > 0 && (
                                <button
                                  className="p-1 ml-1 rounded-full hover:bg-gray-200 hover:text-white align-bottom"
                                  onClick={() =>
                                    openNoteDiaglog({
                                      name: benefit?.name!,
                                      notes: coinsuranceNotes,
                                    })
                                  }
                                >
                                  <InformationCircleIcon className="text-gray-600 h-5" />
                                </button>
                              )}
                            </div>
                          </li>
                        )}
                        {showCopay && (
                          <li>
                            <div className="inline">
                              <span
                                className={
                                  "text-gray-900" +
                                  (!serviceLimitMet ? " font-bold" : "")
                                }
                              >
                                You pay {copay}
                              </span>
                              {benefit?.copay?.patientExplanation && (
                                <span className="pl-1">
                                  {benefit.copay.patientExplanation}
                                </span>
                              )}
                              {copayNotes.length > 0 && (
                                <button
                                  className="p-1 ml-1 rounded-full hover:bg-gray-200 hover:text-white align-bottom"
                                  onClick={() =>
                                    openNoteDiaglog({
                                      name: benefit?.name!,
                                      notes: copayNotes,
                                    })
                                  }
                                >
                                  <InformationCircleIcon className="text-gray-600 h-5" />
                                </button>
                              )}
                            </div>
                          </li>
                        )}
                        {showLimitation && (
                          <li>
                            <div className="inline">
                              <span className="">
                                You have{" "}
                                {isDefined(benefit?.limitation?.remaining) && (
                                  <>
                                    <span
                                      className={
                                        (benefit?.limitation?.remaining === 0 &&
                                          "text-red-500 font-bold") ||
                                        "text-gray-900 font-bold"
                                      }
                                    >
                                      {benefit?.limitation?.remaining} remaining
                                    </span>
                                  </>
                                )}
                                <span>
                                  {isDefined(benefit?.limitation?.max)
                                    ? " of "
                                    : ""}
                                  {benefit?.limitation?.max}
                                </span>{" "}
                                <button
                                  onClick={() =>
                                    setHelpDialog({
                                      label: "Visit Limit",
                                      helpText: VISIT_LIMIT_HELP_TEXT,
                                    })
                                  }
                                >
                                  <span className="decoration-dotted underline underline-offset-2">
                                    covered
                                  </span>
                                </button>{" "}
                                <span>
                                  {toQuantityQualifierString(
                                    benefit?.limitation?.maxBenefit
                                      ?.quantityQualifier ||
                                      benefit?.limitation
                                        ?.remainingBenefits?.[0]
                                        ?.quantityQualifier ||
                                      ""
                                  )}
                                </span>
                              </span>
                              {limitationNotes.length > 0 && (
                                <button
                                  className="p-1 ml-1 rounded-full hover:bg-gray-200 hover:text-white align-bottom"
                                  onClick={() =>
                                    openNoteDiaglog({
                                      name: benefit?.name!,
                                      notes: limitationNotes,
                                    })
                                  }
                                >
                                  <InformationCircleIcon className="text-gray-600 h-5" />
                                </button>
                              )}
                            </div>
                          </li>
                        )}
                        {combinedLimitNotes.length > 0 && (
                          <div className="bg-gray-50 border-gray-400 p-2">
                            <div className="ml-1 pl-4 text-gray-700">
                              {combinedLimitNotes.map((note) => {
                                return (
                                  <li>
                                    <div className="text-sm">{note}</div>
                                  </li>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        {showServiceDeductible && (
                          <li>
                            <div className="inline">
                              {isDefined(serviceDeductibleMax) &&
                              serviceDeductibleMax === 0 ? (
                                <span>
                                  Deductible does not apply to this service
                                </span>
                              ) : (
                                <span>
                                  {isDefined(serviceDeductibleRemaining) &&
                                  serviceDeductibleRemaining === 0 ? (
                                    <span>
                                      Your deductible{" "}
                                      {isDefined(serviceDeductibleMax) && (
                                        <span>
                                          {" "}
                                          of {formatUSD(serviceDeductibleMax)}
                                        </span>
                                      )}{" "}
                                      has been met
                                    </span>
                                  ) : (
                                    <span>
                                      {isDefined(serviceDeductibleRemaining) &&
                                        serviceDeductibleRemaining !== 0 && (
                                          <span className="text-gray-900">
                                            {formatUSD(
                                              serviceDeductibleRemaining
                                            )}{" "}
                                            <button
                                              onClick={() =>
                                                setHelpDialog({
                                                  label:
                                                    "Service level deductible",
                                                  helpText:
                                                    SERVICE_DEDUCTIBLE_HELP_TEXT,
                                                })
                                              }
                                            >
                                              <span className="decoration-dotted underline underline-offset-2">
                                                deductible
                                              </span>
                                            </button>{" "}
                                            remaining of{" "}
                                          </span>
                                        )}{" "}
                                      {isDefined(serviceDeductibleMax) && (
                                        <span className="text-gray-900">
                                          {formatUSD(serviceDeductibleMax)} max
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </span>
                              )}
                            </div>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                );
              })}
          </section>
          <section className="col-span-3 lg:col-start-2 lg:col-span-2 py-2">
            <p className="text-sm">
              Disclaimer: Benefit coverage is determined by your specific policy
              documents, such as an Evidence of Coverage or Schedule of
              Benefits, and applicable laws and regulations that may require
              coverage for a specific service. Payment of benefits are subject
              to all terms, conditions, limitations, and exclusions of the
              contract with your insurance company at time of service.
            </p>
          </section>
        </div>

        <Transition.Root show={notesDialogOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => setNotesDialogOpen(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-medium leading-6 text-gray-900"
                  >
                    {noteBenefit?.name} Insurance Benefit Notes
                  </Dialog.Title>
                  <div className="mt-2 text-sm">
                    <ul className="list-disc pl-5">
                      {noteBenefit?.notes.map((note, i) => (
                        <li key={i}>{note}</li>
                      ))}
                    </ul>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                      onClick={() => setNotesDialogOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Help dialog */}
        <Transition.Root show={!!helpDialog} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => setHelpDialog(null)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-medium leading-6 text-gray-900"
                  >
                    {helpDialog?.label}
                  </Dialog.Title>
                  <div className="mt-2 text-sm">{helpDialog?.helpText}</div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                      onClick={() => setHelpDialog(null)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <FeedbackSurvey
          title="How helpful was this insurance benefits summary?"
          showSurvey={showSurvey}
          setShowSurvey={setShowSurvey}
          feedbackType={FeedbackType.PATIENT_BENEFITS}
          patientId={latestBenefits.insurancePolicy.patient.id}
        />
      </div>
    </div>
  );
};

const PatientBenefits: React.FC<
  React.PropsWithChildren<{ insurancePolicyId: string }>
> = ({ insurancePolicyId }) => {
  const { loading, data } = useQuery<
    GetLatestPortalBenefitsForInsurancePolicy,
    GetLatestPortalBenefitsForInsurancePolicyVariables
  >(GET_PATIENT_ELIGIBILITY, {
    variables: { insurancePolicyId },
  });

  if (loading)
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );

  return data?.latestPortalBenefitsForInsurancePolicy ? (
    <Benefits
      latestBenefits={data.latestPortalBenefitsForInsurancePolicy}
      orgPayerNetworkStatus={
        data.latestPortalBenefitsForInsurancePolicy.insurancePolicy.inNetwork
      }
      orgName={
        data.latestPortalBenefitsForInsurancePolicy.insurancePolicy.patient
          .organization.name
      }
    />
  ) : (
    <NotFound />
  );
};

const ProgressSection: React.FC<
  React.PropsWithChildren<{
    label: string;
    helpText: string;
    remaining?: number | null;
    max?: number | null;
    openHelpDialog: (arg: { label: string; helpText: string } | null) => void;
  }>
> = ({ label, helpText, openHelpDialog, remaining, max }) => {
  const percentageSpent =
    isDefined(remaining) && !!max && formatPercentage(1 - remaining / max);
  return (
    <div className="py-4">
      <div className="flex">
        {isDefined(remaining) ? (
          <div className="w-full text-xl inline">
            <span className="font-semibold text-gray-900">
              {formatUSD(remaining)}
            </span>{" "}
            <button onClick={() => openHelpDialog({ label, helpText })}>
              <span className="decoration-dotted underline underline-offset-2 pr-1">
                {label}
              </span>
            </button>
            Remaining
          </div>
        ) : (
          isDefined(max) && (
            <div className="w-full text-xl inline">
              <span className="font-semibold text-gray-900">
                {formatUSD(max)}
              </span>{" "}
              <button onClick={() => openHelpDialog({ label, helpText })}>
                <span className="decoration-dotted underline underline-offset-2 pr-1">
                  {label}
                </span>
              </button>
              Max
            </div>
          )
        )}
      </div>
      <div className="flex">
        <div className="w-full bg-gray-200 h-3 rounded-lg">
          <div
            className="bg-green-600 h-3 rounded-lg"
            style={{ width: `${percentageSpent}` }}
          ></div>
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          {isDefined(remaining) && max && formatUSD(max - remaining) + " spent"}
        </div>
        <div>{max && "out of " + formatUSD(max)}</div>
      </div>
    </div>
  );
};
