import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Select from "react-select";
import * as HoverCard from "@radix-ui/react-hover-card";
import * as Sentry from "@sentry/react";

import {
  classNames,
  formatDateMMDDYYYY,
  formatUSD,
  getDelinquentDaysFromUserOrg,
  isDefined,
  oldBillStateDisplay,
  sleep,
  toCents,
  toDateMMDDYYYY,
} from "../../utils";
import {
  Button,
  Card,
  Modal,
  ProgressBar,
  SubmitButton,
  Table,
  Tooltip,
} from "../../components";
import { OvalSpinner } from "../../components/loading";
import { HorizontalPadding, FullWidthLayout, Layout } from "../layout";
import {
  PlusIcon,
  XIcon,
  SearchIcon,
  PaperAirplaneIcon,
  ArchiveIcon,
  CheckIcon,
  ChevronDownIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
  CurrencyDollarIcon,
  DocumentDownloadIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import { Td } from "../../components/Table";
import {
  GetBills,
  GetBillsVariables,
  GetBills_bills as Bill,
  GetBills_bills_charges as Charge,
} from "../../generated/GetBills";
import {
  BillCommunicationState,
  BillOrderByWithRelationInput,
  BillState,
  BillWhereInput,
  CommunicationType,
  NullsOrder,
  PatientWhereInput,
  PaymentRequestStatus,
  QueryMode,
  SortOrder,
} from "../../generated/globalTypes";
import { Menu, Popover, Tab, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../user-context";
import {
  GetLocationBillStats,
  GetLocationBillStatsVariables,
} from "../../generated/GetLocationBillStats";
import { UPDATE_BILL } from "../../graphql";
import { UpdateBill, UpdateBillVariables } from "../../generated/UpdateBill";
import { ArchiveBill, ArchiveBillVariables } from "../../generated/ArchiveBill";
import {
  GetAccountTypes,
  GetAccountTypesVariables,
  GetAccountTypes_accountTypes as AccountType,
} from "../../generated/GetAccountTypes";
import {
  UpdateManyBillStatus,
  UpdateManyBillStatusVariables,
} from "../../generated/UpdateManyBillStatus";
import { toast } from "react-toastify";
import {
  GetPatientsWithReadyBalance,
  GetPatientsWithReadyBalanceVariables,
  GetPatientsWithReadyBalance_getPatientsWithReadyBalance as Patient,
} from "../../generated/GetPatientsWithReadyBalance";
import { DollarInput } from "../../components/input";
import {
  addDays,
  compareAsc,
  compareDesc,
  format,
  formatDistanceToNow,
  isPast,
  isValid,
  parseISO,
  startOfDay,
  subDays,
} from "date-fns";
import { BillActivityTimeline } from "./bill-activity-timeline";
import { useAnalytics } from "../../analytics-context";
import { CSVLink } from "react-csv";
import {
  GetBillsForDownload,
  GetBillsForDownloadVariables,
} from "../../generated/GetBillsForDownload";
import {
  GetDelinquentPatients,
  GetDelinquentPatientsVariables,
} from "../../generated/GetDelinquentPatients";
import {
  GetSentPatients,
  GetSentPatientsVariables,
  GetSentPatients_patients as PatientExport,
} from "../../generated/GetSentPatients";
import {
  StartImmediatePaymentRequestBatch,
  StartImmediatePaymentRequestBatchVariables,
} from "../../generated/StartImmediatePaymentRequestBatch";
import {
  SchedulePaymentRequestBatch,
  SchedulePaymentRequestBatchVariables,
} from "../../generated/SchedulePaymentRequestBatch";
import { useFeatureFlags } from "../../hooks";
import {
  GetLocationProviders,
  GetLocationProvidersVariables,
} from "../../generated/GetLocationProviders";
import { BillsTable } from "./table/list";
import { TOGGLE_BILL_ON_HOLD } from "../../graphql";
import {
  ToggleBillOnHold,
  ToggleBillOnHoldVariables,
} from "../../generated/ToggleBillOnHold";
import { BULK_TOGGLE_BILLS_ON_HOLD } from "../../graphql";
import {
  BulkToggleBillsOnHold,
  BulkToggleBillsOnHoldVariables,
} from "../../generated/BulkToggleBillsOnHold";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";

export const GET_BILLS = gql`
  query GetBills(
    $take: Int
    $skip: Int
    $where: BillWhereInput!
    $orderBy: [BillOrderByWithRelationInput!]!
  ) {
    bills(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      account {
        id
        accountType {
          id
          name
        }
        patient {
          id
          displayName
          totalPatientUnallocatedCredits
          reminderWorkflow {
            id
            nextReminderDate
            pausedAt
          }
          autoPayEnabled
          paymentIntents(
            where: { autoPay: { equals: true } }
            orderBy: [{ createdAt: desc }]
            take: 1
          ) {
            id
            status
            lastPaymentError
          }
        }
      }
      appointment {
        id
      }
      primaryProvider {
        id
        displayName
      }
      allowedTotal
      patientBalance
      patientPaidTotal
      insurancePaidTotal
      patientResponsibility
      status
      dateOfService
      dateOfServiceDisplay
      dueDate
      paidAt
      onHoldAt
      communicationStatus
      communications(orderBy: [{ createdAt: desc }]) {
        id
        sentAt
        type
        contentType
        createdAt
        lastErrorType
        lastErrorReasonDisplay
      }
      charges {
        id
        units
        allowedAmount
        insuranceAmount
        patientBalance
        transaction {
          description
          chargeAllocations {
            createdAt
            amount
          }
          customCode
        }
      }
    }
    aggregateBill(where: $where) {
      _count {
        id
      }
    }
  }
`;

export const GET_LOCATION_BILL_STATS = gql`
  query GetLocationBillStats($locationId: String!) {
    getLocationBillStats(locationId: $locationId) {
      inReviewCount
      inReviewSum
      unsentCount
      scheduledCount
      sentCount
      remindingCount
      exhaustedCount
      readySum
      unsentSum
      scheduledSum
      sentSum
      remindingSum
      exhaustedSum
      onHoldCount
      onHoldSum
    }
  }
`;

export const GET_BILLS_FOR_DOWNLOAD = gql`
  query GetBillsForDownload($where: BillWhereInput!) {
    getBillsForDownload(where: $where) {
      patientName
      accountType
      status
      firstSent
      communicationState
      communicationDisplay
      providerName
      dateOfService
      dueDate
      reminders
      nextReminderDate
      allowedAmount
      patientResponsibility
      patientBalance
    }
  }
`;

export const StatusIndicator: React.FC<
  React.PropsWithChildren<{ colorClass: string }>
> = ({ colorClass }) => (
  <div className={`w-[10px] h-[10px] rounded-full ${colorClass}`} />
);
export const EstimatedStatusIndicator: React.FC<
  React.PropsWithChildren<unknown>
> = () => <StatusIndicator colorClass="bg-gray-300" />;
export const PendingStatusIndicator: React.FC<
  React.PropsWithChildren<unknown>
> = () => <StatusIndicator colorClass="bg-yellow-600" />;
export const InReviewStatusIndicator: React.FC<
  React.PropsWithChildren<unknown>
> = () => <StatusIndicator colorClass="bg-yellow-500" />;
export const ReadyStatusIndicator: React.FC<
  React.PropsWithChildren<unknown>
> = () => <StatusIndicator colorClass="bg-blue-600" />;
export const PaidStatusIndicator: React.FC<
  React.PropsWithChildren<unknown>
> = () => <StatusIndicator colorClass="bg-green-600" />;
export const ArchivedStatusIndicator: React.FC<
  React.PropsWithChildren<unknown>
> = () => <StatusIndicator colorClass="bg-gray-500" />;
export const RemindersExhaustedStatusIndicator: React.FC<
  React.PropsWithChildren<unknown>
> = () => <StatusIndicator colorClass="bg-orange-500" />;

export const BillStats: React.FC<
  React.PropsWithChildren<{
    inReviewCount?: number;
    inReviewSum?: number;
    unsentCount?: number;
    unsentSum?: number;
    scheduledCount?: number;
    scheduledSum?: number;
    sentCount?: number;
    sentSum?: number;
    remindingCount?: number;
    remindingSum?: number;
    exhaustedCount?: number;
    exhaustedSum?: number;
    onHoldCount?: number;
    onHoldSum?: number;
    loading: boolean;
    inReviewLoading?: boolean;
    readyLoading?: boolean;
    setInReviewFilter: () => void;
    setReadyUnsentFilter: () => void;
    setReadyScheduledFilter: () => void;
    setReadySentFilter: () => void;
    setReadyRemindingFilter: () => void;
    setRemindersExhaustedFilter: () => void;
    setOnHoldFilter: () => void;
  }>
> = ({
  inReviewCount,
  inReviewSum,
  unsentCount,
  unsentSum,
  scheduledCount,
  scheduledSum,
  sentCount,
  sentSum,
  remindingCount,
  remindingSum,
  exhaustedCount,
  exhaustedSum,
  onHoldCount,
  onHoldSum,
  loading,
  inReviewLoading,
  readyLoading,
  setInReviewFilter,
  setReadyUnsentFilter,
  setReadySentFilter,
  setReadyScheduledFilter,
  setReadyRemindingFilter,
  setRemindersExhaustedFilter,
  setOnHoldFilter,
}) => {
  return (
    <dl className="w-full grid divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow grid-cols-1 md:grid-cols-7 md:divide-y-0 md:divide-x">
      <div className="px-4 py-5 sm:p-6 flex justify-center col-span-1">
        <button
          className="text-gray-900 hover:text-gray-500"
          onClick={setInReviewFilter}
        >
          <dt className="flex justify-center items-center gap-1 text-base font-normal">
            <Tooltip
              trigger={
                <div className="flex items-center gap-1">
                  <InReviewStatusIndicator />
                  In Review
                </div>
              }
              content={
                <div className="max-w-sm text-center">
                  The charges on this bill have been finalized and are waiting
                  to be marked as ready for the patient to pay.
                </div>
              }
            />
          </dt>
          <dd className="mt-1 flex flex-col justify-center items-center">
            <div className="text-2xl font-semibold flex flex-col">
              {inReviewLoading || loading ? (
                <OvalSpinner />
              ) : (
                isDefined(inReviewSum) && formatUSD(-inReviewSum)
              )}
              {isDefined(inReviewCount) && (
                <span className="text-gray-700 hover:text-gray-500 text-sm font-normal">
                  ({inReviewCount.toLocaleString()})
                </span>
              )}
            </div>
          </dd>
        </button>
      </div>
      <div className="px-4 py-5 sm:p-6 flex justify-center col-span-1">
        <button
          className="text-gray-900 hover:text-gray-500"
          onClick={setOnHoldFilter}
        >
          <dt className="flex justify-center items-center gap-1 text-base font-normal">
            <Tooltip
              trigger={
                <div className="flex items-center gap-1">
                  <InReviewStatusIndicator />
                  On Hold
                </div>
              }
              content={
                <div className="max-w-sm text-center">
                  Bills that are in review but have been put on hold.
                </div>
              }
            />
          </dt>
          <dd className="mt-1 flex flex-col justify-center items-center">
            <div className="text-2xl font-semibold flex flex-col">
              {inReviewLoading || loading ? (
                <OvalSpinner />
              ) : (
                isDefined(onHoldSum) && formatUSD(-onHoldSum)
              )}
              {isDefined(onHoldCount) && (
                <span className="text-gray-700 hover:text-gray-500 text-sm font-normal">
                  ({onHoldCount.toLocaleString()})
                </span>
              )}
            </div>
          </dd>
        </button>
      </div>
      <div className="px-4 py-5 sm:p-6 flex justify-center col-span-1">
        <button
          className="text-gray-900 hover:text-gray-500"
          onClick={setReadyUnsentFilter}
        >
          <dt className="flex justify-center items-center gap-1 text-base font-normal">
            <Tooltip
              trigger={
                <div className="flex items-center gap-1">
                  <ReadyStatusIndicator />
                  Not Shared
                </div>
              }
              content={
                <div className="max-w-sm text-center">
                  The bill is ready to pay but payment has not yet been
                  requested.
                </div>
              }
            />
          </dt>
          <dd className="mt-1 flex flex-col justify-center items-center">
            <div className="text-2xl font-semibold flex flex-col">
              {readyLoading || loading ? (
                <OvalSpinner />
              ) : (
                isDefined(unsentSum) && formatUSD(-unsentSum)
              )}
              {isDefined(unsentCount) && (
                <span className="text-gray-700 hover:text-gray-500 text-sm font-normal">
                  ({unsentCount.toLocaleString()})
                </span>
              )}
            </div>
          </dd>
        </button>
      </div>
      <div className="px-4 py-5 sm:p-6 flex justify-center col-span-1">
        <button
          className="text-gray-900 hover:text-gray-500"
          onClick={setReadyScheduledFilter}
        >
          <dt className="flex justify-center items-center gap-1 text-base font-normal">
            <Tooltip
              trigger={
                <div className="flex items-center gap-1">
                  <ReadyStatusIndicator />
                  Scheduled
                </div>
              }
              content={
                <div className="max-w-sm text-center">
                  The bill is ready to pay and the payment request has been
                  scheduled.
                </div>
              }
            />
          </dt>
          <dd className="mt-1 flex flex-col justify-center items-center">
            <div className="text-2xl font-semibold flex flex-col">
              {readyLoading || loading ? (
                <OvalSpinner />
              ) : (
                isDefined(scheduledSum) && formatUSD(-scheduledSum)
              )}
              {isDefined(scheduledCount) && (
                <span className="text-gray-700 hover:text-gray-500 text-sm font-normal">
                  ({scheduledCount.toLocaleString()})
                </span>
              )}
            </div>
          </dd>
        </button>
      </div>
      <div className="px-4 py-5 sm:p-6 flex justify-center col-span-1">
        <button
          className="text-gray-900 hover:text-gray-500"
          onClick={setReadySentFilter}
        >
          <dt className="flex justify-center items-center gap-1 text-base font-normal">
            <Tooltip
              trigger={
                <div className="flex items-center gap-1">
                  <ReadyStatusIndicator />
                  Sent
                </div>
              }
              content={
                <div className="max-w-sm text-center">
                  The bill is ready to pay, a payment request has been sent, and
                  the patient is not enrolled in automatic reminders.
                </div>
              }
            />
          </dt>
          <dd className="mt-1 flex flex-col justify-center items-center">
            <div className="text-2xl font-semibold flex flex-col">
              {readyLoading || loading ? (
                <OvalSpinner />
              ) : (
                isDefined(sentSum) && formatUSD(-sentSum)
              )}
              {isDefined(sentCount) && (
                <span className="text-gray-700 hover:text-gray-500 text-sm font-normal">
                  ({sentCount.toLocaleString()})
                </span>
              )}
            </div>
          </dd>
        </button>
      </div>
      <div className="px-4 py-5 sm:p-6 flex justify-center col-span-1">
        <button
          className="text-gray-900 hover:text-gray-500"
          onClick={setReadyRemindingFilter}
        >
          <dt className="flex justify-center items-center gap-1 text-base font-normal">
            <Tooltip
              trigger={
                <div className="flex items-center gap-1">
                  <ReadyStatusIndicator />
                  Reminding
                </div>
              }
              content={
                <div className="max-w-sm text-center">
                  The bill is ready to pay, a payment request has been sent, and
                  the patient is enrolled in automatic reminders.
                </div>
              }
            />
          </dt>
          <dd className="mt-1 flex flex-col justify-center items-center">
            <div className="text-2xl font-semibold flex flex-col">
              {readyLoading || loading ? (
                <OvalSpinner />
              ) : (
                isDefined(remindingSum) && formatUSD(-remindingSum)
              )}
              {isDefined(remindingCount) && (
                <span className="text-gray-700 hover:text-gray-500 text-sm font-normal">
                  ({remindingCount.toLocaleString()})
                </span>
              )}
            </div>
          </dd>
        </button>
      </div>
      <div className="px-4 py-5 sm:p-6 flex justify-center col-span-1">
        <button
          className="text-gray-900 hover:text-gray-500"
          onClick={setRemindersExhaustedFilter}
        >
          <dt className="flex justify-center items-center gap-1 text-base font-normal">
            <Tooltip
              trigger={
                <div className="flex items-center gap-1">
                  <RemindersExhaustedStatusIndicator />
                  Reminders Exhausted
                </div>
              }
              content={
                <div className="max-w-sm text-center">
                  The bill is ready to pay, a payment request has been sent, and
                  the the last automated reminder has been sent.
                </div>
              }
            />
          </dt>
          <dd className="mt-1 flex flex-col justify-center items-center">
            <div className="text-2xl font-semibold flex flex-col">
              {readyLoading || loading ? (
                <OvalSpinner />
              ) : (
                isDefined(exhaustedSum) && formatUSD(-exhaustedSum)
              )}
              {isDefined(exhaustedCount) && (
                <span className="text-gray-700 hover:text-gray-500 text-sm font-normal">
                  ({exhaustedCount.toLocaleString()})
                </span>
              )}
            </div>
          </dd>
        </button>
      </div>
    </dl>
  );
};

const isValidFilter = (comparison: any, value: any) => {
  const defined = isDefined(comparison) && isDefined(value);
  if (defined) {
    // Invalid if empty array passed to Array filter
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    // Invalid if empty string passed to string filter
    if (typeof value === "string") {
      return value !== "";
    }
    return true;
  }
  return false;
};

const FilterInput: React.FC<
  React.PropsWithChildren<{
    filterOption: BillFilterOption;
    setFilter: (filter: FilterValue | null) => void;
  }>
> = ({ filterOption, setFilter }) => {
  const [selectedComparison, setSelectedComparison] = useState<string | null>();
  const [filterValue, setFilterValue] = useState<any>();
  useEffect(() => {
    if (isValidFilter(selectedComparison, filterValue)) {
      setFilter({
        comparison: selectedComparison!,
        value: filterValue!,
      });
    } else {
      setFilter(null);
    }
  }, [selectedComparison, filterValue]);
  return (
    <Tab.Panel key={filterOption.name}>
      <div className="flex flex-col">
        {filterOption.comparisons.map((comparison) => (
          <div className="flex flex-col">
            <div key={comparison} className="flex items-center">
              <input
                id={comparison}
                name="comparison"
                type="radio"
                onChange={(e) => {
                  setSelectedComparison(comparison);
                }}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label
                htmlFor={comparison}
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {comparisonDisplay(comparison)}
              </label>
            </div>
            <div>
              {selectedComparison === comparison &&
                filterOption.type === FilterOptionType.Boolean && (
                  <div className="pl-8">
                    <div className="flex items-center">
                      <input
                        id={`${comparison}-true`}
                        name={`${comparison}-true`}
                        type="radio"
                        checked={filterValue === true}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={() => {
                          setFilterValue(true);
                        }}
                      />
                      <label
                        htmlFor={`${comparison}-true`}
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        True
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id={`${comparison}-false`}
                        name={`${comparison}-false`}
                        type="radio"
                        checked={filterValue === false}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={() => {
                          setFilterValue(false);
                        }}
                      />
                      <label
                        htmlFor={`${comparison}-false`}
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        False
                      </label>
                    </div>
                  </div>
                )}
              {selectedComparison === comparison && isEnumOption(filterOption) && (
                <Select
                  isMulti
                  name={comparison}
                  options={filterOption.options}
                  onChange={(option) => {
                    // @ts-ignore TODO: will this iterator destructing fail on IE?
                    setFilterValue([...option.values()].map((v) => v.value));
                  }}
                />
              )}
              {selectedComparison === comparison &&
                filterOption.type === FilterOptionType.Date && (
                  <input
                    type="date"
                    name={comparison}
                    className="mt-1 px-3 py-2  focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md"
                    onChange={(e) => {
                      setFilterValue(e.target.value);
                    }}
                  />
                )}
              {selectedComparison === comparison &&
                filterOption.type === FilterOptionType.String && (
                  <input
                    type="text"
                    name={comparison}
                    className="mt-1 px-3 py-2  focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md"
                    onChange={(e) => {
                      setFilterValue(e.target.value);
                    }}
                  />
                )}
            </div>
          </div>
        ))}
      </div>
    </Tab.Panel>
  );
};

type FilterValue = { comparison: string; value: any };

const FilterMenu: React.FC<
  React.PropsWithChildren<{
    filterOptions: BillFilterOption[];
    addFilter: (filter: {
      name: BillFilterKey;
      comparison: string;
      value: any;
    }) => void;
    close: () => void;
    defaultTab?: number;
    defaultFilterValue?: FilterValue;
  }>
> = ({ filterOptions, addFilter, close, defaultTab, defaultFilterValue }) => {
  const [filter, setFilter] = useState<FilterValue | null>(
    defaultFilterValue ?? null
  );
  const [tab, setTab] = useState(defaultTab ?? 0);

  return (
    <Card className="flex flex-col min-w-[24em]">
      <div className="grid grid-cols-2 gap-1">
        <Tab.Group
          vertical
          onChange={(tab) => {
            setTab(tab);
          }}
        >
          <Tab.List className="flex flex-col space-y-1">
            {filterOptions.map((filterOption) => {
              return (
                <Tab as={Fragment} key={filterOption.name}>
                  {({ selected }) => (
                    <button
                      className={classNames(
                        "hover:bg-gray-200 py-1 px-2 rounded-md",
                        selected ? "bg-gray-200" : ""
                      )}
                    >
                      {filterKeyDisplay(filterOption.name)}
                    </button>
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels className="flex flex-col space-y-1">
            {filterOptions.map((filterOption) => (
              <FilterInput
                key={filterOption.name}
                filterOption={filterOption}
                setFilter={setFilter}
              />
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="mt-2 sm:mt-6 flex flex-justify space-x-4">
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => close()}
        >
          Close
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-200"
          onClick={() => {
            // Should be defined since we are disabling button if not
            if (isDefined(filter)) {
              addFilter({
                name: filterOptions[tab].name,
                comparison: filter.comparison,
                value: filter.value,
              });
            }
          }}
          disabled={!isDefined(filter)}
        >
          Apply
        </button>
      </div>
    </Card>
  );
};

const NewFilterButton: React.FC<
  React.PropsWithChildren<{
    filterOptions: BillFilterOption[];
    addFilter: (filter: {
      name: BillFilterKey;
      comparison: string;
      value: any;
    }) => void;
  }>
> = ({ filterOptions, addFilter }) => {
  return (
    <Popover className="relative">
      <Popover.Button className="flex items-center space-x-1 h-9 p-1 rounded-md border bg-gray-100 hover:bg-gray-200">
        <PlusIcon className="w-6 h-4" />
      </Popover.Button>

      <Popover.Panel className="absolute z-20 mt-1">
        {({ close }) => (
          <FilterMenu
            filterOptions={filterOptions}
            addFilter={addFilter}
            close={close}
          />
        )}
      </Popover.Panel>
    </Popover>
  );
};

const EditFilterButton: React.FC<
  React.PropsWithChildren<{
    filterKey: BillFilterKey;
    currentFilter: BillFilterOption;
    currentFilterValue?: FilterValue;
    filterOptions: BillFilterOption[];
    addFilter: (filter: {
      name: BillFilterKey;
      comparison: string;
      value: any;
    }) => void;
    removeFilter: (key: BillFilterKey) => void;
    allAccountTypes: AccountType[];
    allProviders: { id: string; displayName: string }[];
    balanceAutopayEnabled: boolean;
  }>
> = ({
  filterKey,
  currentFilter,
  currentFilterValue,
  filterOptions,
  addFilter,
  removeFilter,
  allAccountTypes,
  allProviders,
  balanceAutopayEnabled,
}) => {
  const user = useUser()!;
  return (
    <Popover className="relative">
      <div className="flex items-center space-x-1 h-9 p-2 rounded-md border bg-gray-100 hover:bg-gray-200">
        <Popover.Button>
          <span>
            {filterKeyDisplay(filterKey)}{" "}
            {/* TODO: Do better. Special case handling of enrolledInReminders :( */}
            {filterKey === "enrolledInReminders" ? (
              <>
                <span className="text-gray-500">is</span>{" "}
                {/* NOTE: enrolledInReminders has 1 filters if in the OR if true, 2 if false */}
                {Array.isArray(currentFilter) &&
                currentFilter.find((clause) =>
                  clause.OR?.some(isEnrolledInRemindersPatientWhereInput)
                )?.OR?.length === 1
                  ? "True"
                  : "False"}
              </>
            ) : filterKey === "sent" ? (
              <>
                <span className="text-gray-500">is</span>{" "}
                {/* communications = { some: {} } } if True and communicatiosn = { { none: {} } if False */}
                {/* {"some" in currentFilter ? "True" : "False"} */}
                {/* WARNING: temporarily using dueDate existence instead of communications = { none: {} } to get around unperformant prisma query */}
                {/* Adding more hacks on hacks, "lte" indicates that we're filtering for exhausted bills and should display True */}
                {"not" in currentFilter ||
                "lte" in currentFilter ||
                "gt" in currentFilter
                  ? "True"
                  : "False"}
              </>
            ) : filterKey === "delivered" ? (
              <>
                <span className="text-gray-500">is</span>{" "}
                {"not" in currentFilter ? "True" : "False"}
              </>
            ) : filterKey === "onHold" ? (
              <>
                <span className="text-gray-500">is</span>{" "}
                {"not" in currentFilter ? "True" : "False"}
              </>
            ) : filterKey === "scheduled" ? (
              // TODO: make this less insane
              <>
                <span className="text-gray-500">is</span>{" "}
                {Array.isArray(currentFilter) &&
                currentFilter.some(
                  (clause) =>
                    !!clause.paymentRequestTargets?.some?.paymentRequest?.is
                ) &&
                currentFilter.length === (balanceAutopayEnabled ? 2 : 1)
                  ? "True"
                  : "False"}
              </>
            ) : filterKey === "charges" ? (
              <>
                <span className="text-gray-500">has</span>{" "}
                {(currentFilter as any)?.some?.customCode?.equals}
              </>
            ) : (
              Object.entries(currentFilter).map(([comp, value]) => {
                return (
                  <>
                    <span className="text-gray-500">
                      {comparisonDisplay(comp as FilterComparison)}
                    </span>{" "}
                    {Array.isArray(value)
                      ? (filterKey === "accountType"
                          ? value.map((v) =>
                              accountTypeDisplay(v, allAccountTypes)
                            )
                          : filterKey === "billingProviderId"
                          ? value.map((v) => providerDisplay(v, allProviders))
                          : value
                        ).join(", ")
                      : value}
                  </>
                );
              })
            )}
          </span>
        </Popover.Button>
        <button
          onClick={() => removeFilter(filterKey as BillFilterKey)}
          className="z-10"
        >
          <XIcon className="w-4 h-4" />
        </button>
      </div>
      <Popover.Panel className="absolute z-20 mt-1">
        {({ close }) => (
          <FilterMenu
            filterOptions={[
              ...BILL_FILTER_OPTIONS.filter((f) => f.name === filterKey),
              ...filterOptions,
            ]}
            addFilter={addFilter}
            close={close}
            defaultTab={0}
            defaultFilterValue={currentFilterValue}
          />
        )}
      </Popover.Panel>
    </Popover>
  );
};

export const ChargesHoverCard: React.FC<
  React.PropsWithChildren<{
    bill: Pick<Bill, "charges" | "dateOfServiceDisplay">;
  }>
> = ({ bill }) => {
  return (
    <HoverCard.Root openDelay={100}>
      <HoverCard.Trigger>
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
          {bill.charges.length ?? 0} charges
        </span>
      </HoverCard.Trigger>
      <HoverCard.Content
        side="top"
        sideOffset={5}
        align="center"
        alignOffset={-200}
        style={{ zIndex: 9999 }}
      >
        <div className="flex flex-col bg-white text-gray-900 drop-shadow-xl text-sm rounded-md p-8 pt-6 border">
          <h2 className="text-lg font-medium pb-1">
            Charges for Bill on{" "}
            {bill.dateOfServiceDisplay &&
              toDateMMDDYYYY(bill.dateOfServiceDisplay)}
          </h2>
          <Table
            columnDefs={[
              {
                header: "Code",
                cellFn: (charge: Charge) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {charge.transaction.customCode}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Description",
                cellFn: (charge: Charge) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {charge.transaction.description}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Units",
                cellFn: (charge: Charge) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {charge.units} units
                    </div>
                  </Td>
                ),
              },
              {
                header: "Allowed Amount",
                cellFn: (charge: Charge) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {formatUSD(-charge.allowedAmount)}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Insurance Amount",
                cellFn: (charge: Charge) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {formatUSD(-charge.insuranceAmount)}
                    </div>
                  </Td>
                ),
              },
              {
                header: "Patient Paid",
                cellFn: (charge: Charge) => (
                  <Td>
                    <div>
                      <div className="text-sm font-normal text-gray-900">
                        {formatUSD(
                          charge.transaction.chargeAllocations.reduce(
                            (partialSum: any, payment: { amount: any }) =>
                              partialSum + payment.amount,
                            0
                          )
                        )}
                      </div>
                    </div>
                  </Td>
                ),
              },
              {
                header: "Patient Owes",
                cellFn: (charge: Charge) => (
                  <Td>
                    <div className="text-sm text-gray-900">
                      {formatUSD(-charge.patientBalance)}
                    </div>
                  </Td>
                ),
              },
            ]}
            rows={bill.charges}
          />
        </div>
        <HoverCard.HoverCardArrow fill="white" style={{ marginTop: "-1px" }} />
      </HoverCard.Content>
    </HoverCard.Root>
  );
};

const PAGE_SIZE = 15;

enum FilterComparison {
  In = "in",
  NotIn = "notIn",
  Lt = "lt",
  Lte = "lte",
  Gt = "gt",
  Gte = "gte",
  Equals = "equals",
}

enum FilterOptionType {
  Enum,
  String,
  Date,
  Boolean,
}

type FilterOption = {
  name: BillFilterKey;
  comparisons: FilterComparison[];
  type: FilterOptionType;
};

type EnumFilterOption = FilterOption & {
  type: FilterOptionType.Enum;
  options: {
    label: string;
    value: string;
  }[];
};

const comparisonDisplay = (comparison: FilterComparison) => {
  switch (comparison) {
    case FilterComparison.In:
      return "is";
    case FilterComparison.NotIn:
      return "is not";
    case FilterComparison.Gt:
      return "greater than";
    case FilterComparison.Gte:
      return "greater than or equal to";
    case FilterComparison.Lt:
      return "less than";
    case FilterComparison.Lte:
      return "less than or equal to";
    case FilterComparison.Equals:
      return "equals";
  }
};

const accountTypeDisplay = (
  accountTypeId: string,
  accountTypes: AccountType[]
) => {
  return accountTypes.find((t) => t.id === accountTypeId)?.name;
};

const providerDisplay = (
  providerId: string,
  providers: { id: string; displayName: string }[]
) => {
  return providers.find((t) => t.id === providerId)?.displayName;
};

const isEnumOption = (
  filterOption: BillFilterOption
): filterOption is EnumFilterOption =>
  filterOption.type === FilterOptionType.Enum;

type BillFilterOption = FilterOption | EnumFilterOption;

const BILL_FILTER_OPTIONS: BillFilterOption[] = [
  {
    name: "status",
    comparisons: [FilterComparison.In, FilterComparison.NotIn],
    type: FilterOptionType.Enum,
    options: [
      {
        label: "Pending",
        value: BillState.Pending,
      },
      {
        label: "In Review",
        value: BillState.InReview,
      },
      {
        label: "Ready",
        value: BillState.Ready,
      },
      {
        label: "Reconciled",
        value: BillState.Reconciled,
      },
      {
        label: "Resolved",
        value: BillState.Resolved,
      },
      {
        label: "Archived",
        value: BillState.Archived,
      },
    ],
  },
  {
    name: "dateOfService",
    type: FilterOptionType.Date,
    comparisons: [
      FilterComparison.Gt,
      FilterComparison.Lt,
      FilterComparison.Equals,
    ],
  },
  {
    name: "accountType",
    type: FilterOptionType.Enum,
    comparisons: [FilterComparison.In, FilterComparison.NotIn],
    options: [],
  },
  {
    name: "enrolledInReminders",
    type: FilterOptionType.Boolean,
    comparisons: [FilterComparison.Equals],
  },
  {
    name: "sent",
    type: FilterOptionType.Boolean,
    comparisons: [FilterComparison.Equals],
  },
  {
    name: "delivered",
    type: FilterOptionType.Boolean,
    comparisons: [FilterComparison.Equals],
  },
  {
    name: "scheduled",
    type: FilterOptionType.Boolean,
    comparisons: [FilterComparison.Equals],
  },
  {
    name: "charges",
    type: FilterOptionType.String,
    comparisons: [FilterComparison.Equals],
  },
  {
    name: "billingProviderId",
    type: FilterOptionType.Enum,
    comparisons: [FilterComparison.In, FilterComparison.NotIn],
    options: [],
  },
  {
    name: "onHold",
    type: FilterOptionType.Boolean,
    comparisons: [FilterComparison.Equals],
  },
];

const BILL_FILTER_KEYS = [
  "dateOfService",
  "status",
  "accountType",
  "enrolledInReminders",
  "sent",
  "delivered",
  "scheduled",
  "charges",
  "billingProviderId",
  "onHold",
] as const;
type BillFilterKey = typeof BILL_FILTER_KEYS[number];

const filterKeyDisplay = (key: BillFilterKey) => {
  switch (key) {
    case "accountType":
      return "Account Type";
    case "status":
      return "Status";
    case "dateOfService":
      return "Date of Service";
    case "enrolledInReminders":
      return "Enrolled in Reminders";
    case "sent":
      return "Sent";
    case "delivered":
      return "Delivered";
    case "scheduled":
      return "Scheduled";
    case "charges":
      return "Charges";
    case "billingProviderId":
      return "Provider";
    case "onHold":
      return "On Hold";
  }
};

const getFilterValueFromWhere = (
  key: BillFilterKey,
  where: BillWhereInput
): FilterValue | null => {
  let filters;
  if (key === "accountType") {
    filters = where.account?.is?.accountType ?? null;
  } else if (key === "enrolledInReminders") {
    filters =
      where.account?.is?.patient?.is?.reminderWorkflow?.is?.pausedAt ?? null;
  } else if (key === "sent") {
    filters = where.dueDate ?? null;
  } else if (key === "delivered") {
    filters = !!where.lastDeliveredAt;
  } else if (key === "scheduled") {
    filters = !!(where.OR ?? where.AND);
  } else if (key === "charges") {
    filters = !!where.charges?.some?.customCode?.equals;
  } else if (key === "billingProviderId") {
    filters = !!where.billingProviderId;
  } else if (key === "onHold") {
    filters = !!where.onHoldAt;
  } else {
    filters = where[key];
  }

  // NOTE: This won't work for any combined filters
  if (
    isDefined(filters) &&
    !Array.isArray(filters) &&
    Object.keys(filters).length === 1
  ) {
    let [[comparison, value]] = Object.entries(filters);
    return { comparison, value };
  }
  return null;
};

const GET_PATIENTS_WITH_READY_BALANCE = gql`
  query GetPatientsWithReadyBalance(
    $where: BillWhereInput!
    $minBalance: Int!
  ) {
    getPatientsWithReadyBalance(where: $where, minBalance: $minBalance) {
      id
      displayName
      autoPayEnabled
      pledgeAutopayActive
      patientReadyBalance
      credits
      shareable
      accountTypesWithReadyBills
      numberOfReadyBills
      allBillsManagedByReminders
      mostRecentPaymentIntent {
        id
        status
        lastPaymentError
      }
    }
  }
`;

const GET_PAYMENT_LINK_BATCH_WORKFLOW = gql`
  query GetPaymentLinkBatchWorkflow($workflowId: String!) {
    paymentLinkBatchWorkflow(workflowId: $workflowId) {
      workflowId
      processed
      totalMessages
    }
  }
`;

export const BatchWorkflowProgress: React.FC<
  React.PropsWithChildren<{
    workflowId: string;
    setComplete: (complete: boolean) => void;
  }>
> = ({ workflowId, setComplete }) => {
  const { data, loading, stopPolling } = useQuery(
    GET_PAYMENT_LINK_BATCH_WORKFLOW,
    {
      variables: { workflowId },
      pollInterval: 1000,
    }
  );

  useEffect(() => {
    if (
      isDefined(data?.paymentLinkBatchWorkflow) &&
      data.paymentLinkBatchWorkflow.processed ===
        data.paymentLinkBatchWorkflow.totalMessages
    ) {
      stopPolling();
      sleep(300).then(() => {
        setComplete(true);
      });
    }
  }, [data, loading]);

  if (loading || !data) return <></>;

  const processed = data.paymentLinkBatchWorkflow.processed;
  const totalMessages = data.paymentLinkBatchWorkflow.totalMessages;
  const percentage = totalMessages === 0 ? 0 : processed / totalMessages;
  return (
    <div>
      <div>
        Sending message {Math.min(processed + 1, totalMessages)} of{" "}
        {totalMessages}.
      </div>
      <div className="w-full py-2">
        <ProgressBar percentage={percentage} />
      </div>
      <p className="text-sm text-gray-500">
        Messages are being sent in the background. Feel free to close. We'll
        reload the page when you close this to get the most up to date ready
        bills.
      </p>
    </div>
  );
};

export const START_IMMEDIATE_PAYMENT_REQUEST_BATCH = gql`
  mutation StartImmediatePaymentRequestBatch(
    $patientPaymentRequests: [PatientPaymentRequest!]!
    $enrollInReminders: Boolean!
  ) {
    startImmediatePaymentRequestBatch(
      patientPaymentRequests: $patientPaymentRequests
      enrollInReminders: $enrollInReminders
    ) {
      workflowId
      paymentRequestBatch {
        id
      }
    }
  }
`;

export const SCHEDULE_PAYMENT_REQUEST_BATCH = gql`
  mutation SchedulePaymentRequestBatch(
    $patientPaymentRequests: [PatientPaymentRequest!]!
    $enrollInReminders: Boolean!
    $scheduledAt: DateTime!
  ) {
    schedulePaymentRequestBatch(
      patientPaymentRequests: $patientPaymentRequests
      enrollInReminders: $enrollInReminders
      scheduledAt: $scheduledAt
    ) {
      paymentRequestBatch {
        id
      }
    }
  }
`;

const SendBillsBatchConfirmation: React.FC<
  React.PropsWithChildren<{
    setOpen: (open: boolean) => void;
    patients: Patient[];
    filteredPatients: SelectedPatientForPayment[];
    selectedPatients: SelectedPatientForPayment[];
    selectAllDigital: () => void;
    unSelectAllDigital: () => void;
    selectAllPrint: () => void;
    unSelectAllPrint: () => void;
    selectRow: (
      patient: Patient,
      checked: boolean,
      method: PayMethod,
      printEnabled: boolean,
      previouslyPrint?: boolean
    ) => void;
    tempMinBalance: number;
    setTempMinBalance: (balance: number) => void;
    setMinBalanceFilter: (balance: number) => void;
    minBalance: number;
    setReminders: (reminders: boolean) => void;
    reminders: boolean;
  }>
> = ({
  setOpen,
  patients,
  filteredPatients,
  selectedPatients,
  selectAllDigital,
  unSelectAllDigital,
  selectAllPrint,
  unSelectAllPrint,
  selectRow,
  tempMinBalance,
  setTempMinBalance,
  setMinBalanceFilter,
  minBalance,
  setReminders,
  reminders,
}) => {
  const user = useUser()!;
  const navigate = useNavigate();
  const flags = useFeatureFlags();
  const [sent, setSent] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const [complete, setComplete] = useState(false);
  const [scheduledAt, setScheduledAt] = useState<string | null>(null);
  const [
    startImmediatePaymentRequestBatch,
    startImmediatePaymentRequestBatchResult,
  ] = useMutation<
    StartImmediatePaymentRequestBatch,
    StartImmediatePaymentRequestBatchVariables
  >(START_IMMEDIATE_PAYMENT_REQUEST_BATCH);
  const [schedulePaymentRequestBatch, schedulePaymentRequestBatchResult] =
    useMutation<
      SchedulePaymentRequestBatch,
      SchedulePaymentRequestBatchVariables
    >(SCHEDULE_PAYMENT_REQUEST_BATCH);

  const sendPaymentRequests = () => {
    if (scheduled) {
      if (!scheduledAt) {
        toast.error("Please select a date and time");
        return;
      }
      schedulePaymentRequestBatch({
        variables: {
          patientPaymentRequests: selectedPatients.map((p) => ({
            patientId: p.patient.id,
            method: p.method,
            printEnabled: p.printEnabled,
          })),
          enrollInReminders: reminders,
          // Send as UTC timestamp
          scheduledAt: new Date(scheduledAt).toISOString(),
        },
        onCompleted: (d) => {
          const batchId =
            d.schedulePaymentRequestBatch?.paymentRequestBatch?.id;
          if (batchId) {
            navigate(`/billing/batches/${batchId}`);
          }
        },
        onError: (e) => {
          toast.error("Sending payment requests failed");
          Sentry.captureException(e);
        },
      });
    } else {
      startImmediatePaymentRequestBatch({
        variables: {
          patientPaymentRequests: selectedPatients.map((p) => ({
            patientId: p.patient.id,
            method: p.method,
            printEnabled: p.printEnabled,
          })),
          enrollInReminders: reminders,
        },
        onCompleted: (d) => {
          const batchId =
            d.startImmediatePaymentRequestBatch.paymentRequestBatch.id;
          if (batchId) {
            navigate(`/billing/batches/${batchId}`);
          }
        },
        onError: (e) => {
          toast.error("Sending payment requests failed");
          Sentry.captureException(e);
        },
        refetchQueries: [GET_BILLS, GET_LOCATION_BILL_STATS],
      });
    }
  };

  const initialMinBalance = React.useMemo(
    () =>
      isDefined(tempMinBalance)
        ? (tempMinBalance / 100).toFixed(2).toString()
        : undefined,
    [tempMinBalance]
  );

  const toggleReminders: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setReminders(event.currentTarget.checked);
  };

  const totalBalance = React.useMemo(
    () =>
      selectedPatients.reduce(
        (sum, { patient }) => sum + patient.patientReadyBalance,
        0
      ),
    [selectedPatients]
  );
  const billTotal = React.useMemo(
    () =>
      selectedPatients.reduce(
        (sum, { patient }) => sum + patient.numberOfReadyBills,
        0
      ),
    [selectedPatients]
  );

  const onClose = () => {
    setOpen(false);
    if (sent) {
      window.location.reload();
    }
  };

  const isValid = () => {
    if (selectedPatients.length === 0) return false;
    if (scheduled) {
      if (!scheduledAt) return false;
      if (isPast(parseISO(scheduledAt))) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className="flex flex-col max-h-[calc(100vh-8rem)]">
      {complete ? (
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Sending Complete!
            </h3>
          </div>
        </div>
      ) : (
        <>
          {/* Header Section */}
          <div className="flex-none">
            {/* Stats Grid */}
            <div className="grid grid-cols-3 gap-4 mb-4">
              <div className="border rounded-lg p-4">
                <p className="text-sm font-medium text-gray-500">
                  Number of Patients
                </p>
                <p className="mt-2 text-3xl font-semibold">
                  {selectedPatients.length}
                </p>
              </div>
              <div className="border rounded-lg p-4">
                <p className="text-sm font-medium text-gray-500">
                  Number of Bills
                </p>
                <p className="mt-2 text-3xl font-semibold">{billTotal}</p>
              </div>
              <div className="border rounded-lg p-4">
                <p className="text-sm font-medium text-gray-500">
                  Amount Requested
                </p>
                <p className="mt-2 text-3xl font-semibold">
                  {formatUSD(-totalBalance)}
                </p>
              </div>
            </div>

            {/* Filter Controls */}
            <div className="flex justify-between items-end mb-4">
              <div className="flex items-center gap-2">
                {selectedPatients.filter((p) => p.method !== "print").length !==
                filteredPatients.length ? (
                  <button
                    onClick={selectAllDigital}
                    className="text-indigo-600 hover:text-indigo-500"
                  >
                    Select All
                  </button>
                ) : (
                  <button
                    onClick={unSelectAllDigital}
                    className="text-indigo-600 hover:text-indigo-500"
                  >
                    Unselect All
                  </button>
                )}
              </div>

              <div className="flex items-end gap-2">
                <div className="text-sm text-gray-500">
                  Filtering out {patients.length - filteredPatients.length}{" "}
                  patients with ready balance {"<"} {formatUSD(minBalance)}
                </div>
                <div className="flex rounded-md shadow-sm">
                  <DollarInput
                    containerClassName="relative rounded-l-md border"
                    className="rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full pl-7 py-1 sm:text-sm border-gray-300"
                    id="minBalance"
                    name="minBalance"
                    placeholder="0.00"
                    decimalsLimit={2}
                    defaultValue={initialMinBalance}
                    onValueChange={(amount) => {
                      if (isDefined(amount)) {
                        const number = Number.parseFloat(amount);
                        if (!Number.isNaN(number)) {
                          setTempMinBalance(toCents(number));
                        }
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100"
                    onClick={() => setMinBalanceFilter(tempMinBalance)}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Scrollable Table Section */}
          <div className="flex-1 min-h-0 border rounded-lg">
            <div className="overflow-y-auto max-h-[calc(50vh-12rem)]">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 sticky top-0">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Send link
                    </th>
                    {user.activeLocation.adjudicatedAutopayEnabled && (
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
                      >
                        Autopay
                      </th>
                    )}
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Ready balance
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Generate PDF
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredPatients.length === 0 && (
                    <li className="flex justify-center">No patients</li>
                  )}
                  {filteredPatients.map(({ patient: p }) => {
                    const lastPaymentError =
                      p.mostRecentPaymentIntent?.lastPaymentError;
                    return (
                      <tr key={p.id}>
                        <td className="whitespace-nowrap px-2 py-1 text-sm text-gray-900">
                          {!p.shareable ? (
                            <Tooltip
                              content={
                                "Patient either has no contact information or has digital communications disabled"
                              }
                              trigger={
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  value={p.id}
                                  checked={selectedPatients.some(
                                    (r) =>
                                      r.patient.shareable &&
                                      r.patient.id === p.id &&
                                      r.method === "link"
                                  )}
                                  onChange={(e) => {
                                    const currentPatient =
                                      selectedPatients.find(
                                        (r) => r.patient.id === p.id
                                      );
                                    selectRow(
                                      p,
                                      e.target.checked,
                                      "link",
                                      currentPatient?.printEnabled || false
                                    );
                                  }}
                                  disabled={!p.shareable}
                                />
                              }
                            />
                          ) : (
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              value={p.id}
                              name="link"
                              checked={selectedPatients.some(
                                (r) =>
                                  r.patient.id === p.id && r.method === "link"
                              )}
                              onChange={(e) => {
                                const currentPatient = selectedPatients.find(
                                  (r) => r.patient.id === p.id
                                );
                                selectRow(
                                  p,
                                  e.target.checked,
                                  "link",
                                  currentPatient?.printEnabled || false
                                );
                              }}
                              disabled={!p.shareable}
                            />
                          )}
                        </td>

                        {user.activeLocation.adjudicatedAutopayEnabled && (
                          <td className="whitespace-nowrap px-2 py-1 text-sm text-gray-900">
                            {!p.pledgeAutopayActive ? (
                              <Tooltip
                                content={
                                  "Autopay is not enabled for this patient"
                                }
                                trigger={
                                  <input
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    value={p.id}
                                    checked={selectedPatients.some(
                                      (r) =>
                                        r.patient.id === p.id &&
                                        r.method === "autopay"
                                    )}
                                    onChange={(e) => {
                                      const currentPatient =
                                        selectedPatients.find(
                                          (r) => r.patient.id === p.id
                                        );
                                      selectRow(
                                        p,
                                        e.target.checked,
                                        "autopay",
                                        currentPatient?.printEnabled || false
                                      );
                                    }}
                                    disabled={!p.pledgeAutopayActive}
                                  />
                                }
                              />
                            ) : (
                              <input
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                value={p.id}
                                checked={selectedPatients.some(
                                  (r) =>
                                    r.patient.id === p.id &&
                                    r.method === "autopay"
                                )}
                                onChange={(e) => {
                                  const currentPatient = selectedPatients.find(
                                    (r) => r.patient.id === p.id
                                  );
                                  selectRow(
                                    p,
                                    e.target.checked,
                                    "autopay",
                                    currentPatient?.printEnabled || false
                                  );
                                }}
                                disabled={!p.pledgeAutopayActive}
                              />
                            )}
                          </td>
                        )}

                        <td className="pl-5 whitespace-nowrap py-1 text-sm text-gray-900">
                          <p className="text-xs flex flex-wrap items-center gap-1">
                            {p.displayName}{" "}
                            {p.credits > 0 ? (
                              <Tooltip
                                trigger={
                                  <InformationCircleIcon className="h-4 w-4 text-yellow-500" />
                                }
                                content={
                                  <div>
                                    {formatUSD(p.credits)} credit applied
                                  </div>
                                }
                              />
                            ) : null}
                            {p.pledgeAutopayActive && (
                              <Tooltip
                                trigger={
                                  <CurrencyDollarIcon
                                    className={classNames(
                                      "w-5 h-5",
                                      lastPaymentError
                                        ? "text-red-500"
                                        : "text-green-500"
                                    )}
                                  />
                                }
                                content={
                                  lastPaymentError
                                    ? `Autopay Enabled but last payment failed with error: ${lastPaymentError}`
                                    : `Autopay Enabled${
                                        -p.patientReadyBalance >
                                        AUTOPAY_THRESHOLD
                                          ? " but balance is >$300"
                                          : ""
                                      }`
                                }
                              />
                            )}
                            <span className="text-gray-500">
                              ({p.numberOfReadyBills} bills in{" "}
                              {p.accountTypesWithReadyBills.join(", ")})
                            </span>
                          </p>
                        </td>
                        <td className="whitespace-nowrap py-1 text-sm text-gray-900">
                          <p className="flex justify-between items-center w-full text-sm">
                            {formatUSD(-p.patientReadyBalance)}
                          </p>
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-sm text-gray-900">
                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            value={p.id}
                            name="print"
                            checked={selectedPatients.some(
                              (r) =>
                                r.patient.id === p.id && r.printEnabled === true
                            )}
                            onChange={(e) => {
                              const currentPatient = selectedPatients.find(
                                (r) => r.patient.id === p.id
                              );
                              selectRow(
                                p,
                                e.target.checked,
                                currentPatient?.method || "print",
                                e.target.checked ? true : false,
                                true
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Footer Section */}
          <div className="flex-none mt-4 space-y-4">
            {/* Print Selection */}
            <div className="flex justify-end">
              {selectedPatients.filter((p) => p.printEnabled).length !==
              filteredPatients.length ? (
                <button
                  onClick={selectAllPrint}
                  className="text-indigo-600 hover:text-indigo-500"
                >
                  Generate All
                </button>
              ) : (
                <button
                  onClick={unSelectAllPrint}
                  className="text-indigo-600 hover:text-indigo-500"
                >
                  Unselect All
                </button>
              )}
            </div>

            {/* Reminders Checkbox */}
            {(user?.organization?.automatedRemindersEnabled || true) && (
              <div className="flex items-center">
                <input
                  disabled={selectedPatients.every((p) => p.method === "print")}
                  id="reminders"
                  name="reminders"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600"
                  onChange={toggleReminders}
                  checked={reminders}
                />
                <label
                  htmlFor="reminders"
                  className="ml-2 text-sm text-gray-700"
                >
                  Enroll Patients in Reminders
                </label>
              </div>
            )}

            {/* Schedule Checkbox */}
            <div className="flex items-center">
              <input
                id="scheduled"
                name="scheduled"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600"
                onChange={(val) => setScheduled(val.currentTarget.checked)}
              />
              <label htmlFor="scheduled" className="ml-2 text-sm text-gray-700">
                Schedule for later?
              </label>
            </div>

            {/* Schedule DateTime Input */}
            {scheduled && (
              <div className="max-w-xs">
                <input
                  type="datetime-local"
                  name="scheduledAt"
                  id="scheduledAt"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  min={startOfDay(new Date()).toISOString().slice(0, 16)}
                  onChange={(e) => setScheduledAt(e.target.value)}
                />
              </div>
            )}
          </div>
        </>
      )}
      {/* Footer Section */}
      <div className="flex-none mt-4 space-y-4">
        {/* Action Buttons */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            onClick={onClose}
          >
            Close
          </button>
          {!complete && (
            <SubmitButton
              type="button"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-gray-200 disabled:cursor-not-allowed"
              onClick={() => sendPaymentRequests()}
              loading={startImmediatePaymentRequestBatchResult.loading}
              disabled={!isValid()}
            >
              {scheduled ? "Schedule" : "Send"}
            </SubmitButton>
          )}
        </div>
      </div>
    </div>
  );
};

type PayMethod = "link" | "autopay" | "print";
type SelectedPatientForPayment = {
  patient: Patient;
  method: PayMethod;
  printEnabled: boolean;
};

// TODO: This should be a setting
const AUTOPAY_THRESHOLD = 30000; // $300

const PatientsWithReadyBillsList: React.FC<
  React.PropsWithChildren<{
    setOpen: (open: boolean) => void;
    patients: Patient[];
  }>
> = ({ setOpen, patients }) => {
  const user = useUser()!;
  const [reminders, setReminders] = useState(
    user?.organization?.automatedRemindersEnabled ?? false
  );
  const [minBalance, setMinBalance] = useState<number>(100);
  const [tempMinBalance, setTempMinBalance] = useState(minBalance);
  // Filter out the patients whose ready balance is less than the min balance
  // NOTE: We're NOT including any allocated credits in this calculation
  const filteredPatients = patients
    .filter((patient) => -patient.patientReadyBalance >= minBalance)
    .map((patient) => {
      const lastPaymentError =
        patient.mostRecentPaymentIntent?.lastPaymentError;

      // If the patient's ready balance is above the autopay threshold, default to sending a payment link
      const overAutopayThreshold =
        -patient.patientReadyBalance > AUTOPAY_THRESHOLD;

      return {
        patient,
        // Only default to autopay if it's enabled and there was no error on the last payment and ready balance is below the autopay threshold
        method: patient.shareable
          ? user.activeLocation.adjudicatedAutopayEnabled &&
            patient.pledgeAutopayActive &&
            !lastPaymentError &&
            !overAutopayThreshold
            ? "autopay"
            : "link"
          : "print",
        printEnabled: patient.shareable ? false : true,
      } as SelectedPatientForPayment;
    });
  const [selectedPatients, setSelectedPatients] =
    useState<SelectedPatientForPayment[]>(filteredPatients);

  const selectRow = (
    patient: Patient,
    checked: boolean,
    method: PayMethod,
    printEnabled: boolean,
    printCheckBox: boolean = false
  ) => {
    const newRows = [
      ...selectedPatients.filter((p) => p.patient.id !== patient.id),
      ...(checked
        ? [{ patient, method, printEnabled }]
        : printEnabled
        ? // If we've unchecked a box but print is still enabled, change the method to print
          [
            {
              patient,
              method: "print" as PayMethod,
              printEnabled,
            },
          ]
        : // If we've unchecked either autopay or link box and print is not enabled, remove the row
        printCheckBox
        ? // If we've unchecked the print box, only remove the row if the autopay or link box is unchecked
          method !== "print"
          ? [{ patient, method, printEnabled }]
          : []
        : []),
    ];
    setSelectedPatients(newRows);
    setReminders(
      newRows.every((p) => p.method === "print") ? false : reminders
    );
  };

  const selectAllDigital = () =>
    setSelectedPatients(
      filteredPatients
        .filter(
          ({ patient }) =>
            patient.shareable ||
            selectedPatients.find((p) => p.patient.id === patient.id)
              ?.printEnabled
        )
        .map(({ patient, method }) => ({
          patient,
          method,
          printEnabled:
            selectedPatients.find((p) => p.patient.id === patient.id)
              ?.printEnabled || false,
        }))
    );

  const selectAllPrint = () =>
    setSelectedPatients(
      filteredPatients.map(({ patient, method }) => ({
        patient,
        method:
          selectedPatients.find((p) => p.patient.id === patient.id)?.method ||
          "print",
        printEnabled: true,
      }))
    );

  const unSelectAllDigital = () => {
    setSelectedPatients(
      selectedPatients
        .filter((p) => p.printEnabled)
        .map(({ patient }) => ({
          patient,
          method: "print",
          printEnabled: true,
        }))
    );
    setReminders(false);
  };

  const unSelectAllPrint = () =>
    setSelectedPatients(
      selectedPatients
        .filter((p) => p.method !== "print")
        .map(({ patient, method }) => ({
          patient,
          method,
          printEnabled: false,
        }))
    );

  return (
    <SendBillsBatchConfirmation
      setOpen={setOpen}
      patients={patients}
      filteredPatients={filteredPatients}
      selectedPatients={selectedPatients}
      selectAllDigital={selectAllDigital}
      unSelectAllDigital={unSelectAllDigital}
      selectAllPrint={selectAllPrint}
      unSelectAllPrint={unSelectAllPrint}
      selectRow={selectRow}
      tempMinBalance={tempMinBalance}
      setTempMinBalance={setTempMinBalance}
      setMinBalanceFilter={(balance) => {
        setMinBalance(balance);
        setSelectedPatients(
          // Filter out the patients whose ready balance is less than the min balance
          // NOTE: We're NOT including any allocated credits in this calculation
          patients
            .filter((patient) => -patient.patientReadyBalance >= balance)
            .map((patient) => {
              const lastPaymentError =
                patient.mostRecentPaymentIntent?.lastPaymentError;
              return {
                patient,
                // Only default to autopay if it's enabled and there was no error on the last payment
                method:
                  user.activeLocation.adjudicatedAutopayEnabled &&
                  patient.pledgeAutopayActive &&
                  !lastPaymentError
                    ? "autopay"
                    : "link",
                printEnabled: false,
              } as SelectedPatientForPayment;
            })
        );
      }}
      minBalance={minBalance}
      setReminders={setReminders}
      reminders={reminders}
    />
  );
};

export const SendBillsBatchDialog: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    billFilterParams: BillWhereInput;
    billTotal: number;
  }>
> = ({ open, setOpen, billFilterParams, billTotal }) => {
  const [ignoredWarning, setIgnoredWarning] = useState(false);
  const patientsWithBalanceResult = useQuery<
    GetPatientsWithReadyBalance,
    GetPatientsWithReadyBalanceVariables
  >(GET_PATIENTS_WITH_READY_BALANCE, {
    variables: {
      where: billFilterParams,
      minBalance: 0,
    },
  });

  const patients =
    patientsWithBalanceResult.data?.getPatientsWithReadyBalance ?? [];

  const hasPatientsWhereAllReadyBillsManagedByReminders = patients.some(
    (p) => p.allBillsManagedByReminders
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-4xl max-h-[90vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>Create payment request batch</DialogTitle>
        </DialogHeader>
        <div className="flex-1 min-h-0">
          {patientsWithBalanceResult.loading ? (
            <div className="flex flex-col items-center justify-center gap-4 m-auto w-full min-h-[50vh]">
              <div className="text-2xl">Fetching data for patients...</div>
              <OvalSpinner className="w-6 h-6" />
            </div>
          ) : hasPatientsWhereAllReadyBillsManagedByReminders &&
            !ignoredWarning ? (
            <div className="mt-3 text-center sm:mt-5">
              <div className="flex justify-center gap-2 items-center text-4xl pb-4">
                <ExclamationCircleIcon
                  className="h-10 w-10 text-yellow-400"
                  aria-hidden="true"
                />
                <div className="text-yellow-400">Warning</div>
              </div>
              Some of the bills you've selected are currently managed by
              automated reminders, are you sure you want to continue sending?
              <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <SubmitButton
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  onClick={() => setIgnoredWarning(true)}
                >
                  Yes, continue to sending
                </SubmitButton>
              </div>
            </div>
          ) : (
            <div className="overflow-y-auto flex-1">
              <PatientsWithReadyBillsList
                setOpen={setOpen}
                patients={patients}
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const UPDATE_MANY_BILL_STATUS = gql`
  mutation UpdateManyBillStatus($where: BillWhereInput!, $status: BillState!) {
    updateManyBillStatus(where: $where, status: $status) {
      id
      status
      communicationStatus
      onHoldAt
    }
  }
`;

export const BatchUpdateBillsStatusConfirmationDialog: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    deselectAll: () => void;
    billFilterParams: BillWhereInput;
    selected: number;
    billStatus: BillState;
    onCompleted?: () => Promise<void>;
  }>
> = ({
  open,
  setOpen,
  billFilterParams,
  deselectAll,
  selected,
  billStatus,
  onCompleted,
}) => {
  const [updateManyBillStatus, updateManyBillStatusResult] = useMutation<
    UpdateManyBillStatus,
    UpdateManyBillStatusVariables
  >(UPDATE_MANY_BILL_STATUS);

  const [loading, setLoading] = useState(false);

  const updateStatus = () => {
    setLoading(true);
    updateManyBillStatus({
      variables: {
        where: billFilterParams,
        status: billStatus,
      },
      onCompleted: async (data) => {
        const updatedCount = data.updateManyBillStatus?.length ?? 0;
        if (updatedCount > 0) {
          deselectAll();
          await onCompleted?.();
          toast.success("Bills Updated");
        } else {
          toast.error(
            `Failed to mark bills as ${oldBillStateDisplay(billStatus)}.`
          );
        }
        setLoading(false);
        setOpen(false);
      },
      onError: () => {
        toast.error(
          `Failed to mark bills as ${oldBillStateDisplay(billStatus)}.`
        );
      },
      refetchQueries: [GET_BILLS, GET_LOCATION_BILL_STATS],
    });
  };
  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Mark bills as {oldBillStateDisplay(billStatus)}
          </h3>
          <div className="mt-2 py-4">
            <p className="text-sm text-gray-500">
              By clicking Confirm, you will marking{" "}
              <span className="font-semibold text-gray-900">{selected}</span>{" "}
              bills as {oldBillStateDisplay(billStatus)}
            </p>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
          <SubmitButton
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            onClick={() => updateStatus()}
            loading={loading}
          >
            Confirm
          </SubmitButton>
        </div>
      </div>
    </Modal>
  );
};

export const BulkToggleBillsOnHoldConfirmationDialog: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    selected: number;
    putOnHold: boolean;
    onConfirm: () => Promise<void>;
    loading: boolean;
  }>
> = ({ open, setOpen, selected, putOnHold, onConfirm, loading }) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {putOnHold ? "Put Bills On Hold" : "Remove Bills From Hold"}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to {putOnHold ? "put" : "remove"} {selected}{" "}
              bill{selected !== 1 ? "s" : ""} {putOnHold ? "on" : "from"} hold?
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={async () => {
            await onConfirm();
            setOpen(false);
          }}
        >
          {loading ? <OvalSpinner className="text-white" /> : "Confirm"}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export const DownloadBillsButton: React.FC<
  React.PropsWithChildren<{
    getBillsVariables: GetBillsVariables;
    selectedRows: Pick<Bill, "id">[];
    allSelected: boolean;
  }>
> = ({ getBillsVariables, selectedRows, allSelected }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const [getBillsForDownload, getBillsForDownloadResult] = useLazyQuery<
    GetBillsForDownload,
    GetBillsForDownloadVariables
  >(GET_BILLS_FOR_DOWNLOAD);

  const csvRef = useRef<any>();
  const [csvData, setCsvData] = useState<any[] | null>(null);

  // Necessary to trigger the download after the data has been set async
  useEffect(() => {
    if (csvData && csvRef.current && csvRef.current.link) {
      setTimeout(() => {
        // Click the hidden CSVLink to download the file
        csvRef.current.link.click();
        setCsvData(null);
      });
    }
  }, [csvData]);

  return (
    <>
      <button
        onClick={async () => {
          const { data } = await getBillsForDownload({
            fetchPolicy: "network-only",
            variables: {
              ...getBillsVariables,
              ...(allSelected
                ? {}
                : {
                    // Overwrite where clause to only include selected rows
                    where: {
                      id: { in: selectedRows.map((bill) => bill.id) },
                    },
                  }),
            },
          });
          let rows = (data?.getBillsForDownload ?? []).map((bill) => {
            let remindersDisplay;
            if (!bill.reminders) {
              remindersDisplay = "Not Enrolled";
            } else if (bill.nextReminderDate) {
              remindersDisplay = formatDateMMDDYYYY(bill.nextReminderDate);
            } else {
              remindersDisplay = "Reminders Exhausted";
            }
            return [
              bill.patientName,
              bill.accountType,
              bill.status,
              bill.firstSent ? formatDateMMDDYYYY(bill.firstSent) : "",
              bill.communicationDisplay,
              bill.providerName,
              bill.dateOfService ? toDateMMDDYYYY(bill.dateOfService) : "",
              bill?.dueDate ? toDateMMDDYYYY(bill.dueDate) : "",
              remindersDisplay,
              formatUSD(-bill.allowedAmount),
              formatUSD(-bill.patientResponsibility),
              formatUSD(-bill.patientBalance),
            ];
          });
          const headerRow = [
            "Patient",
            "Account",
            "Status",
            "First Sent",
            "Communication",
            "Provider",
            "Date of Service",
            "Due Date",
            "Reminders",
            "Allowed Amount",
            "Patient Responsibility",
            "Patient Balance",
          ];
          setCsvData([headerRow, ...rows]);
          analytics?.track("Bills Exported", {
            organizationId: user.organization.id,
            organizationName: user.organization.name,
            locationId: user.activeLocation.id,
            locationName: user.activeLocation.name,
          });
        }}
        className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
        disabled={getBillsForDownloadResult.loading}
      >
        {getBillsForDownloadResult.loading ? (
          <>
            Downloading <OvalSpinner className="text-white" />
          </>
        ) : (
          <>
            Download
            <DocumentDownloadIcon
              className="ml-2 -mr-1 h-5 w-5"
              aria-hidden="true"
            />
          </>
        )}
      </button>
      {csvData && (
        <CSVLink
          data={csvData}
          filename="bills.csv"
          ref={csvRef}
          className="hidden"
          target="_blank"
        />
      )}
    </>
  );
};

const PATIENT_BILL_EXPORT = gql`
  fragment PatientBillExport on Patient {
    id
    location {
      name
    }
    displayName
    email
    cellPhone
    patientReadyBalance
    patientDelinquentBalance
    accounts {
      id
      newestBillsByDos: bills(
        orderBy: { dateOfService: { sort: desc, nulls: last } }
        where: { status: { equals: Ready } }
        take: 1
      ) {
        id
        dateOfService
      }
      oldestBillsByDos: bills(
        orderBy: { dateOfService: { sort: asc, nulls: last } }
        where: { status: { equals: Ready } }
        take: 1
      ) {
        id
        dateOfService
      }
      oldestBills: bills(
        orderBy: { dueDate: { sort: asc, nulls: last } }
        where: { status: { equals: Ready } }
        take: 1
      ) {
        id
        dateOfService
        dueDate
        communicationStatus
      }
    }
    paymentIntents(
      where: { status: { equals: "succeeded" } }
      orderBy: { createdAt: desc }
      take: 1
    ) {
      id
      createdAt
    }
    organizationPatientGroup {
      id
      patients {
        id
        displayName
        patientReadyBalance
        location {
          id
          name
        }
      }
    }
  }
`;

export const GET_DELINQUENT_PATIENTS = gql`
  query GetDelinquentPatients {
    getDelinquentPatients {
      id
      locationName
      displayName
      email
      cellPhone
      readyBalance
      delinquentBalance
      oldestDueDate
      newestDueDate
      oldestDos
      newestDos
      mostRecentPledgePaymentDate
      viewed
      linkedPatients
    }
  }
`;

export const dueDateToDelinquentDate = (
  dueDate: Date,
  delinquentDays: number
) => addDays(dueDate, delinquentDays);

/**
 * Button to download a list of patients who have bills that are delinquent (>90 days past due)
 */
export const DownloadDelinquentPatientsButton: React.FC<
  React.PropsWithChildren<{
    delinquentSince: Date | null;
  }>
> = ({ delinquentSince }) => {
  const user = useUser()!;
  const flags = useFeatureFlags();
  const delinquentDays = getDelinquentDaysFromUserOrg(user);
  const analytics = useAnalytics();
  const [getDelinquentPatients, getDelinquentPatientsResult] = useLazyQuery<
    GetDelinquentPatients,
    GetDelinquentPatientsVariables
  >(GET_DELINQUENT_PATIENTS);

  const csvRef = useRef<any>();
  const [csvData, setCsvData] = useState<any[] | null>(null);
  // Necessary to trigger the download after the data has been set async
  useEffect(() => {
    if (csvData && csvRef.current && csvRef.current.link) {
      setTimeout(() => {
        // Click the hidden CSVLink to download the file
        csvRef.current.link.click();
        setCsvData(null);
      });
    }
  }, [csvData]);

  // Due dates before this date are considered delinquent
  const dueDateThreshold = subDays(new Date(), delinquentDays);

  return (
    <>
      <button
        onClick={async () => {
          const { data } = await getDelinquentPatients({
            fetchPolicy: "network-only",
            variables: {
              dueDateThreshold,
            },
          });

          const patients = data?.getDelinquentPatients ?? [];
          const headerRow = [
            "Clinic",
            "Patient Name",
            "Email",
            "Phone Number",
            "Ready Balance",
            "Overdue Balance",
            "Oldest Date of Service",
            "Newest Date of Service",
            "Oldest Due Date",
            "Delinquent On",
            "Last payment from Pledge",
            "Bill has been viewed",
            "Pledge URL",
            ...(flags.patientAccountLinkingEnabled
              ? ["Linked Accounts / Location / Ready Balance"]
              : []),
          ];
          const rows = patients.map((patient) => {
            return [
              patient.locationName,
              patient.displayName,
              patient.email,
              patient.cellPhone,
              formatUSD(patient.readyBalance),
              formatUSD(patient.delinquentBalance),
              patient.oldestDos ? toDateMMDDYYYY(patient.oldestDos) : "",
              patient.newestDos ? toDateMMDDYYYY(patient.newestDos) : "",
              patient.oldestDueDate
                ? toDateMMDDYYYY(patient.oldestDueDate)
                : "",
              patient.oldestDueDate
                ? toDateMMDDYYYY(
                    dueDateToDelinquentDate(
                      parseISO(patient.oldestDueDate),
                      delinquentDays
                    ).toISOString()
                  )
                : "",
              patient.mostRecentPledgePaymentDate
                ? formatDateMMDDYYYY(patient.mostRecentPledgePaymentDate)
                : "",
              patient.viewed ? "Yes" : "No",
              `${window.location.origin}/pledge/${patient.id}`,
              ...(flags.patientAccountLinkingEnabled
                ? [patient.linkedPatients]
                : []),
            ];
          });
          setCsvData([headerRow, ...rows]);
          analytics?.track("Delinquent Patients Exported", {
            organizationId: user.organization.id,
            organizationName: user.organization.name,
            locationId: user.activeLocation.id,
            locationName: user.activeLocation.name,
          });
        }}
        className="inline-flex w-full items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
        disabled={getDelinquentPatientsResult.loading}
      >
        {getDelinquentPatientsResult.loading ? (
          <>
            Downloading <OvalSpinner className="text-white" />
          </>
        ) : (
          <>
            Download
            <DocumentDownloadIcon
              className="ml-2 -mr-1 h-5 w-5"
              aria-hidden="true"
            />
          </>
        )}
      </button>
      {csvData && (
        <CSVLink
          data={csvData}
          filename={`delinquent-patients${
            delinquentSince
              ? "-since-" + format(delinquentSince, "yyyy-MM-dd")
              : ""
          }.csv`}
          ref={csvRef}
          className="hidden"
          target="_blank"
        />
      )}
    </>
  );
};

export const DownloadDelinquentPatientsDialog: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ open, setOpen }) => {
  const [delinquentSince, setDelinquentSince] = useState<Date | null>(null);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Export Delinquent Patients List
          </h3>
          <div className="mt-2 py-4">
            <div className="flex flex-col items-center gap-2">
              Exports a list of patients who have bills that are more than 90
              days overdue and no longer receive reminders. Optionally, filter
              the export to just patients who became delinquent since the
              specified date.
              <div className="max-w-[16em]">
                <input
                  type="date"
                  className="appearance-none block w-full pl-3 pr-8 py-2 border bg-white border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(d) => {
                    if (isValid(d.target.valueAsDate)) {
                      setDelinquentSince(d.target.valueAsDate);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
          <div className="w-full">
            <DownloadDelinquentPatientsButton
              delinquentSince={delinquentSince}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const GET_SENT_PATIENTS = gql`
  ${PATIENT_BILL_EXPORT}
  query GetSentPatients($locationId: String!) {
    patients(
      where: {
        locationId: { equals: $locationId }
        accounts: {
          some: {
            bills: {
              some: {
                status: { equals: Ready }
                dueDate: { not: { equals: null } }
              }
            }
          }
        }
      }
    ) {
      ...PatientBillExport
    }
  }
`;

const getSentPatientsRows = ({
  patients,
  delinquentSince,
  delinquentDays,
  showLinkedAccountsColumn,
}: {
  patients: PatientExport[];
  delinquentSince?: Date | null;
  // Threshold of days past due to be considered delinquent
  delinquentDays: number;
  showLinkedAccountsColumn: boolean;
}) => {
  let rows = [...patients]
    .map((patient) => {
      const oldestBillsByDueDate = patient.accounts.flatMap(
        (account) => account.oldestBills
      );
      const oldestBillsByDos = patient.accounts.flatMap(
        (account) => account.oldestBillsByDos
      );
      const newestBillsByDos = patient.accounts.flatMap(
        (account) => account.newestBillsByDos
      );
      const oldestBillByDos = oldestBillsByDos
        .sort((a, b) => {
          // Sort by oldest dateOfService, ascending
          if (a.dateOfService && b.dateOfService) {
            return compareAsc(
              parseISO(a.dateOfService),
              parseISO(b.dateOfService)
            );
          } else if (a.dateOfService) {
            return -1;
          } else if (b.dateOfService) {
            return 1;
          }
          return 0;
        })
        .at(0);
      const newestBillByDos = newestBillsByDos
        .sort((a, b) => {
          // Sort by oldest dateOfService, ascending
          if (a.dateOfService && b.dateOfService) {
            return compareAsc(
              parseISO(a.dateOfService),
              parseISO(b.dateOfService)
            );
          } else if (a.dateOfService) {
            return -1;
          } else if (b.dateOfService) {
            return 1;
          }
          return 0;
        })
        .at(0);
      const oldestBillByDueDate = oldestBillsByDueDate
        .sort((a, b) => {
          // Sort by oldest due date, ascending
          if (a.dueDate && b.dueDate) {
            return compareAsc(parseISO(a.dueDate), parseISO(b.dueDate));
          } else if (a.dueDate) {
            return -1;
          } else if (b.dueDate) {
            return 1;
          }
          return 0;
        })
        .at(0);
      const oldestDueDate = oldestBillByDueDate?.dueDate
        ? parseISO(oldestBillByDueDate.dueDate)
        : null;
      const delinquentDate = oldestDueDate
        ? dueDateToDelinquentDate(oldestDueDate, delinquentDays)
        : null;
      const oldestDos = oldestBillByDos?.dateOfService
        ? parseISO(oldestBillByDos?.dateOfService)
        : null;
      const oldestDosDisplay = oldestDos
        ? format(oldestDos, "MM/dd/yyyy")
        : null;
      const newestDos = newestBillByDos?.dateOfService
        ? parseISO(newestBillByDos?.dateOfService)
        : null;
      const newestDosDisplay = newestDos
        ? format(newestDos, "MM/dd/yyyy")
        : null;

      // If the oldest bill is before the user-provided delinquent threshold, skip this patient
      if (
        delinquentDate &&
        delinquentSince &&
        delinquentDate < delinquentSince
      ) {
        return null;
      }

      const mostRecentPledgePayment = patient.paymentIntents
        .sort((a, b) => compareDesc(a.createdAt, b.createdAt))
        .at(0);
      const oldestDueDateDisplay = oldestDueDate
        ? format(oldestDueDate, "MM/dd/yyyy")
        : null;
      // If the delinquent date is in the future, don't display it
      const delinquentDateDisplay =
        delinquentDate && isPast(delinquentDate)
          ? format(delinquentDate, "MM/dd/yyyy")
          : null;
      const mostRecentPledgePaymentDateDisplay =
        mostRecentPledgePayment?.createdAt
          ? formatDateMMDDYYYY(mostRecentPledgePayment.createdAt)
          : null;
      const linkedPatients = (patient.organizationPatientGroup?.patients ?? [])
        .map(
          (p) =>
            `${p.displayName} / ${p.location.name} / ${formatUSD(
              -p.patientReadyBalance
            )}`
        )
        .join("\n");
      return {
        oldestDueDate,
        row: [
          patient.location.name,
          patient.displayName,
          patient.email,
          patient.cellPhone,
          formatUSD(-patient.patientReadyBalance),
          formatUSD(-patient.patientDelinquentBalance),
          oldestDosDisplay,
          newestDosDisplay,
          oldestDueDateDisplay,
          delinquentDateDisplay,
          mostRecentPledgePaymentDateDisplay,
          oldestBillByDueDate?.communicationStatus ===
          BillCommunicationState.Viewed
            ? "Yes"
            : "No",
          `${window.location.origin}/patients/${patient.id}`,
          ...(showLinkedAccountsColumn ? [linkedPatients] : []),
        ],
      };
    })
    .filter(isDefined)
    .sort((a, b) => {
      // Sort by oldest due date, ascending
      if (a.oldestDueDate && b.oldestDueDate) {
        return compareAsc(a.oldestDueDate, b.oldestDueDate);
      } else if (a.oldestDueDate) {
        return -1;
      } else if (b.oldestDueDate) {
        return 1;
      }
      return 0;
    });
  const headerRow = [
    "Clinic",
    "Patient Name",
    "Email",
    "Phone Number",
    "Ready Balance",
    "Overdue Balance",
    "Oldest Date of Service",
    "Newest Date of Service",
    "Oldest Due Date",
    "Delinquent On",
    "Last payment from Pledge",
    "Bill has been viewed",
    "Pledge URL",
    ...(showLinkedAccountsColumn
      ? ["Linked Accounts / Location / Ready Balance"]
      : []),
  ];
  return [headerRow, ...rows.map((r) => r.row)];
};

/**
 * Button to download a list of patients who have bills that have been sent a bill
 */
export const DownloadSentPatientsButton: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const user = useUser()!;
  const flags = useFeatureFlags();
  const delinquentDays = getDelinquentDaysFromUserOrg(user);
  const analytics = useAnalytics();
  const [getSentPatients, getSentPatientsResult] = useLazyQuery<
    GetSentPatients,
    GetSentPatientsVariables
  >(GET_SENT_PATIENTS);

  const csvRef = useRef<any>();
  const [csvData, setCsvData] = useState<any[] | null>(null);
  // Necessary to trigger the download after the data has been set async
  useEffect(() => {
    if (csvData && csvRef.current && csvRef.current.link) {
      setTimeout(() => {
        // Click the hidden CSVLink to download the file
        csvRef.current.link.click();
        setCsvData(null);
      });
    }
  }, [csvData]);

  return (
    <>
      <button
        onClick={async () => {
          const { data } = await getSentPatients({
            fetchPolicy: "network-only",
            variables: {
              locationId: user.activeLocation.id,
            },
          });
          const rows = getSentPatientsRows({
            patients: data?.patients ?? [],
            delinquentDays,
            showLinkedAccountsColumn: flags.patientAccountLinkingEnabled,
          });
          setCsvData(rows);
          analytics?.track("Sent Bills Patients Exported", {
            organizationId: user.organization.id,
            organizationName: user.organization.name,
            locationId: user.activeLocation.id,
            locationName: user.activeLocation.name,
          });
        }}
        className="inline-flex w-full items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
        disabled={getSentPatientsResult.loading}
      >
        {getSentPatientsResult.loading ? (
          <>
            Downloading <OvalSpinner className="text-white" />
          </>
        ) : (
          <>
            Download
            <DocumentDownloadIcon
              className="ml-2 -mr-1 h-5 w-5"
              aria-hidden="true"
            />
          </>
        )}
      </button>
      {csvData && (
        <CSVLink
          data={csvData}
          filename={`patients-with-unpaid-sent-bills-on-${format(
            new Date(),
            "yyyy-MM-dd"
          )}.csv`}
          ref={csvRef}
          className="hidden"
          target="_blank"
        />
      )}
    </>
  );
};

export const DownloadSentPatientsDialog: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ open, setOpen }) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Export Patients Sent Bills List
          </h3>
          <div className="mt-2 py-4">
            <div className="flex flex-col items-center gap-2">
              Exports a list of patients who have been sent a bill that hasn't
              yet been paid.
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
          <div className="w-full">
            <DownloadSentPatientsButton />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const BottomDrawer: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    selectedRows: Bill[];
    allSelected: boolean;
    deselect: () => void;
    selectAll: () => void;
    totalCount: number;
    markAsReady: () => void;
    archive: () => void;
    markAsPending: () => void;
    markAsInReview: () => void;
    sendReadyBills: () => void;
    getBillsVariables: GetBillsVariables;
    putOnHold: () => void;
    removeFromHold: () => void;
  }>
> = ({
  open,
  setOpen,
  selectedRows,
  allSelected,
  deselect,
  selectAll,
  totalCount,
  markAsReady,
  archive,
  markAsPending,
  markAsInReview,
  sendReadyBills,
  getBillsVariables,
  putOnHold,
  removeFromHold,
}) => {
  // TODO check status filter too when allSelected

  const allReadyableBills = selectedRows.every(isReadyable);
  const allReviewableBills = selectedRows.every(isReviewable);
  const allPendableBills = selectedRows.every(isPendable);
  const allArchivableBills = selectedRows.every(isArchiveable);
  const allSendableBills = selectedRows.every(isSendaable);
  const allHoldableBills = selectedRows.every(
    (r) => r.status === BillState.InReview
  );
  const allOnHoldBills =
    allHoldableBills && selectedRows.every((bill) => bill.onHoldAt !== null);
  const allNotOnHoldBills =
    allHoldableBills && selectedRows.every((bill) => bill.onHoldAt === null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <div>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div className="flex h-full flex-col overflow-y-scroll bg-indigo-600 text-white py-6 shadow-xl">
            <div className="px-4 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 pl-16">
                  <div className="contents gap-1">
                    {allSelected ? totalCount : selectedRows.length} bills
                    <span className="text-gray-300">selected</span>
                  </div>
                  {!allSelected && (
                    <button
                      onClick={selectAll}
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                    >
                      Select all {totalCount} bills
                    </button>
                  )}
                  <button
                    onClick={deselect}
                    className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                  >
                    Deselect
                  </button>
                </div>
                <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                  <DownloadBillsButton
                    getBillsVariables={getBillsVariables}
                    selectedRows={selectedRows}
                    allSelected={allSelected}
                  />
                  {allPendableBills && (
                    <button
                      type="button"
                      onClick={markAsPending}
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                    >
                      Mark as Pending
                    </button>
                  )}
                  {allOnHoldBills && (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                      onClick={removeFromHold}
                    >
                      Remove from Hold
                    </button>
                  )}
                  {allNotOnHoldBills && (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                      onClick={putOnHold}
                    >
                      Put on Hold
                    </button>
                  )}
                  {allReviewableBills && (
                    <button
                      type="button"
                      onClick={markAsInReview}
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                    >
                      Mark as In Review
                    </button>
                  )}
                  {allReadyableBills && (
                    <button
                      type="button"
                      onClick={markAsReady}
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                    >
                      Mark as Ready
                    </button>
                  )}
                  {allSendableBills && (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                      onClick={sendReadyBills}
                    >
                      Send Bills <PaperAirplaneIcon className="ml-1 w-4 h-4" />
                    </button>
                  )}
                  {allArchivableBills && (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                      onClick={archive}
                    >
                      Archive <ArchiveIcon className="ml-1 w-4 h-4" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

const isEnrolledInRemindersPatientWhereInput = (where: PatientWhereInput) =>
  isDefined(where.reminderWorkflow);

const enrolledInRemindersActive = (where: BillWhereInput) => {
  const and = where.account?.is?.patient?.is?.AND ?? [];
  return and.some((andClause) =>
    andClause.OR?.some(isEnrolledInRemindersPatientWhereInput)
  );
};

const isOnHoldWhereInput = (where: BillWhereInput) => isDefined(where.onHoldAt);

const isScheduledPatientWhereInput = (
  where: BillWhereInput,
  balanceAutopayEnabled: boolean
) => {
  const and = where.AND;
  const or = where.OR;
  const prt =
    or?.some(
      (clause) => clause.paymentRequestTargets?.some?.paymentRequest?.is?.status
    ) ??
    and?.some(
      (clause) => clause.paymentRequestTargets?.none?.paymentRequest?.is?.status
    );
  const account =
    or?.some((clause) => clause.account?.is?.patient?.is?.autoPayEnabled) ??
    and?.some((clause) => clause.account?.is?.patient?.is?.autoPayEnabled);

  // If balance autopay is enabled, we need to check for both scheduled payment request targets and autopay enabled filters are present
  if (balanceAutopayEnabled) return !!prt && !!account;
  return !!prt;
};

const isChargesWhereInput = (where: BillWhereInput) =>
  isDefined(where.charges?.some?.customCode?.equals);

type SortColumn =
  | "dateOfService"
  | "firstName"
  | "accountType"
  | "paidAt"
  | "dueDate";
type AppliedSort = {
  column: SortColumn;
  orderBy: BillOrderByWithRelationInput;
};

export const isPendable = (bill: Pick<Bill, "status">) =>
  bill.status === BillState.InReview ||
  bill.status === BillState.Ready ||
  bill.status === BillState.Archived;
export const isReviewable = (bill: Pick<Bill, "status">) =>
  bill.status === BillState.Pending ||
  bill.status === BillState.Ready ||
  bill.status === BillState.Archived;
export const isReadyable = (bill: Pick<Bill, "status">) =>
  bill.status === BillState.Pending ||
  bill.status === BillState.InReview ||
  bill.status === BillState.Archived;
export const isArchiveable = (bill: Pick<Bill, "status">) =>
  bill.status !== BillState.Resolved &&
  bill.status !== BillState.Reconciled &&
  bill.status !== BillState.Archived;
export const isSendaable = (bill: Pick<Bill, "status">) =>
  bill.status === BillState.Ready;

export const BillActionDropdown: React.FC<{
  bill: Pick<Bill, "id" | "status" | "communicationStatus" | "onHoldAt">;
}> = ({ bill }) => {
  const flags = useFeatureFlags();
  const [updateBill, updateBillResult] = useMutation<
    UpdateBill,
    UpdateBillVariables
  >(UPDATE_BILL);
  const [toggleBillOnHold] = useMutation<
    ToggleBillOnHold,
    ToggleBillOnHoldVariables
  >(TOGGLE_BILL_ON_HOLD);

  const fromPending = bill.status === BillState.Pending;

  const markBillAsReady = async (id: string) => {
    await updateBill({
      variables: {
        id: id,
        data: {
          status: { set: BillState.Ready },
          communicationStatus: {
            set: bill.communicationStatus ?? BillCommunicationState.NotShared,
          },
          readyOn: { set: new Date() },
          inReviewOverrideOn: fromPending ? { set: new Date() } : undefined,
        },
      },
      refetchQueries: [GET_BILLS, GET_LOCATION_BILL_STATS],
      onCompleted: () => {
        toast.success("Bill marked as ready");
      },
      onError: () => {
        toast.error("Failed to mark bill as ready");
      },
    });
  };

  const markBillAsInReview = async (id: string) => {
    await updateBill({
      variables: {
        id: id,
        data: {
          status: { set: BillState.InReview },
          communicationStatus: {
            set: BillCommunicationState.NotShared,
          },
          inReviewOn: { set: new Date() },
          inReviewOverrideOn: fromPending ? { set: new Date() } : undefined,
          readyOn: { set: null },
          dueDate: { set: null },
        },
      },
      refetchQueries: [GET_BILLS, GET_LOCATION_BILL_STATS],
      onCompleted: () => {
        toast.success("Bill marked as in review");
      },
      onError: () => {
        toast.error("Failed to mark bill as in review");
      },
    });
  };

  const markBillAsPending = async (id: string) => {
    await updateBill({
      variables: {
        id: id,
        data: {
          status: { set: BillState.Pending },
          communicationStatus: {
            set: BillCommunicationState.NotShared,
          },
          inReviewOn: { set: null },
          inReviewOverrideOn: { set: null },
          readyOn: { set: null },
          dueDate: { set: null },
        },
      },
      refetchQueries: [GET_BILLS, GET_LOCATION_BILL_STATS],
      onCompleted: () => {
        toast.success("Bill marked as pending");
      },
      onError: () => {
        toast.error("Failed to mark bill as pending");
      },
    });
  };

  const archiveBill = async (id: string) => {
    await updateBill({
      variables: {
        id: id,
        data: {
          status: { set: BillState.Archived },
        },
      },
      refetchQueries: [GET_BILLS, GET_LOCATION_BILL_STATS],
      onCompleted: () => {
        toast.success("Bill archived");
      },
      onError: () => {
        toast.error("Failed to archive bill");
      },
    });
  };

  const toggleOnHold = async (id: string) => {
    await toggleBillOnHold({
      variables: { id },
      refetchQueries: [GET_BILLS, GET_LOCATION_BILL_STATS],
      onCompleted: (data) => {
        if (data.toggleBillOnHold.errors.length > 0) {
          data.toggleBillOnHold.errors.forEach((error) => {
            toast.error(error.message);
          });
        } else {
          toast.success("Bill on hold status updated");
        }
      },
      onError: () => {
        toast.error("Failed to update bill on hold status");
      },
    });
  };

  const pendable = isPendable(bill);
  const reviewable = isReviewable(bill);
  // Can't mark as ready if no post-visit billing enabled
  const readyable = isReadyable(bill) && flags.postVisitBillingEnabled;
  const archiveable = isArchiveable(bill);

  // If no actions, don't display
  if (!pendable && !reviewable && !readyable && !archiveable) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 divide-y">
            {pendable && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm w-full"
                    )}
                    disabled={updateBillResult.loading}
                    onClick={() => markBillAsPending(bill.id)}
                  >
                    Mark as Pending
                  </button>
                )}
              </Menu.Item>
            )}
            {reviewable && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm w-full"
                    )}
                    disabled={updateBillResult.loading}
                    onClick={() => markBillAsInReview(bill.id)}
                  >
                    Mark as In Review
                  </button>
                )}
              </Menu.Item>
            )}
            {readyable && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm w-full"
                    )}
                    disabled={updateBillResult.loading}
                    onClick={() => markBillAsReady(bill.id)}
                  >
                    Mark as Ready
                  </button>
                )}
              </Menu.Item>
            )}
            {bill.status === BillState.InReview && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm w-full"
                    )}
                    onClick={() => toggleOnHold(bill.id)}
                  >
                    {bill.onHoldAt ? "Remove from Hold" : "Put on Hold"}
                  </button>
                )}
              </Menu.Item>
            )}
            {archiveable && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm w-full"
                    )}
                    disabled={updateBillResult.loading}
                    onClick={() => archiveBill(bill.id)}
                  >
                    <ArchiveIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Archive
                  </button>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export const ActionDropdownCell: React.FC<
  React.PropsWithChildren<{
    bill: Pick<Bill, "id" | "status" | "communicationStatus" | "onHoldAt">;
  }>
> = ({ bill }) => {
  const pendable = isPendable(bill);
  const reviewable = isReviewable(bill);
  const readyable = isReadyable(bill);
  const archiveable = isArchiveable(bill);

  // If no actions, don't display
  if (!pendable && !reviewable && !readyable && !archiveable) {
    return <Td />;
  }

  return (
    <Td
      className="flex"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <BillActionDropdown bill={bill} />
    </Td>
  );
};

const MarkBillAsReadyCell: React.FC<
  React.PropsWithChildren<{ bill: Bill }>
> = ({ bill }) => {
  const [updateBill, updateBillResult] = useMutation<
    UpdateBill,
    UpdateBillVariables
  >(UPDATE_BILL);
  const markBillAsReady = async (id: string) => {
    await updateBill({
      variables: {
        id: id,
        data: {
          status: { set: BillState.Ready },
          communicationStatus: {
            set: bill.communicationStatus ?? BillCommunicationState.NotShared,
          },
          readyOn: { set: new Date() },
        },
      },
      refetchQueries: [GET_BILLS, GET_LOCATION_BILL_STATS],
    });
  };
  return (
    <Td>
      {bill.status === BillState.InReview && (
        <button
          className="font-light text-xs text-indigo-700 rounded-md bg-indigo-100 p-1 flex justify-center"
          onClick={() => markBillAsReady(bill.id)}
        >
          {updateBillResult.loading ? (
            <OvalSpinner className="w-4 h-4" />
          ) : (
            "Mark as Ready"
          )}
        </button>
      )}
    </Td>
  );
};

const checkIfLastCommunicationBatchWasSentSuccessfully = (
  bill: Pick<Bill, "communications">
) => {
  const lastCommunication = bill.communications.at(0);
  const lastSuccessfullySentCommunication = bill.communications.find(
    (c) => c.lastErrorType === null
  );
  const lastEmail = bill.communications.find(
    (c) =>
      c.type === CommunicationType.EMAIL &&
      c.contentType === lastCommunication?.contentType
  );
  const lastText = bill.communications.find(
    (c) =>
      c.type === CommunicationType.TEXT &&
      c.contentType === lastCommunication?.contentType
  );

  // If any of the last batch of communications was sent successfully
  let successfullySent = false;
  let withError = false;
  if (lastEmail) {
    successfullySent = successfullySent || lastEmail.lastErrorType === null;
    withError = withError || lastEmail.lastErrorType !== null;
  }
  if (lastText) {
    successfullySent = successfullySent || lastText.lastErrorType === null;
    withError = withError || lastText.lastErrorType !== null;
  }
  return {
    successfullySent,
    withError,
    lastCommunication,
    lastSuccessfullySentCommunication,
  };
};

export const BillShareStatusCell: React.FC<{
  bill: Pick<Bill, "id" | "communications" | "communicationStatus"> & {
    account: {
      patient: {
        displayName: string;
      };
    };
  };
}> = ({ bill }) => {
  const {
    successfullySent,
    withError,
    lastCommunication,
    lastSuccessfullySentCommunication,
  } = checkIfLastCommunicationBatchWasSentSuccessfully(bill);

  if (bill.communicationStatus === BillCommunicationState.Viewed) {
    return (
      <Td>
        <HoverCard.Root openDelay={100}>
          <HoverCard.Trigger>
            <div className="inline-flex items-center gap-1">
              <span className={`inline-flex items-center text-sm`}>
                Bill Viewed
              </span>
              {withError && (
                <InformationCircleIcon className="h-4 w-4 text-red-500" />
              )}
            </div>
          </HoverCard.Trigger>
          <HoverCard.Content
            side="top"
            sideOffset={5}
            align="center"
            alignOffset={-200}
          >
            <div className="flex flex-col bg-white text-gray-900 drop-shadow-xl text-sm rounded-md p-8 pt-6 border">
              <h2 className="text-lg font-medium pb-1">
                Bill Communication Activity
              </h2>
              <BillActivityTimeline
                billId={bill.id}
                patient={bill.account.patient}
              />
            </div>
            <HoverCard.HoverCardArrow
              fill="white"
              style={{ marginTop: "-1px" }}
            />
          </HoverCard.Content>
        </HoverCard.Root>
      </Td>
    );
  }

  if (bill.communications.length === 0) {
    return (
      <Td>
        <HoverCard.Root openDelay={100}>
          <HoverCard.Trigger>
            <span
              className={`inline-flex items-center text-sm italic text-gray-700`}
            >
              Not Shared
            </span>
          </HoverCard.Trigger>
          <HoverCard.Content
            side="top"
            sideOffset={5}
            align="center"
            alignOffset={-200}
          >
            <div className="flex flex-col bg-white text-gray-900 drop-shadow-xl text-sm rounded-md p-8 pt-6 border">
              <h2 className="text-lg font-medium pb-1">
                Bill Communication Activity
              </h2>
              <BillActivityTimeline
                billId={bill.id}
                patient={bill.account.patient}
              />
            </div>
            <HoverCard.HoverCardArrow
              fill="white"
              style={{ marginTop: "-1px" }}
            />
          </HoverCard.Content>
        </HoverCard.Root>
      </Td>
    );
  }

  if (!successfullySent) {
    return (
      <Td>
        <HoverCard.Root openDelay={100}>
          <HoverCard.Trigger>
            <span className={`inline-flex items-center text-sm text-red-600`}>
              Failed
              {lastCommunication?.sentAt &&
                " to send on " + formatDateMMDDYYYY(lastCommunication.sentAt)}
            </span>
          </HoverCard.Trigger>
          <HoverCard.Content
            side="top"
            sideOffset={5}
            align="center"
            alignOffset={-200}
          >
            <div className="flex flex-col bg-white text-gray-900 drop-shadow-xl text-sm rounded-md p-8 pt-6 border">
              <h2 className="text-lg font-medium pb-1">
                Bill Communication Activity
              </h2>
              <BillActivityTimeline
                billId={bill.id}
                patient={bill.account.patient}
              />
            </div>
            <HoverCard.HoverCardArrow
              fill="white"
              style={{ marginTop: "-1px" }}
            />
          </HoverCard.Content>
        </HoverCard.Root>
      </Td>
    );
  } else {
    return (
      <Td>
        <HoverCard.Root openDelay={100}>
          <HoverCard.Trigger>
            <div className="inline-flex items-center gap-1">
              <span className={`inline-flex items-center text-sm`}>
                Sent
                {lastSuccessfullySentCommunication?.sentAt &&
                  " last on " +
                    formatDateMMDDYYYY(
                      lastSuccessfullySentCommunication.sentAt
                    )}
              </span>
              {withError && (
                <InformationCircleIcon className="h-4 w-4 text-red-500" />
              )}
            </div>
          </HoverCard.Trigger>
          <HoverCard.Content
            side="top"
            sideOffset={5}
            align="center"
            alignOffset={-200}
          >
            <div className="flex flex-col bg-white text-gray-900 drop-shadow-xl text-sm rounded-md p-8 pt-6 border">
              <h2 className="text-lg font-medium pb-1">
                Bill Communication Activity
              </h2>
              <BillActivityTimeline
                billId={bill.id}
                patient={bill.account.patient}
              />
            </div>
            <HoverCard.HoverCardArrow
              fill="white"
              style={{ marginTop: "-1px" }}
            />
          </HoverCard.Content>
        </HoverCard.Root>
      </Td>
    );
  }
};

const ColumnSortButton: React.FC<
  React.PropsWithChildren<{
    header: string;
    sort: SortOrder | null;
    sortColumn: SortColumn;
    appliedSort: AppliedSort[];
    removeSort: (sortColumn: SortColumn) => void;
    onClick: () => void;
  }>
> = ({ header, sort, sortColumn, appliedSort, removeSort, onClick }) => {
  const visible = isDefined(sort);
  const sortIdx = appliedSort.findIndex((s) => s.column === sortColumn);
  return (
    <div className="relative inline-block">
      <button
        onClick={onClick}
        className="group inline-flex items-center text-left text-xs font-medium text-gray-500 uppercase"
      >
        {header}
        <div className="relative inline-block">
          <div
            className={classNames(
              "ml-2 flex-none rounded text-white group-hover:visible group-focus:visible",
              visible
                ? "visible bg-indigo-300 hover:bg-indigo-400"
                : "invisible group-hover:visible group-hover:text-gray-500",
              sort === SortOrder.asc ? "rotate-180 transform" : ""
            )}
          >
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </div>
        </div>
      </button>
      {visible && (
        <div className="group absolute -top-2 -right-2 text-center h-4 w-4 rounded-full bg-gray-300 block text-xs hover:bg-gray-400 z-10">
          <button
            onClick={() => removeSort(sortColumn)}
            className="hidden group-hover:flex text-gray-50 h-full w-full justify-center items-center"
          >
            <XIcon className="h-3 w-3" />
          </button>
          <span className="group-hover:hidden">{sortIdx + 1}</span>
        </div>
      )}
    </div>
  );
};

const ARCHIVE_BILL = gql`
  mutation ArchiveBill($id: String!) {
    archiveBill(id: $id) {
      bill {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const ArchiveButton: React.FC<
  React.PropsWithChildren<{ id: string }>
> = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [archiveBill, archiveBillResult] = useMutation<
    ArchiveBill,
    ArchiveBillVariables
  >(ARCHIVE_BILL);

  const archive = () => {
    setLoading(true);
    archiveBill({
      variables: { id },
      onCompleted: async (data) => {
        const [error] = data.archiveBill?.errors ?? [];
        if (!error) {
          toast.success("Bill archived");
        } else {
          toast.error(error.message);
        }
        setLoading(false);
      },
      onError: () => {
        toast.error("Failed to archive bill");
        setLoading(false);
      },
      update: (cache) => {
        const normalizedId = cache.identify({ id, __typename: "Bill" });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  return (
    <SubmitButton
      className={classNames(
        "rounded p-1 -ml-2 hover:bg-gray-200 text-gray-400",
        loading ? "bg-gray-200" : ""
      )}
      spinnerClassName="w-4 h-4"
      onClick={() => archive()}
      loading={loading}
    >
      <ArchiveIcon className="w-4 h-4" />
    </SubmitButton>
  );
};

export const GET_ACCOUNT_TYPES = gql`
  query GetAccountTypes($locationId: String!) {
    accountTypes(
      where: { locationId: { equals: $locationId } }
      orderBy: [{ name: asc }]
    ) {
      id
      name
    }
  }
`;

const getScheduledTrueQuery = (balanceAutopayEnabled: boolean) => {
  if (balanceAutopayEnabled) {
    return {
      OR: [
        {
          paymentRequestTargets: {
            some: {
              paymentRequest: {
                is: {
                  status: { equals: PaymentRequestStatus.Scheduled },
                },
              },
            },
          },
        },
        {
          account: {
            is: {
              patient: {
                is: {
                  autoPayEnabled: { equals: true },
                },
              },
            },
          },
        },
      ],
    };
  }
  return {
    OR: [
      {
        paymentRequestTargets: {
          some: {
            paymentRequest: {
              is: {
                status: { equals: PaymentRequestStatus.Scheduled },
              },
            },
          },
        },
      },
    ],
  };
};

const getScheduledFalseQuery = (balanceAutopayEnabled: boolean) => {
  if (balanceAutopayEnabled) {
    return {
      AND: [
        {
          paymentRequestTargets: {
            none: {
              paymentRequest: {
                is: {
                  status: { equals: PaymentRequestStatus.Scheduled },
                },
              },
            },
          },
        },
        {
          account: {
            is: {
              patient: {
                is: {
                  autoPayEnabled: { equals: false },
                },
              },
            },
          },
        },
      ],
    };
  }
  return {
    AND: [
      {
        paymentRequestTargets: {
          none: {
            paymentRequest: {
              is: {
                status: { equals: PaymentRequestStatus.Scheduled },
              },
            },
          },
        },
      },
    ],
  };
};

export const GET_LOCATION_PROVIDERS = gql`
  query GetLocationProviders($locationId: String!) {
    providers(
      where: { primaryLocationId: { equals: $locationId } }
      orderBy: [{ firstName: { sort: asc, nulls: last } }]
    ) {
      id
      displayName
    }
  }
`;

export const Bills: React.FC<React.PropsWithChildren<unknown>> = () => {
  const flags = useFeatureFlags()!;
  return flags.useNewBillingDashboard ? <BillingPage /> : <OldBillingPage />;
};

export const OldBillingPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const user = useUser()!;
  const delinquentDays = getDelinquentDaysFromUserOrg(user);
  const locationId = user.activeLocation.id;
  const [currentPage, setPageNum] = useState(1);
  const skip = (currentPage - 1) * PAGE_SIZE;
  const [sendBillsBatchDialogOpen, setSendBillsBatchDialogOpen] =
    useState(false);
  const [updateBillStatusModalOpen, setUpdateBillStatusModalOpen] =
    useState(false);
  const [
    exportDelinquentPatientsDialogOpen,
    setExportDelinquentPatientsDialogOpen,
  ] = useState(false);
  const [
    exportSentBillsPatientsDialogOpen,
    setExportSentBillsPatientsDialogOpen,
  ] = useState(false);
  const [bulkToggleOnHoldModalOpen, setBulkToggleOnHoldModalOpen] =
    useState(false);
  const [bulkToggleOnHoldPutOnHold, setBulkToggleOnHoldPutOnHold] =
    useState(false);

  const bulkToggleOnHold = async (putOnHold: boolean) => {
    setBulkToggleOnHoldPutOnHold(putOnHold);
    setBulkToggleOnHoldModalOpen(true);
  };

  const [bulkToggleBillsOnHold, bulkToggleBillsOnHoldResult] = useMutation<
    BulkToggleBillsOnHold,
    BulkToggleBillsOnHoldVariables
  >(BULK_TOGGLE_BILLS_ON_HOLD);

  const confirmBulkToggleOnHold = async () => {
    try {
      const { data } = await bulkToggleBillsOnHold({
        variables: {
          where: allChecked
            ? {
                ...where,
                account: {
                  is: {
                    ...(where.account?.is ?? {}),
                    locationId: { equals: locationId },
                  },
                },
              }
            : {
                id: { in: selectedRows.map((bill) => bill.id) },
              },
          putOnHold: bulkToggleOnHoldPutOnHold,
        },
        refetchQueries: [GET_BILLS, GET_LOCATION_BILL_STATS],
      });

      if (data?.bulkToggleBillsOnHold.errors.length === 0) {
        toast.success(
          `Successfully ${
            bulkToggleOnHoldPutOnHold ? "put" : "removed"
          } bills ${bulkToggleOnHoldPutOnHold ? "on" : "from"} hold`
        );
        deselectAll();
      } else {
        data?.bulkToggleBillsOnHold.errors.forEach((error) => {
          toast.error(error.message);
        });
      }
    } catch (error) {
      console.error("Error toggling bills on hold:", error);
      toast.error("An error occurred while updating bills");
    }
  };

  const [activeModalBillStatus, setActiveModalBillStatus] =
    useState<BillState | null>();
  const [bottomDrawerOpen, setBottomDrawerOpen] = useState(false);
  const searchQueryRef = useRef<HTMLInputElement | null>(null);

  const balanceAutopayEnabled = user.activeLocation.balanceAutopayEnabled;

  const [where, setWhere] = useState<BillWhereInput>({
    status: {
      in: [BillState.InReview],
    },
    onHoldAt: { equals: null },
  });
  const [appliedSort, setAppliedSort] = useState<AppliedSort[]>([
    {
      column: "firstName",
      orderBy: { account: { patient: { firstName: SortOrder.asc } } },
    },
    {
      column: "accountType",
      orderBy: { account: { accountType: { name: SortOrder.asc } } },
    },
  ]);

  const setWhereAndClearSelected = (newWhere: BillWhereInput) => {
    setWhere(newWhere);
    setSelectedRows([]);
    setPageNum(1);
  };

  const addFilter = (newFilter: {
    name: BillFilterKey;
    comparison: string;
    value: any;
  }) => {
    if (newFilter.name === "accountType") {
      setWhereAndClearSelected({
        ...where,
        account: {
          ...where.account,
          is: {
            ...(where.account?.is ?? {}),
            accountType: {
              is: {
                id: {
                  [newFilter.comparison]: newFilter.value,
                },
              },
            },
          },
        },
      });
    } else if (newFilter.name === "enrolledInReminders") {
      setWhereAndClearSelected({
        ...where,
        account: {
          ...where.account,
          is: {
            ...(where.account?.is ?? {}),
            patient: {
              ...(where.account?.is?.patient ?? {}),
              is: {
                ...(where.account?.is?.patient?.is ?? {}),
                AND: [
                  ...(where.account?.is?.patient?.is?.AND ?? []).filter(
                    (andClause) =>
                      !andClause.OR?.some((clause) =>
                        isDefined(clause.reminderWorkflow)
                      )
                  ),
                  newFilter.value === true
                    ? {
                        OR: [
                          {
                            reminderWorkflow: {
                              is: {
                                pausedAt: { equals: null },
                              },
                            },
                          },
                        ],
                      }
                    : {
                        OR: [
                          { reminderWorkflow: { is: null } },
                          {
                            reminderWorkflow: {
                              is: { pausedAt: { not: { equals: null } } },
                            },
                          },
                        ],
                      },
                ],
              },
            },
          },
        },
      });
    } else if (newFilter.name === "sent") {
      setWhereAndClearSelected({
        ...where,
        // FIXME: use communications table instead
        // communications: newFilter.value === true ? { some: {} } : undefined,
        dueDate:
          newFilter.value === true
            ? { not: { equals: null } }
            : { equals: null },
      });
    } else if (newFilter.name === "delivered") {
      setWhereAndClearSelected({
        ...where,
        lastDeliveredAt:
          newFilter.value === true
            ? { not: { equals: null } }
            : { equals: null },
      });
    } else if (newFilter.name === "scheduled") {
      setWhereAndClearSelected({
        ...where,
        // Clear out the existing scheduled filter
        ...(newFilter.value === true ? { AND: null } : { OR: null }),
        ...(newFilter.value === true
          ? getScheduledTrueQuery(balanceAutopayEnabled)
          : getScheduledFalseQuery(balanceAutopayEnabled)),
      });
    } else if (newFilter.name === "charges") {
      setWhereAndClearSelected({
        ...where,
        charges: {
          some: {
            customCode: {
              equals: newFilter.value,
            },
          },
        },
      });
    } else if (newFilter.name === "onHold") {
      setWhereAndClearSelected({
        ...where,
        onHoldAt: newFilter.value
          ? { not: { equals: null } }
          : { equals: null },
      });
    } else {
      setWhereAndClearSelected({
        ...where,
        [newFilter.name]: {
          [newFilter.comparison]: newFilter.value,
        },
      });
    }
  };

  const removeFilter = (name: BillFilterKey) => {
    let newWhere;
    if (name === "accountType") {
      newWhere = {
        ...where,
        account: {
          ...where.account,
          is: { ...(where.account ?? {}).is, accountType: undefined },
        },
      };
    } else if (name === "enrolledInReminders") {
      newWhere = {
        ...where,
        account: {
          ...where.account,
          is: {
            ...(where.account ?? {}).is,
            patient: {
              is: {
                ...(where.account?.is?.patient ?? {}).is,
                AND: where.account?.is?.patient?.is?.AND?.filter(
                  (andClause) =>
                    !andClause?.OR?.some((clause) =>
                      isDefined(clause.reminderWorkflow)
                    )
                ),
              },
            },
          },
        },
      };
    } else if (name === "sent") {
      newWhere = {
        ...where,
        dueDate: undefined,
      };
    } else if (name === "delivered") {
      newWhere = {
        ...where,
        lastDeliveredAt: undefined,
      };
    } else if (name === "scheduled") {
      newWhere = {
        ...where,
        OR: undefined,
        AND: undefined,
      };
    } else if (name === "charges") {
      newWhere = {
        ...where,
        charges: undefined,
      };
    } else if (name === "billingProviderId") {
      newWhere = {
        ...where,
        billingProviderId: undefined,
      };
    } else if (name === "onHold") {
      newWhere = {
        ...where,
        onHoldAt: undefined,
      };
    } else {
      let { [name]: removed, ...updated } = where;
      newWhere = updated;
    }
    setWhereAndClearSelected(newWhere);
  };

  const clearSearchQuery = () => {
    if (searchQueryRef.current && searchQueryRef.current.value) {
      searchQueryRef.current.value = "";
      updateSearchQueryFilter("");
    }
  };

  const updateSearchQueryFilter = (query: string) => {
    if (query === "") {
      setWhereAndClearSelected({
        ...where,
        account: {
          is: {
            patient: {
              is: {
                AND: (where.account?.is?.patient?.is?.AND ?? []).filter(
                  // TODO extract into function
                  (andClause) =>
                    !andClause?.OR?.some((clause) => "firstName" in clause) ||
                    !andClause?.OR?.some((clause) => "lastName" in clause) ||
                    !andClause?.OR?.some((clause) => "preferredName" in clause)
                ),
              },
            },
          },
        },
      });
    } else {
      setWhereAndClearSelected({
        ...where,
        account: {
          is: {
            patient: {
              is: {
                AND: [
                  // keep existing OR filters, but replace the name query ones
                  ...(where.account?.is?.patient?.is?.AND ?? []).filter(
                    // TODO extract into function
                    (andClause) =>
                      !andClause?.OR?.some((clause) => "firstName" in clause) ||
                      !andClause?.OR?.some((clause) => "lastName" in clause) ||
                      !andClause?.OR?.some(
                        (clause) => "preferredName" in clause
                      )
                  ),
                  {
                    OR: [
                      {
                        firstName: {
                          contains: query,
                          mode: QueryMode.insensitive,
                        },
                      },
                      {
                        lastName: {
                          contains: query,
                          mode: QueryMode.insensitive,
                        },
                      },
                      {
                        preferredName: {
                          contains: query,
                          mode: QueryMode.insensitive,
                        },
                      },
                    ],
                  },
                ],
              },
            },
          },
        },
      });
    }
  };

  const accountTypesResult = useQuery<
    GetAccountTypes,
    GetAccountTypesVariables
  >(GET_ACCOUNT_TYPES, {
    variables: {
      locationId,
    },
  });
  const allAccountTypes = accountTypesResult.data?.accountTypes ?? [];
  const providersResult = useQuery<
    GetLocationProviders,
    GetLocationProvidersVariables
  >(GET_LOCATION_PROVIDERS, {
    variables: {
      locationId,
    },
  });
  const allProviders = (providersResult.data?.providers ?? []).filter(
    (p) => !!p.displayName.trim()
  );

  const ninetyDaysAgo = startOfDay(subDays(new Date(), delinquentDays));

  const getBillsVariables = {
    skip,
    take: PAGE_SIZE,
    where: {
      ...where,
      account: {
        is: {
          ...(where.account?.is ?? {}),
          locationId: { equals: locationId },
        },
      },
    },
    // Always sort by createdAt desc so order is deterministic
    orderBy: [
      ...appliedSort.map((s) => s.orderBy),
      { createdAt: SortOrder.desc },
    ],
  };
  const { loading, error, data } = useQuery<GetBills, GetBillsVariables>(
    GET_BILLS,
    {
      variables: getBillsVariables,
      context: {
        debounceKey: "GET_BILLS",
        debounceTimeout: 300,
      },
    }
  );

  const statsResult = useQuery<
    GetLocationBillStats,
    GetLocationBillStatsVariables
  >(GET_LOCATION_BILL_STATS, {
    variables: {
      locationId,
    },
  });

  const rows = data?.bills || [];
  const billTotal = data?.aggregateBill._count?.id ?? 0;

  const onPageChange = (num: number) => {
    setPageNum(num);
  };

  const onStatusSelect = (statuses: BillState[]) => {
    resetPage();
    clearSearchQuery();
    clearUnusedSorts();
    const isInReview = statuses.includes(BillState.InReview);
    setWhereAndClearSelected({
      status: {
        in: statuses,
      },
      ...(isInReview ? { onHoldAt: { equals: null } } : {}),
    });
  };

  // Remove existing filters from new filter dialog
  const filterOptions = BILL_FILTER_OPTIONS.filter(
    (filter) =>
      !isDefined((where as any)[filter.name]) &&
      // Handle filtering if acountType filter is active
      (filter.name === "accountType"
        ? !isDefined(where.account?.is?.accountType?.is?.id)
        : true) &&
      // Handle filtering if enrolledInReminders filter is active
      (filter.name === "enrolledInReminders"
        ? !enrolledInRemindersActive(where)
        : true) &&
      // Handle filtering if sent filter is active
      (filter.name === "sent" ? !isDefined(where.dueDate) : true) &&
      // Handle filtering if delivered filter is active
      (filter.name === "delivered"
        ? !isDefined(where.lastDeliveredAt)
        : true) &&
      // Handle filtering if scheduled filter is active
      (filter.name === "scheduled"
        ? !isScheduledPatientWhereInput(where, balanceAutopayEnabled)
        : true) &&
      // Handle filtering if charges filter is active
      (filter.name === "charges" ? !isChargesWhereInput(where) : true) &&
      // Handle filtering if provider
      (filter.name === "billingProviderId"
        ? !isDefined(where.billingProviderId)
        : true) &&
      // Handle filtering if onHold filter is active
      (filter.name === "onHold" ? !isOnHoldWhereInput(where) : true)
  ).map((filter) => {
    if (
      allAccountTypes.length > 0 &&
      filter.name === "accountType" &&
      isEnumOption(filter)
    ) {
      filter.options = allAccountTypes.map((acctType) => ({
        label: acctType.name,
        value: acctType.id,
      }));
    }
    if (
      allProviders.length > 0 &&
      filter.name === "billingProviderId" &&
      isEnumOption(filter)
    ) {
      filter.options = allProviders.map((p) => ({
        label: p.displayName,
        value: p.id,
      }));
    }
    return filter;
  });

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  useLayoutEffect(() => {
    const currentPageSelectedRows = rows.filter((row) =>
      selectedRows.includes(row)
    );
    const isIndeterminate =
      currentPageSelectedRows.length > 0 &&
      currentPageSelectedRows.length < rows.length;
    const setCheck =
      selectedRows.length > 0 && currentPageSelectedRows.length === rows.length;
    setChecked(setCheck);
    // setChecked(selectedRows.length > 0 && selectedRows.length === rows.length);
    setIndeterminate(isIndeterminate);
    // @ts-ignore
    checkbox.current.indeterminate = isIndeterminate;
    // If any selected rows, open bottom drawer
    setBottomDrawerOpen(selectedRows.length > 0);
  }, [selectedRows, rows, currentPage]);

  function toggleAll() {
    const newRows =
      checked || indeterminate
        ? [...selectedRows.filter((row) => !rows.includes(row))]
        : [
            ...rows.filter((row) => !selectedRows.includes(row)),
            ...selectedRows,
          ];
    setSelectedRows(newRows);
    const becomesChecked = !checked && !indeterminate;
    setChecked(becomesChecked);
    setIndeterminate(false);
  }

  const selectRow = (bill: Bill, checked: boolean) => {
    if (allChecked) {
      setAllChecked(false);
    }
    const newRows = checked
      ? [...selectedRows, bill]
      : selectedRows.filter((p) => p !== bill);
    setSelectedRows(newRows);
  };

  const onSelectAll = () => {
    if (allChecked) {
      setAllChecked(false);
      toggleAll();
    } else {
      setAllChecked(true);
    }
  };

  const deselectAll = () => {
    setSelectedRows([]);
    setChecked(false);
    setAllChecked(false);
    setIndeterminate(false);
  };

  // Checks if the given status is in an active filter
  const statusFilterActive = (status: BillState) => {
    const noStatusFilter =
      !isDefined(where.status) || Object.keys(where.status).length === 0;
    const inFilter = where.status?.in?.includes(status) ?? false;
    const notInFilter =
      where.status?.notIn && !where.status.notIn.includes(status);
    return noStatusFilter || inFilter || notInFilter;
  };

  const setOnHoldFilter = () => {
    resetPage();
    clearSearchQuery();
    clearUnusedSorts();
    setWhereAndClearSelected({
      status: {
        equals: BillState.InReview,
      },
      onHoldAt: {
        not: { equals: null },
      },
    });
  };

  const setReadyUnsentFilter = () => {
    resetPage();
    clearSearchQuery();
    clearUnusedSorts();
    setWhereAndClearSelected({
      status: {
        in: [BillState.Ready],
      },
      // FIXME: revert to this once perf is figured out
      // communications: { none: {} },
      dueDate: { equals: null },
      ...getScheduledFalseQuery(balanceAutopayEnabled),
    });
  };

  const setReadyScheduledFilter = () => {
    resetPage();
    clearSearchQuery();
    clearUnusedSorts();
    setWhereAndClearSelected({
      status: {
        in: [BillState.Ready],
      },
      ...getScheduledTrueQuery(balanceAutopayEnabled),
    });
  };

  const setReadySentFilter = () => {
    resetPage();
    clearSearchQuery();
    clearUnusedSorts();
    setWhereAndClearSelected({
      status: {
        in: [BillState.Ready],
      },
      // FIXME: revert to this once perf is figured out
      // communications: { some: {} },
      dueDate: { not: { equals: null } },
      ...getScheduledFalseQuery(balanceAutopayEnabled),
      // If patient is not enrolled in reminders
      account: {
        is: {
          patient: {
            is: {
              AND: [
                {
                  OR: [
                    { reminderWorkflow: { is: null } },
                    {
                      reminderWorkflow: {
                        is: { pausedAt: { not: { equals: null } } },
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    });
  };

  const setReadyRemindingFilter = () => {
    resetPage();
    clearSearchQuery();
    clearUnusedSorts();
    setWhereAndClearSelected({
      status: {
        in: [BillState.Ready],
      },
      // FIXME: revert to this once perf is figured out
      // communications: { some: {} },
      // If the due date is more than 90 days ago
      dueDate: { gt: ninetyDaysAgo },
      ...getScheduledFalseQuery(balanceAutopayEnabled),
      // If patient is not enrolled in reminders
      account: {
        is: {
          patient: {
            is: {
              AND: [
                {
                  OR: [
                    {
                      reminderWorkflow: {
                        is: { pausedAt: { equals: null } },
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    });
  };

  const setRemindersExhaustedFilter = () => {
    resetPage();
    clearSearchQuery();
    clearUnusedSorts();
    setWhereAndClearSelected({
      status: {
        in: [BillState.Ready],
      },
      // FIXME: revert to this once perf is figured out
      // communications: { some: {} },
      // If the due date is more than 90 days ago
      dueDate: { lte: ninetyDaysAgo },
      ...getScheduledFalseQuery(balanceAutopayEnabled),
      // If patient is not enrolled in reminders
      account: {
        is: {
          patient: {
            is: {
              AND: [
                {
                  OR: [
                    {
                      reminderWorkflow: {
                        is: { pausedAt: { equals: null } },
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    });
  };

  // Remove any sorts on columns that aren't visible
  const clearUnusedSorts = () => {
    // If no paid statuses, remove the paidAt sort
    if (
      !where.status?.in?.includes(BillState.Resolved) ||
      !where.status?.in?.includes(BillState.Reconciled) ||
      where.status?.notIn?.includes(BillState.Resolved) ||
      where.status?.notIn?.includes(BillState.Reconciled)
    ) {
      removeSort("paidAt");
    }
    // If no ready statuses, remove the dueDate sort
    if (
      !where.status?.in?.includes(BillState.Ready) ||
      where.status?.notIn?.includes(BillState.Ready)
    ) {
      removeSort("dueDate");
    }
  };

  // Set the page back to 1 for certain transitions
  const resetPage = () => {
    setPageNum(1);
  };

  const toggleOrder = (
    column: SortColumn,
    currentSort: SortOrder | null,
    setter: (
      orderToAppend: AppliedSort[],
      sortOrder: SortOrder
    ) => AppliedSort[]
  ) => {
    if (currentSort) {
      let newOrderBy: AppliedSort[] = [];
      for (const o of appliedSort) {
        if (o.column === column) {
          // Cycle to desc
          newOrderBy = setter(
            newOrderBy,
            currentSort === SortOrder.asc ? SortOrder.desc : SortOrder.asc
          );
        } else {
          // Leave in place
          newOrderBy.push(o);
        }
      }
      setAppliedSort(newOrderBy);
    } else {
      // Set to active sort
      const newAppliedSort = setter(appliedSort, SortOrder.asc);
      setAppliedSort(newAppliedSort);
    }
  };

  const removeSort = (column: SortColumn) => {
    // Clear filter
    setAppliedSort(appliedSort.filter((o) => o.column !== column));
  };

  const dosSort =
    appliedSort.find((s) => s.column === "dateOfService")?.orderBy
      ?.dateOfService ?? null;
  const firstNameSort =
    appliedSort.find((s) => s.column === "firstName")?.orderBy?.account?.patient
      ?.firstName ?? null;
  const accountTypeSort =
    appliedSort.find((s) => s.column === "accountType")?.orderBy?.account
      ?.accountType?.name ?? null;
  const paidAtSort =
    appliedSort.find((s) => s.column === "paidAt")?.orderBy?.paidAt ?? null;
  const dueDateSort =
    appliedSort.find((s) => s.column === "dueDate")?.orderBy?.dueDate ?? null;

  return (
    <div className="w-full">
      <Layout
        header={
          <div className="flex flex-col">
            <h1 className="text-2xl font-semibold text-gray-900">Billing</h1>
          </div>
        }
        content={
          // Add bottom margin to account for drawer
          <div
            className={classNames(
              "mt-4",
              selectedRows.length > 0 ? "mb-20" : ""
            )}
          >
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end">
              <BillStats
                setInReviewFilter={() => onStatusSelect([BillState.InReview])}
                inReviewCount={
                  statsResult.data?.getLocationBillStats?.inReviewCount
                }
                inReviewSum={
                  statsResult.data?.getLocationBillStats?.inReviewSum
                }
                unsentCount={
                  statsResult.data?.getLocationBillStats?.unsentCount
                }
                unsentSum={statsResult.data?.getLocationBillStats?.unsentSum}
                sentCount={statsResult.data?.getLocationBillStats?.sentCount}
                sentSum={statsResult.data?.getLocationBillStats?.sentSum}
                scheduledCount={
                  statsResult.data?.getLocationBillStats?.scheduledCount
                }
                scheduledSum={
                  statsResult.data?.getLocationBillStats?.scheduledSum
                }
                remindingCount={
                  statsResult.data?.getLocationBillStats?.remindingCount
                }
                remindingSum={
                  statsResult.data?.getLocationBillStats?.remindingSum
                }
                exhaustedCount={
                  statsResult.data?.getLocationBillStats?.exhaustedCount
                }
                exhaustedSum={
                  statsResult.data?.getLocationBillStats?.exhaustedSum
                }
                onHoldCount={
                  statsResult.data?.getLocationBillStats?.onHoldCount
                }
                onHoldSum={statsResult.data?.getLocationBillStats?.onHoldSum}
                loading={statsResult.loading}
                setReadyUnsentFilter={setReadyUnsentFilter}
                setReadyScheduledFilter={setReadyScheduledFilter}
                setReadySentFilter={setReadySentFilter}
                setReadyRemindingFilter={setReadyRemindingFilter}
                setRemindersExhaustedFilter={setRemindersExhaustedFilter}
                setOnHoldFilter={setOnHoldFilter}
              />
            </div>
            <div className="flex"></div>
            <div className="flex items-center justify-between">
              <div className="flex flex-wrap gap-1.5 pt-2">
                <div className="relative flex items-center min-w-[20em] border rounded-md">
                  <div className="absolute inset-y-0 left-2 flex items-center py-1.5 pr-1.5">
                    <SearchIcon className="h-5 w-5" />
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    ref={searchQueryRef}
                    className="block h-8 w-full rounded-md border-gray-300 pl-8 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) => updateSearchQueryFilter(e.target.value)}
                    placeholder="Search by patient first or last name"
                  />
                </div>
                {[
                  ...Object.entries(where).filter(
                    ([key]) =>
                      BILL_FILTER_KEYS.includes(key as BillFilterKey) &&
                      // @ts-ignore
                      isDefined(where[key])
                  ),
                  // Note: Manually adding in nested account type filter option
                  ...(where.account?.is?.accountType?.is?.id
                    ? [["accountType", where.account.is.accountType.is.id]]
                    : []),
                  // Note: Manually adding in nested enrolledInReminders filter option
                  ...(where.account?.is?.patient?.is?.AND
                    ? [
                        [
                          "enrolledInReminders",
                          where.account?.is?.patient?.is?.AND,
                        ],
                      ]
                    : []),
                  // Note: Manually adding in nested sent filter option
                  ...(isDefined(where.dueDate)
                    ? [["sent", where.dueDate]]
                    : []),
                  // Note: Manually adding in nested sent filter option
                  ...(isDefined(where.lastDeliveredAt)
                    ? [["delivered", where.lastDeliveredAt]]
                    : []),
                  ...(isDefined(where.onHoldAt)
                    ? [["onHold", where.onHoldAt]]
                    : []),
                  // Note: Manually adding in nested sent filter option
                  ...(isScheduledPatientWhereInput(where, balanceAutopayEnabled)
                    ? [["scheduled", where.OR ?? where.AND]]
                    : []),
                ].map(([key, filter]) => {
                  return (
                    <EditFilterButton
                      key={key as string}
                      filterKey={key as BillFilterKey}
                      currentFilter={filter}
                      currentFilterValue={
                        getFilterValueFromWhere(key as BillFilterKey, where) ??
                        undefined
                      }
                      filterOptions={filterOptions}
                      addFilter={addFilter}
                      removeFilter={removeFilter}
                      allAccountTypes={allAccountTypes}
                      allProviders={allProviders}
                      balanceAutopayEnabled={balanceAutopayEnabled}
                    />
                  );
                })}
                {/* Don't show the add filter button if they've all been applied */}
                {filterOptions.length > 0 && (
                  <NewFilterButton
                    filterOptions={filterOptions}
                    addFilter={addFilter}
                  />
                )}
              </div>
              {where.status?.in?.includes(BillState.Ready) &&
                enrolledInRemindersActive(where) &&
                isDefined(where.dueDate?.lte) && (
                  <div className="pt-2">
                    <Button
                      onClick={() =>
                        setExportDelinquentPatientsDialogOpen(true)
                      }
                    >
                      Export Delinquent Patients
                    </Button>
                  </div>
                )}
              {where.status?.in?.includes(BillState.Ready) &&
                enrolledInRemindersActive(where) &&
                !isDefined(where.dueDate?.lte) && (
                  <div className="pt-2">
                    <Button
                      onClick={() => setExportSentBillsPatientsDialogOpen(true)}
                    >
                      Export Patients Sent Bills
                    </Button>
                  </div>
                )}
            </div>
            <div className="py-4">
              <Table
                loading={loading}
                columnDefs={[
                  {
                    header: "Select",
                    headerComponent: (
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        // @ts-ignore
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                        hidden={rows.length === 0}
                      />
                    ),
                    cellFn: (bill: Bill) => (
                      <Td className="w-6">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          value={bill.id}
                          checked={
                            selectedRows.some((r) => r.id === bill.id) ||
                            allChecked
                          }
                          onChange={(e) => selectRow(bill, e.target.checked)}
                        />
                      </Td>
                    ),
                  },
                  {
                    header: "Patient",
                    headerComponent: (
                      <ColumnSortButton
                        header="Patient"
                        sort={firstNameSort}
                        sortColumn="firstName"
                        appliedSort={appliedSort}
                        removeSort={removeSort}
                        onClick={() => {
                          toggleOrder(
                            "firstName",
                            firstNameSort,
                            (
                              orderToAppend: AppliedSort[],
                              sortOrder: SortOrder
                            ) => {
                              return [
                                ...orderToAppend,
                                {
                                  column: "firstName",
                                  orderBy: {
                                    account: {
                                      patient: {
                                        firstName: sortOrder,
                                      },
                                    },
                                  },
                                },
                              ];
                            }
                          );
                        }}
                      />
                    ),
                    cellFn: (bill: Bill) => {
                      const lastPaymentError =
                        bill.account.patient.paymentIntents.at(
                          0
                        )?.lastPaymentError;
                      return (
                        <Td>
                          <div className="flex gap-1 items-center">
                            <Link
                              to={`/patients/${bill.account.patient.id}`}
                              className="text-sm text-gray-900 hover:text-gray-500"
                            >
                              {bill.account.patient.displayName}
                            </Link>
                            {bill.account.patient.autoPayEnabled && (
                              <Tooltip
                                trigger={
                                  <CurrencyDollarIcon
                                    className={classNames(
                                      "w-5 h-5",
                                      lastPaymentError
                                        ? "text-red-500"
                                        : "text-green-500"
                                    )}
                                  />
                                }
                                content={
                                  lastPaymentError
                                    ? `Autopay Enabled but last payment failed with error: ${lastPaymentError}`
                                    : "Autopay Enabled"
                                }
                              />
                            )}
                            {bill.account.patient
                              .totalPatientUnallocatedCredits > 0 && (
                              <Tooltip
                                trigger={
                                  <InformationCircleIcon className="h-4 w-4 text-yellow-500" />
                                }
                                content={
                                  <>
                                    This patient has a{" "}
                                    {formatUSD(
                                      bill.account.patient
                                        .totalPatientUnallocatedCredits
                                    )}{" "}
                                    credit
                                  </>
                                }
                              />
                            )}
                            {bill.charges.length > 0 && (
                              <ChargesHoverCard bill={bill} />
                            )}
                          </div>
                        </Td>
                      );
                    },
                  },
                  {
                    header: "Account",
                    headerComponent: (
                      <ColumnSortButton
                        header="Account"
                        sort={accountTypeSort}
                        sortColumn="accountType"
                        appliedSort={appliedSort}
                        removeSort={removeSort}
                        onClick={() => {
                          toggleOrder(
                            "accountType",
                            accountTypeSort,
                            (
                              orderToAppend: AppliedSort[],
                              sortOrder: SortOrder
                            ) => {
                              return [
                                ...orderToAppend,
                                {
                                  column: "accountType",
                                  orderBy: {
                                    account: {
                                      accountType: {
                                        name: sortOrder,
                                      },
                                    },
                                  },
                                },
                              ];
                            }
                          );
                        }}
                      />
                    ),
                    cellFn: (bill: Bill) => (
                      <Td className="text-sm">
                        {bill.account.accountType?.name}
                      </Td>
                    ),
                  },
                  {
                    header: "Status",
                    cellFn: (bill: Bill) => {
                      let indicator;
                      switch (bill.status) {
                        case BillState.Pending:
                          indicator = <PendingStatusIndicator />;
                          break;
                        case BillState.InReview:
                          indicator = <InReviewStatusIndicator />;
                          break;
                        case BillState.Ready:
                          indicator = <ReadyStatusIndicator />;
                          break;
                        case BillState.Reconciled:
                          indicator = <PaidStatusIndicator />;
                          break;
                        case BillState.Resolved:
                          indicator = <PaidStatusIndicator />;
                          break;
                        case BillState.Archived:
                          indicator = <ArchivedStatusIndicator />;
                          break;
                      }
                      return (
                        <Td className="text-sm">
                          <div className="flex items-center gap-1">
                            {indicator}
                            {oldBillStateDisplay(bill.status)}
                          </div>
                        </Td>
                      );
                    },
                  },
                  ...(statusFilterActive(BillState.Ready)
                    ? [
                        {
                          header: "Communication",
                          cellFn: (bill: Bill) => (
                            <BillShareStatusCell bill={bill as any} />
                          ),
                        },
                      ]
                    : []),
                  {
                    header: "Provider",
                    cellFn: (bill: Bill) => {
                      const provider = bill.primaryProvider;
                      return (
                        <Td>
                          <div className="text-sm text-gray-900">
                            {provider?.displayName}
                          </div>
                        </Td>
                      );
                    },
                  },
                  {
                    header: "DOS",
                    headerComponent: (
                      <ColumnSortButton
                        header="DOS"
                        sort={dosSort?.sort ?? null}
                        sortColumn="dateOfService"
                        appliedSort={appliedSort}
                        removeSort={removeSort}
                        onClick={() => {
                          toggleOrder(
                            "dateOfService",
                            dosSort?.sort ?? null,
                            (
                              orderToAppend: AppliedSort[],
                              sortOrder: SortOrder
                            ) => {
                              return [
                                ...orderToAppend,
                                {
                                  column: "dateOfService",
                                  orderBy: {
                                    dateOfService: {
                                      sort: sortOrder,
                                      nulls: NullsOrder.last,
                                    },
                                  },
                                },
                              ];
                            }
                          );
                        }}
                      />
                    ),
                    cellFn: (bill: Bill) => (
                      <Td>
                        <div className="text-sm text-gray-900">
                          {bill.dateOfServiceDisplay &&
                            toDateMMDDYYYY(bill.dateOfServiceDisplay)}
                        </div>
                      </Td>
                    ),
                  },
                  ...(isOnHoldWhereInput(where) &&
                  isDefined(where?.onHoldAt?.not)
                    ? [
                        {
                          header: "On Hold",
                          cellFn: (bill: Bill) => (
                            <Td>
                              {bill.onHoldAt ? (
                                <div className="text-sm text-gray-900">
                                  {formatDistanceToNow(
                                    parseISO(bill.onHoldAt),
                                    { addSuffix: true }
                                  )}
                                </div>
                              ) : null}
                            </Td>
                          ),
                        },
                      ]
                    : []),
                  ...(statusFilterActive(BillState.Ready)
                    ? [
                        {
                          header: "Due Date",
                          headerComponent: (
                            <ColumnSortButton
                              header="Due Date"
                              sort={dueDateSort?.sort ?? null}
                              sortColumn="dueDate"
                              appliedSort={appliedSort}
                              removeSort={removeSort}
                              onClick={() => {
                                toggleOrder(
                                  "dueDate",
                                  dueDateSort?.sort ?? null,
                                  (
                                    orderToAppend: AppliedSort[],
                                    sortOrder: SortOrder
                                  ) => {
                                    return [
                                      ...orderToAppend,
                                      {
                                        column: "dueDate",
                                        orderBy: {
                                          dueDate: {
                                            sort: sortOrder,
                                            nulls: NullsOrder.last,
                                          },
                                        },
                                      },
                                    ];
                                  }
                                );
                              }}
                            />
                          ),
                          cellFn: (bill: Bill) => (
                            <Td
                              className={classNames(
                                "text-sm font-normal",
                                bill.dueDate && isPast(parseISO(bill.dueDate))
                                  ? "text-red-600"
                                  : "text-gray-900"
                              )}
                            >
                              {bill?.dueDate && toDateMMDDYYYY(bill.dueDate)}
                            </Td>
                          ),
                        },
                      ]
                    : []),
                  ...(statusFilterActive(BillState.Ready) &&
                  enrolledInRemindersActive(where)
                    ? [
                        {
                          header: "Next Reminder",
                          cellFn: (bill: Bill) => {
                            const nextReminderDate =
                              bill.account.patient.reminderWorkflow
                                ?.nextReminderDate;
                            const enrolled =
                              isDefined(
                                bill.account.patient.reminderWorkflow?.id
                              ) &&
                              !isDefined(
                                bill.account.patient.reminderWorkflow?.pausedAt
                              );
                            return (
                              <Td className="text-sm font-normal">
                                {!enrolled ? (
                                  <span className="italic text-gray-700">
                                    Not Enrolled
                                  </span>
                                ) : nextReminderDate ? (
                                  <span
                                    className={classNames(
                                      nextReminderDate &&
                                        isPast(parseISO(nextReminderDate))
                                        ? "text-red-600"
                                        : "text-gray-900"
                                    )}
                                  >
                                    {formatDateMMDDYYYY(nextReminderDate)}
                                  </span>
                                ) : (
                                  <span className="text-gray-900">
                                    Reminders Exhausted
                                  </span>
                                )}
                              </Td>
                            );
                          },
                        },
                      ]
                    : []),
                  ...(statusFilterActive(BillState.Resolved) ||
                  statusFilterActive(BillState.Reconciled)
                    ? [
                        {
                          header: "Paid On",
                          headerComponent: (
                            <ColumnSortButton
                              header="Paid On"
                              sort={paidAtSort?.sort ?? null}
                              sortColumn="paidAt"
                              appliedSort={appliedSort}
                              removeSort={removeSort}
                              onClick={() => {
                                toggleOrder(
                                  "paidAt",
                                  paidAtSort?.sort ?? null,
                                  (
                                    orderToAppend: AppliedSort[],
                                    sortOrder: SortOrder
                                  ) => {
                                    return [
                                      ...orderToAppend,
                                      {
                                        column: "paidAt",
                                        orderBy: {
                                          paidAt: {
                                            sort: sortOrder,
                                            nulls: NullsOrder.last,
                                          },
                                        },
                                      },
                                    ];
                                  }
                                );
                              }}
                            />
                          ),
                          cellFn: (bill: Bill) => (
                            <Td>
                              <div className="text-sm text-gray-900">
                                {bill.paidAt && formatDateMMDDYYYY(bill.paidAt)}
                              </div>
                            </Td>
                          ),
                        },
                      ]
                    : []),
                  {
                    header: "Allowed Amt.",
                    cellFn: (bill: Bill) => (
                      <Td>
                        <div className="text-sm text-gray-900">
                          {formatUSD(-bill.allowedTotal)}
                        </div>
                      </Td>
                    ),
                  },
                  {
                    header: "Patient Resp.",
                    cellFn: (bill: Bill) => (
                      <Td>
                        <div className="text-sm text-gray-900">
                          {formatUSD(-bill.patientResponsibility)}
                        </div>
                      </Td>
                    ),
                  },
                  {
                    header: "Patient Balance",
                    cellFn: (bill: Bill) => (
                      <Td>
                        <div className="text-sm font-semibold text-gray-900">
                          {formatUSD(-bill.patientBalance)}
                        </div>
                      </Td>
                    ),
                  },
                  // Action Column
                  {
                    header: "",
                    // cellFn: (bill: Bill) => <MarkBillAsReadyCell bill={bill} />,
                    cellFn: (bill: Bill) => <ActionDropdownCell bill={bill} />,
                  },
                ]}
                rows={rows}
                pagination={{
                  currentPage,
                  totalCount: billTotal,
                  onPageChange,
                  pageSize: PAGE_SIZE,
                  siblingCount: 0,
                }}
              />
            </div>
            {sendBillsBatchDialogOpen && (
              <SendBillsBatchDialog
                open={sendBillsBatchDialogOpen}
                setOpen={setSendBillsBatchDialogOpen}
                billFilterParams={
                  allChecked
                    ? {
                        ...where,
                        account: {
                          is: {
                            ...(where.account?.is ?? {}),
                            locationId: { equals: locationId },
                          },
                        },
                      }
                    : {
                        id: { in: selectedRows.map((bill) => bill.id) },
                      }
                }
                billTotal={billTotal}
              />
            )}
            {updateBillStatusModalOpen && (
              <BatchUpdateBillsStatusConfirmationDialog
                open={updateBillStatusModalOpen}
                setOpen={setUpdateBillStatusModalOpen}
                billFilterParams={
                  allChecked
                    ? {
                        ...where,
                        account: {
                          is: {
                            ...(where.account?.is ?? {}),
                            locationId: { equals: locationId },
                          },
                        },
                      }
                    : {
                        id: { in: selectedRows.map((bill) => bill.id) },
                      }
                }
                deselectAll={() => {
                  deselectAll();
                  // Kind of a hack, reset the page after deselect is called on successful batch status update
                  const totalPages = Math.ceil(billTotal / PAGE_SIZE);
                  const lastPage = currentPage === totalPages;
                  // If it's the last page, then reset to page 1, otherwise reset to the current page
                  setPageNum(lastPage ? 1 : currentPage);
                }}
                selected={allChecked ? billTotal : selectedRows.length}
                billStatus={activeModalBillStatus!}
              />
            )}
            {exportDelinquentPatientsDialogOpen && (
              <DownloadDelinquentPatientsDialog
                open={exportDelinquentPatientsDialogOpen}
                setOpen={setExportDelinquentPatientsDialogOpen}
              />
            )}
            {exportSentBillsPatientsDialogOpen && (
              <DownloadSentPatientsDialog
                open={exportSentBillsPatientsDialogOpen}
                setOpen={setExportSentBillsPatientsDialogOpen}
              />
            )}
            {bulkToggleOnHoldModalOpen && (
              <BulkToggleBillsOnHoldConfirmationDialog
                open={bulkToggleOnHoldModalOpen}
                setOpen={setBulkToggleOnHoldModalOpen}
                selected={allChecked ? billTotal : selectedRows.length}
                putOnHold={bulkToggleOnHoldPutOnHold}
                onConfirm={confirmBulkToggleOnHold}
                loading={bulkToggleBillsOnHoldResult.loading}
              />
            )}
          </div>
        }
      />
      <div className="fixed bottom-0 right-0 w-full">
        <BottomDrawer
          open={bottomDrawerOpen}
          setOpen={setBottomDrawerOpen}
          selectedRows={selectedRows}
          deselect={deselectAll}
          selectAll={onSelectAll}
          allSelected={allChecked}
          totalCount={billTotal}
          markAsReady={() => {
            setUpdateBillStatusModalOpen(true);
            setActiveModalBillStatus(BillState.Ready);
          }}
          archive={() => {
            setUpdateBillStatusModalOpen(true);
            setActiveModalBillStatus(BillState.Archived);
          }}
          markAsInReview={() => {
            setUpdateBillStatusModalOpen(true);
            setActiveModalBillStatus(BillState.InReview);
          }}
          markAsPending={() => {
            setUpdateBillStatusModalOpen(true);
            setActiveModalBillStatus(BillState.Pending);
          }}
          sendReadyBills={() => {
            setSendBillsBatchDialogOpen(true);
          }}
          putOnHold={() => bulkToggleOnHold(true)}
          removeFromHold={() => bulkToggleOnHold(false)}
          getBillsVariables={getBillsVariables}
        />
      </div>
    </div>
  );
};

export const BillingPage = () => {
  return (
    <FullWidthLayout
      header={
        <HorizontalPadding>
          <div className="flex flex-col pt-4">
            <h1 className="text-2xl font-semibold text-gray-900">Billing</h1>
          </div>
        </HorizontalPadding>
      }
      content={
        <div className="flex flex-col">
          <div className="py-4">
            <BillsTable />
          </div>
        </div>
      }
    />
  );
};
